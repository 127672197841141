package mainmenu.about.termstexts

import dev.fritz2.components.compat.div
import dev.fritz2.core.RenderContext
import mainmenu.about.textParagraphs
import mainmenu.about.textPart
import mainmenu.about.textPartTitle
import mainmenu.about.textSubPart

fun RenderContext.termsTextEN() {
    div(
        {
            width { full }
        },
    ) {
        textPartTitle("General Terms and Conditions") {
            p {
                +"Date: March 2023"
            }
        }
        textPart("PREAMBLE") {
            textParagraphs {
                p {
                    +"""FORMATION GmbH, Urbanstraße 71, 10967 Berlin (hereinafter: "Provider") offers customers an innovative workforce-worksite management platform, accessible via both a web application and an app (hereinafter collectively: "Platform"). The core of the services is to create interactive maps within the platform's functionalities, individualize them with information relevant to the respective users (hereinafter: "Users"), and make them usable."""
                }
                p {
                    +"""The following General Terms and Conditions (hereinafter "GTC") apply to all services to be provided by the Provider for Users:"""
                }
            }
        }
        textPart("CONTRACTUAL BASIS", 1) {
            textSubPart(number = 1.1) {
                p {
                    +"The Provider will provide the respective Services agreed under these GTC for the Users during the term of the contract. Unless otherwise agreed, these are free of charge for the User."
                }
            }
            textSubPart(number = 1.2) {
                p {
                    +"The specific services to be provided by the Provider shall be determined by the applicable service description and these GTC."
                }
            }
        }
        textPart("SERVICES OF THE PROVIDER", 2) {
            textSubPart(number = 2.1) {
                p {
                    +"Unless otherwise agreed, the Provider shall make the Platform available as it was available at the time of contract conclusion (\"as is\")."
                }
            }
            textSubPart(number = 2.2) {
                p {
                    +"The Provider is responsible for the functioning and provision of the Platform. The Platform shall be provided through an online access (SaaS solution) or an app. Any further provision or release of the Platform or its underlying source code is not owed."
                }
            }
            textSubPart(number = 2.3) {
                p {
                    +"The handover point for the tool and the data points and datasets is the router output of the server used by the Provider."
                }
            }
        }
        textPart("RIGHTS AND OBLIGATIONS OF USERS", 3) {
            textSubPart(number = 3.1) {
                p {
                    +"The User acknowledges that the Provider, as a technical service provider, only stores the information generated during the execution of the Services for the respective contractual partner."
                }
            }
            textSubPart(number = 3.2) {
                p {
                    +"The User is obligated to keep their password confidential and protect it against unauthorized use by third parties. If the User becomes aware of misuse of their access data or if such misuse is imminent, the User shall inform the Provider immediately. The User is liable for any misuse of their profile; this does not apply if the User is not responsible for the misuse."
                }
            }
            textSubPart(number = 3.3) {
                p {
                    +"The content stored by the User on the Platform may be protected by copyright and data protection regulations. The User hereby grants the Provider the right to reproduce and transmit the content stored on the respective server for the purpose of fulfilling the contract, to analyze it for the improvement of the services, and to reproduce it for the purpose of data backup."
                }
            }
            textSubPart(number = 3.4) {
                p {
                    +"The User supports the Provider in fulfilling its contractual obligations and provides all cooperation necessary for the execution of the contract completely, without request, timely, and at their own expense."
                }
            }
            textSubPart(number = 3.5) {
                textParagraphs {
                    p {
                        +"The User is responsible for ensuring compliance with legal provisions regarding content they upload. The User will observe applicable law and respect the rights of third parties. In particular, the User is prohibited from:"
                    }
                    ul {
                        li {
                            +"Violating third-party protective rights such as trademarks, copyrights, naming rights, personal rights, or data protection rights, as well as"
                        }
                        li {
                            +"Using mechanisms, software, and/or scripts that extend beyond the functionalities and interfaces provided by the Provider on the Platform, especially if the Platform or Provider's systems are blocked, modified, copied, and/or overwritten."
                        }
                    }
                }

            }
            textSubPart(number = 3.6) {
                p {
                    +"The provider may, at its reasonable discretion (§ 315 BGB), taking into account the interests of the user, impose one or more sanctions if the content posted by the user violates these terms and conditions or if there is an important reason. This is particularly the case if the user culpably violates the provisions of section 4.5. The severity of the sanctions depends on the severity of the respective infringement. The sanction remains in force until the sanctioned action is stopped by the respective user and any risk of repetition is eliminated."
                }
            }
            textSubPart(number = 3.7) {
                p {
                    +"Possible sanctions include: (i) deactivation or blocking of individual services or content for the user; (ii) deletion of the content posted by the user; (iii) complete blocking of access to the platform, including the profile; and (iv) complete deletion of an account. The provider's right to terminate the contract without notice for an important reason remains unaffected, as does the assertion of further claims."
                }
            }
            textSubPart(number = 3.8) {
                p {
                    +"If it becomes apparent to the user that, within the scope of the entire contractual relationship between the parties, assumptions, specifications, and statements are incorrect, incomplete, or ambiguous, or if their execution is or becomes impossible, the user will promptly notify the provider of this circumstance."
                }
            }
        }
        textPart("USAGE RIGHTS", 4) {
            textSubPart(number = 4.1) {
                p {
                    +"The provider grants the user the worldwide, non-exclusive and unlimited content rights, limited in time to the respective contractual relationship with the provider, to use the platform and – if applicable – the individually created map within the scope of the available functionalities. Sub-licensing for profit or any other transfer or provision to third parties is not permitted."
                }
            }
            textSubPart(number = 4.2) {
                p {
                    +"Users are not authorized to modify the platform, in particular to change, translate, develop back and forth, decompile or disassemble and re-adapt, unless allowed in this Section 4 or a statutory exemption exists."
                }
            }
            textSubPart(number = 4.3) {
                p {
                    +"A revision is permissible if it is necessary for the correction of a defect and the provider is in default with the correction of the defect, the provider unjustly refuses to rectify the defect or is unable to rectify the defect immediately for other reasons within his area of responsibility."
                }
            }
            textSubPart(number = 4.4) {
                p {
                    +"Decompiling the platform is only allowed if the conditions and requirements specified in § 69 e (1) UrhG are met. The information obtained in this way may not be used or disclosed contrary to the provisions of § 69 e (2) UrhG."
                }
            }
            textSubPart(number = 4.5) {
                p {
                    +"The granting of usage rights, which are wholly or partially owned by third parties, is not owed by the provider unless expressly agreed otherwise."
                }
            }
        }
        textPart("TERM AND TERMINATION", 5) {
            textSubPart(number = 5.1) {
                p {
                    +"The contractual relationship begins at the time the contract is concluded and runs for an indefinite period."
                }
            }
            textSubPart(number = 5.2) {
                p {
                    +"The contractual relationship can be terminated by the contracting parties at any time without notice and without stating reasons."
                }
            }
        }

        textPart("LIABILITY AND WARRANTY", 6) {
            textSubPart(number = 6.1) {
                p {
                    +"The parties shall be liable to each other for direct and indirect material and financial damages caused by them, their legal representatives or vicarious agents or third parties involved by them, in particular subcontractors, in accordance with the following provisions."
                }
            }
            textSubPart(number = 6.2) {
                p {
                    +"The provider shall be liable – except in case of breach of essential contractual obligations, injury to life, body or health, or claims under the Product Liability Act – only for intent and gross negligence. Essential contractual obligations are those the fulfillment of which is necessary to achieve the purpose of the contract. In other respects, the liability standards of §§ 521ff. BGB apply."
                }
            }
            textSubPart(number = 6.3) {
                p {
                    +"The provider shall not be liable for damages resulting from technical malfunctions or performance disruptions of the user or other third parties. The provider shall also not be liable for damages that the user could have prevented by taking reasonable measures, in particular regular program and data backup in accordance with section 3.5. Liability for damages caused by system failures, hacker attacks or other external attacks is excluded. The liability for damages independent of fault for defects, in particular pursuant to § 536a BGB, which already existed at the time the contract was concluded, is excluded."
                }
            }
            textSubPart(number = 6.4) {
                p {
                    +"These claims shall otherwise be governed by the statutory provisions."
                }
            }
        }
        textPart("DATA PROTECTION AND COMPLIANCE", 7) {
            textSubPart(number = 7.1) {
                p {
                    +"The user is responsible for ensuring that all compliance requirements are met within the scope of the contractual relationship and that – if personal data is processed – the provisions of data protection (GDPR, BDSG, TTDSG, etc.) are implemented and continuously observed in compliance with the law. To ensure the security and confidentiality of data subject to data protection, the user shall in particular take and maintain the necessary technical and organizational measures."
                }
            }
            textSubPart(number = 7.2) {
                p {
                    +"In the event that the provider acts as a processor within the scope of the contractual relationship in accordance with Art. 28 GDPR, the additional agreement on order data processing shall apply. In case of contradictions between this contract and the agreement on order data processing, the latter takes precedence over the former."
                }
            }
        }
        textPart("FINAL PROVISIONS", 8) {
            textSubPart(number = 8.1) {
                p {
                    +"The entire contractual relationship between the parties and the services provided by the parties hereunder shall be governed exclusively by the laws of the Federal Republic of Germany, excluding the UN Convention on Contracts for the International Sale of Goods and the conflict of laws rules; Art. 3 EGBGB remains unaffected."
                }
            }
            textSubPart(number = 8.2) {
                p {
                    +"In relation to entrepreneurs within the meaning of § 14 BGB, the registered office of the provider shall be deemed the place of performance and jurisdiction for mutual claims arising from the contractual relationship, unless otherwise specified. In relation to entrepreneurs, the provider is entitled to sue them at their general place of jurisdiction."
                }
            }
            textSubPart(number = 8.3) {
                p {
                    +"Should individual provisions of these license terms be or become invalid or lose their validity due to a later occurring circumstance, the validity of the respective contract shall remain unaffected. The same applies to gaps in these terms and conditions."
                }
            }
        }
    }
}
