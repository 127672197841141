package utils

import com.jillesvangurp.serializationext.DEFAULT_JSON
import kotlin.reflect.KClass
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.callbackFlow
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.KSerializer
import kotlinx.serialization.json.encodeToDynamic
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement
import org.w3c.dom.MutationObserver
import org.w3c.dom.MutationObserverInit
import org.w3c.dom.TouchEvent
import org.w3c.dom.get

/**
 * @suppress
 * External function for loading CommonJS modules.
 */
external fun require(name: String): dynamic


/**
 * JavaScript Object type
 */
external class Object

/**
 * Helper function for creating JavaScript objects.
 */
inline fun obj(init: dynamic.() -> Unit): dynamic {
    return (Object()).apply(init)
}

/**
 * Helper function for creating JavaScript objects with given type.
 */
inline fun <T> obj(init: T.() -> Unit): T {
    return (js("{}") as T).apply(init)
}

/**
 * Helper function for creating JavaScript objects from dynamic constructors.
 */
@Suppress("UNUSED_VARIABLE")
fun <T> Any?.createInstance(vararg args: dynamic): T {
    val jsClassConstructor = this
    val argsArray = (listOf<dynamic>(null) + args).toTypedArray()
    return js("new (Function.prototype.bind.apply(jsClassConstructor, argsArray))").unsafeCast<T>()
}

/**
 * Creates a js(""), casts it to the provided interface and then passes it to the block. Use for initializing whatever implementing
 * a javascript interface. Don't use object:MyInterface.
 *
 * Basically, we're running into this: https://discuss.kotlinlang.org/t/kotlinjs-object-literals-generate-mangled-names-breaking-hasownproperty/9530/4
 *
 * Basically kotlinJs mangles field names of object: MapBoxOptions. Solution: don't use object and instead create a js object and
 * cast it to the interface. This seems a common pattern with libraries that do checks on object fields by name.
 */
inline fun <T> jsApply(init: dynamic = js("{}"), cb: T.() -> Unit): T {
    cb(init.unsafeCast<T>())
    return init.unsafeCast<T>()
}

/**
 * Helper function to enumerate properties of a data class.
 */
@Suppress("UnsafeCastFromDynamic")
fun getAllPropertyNames(obj: Any): List<String> {
    val prototype = js("Object").getPrototypeOf(obj)
    val prototypeProps: Array<String> = js("Object").getOwnPropertyNames(prototype)
    val isLegacy = prototypeProps.filterNot { prototype.propertyIsEnumerable(it) }.toSet() == setOf("constructor")
    return if (isLegacy) {
        val ownProps: Array<String> = js("Object").getOwnPropertyNames(obj)
        ownProps.toList()
    } else {
        prototypeProps.filter { it != "constructor" }.filterNot { prototype.propertyIsEnumerable(it) }.toList()
    }
}

/**
 * Helper extension function to convert a data class to a plain JS object.
 */
fun toPlainObj(data: Any): dynamic {
    val properties = getAllPropertyNames(data)
    val ret = js("{}")
    properties.forEach {
        ret[it] = data.asDynamic()[it]
    }
    return ret
}

/**
 * Helper function to convert a plain JS object to a data class.
 */
fun <T : Any> toKotlinObj(data: dynamic, kClass: KClass<T>): T {
    val newT = kClass.js.createInstance<T>()
    for (key in js("Object").keys(data)) {
        newT.asDynamic()[key] = data[key]
    }
    return newT
}

/**
 * Horizontal and vertical swipe gesture listeners
 *
 */
// source: https://stackoverflow.com/questions/2264072/detect-a-finger-swipe-through-javascript-on-the-iphone-and-android

fun horizontalSwipeListener(
    domNode: HTMLElement,
    onSwipedLeft: (() -> Unit)? = null,
    onSwipedRight: (() -> Unit)? = null,
) {
    var touchStartX = 0
    var touchEndX = 0

    val observer = MutationObserver { _, observer ->
        if (document.contains(domNode)) {
            fun handleGesture() {
                if (touchEndX < touchStartX && (touchStartX - touchEndX) > 100) {
                    onSwipedLeft?.invoke()
//                    console.log("swiped left!")
                }
                if (touchEndX > touchStartX && (touchEndX - touchStartX) > 100) {
                    onSwipedRight?.invoke()
//                    console.log("swiped right!")
                }
            }
            domNode.addEventListener(
                "touchstart",
                { event ->
                    val e = event as? TouchEvent
                    touchStartX = e?.changedTouches?.get(0)?.screenX ?: 0
                },
            )
            domNode.addEventListener(
                "touchend",
                { event ->
                    val e = event as? TouchEvent
                    touchEndX = e?.changedTouches?.get(0)?.screenX ?: 0
                    handleGesture()
                },
            )
            observer.disconnect()
        }
    }
    observer.observe(
        document,
        MutationObserverInit(attributes = false, childList = true, characterData = false, subtree = true),
    )
}

fun verticalSwipeListener(
    domNode: HTMLElement,
    onSwipedUp: (() -> Unit)? = null,
    onSwipedDown: (() -> Unit)? = null,
) {
    var touchStartY = 0
    var touchEndY = 0

    val observer = MutationObserver { _, observer ->
        if (document.contains(domNode)) {
            fun handleGesture() {
                if (touchEndY < touchStartY) {
                    onSwipedDown?.invoke()
//                    console.log("swiped down!")
                }
                if (touchEndY > touchStartY) {
                    onSwipedUp?.invoke()
//                    console.log("swiped up!")
                }
            }
            domNode.addEventListener(
                "touchstart",
                { event ->
                    val e = event as? TouchEvent
                    touchStartY = e?.changedTouches?.get(0)?.screenY ?: 0
                },
            )
            domNode.addEventListener(
                "touchend",
                { event ->
                    val e = event as? TouchEvent
                    touchEndY = e?.changedTouches?.get(0)?.screenY ?: 0
                    handleGesture()
                },
            )
            observer.disconnect()
        }
    }
    observer.observe(
        document,
        MutationObserverInit(attributes = false, childList = true, characterData = false, subtree = true),
    )
}

data class ElementSize(
    val width: Int,
    val height: Int
)

fun resizeObserver(
    domNode: HTMLElement,
) = callbackFlow {
    val resizeObserver = ResizeObserver { entries, _ ->
        val element = entries.firstOrNull()
        val containerWidth = (element?.contentRect?.width?.toInt() ?: 0)
        val containerHeight = (element?.contentRect?.height?.toInt() ?: 0)
        channel.trySend(ElementSize(containerWidth, containerHeight))
    }
    resizeObserver.observe(domNode)
    awaitClose {
        console.log("ResizeObserver channel closed.")
        resizeObserver.disconnect()
    }
}

fun widthWithMarginsOf(element: Element): Int {
    return (element.clientWidth) +
        window.getComputedStyle(element).marginLeft.dropLast(2).toInt() +
        window.getComputedStyle(element).marginRight.dropLast(2).toInt()
}

fun heightWithMarginsOf(element: Element): Int {
    return (element.clientHeight) +
        window.getComputedStyle(element).marginTop.dropLast(2).toInt() +
        window.getComputedStyle(element).marginBottom.dropLast(2).toInt()
}

@OptIn(ExperimentalSerializationApi::class)
fun <T> toJsObject(serializer: KSerializer<T>, obj: T): dynamic {
    return DEFAULT_JSON.encodeToDynamic(serializer, obj)
}

@OptIn(ExperimentalSerializationApi::class)
inline fun <reified T> toJsObject(obj: T): dynamic {
    return DEFAULT_JSON.encodeToDynamic(obj)
}

fun <T> fromJsObject(serializer: KSerializer<T>, jsObject: dynamic): T {
    val jsonString = JSON.stringify(jsObject)
    return DEFAULT_JSON.decodeFromString(serializer, jsonString)
}

// Detecting if iOS via feature detection
// Source: https://stackoverflow.com/a/9039885
fun isIOS(): Boolean {
    val iosDevices = listOf(
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
    )

    val platform = js("navigator.platform") as String
    val userAgent = js("navigator.userAgent") as String
    val isTouchDevice = js("('ontouchend' in document)") as Boolean

    return platform in iosDevices || (userAgent.contains("Mac") && isTouchDevice)
}

/*
  Dynamically add maximum-scale to the meta viewport when the device is iOS.
  This achieves the best of both worlds: We allow the user to zoom on android
  and prevent iOS from zooming into text fields on focus.
  Source: https://stackoverflow.com/a/57527009
*/

fun disableIOSTextFieldZoom() {
    if (!isIOS()) {
        console.log("No iOS detected -> Keep meta[name=viewport] > content header as is.")
        return
    }

    val element = document.querySelector("meta[name=viewport]")
    if (element != null) {
        var content = element.getAttribute("content")
        if (content != null) {
            val scalePattern = Regex("maximum-scale=[0-9\\.]+")

            content = if (scalePattern.containsMatchIn(content)) {
                content.replace(scalePattern, "maximum-scale=1.0")
            } else {
                "$content, maximum-scale=1.0"
            }
            element.setAttribute("content", content)

            console.log("iOS detected -> Set \"maximum-scale=1.0\" in meta[name=viewport] > content header.", content.toString())
        }
    }
}
