package twcomponents

import dev.fritz2.core.RenderContext
import dev.fritz2.core.viewBox
import theme.IconEnum

fun RenderContext.twIconSmall(icon: IconEnum) {
    svg("h-4 flex-shrink-0") {
        viewBox(icon.viewBox)
        content(icon.svg)
    }
}

fun RenderContext.twIconMedium(icon: IconEnum) {
    svg("h-5 flex-shrink-0") {
        viewBox(icon.viewBox)
        content(icon.svg)
    }
}

fun RenderContext.twIconLarge(icon: IconEnum) {
    svg("h-7 flex-shrink-0") {
        viewBox(icon.viewBox)
        content(icon.svg)
    }
}

fun RenderContext.twIconSmallToMedium(icon: IconEnum) {
    svg("h-4 sm:h-5") {
        viewBox(icon.viewBox)
        content(icon.svg)
    }
}

fun RenderContext.twIconMediumToLarge(icon: IconEnum) {
    svg("h-5 sm:h-8") {
        viewBox(icon.viewBox)
        content(icon.svg)
    }
}

/**
 * Icon with customizable [size] in px.
 *
 * Example: size = "100px"
 */
fun RenderContext.twIconCustomSize(icon: IconEnum, size: String) {
    svg("flex-shrink-0") {
        inlineStyle("--iconSize:$size")
        className("w-[var(--iconSize)] h-[var(--iconSize)]")
        viewBox(icon.viewBox)
        content(icon.svg)
    }
}
