package map

import auth.ApiUserStore
import data.users.ActiveUserStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import location.GeoPositionStore
import location.LocationFollowStore
import location.LocationUploadStore
import location.geolocation.GeoPosition
import maplibreGL.MaplibreMap
import maplibreGL.generateMyMaplibreUserMarker


class UserMapMarkerStore : RootStore<Boolean>(
    initialData = false,
    job = Job(),
) {

    private val geoPositionStore: GeoPositionStore by koinCtx.inject()
    private val locationUploadStore: LocationUploadStore by koinCtx.inject()
    private val locationFollowStore: LocationFollowStore by koinCtx.inject()
    private val maplibreMap: MaplibreMap by koinCtx.inject()
    private val apiUserStore: ApiUserStore by koinCtx.inject()
    private val activeUserStore: ActiveUserStore by koinCtx.inject()

    val initialize = handle { current -> current }

    // use var latest to mirror the current store value to handle almost simultaneous updates of the store
//    var latest = ObjectLayers(objectLayers = initialObjectLayerSet)

//    val jumpToMyPosition = handle { current ->
//        CoroutineScope(CoroutineName("jump-to-user")).launch {
//            for (i in 0..1) {
//                if (geoPositionStore.current == null) {
//                    geoPositionService.locateMe()
//                    console.log("Trying to jump to users position")
//                } else {
//                    geoPositionService.locateMe()
//                    break
//                }
//                delay(500)
//            }
//            maplibreMap.addHiddenOverride(apiUserStore.current.userId)
//        }
//        current
//    }

//    private fun setObjects(objectLayerId: ObjectType, newObjects: List<MaplibreElement>?) {
//        val newLayers = latest.objectLayers?.map { objectLayer ->
//            if (objectLayer.id == objectLayerId) {
//                if (objectLayer.objects != newObjects) {
//                    objectLayer.copy(objects = newObjects, hasChanged = true)
//                } else objectLayer.copy(hasChanged = false)
//            } else objectLayer
//        }
//        latest = current.copy(objectLayers = newLayers)
//        update(latest)
//    }

//    private fun setObjects(objectMap: Map<ObjectType, List<MaplibreElement>?>) {
//        val newLayers = latest.objectLayers?.map { objectLayer ->
//            if (objectMap.keys.contains(objectLayer.id)) {
//                if (objectLayer.objects != objectMap[objectLayer.id]) {
////                    console.log("SET OBJECTS (${objectLayer.id.name})",
////                        objectMap[objectLayer.id]?.map { (it as? MaplibreMarker)?.title }.toString())
//                    objectLayer.copy(objects = objectMap[objectLayer.id], hasChanged = true)
//                } else objectLayer.copy(hasChanged = false)
//            } else objectLayer
//        }
//        latest = current.copy(objectLayers = newLayers)
//        update(latest)
//    }

//    private val updateMaplibreMap = handle<ObjectLayers> { _, newMapObjectLayers ->
//        newMapObjectLayers.objectLayers?.let { objectsLayers -> maplibreMap.updateMap(objectsLayers.filter { it.hasChanged }) }
//        maplibreMap.syncMarkersNow()
//        newMapObjectLayers
//    }

    private fun updateMyUser(
        position: GeoPosition? = geoPositionStore.current,
    ) {
        position?.let {
            console.warn("update MaplibreMap and trigger a refresh instead")
            //TODO update MaplibreMap and trigger a refresh instead
            val newUserMarker = generateMyMaplibreUserMarker(
                user = apiUserStore.current,
                position = it,
            )
            maplibreMap.setMyUserMarker(
                newUserMarker,
            )
        }
    }

    private val updateMyUserPosition = SimpleHandler<GeoPosition?> { data, _ ->
        data handledBy { newPosition ->
            updateMyUser(position = newPosition)
        }
    }

//    val reset = handle {
//        console.log("Reset MapObjectStore")
//        latest = ObjectLayers(initialObjectLayerSet)
//        ObjectLayers(initialObjectLayerSet)
//    }

    init {
        combine(
            apiUserStore.data,
            activeUserStore.data,
            locationUploadStore.data,
            locationFollowStore.data,
            geoPositionStore.data,
        ) { _, _, _, _, position -> position } handledBy updateMyUserPosition
    }
}
