package objectrouting

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectChanges
import apiclient.geoobjects.RemoveOpenRouteToXActionCode
import apiclient.geoobjects.applyObjectChanges
import com.jillesvangurp.geojson.urlEncode
import data.objects.ActiveObjectStore
import dev.fritz2.core.Handler
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import koin.koinCtx
import koin.withKoin
import kotlinx.coroutines.flow.Flow
import localization.TL
import localization.getTranslationFlow
import localization.translate
import maplibreGL.joinNullableLists
import model.L
import org.w3c.dom.asList
import org.w3c.dom.svg.SVGElement
import overlays.withBusyApiClient
import qrcodegeneration.toSvgQrCode
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.twColOfNoGap
import twcomponents.twFlatBoxRowCenter
import twcomponents.twFlatCopyClipboardButton
import twcomponents.twFlatIconBox
import twcomponents.twIconMedium
import twcomponents.twRowOfNoGap
import twcomponents.twSecondaryButton
import twcomponents.twSimpleModalWithCloseHeader
import utils.insertObjectInCachesAndMap
import workspacetools.usermanagement.confirm

fun showRouteToXActionCodeModal(
    title: Flow<String>,
    toggleShowModalStore: Store<Boolean>,
    actionCodeFlow: Flow<String>,
    geoObject: GeoObjectDetails? = null,
    objectUpdateHandlers: List<Handler<GeoObjectDetails?>> = emptyList(),
) {
    withKoin {
        val activeObjectStore: ActiveObjectStore = koinCtx.get()
        val tags = activeObjectStore.map(GeoObjectDetails.L.tags)

        twSimpleModalWithCloseHeader(
            titleAsFlow = title,
            toggleStore = toggleShowModalStore,
        ) { close, _, optionalCloseHandlers ->
            twColOfNoGap("gap-4 items-stretch justify-center") {
                // If action code is entered, use code together with destination latLon to add a RouteToX action code to the origin object
                actionCodeFlow handledBy { actionCode ->
                    if (actionCode.isNotBlank()) {
                        val link = "https://app.tryformation.com/#id=${actionCode.urlEncode()}"
                        val svgContent = toSvgQrCode(link)

                        div("flex max-h-max w-full px-4 object-scale-down") {
                            domNode.innerHTML = svgContent
                        }.also {
                            this.domNode.children.asList().firstOrNull { it is SVGElement }.also { svg ->
                                svg?.setAttribute("height", "100%")
                                svg?.setAttribute("width", "100%")
                            }
                        }
//                            }
                        p("text-xs font-mono text-center w-full h-full px-4") { +actionCode }

                        if (geoObject != null) {
                            div("flex flex-col w-full px-4") {
                                twSecondaryButton(
                                    icon = FormationIcons.DeleteAlt,
                                ) {
                                    p("text-xs font-bold") { translate(TL.ActionCode.REMOVE_ACTION_CODE) }
                                    clicks handledBy confirm(
                                        title = TL.ActionCode.ARE_YOU_SURE.getTranslationFlow(),
                                        text = TL.ActionCode.DO_YOU_REALLY_WANT_TO_REMOVE.getTranslationFlow(),
                                        okHandlers = joinNullableLists(
                                            listOf(
                                                listOf(
                                                    SimpleHandler { data, _ ->
                                                        data handledBy {
                                                            withBusyApiClient(
                                                                { client ->
                                                                    client.applyObjectChanges(
                                                                        ObjectChanges(
                                                                            geoObject.id,
                                                                            RemoveOpenRouteToXActionCode(
                                                                                actionCode = actionCode,
                                                                            ),
                                                                        ),
                                                                    )
                                                                },
                                                            ) { results ->
                                                                results.firstOrNull()?.let { obj ->
                                                                    if (activeObjectStore.current.id == obj.id) {
                                                                        tags.update(obj.tags)
                                                                    }
                                                                    insertObjectInCachesAndMap(obj)
                                                                    objectUpdateHandlers.forEach { update ->
                                                                        update(obj)
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    },
                                                ),
                                                optionalCloseHandlers,
                                                listOf(close),
                                            ),
                                        ),
                                    )
                                }
                            }
                        }

                        twRowOfNoGap("-mx-2 -mb-2") {
                            twFlatIconBox {
                                twIconMedium(FormationUIIcons.Link)
                            }
                            twFlatBoxRowCenter {
                                p("text-xs") {
                                    +link
                                }
                            }
                            twFlatCopyClipboardButton(link)
                        }
                    }
                }
            }
        }
    }
}
