package data.users.profile

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import apiclient.passwords.evaluatePassword
import apiclient.users.restResetPasswordUsingToken
import auth.ApiUserStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.flowOf
import localization.TL
import localization.Translation
import login.HostConfigStore
import login.ResetPasswordDataStore
import login.WorkspaceInputStore
import mainmenu.Pages
import mainmenu.RouterStore
import model.NotificationType
import model.Overlay
import model.StatusResult
import model.ValidatePassword
import overlays.AlertOverlayStore
import services.UserService
import theme.FormationColors
import utils.merge

class UpdatePasswordStore : RootStore<ValidatePassword>(
    initialData = ValidatePassword(),
    id = "userUpdatePassword",
    job = Job(),
) {
    private val userService: UserService by koinCtx.inject()
    private val apiUserStore: ApiUserStore by koinCtx.inject()
    private val alertOverlayStore: AlertOverlayStore by koinCtx.inject()
    val router: MapRouter by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    private val translation: Translation by koinCtx.inject()
    private val resetPasswordDataStore: ResetPasswordDataStore by koinCtx.inject()
    private val workspaceInputStore: WorkspaceInputStore by koinCtx.inject()
    private val hostConfigStore: HostConfigStore by koinCtx.inject()
    private val analyticsService by koinCtx.inject<AnalyticsService>()

    val reset = handle {
        ValidatePassword()
    }

    private val resetPasswordGraphQLClient = hostConfigStore.anonymousClient()

    val resetPasswordWithToken = handle {
        val token = resetPasswordDataStore.current.token
        val email = resetPasswordDataStore.current.email
        val workspace = workspaceInputStore.current
        if (current.isValid and token.isNotBlank() && email.isNotBlank() && workspace.isNotBlank()) {
            console.log(
                "Reset password using:",
                "email:",
                email,
                "workspace:",
                workspace,
                "token:",
                token,
            ) //, "password:", current.firstPass)
            val res = resetPasswordGraphQLClient.restResetPasswordUsingToken(
                token = token,
                emailAddress = email,
                workspaceName = workspace,
                newPassword = current.firstPass,
            )
            res.fold(
                { apiUser ->
                    reset()
                    routerStore.validateInternalRoute(Pages.Map.route)
                    console.log("Reset Password succeeded!", apiUser)
                    alertOverlayStore.notify(translation[TL.AlertNotifications.PW_CHANGE_SUCCESSFUL])
                    apiUserStore.set(apiUser)
                },
                { error ->
                    console.log("Reset Password failed with message:", error.cause, error.message)
                    alertOverlayStore.errorNotify(translation[TL.AlertNotifications.PW_CHANGE_ERROR].merge(flowOf(": ${error.message}")))
                },
            )
        } else {
            console.log(
                "Reset password failed. Something missing.",
                "email:",
                email,
                "workspace:",
                workspace,
                "token:",
                token,
            ) //, "password:", current.firstPass)
        }
        it
    }

    val updatePassword = handle { current ->
        if (current.isValid) {
            val (result, error) = userService.updatePassword(
                newPassword = current.firstPass,
                oldPassword = current.oldPass,
                userId = apiUserStore.current.userId,
            )
            if (result != null) {
                alertOverlayStore.show(
                    Overlay.NotificationToast(
                        notificationType = NotificationType.Alert,
                        durationSeconds = 4,
                        text = translation[TL.AlertNotifications.PW_CHANGE_SUCCESSFUL],
                        bgColor = FormationColors.GreenActive.color,
                    ),
                )
                analyticsService.createEvent(
                    AnalyticsCategory.Password,
                ) {
                    recordAction("password-changed")
                }

                return@handle ValidatePassword(responseStatus = StatusResult.Success)
            } else {
                alertOverlayStore.show(
                    Overlay.NotificationToast(
                        notificationType = NotificationType.Alert,
                        durationSeconds = 4,
                        text = translation[TL.AlertNotifications.PW_CHANGE_ERROR],
                        bgColor = FormationColors.RedError.color,
                    ),
                )
                analyticsService.createEvent(
                    AnalyticsCategory.Password,
                ) {
                    recordAction("password-change-failed", label = error)
                }
                return@handle current.copy(responseStatus = StatusResult.Failure("${translation.getString(TL.AlertNotifications.PW_CHANGE_ERROR)} $error"))
            }
        } else {
            alertOverlayStore.show(
                Overlay.NotificationToast(
                    notificationType = NotificationType.Alert,
                    durationSeconds = 4,
                    text = translation[TL.AlertNotifications.PWS_DO_NOT_MATCH], // flowOf(current.passwordEval?.description)
                    bgColor = FormationColors.RedError.color,
                ),
            )
            analyticsService.createEvent(
                AnalyticsCategory.Password,
            ) {
                recordAction("password-mismatch")
            }
            return@handle current.copy(responseStatus = StatusResult.Failure(translation.getString(TL.AlertNotifications.PWS_DO_NOT_MATCH)))
        }
    }

    val validateCurrentPassword = handle { current ->
        val result = apiUserStore.current.apiUser?.emails?.firstOrNull()?.let { email ->
            apiUserStore.current.apiUser?.workspaceName?.let { workspace ->
                userService.validatePassword(workspace = workspace, email = email, password = current.oldPass)
            }
        } ?: return@handle current.copy(
            responseStatus = StatusResult.Failure(translation.getString(TL.AlertNotifications.USER_NOT_LOGGED_IN)),
        )
        return@handle result.fold(
            {
                ValidatePassword(oldPass = current.oldPass, responseStatus = StatusResult.Success)
            },
            {
                alertOverlayStore.show(
                    Overlay.NotificationToast(
                        notificationType = NotificationType.Alert,
                        durationSeconds = 4,
                        text = translation[TL.AlertNotifications.CURRENT_PW_NOT_CORRECT],
                        bgColor = FormationColors.RedError.color,
                    ),
                )
                current.copy(responseStatus = StatusResult.Failure(translation.getString(TL.AlertNotifications.CURRENT_PW_NOT_CORRECT)))
            },
        )
    }
    val updateValidation = handle { current ->
        val evaluation = evaluatePassword(current.firstPass)
        if (current.secondPass != current.firstPass) {
            return@handle current.copy(passwordEval = evaluation, isMatching = false, isValid = false)
        }
        return@handle current.copy(passwordEval = evaluation, isMatching = true, isValid = !evaluation.reject())
    }

}
