package maplibreGL.renderer

import apiclient.geoobjects.LatLon
import apiclient.geoobjects.ObjectType
import dev.fritz2.core.Id
import koin.koinCtx
import maplibreGL.MaplibreMap
import maplibreGL.MaplibreMarkerOptions
import maplibreGL.Marker
import maplibreGL.getDomElement
import maplibreGL.toJs
import maplibreGL.toLngLat
import org.w3c.dom.events.EventListener
import svgmarker.SvgIconOptions
import svgmarker.makeSvgMarker
import theme.FormationIcons

class TemporaryMarkerRender {

    val maplibreMap: MaplibreMap by koinCtx.inject()

    val map get() = maplibreMap.map

    private var coordinatePointers: MutableMap<String, Marker> = mutableMapOf()

    fun insertTemporaryMarker(title: String, coordinates: LatLon) {
        val newId = "coordinate-${Id.next(9)}"
        val renderContext = maplibreMap.getMapRenderContext() ?: return

        val svgIconOptions = SvgIconOptions(
            icon = FormationIcons.Location,
        )

        val coordinateMarkerEventListener = EventListener { event ->
            event.stopPropagation()
            coordinatePointers[maplibreMap.getTargetElementIdFromEvent(event)]?.remove()
        }

        val markerOptions = MaplibreMarkerOptions(
            element = getDomElement(newId, renderContext) {
                makeSvgMarker(
                    objectId = newId,
                    objectType = ObjectType.POI,
                    title = title,
                    svgIconOptions = svgIconOptions,
                    active = false,
                    showTitle = true,
                )
            }.also { element ->
                element.addEventListener("click", coordinateMarkerEventListener)
            },
        )
        val marker = Marker(markerOptions.toJs())
        marker.setLngLat(coordinates.toLngLat())
        coordinatePointers[newId] = marker
        marker.addTo(map)
    }
}
