package mainmenu.about

import data.users.settings.LocalSettingsStore
import dev.fritz2.components.compat.p
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.core.RenderContext
import koin.koinCtx
import localization.TL
import localization.Translation
import mainmenu.Pages
import overlays.SplashOverlayStore
import styling.secondaryButtonStyleParams
import theme.FormationColors
import theme.FormationIcons
import webcomponents.baseLayout
import webcomponents.twContentScrollBox
import webcomponents.mainTitle
import webcomponents.oneButtonFooter
import webcomponents.twoButtonFooter

fun RenderContext.pageAcceptCookies(acceptDest: Map<String, String>? = Pages.Login.route, rejectDest: Map<String, String> = Pages.AppPaused.route) {
    val splashOverlayStore: SplashOverlayStore by koinCtx.inject()
    val localSettingsStore: LocalSettingsStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()

    baseLayout(
        content = {
            twContentScrollBox {
                className("mt-2")
                flexBox({ flex { grow { "1" } } }) { }

                icon(
                    {
                        size { "4rem" }
                        color { primary.main }
                        css("align-self: center;")
                        flex { grow { "1" } }
                    },
                ) { fromTheme { FormationIcons.Cookie.icon } }

                flexBox(
                    {
                        width { full }
                        justifyContent { center }
                        flex { grow { "1" } }
                    },
                ) { mainTitle(translation[TL.Cookie.QUERY]) }

                p(
                    {
                        textAlign { center }
                    },
                ) { translation[TL.Cookie.DISCLAIMER].renderText(into = this) }

                flexBox({ flex { grow { "1" } } }) { }
            }
        },
        footer = {
            twoButtonFooter(
                primaryTitle = translation[TL.Cookie.ACCEPT],
                primaryRoutingMap = acceptDest,
                primaryValue = Unit,
                primaryClickHandlers = listOf(splashOverlayStore.showAppStartSplash, localSettingsStore.acceptCookies),
                secondaryTitle = translation[TL.Cookie.REJECT],
                secondaryRoutingMap = rejectDest,
            )
        },
    )
}

fun RenderContext.pageAppPaused(acceptDest: Map<String, String> = Pages.Login.route) {
    val splashOverlayStore: SplashOverlayStore = koinCtx.get()
    val localSettingsStore: LocalSettingsStore = koinCtx.get()
    val translation: Translation by koinCtx.inject()

    baseLayout(
        content = {
            twContentScrollBox {
                className("mt-2")
                flexBox({ flex { grow { "1" } } }) { }

                icon(
                    {
                        size { "4rem" }
                        color { FormationColors.GrayPrivate.color }
                        css("align-self: center;")
                        flex { grow { "1" } }
                    },
                ) { fromTheme { FormationIcons.Cookie.icon } }

                flexBox(
                    {
                        width { full }
                        justifyContent { center }
                        flex { grow { "1" } }
                    },
                ) { mainTitle(translation[TL.Cookie.REJECT_TITLE]) }

                p(
                    {
                        textAlign { center }
                    },
                ) { translation[TL.Cookie.REJECT_WARNING].renderText(into = this) }
                br { }
                p(
                    {
                        textAlign { center }
                    },
                ) { translation[TL.Cookie.QUERY].renderText(into = this) }

                flexBox({ flex { grow { "1" } } }) { }
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.Cookie.ACCEPT],
                routingMap = acceptDest,
                styleParams = secondaryButtonStyleParams,
                value = Unit,
                clickHandlers = listOf(splashOverlayStore.showAppStartSplash, localSettingsStore.acceptCookies),
            )
        },
    )
}
