package mainmenu.about

import dev.fritz2.components.compat.a
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.href
import dev.fritz2.core.target
import koin.koinCtx
import kotlinx.coroutines.flow.flowOf
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import mainmenu.mainMenuButton
import theme.FormationIcons
import webcomponents.baseLayout
import webcomponents.twContentScrollBox
import webcomponents.mainTitle
import webcomponents.oneButtonFooter

fun RenderContext.pageHelp() {
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()

    baseLayout(
        header = {
            mainTitle(translation[TL.MainMenu.HELP])
        },
        content = {
            twContentScrollBox {
                // TODO insert stuff here
                helpContent()
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.CLOSE],
                value = Unit,
                clickHandlers = listOf(routerStore.back),
            )
        },
    )
}

fun RenderContext.helpContent() {
    val translation: Translation by koinCtx.inject()

    stackUp(
        {
            width { full }
            alignItems { stretch }
            justifyContent { center }
        },
    ) {
        spacing { small }
        items {
            a(
                {
                    color { primary.main }
                    hover { color { primary.main } }
                    margin { none }
                },
            ) {
                href("https://tryformation.com/formation4ukraine")
                target("_blank")
                mainMenuButton(
                    icon = { FormationIcons.Formation4UkraineLogo.icon },
                    titleLiteral = flowOf("FORMATION 4 Ukraine"),
                    subtitle = translation[TL.PageHelp.FORMATION4UKRAINE_WEBSITE_DESC],
                )
            }
        }
    }
}
