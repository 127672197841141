package twcomponents

import com.tryformation.localization.Translatable
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import kotlinx.browser.document
import kotlinx.coroutines.flow.Flow
import localization.translate
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.MutationObserver
import org.w3c.dom.MutationObserverInit
import org.w3c.dom.Node
import theme.IconEnum
import utils.handleFunctions
import webcomponents.baseLayout

fun RenderContext.twContentScrollBox(content: (HtmlTag<HTMLDivElement>.() -> Unit)? = null) {
    div("flex flex-col w-full h-full items-stretch justify-start overflow-y-auto") {
        content?.invoke(this)
    }
}

fun twSimpleModalWithCloseHeader(
    additionalClasses: String? = null,
    title: Translatable? = null,
    titleAsFlow: Flow<String>? = null,
    icon: IconEnum? = null,
    toggleStore: Store<Boolean>,
    closeHandlers: List<SimpleHandler<Unit>>? = null,
    content: (HtmlTag<HTMLDivElement>.(closeModal: SimpleHandler<Unit>, modalOpened: Flow<Boolean>, optionalCloseHandlers: List<SimpleHandler<Unit>>?) -> Unit)? = null,
) = twModal(
    toggleStore,
    closeHandlers = closeHandlers,
) { closeModal, modalOpened, optionalCloseHandlers ->
    if (content != null) {
        twModalContentWrapper {
            additionalClasses?.let { className(it) }
            baseLayout(
                header = {
                    twPageHeaderClose(
                        routeToMap = false,
                        additionalBackButtonHandler = handleFunctions {
                            optionalCloseHandlers?.let { handlers ->
                                handlers.forEach {
                                    it.invoke(Unit)
                                }
                            }
                            closeModal(Unit)
                        },
                    ) {
                        twTitle(
                            icon = icon,
                        ) {
                            title?.let { translate(title) } ?: run {
                                titleAsFlow?.let { titleAsFlow.renderText() }
                            }
                        }
                    }
                },
                content = {
                    content(closeModal, modalOpened, optionalCloseHandlers)
                },
            )
        }
    }
}

fun doOnceWhenElementInDOM(elementId: String? = null, domNode: Node? = null, block: () -> Unit) {
    val observer = MutationObserver { _, mutationObserver ->
        domNode?.let {
            if (document.contains(domNode)) {
                block.invoke()
                mutationObserver.disconnect()
            }
        }
        elementId?.let {
            if (document.getElementById(elementId) != null) {
                block.invoke()
                mutationObserver.disconnect()
            }
        }
    }
    observer.observe(
        document,
        MutationObserverInit(attributes = true, childList = true, characterData = false, subtree = true),
    )
}

fun doContinuouslyWhenElementInDOM(elementId: String? = null, domNode: Node? = null, block: () -> Unit) {
    val observer = MutationObserver { _, _ ->
        domNode?.let {
            if (document.contains(domNode)) {
                block.invoke()
            }
        }
        elementId?.let {
            if (document.getElementById(elementId) != null) {
                block.invoke()
            }
        }
    }
    observer.observe(
        document,
        MutationObserverInit(attributes = true, childList = true, characterData = false, subtree = true),
    )
}

fun hideElement(elementId: String, message: String? = null) {
    message?.let { console.log(message) }
    (document.getElementById(elementId) as? HTMLElement)?.style?.display = "none"
}

fun showElement(elementId: String, message: String? = null) {
    message?.let { console.log(message) }
    (document.getElementById(elementId) as? HTMLElement)?.style?.display = "block"
}

