package objectrouting

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.ObjectTags
import apiclient.tags.getUniqueTag
import data.objects.building.ActiveBuildingStore
import data.objects.building.CurrentBuildingsStore
import data.objects.building.getBuilding
import data.objects.building.getFloorLevel
import dev.fritz2.core.Store
import koin.koinCtx


fun getFloorIdForNavigationPointSource(navigationPointSource: NavigationPointSource?, latLon: LatLon?, selectedObjectStore: Store<GeoObjectDetails?>): String? {
    val lastKnownPositionStore: LastKnownPositionStore = koinCtx.get()
    val currentBuildingsStore: CurrentBuildingsStore = koinCtx.get()

    return when (navigationPointSource) {
        NavigationPointSource.LastKnownLocation -> {
            when (lastKnownPositionStore.current?.type) {
                PositionType.ScannedObject -> {
                    lastKnownPositionStore.current?.geoObjectDetails?.tags
                        ?.getUniqueTag(ObjectTags.ConnectedTo)
                }

                else -> {
                    currentBuildingsStore.pointWithinFloor(latLon)
                }
            }
        }

        NavigationPointSource.YourLocation,
        NavigationPointSource.MapCenter,
        NavigationPointSource.GpsCoordinates,
        NavigationPointSource.UtmCoordinates,
        NavigationPointSource.MgrsCoordinates -> currentBuildingsStore.pointWithinFloor(latLon)

        NavigationPointSource.OtherObject -> selectedObjectStore.current?.tags?.getUniqueTag(ObjectTags.ConnectedTo)
        else -> null
    }
}

/**
 * Get the floorLevel for the given floorId using level of current selected
 * floor or the building default level as fallback (e.g. when origin is MapCenter or GPS)
 */

fun getLevelFromFloorId(floorId: String? = null): Double? {

    val currentBuildingsStore: CurrentBuildingsStore by koinCtx.inject()
    val activeBuildingsStore: ActiveBuildingStore by koinCtx.inject()

    return (floorId?.let {
        currentBuildingsStore.current.getBuilding(floorId)
    } ?: activeBuildingsStore.current?.let { currentBuildingsStore.current[it] }
        )?.let { building ->
            (building.getFloorLevel(floorId)
                ?: building.floorData?.keys?.filter { it >= 0 }?.min()
                ?: building.defaultFloorLevel)
        }
}
