package location

import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.core.storeOf
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.distinctUntilChanged
import location.geolocation.GeoPosition

//val LatLon.geoPosition get() = GeoPosition(
//    timestamp = Clock.System.now().toEpochMilliseconds(),
//    coords = Coordinates(
//        latitude = lat,
//        longitude = lon,
//        altitude = 0.0,
//        accuracy = 1.0,
//        altitudeAccuracy = 1.0,
//        heading = 0.0,
//        speed = 42.0
//    )
//)

class GeoPositionStore(
    // making this configurable so we can plugin more elaborate location simulation later
    val spoofedLocationStore: Store<GeoPosition?> = storeOf(
        initialData = null,
        job = Job(),
    )
) {
    private val actualPositionStore: ActualGeoPositionStore = ActualGeoPositionStore()

    // if there is a spoofed location use that, otherwise use the real thing
    private val activeStore get() = if (spoofedLocationStore.current == null) actualPositionStore else spoofedLocationStore

    val data: Flow<GeoPosition?>
        get() = combine(
            actualPositionStore.data,
            spoofedLocationStore.data,
        ) { _, _ ->
            activeStore.current
        }.distinctUntilChanged()

    val current: GeoPosition?
        get() = activeStore.current

    val update = actualPositionStore.update
    val reset = actualPositionStore.reset

    // this is the old position store all code paths now go through its spoofing parent
    private class ActualGeoPositionStore : RootStore<GeoPosition?>(
        initialData = null,
        job = Job(),
    ) {

        private val log = SimpleHandler<GeoPosition?> { data, _ ->
            data handledBy { geoPosition ->
                geoPosition?.let { console.log("new position", it.coords.latitude, it.coords.longitude) }
            }
        }

        val reset = handle { current ->
            console.log("Reset GeoPositionStore")
            update(null)
            current
        }

        init {
            data handledBy log
        }
    }
}
