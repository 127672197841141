package twcomponents

import apiclient.users.UserFeatureFlag
import auth.FeatureFlagStore
import auth.Features
import data.users.profile.MyProfileStore
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import koin.withKoin
import model.MyProfile
import model.featureFlags
import org.w3c.dom.HTMLDivElement

fun RenderContext.doIfUserFeatureFlagEnabled(
    flag: UserFeatureFlag,
    skipCheck: Boolean = false,
    elseBlock: (() -> Unit)? = null,
    block: () -> Unit
) = if (skipCheck) {
    block()
} else {
    withKoin {
        get<MyProfileStore>().map(MyProfile.featureFlags()).data.render { flags ->
            if (flags != null && flags[flag] == true) {
                block()
            } else {
                elseBlock?.invoke()
            }
        }
    }
}

fun RenderContext.doIfFeatureFlagEnabled(
    flag: Features,
    skipCheck: Boolean = false,
    elseBlock: (() -> Unit)? = null,
    block: () -> Unit
) = if (skipCheck) {
    block()
} else {
    withKoin {
        get<FeatureFlagStore>().data.render { features ->
            if (features[flag] == true) {
                block()
            } else {
                elseBlock?.invoke()
            }
        }
    }
}

fun RenderContext.renderIfFeatureFlagEnabled(
    flag: Features,
    skipCheck: Boolean = false,
    elseBlock: (HtmlTag<HTMLDivElement>.() -> Unit)? = null,
    block: HtmlTag<HTMLDivElement>.() -> Unit
) = div {
    if (skipCheck) {
        block()
    } else {
        withKoin {
            get<FeatureFlagStore>().data.render { features ->
                if (features[flag] == true) {
                    block()
                } else {
                    if (elseBlock != null) {
                        elseBlock()
                    } else {
                        this@div.domNode.remove()
                    }
                }
            }
        }
    }
}
