package analytics

import apiclient.geoobjects.LatLon
import apiclient.tags.TagEnum
import apiclient.tags.TagList
import apiclient.tags.removeTag
import apiclient.tags.setUniqueTag
import apiclient.tags.tag
import apiclient.websocket.AnalyticsModule
import apiclient.websocket.MessageToServer
import auth.ApiUserStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import koin.koinCtx
import koin.withKoin
import kotlin.math.roundToInt
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import map.MapStateStore
import utils.apiScope

/**
 * Use this to create an analytics event. Events must have a [category] and an [action].
 *
 * [location] and [tags] may be added from the users context. But you can add your own as well.
 *
 * [target] should be used to indicate the subject of the action. If the action is click, what did they click?
 * [label] is informational
 */
suspend fun analyticsEvent(
    category: AnalyticsCategory,
    action: String,
    target: String? = null,
    value: Double? = null,
    label: String? = null,
    tags: TagList = emptyList(),
    location: LatLon? = null,
) {
    withKoin {
        val analyticsService = get<AnalyticsService>()
        analyticsService.createEvent(category) {
            recordAction(
                action = action,
                target = target,
                label = label,
                location = location,
                value = value,
                tags = tags
            )
        }
    }
}

class AnalyticsService : RootStore<Boolean>(
    initialData = true,
    job = Job(),
) {
    // fake store so we can have a handler
    private val am: AnalyticsModule by koinCtx.inject()
    private val apiUserStore: ApiUserStore by koinCtx.inject()

    private val mapStateStore: MapStateStore by koinCtx.inject()

    private var userTags: TagList = listOf()

    private fun TagList?.enrichTags() =
        (this
            ?: listOf()) + userTags + listOfNotNull(mapStateStore.current?.zoom?.let { AnalyticsTags.Zl.tag(it.roundToInt().toString()) })

    private fun MessageToServer.AnalyticsEvent.enrich(): MessageToServer.AnalyticsEvent {
        return this.copy(
            tags = tags.enrichTags(),
            location = mapStateStore.current?.center,
        )
    }

    init {
        apiUserStore.data handledBy {
            refreshUser()
        }
    }

    private val events = mutableListOf<MessageToServer.AnalyticsEvent>()

    suspend fun flush() {
        if (apiUserStore.current.apiUser != null && am.readyToSend) {
            events.forEach {
                am.send(it.enrich())
            }
            events.clear()
        }
    }

    private suspend fun addEvent(event: MessageToServer.AnalyticsEvent) {
        events.add(event)
        flush()
    }

    val analyticsEvent: SimpleHandler<MessageToServer.AnalyticsEvent> = handle { _, event ->
        apiScope.launch {
            addEvent(event)
        }
        true
    }

    suspend fun withAnalytics(
        category: AnalyticsCategory,
        tags: TagList = listOf(),
        block: suspend AnalyticsContext.() -> Unit
    ) {
        val analyticsContext = AnalyticsContext(category, tags)
        block.invoke(analyticsContext)
        analyticsContext.events
            .forEach {
                addEvent(it)
            }
    }

    suspend fun createEvent(
        category: AnalyticsCategory,
        tags: TagList = listOf(),
        block: (suspend AnalyticsContext.() -> Unit)? = null,
    ) {
        val analyticsContext = AnalyticsContext(category, tags)
        block?.invoke(analyticsContext)
        if (analyticsContext.events.isEmpty()) {
            listOf(
                MessageToServer.AnalyticsEvent(
                    category = category.name,
                    action = AnalyticsAction.Click.name,
                    tags = tags,
                ),
            )
        } else {
            analyticsContext.events
        }.onEach { event ->
            addEvent(event)
        }
    }

    private fun refreshUser() {
        val apiUser = apiUserStore.current.apiUser
        if (apiUser != null) {
            userTags = if (apiUser.isAdmin) {
                userTags.setUniqueTag(AnalyticsTags.Adm, "true")
            } else {
                userTags.removeTag(AnalyticsTags.Adm)
            }
            userTags = if (apiUser.isAnonymous) {
                userTags.setUniqueTag(AnalyticsTags.An, "true")
            } else {
                userTags.removeTag(AnalyticsTags.An)
            }
            val formationEmployee = apiUser.emails.firstOrNull() { it.contains("tryformation.com") } != null
            userTags = if (formationEmployee) {
                userTags.setUniqueTag(AnalyticsTags.Int, true)
            } else {
                userTags.removeTag(AnalyticsTags.Int)
            }
            userTags = userTags.removeTag(AnalyticsTags.Gr)
            apiUser.groups.forEach { m ->
                userTags = userTags
                    .setUniqueTag(AnalyticsTags.Gr, m.groupId)
                    .setUniqueTag(AnalyticsTags.Ws, m.groupName)
            }
        }
    }

}

class AnalyticsContext(
    private val category: AnalyticsCategory,
    tags: TagList = listOf()
) {
    private val _tags: MutableList<String> = tags.toMutableList()
    internal val events = mutableListOf<MessageToServer.AnalyticsEvent>()

    fun tags(vararg tags: String?) {
        tags.filterNotNull().forEach {
            if (it.isNotBlank()) {
                _tags.add(it)
            }
        }
    }

    fun search(target: String? = null, value: Double? = null, label: String? = null, tags: TagList = listOf()) {
        val action = AnalyticsAction.Search.name
        recordAction(action, target, value, label, tags)
    }

    fun click(target: String? = null, value: Double? = null, label: String? = null, tags: TagList = listOf()) {
        val action = AnalyticsAction.Click.name
        recordAction(action, target, value, label, tags)
    }

    fun update(
        location: LatLon? = null,
        target: String? = null, value: Double? = null, label: String? = null, tags: TagList = listOf()
    ) {
        val action = AnalyticsAction.Update.name
        events.add(
            MessageToServer.AnalyticsEvent(
                category = category.name,
                action = action,
                location = location,
                target = target,
                value = value,
                label = label,
                tags = _tags + tags,
            ),
        )
    }

    fun on(target: String? = null, value: Double? = null, label: String? = null, tags: TagList = listOf()) {
        val action = AnalyticsAction.ON.name
        recordAction(action, target, value, label, tags)
    }

    fun off(target: String? = null, value: Double? = null, label: String? = null, tags: TagList = listOf()) {
        val action = AnalyticsAction.OFF.name
        recordAction(action, target, value, label, tags)
    }

    fun appStateChange(target: String? = null, value: Double? = null, label: String? = null, tags: TagList = listOf()) {
        val action = AnalyticsAction.AppState.name
        recordAction(action, target, value, label, tags)
    }

    fun recordAction(
        action: String,
        target: String? = null,
        value: Double? = null,
        label: String? = null,
        tags: TagList = emptyList(),
        location: LatLon? = null,
    ) {
        events.add(
            MessageToServer.AnalyticsEvent(
                category = category.name,
                action = action,
                target = target,
                value = value,
                label = label,
                tags = _tags + tags,
                location = location
            ),
        )
    }

    fun exception(e: Throwable, target: String? = null, value: Double? = null, tags: TagList = listOf()) {
        events.add(
            MessageToServer.AnalyticsEvent(
                category = category.name,
                action = AnalyticsAction.Error.name,
                label = e::class.simpleName,
                value = value,
                target = target,
                tags = _tags + listOfNotNull(AnalyticsTags.Exc.tag(e.message)) + tags,
            ),
        )
    }
}

enum class AnalyticsTags : TagEnum {
    // Exception
    Exc,
    Adm,

    // internal FORMATION
    Int,
    Zl,
    Gr,
    Ws,
    Evt,

    // Keyword
    Kw,

    // Anonymous
    An,
    ;

}

enum class AnalyticsAction {
    Click,
    Select,
    Update,
    Open,
    Close,
    ON,
    OFF,
    Error,
    AppState,
    Search,
}

enum class AnalyticsCategory {
    MainMenu,
    Map,
    FloorButton,
    FollowMeButton,
    SharingButton,
    MapLayersSelector,
    ZoomInButton,
    ZoomOutButton,
    AddContentButton,
    ScanContentButton,
    MenuButton,
    HubButton,
    MapButton,
    ScanButton,
    SearchButton,
    CloseButton,
    LocShare,
    LifeCycle,
    CreatePointButton,
    CreateTaskButton,
    CreateEventButton,
    CreateZoneButton,
    CreateEventFromUserCardButton,
    CreateTaskFromUserCardButton,
    ChangePasswordButton,
    QuickUpdateTrackedObjectLocation,
    CreateObjectFromTrackingEvent,
    SendFeedback,
    DiscardFeedback,
    ChangeMyUserStatus,
    ShowSettingsFromMyUserCard,
    ShowSharingOptionsFromMyUserCard,
    SelectSearchLayer,
    AddKeyWord,
    AddObjectType,
    AddAssignee,
    AddCreator,
    DoSearch,
    CreateFromArchetype,
    ObjectTracking,
    NavigationToolButton,
    ObjectScan,
    UserProfile,
    Password,
    VerifyUser,
    LocalSettings,
    UserSettings,
    WorkspaceOptions,
    BuildingEditor,
    AccountManagement,
    ChangeLoginState,
    MeasuringTool,
    ApplicationOpen,
    OpenMarker,
    WebLink,
    Poll,
    TaskTemplate
    ;

    fun provider(
        action: AnalyticsAction,
        label: String? = null,
        value: Double? = null, // numeric values like durations, counts, etc.
        target: String? = null, // target of the action id or similar identifier of an object
        location: LatLon? = null,
        tags: TagList? = null,
    ): () -> MessageToServer.AnalyticsEvent {
        return {
            MessageToServer.AnalyticsEvent(
                category = this.name,
                action = action.name,
                label = label,
                value = value,
                target = target,
                location = location,
                tags = tags,
            )
        }
    }

    fun click(
        label: String? = null,
        value: Double? = null, // numeric values like durations, counts, etc.
        target: String? = null, // target of the action id or similar identifier of an object
        location: LatLon? = null,
        tags: TagList? = null,
    ) = provider(AnalyticsAction.Click, label, value, target, location, tags)

    fun open(
        label: String? = null,
        value: Double? = null, // numeric values like durations, counts, etc.
        target: String? = null, // target of the action id or similar identifier of an object
        location: LatLon? = null,
        tags: TagList? = null,
    ) = provider(AnalyticsAction.Open, label, value, target, location, tags)

    fun close(
        label: String? = null,
        value: Double? = null, // numeric values like durations, counts, etc.
        target: String? = null, // target of the action id or similar identifier of an object
        location: LatLon? = null,
        tags: TagList? = null,
    ) = provider(AnalyticsAction.Close, label, value, target, location, tags)

    fun on(
        label: String? = null,
        value: Double? = null, // numeric values like durations, counts, etc.
        target: String? = null, // target of the action id or similar identifier of an object
        location: LatLon? = null,
        tags: TagList? = null,
    ) = provider(AnalyticsAction.ON, label, value, target, location, tags)

    fun off(
        label: String? = null,
        value: Double? = null, // numeric values like durations, counts, etc.
        target: String? = null, // target of the action id or similar identifier of an object
        location: LatLon? = null,
        tags: TagList? = null,
    ) = provider(AnalyticsAction.OFF, label, value, target, location, tags)

    fun error(
        label: String? = null,
        value: Double? = null, // numeric values like durations, counts, etc.
        target: String? = null, // target of the action id or similar identifier of an object
        location: LatLon? = null,
        tags: TagList? = null,
    ) = provider(AnalyticsAction.Error, label, value, target, location, tags)

}
