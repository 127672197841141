package notifications

import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.map
import localization.TL
import localization.translate
import search.searchInput
import theme.FormationDefault
import twcomponents.twColOf
import twcomponents.twGhostButton
import twcomponents.twMainTitle
import twcomponents.twPageHeaderBack
import twcomponents.twRowOfJustifyBetween
import utils.focusInputObserver
import utils.handleFunctions
import webcomponents.baseLayout
import webcomponents.twContentScrollBox

fun RenderContext.pageNotificationCenter() {

    val notificationSearchInputFieldStore: NotificationSearchInputFieldStore by koinCtx.inject()
    val globalNotificationResultsStore: GlobalNotificationResultsStore by koinCtx.inject()
    val notificationFilterStore: NotificationFilterStore by koinCtx.inject()

    baseLayout(
        header = {
            twPageHeaderBack(
                additionalBackButtonHandler = handleFunctions {
                    notificationFilterStore.reset(Unit)
                    notificationSearchInputFieldStore.reset(Unit)
                },
            ) {
                twColOf {
                    twMainTitle {
                        translate(TL.MainMenu.NOTIFICATION_CENTER)
                    }
                }
            }
            twRowOfJustifyBetween {
                className("pt-2")
                // SEARCH INPUT FIELD
                div(
                    "w-full flex items-center mb-1 gap-3",
                ) {
                    inlineStyle("height: ${FormationDefault.formationStyles.inputHeight};")
                    searchInput("inputNotificationSearch", notificationSearchInputFieldStore)
                    focusInputObserver(
                        { js("document.getElementById('inputNotificationSearch').focus()") },
                        domNode,
                    )
                }
                // Mark as Read button
                globalNotificationResultsStore.data.map { it.unreadNumber }.render { unreadNumber ->
                    if (unreadNumber > 0) twGhostButton(
                        text = TL.Notifications.MARK_ALL_NEW_AS_READ,
                    ) {
                        clicks handledBy globalNotificationResultsStore.markAllAsRead
                    }
                }
            }

        },
        content = {
            twContentScrollBox {
                notificationResultsList(results = globalNotificationResultsStore.data)
            }
        },
        footer = null,
    )
}
