package mainmenu

import dev.fritz2.core.RenderContext
import koin.koinCtx
import localization.TL
import localization.translate
import routing.MainController
import twcomponents.twColOfCenter
import twcomponents.twMainTitle
import twcomponents.twPageHeaderClose
import twcomponents.twPrimaryButton
import twcomponents.twRowOfCenter
import webcomponents.baseLayout
import webcomponents.twContentScrollBox

fun RenderContext.pageSignOut() {
    val mainController: MainController by koinCtx.inject()

    baseLayout(
        header = {
            twPageHeaderClose {
                twMainTitle {
                    translate(TL.PageLogout.CARD_TITLE)
                }
            }
        },
        content = {
            twContentScrollBox {
                twColOfCenter {
                    className("flex-grow")
                    p("text-xl text-center") {
                        translate(TL.PageLogout.ARE_YOU_SURE)
                    }
                }
//                className("mt-2")
//                flexBox(
//                    {
//                        width { full }
//                        margins { vertical { large } }
//                        justifyContent { center }
//                        alignItems { center }
//                        flex { grow { "1" } }
//                    },
//                ) { mainTitle(translation[TL.PageLogout.CARD_TITLE]) }
//                p(
//                    {
//                        margins { vertical { large } }
//                        fontSize { large }
//                        textAlign { center }
//                        flex { grow { "1" } }
//                    },
//                ) { translation[TL.PageLogout.ARE_YOU_SURE].renderText(into = this) }
//                flexBox({ flex { grow { "1" } } }) { }
            }
        },
        footer = {
            twRowOfCenter {
                twPrimaryButton(text = TL.PageLogout.LOGOUT) {
                    clicks handledBy mainController.logout
                }
            }
//            twoButtonFooter(
//                primaryTitle = translation[TL.PageLogout.LOGOUT],
//                primaryValue = Unit,
//                primaryClickHandlers = listOf(mainController.logout),
//                secondaryTitle = translation[TL.PageLogout.STAY],
//                secondaryClickHandlers = listOf(routerStore.back),
//            )
        },
    )
}
