package data.users.views

import camera.cameraWrapper.cameraModal
import camera.photo.photoCamera
import data.objects.views.attachments.FileHandlerStore
import data.objects.views.attachments.FileStoreJS
import data.objects.views.attachments.ImageFileDataStore
import data.users.profile.MyProfileStore
import dev.fritz2.core.RenderContext
import dev.fritz2.core.accept
import dev.fritz2.core.files
import dev.fritz2.core.storeOf
import dev.fritz2.core.type
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import localization.translate
import model.MyProfile
import model.profilePhoto
import org.w3c.files.get
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.twColOfCenter
import twcomponents.twColOfNoGap
import twcomponents.twDeleteButton
import twcomponents.twIconCustomSize
import twcomponents.twMainTitle
import twcomponents.twPageHeaderBack
import twcomponents.twRowOfJustifyBetween
import twcomponents.twRowOfJustifyEnd
import twcomponents.twRowOfJustifyStart
import twcomponents.twSecondaryButton
import utils.isMobileOrTabletBrowser
import webcomponents.baseLayout
import webcomponents.fullCard
import webcomponents.twContentScrollBox
import workspacetools.usermanagement.confirm

fun RenderContext.cardManageProfilePhoto() {

    val translation by koinCtx.inject<Translation>()
    val myProfileStore: MyProfileStore by koinCtx.inject()
    val fileHandlerStore by koinCtx.inject<FileHandlerStore>()
    val fileStoreJS by koinCtx.inject<FileStoreJS>()
    val imageFileDataStore by koinCtx.inject<ImageFileDataStore>()
    val profilePhoto = myProfileStore.map(MyProfile.profilePhoto())

    fileHandlerStore.initialize()

    fullCard {
        baseLayout(
            header = {
                twPageHeaderBack {
                    twMainTitle {
                        translate(TL.UserProfile.PROFILE_PHOTO)
                    }
                }
            },
            content = {
                twContentScrollBox {
                    profilePhoto.data.combine(imageFileDataStore.data) { profilePhoto, uploadedPhoto ->
                        uploadedPhoto.href ?: profilePhoto?.href
                    }.render { imageSrc ->
                        twColOfCenter {
                            className("my-4")
                            // Profile Photo
                            div("flex items-center justify-center text-center grow-0 shrink-0 w-52 h-52 rounded-full ") {
                                if (imageSrc == null) {
                                    className("text-gray-300 border-2 border-gray-300")
                                    twIconCustomSize(icon = FormationIcons.UserAlt, size = "100px")
                                } else {
                                    inlineStyle("--image-url:url(${imageSrc})")
                                    className("bg-[image:var(--image-url)] bg-center bg-cover")
                                }
                            }
                        }
                    }
                    twColOfNoGap {
                        className("gap-4")
                        twRowOfJustifyBetween {
                            // TAKE PHOTO BUTTON FOR MOBILE BROWSER ONLY - Starts devices camera directly (input attr > capture)
                            if (isMobileOrTabletBrowser()) {
                                twSecondaryButton(
                                    text = TL.Attachments.TAKE_A_PHOTO,
                                    icon = FormationIcons.CameraPlus,
                                ) {
                                    className("relative")
                                    input("absolute top-0 bottom-0 left-0 right-0 pl-[100%] opacity-0 m-0 cursor-pointer") {
                                        type("file")
                                        accept("image/png, image/gif, image/jpeg")
                                        attr("capture", "environment")
                                        changes.files().map { files ->
                                            files?.get(0)?.let { file ->
                                                console.log("image picked:", file)
                                                file
                                            }
                                        } handledBy fileStoreJS.update
                                    }
                                }
                            } else {
                                // TAKE PHOTO BUTTON FOR DESKTOP  - Opens camera modal
                                val toggleCamera = storeOf(false, Job())
                                cameraModal(toggleCamera) { close, opened, video, canvas ->
                                    photoCamera(close, opened, video, canvas)
                                }

                                twSecondaryButton(
                                    text = TL.Attachments.TAKE_A_PHOTO,
                                    icon = FormationIcons.CameraPlus,
                                ) {
                                    clicks handledBy {
                                        toggleCamera.update(true)
                                    }
                                }
                            }
                            // UPLOAD IMAGE BUTTON
                            twSecondaryButton(
                                text = TL.Attachments.UPLOAD_IMAGE,
                                icon = FormationIcons.Upload,
                            ) {
                                className("relative overflow-hidden")
                                input("absolute top-0 bottom-0 left-0 right-0 pl-[100%] opacity-0 m-0 cursor-pointer") {
                                    type("file")
                                    accept("image/png, image/gif, image/jpeg")
                                    changes.files().map { files ->
                                        files?.get(0)?.let { file ->
                                            console.log("image picked:", file)
                                            file
                                        }
                                    } handledBy fileStoreJS.update
                                }
                            }
                        }
                        twRowOfJustifyStart {
                            // Revert Button
                            twSecondaryButton(
                                text = TL.General.REVERT,
                                icon = FormationUIIcons.Undo,
                                disabledFlow = imageFileDataStore.data.map { it.prevBytes == null },
                            ) {
                                clicks handledBy {
                                    imageFileDataStore.reset(Unit)
                                }
                            }
                        }
                        twRowOfJustifyEnd {
                            p("text-sm font-bold") {
                                translate(TL.UserProfile.DELETE_PICTURE_SUBTITLE)
                            }
                            // Delete Button
                            twDeleteButton(
                                text = TL.UserProfile.DELETE_PICTURE,
                                icon = FormationIcons.DeleteAlt,
                                disabledFlow = imageFileDataStore.data.map { it.prevBytes != null },
                            ) {
                                clicks handledBy confirm(
                                    translation[TL.UserProfile.DELETE_ARE_YOU_SURE],
                                    listOf(myProfileStore.deleteProfilePhoto),
                                )
                            }
                        }
                    }
                }
            },
            footer = null,
        )
    }
}
