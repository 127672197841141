package measuringTool

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import maplibreGL.renderer.MeasuringToolRender

class MeasuringToolStore : RootStore<Boolean>(false, Job()) {

    private val measuringToolRender: MeasuringToolRender by koinCtx.inject()
    val analyticsService by koinCtx.inject<AnalyticsService>()

    val toggle = handle { current ->
        analyticsService.createEvent(
            AnalyticsCategory.MeasuringTool,
        ) {
            recordAction("toggle", target = "state-${!current}")
        }
        if (!current) {
            measuringToolRender.enableMeasuringToolAndAddLayers()
        } else {
            measuringToolRender.disableMeasuringTool()
        }
        !current
    }

    init {
        measuringToolRender.disableMeasuringTool()
    }
}
