package data.objects.building

import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import objectrouting.NavigationRender

class ActiveFloorLevelStore : RootStore<Double?>(
    initialData = null,
    job = Job(),
)

class ActiveFloorsStore : RootStore<List<String>?>(
    initialData = null,
    job = Job(),
) {
    private val navigationRender: NavigationRender by koinCtx.inject()

    init {
        data handledBy { floors ->
            navigationRender.filterFeaturesByFloorIds(floors)
        }
    }
}

class ActiveBuildingStore : RootStore<String?>(
    initialData = null,
    job = Job(),
)

data class BuildingOverride(
    val buildingId: String,
    val floorId: String,
)

class ActiveBuildingOverrideStore : RootStore<BuildingOverride?>(
    initialData = null,
    job = Job(),
) {
    val reset = handle {
        null
    }
}
