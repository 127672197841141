package qrcode

import apiclient.geoobjects.GeoObjectDetails
import camera.cameraWrapper.cameraModal
import camera.nimiq.qrScanCamera
import data.objects.ActiveObjectStore
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.id
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import dev.fritz2.core.values
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import localization.TL
import localization.Translation
import model.CodeType
import model.L
import model.ScanPurpose
import signup.textButton
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault
import theme.FormationIcons
import twcomponents.twPrimaryButton
import utils.focusInputObserver
import utils.getCase
import utils.getName
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.cardTitle
import webcomponents.twContentScrollBox
import webcomponents.genericInput
import webcomponents.twoButtonFooter

class ScannedCodeTypeStore : RootStore<CodeType?>(
    initialData = null,
    job = Job(),
) {
    val reset = handle { null }
}

class ManualCodeInputStore : RootStore<String>(
    initialData = "",
    job = Job(),
) {
    val reset = handle { "" }
}

fun RenderContext.cardConnectQR() {
    val translation: Translation by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val scannedCodeStore: ScannedCodeStore by koinCtx.inject()
    val scannedCodeTypeStore: ScannedCodeTypeStore by koinCtx.inject()
    val hasCameraStore: HasCameraStore by koinCtx.inject()
    val manualEnterStore: ManualEnterStore by koinCtx.inject()
    val manualCodeInputStore: ManualCodeInputStore by koinCtx.inject()
    val title = activeObjectStore.map(GeoObjectDetails.L.title)
    val objectType = activeObjectStore.map(GeoObjectDetails.L.objectType)

    val connectable = combine(
        scannedCodeStore.data,
        scannedCodeTypeStore.data,
    ) { c, t -> c.extOrIntObjIdOrActionId != null && t == CodeType.Unused }
    baseLayout(
        header = {
            cardTitle(title = title.data) { FormationIcons.QRCode.icon }
        },
        content = {
            twContentScrollBox {
                stackUp(
                    {
                        height { full }
                        padding { small }
                        radius(FormationDefault.formationStyles.buttonRadius)
                        alignItems { center }
                    },
                ) {
                    spacing { larger }
                    items {
                        span {
                            translation[
                                TL.CodeScanning.CONNECT_CODE_TO,
                                mapOf(
                                    "case" to objectType.current.getCase(),
                                    "objectType" to objectType.current.getName(),
                                ),
                            ].renderText(into = this)
                        }
                        scannedCodeStore.data.render { scannedCode ->
                            scannedCodeTypeStore.data.render { codeType ->
                                flexBox({ flex { grow { "1" } } }) { }
                                if (scannedCode.extOrIntObjIdOrActionId != null && codeType != null) {
                                    span {
                                        translation[TL.CodeScanning.SCANNED_CODE].renderText(into = this)
                                    }
                                    cardTitle(flowOf(scannedCode.extOrIntObjIdOrActionId))
                                    flexBox({ flex { grow { "1" } } }) { }
                                    if (codeType == CodeType.Unused) {
                                        span(
                                            {
                                                color { FormationColors.GreenActive.color }
                                            },
                                        ) {
                                            translation[codeType.message].renderText(into = this)
                                        }
                                    } else {
                                        // message about code usage
                                        span(
                                            {
                                                color { FormationColors.RedError.color }
                                                margins { bottom { small } }
                                            },
                                        ) {
                                            translation[codeType.message].renderText(into = this)
                                        }
                                        cardSubtitle(translation[TL.CodeScanning.CODE_ALREADY_IN_USE])
                                    }
                                    flexBox({ flex { grow { "1" } } }) { }
                                }
                                if (scannedCode.extOrIntObjIdOrActionId == null
                                    || codeType == null
                                    || codeType != CodeType.Unused
                                ) {

                                    val toggleCamera = storeOf(false, Job())
                                    cameraModal(toggleCamera) { close, opened, video, _ ->
                                        qrScanCamera(close, opened, video, ScanPurpose.ConnectQRToObject)
                                    }
                                    twPrimaryButton(
                                        text = TL.CodeScanning.START_SCAN,
                                    ) {
                                        clicks handledBy {
                                            toggleCamera.update(true)
                                        }
                                    }

                                    flexBox({ flex { grow { "1" } } }) { }
                                    // MANUAL CODE INPUT
                                    manualEnterStore.data.render { manualEnter ->
                                        if (manualEnter) {
                                            stackUp(
                                                {
                                                    width { full }
                                                    alignItems { center }
                                                    justifyContent { center }
                                                },
                                            ) {
                                                spacing { small }
                                                items {
                                                    genericInput(
                                                        value = manualCodeInputStore.data,
                                                    ) {
                                                        id("enterCodeManuallyInput")
                                                        placeholder(translation[TL.CodeScanning.INSERT_CODE_MANUALLY])
                                                        inputs.values() handledBy manualCodeInputStore.update
                                                    }
                                                    focusInputObserver(
                                                        { js("document.getElementById('enterCodeManuallyInput').focus()") },
                                                        domNode,
                                                    )
                                                    textButton(
                                                        text = translation[TL.CodeScanning.CHECK_MANUAL_CODE],
                                                        clickHandlers = listOf(scannedCodeStore.checkManuallyEnteredCodeToConnect),
                                                    )
                                                }
                                            }
                                        } else {
                                            flexBox(
                                                {
                                                    width { full }
                                                    height(FormationDefault.formationStyles.inputHeight)
                                                    alignItems { center }
                                                    justifyContent { center }
                                                },
                                            ) {
                                                textButton(
                                                    text = translation[TL.CodeScanning.INSTRUCTIONS_ENTER_MANUALLY],
                                                    clickHandlers = listOf(manualEnterStore.enable),
                                                )
                                            }
                                        }
                                    }
                                    flexBox({ flex { grow { "2" } } }) { }
                                }
                            }
                        }
                    }
                }
            }
        },
        footer = {
            twoButtonFooter(
                secondaryTitle = translation[TL.General.CANCEL],
                secondaryStyleParams = secondaryButtonStyleParams,
                secondaryClickHandlers = listOf(
                    scannedCodeStore.reset,
                    scannedCodeTypeStore.reset,
                    manualEnterStore.reset,
                    manualCodeInputStore.reset,
                ),
                secondaryRoutingMapBackTo = mapOf("show" to "menu"),
                primaryTitle = translation[TL.General.LINK],
                primaryState = connectable,
                primaryStyleParams = primaryButtonStyleParams,
                primaryValue = Unit,
                primaryClickHandlers = listOf(activeObjectStore.connectQRCodeToObject),
            )
        },
    )
}
