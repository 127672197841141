package twcomponents

import analytics.AnalyticsService
import apiclient.websocket.MessageToServer
import com.tryformation.localization.Translatable
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.ScopeContext
import dev.fritz2.core.Store
import dev.fritz2.core.disabled
import dev.fritz2.core.title
import koin.withKoin
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.getTranslationFlow
import localization.translate
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import overlays.AlertOverlayStore
import theme.FormationUIIcons
import theme.IconEnum
import web.navigator.navigator
import webcomponents.Position

/**
 * Base: Icon buttons
 */

fun RenderContext.twSmallIconButton(icon: IconEnum, block: HtmlTag<HTMLButtonElement>.() -> Unit) =
    button("cursor-pointer disabled:text-gray-300") {
        twIconSmall(icon)
        block.invoke(this)
    }

fun RenderContext.twMediumIconButton(icon: IconEnum, block: HtmlTag<HTMLButtonElement>.() -> Unit) =
    button("cursor-pointer disabled:text-gray-300") {
        twIconMedium(icon)
        block.invoke(this)
    }

fun RenderContext.twLargeIconButton(icon: IconEnum, block: HtmlTag<HTMLButtonElement>.() -> Unit) =
    button("cursor-pointer disabled:text-gray-300") {
        twIconLarge(icon)
        block.invoke(this)
    }

/**
 * Icon button style variants
 */

// MEDIUM
fun RenderContext.twMediumIconButtonRed(icon: IconEnum, block: HtmlTag<HTMLButtonElement>.() -> Unit) =
    twMediumIconButton(icon) {
        className("hover:text-red-500")
        block.invoke(this)
    }

fun RenderContext.twMediumIconButtonHighlight(icon: IconEnum, block: HtmlTag<HTMLButtonElement>.() -> Unit) =
    twMediumIconButton(icon) {
        className("hover:text-highlight")
        block.invoke(this)
    }

fun RenderContext.twMediumIconButtonNeutral(icon: IconEnum, block: HtmlTag<HTMLButtonElement>.() -> Unit) =
    twMediumIconButton(icon) {
        className("hover:text-gray-500")
        block.invoke(this)
    }

fun RenderContext.twMediumIconButtonNeutralRounded(icon: IconEnum, block: HtmlTag<HTMLButtonElement>.() -> Unit) =
    twMediumIconButton(icon) {
        className("hover:bg-gray-100 p-2.5 rounded-xl")
        block.invoke(this)
    }

// LARGE
fun RenderContext.twLargeIconButtonNeutral(icon: IconEnum, block: HtmlTag<HTMLButtonElement>.() -> Unit) =
    twLargeIconButton(icon) {
        className("hover:text-gray-500")
        block.invoke(this)
    }

fun RenderContext.twLargeIconButtonNeutralRounded(icon: IconEnum, block: HtmlTag<HTMLButtonElement>.() -> Unit) =
    twLargeIconButton(icon) {
        className("hover:bg-gray-100 p-1.5 rounded-xl")
        block.invoke(this)
    }

fun RenderContext.twLargeIconButtonFilledCircle(icon: IconEnum, block: HtmlTag<HTMLButtonElement>.() -> Unit) =
    twLargeIconButton(icon) {
        className("text-formationBlack bg-gray-300 p-1.5 rounded-full")
        block.invoke(this)
    }

fun RenderContext.twLargeIconButtonDangerRounded(icon: IconEnum, block: HtmlTag<HTMLButtonElement>.() -> Unit) =
    twLargeIconButton(icon) {
        className("hover:bg-gray-100 hover:text-red-500 p-1.5 rounded-xl")
        block.invoke(this)
    }

/**
 * Icon button functional variants
 */
fun RenderContext.twCloseButton(block: (HtmlTag<HTMLButtonElement>.() -> Unit)? = null) =
    twMediumIconButtonNeutral(FormationUIIcons.Close) {
        block?.invoke(this)
    }

fun RenderContext.twLargeCloseButton(block: (HtmlTag<HTMLButtonElement>.() -> Unit)? = null) =
    twLargeIconButtonNeutral(FormationUIIcons.Close) {
        block?.invoke(this)
    }

fun <T> RenderContext.twRevertButton(
    store: Store<T>,
    revertTo: T,
    block: (HtmlTag<HTMLButtonElement>.() -> Unit)? = null
) = twCloseButton {
    store.data.render { current ->
        disabled(current == revertTo)
    }
    clicks handledBy {
        store.update(revertTo)
    }
    block?.invoke(this)
}


/**
 * Base: Icon toggle buttons
 */
fun RenderContext.twMediumIconToggleButton(
    iconTrue: IconEnum,
    iconFalse: IconEnum,
    boolStore: Store<Boolean>,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = button("cursor-pointer disabled:text-gray-300") {
    boolStore.data.render { bool ->
        if (bool) twIconMedium(iconTrue) else twIconMedium(iconFalse)
    }
    block.invoke(this)
}

fun RenderContext.twLargeIconToggleButton(
    iconTrue: IconEnum,
    iconFalse: IconEnum,
    boolStore: Store<Boolean>,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = button("cursor-pointer disabled:text-gray-300") {
    boolStore.data.render { bool ->
        if (bool) twIconLarge(iconTrue) else twIconLarge(iconFalse)
    }
    block.invoke(this)
}

/**
 * Icon toggle button variants
 */
fun RenderContext.twMediumIconToggleButtonNeutral(
    iconTrue: IconEnum,
    iconFalse: IconEnum,
    boolStore: Store<Boolean>,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = twMediumIconToggleButton(iconTrue, iconFalse, boolStore) {
    className("hover:text-gray-500")
    block.invoke(this)
}

fun RenderContext.twMediumIconToggleButtonNeutralRounded(
    iconTrue: IconEnum,
    iconFalse: IconEnum,
    boolStore: Store<Boolean>,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = twMediumIconToggleButton(iconTrue, iconFalse, boolStore) {
    className("hover:bg-gray-100 p-1 rounded-xl")
    block.invoke(this)
}

fun RenderContext.twLargeIconToggleButtonNeutral(
    iconTrue: IconEnum,
    iconFalse: IconEnum,
    boolStore: Store<Boolean>,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = twLargeIconToggleButton(iconTrue, iconFalse, boolStore) {
    className("hover:bg-gray-100")
    block.invoke(this)
}

fun RenderContext.twLargeIconToggleButtonNeutralRounded(
    iconTrue: IconEnum,
    iconFalse: IconEnum,
    boolStore: Store<Boolean>,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = twLargeIconToggleButton(iconTrue, iconFalse, boolStore) {
    className("hover:bg-gray-100 p-1.5 rounded-xl")
    block.invoke(this)
}

// Other Icon buttons
fun RenderContext.twCopyClipboardButton(block: () -> String) =
    twMediumIconButton(FormationUIIcons.Copy) {
        clicks handledBy {
            withKoin {
                val content = block.invoke()
                val alertOverlayStore = get<AlertOverlayStore>()
                navigator.clipboard.writeText(content)
                alertOverlayStore.notify(flowOf("Copied $content"))
            }
        }
    }


fun RenderContext.twCopyClipboardButton(content: String) =
    twMediumIconButton(FormationUIIcons.Copy) {
        clicks handledBy {
            withKoin {
                val alertOverlayStore = get<AlertOverlayStore>()
                navigator.clipboard.writeText(content)
                alertOverlayStore.notify(flowOf("Copied $content"))
            }
        }
    }


/**
 * Base buttons normal
 */
fun RenderContext.twButtonBase(
    text: Translatable? = null,
    translationArgs: Map<String, Any>? = null,
    textAsFlow: Flow<String>? = null,
    icon: IconEnum? = null,
    iconPosition: Position = Position.Left,
    disabledFlow: Flow<Boolean>? = null,
    analyticsEventProvider: (() -> MessageToServer.AnalyticsEvent)? = null,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = withKoin {

    val analyticsService: AnalyticsService = get()

    button(
        "cursor-pointer " +
            "h-10 " +
            "flex flex-row items-center justify-center gap-2 " +
            "disabled:opacity-50 disabled:pointer-events-none " +
            "transition-color duration-300 ease-in-out " +
            "rounded-xl ", //border border-formationBlack
    ) {
        className(if ((text ?: textAsFlow) != null) "py-2 px-4" else "p-2.5")
        disabledFlow?.let { disabled ->
            disabled(disabled)
        }
        if (iconPosition == Position.Left) {
            icon?.let {
                twIconMedium(icon)
            }
        }

        text?.let {
            p("text-sm font-bold truncate") {
                translate(text, translationArgs)
            }
            title(text.getTranslationFlow())
        } ?: run {
            textAsFlow?.let { t ->
                p("text-sm font-bold truncate") {
                    t.renderText(this)
                }
                title(t)
            }
        }

        block.invoke(this)

        if (analyticsEventProvider != null) {
            clicks.map { analyticsEventProvider.invoke() } handledBy analyticsService.analyticsEvent
        }

        if (iconPosition == Position.Right) {
            icon?.let {
                twIconMedium(icon)
            }
        }
    }
}

/**
 * Base button normal variants
 */
fun RenderContext.twPrimaryButton(
    text: Translatable? = null,
    translationArgs: Map<String, Any>? = null,
    fallbackTextAsFlow: Flow<String>? = null,
    icon: IconEnum? = null,
    iconPosition: Position = Position.Left,
    disabledFlow: Flow<Boolean>? = null,
    analyticsEventProvider: (() -> MessageToServer.AnalyticsEvent)? = null,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = twButtonBase(
    text = text,
    translationArgs = translationArgs,
    textAsFlow = fallbackTextAsFlow,
    icon = icon,
    iconPosition = iconPosition,
    disabledFlow = disabledFlow,
    analyticsEventProvider = analyticsEventProvider,
) {
    className("text-formationWhite bg-formationBlack enabled:hover:bg-gray-700")
    block.invoke(this)
}

fun RenderContext.twSecondaryButton(
    text: Translatable? = null,
    translationArgs: Map<String, Any>? = null,
    fallbackTextAsFlow: Flow<String>? = null,
    icon: IconEnum? = null,
    iconPosition: Position = Position.Left,
    disabledFlow: Flow<Boolean>? = null,
    analyticsEventProvider: (() -> MessageToServer.AnalyticsEvent)? = null,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = twButtonBase(
    text = text,
    translationArgs = translationArgs,
    textAsFlow = fallbackTextAsFlow,
    icon = icon,
    iconPosition = iconPosition,
    disabledFlow = disabledFlow,
    analyticsEventProvider = analyticsEventProvider,
) {
    className("text-formationBlack bg-gray-100 enabled:hover:bg-gray-300")
    block.invoke(this)
}

fun RenderContext.twGhostButton(
    text: Translatable? = null,
    translationArgs: Map<String, Any>? = null,
    fallbackTextAsFlow: Flow<String>? = null,
    icon: IconEnum? = null,
    iconPosition: Position = Position.Left,
    disabledFlow: Flow<Boolean>? = null,
    analyticsEventProvider: (() -> MessageToServer.AnalyticsEvent)? = null,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = twButtonBase(
    text = text,
    textAsFlow = fallbackTextAsFlow,
    translationArgs = translationArgs,
    icon = icon,
    iconPosition = iconPosition,
    disabledFlow = disabledFlow,
    analyticsEventProvider = analyticsEventProvider,
) {
    className("text-formationBlack enabled:hover:bg-gray-100")
    block.invoke(this)
}

fun RenderContext.twPrimaryButtonBlue(
    text: Translatable? = null,
    translationArgs: Map<String, Any>? = null,
    icon: IconEnum? = null,
    iconPosition: Position = Position.Left,
    disabledFlow: Flow<Boolean>? = null,
    analyticsEventProvider: (() -> MessageToServer.AnalyticsEvent)? = null,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = twButtonBase(
    text = text,
    translationArgs = translationArgs,
    icon = icon,
    iconPosition = iconPosition,
    disabledFlow = disabledFlow,
    analyticsEventProvider = analyticsEventProvider,
) {
    className("text-formationWhite bg-sky-600 enabled:hover:bg-sky-500")
    block.invoke(this)
}

fun RenderContext.twDeleteButton(
    text: Translatable? = null,
    translationArgs: Map<String, Any>? = null,
    icon: IconEnum? = null,
    iconPosition: Position = Position.Left,
    disabledFlow: Flow<Boolean>? = null,
    analyticsEventProvider: (() -> MessageToServer.AnalyticsEvent)? = null,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = twButtonBase(
    text = text,
    translationArgs = translationArgs,
    icon = icon,
    iconPosition = iconPosition,
    disabledFlow = disabledFlow,
    analyticsEventProvider = analyticsEventProvider,
) {
    className("box-border text-red-500 bg-formationWhite enabled:hover:bg-red-500 enabled:hover:text-formationWhite border border-red-500")
    block.invoke(this)
}

fun RenderContext.twPrimaryWhenActiveButton(
    text: Translatable? = null,
    translationArgs: Map<String, Any>? = null,
    fallbackTextAsFlow: Flow<String>? = null,
    icon: IconEnum? = null,
    iconPosition: Position = Position.Left,
    disabledFlow: Flow<Boolean>? = null,
    activeFlow: Flow<Boolean>? = flowOf(true),
    analyticsEventProvider: (() -> MessageToServer.AnalyticsEvent)? = null,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = twButtonBase(
    text = text,
    translationArgs = translationArgs,
    textAsFlow = fallbackTextAsFlow,
    icon = icon,
    iconPosition = iconPosition,
    disabledFlow = disabledFlow,
    analyticsEventProvider = analyticsEventProvider,
) {
    activeFlow?.let { flow ->
        className(
            flow.map {
                if (it) {
                    "text-formationWhite bg-formationBlack enabled:hover:bg-gray-700"
                } else {
                    "text-formationBlack bg-gray-100 enabled:hover:bg-gray-300"
                }
            },
        )
    }
    block.invoke(this)
}

/**
 * Base buttons small
 */

fun RenderContext.twButtonBaseSmall(
    text: Translatable? = null,
    translationArgs: Map<String, Any>? = null,
    icon: IconEnum? = null,
    iconPosition: Position = Position.Left,
    disabledFlow: Flow<Boolean>? = null,
    analyticsEventProvider: (() -> MessageToServer.AnalyticsEvent)? = null,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = withKoin {

    val analyticsService: AnalyticsService = get()

    button(
        "cursor-pointer " +
            "px-2 py-1 h-8 " +
            "flex flex-row items-center justify-center gap-2 " +
            "disabled:opacity-50 disabled:pointer-events-none " +
            "transition duration-300 ease-in-out " +
            "rounded-xl ",
    ) {
        disabledFlow?.let { disabled ->
            disabled(disabled)
        }
        if (iconPosition == Position.Left) {
            icon?.let {
                twIconSmall(icon)
            }
        }

        text?.let {
            div("flex w-full text-xs font-bold items-center justify-center") {
                translate(text, translationArgs)
            }
        }

        if (analyticsEventProvider != null) {
            clicks.map { analyticsEventProvider.invoke() } handledBy analyticsService.analyticsEvent
        }

        block.invoke(this)

        if (iconPosition == Position.Right) {
            icon?.let {
                twIconSmall(icon)
            }
        }
    }
}

/**
 * Base button small variants
 */

fun RenderContext.twPrimaryButtonSmall(
    text: Translatable? = null,
    translationArgs: Map<String, Any>? = null,
    icon: IconEnum? = null,
    iconPosition: Position = Position.Left,
    disabledFlow: Flow<Boolean>? = null,
    analyticsEventProvider: (() -> MessageToServer.AnalyticsEvent)? = null,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = twButtonBaseSmall(
    text = text,
    translationArgs = translationArgs,
    icon = icon,
    iconPosition = iconPosition,
    disabledFlow = disabledFlow,
    analyticsEventProvider = analyticsEventProvider,
) {
    className("bg-formationBlack text-formationWhite enabled:hover:bg-gray-700")
    block.invoke(this)
}


fun RenderContext.twSecondaryButtonSmall(
    text: Translatable? = null,
    translationArgs: Map<String, Any>? = null,
    icon: IconEnum? = null,
    iconPosition: Position = Position.Left,
    disabledFlow: Flow<Boolean>? = null,
    analyticsEventProvider: (() -> MessageToServer.AnalyticsEvent)? = null,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = twButtonBaseSmall(
    text = text,
    translationArgs = translationArgs,
    icon = icon,
    iconPosition = iconPosition,
    disabledFlow = disabledFlow,
    analyticsEventProvider = analyticsEventProvider,
) {
    className("bg-gray-100 text-formationBlack enabled:hover:bg-gray-300")
    block.invoke(this)
}


fun RenderContext.twCenteredLink(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLAnchorElement>.() -> Unit

) = a(
    baseClass = "cursor-pointer underline text-center w-full block text-sm hover:opacity-25",
    id = id,
    scope = scope,
) {
    content(this)
}


fun RenderContext.twTextLinkButton(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLAnchorElement>.() -> Unit
) = a(
    baseClass = "h-min cursor-pointer underline block text-xs hover:text-highlight break-all",
    id = id,
    scope = scope,
) {
    content(this)
}

fun RenderContext.twCheckButton(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLButtonElement>.() -> Unit
) = button(
    "cursor-pointer text-green-500 fill-green-500 disabled:fill-gray-300 disabled:text-gray-300 hover:shadow-lg transition-shadow duration-300 ease-in-out",
    id,
    scope,
) {
    twIconMedium(FormationUIIcons.Check)
    title(FormationUIIcons.Check.icon.displayName)

    content(this)
}

/**
 * Special button definitions
 */

fun RenderContext.twTagButton(
    text: String,
    icon: IconEnum,
    title: String? = null, // = icon.displayName,
    inverted: Boolean = false,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = button(
    "cursor-pointer h-7.5 hover:border-highlight text-xs pr-2 pl-3 font-bold py-1 border rounded-full " +
        "flex flex-row gap-1 justify-between place-items-center disabled:opacity-50 transition ease-in-out delay-50",
) {
    if (inverted) {
        className("bg-highlight border-highlight text-white hover:bg-white hover:text-highlight")
    } else {
        className("bg-white hover:bg-highlight hover:text-white")
    }
    div {
        +text
    }
    twIconMedium(icon)
    title?.let { title ->
        title(title)
    }
    block.invoke(this)
}

fun RenderContext.twAddContentButton(block: (HtmlTag<HTMLDivElement>.() -> Unit)? = null) =
    div("flex flex-row w-full h-10 items-center justify-center p-2 rounded-xl cursor-pointer hover:text-gray-500") { // border-2 border-dashed border-gray-100 hover:border-formationBlack
        block?.invoke(this)
    }

/**
 * Flat button components
 */
fun RenderContext.twFlatBoxRow(block: HtmlTag<HTMLDivElement>.() -> Unit) =
    div("flex flex-row items-center justify-between max-w-full grow disabled:text-gray-300 bg-gray-100 text-slate-500 overflow-hidden") {
        block.invoke(this)
    }


fun RenderContext.twFlatBoxRowCenter(block: HtmlTag<HTMLDivElement>.() -> Unit) =
    div("flex flex-row items-center justify-center max-w-full grow p-2 disabled:text-gray-300 bg-gray-100 text-slate-500 overflow-hidden") {
        block.invoke(this)
    }


fun RenderContext.twFlatBoxColStart(block: HtmlTag<HTMLDivElement>.() -> Unit) =
    div("flex flex-col items-start justify-center max-w-full grow disabled:text-gray-300 bg-gray-100 text-slate-500") {
        block.invoke(this)
    }


fun RenderContext.twFlatIconBox(block: HtmlTag<HTMLDivElement>.() -> Unit) =
    div("flex items-center justify-center p-3 disabled:text-gray-300 bg-gray-100 text-slate-500") {
        block.invoke(this)
    }


fun RenderContext.twFlatButton(block: HtmlTag<HTMLDivElement>.() -> Unit) =
    div("flex flex-row items-center justify-center max-w-full grow disabled:text-gray-300 bg-gray-100 text-slate-500 cursor-pointer bg-gray-200 hover:bg-gray-300 hover:text-slate-700 overflow-hidden") {
        block.invoke(this)
    }


fun RenderContext.twFlatIconButton(
    icon: IconEnum,
    iconPosition: Position = Position.Right,
    disabled: Flow<Boolean> = flowOf(false),
    block: HtmlTag<HTMLDivElement>.() -> Unit,
) = div("flex items-center justify-center p-3 gap-2 self-stretch") {
    className(
        disabled.map {
            if (it) "bg-transparent border border-gray-300 text-gray-300"
            else "cursor-pointer bg-gray-200 hover:bg-gray-300 text-slate-500 hover:text-slate-700 border border-gray-200 hover:border-gray-300"
        },
    )
    if (iconPosition == Position.Right) block.invoke(this)
    twIconMedium(icon)
    if (iconPosition == Position.Left) block.invoke(this)
}

fun RenderContext.twFlatIconButtonLight(
    icon: IconEnum,
    iconPosition: Position = Position.Right,
    disabled: Flow<Boolean> = flowOf(false),
    block: HtmlTag<HTMLDivElement>.() -> Unit,
) = div("flex items-center justify-center p-3 gap-2") {
    className(
        disabled.map {
            if (it) "bg-transparent border border-gray-100 text-gray-100"
            else "cursor-pointer hover:bg-gray-100 text-slate-500 hover:text-slate-700 border border-transparent hover:border-gray-100"
        },
    )
    if (iconPosition == Position.Right) block.invoke(this)
    twIconMedium(icon)
    if (iconPosition == Position.Left) block.invoke(this)
}


fun RenderContext.twFlatCopyClipboardButton(content: String) =
    twFlatIconButton(FormationUIIcons.Copy) {
        clicks handledBy {
            withKoin {
                val alertOverlayStore = get<AlertOverlayStore>()
                navigator.clipboard.writeText(content)
                alertOverlayStore.notify(flowOf("Copied $content"))
            }
        }
    }



