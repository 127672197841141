package websocket

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.TaskState
import apiclient.geoobjects.TranslationTags
import apiclient.localizations.LocalizationArg
import apiclient.localizations.TranslationKey
import apiclient.tags.getUniqueTag
import apiclient.validations.parseEnumValue
import data.objects.ActiveObjectStore
import data.objects.emptyGeoObjectDetails
import dev.fritz2.core.RootStore
import koin.FlowQualifiers
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.mapNotNull
import model.L
import model.NotificationType
import model.Overlay
import notifications.GlobalNotificationResultsStore
import org.koin.core.qualifier.named
import overlays.AlertOverlayStore
import search.searchlayer.MapSearchClientsStore
import theme.FormationColors
import utils.getMessage

class NotificationClientStore : RootStore<GeoObjectDetails>(
    initialData = emptyGeoObjectDetails,
    job = Job(),
) {

    private val websocketNotifications: Flow<GeoObjectDetails> = koinCtx.get(named(FlowQualifiers.WebsocketNotifications))
    private val globalNotificationResultsStore: GlobalNotificationResultsStore by koinCtx.inject()
    private val mapSearchClientsStore: MapSearchClientsStore by koinCtx.inject()
    private val alertOverlayStore: AlertOverlayStore by koinCtx.inject()
    private val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    private val taskState = activeObjectStore.map(GeoObjectDetails.L.taskState)

    val initialize = handle { it }

    private fun updateTaskState(notificationObj: GeoObjectDetails) {
        if (activeObjectStore.current.id == notificationObj.connectedTo) {
            parseEnumValue<TaskState>(notificationObj.tags.getUniqueTag(LocalizationArg.TASK_STATUS))?.let { newTaskState ->
                taskState.update(newTaskState)
            }
        }
    }

    private val processNotification = handle<GeoObjectDetails> { _, notificationObj ->
        console.log("new incoming notification $notificationObj")

//        try {
//            window.navigator.vibrate(intArrayOf(200, 100, 200).asDynamic())
//        } catch (e: Exception) {
//            console.log("Failed to vibrate. Device not supporting it?", e.message)
//        }

        globalNotificationResultsStore.insertNewNotification(notificationObj)
        parseEnumValue<ObjectType>(notificationObj.tags.getUniqueTag(LocalizationArg.OBJECT_TYPE))?.let { connectedObjType ->
            if (connectedObjType == ObjectType.Task) {
                updateTaskState(notificationObj)
            }
            mapSearchClientsStore.invalidateLayerCaches(connectedObjType)
        }
        alertOverlayStore.show(
            Overlay.NotificationToast(
                notificationType = NotificationType.Alert,
                durationSeconds = 5,
                text = parseEnumValue<TranslationKey>(notificationObj.tags.getUniqueTag(TranslationTags.TitleTranslationKey))?.getMessage(notificationObj),
                bgColor = FormationColors.BlueLight.color,
                textColor = FormationColors.BlueDeep.color,
//              icon = it.tags.iconCategory
                geoObject = notificationObj,
            ),
        )
        notificationObj
    }

    val reset = handle {
        console.log("reset NotificationClientStore")
        emptyGeoObjectDetails
    }

    init {
        console.log("NotificationClientStore initialized")
        websocketNotifications handledBy processNotification
        globalNotificationResultsStore.data.mapNotNull {
            if (current.id.isNotBlank()) {
                if (globalNotificationResultsStore.isOrHasUnreadNotification(current)) null else return@mapNotNull Unit
            } else null
        } handledBy reset
    }
}
