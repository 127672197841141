package data.objects.views.directediting

import com.jillesvangurp.geojson.urlEncode
import dev.fritz2.core.Store
import kotlinx.coroutines.flow.Flow
import localization.TL
import localization.getTranslationFlow
import org.w3c.dom.asList
import org.w3c.dom.svg.SVGElement
import qrcodegeneration.toSvgQrCode
import theme.FormationUIIcons
import twcomponents.twColOfNoGap
import twcomponents.twFlatBoxRowCenter
import twcomponents.twFlatCopyClipboardButton
import twcomponents.twFlatIconBox
import twcomponents.twIconMedium
import twcomponents.twRowOfNoGap
import twcomponents.twSimpleModalWithCloseHeader

fun showActionCodeModal(
    title: Flow<String> = TL.ActionCode.LINKED_ACTION_CODE.getTranslationFlow(),
    toggleModalStore: Store<Boolean>,
    actionCodeFlow: Flow<String>,
) {
    twSimpleModalWithCloseHeader(
        titleAsFlow = title,
        toggleStore = toggleModalStore,
    ) { _, _, _ ->
        twColOfNoGap("gap-4 items-stretch justify-center") {
            // If action code is entered, use code together with destination latLon to add a RouteToX action code to the origin object
            actionCodeFlow handledBy { actionCode ->
                // Show Action QR Code
                if (actionCode.isNotBlank()) {
                    val link = "https://app.tryformation.com/#id=${actionCode.urlEncode()}"
                    val svgContent = toSvgQrCode(link)

                    div("flex max-h-max w-full px-4 object-scale-down") {
                        domNode.innerHTML = svgContent
                    }.also {
                        this.domNode.children.asList().firstOrNull { it is SVGElement }.also { svg ->
                            svg?.setAttribute("height", "100%")
                            svg?.setAttribute("width", "100%")
                        }
                    }

                    p("text-xs font-mono text-center w-full h-full px-4") { +actionCode }

                    // Show Link to copy
                    twRowOfNoGap("-mx-2 -mb-2") {
                        twFlatIconBox {
                            twIconMedium(FormationUIIcons.Link)
                        }
                        twFlatBoxRowCenter {
                            p("text-xs") {
                                +link
                            }
                        }
                        twFlatCopyClipboardButton(link)
                    }
                }
            }
        }
    }
}
