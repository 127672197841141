package camera.photo

import camera.cameraWrapper.CameraDevicesStore
import camera.cameraWrapper.SelectedCameraDeviceStore
import camera.cameraWrapper.cameraControls
import data.objects.views.attachments.FileStoreFritz2
import data.objects.views.attachments.base64ToArrayBuffer
import dev.fritz2.components.File
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.datetime.Clock
import localization.TL
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.HTMLVideoElement
import org.w3c.dom.MediaProvider
import theme.FormationIcons
import twcomponents.twPrimaryButton

@Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE", "CAST_NEVER_SUCCEEDS")
fun RenderContext.photoCamera(
    closeModal: SimpleHandler<Unit>,
    modalOpened: Flow<Boolean>,
    videoElement: HTMLVideoElement?,
    canvasElement: HTMLCanvasElement?
) {

    // transform video element
//    videoElement?.style?.transform = "scaleX(-1)"

    val devicesStore: CameraDevicesStore by koinCtx.inject()
    val selectedCameraDeviceStore: SelectedCameraDeviceStore by koinCtx.inject()
    val fileStoreFritz2: FileStoreFritz2 by koinCtx.inject()

    modalOpened handledBy { opened ->
        console.log("Modal open:", opened, "Devices:", devicesStore.current.toTypedArray(), "Selected:", selectedCameraDeviceStore.current.toString())
        if (opened) {
            val aspectRatio = if (videoElement != null && videoElement.clientWidth > 0 && videoElement.clientHeight > 0) {
                videoElement.clientWidth.toDouble() / videoElement.clientHeight.toDouble()
            } else null
            devicesStore.fetchCameras(aspectRatio)
        } else {
            devicesStore.reset(Unit)
            selectedCameraDeviceStore.stopTracksAndReset()
        }
    }

    selectedCameraDeviceStore.data handledBy { selectedCamera ->
        selectedCamera?.mediaStream?.let { stream ->
            videoElement?.srcObject = stream as MediaProvider
            videoElement?.onloadedmetadata = {
                videoElement?.play()
            }
        }
    }

    cameraControls(videoElement)

    twPrimaryButton(
        text = TL.Attachments.TAKE_A_PHOTO,
        icon = FormationIcons.CameraPlus,
    ) {
        clicks handledBy {
            makeFileFromDataUrl(
                getImageDataUrlFromVideoElement(videoElement, canvasElement),
            )?.let { imageFile ->
                fileStoreFritz2.update(imageFile)
            }
            closeModal(Unit)
        }
    }
}

fun makeFileFromDataUrl(imageDataURL: String?): File? {

    console.log("takeFileFromBrowserCameraPhoto:", imageDataURL)

    if (!imageDataURL.isNullOrBlank()) {
        val index = imageDataURL.indexOf("base64,")
        val content = if (index > -1) imageDataURL.substring(index + 7) else ""
        val type = if (index > -1) imageDataURL.substring(5, index - 1) else ""
        if (content.isNotBlank()) {
            val file = File(
                name = "image-${Clock.System.now().toEpochMilliseconds()}.png",
                size = base64ToArrayBuffer(content).byteLength.toLong(),
                type = type,
                content = content,
            )
            return file
        } else {
            console.log("content from imageURL is blank:", content)
            return null
        }
    } else {
        console.log("imageURL from photo is null or blank:", imageDataURL)
        return null
    }
}

fun getImageDataUrlFromVideoElement(video: HTMLVideoElement?, canvas: HTMLCanvasElement?): String? {
    if (video != null && canvas != null) {
        with(canvas) {
            run {
                this.width = video!!.videoWidth
                this.height = video!!.videoHeight
                (this.getContext("2d") as CanvasRenderingContext2D).drawImage(
                    image = video!!,
                    dx = 0.0,
                    dy = 0.0,
                    dw = this.width.toDouble(),
                    dh = this.height.toDouble(),
                )
                val imageURL = this.toDataURL("image/png")
                return imageURL
            }
        }
    } else {
        console.log("video or canvas element is null")
        return null
    }
}
