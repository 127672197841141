package data.objects.views

import apiclient.customfields.parseFieldValues
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.MeetingInvitationStatus
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.extractAddress
import apiclient.geoobjects.pointCoordinates
import apiclient.geoobjects.tagList
import apiclient.tags.getUniqueTag
import apiclient.util.isNotNullOrEmpty
import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import com.jillesvangurp.geo.GeoGeometry.Companion.roundDecimals
import com.jillesvangurp.geo.toMgrs
import com.jillesvangurp.geo.toUtmOrUps
import data.objects.ActiveObjectStore
import data.objects.building.CurrentBuildingsStore
import data.objects.views.cardinfo.colorDot
import data.users.UserListStore
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Tag
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Icons
import koin.koinCtx
import kotlinx.browser.window
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import localization.translate
import mainmenu.RouterStore
import model.L
import model.NotificationType
import model.Overlay
import model.User
import model.userId
import org.w3c.dom.HTMLElement
import overlays.AlertOverlayStore
import qrcodegeneration.toSvgQrCode
import search.nestedObjects.NestedObjectsResultsStore
import search.separationLine
import svgmarker.MarkerSize
import svgmarker.pointSvgIconOptions
import svgmarker.renderSvgIcon
import theme.FormationColors
import theme.FormationIcons
import theme.FormationUIIcons
import theme.IconEnum
import twcomponents.twPageHeaderBack
import twcomponents.twTitle
import utils.extractReadOnlyTags
import utils.formatDateTime
import utils.getIcon
import utils.getName
import utils.getTitle
import utils.isSameTime
import utils.merge
import utils.mergeTagLists
import utils.respectFeatureFlags
import utils.toKeyWordTagsList
import webcomponents.KeywordTagActionType
import webcomponents.KeywordTagSize
import webcomponents.KeywordTagType
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.ellipseText
import webcomponents.keywordTagList
import webcomponents.readOnlyTextArea
import webcomponents.selectorButton
import webcomponents.selectorContent
import webcomponents.twContentScrollBox
import workspacetools.usermanagement.urlEncode

fun RenderContext.cardDetails() {
    val routerStore: RouterStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val userListStore: UserListStore by koinCtx.inject()
    val nestedObjectsResultsStore: NestedObjectsResultsStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val currentUserId = apiUserStore.map(User.userId())
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val objectId = activeObjectStore.map(GeoObjectDetails.L.id)
    val title = activeObjectStore.map(GeoObjectDetails.L.title)
    val description = activeObjectStore.map(GeoObjectDetails.L.description)
    val objectType = activeObjectStore.map(GeoObjectDetails.L.objectType)
    val createTime = activeObjectStore.map(GeoObjectDetails.L.createdAt)
    val updateTime = activeObjectStore.map(GeoObjectDetails.L.updatedAt)
    val updatedBy = userListStore.getPublicUserProfile(activeObjectStore.map(GeoObjectDetails.L.updatedBy).current)
    val owner = activeObjectStore.map(GeoObjectDetails.L.owner)
    val position = activeObjectStore.map(GeoObjectDetails.L.latLon)
    val taskState = activeObjectStore.map(GeoObjectDetails.L.taskState)
    val keywords = activeObjectStore.map(GeoObjectDetails.L.keywords)
    val color = activeObjectStore.map(GeoObjectDetails.L.color)
    val icon = activeObjectStore.map(GeoObjectDetails.L.iconCategory)
    val shape = activeObjectStore.map(GeoObjectDetails.L.shape)
    val meetingInvitationStatus = activeObjectStore.map(GeoObjectDetails.L.attendees).data.map { attendees ->
        attendees?.find { it.userId == currentUserId.current }?.meetingInvitationStatus
    }
    val assigneeId = activeObjectStore.map(GeoObjectDetails.L.assignedTo)
    val assignee = assigneeId.data.map { userListStore.getPublicUserProfile(it) }
    val attendees = activeObjectStore.map(GeoObjectDetails.L.attendees)
    val currentBuildingsStore: CurrentBuildingsStore by koinCtx.inject()
    val floorData =
        currentBuildingsStore.data.combine(objectId.data) { buildings, objId -> buildings[objId]?.floorData }
    val attribution =
        activeObjectStore.map(GeoObjectDetails.L.tags).data.map { tags -> tags.getUniqueTag(ObjectTags.Attribution) }
    val readOnlyTags = activeObjectStore.map(GeoObjectDetails.L.tags).data.extractReadOnlyTags()
    val currentWorkspaceStore: CurrentWorkspaceStore by koinCtx.inject()
    val fieldValueTags = activeObjectStore.map(GeoObjectDetails.L.tags).data.respectFeatureFlags().map { tags ->
        currentWorkspaceStore.current?.fieldDefinitions?.let { definitions ->
            tags.parseFieldValues(definitions)
        }
    }.map { it?.toKeyWordTagsList(KeywordTagActionType.DefaultFieldValue) ?: emptyList() }
    val externalId = activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getUniqueTag(ObjectTags.ExternalId) }
    val address = activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.extractAddress() }

    baseLayout(
        header = {
            twPageHeaderBack(
                backButtonToolTipPlacement = PlacementValues.left,
            ) {
                twTitle {
                    translate(TL.CardSecondaryMenu.DETAILS)
                }
            }
        },
        content = {
            twContentScrollBox {
                objectType.data.render { objType ->
                    detailsSection(topMargin = false) {
                        // ICON & TYPE
                        when (objType) {
                            ObjectType.POI, ObjectType.ObjectMarker -> {
                                detailsSection(separation = false) {
                                    lineUp(
                                        {
                                            alignItems { center }
                                            justifyContent { flexStart }
                                        },
                                    ) {
                                        spacing { tiny }
                                        items {
                                            combine(color.data, icon.data, shape.data) { color, icon, shape ->
                                                Triple(color, icon, shape)
                                            }.render { (color, icon, shape) ->
                                                renderSvgIcon(
                                                    svgIconOptions = pointSvgIconOptions(
                                                        size = MarkerSize.XS,
                                                        color = color,
                                                        icon = icon,
                                                        shape = shape,
                                                    ),
                                                )
                                            }
                                            cardSubtitle(title = objectType.data.map { it.getName() })
                                        }
                                    }
                                }
                            }

                            else -> {
                                detailsSection(separation = false) {
                                    cardSubtitle(
                                        title = objectType.data.map { it.getName() },
                                        iconFlow = objectType.data.map { it.getIcon() },
                                    )
                                }
                            }
                        }
                        // TYPE DEPENDENT CONTENT
                        when (objType) {
                            // TASK -> STATUS & ASSIGNMENT
                            ObjectType.Task -> {
                                combine(assignee, apiUserStore.data) { assigneeProfile, currentUser ->
                                    translation[
                                        TL.CardInfo.ASSIGNED_TO,
                                        mapOf(
                                            "user" to (
                                                assigneeProfile?.let { profile ->
                                                    if (profile.userId == currentUser.userId) translation.getString(
                                                        TL.General.YOU, mapOf("case" to "accusative"),
                                                    )
                                                    else "${profile.firstName} ${profile.lastName}"
                                                } ?: translation.getString(TL.General.NO_ONE)
                                                ),
                                        ),
                                    ]
                                }.render { string ->
                                    detailsSection(separation = false) {
                                        cardSubtitle(
                                            string,
                                            iconFlow = flowOf(FormationIcons.UserAlt),
                                        )
                                    }
                                }
                                detailsSection(separation = false) {
                                    cardSubtitle(
                                        translation[TL.CardInfo.STATUS].merge(
                                            taskState.data.map {
                                                it?.getTitle() ?: ""
                                            },
                                        ),
                                        iconFlow = taskState.data.map { it?.getIcon() },
                                    )
                                }
                            }
                            // MEETING -> STATUS & ATTENDEES
                            ObjectType.Event -> {
                                meetingInvitationStatus.render { status ->
                                    if (status != null) {
                                        detailsSection(separation = false) {
                                            cardSubtitle(
                                                translation[TL.CardInfo.STATUS].merge(
                                                    meetingInvitationStatus.map {
                                                        it.getName()
                                                    },
                                                ),
                                                iconFlow = meetingInvitationStatus.map { it?.getIcon() },
                                            )
                                        }
                                    }
                                }
                                attendees.data.render { attendeeList ->
                                    val pending = attendeeList?.filter {
                                        it.meetingInvitationStatus == MeetingInvitationStatus.Pending
                                    }?.size
                                    val accepted = attendeeList?.filter {
                                        it.meetingInvitationStatus == MeetingInvitationStatus.Accepted
                                    }?.size
                                    val maybe = attendeeList?.filter {
                                        it.meetingInvitationStatus == MeetingInvitationStatus.Maybe
                                    }?.size
                                    val rejected = attendeeList?.filter {
                                        it.meetingInvitationStatus == MeetingInvitationStatus.Rejected
                                    }?.size
                                    detailsSection(separation = false) {
                                        if (!attendeeList.isNullOrEmpty()) {
                                            lineUp {
                                                spacing { small }
                                                items {
                                                    cardSubtitle(
                                                        translation[TL.CardInfo.ATTENDEES, mapOf("size" to attendeeList.size)],
                                                        iconFlow = flowOf(FormationIcons.UserGroup),
                                                    )
                                                    iconNumber(pending) { FormationIcons.Wait.icon }
                                                    dotNumber(accepted, FormationColors.GreenActive)
                                                    dotNumber(maybe, FormationColors.YellowDoing)
                                                    dotNumber(rejected, FormationColors.RedError)
                                                }
                                            }
                                        } else {
                                            cardSubtitle(
                                                translation[
                                                    TL.CardInfo.ATTENDEES,
                                                    mapOf(
                                                        "size" to translation.getString(TL.General.NONE),
                                                    ),
                                                ],
                                                iconFlow = flowOf(FormationIcons.UserGroup),
                                            )
                                        }
                                    }
                                }
                            }
                            // BUILDING -> FLOORS & CONTAINED OBJECTS)
                            ObjectType.Building, ObjectType.Area, ObjectType.Zone -> {
                                floorData.render { floors ->
                                    if (floors != null) {
                                        detailsSection(separation = false) {
                                            cardSubtitle(
                                                translation[TL.CardInfo.FLOORS]
                                                    .merge(
                                                        flowOf(
                                                            floors.entries.sortedBy { it.key }
                                                                .joinToString(", ") { (lvl, floors) ->
                                                                    "${lvl}: \"${
                                                                        floors.map { floor -> floor.floor.title }
                                                                    }\"${
                                                                        floors.mapNotNull { floor ->
                                                                            if (floor.units.isNotNullOrEmpty()) {
                                                                                floor.units?.let { units ->
                                                                                    if (units.isNotEmpty()) {
                                                                                        " (↪ ${units.size})"
                                                                                    } else ""
                                                                                }
                                                                            } else null
                                                                        }.ifEmpty { "" }
                                                                    }"
                                                                },
                                                        ),
                                                        separator = ": ",
                                                    ),
                                                iconFlow = flowOf(FormationIcons.Floor),
                                            )
                                        }
                                    }
                                }
                                nestedObjectsResultsStore.data.render { nestedObjectData ->
                                    if (nestedObjectData.fetched.isNotEmpty()) {
                                        detailsSection(separation = false) {
                                            cardSubtitle(
                                                translation[TL.CardInfo.CONTAINED_OBJECTS].merge(
                                                    flowOf("${nestedObjectData.total}"),
                                                    separator = ": ",
                                                ),
                                                iconFlow = flowOf(FormationIcons.Boxes),
                                            )
                                        }
                                    }
                                }
                            }

                            else -> {}
                        }
                    }
                }
                // TAGS
                mergeTagLists(
                    readOnlyTags = readOnlyTags,
                    keywords = keywords.data,
                    fieldValueTags = fieldValueTags,
                ).render { tagList ->
                    if (tagList.isNotEmpty()) {
                        detailsSection {
                            lineUp(
                                {
                                    alignItems { center }
                                    justifyContent { flexStart }
                                },
                            ) {
                                spacing { small }
                                items {
                                    cardSubtitle(
                                        flowOf("Tags").merge(flowOf(""), separator = ": "),
                                        iconFlow = flowOf(FormationIcons.Tag),
                                    )
                                    keywordTagList(
                                        keywords = flowOf(tagList),
                                        tagSize = KeywordTagSize.Tiny,
                                        searchable = true,
                                        keywordTagType = KeywordTagType.ObjectTag,
                                    )
                                }
                            }
                        }
                    }
                }
                // TITLE
                detailsSection {
                    cardSubtitle(
                        translation[TL.CardInfo.TITLE].merge(title.data),
                        iconFlow = flowOf(FormationIcons.Title),
                    )
                    // DESCRIPTION
                    description.data.render { desc ->
                        if (!desc.isNullOrBlank()) {
                            detailsSection(separation = false) {
                                cardSubtitle(
                                    translation[TL.General.DESCRIPTION].merge(flowOf(":"), separator = ""),
                                    iconFlow = flowOf(FormationIcons.Description),
                                )
                                readOnlyTextArea(
                                    value = desc,
                                    placeHolder = translation[TL.General.DESCRIPTION],
                                    resizable = true,
                                    height = { maxContent },
                                    margins = { top { small } },
                                )
                            }
                        }
                    }
                }
                // CREATION INFO
                detailsSection {
                    cardSubtitle(
                        translation[TL.CardInfo.CREATED_BY, mapOf("user" to (owner.current?.name ?: ""), "time" to "${createTime.current?.formatDateTime()}")],
                        iconFlow = flowOf(FormationIcons.Create),
                    )
                    // UPDATE INFO
                    createTime.data.combine(updateTime.data) { created, updated ->
                        //FIXME just use string equals?
                        created != null && updated != null && !isSameTime(created, updated)
                    }.render { isUpdated ->
                        if (isUpdated) {
                            detailsSection(separation = false) {
                                updatedBy?.let {
                                    cardSubtitle(
                                        translation[TL.CardInfo.UPDATED_BY, mapOf("user" to it.name, "time" to "${updateTime.current?.formatDateTime()}")],
                                        iconFlow = flowOf(FormationIcons.Edit),
                                    )
                                } ?: run {
                                    cardSubtitle(
                                        translation[
                                            TL.CardInfo.UPDATED_AT,
                                            mapOf(
                                                "time" to (updateTime.current?.formatDateTime() ?: ""),
                                            ),
                                        ],
                                        iconFlow = flowOf(FormationIcons.Edit),
                                    )
                                }
                            }
                        }
                    }
                }
                // COORDINATES & OBJECT IDs
                detailsSection(separation = false) {
                    // ADDRESS
                    address.filter { it?.tagList?.isNotEmpty() == true }.render { addr ->
                        val addressStr = listOfNotNull(
                            if (!(addr?.name ?: "").startsWith(addr?.street ?: "xxxx")) {
                                addr?.name
                            } else {
                                null
                            },
                            addr?.street,
                            addr?.houseNumber,
                            addr?.postalCode,
                            addr?.neighborhood,
                            addr?.borrow,
                            addr?.city,
                            addr?.countryCode?.uppercase(),
                        ).joinToString(", ")

                        val copyStr = listOfNotNull(
                            addr?.street,
                            addr?.houseNumber,
                            addr?.postalCode,
                            addr?.city,
                            addr?.countryCode?.uppercase(),
                        ).joinToString(", ")

                        flexBox(
                            {
                                width { full }
                                margins {
                                    top { small }
                                }
                                alignItems { stretch }
                            },
                        ) {
                            copyButtonWithTitle(
                                title = flowOf(addressStr),
                                icon = flowOf(FormationIcons.Building),
                                clipboardData = copyStr,
                                clipboardText = "Copied address to clipboard!",
                            )
                        }
                    }
                    // GPS COORDINATES
                    copyButtonWithTitle(
                        title = position.data.map { "GPS: ${it.lat.roundDecimals(6)}, ${it.lon.roundDecimals(6)}" },
                        icon = flowOf(FormationIcons.Location),
                        clipboardData = position.current.let { ll ->
                            "${ll.lat.roundDecimals(6)}, ${
                                ll.lon.roundDecimals(
                                    6,
                                )
                            }"
                        },
                        clipboardText = "Copied GPS coordinates to clipboard!",
                    )
                    // UTM COORDINATES
                    copyButtonWithTitle(
                        position.data.map { "UTM: " + it.pointCoordinates().toUtmOrUps().toString() },
                        icon = flowOf(FormationIcons.Location),
                        clipboardData = position.current.pointCoordinates().toUtmOrUps().toString(),
                        clipboardText = "Copied UTM coordinates to clipboard!",
                    )

                    // MGRS COORDINATES
                    copyButtonWithTitle(
                        title = position.data.map { "MGRS: " + it.pointCoordinates().toMgrs().usng() },
                        icon = flowOf(FormationIcons.Location),
                        clipboardData = position.current.pointCoordinates().toMgrs().usng(),
                        clipboardText = "Copied MGRS coordinates to clipboard!",
                    )

                    // INTERNAL OBJECT ID
                    copyButtonWithContent(
                        objectId.current,
                        "Copied object id to clipboard!",
                    ) {
                        flexBox(
                            {
                                alignItems { center }
                            },
                        ) {
                            span(
                                {
                                    width { "20px" }
                                    fontSize { normal }
                                    fontWeight { bold }
                                    margins { right { smaller } }
                                    textAlign { center }
                                    lineHeight { none }
                                },
                            ) { +"id" }
                            span(
                                {
                                    fontSize { small }
                                    fontWeight { lighter }
                                },
                            ) { objectId.data.renderText(into = this) }
                        }
                    }
                    // EXTERNAL OBJECT ID
                    externalId.render { extId ->
                        if (!extId.isNullOrBlank()) {
                            copyButtonWithTitle(
                                title = flowOf(extId),
                                icon = flowOf(FormationIcons.QRCode),
                                clipboardData = "https://app.tryformation.com/#id=${extId.urlEncode()}",
                                clipboardText = "Copied link to clipboard!",
                            )
                            flowOf(
                                extId,
                            ) handledBy {
                                val svgContent = toSvgQrCode("https://app.tryformation.com/#id=${extId.urlEncode()}")
                                div(
                                    {
                                        width { full }
                                        maxWidth { "300px" }
                                        margins {
                                            horizontal { auto }
                                        }
                                    },
                                ) {
                                    domNode.innerHTML = svgContent
                                }
                            }
                        }
                    }
                }
                attribution.render { attr ->
                    if (!attr.isNullOrBlank()) {
                        selectorButton {
                            selectorContent {
                                icon({ margins { horizontal { tiny } } }) { fromTheme { FormationIcons.Copyright.icon } }
                                ellipseText { +"$attr contributors" }
                            }
                            clicks.map {
                                mapOf("show" to "attribution")
                            } handledBy routerStore.addOrReplaceRoute
                        }
                    }
                }
            }
        },
    )
}

fun RenderContext.detailsSection(
    separation: Boolean = true,
    topMargin: Boolean = true,
    items: Tag<HTMLElement>.() -> Unit,
) {
    stackUp(
        {
            alignItems { stretch }
            justifyContent { flexStart }
            if (topMargin) margins { top { small } }
        },
    ) {
        spacing { small }
        items {
            items.invoke(this)
            if (separation) separationLine()
        }
    }
}

val copyToClipboardAlertHandler: suspend (Pair<String, String>) -> Unit = { (string, message) ->

    val alertOverlayStore: AlertOverlayStore by koinCtx.inject()

    window.navigator.clipboard.writeText(string)
    alertOverlayStore.show(
        Overlay.NotificationToast(
            notificationType = NotificationType.Alert,
            durationSeconds = 3,
            text = flowOf(message),
            bgColor = FormationColors.GreenActive.color,
        ),
    )
}

fun RenderContext.copyButton(value: Flow<String>, alertMessage: Flow<String>) {
    value.combine(alertMessage) { v, a -> Pair(v, a) }.render { (value, alert) ->
        button {
            flexBox(
                {
                    alignItems { center }
                    justifyContent { center }
                    color { primary.main }
                    background {
                        color { secondary.main }
                    }
                    radius { normal }
                    hover {
                        color { secondary.main }
                        background {
                            color { primary.main }
                        }
                    }
                    padding { tiny }
                },
            ) {
                icon { fromTheme { FormationUIIcons.Copy.icon } }
            }
            clicks.map { Pair(value, alert) } handledBy copyToClipboardAlertHandler
        }
    }
}

fun RenderContext.dotNumber(number: Int?, color: FormationColors) {
    if (number != null && number > 0) {
        lineUp(
            {
                alignItems { center }
                justifyContent { flexStart }
            },
        ) {
            spacing { none }
            items {
                colorDot(color)
                span(
                    {
                        fontSize { small }
                        fontWeight { lighter }
                    },
                ) { +number.toString() }
            }
        }
    }
}

fun RenderContext.iconNumber(number: Int?, icon: Icons.() -> IconDefinition) {
    if (number != null && number > 0) {
        lineUp(
            {
                alignItems { center }
                justifyContent { flexStart }
            },
        ) {
            items {
                icon(
                    {
                        size { small }
                    },
                ) { fromTheme(icon) }
                span(
                    {
                        fontSize { small }
                        fontWeight { lighter }
                    },
                ) { +number.toString() }
            }
        }
    }
}

fun RenderContext.copyButtonWithTitle(
    title: Flow<String>,
    icon: Flow<IconEnum>,
    clipboardData: String,
    clipboardText: String,
) {
    button(
        {
            width { full }
        },
    ) {
        flexBox(
            {
                width { full }
                alignItems { center }
                justifyContent { spaceBetween }
                hover {
                    color { secondary.main }
                    background {
                        color { primary.main }
                    }
                }
                paddings {
                    vertical { tiny }
                    horizontal { tiny }
                }
                radius { large }
                textAlign { left }
            },
        ) {
            cardSubtitle(
                title,
                iconFlow = icon,
            )
            icon { FormationUIIcons.Copy.icon }
        }
        clicks.map {
            Pair(
                clipboardData,
                clipboardText,
            )
        } handledBy copyToClipboardAlertHandler
    }
}

fun RenderContext.copyButtonWithContent(
    clipboardData: String,
    clipboardText: String,
    content: RenderContext.() -> Unit,
) {
    button {
        flexBox(
            {
                width { full }
                alignItems { center }
                justifyContent { spaceBetween }
                hover {
                    color { secondary.main }
                    background {
                        color { primary.main }
                    }
                }
                paddings {
                    vertical { tiny }
                    horizontal { tiny }
                }
                radius { large }
                textAlign { left }
            },
        ) {
            content.invoke(this)
            icon { fromTheme { FormationUIIcons.Copy.icon } }
        }
        clicks.map {
            Pair(
                clipboardData,
                clipboardText,
            )
        } handledBy copyToClipboardAlertHandler
    }
}
