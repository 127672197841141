package maplibreGL

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.ObjectVisibility
import apiclient.geoobjects.connectableGeometry
import apiclient.geoobjects.connectableObjectExclusionZoneGeometry
import apiclient.tags.visibility
import apiclient.util.withDuration
import com.jillesvangurp.geo.GeoGeometry
import com.jillesvangurp.geojson.Geometry
import com.jillesvangurp.geojson.translate
import data.ObjectAndUserHandler
import data.connectableshapes.NewConnectableShapeConnectionStore
import data.objects.ActiveObjectStore
import data.objects.emptyGeoObjectDetails
import data.objects.objecthistory.ObjectHistoryResultsCache
import data.objects.views.CurrentClusterStore
import data.users.settings.LocalSettingsStore
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Scope
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlin.collections.component1
import kotlin.collections.component2
import kotlin.collections.set
import kotlin.js.json
import kotlin.time.Duration.Companion.milliseconds
import kotlin.time.measureTime
import kotlin.time.measureTimedValue
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.launch
import kotlinx.coroutines.promise
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import map.MaplibreTileLayersStore
import map.views.MapStyleOffline
import map.views.MapStyleOnline
import map.views.MapStyleSelectionStore
import map.views.bottomLeftSideControls
import map.views.bottomRightSideControls
import map.views.topRightSideControls
import maplibreGL.renderer.ADDITIONAL_GEOMETRY
import maplibreGL.renderer.ActiveTileLayerSourceIdsStore
import maplibreGL.renderer.GeoJsonGeometryRender
import maplibreGL.renderer.MeasuringToolRender
import measuringTool.MeasuringToolStore
import model.MapState
import model.ObjectLayer
import objectrouting.NavigationRender
import objectrouting.NavigationToolToggleStore
import org.w3c.dom.Element
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.events.Event
import org.w3c.dom.events.EventListener
import search.PathActiveHighlightedObjectMarkersStore
import svgmarker.makeClusterMarkerSvg
import svgmarker.makeSvgMarker
import theme.FormationColors
import utils.debug
import utils.jsApply
import utils.obj

const val renderInfoOutput = false
private const val CLUSTER_RADIUS_PIXEL = 35.0

class MaplibreMap(
    val targetId: String,
    private val centerLat: Double,
    private val centerLng: Double,
    private val initZoom: Double,
) {

    private val currentClusterStore: CurrentClusterStore by koinCtx.inject()
    private val activeTileLayerSourceIdsStore: ActiveTileLayerSourceIdsStore by koinCtx.inject()
    val initialized: Boolean get() = map != null
    var map: Map? = null
        private set

    private var mapContext: RenderContext? = null

    private val clusteredFeaturesMap = mutableMapOf<String, Pair<GeoObjectDetails, MapMarker>>()
    private val unClusteredFeaturesMap = mutableMapOf<String, Pair<GeoObjectDetails, MapMarker>>()
    private val myUserMarker = mutableListOf<MapMarkerMyUser>()
    var disabledObjects = mutableMapOf<String, Pair<GeoObjectDetails, String>>()
    private var disabledUsers = mutableSetOf<String>()

    private val activeObjectIds = mutableSetOf<String>()
    private val geometryCenterOverrides = mutableMapOf<String, Geometry.Point>()
    private val geometryShapeOverrides = mutableMapOf<String, Geometry>()
    private val hiddenMarkerIds = mutableSetOf<String>()
    private val priorityOverrides = mutableSetOf<String>()
    private val pathToolHighlightedObjectIds = mutableSetOf<String>()
    private val highlightedObjectIds = mutableSetOf<String>()

    var activeListeners = mutableMapOf<String, dynamic>()

    val activeObjectStore: ActiveObjectStore by koinCtx.inject()

    private fun isDisabled(id: String, type: ObjectType): Boolean {
        return when (type) {
            ObjectType.CurrentUserMarker -> id in disabledUsers
            else -> id in disabledObjects
        }
    }

    private fun isEnabled(id: String, type: ObjectType): Boolean =
        !isDisabled(id, type)

    class MaplibreDynamicObjectLayer(
        val id: ObjectType,
        var objectPairs: MutableMap<String, Pair<MaplibreElement, dynamic>> = mutableMapOf(),
    )

//    class MaplibreDynamicObjectLayers(val layers: List<MaplibreDynamicObjectLayer>?)

    /** stores and displays dynamically generated objects on the map. One for each object class */
//    private var maplibreDynamicObjectLayers = MaplibreDynamicObjectLayers(listOf())

    private var maplibreImageOverlayLayer = MaplibreDynamicObjectLayer(ObjectType.Floor)

    init {
        utils.require("maplibre-gl/dist/maplibre-gl.css")

        val protocol = pmtiles.Protocol()

//        console.log("addProtocol", addProtocol("pmtiles", protocol.tile))

//        val pmtiles = pmtiles.PMTiles(PMTILES_URL)
//        protocol.add(pmtiles)
    }

    fun getMapRenderContext() = mapContext

    fun setRenderContext(ctx: RenderContext) {
        mapContext = ctx
    }

    private fun getGeoJSONSource(id: String): GeoJSONSource? {
        return map?.getSource(id) as? GeoJSONSource
    }

    fun removeMap() {
        if (map != null) {
            console.log("No user logged in anymore -> Removed map")
            map?.remove()

            markers.clear()
            clusterMarkers.clear()
            markersOnScreen.clear()
            clusterMarkersOnScreen.clear()
            clusteredFeaturesMap.clear()
            unClusteredFeaturesMap.clear()
            disabledObjects.clear()
            disabledUsers.clear()
            activeObjectIds.clear()
            geometryCenterOverrides.clear()
            geometryShapeOverrides.clear()
            hiddenMarkerIds.clear()
            priorityOverrides.clear()
            pathToolHighlightedObjectIds.clear()
//            lastMaplibreElements.clear()
        }
        map = null
    }

    private var mapLastMoved: Instant = Instant.DISTANT_PAST
    private val markers = mutableMapOf<Int, Marker>()
    private val clusterMarkers = mutableMapOf<Int, Marker>()
    private val markersOnScreen = mutableMapOf<Int, Marker>()
    private val clusterMarkersOnScreen = mutableMapOf<Int, Marker>()

    suspend fun insertMap(existingMapState: MapState? = null, existingObjectLayers: List<ObjectLayer>? = null) {

//        val mapSearchClientsStore: MapSearchClientsStore by koinCtx.inject()
        val mapStyleSelectionStore: MapStyleSelectionStore by koinCtx.inject()
        val center = LngLat(lng = centerLng, lat = centerLat)

        val map = Map(
            MaplibreMapOptions(
                containerId = targetId,
                center = center,
                zoom = initZoom,
                style = when (val current = mapStyleSelectionStore.current) {
                    is MapStyleOnline -> current.url
                    is MapStyleOffline -> run {
                        console.warn("changing mapstlye to ${current.name}", current.styleSpecification)
                        current.styleSpecification
                    }

                    else -> null
                },
            ).toJs(),
        )
        this.map = map
        map.once("load") { event ->
            reRenderGeoJSONLayers()
        }

//        on("movestart", fn = mapSearchClientsStore::cancelSearch, fnId = "cancel-search")
//        map.on("move", ::syncMaplibreMarkersHandler)
        map.on("moveend", ::syncMaplibreMarkersHandler)

        coroutineScope {
            promise {
                map.loadImage("assets/images/marker/levelUp_navBlue_128.png")
            }.then { levelUp: dynamic ->
                map.addImage("levelUp", levelUp.data)
//                console.log("Added levelUp image", levelUp.data)
            }
            promise {
                map.loadImage("assets/images/marker/levelDown_navBlue_128.png")
            }.then { levelDown: dynamic ->
                map.addImage("levelDown", levelDown.data)
//                console.log("Added levelDown image", levelDown.data)
            }
            promise {
                map.loadImage("assets/images/marker/stairs_navBlue_128.png")
            }.then { stairs: dynamic ->
                map.addImage("stairs", stairs.data)
//                console.log("Added stairs image", stairs.data)
            }
        }

        if (existingMapState != null) {
            createMaps(existingMapState.center.toLngLat(), existingMapState.zoom)
        } else createMaps()
    }

    private suspend fun createMaps(
        lastCenter: LngLat? = null,
        lastZoom: Double? = null,
    ) {
//        map?.once("load") {
//            map?.addGeojson()
//        }

        if (lastCenter != null) map?.setCenter(lastCenter)
        if (lastZoom != null) map?.setZoom(lastZoom)

//        // Only one layer for the floor plan images
//        maplibreDynamicObjectLayers = MaplibreDynamicObjectLayers(
//            listOf(MaplibreDynamicObjectLayer(id = ObjectType.Floor)),
//        )

        map?.addControl(AttributionControl(obj { }), "top-right")
        insertTopRightSideControls()
        map?.addControl(
            NavigationControl(
                obj {
                    this.showCompass = true
                    this.showZoom = false
                    this.visualizePitch = true
                },
            ),
        )
        map?.addControl(ScaleControl(obj { this.unit = "metric" }), "bottom-right")
//        map?.addControl(
//            GeolocateControl(
//                obj {
//                    this.trackUserLocation = true
//                    this.showAccuracyCircle = true
//                    this.showUserLocation = true
//                },
//            ),
//            "bottom-right",
//        )
        insertBottomRightSideControls()
        insertBottomLeftSideControls()

        // Create vector tile layers initially
//        if (isStyleLoaded()) updateTileLayersSourceToken() // TODO x tileLayer
    }

    private fun Map.addGeoJSONLayers(
        layerDefinitionsMap: kotlin.collections.Map<kotlin.js.Json, String?>, // layerDefinition, beforeId
        blockDoAfterLayersAddedInitially: (() -> Unit)? = null
    ) {
        layerDefinitionsMap.forEach { (layerDefinition, beforeId) ->
            (layerDefinition["id"] as? String)?.let { layerId ->
                if (map?.getLayer(layerId) == null) {
                    if (beforeId != null && map?.getLayer(beforeId) != null) {
                        this.addLayer(layerDefinition, beforeId)
                    } else {
                        this.addLayer(layerDefinition)
                    }
                    blockDoAfterLayersAddedInitially?.invoke()
                }
            }
        }
    }

    fun ensureGeoJSONSourceAndAddGeoJSONLayers(
        sourceId: String,
        sourceDefinition: kotlin.js.Json,
        forceReplaceSource: Boolean = false,
        layerDefinitionsMap: kotlin.collections.Map<kotlin.js.Json, String?>, // layerDefinition, beforeId
        blockDoAfterLayerAddedInitially: (() -> Unit)? = null
    ) {
//        if (forceReplaceSource) {
//            try {
//                map?.removeSource(sourceId)
//            } catch (e: Exception) {
//                console.log("Could not remove GeoJSON source", e.message)
//            }
//        }
//        if (map?.getSource(sourceId) == null) {
//            try {
//                map?.addSource(sourceId, sourceDefinition)
//                map?.addGeoJSONLayers(layerDefinitionsMap, blockDoAfterLayerAddedInitially)
//            } catch (e: Exception) {
//                console.log("Could not add GeoJSON source", e.message)
//                map?.addGeoJSONLayers(layerDefinitionsMap, blockDoAfterLayerAddedInitially)
//            }
//        } else {
//            map?.addGeoJSONLayers(layerDefinitionsMap, blockDoAfterLayerAddedInitially)
//        }

        if (forceReplaceSource) {
            try {
                map?.removeSource(sourceId)
                map?.addSource(sourceId, sourceDefinition)
                map?.addGeoJSONLayers(layerDefinitionsMap, blockDoAfterLayerAddedInitially)
            } catch (e: Exception) {
                console.log("Could not remove GeoJSON source", e.message)
            }
        } else if (map?.getSource(sourceId) == null) {
            try {
                map?.addSource(sourceId, sourceDefinition)
                map?.addGeoJSONLayers(layerDefinitionsMap, blockDoAfterLayerAddedInitially)
            } catch (e: Exception) {
                console.log("Could not add GeoJSON source", e.message)
                map?.addGeoJSONLayers(layerDefinitionsMap, blockDoAfterLayerAddedInitially)
            }
        } else {
            map?.addGeoJSONLayers(layerDefinitionsMap, blockDoAfterLayerAddedInitially)
        }
    }

    private fun reRenderGeoJSONLayers() {
        val geoJsonGeometryRender: GeoJsonGeometryRender by koinCtx.inject()

        val atLeastOneLayerIsMissing = GeoJsonGeometryRender.GeoJsonGeometryLayer.entries.map { geoJSONLayer ->
            map?.getLayer(geoJSONLayer.layerId) == null
        }.reduce { a, b -> a && b }
        if (atLeastOneLayerIsMissing) {
            geoJsonGeometryRender.addAllGeoJsonGeometryLayers()
        }
    }

    private fun reRenderMeasuringToolPath() {
        val measuringToolStore: MeasuringToolStore by koinCtx.inject()
        val measuringToolRender: MeasuringToolRender by koinCtx.inject()

        if (measuringToolStore.current) {
            measuringToolRender.enableMeasuringToolAndAddLayers()
        }
    }

    private fun reRenderTileLayers() {
        val maplibreTileLayersStore: MaplibreTileLayersStore by koinCtx.inject()
        maplibreTileLayersStore.renderTileLayers(null)
    }

    private fun reRenderNavigationPath() {
        val navigationToolToggleStore: NavigationToolToggleStore by koinCtx.inject()
        val navigationRender: NavigationRender by koinCtx.inject()

//        if (navigationToolToggleStore.current) {
        navigationRender.reDrawRoutingPath()
//        }
    }

    private fun redrawSourceLayers() {
        if (renderInfoOutput) {
            console.info("Redraw source layers...")
        }
        reRenderGeoJSONLayers()
        reRenderMeasuringToolPath()
        reRenderTileLayers()
        addOrUpdateImageOverlayRotated(null)
        reRenderNavigationPath()
    }

    private fun addImageOverlayLayer(
        imageLayer: MapLayer,
        beforeIds: List<String> = emptyList(),
        blockDoAfterLayersAddedInitially: (() -> Unit)? = null
    ) {
        beforeIds.firstNotNullOfOrNull { id ->
            if (map?.getLayer(id) != null) id else null
        }?.let { beforeId ->
            map?.addLayer(imageLayer, beforeId)
        } ?: run {
            map?.addLayer(imageLayer)
        }
        blockDoAfterLayersAddedInitially?.invoke()
//        if (map?.getLayer(layer.id) == null) {
//            if (beforeId != null && map?.getLayer(beforeId) != null) {
//                map?.addLayer(imageLayer, beforeId)
//            } else {
//                map?.addLayer(imageLayer)
//            }
//            blockDoAfterLayersAddedInitially?.invoke()
//        }
    }

    private fun geoJsonUpdateData(diff: GeoJSONSourceDiff, sourceId: String) {
        val source = getGeoJSONSource(sourceId)
        if (source != null) {
            source.updateData(diff = diff)
        } else {
            console.warn("failed to get GeoJSON source", sourceId)
        }
    }

    private fun geoJSONReplaceData(features: List<GeoJSONFeature>, sourceId: String) {
//        console.log("replaceData on '$sourceId'")
        val source = getGeoJSONSource(sourceId)
//        console.log("replaceData on source:", source)
        if (source != null) {
            val jsonFeatureCollection = json(
                "type" to "FeatureCollection",
                "features" to features,
            )
            source.setData(
                jsonFeatureCollection,
            )
        } else {
            // only print this warning if it actually tried to replace data
            if (features.isNotEmpty()) {
                console.warn("Cannot replace data of non-existing GeoJSON source: $sourceId.")
            }
        }
    }

    private fun geoJSONRemove(ids: Array<String>, sourceId: String) {
        geoJsonUpdateData(
            jsApply<GeoJSONSourceDiff> {
                remove = ids
            },
            sourceId,
        )
    }

    fun clearClustersFromMap() {
//        console.warn("Refresh clusters!!!")
        clusterMarkersOnScreen.forEach { (_, marker) ->
            marker.remove()
        }
        clusterMarkers.forEach { (_, marker) ->
            marker.remove()
        }
        clusterMarkers.clear()
        clusterMarkersOnScreen.clear()
    }

    fun geoObjectDetailsSetData(geoObjects: Set<GeoObjectDetails>, clustered: Boolean) {
        val connectableGeoShapes = extractConnectableGeoShapesMap(geoObjects)
        val overlappingShapeIds = geoObjects.extractOverlappingGeoShapeIds
        val features = geoObjects.mapNotNull { geoObject ->
            val markerHidden = geoObject.tags.visibility == ObjectVisibility.MarkerHidden
            if (!markerHidden) {
                val desaturated =
                    pathToolHighlightedObjectIds.isNotEmpty() && geoObject.id !in pathToolHighlightedObjectIds
                val highlighted = highlightedObjectIds.isNotEmpty() && geoObject.id in highlightedObjectIds
                GeojsonConverter.fromGeoObject(
//                    mapRenderContext = mapRenderContext,
                    geoObject = geoObject,
                    desaturated = desaturated,
                    highlighted = highlighted,
                    connectables = connectableGeoShapes,
                    overlappingShapeIds = overlappingShapeIds,
                )?.let {
                    geoObject.id to (geoObject to it)
                }
            } else null
        }.toMap()

        if (clustered) {
            clusteredFeaturesMap.clear()
            clusteredFeaturesMap.putAll(features)
        } else {
            unClusteredFeaturesMap.clear()
            unClusteredFeaturesMap.putAll(features)
        }
    }

    fun geoObjectAddOrUpdate(geoObject: GeoObjectDetails, clustered: Boolean) {

        val geoObjects = (clusteredFeaturesMap + unClusteredFeaturesMap).values.map { it.first }.toSet()
        val connectableGeoShapes = extractConnectableGeoShapesMap(geoObjects)
        val overlappingShapeIds = geoObjects.extractOverlappingGeoShapeIds
        val desaturated = pathToolHighlightedObjectIds.isNotEmpty() && geoObject.id !in pathToolHighlightedObjectIds
        val highlighted = highlightedObjectIds.isNotEmpty() && geoObject.id in highlightedObjectIds
        val mapMarker = GeojsonConverter.fromGeoObject(
            geoObject = geoObject,
            desaturated = desaturated,
            highlighted = highlighted,
            connectables = connectableGeoShapes,
            overlappingShapeIds = overlappingShapeIds,
        ) ?: return

        if (clustered) {
            clusteredFeaturesMap[geoObject.id] = geoObject to mapMarker
        } else {
            unClusteredFeaturesMap[geoObject.id] = geoObject to mapMarker
        }
        syncMarkersNow(trigger = "geoObjectAddOrUpdate")
    }

    fun geoObjectDetailsRemove(geoObjectId: String, clustered: Boolean) {
        // remove geoJSON children of object first, then the object
        if (clustered) {

            clusteredFeaturesMap[geoObjectId]?.second?.geoJSONs?.forEach { geoJSONChild ->
                clusteredFeaturesMap.remove(geoJSONChild.id)
            }
            clusteredFeaturesMap.remove(geoObjectId)
        } else {
            unClusteredFeaturesMap[geoObjectId]?.second?.geoJSONs?.forEach { geoJSONChild ->
                unClusteredFeaturesMap.remove(geoJSONChild.id)
            }
            unClusteredFeaturesMap.remove(geoObjectId)
        }
        syncMarkersNow(trigger = "geoObjectDetailsRemove")
    }

    private val syncMarkerScope = CoroutineScope(
        CoroutineName("sync-markers"),
    )

    private fun syncMaplibreMarkersHandler(e: dynamic) {
        val eventType = e.type as String
//        console.log("syncMaplibreMarkers", e.type)
        val doRateLimiting = when (eventType) {
            "move" -> true
            "sourcedata" -> true
            else -> false
        }
        val updateObjectLayers = when (eventType) {
            "moveend" -> true
            "sourcedata" -> true
            else -> false
        }
        val updateGeoJSON = when (eventType) {
            else -> true
        }
        syncMarkerScope.launch {
            try {
                measureTime {
                    syncMarkers(
                        source = "event $eventType",
                        doRateLimiting = doRateLimiting,
                        updateImageLayers = updateObjectLayers,
                        updateGeoJSON = updateGeoJSON,
                        activeObjectId = activeObjectStore.current.id,
                    )
                }
            } catch (e: ClassCastException) {
                console.error("it borked", e)
                e.printStackTrace()
            }
        }

    }

    fun syncMarkersNow(
        updateGeoJSON: Boolean = true,
        updateImageLayers: Boolean = false, //TODO CHEKL
        trigger: String = "?",
        doRateLimiting: Boolean = false,
    ) {
        syncMarkerScope.launch {
            try {
                syncMarkers(
                    source = "$trigger and sync",
                    doRateLimiting = doRateLimiting,
                    updateImageLayers = updateImageLayers,
                    updateGeoJSON = updateGeoJSON,
                    activeObjectId = activeObjectStore.current.id,
                )
            } catch (e: ClassCastException) {
                console.error("it borked", e)
            }
        }
    }

    private var lastActiveConnectorIds: Set<String> = setOf()

    private fun applyOverridesAndConvert(
        geoObjects: kotlin.collections.Map<GeoObjectDetails, MapMarker>,
        activeObjectId: String,
    ): List<MapMarker> {
        val activeObjectStore: ActiveObjectStore by koinCtx.inject()
        val newConnectableShapeConnectionStore: NewConnectableShapeConnectionStore by koinCtx.inject()
        val activeConnectorIds =
            listOf(
                newConnectableShapeConnectionStore.current.sourceConnectorId,
                newConnectableShapeConnectionStore.current.targetConnectorId,
            )
        val oldConnectableGeoShapes = extractConnectableGeoShapesMap(geoObjects.keys)
        val oldOverlappingShapeIds = geoObjects.keys.extractOverlappingGeoShapeIds
//        console.log("Overlapping Ids:", overlappingShapeIds.toString())

        // regenerate active object first
        val activeObj = geoObjects.entries.firstOrNull { it.key.id == activeObjectId }

        val updatedActiveObj = activeObj?.let { (obj, marker) ->
            val activeObject = activeObjectStore.current.takeIf { it != emptyGeoObjectDetails } ?: obj

            val newCenterPoint = geometryCenterOverrides[activeObjectId]
            val newGeometryShape = geometryShapeOverrides[activeObjectId]
            val desaturated = pathToolHighlightedObjectIds.isNotEmpty() && obj.id !in pathToolHighlightedObjectIds
            val highlighted = highlightedObjectIds.isNotEmpty() && obj.id in highlightedObjectIds
            if (newCenterPoint != null || newGeometryShape != null) {
                // update position
                val newObj = activeObject.copy(
                    latLon = newCenterPoint?.let { center -> LatLon(center.coordinates!!) } ?: activeObject.latLon,
                    geometry = newCenterPoint?.let { center ->
                        (newGeometryShape ?: activeObject.geometry)?.translate(center)
                    } ?: (newGeometryShape ?: activeObject.geometry),
//                        tags = obj.tags + "hidden:true",
                )
                val updatedMarker = GeojsonConverter.fromGeoObject(
                    geoObject = newObj,
                    desaturated = desaturated,
                    highlighted = highlighted,
                    connectables = oldConnectableGeoShapes.plus(setOf(newObj).extractConnectableGeoShapesMap),
                    overlappingShapeIds = oldOverlappingShapeIds.plus(setOf(newObj).extractOverlappingGeoShapeIds),
                    activeOverride = true,
                )
//                console.log("UPDATED Active Object!", newObj.title, " -> ", newObj.latLon.toString())
                newObj to (updatedMarker ?: marker)
            } else obj to marker
        }

        val newGeoObjectsMap = (updatedActiveObj?.let {
            geoObjects.filter { (obj, _) ->
                obj.id != activeObjectId
            }.plus(it)
        } ?: geoObjects)

        val connectableGeoShapes = newGeoObjectsMap.keys.extractConnectableGeoShapesMap
        val overlappingIds = newGeoObjectsMap.keys.extractOverlappingGeoShapeIds

        // iterate through all other objects including the updated active object
        return newGeoObjectsMap
            .asSequence()
            .distinctBy { it.key.id }
            .mapNotNull { (obj, feature) ->
                val active = obj.id in activeObjectIds
                val desaturated = pathToolHighlightedObjectIds.isNotEmpty() && obj.id !in pathToolHighlightedObjectIds
                val highlighted = highlightedObjectIds.isNotEmpty() && obj.id in highlightedObjectIds

                val drawConnectors = feature.geoJSONs.map { it.geoJSONType }.contains("connector") &&
                    feature.geoJSONs.any { geoJSON -> geoJSON.id in activeConnectorIds }
                val removeConnectors = feature.geoJSONs.map { it.geoJSONType }.contains("connector") &&
                    feature.geoJSONs.any { geoJSON -> geoJSON.id !in lastActiveConnectorIds }

//                val newMarker = if ((active && !feature.isActive) || drawConnectors || removeConnectors) {
                val newMarker =
                    if (
                        active
                        || drawConnectors
                        || removeConnectors
                        || (desaturated && !feature.isDesaturated)
                        || (!desaturated && feature.isDesaturated)
                        || (highlighted && !feature.isHighlighted)
                        || (!highlighted && feature.isHighlighted)
                    ) {
                        GeojsonConverter.fromGeoObject(
                            geoObject = obj,
                            connectables = connectableGeoShapes,
                            overlappingShapeIds = overlappingIds,
                            activeOverride = active,
                            desaturated = desaturated,
                            highlighted = highlighted,
                        )
                    } else {
                        feature
                    }
                lastActiveConnectorIds = activeObjectIds
                newMarker
            }
            .sortedBy { feature ->
                if (feature.id in priorityOverrides) {
                    999
                } else {
                    val priority = when {
                        feature.isActive -> 999
                        feature.objectType == ObjectType.Building -> 800
                        else -> 0
                    }
                    priority
                }
            }
            .toList()
    }

    fun extractConnectableGeoShapesMap(geoObjects: Set<GeoObjectDetails>): kotlin.collections.Map<String, ConnectableAndPosition> {
        return geoObjects.mapNotNull { geoObjectDetails ->
            geoObjectDetails.geoReferencedConnectableObject?.let { connectableShape ->
                geoObjectDetails.id to ConnectableAndPosition(connectableShape, geoObjectDetails.latLon)
            }
        }.toMap()
    }

    private val Set<GeoObjectDetails>.extractConnectableGeoShapesMap
        get() = extractConnectableGeoShapesMap(
            this,
        )


    private val Set<GeoObjectDetails>.extractOverlappingGeoShapeIds
        get() =
            this.filter { selfObj ->
                this.map { otherObj ->
                    val shapeCoordinates = selfObj.connectableGeometry()?.coordinates
                    val otherCoordinates = otherObj.connectableGeometry()?.coordinates
                    val otherExclusionZoneCoordinates =
                        otherObj.geoReferencedConnectableObject?.connectableObjectExclusionZoneGeometry(position = otherObj.latLon)?.coordinates
                    if (selfObj.id != otherObj.id && otherCoordinates != null && shapeCoordinates != null) {
                        GeoGeometry.overlap(otherCoordinates, shapeCoordinates) || otherExclusionZoneCoordinates?.let {
                            GeoGeometry.overlap(
                                it,
                                shapeCoordinates,
                            )
                        } ?: false
                    } else false
                }.reduceOrNull { acc, value -> acc || value } ?: false
            }.map { it.id }.toSet()


    private fun syncMarkers(
        source: String,
        doRateLimiting: Boolean,
        updateImageLayers: Boolean,
        updateGeoJSON: Boolean,
        activeObjectId: String,
    ) {
        if (doRateLimiting) {
            val now = Clock.System.now()
            if (now - mapLastMoved > 500.milliseconds) {
                mapLastMoved = now
            } else {
                return
            }
        }
        val localSettingsStore: LocalSettingsStore by koinCtx.inject()
        console.debug("syncMarkers triggered by", source)
        val map = map ?: return
        val renderContext = getMapRenderContext() ?: return
        val desaturateClusters = pathToolHighlightedObjectIds.isNotEmpty()
        measureTime {
            val clusteredFeatures: List<MapMarker> =
                applyOverridesAndConvert(clusteredFeaturesMap.values.toMap(), activeObjectId)

            val connectableGeoShapes =
                (clusteredFeaturesMap + unClusteredFeaturesMap).filter { (_, geoObjectPair) ->
                    geoObjectPair.first.geoReferencedConnectableObject != null
                }.toMutableMap()

            val connectableGeoShapeIds = connectableGeoShapes.keys

            val excludedIds =
                hiddenMarkerIds + connectableGeoShapeIds + pathToolHighlightedObjectIds + highlightedObjectIds

            val excludedFromClustering = excludedIds.mapNotNull { id ->
                clusteredFeatures.firstOrNull { it.id == id }
            } + applyOverridesAndConvert((unClusteredFeaturesMap + connectableGeoShapes).values.toMap(), activeObjectId)
            val clusterInput = clusteredFeatures.filter { it.id !in excludedIds }

            val (clusters, outsideClusterFeatures) = if (localSettingsStore.clusteringEnabled) {
                measureTimedValue {
                    clusterDbScan(
                        input = clusterInput,
                        minPointsPerCluster = 2,
                        neighborhoodRadius = CLUSTER_RADIUS_PIXEL * CLUSTER_RADIUS_PIXEL,
                        coordinateConverter = { feature ->
                            val point = map.project(
                                lngLat = feature.lngLat,
                            )
                            arrayOf(point.x, point.y)
                        },
                        getLngLat = { feature ->
                            feature.lngLat
                        },
                        getLabel = { feature ->
                            "${feature.id} ${feature.title}"
                        },
                        distanceFunction = { a: Array<Double>, b: Array<Double> ->
                            val (x1, y1) = a
                            val (x2, y2) = b
                            val dx = x1 - x2
                            val dy = y1 - y2

                            (dx * dx) + (dy * dy)
                        },
                    ) { clusterIndex, features ->
                        ClusterProperties(
                            objectTypes = ObjectType.entries.associateWith { type ->
                                features.count {
                                    it.objectType == type
                                }
                            },
                            colors = FormationColors.entries.associateWith { color ->
                                features.count {
                                    it.bgColor == color
                                }
                            },
                        )
                    }
                }.withDuration {
//                    console.info("clustering took", duration.toString())
                }
            } else {
                emptyList<Cluster<MapMarker, ClusterProperties>>() to clusterInput
            }

            val newMarkers = mutableMapOf<Int, Marker>()
            val newClusterMarkers = mutableMapOf<Int, Marker>()

            val maplibreGeoJSONList = mutableListOf<MaplibreGeoJSON>()
            val maplibreImageOverlayObjectsList = mutableListOf<MaplibreImageOverlayRotated>()

            clusters.forEach { cluster ->
                val key = cluster.key()
                var clusterMarker = clusterMarkers[key]

                if (clusterMarker == null) {
                    val element =
                        getDomElement(key.toString(), renderContext) {
                            svg {}.domNode.apply {
                                outerHTML = makeClusterMarkerSvg(
                                    cluster.pointCount,
                                    cluster.properties,
                                    desaturated = desaturateClusters,
                                )
                            }
                        }

                    val markerOptions = MaplibreMarkerOptions(
                        element = element,
                    )
                    clusterMarker = Marker(markerOptions.toJs())
                    clusterMarker.setLngLat(cluster.center)
                    clusterMarkers[key] = clusterMarker

                    if (!desaturateClusters) { // make clusters non-clickable when desaturated (= disabled)
                        element.onclick = { event ->
                            event.stopPropagation()
                            event.stopImmediatePropagation()
                            currentClusterStore.onClusterClick(cluster)
                        }
                    }
                }

                newClusterMarkers[key] = clusterMarker
                if (key !in clusterMarkersOnScreen) {
                    clusterMarker.addTo(map)
                }
            }

            // remove clusters that are no longer on screen
            clusterMarkersOnScreen.forEach { (key, marker) ->
                if (key !in newClusterMarkers) {
                    marker.remove()
                }
            }
            clusterMarkersOnScreen.clear()
            clusterMarkersOnScreen.putAll(newClusterMarkers)

            (excludedFromClustering + outsideClusterFeatures + myUserMarker).forEachIndexed { i, feature ->
                val key: Int = feature.hashCode()
                var marker = markers[key]

                val objectType = feature.objectType

                val hidden =
                    feature.id in hiddenMarkerIds //|| feature.id in geometryCenterOverrides || feature.id in geometryShapeOverrides
                val svgIconOptions = feature.svgIconOptions
                if (!hidden && svgIconOptions != null) {
                    if (marker == null) {
                        val markerOptions = MaplibreMarkerOptions(
                            element = getDomElement(feature.id, renderContext) {
                                makeSvgMarker(
                                    title = feature.title,
                                    svgIconOptions = svgIconOptions,
                                    active = feature.isActive,
                                    objectType = objectType,
                                    objectId = feature.id,
                                    showTitle = feature.showTitle,
                                )
                            }.also { element ->
                                if (!feature.isDesaturated) { // make non-clickable when desaturated (= disabled)
                                    if (objectType == ObjectType.HistoryEntry) {
                                        element.addEventListener("click", historyMarkerEventListener)
                                    } else if (objectType == ObjectType.ObjectMarker && feature.id in pathToolHighlightedObjectIds) {
                                        element.addEventListener("click", pathToolTrackedObjectMarkerEventListener)
                                    } else if (objectType == ObjectType.UserMarker || objectType == ObjectType.CurrentUserMarker) {
                                        element.addEventListener("click", userMarkerEventListener)
                                    } else {
                                        element.addEventListener("click", markerEventListener)
                                    }
                                }
                            },
                        )
                        marker = Marker(markerOptions.toJs())
                        marker.setLngLat(feature.lngLat)
                        markers[key] = marker
                    }

                    newMarkers[key] = marker
                    if (key !in markersOnScreen) {
                        marker.addTo(map)
                    }

//                    val redrawOnTopOverride = redrawOnTopOverride == props.id
                    if (feature.isActive || feature.id in priorityOverrides || !feature.isDesaturated || feature.isHighlighted) {
                        marker.remove()
                        marker.addTo(map)
                    }
                }

                if (updateGeoJSON) {
                    maplibreGeoJSONList += feature.geoJSONs
                }
                if (updateImageLayers) {
                    maplibreImageOverlayObjectsList += feature.imageOverlays
                }
            }


            // remove markers that are no longer on screen
            markersOnScreen.forEach { (key, marker) ->
                if (key !in newMarkers) {
                    marker.remove()
                }
            }
            markersOnScreen.clear()
            markersOnScreen.putAll(newMarkers)

            if (updateGeoJSON) {
                geoJSONReplaceData(
                    maplibreGeoJSONList.distinctBy { it.id }.map { it.feature },
                    ADDITIONAL_GEOMETRY,
                )
            }
            if (updateImageLayers) {
                updateImageOverlayLayer(maplibreImageOverlayObjectsList)
            }
        }
    }

    private fun insertTopRightSideControls() {

        val topRightSideControl = HtmlTag<HTMLDivElement>(
            tagName = "div",
            id = "topRightSideControls",
            baseClass = "maplibregl-ctrl",
            job = Job(parent = null),
            scope = Scope(),
        ).apply { topRightSideControls() }.domNode


        fun addTopRightSideControl(): dynamic = topRightSideControl
        fun removeTopRightSideControl() = obj { }
        val newTopRightControlElement: dynamic = obj { }
        newTopRightControlElement.onAdd = ::addTopRightSideControl
        newTopRightControlElement.onRemove = ::removeTopRightSideControl
        map?.addControl(newTopRightControlElement.unsafeCast<IControl>(), "top-right")
    }

    private fun insertBottomRightSideControls() {
        val bottomRightSideControl = HtmlTag<HTMLDivElement>(
            tagName = "div",
            id = "bottomRightSideControls",
            baseClass = "pointer-events-none",
            job = Job(parent = null),
            scope = Scope(),
        ).apply { bottomRightSideControls() }.domNode

        fun addBottomRightSideControl(): dynamic = bottomRightSideControl
        fun removeBottomRightSideControl() = obj { }
        val newRightControlElement: dynamic = obj { }
        newRightControlElement.onAdd = ::addBottomRightSideControl
        newRightControlElement.onRemove = ::removeBottomRightSideControl
        map?.addControl(newRightControlElement, "bottom-right")
    }

    private fun insertBottomLeftSideControls() {

        val bottomLeftSideControl = HtmlTag<HTMLDivElement>(
            tagName = "div",
            id = "bottomLeftSideControls",
            baseClass = "maplibregl-ctrl",
            job = Job(parent = null),
            scope = Scope(),
        ).apply { bottomLeftSideControls() }.domNode

        fun addBottomLeftSideControl(): dynamic = bottomLeftSideControl
        fun removeBottomLeftSideControl() = obj { }
        val newLeftControlElement: dynamic = obj { }
        newLeftControlElement.onAdd = ::addBottomLeftSideControl
        newLeftControlElement.onRemove = ::removeBottomLeftSideControl
        map?.addControl(newLeftControlElement, "bottom-left")
    }

    fun changeMapStyle(url: String) {
//        val measuringToolRender: MeasuringToolRender by koinCtx.inject()
//        measuringToolRender.disableMeasuringTool()
        map?.setStyle(url)
        map?.once("idle") {
            redrawSourceLayers()
            syncMarkersNow(trigger = "changeMapStyle", updateImageLayers = true)
        }
    }


    fun changeMapStyleDynamic(newStyle: dynamic) {
//        val measuringToolRender: MeasuringToolRender by koinCtx.inject()
//        measuringToolRender.disableMeasuringTool()
        map?.setStyle(newStyle)
        map?.once("idle") {
            redrawSourceLayers()
            syncMarkersNow(trigger = "changeMapStyle", updateImageLayers = true)
        }
    }

    private val markerEventListener = EventListener { event ->
        val objectAndUserHandler: ObjectAndUserHandler by koinCtx.inject()
        val newConnectableShapeConnectionStore: NewConnectableShapeConnectionStore by koinCtx.inject()
        event.stopImmediatePropagation()
        event.stopPropagation()
        newConnectableShapeConnectionStore.reset()
        objectAndUserHandler.fetchAndShowObject(objectId = getTargetElementIdFromEvent(event))
    }

    private val userMarkerEventListener = EventListener { event ->
        val objectAndUserHandler: ObjectAndUserHandler by koinCtx.inject()
        val newConnectableShapeConnectionStore: NewConnectableShapeConnectionStore by koinCtx.inject()
        event.stopImmediatePropagation()
        event.stopPropagation()
        newConnectableShapeConnectionStore.reset()
        objectAndUserHandler.fetchAndShowUser(userId = getTargetElementIdFromEvent(event))
    }

    private val pathToolTrackedObjectMarkerEventListener = EventListener { event ->
        val pathActivehighlightedObjectMarkersStore: PathActiveHighlightedObjectMarkersStore by koinCtx.inject()
        event.stopImmediatePropagation()
        event.stopPropagation()
        getTargetElementIdFromEvent(event)?.let {
            pathActivehighlightedObjectMarkersStore.selectTrackedObject(it)
        }
    }

    private val historyMarkerEventListener = EventListener { event ->
        val objectHistoryResultsCache: ObjectHistoryResultsCache by koinCtx.inject()
        event.stopImmediatePropagation()
        event.stopPropagation()
        objectHistoryResultsCache.selectHistoryEntry(objectHistoryEntryId = getTargetElementIdFromEvent(event))
    }

    /**
     * Reads the currentTarget from the event and returns its id
     */
    fun getTargetElementIdFromEvent(event: Event): String? {
        return (event.currentTarget as? Element)?.id
    }

    private fun updateImageOverlayLayer(imageOverlayObjects: List<MaplibreImageOverlayRotated>) {
        if (imageOverlayObjects.isNotEmpty()) {
            cleanUpImageOverlays(imageOverlayObjects.map { it.id })
            addOrUpdateImageOverlayRotated(imageOverlayObjects)
//            if (isStyleLoaded()) updateTileLayerStates(tileLayers ?: emptyMap()) // // TODO x tileLayer
        } else {
            // INFO OUTPUT
            if (renderInfoOutput) {
                with(maplibreImageOverlayLayer.objectPairs.size) {
                    if (this > 0) console.info("remove $this ${maplibreImageOverlayLayer.id}(s)")
                }
            }
            // NO OBJECTS? -> CLEAR ALL
            maplibreImageOverlayLayer.objectPairs.forEach { objectPair ->
                removeImageOverlayLayer(objectPair.key)
                maplibreImageOverlayLayer.objectPairs.remove(objectPair.key)
            }
        }
    }

    private fun removeImageOverlayLayer(objectId: String) {
        if (map?.getLayer(objectId) != null) map?.removeLayer(objectId)
        if (map?.getSource(objectId) != null) map?.removeSource(objectId)
    }

    /**
     * Takes a list of imageOverlayObjectIds and removes all map imageOverlayObjects that are on the layer
     * but not in the objectsList
     * @param imageOverlayObjectIds list of objectIds to remove, if they are not in the layer
     */
    private fun cleanUpImageOverlays(imageOverlayObjectIds: List<String>) {
        maplibreImageOverlayLayer.objectPairs.filterKeys { objectId ->
            objectId !in imageOverlayObjectIds
        }.forEach { objectPair ->
            removeImageOverlayLayer(objectPair.key)
        }
    }

    /**
     * Adds or updates a rotated image overlay.
     * @param overlayList list of MaplibreImageOverlayRotated (that will be updated or added, if they are not yet in the layer)
     */
    private fun addOrUpdateImageOverlayRotated(
        overlayList: List<MaplibreImageOverlayRotated>? = null
    ) {
        var updateCounter = 0
        val processList = overlayList ?: maplibreImageOverlayLayer.objectPairs.mapNotNull { (_, maplibreElement) ->
            if (maplibreElement.first is MaplibreImageOverlayRotated) {
                maplibreElement.first as MaplibreImageOverlayRotated
            } else null
        }

        processList.forEach { overlay ->
            val srcDef = jsApply<ImageSourceOptions> {
                type = "image"
                url = overlay.imageUrl
                coordinates = arrayOf(
                    overlay.topLeft,
                    overlay.topRight,
                    overlay.bottomRight,
                    overlay.bottomLeft,
                ).map { arrayOf(it.lon, it.lat) }.toTypedArray()
            }

            val oldSrc = map?.getSource(overlay.id) as? ImageSource
            if (overlay.id in maplibreImageOverlayLayer.objectPairs.keys && oldSrc != null) {
                // try to update src and re-render layer
                val oldSrcDef = maplibreImageOverlayLayer.objectPairs[overlay.id]?.second
                if (srcDef.toString() != oldSrcDef.toString()) {
                    // update existing overlay source
                    oldSrc.updateImage(srcDef)
                    updateCounter += 1
                    maplibreImageOverlayLayer.objectPairs[overlay.id] = Pair(overlay, srcDef)
//                    console.log("DIFFERENT Source Definitions. Processed and updated overlay.", overlay.id, overlay.type.name)
                } else {
                    // re-render image anyway
//                    console.log("SAME Source Definitions. Floorplan should be rendered")
                }
            } else {
                reRenderGeoJSONLayers()
                // create and add layer
                val imageOverlayLayer = jsApply<MapLayer> {
                    id = overlay.id
                    source = srcDef //embed source directly instead of refer source id
                    type = "raster"
                    paint = json(
                        "raster-opacity" to 1.0,
                        "raster-fade-duration" to 0,
                    )
                }
                addImageOverlayLayer(
                    imageOverlayLayer,
                    beforeIds = activeTileLayerSourceIdsStore.current.toList() +
                        GeoJsonGeometryRender.GeoJsonGeometryLayer.entries.map { it.layerId },
                )
                maplibreImageOverlayLayer.objectPairs[overlay.id] = Pair(overlay, srcDef)
            }
        }
        // INFO OUTPUT
        if (renderInfoOutput) {
            with(processList.size) {
                if (this > 0) console.info("checked $this ${maplibreImageOverlayLayer.id}(s)${if (updateCounter > 0) ", $updateCounter needed an update" else ""}")
            }
        }
    }

    fun updateRedrawOnTopOverride(objId: String) {
        if (objId !in priorityOverrides && objId !in activeObjectIds) {
            priorityOverrides += objId
            syncMarkersNow(false, trigger = "updateRedrawOnTopOverride")
        }
    }

    fun removeRedrawOnTopOverride(objId: String) {
        if (objId in priorityOverrides) {
            priorityOverrides -= objId
            syncMarkersNow(false, trigger = "removeRedrawOnTopOverride")
        }
    }

    fun addActiveObjectOverride(obj: GeoObjectDetails? = null, objId: String? = null) {
        if ((obj?.id ?: objId).isNullOrEmpty()) {
            return
        } else {
            (obj?.id ?: objId)?.let { id ->
//                console.log("addActiveObjectOverride", obj?.id ?: objId, obj?.title)
//                hiddenMarkerIds -= id
                activeObjectIds += id
            }
        }

        syncMarkersNow(trigger = "addActiveObjectOverride")
    }

    fun removeAllActiveObjectOverrides(sync: Boolean = true) {
//        console.log("removeAllActiveObjectOverrides")
        activeObjectIds.clear()
        if (sync) syncMarkersNow(trigger = "removeAllActiveObjectOverrides")
    }

    fun removeActiveObjectOverride(id: String, sync: Boolean = true) {
//        console.log("removeActiveObjectOverride", id)
        activeObjectIds -= id
        if (sync) syncMarkersNow(trigger = "removeActiveObjectOverride")
    }

    fun addGeometryCenterOverride(obj: GeoObjectDetails, newCenterPoint: Geometry.Point, sync: Boolean = true) {
        geometryCenterOverrides[obj.id] = newCenterPoint
//        hiddenMarkerIds += obj.id
        if (sync) syncMarkersNow(trigger = "addGeometryCenterOverride")
    }

    fun removeGeometryCenterOverride(obj: GeoObjectDetails, sync: Boolean = true) {
        geometryCenterOverrides -= obj.id
//        hiddenMarkerIds -= obj.id
        if (sync) syncMarkersNow(updateGeoJSON = true, trigger = "removeGeometryCenterOverride")
    }

    fun addGeometryShapeOverride(obj: GeoObjectDetails, newGeometry: Geometry, sync: Boolean = true) {
        geometryShapeOverrides[obj.id] = newGeometry
        if (sync) syncMarkersNow(trigger = "addGeometryShapeOverride")
    }

    fun removeGeometryShapeOverride(obj: GeoObjectDetails, sync: Boolean = true) {
        geometryShapeOverrides -= obj.id
        if (sync) syncMarkersNow(updateGeoJSON = true, trigger = "removeGeometryShapeOverride")
    }

    fun addHiddenOverride(objId: String, sync: Boolean = true) {
        hiddenMarkerIds.add(objId)
        if (sync) syncMarkersNow(trigger = "addHiddenOverride")
    }

    fun removeHiddenOverride(objId: String, sync: Boolean = true) {
        hiddenMarkerIds.remove(objId)
        if (sync) syncMarkersNow(trigger = "removeHiddenOverride")
    }

    fun setPathToolHighlightOverrides(objIds: Set<String>, sync: Boolean = true) {
//        console.log("setPathToolHighlightOverrides", objIds.toTypedArray())
        pathToolHighlightedObjectIds.clear()
        pathToolHighlightedObjectIds.addAll(objIds)
        if (sync) syncMarkersNow(trigger = "setPathToolHighlightOverrides")
    }

    fun addPathToolHighlightOverrides(objIds: Set<String>, sync: Boolean = true) {
//        console.log("addPathToolHighlightOverrides", objIds.toTypedArray())
        pathToolHighlightedObjectIds.addAll(objIds)
        if (sync) syncMarkersNow(trigger = "addPathToolHighlightOverrides")
    }

    fun setHighlightOverrides(objIds: Set<String>, sync: Boolean = true) {
//        console.log("setHighlightOverrides", objIds.toTypedArray())
        highlightedObjectIds.clear()
        highlightedObjectIds.addAll(objIds)
        if (sync) syncMarkersNow(trigger = "setHighlightOverrides")
    }

    fun addHighlightOverrides(objIds: Set<String>, sync: Boolean = true) {
//        console.log("addHighlightOverrides", objIds.toTypedArray())
        highlightedObjectIds.addAll(objIds)
        if (sync) syncMarkersNow(trigger = "addHighlightOverrides")
    }

    /** Returns the geographical center of the map view */
    fun getCenter(): LngLat = map?.getCenter() ?: LngLat(0.0, 0.0)

    /** Returns the current zoom level of the map view */
    fun getZoom(): Double = map?.getZoom() ?: -1.0

    /**
     * Increases the zoom of the map by 1.
     * @param options
     */
    fun zoomIn(options: AnimationOptions? = null) {
        map?.zoomIn(options)
    }

    /**
     * Decreases the zoom of the map by 1.
     * @param options
     */
    fun zoomOut(options: AnimationOptions? = null) {
        map?.zoomOut(options)
    }

    /**
     * Zooms the map to the specified zoom level, with an animated transition.
     * @param options
     */
    fun zoomTo(zoom: Double, options: AnimationOptions? = null) {
        map?.zoomTo(zoom, options)
    }

    /**
     * Returns the geographical bounds visible in the current map view
     */

    fun getCardinalDirectionBounds(): LngLatBounds? {
        val bounds = map?.getBounds()
        return bounds
    }

    /**
     * Checks if the map container size changed and updates the map if so — call it after you've
     * changed the map size dynamically, also animating pan by default.
     */
    fun invalidateSize() {
        map?.resize()
    }

//    fun on(obj: dynamic = map, eventMap: dynamic){
//        obj?.on(eventMap)
//    }

    fun on(type: String, fn: () -> Unit, layerId: String? = null, fnId: String) {
        activeListeners[fnId] = fn
        if (layerId != null) {
            map?.on(type, layerId, fn)
        } else {
            map?.on(type, fn)
        }
    }

    fun on(type: String, fn: dynamic, layerId: String? = null, fnId: String) {
        activeListeners[fnId] = fn
        if (layerId != null) {
            map?.on(type, layerId, fn)
        } else {
            map?.on(type, fn)
        }
    }

    fun off(type: String, fn: dynamic = null, fnId: String? = null) {
        if (fn != null) {
            map?.off(type, fn)
        } else {
            if (fnId != null) {
                val listener = activeListeners[fnId]
                if (listener != null) {
                    activeListeners.remove(fnId)
                    map?.off(type, listener)
                }
            }
        }
    }

    fun once(type: String, fn: dynamic, fnId: String) {
        if (activeListeners[fnId] == null) {
            activeListeners[fnId] = fn
            map?.once(type, fn)
        }
    }

    fun isNotMoving(): Boolean {
        return if (map != null) {
            !(map?.isMoving() ?: false)
        } else true
    }

    fun isStyleLoaded(): Boolean {
        return if (map != null) {
            map?.isStyleLoaded() ?: false
        } else false
    }

    fun jumpTo(center: LatLon, zoom: Double, bearing: Double? = null, pitch: Double? = null) {
        map?.jumpTo(
            obj {
                this.center = doubleArrayOf(center.lon, center.lat).toTypedArray()
                this.zoom = zoom
                if (bearing != null) this.bearing = bearing
                if (pitch != null) this.pitch = pitch
            },
        )
    }

    fun panTo(center: LatLon, duration: Long? = null) {
        map?.panTo(
            lnglat = center.toLngLat(),
            options = jsApply {
                if (duration != null) this.duration = duration
            },
        )
    }

    /**
     * Changes any combination of center, zoom, bearing, and pitch,
     * animating the transition along a curve that evokes flight.
     * The animation seamlessly incorporates zooming and panning to help the user
     * maintain her bearings even after traversing a great distance.
     * @param center The desired center
     * @param zoom The desired zoom level
     * @param curve The zooming "curve" that will occur along the flight path.
     * A high value maximizes zooming for an exaggerated animation, while a low value minimizes zooming
     * for an effect closer to Map#easeTo. 1.42 is the average value selected by participants in a user study.
     * @param maxZoom The zero-based zoom level at the peak of the flight path. If options.curve is specified,
     * this option is ignored
     * @param speed The average speed of the animation defined in relation to options.curve.
     * A speed of 1.2 means that the map appears to move along the flight path by 1.2 times options.curve
     * screenfuls every second. A screenful is the map's visible span. It does not correspond to a fixed
     * physical distance, but varies by zoom level.
     * @param screenSpeed The average speed of the animation measured in screenfuls per second,
     * assuming a linear timing curve. If options.speed is specified, this option is ignored
     * @param maxDuration The animation's maximum duration, measured in milliseconds.
     * If duration exceeds maximum duration, it resets to 0.
     */

    fun flyTo(
        center: LatLon,
        zoom: Double,
        curve: Double = 1.42,
        maxZoom: Double? = null,
        speed: Double = 2.0,
        screenSpeed: Double? = null,
        maxDuration: Double? = null,
    ) {
        map?.flyTo(
            obj {
                this.center = doubleArrayOf(center.lon, center.lat).toTypedArray()
                this.zoom = zoom
                this.curve = curve
                if (maxZoom != null) this.maxZoom = maxZoom
                this.speed = speed
                if (screenSpeed != null) this.screenSpeed = screenSpeed
                if (maxDuration != null) this.maxDuration = maxDuration
            },
        )
    }

    /**
     * Changes any combination of center, zoom, bearing, pitch, and padding
     * with an animated transition between old and new values.
     * The map will retain its current values for any details not specified in options.
     * @param center The desired center
     * @param zoom The desired zoom level
     * - CameraOptions:
     * @param bearing The desired bearing in degrees. The bearing is the compass direction that is "up".
     * For example, bearing: 90 orients the map so that east is up
     * @param pitch The desired pitch in degrees. The pitch is the angle towards the horizon measured in degrees with
     * a range between 0 and 60 degrees. For example, pitch: 0 provides the appearance of looking straight down
     * at the map, while pitch: 60 tilts the user's perspective towards the horizon. Increasing the pitch value is
     * often used to display 3D active.objects.
     * @param around  If "zoom" is specified, around determines the point around which the zoom is centered
     * @param padding Dimensions in pixels applied on each side of the viewport for shifting the vanishing point
     * (PaddingOptions)
     * - AnimationOptions:
     * @param duration The animation's duration, measured in milliseconds.
     * @param animate If false, no animation will occur.
     */

    fun easeTo(
        center: LatLon, zoom: Double, bearing: Double? = null, pitch: Double? = null,
        around: LatLon? = null, padding: dynamic = null, duration: Double? = null, animate: Boolean? = null,
    ) {
        map?.easeTo(
            obj {
                this.center = doubleArrayOf(center.lon, center.lat).toTypedArray()
                this.zoom = zoom
                if (bearing != null) this.bearing = bearing
                if (pitch != null) this.pitch = pitch
                if (around != null) this.around = doubleArrayOf(around.lon, around.lat).toTypedArray()
                if (padding != null) this.padding = padding
                if (duration != null) this.duration = duration
                if (animate != null) this.animate = animate
            },
        )
    }

    fun setMyUserMarker(newUserMarker: MapMarkerMyUser) {
        myUserMarker.clear()
        myUserMarker.add(
            newUserMarker,
        )
        syncMarkersNow(updateGeoJSON = true, trigger = "setMyUserMarker")
    }
}
