package twcomponents

import auth.FeatureFlagStore
import auth.Features
import com.tryformation.localization.Translatable
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.Listener
import dev.fritz2.core.RenderContext
import dev.fritz2.core.ScopeContext
import dev.fritz2.core.title
import dev.fritz2.headless.components.Tooltip
import dev.fritz2.headless.components.tooltip
import dev.fritz2.headless.foundation.utils.floatingui.core.middleware.offset
import dev.fritz2.headless.foundation.utils.floatingui.utils.Placement
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import koin.koinCtx
import koin.withKoin
import kotlin.time.Duration.Companion.seconds
import kotlinx.coroutines.flow.debounce
import localization.TranslationStore
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLHeadingElement
import org.w3c.dom.events.UIEvent
import theme.IconEnum
import utils.isMobileOrTabletBrowser
import web.dom.document

/**
 * h1 section header intended for use in cards
 */
fun RenderContext.twCardSectionH1(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLHeadingElement>.() -> Unit
) {
    h1(
        baseClass = "py-2 flex flex-row gap-2 place-items-center",
        id = id,
        scope = scope,
    ) {
        content(this)
    }
}

/**
 * h2 section header intended for use in cards
 */
fun RenderContext.twCardSectionH2(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLHeadingElement>.() -> Unit
) {
    h2(
        baseClass = "py-2 flex flex-row gap-2 place-items-center",
        id = id,
        scope = scope,
    ) {
        content(this)
    }
}

/**
 * h3 section header intended for use in cards
 */
fun RenderContext.twCardSectionH3(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLHeadingElement>.() -> Unit
) {
    h3(
        baseClass = "py-2 flex flex-row gap-2 place-items-center",
        id = id,
        scope = scope,
    ) {
        content(this)
    }
}

/**
 * h4 section header intended for use in cards
 */
fun RenderContext.twCardSectionH4(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLHeadingElement>.() -> Unit
) {
    h4(
        baseClass = "py-2 flex flex-row gap-2 place-items-center",
        id = id,
        scope = scope,
    ) {
        content(this)
    }
}

/**
 * Highlighted and underlined section header intended for use in cards
 */
//fun RenderContext.twCardSectionTitle(
//    id: String? = null,
//    scope: (ScopeContext.() -> Unit) = {},
//    content: HtmlTag<HTMLElement>.() -> Unit
//) {
//    div(
//        baseClass = "text-lg py-2 flex flex-row gap-2 place-items-center font-bold tracking-widest underline decoration-highlight",
//        id = id,
//        scope = scope,
//    ) {
//        content(this)
//    }
//}

fun RenderContext.twCardSectionTitle(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLElement>.() -> Unit
) {
    div(
        baseClass = "text-lg py-2 flex flex-col gap-1 items-start justify-center font-bold tracking-widest", // underline decoration-formationBlack",
        id = id,
        scope = scope,
    ) {
        twRowOf {
            content(this)
        }
        // blue underline
        div("w-48 h-px bg-highlight") { }
    }
}

fun RenderContext.twMainTitle(
    id: String? = null,
    icon: IconEnum? = null,
    content: HtmlTag<HTMLElement>.() -> Unit
) {
    twRowOf {
        icon?.let { twIconLarge(icon) }
        p(
            baseClass = "flex flex-row items-center justify-center text-2xl font-bold",
            id = id,
        ) {
            content(this)
        }
    }
}

fun RenderContext.twTitle(
    id: String? = null,
    icon: IconEnum? = null,
    content: HtmlTag<HTMLElement>.() -> Unit
) {
    twRowOf {
        icon?.let { twIconMedium(icon) }
        p(
            baseClass = "flex flex-row items-center justify-center text-xl font-bold",
            id = id,
        ) {
            content(this)
        }
    }
}

fun RenderContext.twSubtitle(
    id: String? = null,
    icon: IconEnum? = null,
    content: HtmlTag<HTMLElement>.() -> Unit
) {
    twRowOf {
        icon?.let { twIconSmall(icon) }
        p(
            baseClass = "flex flex-row items-center justify-center text-sm font-light",
            id = id,
        ) {
            content(this)
        }
    }
}

/**
 * Renders the string as markdown in a markdown_content div. The markdown_content class triggers styling we need.
 */
fun RenderContext.twMarkdownContent(content: Translatable, args: Map<String, Any>? = null) {
    // we need some custom css rules to ensure the innerHTML looks alright
    div("markdown_content") {
        withKoin {
            val translation = get<TranslationStore>()
            val translated = translation.getString(content, args)
            domNode.innerHTML = mdService.markdown2html(translated)
        }
    }
}

private val featureFlagStore by lazy {
    koinCtx.get<FeatureFlagStore>()
}

/**
 * Only render the div if [flag] == [flagValue]. Defaults to ExperimentalFeatures and true.
 */
fun RenderContext.twFeatureFlagDiv(
    baseClass: String? = null,
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    flag: Features = Features.AllowExperimentalFeatures,
    flagValue: Boolean = true,
    content: HtmlTag<HTMLDivElement>.() -> Unit
) {
    featureFlagStore.data.render { features ->
        if (features[flag] == flagValue) {
            div(
                baseClass = baseClass,
                id = id,
                scope = scope,
            ) {
                content(this)
            }
        }
    }
}

/**
 * - Adds event listeners to a given [elementId] to add/remove the given string of [className]s to it
 * - Add-/Remove-Events can be defined as Map of [UIEvent] to [kotlin.time.Duration] (Duration for optional trigger delay)
 * - defaults to remove class on: mouseenters | focusins and add class on: mouseleaves | focusouts
 */

fun HtmlTag<HTMLElement>.toggleClassOnElement(
    className: String,
    elementId: String,
    removeClassEvents: Map<Listener<out UIEvent, HTMLElement>, kotlin.time.Duration> = mapOf(mouseenters to 0.seconds, focusins to 0.seconds),
    addClassEvents: Map<Listener<out UIEvent, HTMLElement>, kotlin.time.Duration> = mapOf(mouseleaves to 0.seconds, focusouts to 1.seconds)
) {
    removeClassEvents.forEach { (event, delay) ->
        event.debounce(delay) handledBy {
            document.getElementById(elementId)?.let { element ->
                if (element.classList.contains(className)) {
                    element.classList.remove(className)
                }
            }
        }
    }
    addClassEvents.forEach { (event, delay) ->
        event.debounce(delay) handledBy {
            document.getElementById(elementId)?.let { element ->
                if (!element.classList.contains(className)) {
                    element.classList.add(className)
                }
            }
        }
    }
}

fun RenderContext.contentSelectButton(content: HtmlTag<HTMLDivElement>.() -> Unit) {
    div("flex flex-row w-full text-sm gap-2 bg-gray-200 p-2 rounded-xl hover:bg-gray-100 cursor-pointer") {
        content.invoke(this)
    }
}

/**
 * Adds a simple tooltip with defined content to the HTMLElement
 */

fun HtmlTag<HTMLElement>.twTooltip(positioning: Placement = PlacementValues.top, fallback: String? = null, content: Tooltip<HTMLDivElement>.() -> Unit) =
    if (!isMobileOrTabletBrowser()) {
        this.tooltip("text-formationWhite bg-formationBlack opacity-80 text-sm py-1 px-2 rounded-lg z-[9999]") {
            placement = positioning
            addMiddleware(offset(10))
            arrow()
            content.invoke(this)
        }
    } else {
        fallback?.let { this.title(fallback) }
    }
