package data.objects.views.attachments

import apiclient.geoobjects.Content
import dev.fritz2.components.compat.img
import dev.fritz2.components.flexBox
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.src
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import theme.FormationDefault.Companion.formationStyles
import theme.FormationUIIcons
import webcomponents.baseLayout
import webcomponents.cardTitle
import webcomponents.twContentScrollBox
import webcomponents.genericSmallIconButton
import webcomponents.oneButtonFooter

class ImagePreviewStore : RootStore<Content.Image?>(
    initialData = null,
    job = Job(),
)

fun RenderContext.cardImagePreview() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val imagePreviewStore: ImagePreviewStore by koinCtx.inject()

    baseLayout(
        header = {
            cardTitle(
                imagePreviewStore.data.map {
                    it?.title?.let { title -> title.ifBlank { translation.getString(TL.Attachments.ATTACHED_IMAGE) } }
                        ?: translation.getString(TL.Attachments.ATTACHED_IMAGE)
                },
            ) { FormationUIIcons.Image.icon }
        },
        content = {
            twContentScrollBox {
                imagePreviewStore.data.render { attachment ->
                    if (attachment != null && attachment.href.isNotBlank()) {
                        flexBox(
                            {
                                height { full }
                                width { full }
                                alignItems { center }
                                justifyContent { center }
                            },
                        ) {
                            img(
                                {
                                    css("object-fit: contain;")
                                    width { full }
                                    maxHeight { full }
                                    maxWidth { full }
//                                border {
//                                    color { primary.main }
//                                    width(formationStyles.borderWidth)
//                                }
//                                radius { larger }
                                },
                            ) {
                                src(attachment.href)
                            }
                        }
                        flexBox(
                            {
                                maxWidth(sm = { full }, md = formationStyles.cardWidth)
                                wrap { wrap }
                                alignItems { center }
                                justifyContent { flexStart }
                            },
                        ) {
                            imagePrevData(value = attachment.mimeType.removePrefix("image/"))
                            imagePrevData(value = "${attachment.width} x ${attachment.height} px")
                        }
                        flexBox(
                            {
                                width { full }
                                alignItems { center }
                                justifyContent { center }
                                margins { top { small } }
                            },
                        ) {
                            genericSmallIconButton(
                                title = translation[TL.General.OPEN_IN_NEW_TAB],
                                icon = { FormationUIIcons.ExternalLink.icon },
                                hrefOrValue = attachment.href,
                                isLink = true,
                            )
                        }
                    } else {
                        flexBox(
                            {
                                width { full }
                                height { full }
                                alignItems { center }
                                justifyContent { center }
                            },
                        ) {
                            span { translation[TL.Attachments.NO_PREVIEW_AVAILABLE].renderText(into = this) }
                        }
                    }
                }
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.BACK],
                value = Unit,
                clickHandlers = listOf(routerStore.back),
            )
        },
    )

}
