package localization

import apiclient.analytics.AnalyticsTranslatables
import apiclient.markers.DefaultLayers
import apiclient.markers.SearchLayers
import apiclient.users.NotificationSetting
import apiclient.users.NotificationSettingValue
import camera.nimiq.InversionMode
import com.tryformation.localization.Translatable
import data.objects.views.IconGroup
import map.views.MapStyleOffline
import map.views.MapStyleOnline
import workspacetools.WorkspaceToolsMenu
import workspacetools.usermanagement.DashboardTexts
import workspacetools.workspaceoptions.WorkspaceNameState

object TL {
    val translatables: List<Translatable> = listOf(
        // translatable enum classes inside this object
        Locales.entries,
        General.entries,
        Cookie.entries,
        Login.entries,
        MainMenu.entries,
        BottomBar.entries,
        UserProfile.entries,
        LocationSharing.entries,
        ApplicationSettings.entries,
        CardToolSelect.entries,
        WorkPlaceTools.entries,
        CardCreate.entries,
        CardAttendees.entries,
        CardCustomizeBadge.entries,
        CardDateTime.entries,
        CardDueDate.entries,
        CardDeleteConfirmation.entries,
        PageLogout.entries,
        CardDescription.entries,
        CardEdit.entries,
        CardInfo.entries,
        CardMyUser.entries,
        PublicUserProfile.entries,
        CardMapLayerSelection.entries,
        CardMapLayers.entries,
        CardMapStyles.entries,
        CardTaskState.entries,
        CardUserSelect.entries,
        ObjectTypeTitle.entries,
        TaskStateTitle.entries,
        TaskStateSubTitle.entries,
        DateTime.entries,
        Search.entries,
        MapTools.entries,
        MonthAbbreviations.entries,
        PageTerms.entries,
        GenericSwitch.entries,
        LoginSplash.entries,
        AlertNotifications.entries,
        PWEvaluations.entries,
        MeetingInvitationStatus.entries,
        Notifications.entries,
        Signup.entries,
        CodeScanning.entries,
        Status.entries,
        Feedback.entries,
        KeywordTags.entries,
        CardObjectHistory.entries,
        ObjectHistory.entries,
        CardCustomData.entries,
        CardNestedObjects.entries,
        CardManageKeywordLayer.entries,
        LayerAction.entries,
        CardSecondaryMenu.entries,
        SpinnerTexts.entries,
        PageActivation.entries,
        PageDisclaimer.entries,
        OnBoarding.entries,
        PageHelp.entries,
        CardBrowserQrScanner.entries,
        CardBrowserPhotoCamera.entries,
        CardEditPosition.entries,
        Attachments.entries,
        AttachmentTypeTrans.entries,
        PopUpSignUp.entries,
        UserVerification.entries,
        Poll.entries,
        ReadOnlyTagTranslations.entries,
        TimeAbbrevations.entries,
        PageHub.entries,
        AppVersion.entries,
        CardClusterPrev.entries,
        AnalyticsTimefilter.entries,
        CardZoneEditor.entries,
        DeleteAccount.entries,
        SuspendedAccount.entries,
        ImportExport.entries,
        SearchFilterTexts.entries,
        OnBoardingWizardTexts.entries,
        Comments.entries,
        PermissionPromptTexts.entries,
        CreateWorkspaceTexts.entries,
        // webapp (internal) translatable enum classes
        MapStyleOnline.entries,
        MapStyleOffline.entries,
        IconGroup.entries,
        InversionMode.entries,
        WorkspaceNameState.entries,
        // api client (external) translatable enum classes
        DefaultLayers.entries,
        SearchLayers.entries,
        DashboardTexts.entries,
        NotificationSetting.entries,
        NotificationSettingValue.entries,
        AnalyticsTranslatables.entries,
        Comments.entries,
        WorkspaceToolsMenu.entries,
    ).flatten()

    enum class General : Translatable {
        BACK,
        CANCEL,
        SAVE,
        SET,
        DONE,
        NEXT,
        CLOSE,
        CREATE,
        EDIT,
        UPDATE,
        CHANGE,
        KEEP,
        DELETE,
        NONE,
        NO_ONE,
        DESCRIPTION,
        ON,
        OFF,
        COMING_SOON,
        YOU,
        YES,
        NO,
        MOVE_TO_MY_LOCATION,
        AT_TIME,
        AT_DATE,
        BY,
        ACTIVATE_ACCOUNT,
        QUIT,
        RESTART,
        OPEN_IN_NEW_TAB,
        SELECT,
        REVERT,
        ARCHIVED,
        FLAGGED,
        LOGIN,
        SIGN_UP,
        LINK,
        DOWNLOAD,
        DISCARD
        ;

        override val prefix: String = "general"
    }

    enum class Cookie : Translatable {
        QUERY,
        ACCEPT,
        REJECT,
        DISCLAIMER,
        REJECT_TITLE,
        REJECT_WARNING,
        REJECT_AGAIN,
        ACCEPT_NECESSARY,
        CONSENT_BUTTON,
        DISCLAIMER_BUTTON
        ;

        override val prefix: String = "cookie"
    }

    enum class Login : Translatable {
        WORKSPACE,
        EMAIL_AND_PASSWORD,
        EMAIL,
        PASSWORD,
        FORGOT_PASSWORD,
        RESET_PASSWORD,
        RESET_PW_INFO_TEXT,
        EMAIL_SEND,
        RESET_PW_LINK_SEND_TEXT,
        RESET_PW_TEXT_CHECK_YOUR_EMAIL,
        SEND_PW_RESET_LINK,
        LOGIN_ONGOING,
        LOGIN,
        LOGIN_NEED_HELP,
        LOGIN_INFO_TEXT,
        DO_NOT_HAVE_ACCOUNT,
        OPEN_FOR_ANONYMOUS_ACCESS,
        PROCEED_ANONYMOUSLY,
        LOADING,
        WELCOME,
        LOGIN_WITH_ACCOUNT,
        OR,
        SIGN_IN,
        SIGN_IN_VIA_EMAIL_BUTTON,
        SIGN_IN_EMAIL_SEND_TEXT,
        SIGN_IN_CHECK_SPAM,
        SIGN_IN_DID_NOT_GET_EMAIL_BUTTON,
        SIGN_IN_USE_DIFFERENT_EMAIL_BUTTON,
        SIGN_IN_BACK_TO_LOGIN_WITH_PASSWORD_BUTTON,
        ;

        override val prefix: String = "login"
    }

    enum class MainMenu : Translatable {
        WORKPLACE_TOOLS,
        WORKPLACE_TOOLS_SUBTITLE,
        INSIGHTS_SUITE,
        INSIGHTS_SUITE_SUBTITLE,
        PROFILE,
        PROFILE_SUBTITLE,
        LOCATION_SHARING,
        LOCATION_SHARING_SUBTITLE,
        APPLICATION_SETTINGS,
        APPLICATION_SETTINGS_SUBTITLE,
        TC,
        TC_SUBTITLE,
        WEBSITE_LINK,
        WEBSITE_LINK_SUBTITLE,
        LOGOUT,
        LOGOUT_SUBTITLE,
        LOGIN,
        LOGIN_SUBTITLE,
        SIGN_UP,
        SIGN_UP_SUBTITLE,
        NOTIFICATION_CENTER,
        NOTIFICATION_CENTER_SUBTITLE,
        FEEDBACK,
        FEEDBACK_SUBTITLE,
        HELP,
        HELP_SUBTITLE,
        QUICK_TOUR,
        QUICK_TOUR_SUBTITLE,
        LoginWithDifferentDevice,
        LoginWithDifferentDeviceSubtitle,
        ABOUT,
        ABOUT_SUBTITLE,
        ;

        override val prefix: String = "mainmenu"
    }

    enum class BottomBar : Translatable {
        MENU,
        HUB,
        ADD,
        ADD_TOOLTIP,
        MAP,
        SCAN,
        SEARCH,
        ;

        override val prefix: String = "bottombar"
    }

    enum class UserProfile : Translatable {
        EMAIL,
        ACCOUNT_EMAIL,
        PUBLIC_EMAIL,
        CONTACT_EMAIL,
        PHONE,
        EDIT_PROFILE,
        FIRST_NAME,
        LAST_NAME,
        JOB_TITLE,
        PHONE_NUMBER,
        SAVE_PROFILE,
        PASSWORD_VIEW1_HEADER,
        PASSWORD_VIEW1_TITLE,
        OLD_PASSWORD,
        SET_PASSWORD,
        PASSWORD_VIEW2_HEADER,
        PASSWORD_VIEW2_TITLE1,
        PASSWORD_VIEW2_TITLE2,
        NEW_PASSWORD,
        NEW_PASSWORD_RE,
        PASSWORD_VIEW3_HEADER,
        PASSWORD_VIEW3_TITLE,
        PASSWORD_NO_MATCH,
        VIEW_PROFILE,
        LOCATE_USER,
        CHAT_TOOLTIP,
        NEW_PASSWORD_NEEDED,
        NEW_PASSWORD_INFO_TEXT,
        SET_NEW_PASSWORD,
        PROFILE_PHOTO,
        DELETE_PICTURE,
        DELETE_PICTURE_SUBTITLE,
        DELETE_ARE_YOU_SURE,
        UPLOAD_A_PICTURE,
        UPLOAD_A_PICTURE_SUBTITLE,
        TAKE_A_PHOTO,
        TAKE_A_PHOTO_SUBTITLE,
        PASSWORD,
        COMPANY,
        LINKEDIN,
        WEBSITE,
        WEBLINK,
        CELLPHONE,
        KEYWORDS,
        ;

        override val prefix: String = "userprofile"
    }

    enum class LocationSharing : Translatable {
        HEADER,
        DESCRIPTION,
        TURN_SHARING_ON,
        TURN_SHARING_OFF,
        LOCATION_SHARING_ON,
        LOCATION_SHARING_OFF,
        NO_POSITION_FOUND,
        POSITION_PERMISSION_DENIED,
        POSITION_TIMEOUT,
        SHARING_DISABLED,
        SHARING_DISCLAIMER,
        ;

        override val prefix: String = "locationsharing"

    }

    enum class ApplicationSettings : Translatable {
        HEADER,
        LOCALIZATION,
        LOCALIZATION_SUBTITLE,
        SELECT_LOCALE_PLACEHOLDER,
        SELECT_TIMEZONE_PLACEHOLDER,
        LOCATION_SHARING,
        LOCATION_SHARING_SUBTITLE,
        REMEMBER_LOCATION_SHARING_STATE,
        REMEMBER_LOCATION_SHARING_STATE_DESC,
        MAP,
        MAP_SUBTITLE,
        TRIGGER_REFRESH_MANUALLY,
        TRIGGER_REFRESH_PERIODICALLY,
        NOTIFICATIONS,
        NOTIFICATIONS_SUBTITLE,
        HIDE_MARKER_TITLES,
        EXPERIMENTAL,
        EXPERIMENTAL_SUBTITLE,
        SHOW_HISTORY_PATH,
        LOCATION_SPOOFING,
        LOCATION_SPOOFING_SUBTITLE,
        CLUSTER_OBJECTS,
        THEME,
        THEME_SUBTITLE,
        ;

        override val prefix: String = "applicationsettings"
    }

    enum class CardToolSelect : Translatable {
        HEADER,
        DESCRIPTION,
        CLOSE_TOOLTIP,
        ADD_TOOLTIP,
        ;

        override val prefix: String = "cardtoolselect"
    }

    enum class WorkPlaceTools : Translatable {
        POINT,
        TASK,
        MEET,
        ZONE,
        TRACKING,
        NFC,
        CHAT,
        ROUTING,
        ;

        override val prefix: String = "workplacetools"
    }

    enum class CardCreate : Translatable {
        POI_PLACEHOLDER,
        TASK_PLACEHOLDER,
        MEETING_PLACEHOLDER,
        ZONE_PLACEHOLDER,
        TRACKED_OBJECT_PLACEHOLDER,
        CREATE_NEW,
        CUSTOMIZE_BADGE,
        ATTENDEES,
        ASSIGNEE,
        ;

        override val prefix: String = "cardcreate"

    }

    enum class CardAttendees : Translatable {
        CARD_TITLE,
        ;

        override val prefix: String = "cardattendees"

    }

    enum class CardCustomizeBadge : Translatable {
        COLOUR,
        ICON,
        SHAPE,
        ICON_SEARCH_PLACEHOLDER
        ;

        override val prefix: String = "cardcustomizebadge"
    }

    enum class CardDateTime : Translatable {
        CARD_TITLE,
        MINUTE,
        DURATION_IN_MINUTES,
        DATE,
        TIME,
        ;

        override val prefix: String = "carddatetime"

    }

    enum class CardDueDate : Translatable {
        CARD_TITLE,
        ;

        override val prefix: String = "cardduedate"

    }

    enum class CardDeleteConfirmation : Translatable {
        CARD_TITLE,
        ARE_YOU_SURE,
        ;

        override val prefix: String = "carddeleteconfirmation"

    }

    enum class PageLogout : Translatable {
        CARD_TITLE,
        ARE_YOU_SURE,
        STAY,
        LOGOUT,
        ;

        override val prefix: String = "pagelogout"

    }

    enum class CardDescription : Translatable {
        CARD_TITLE,
        ;

        override val prefix: String = "carddescription"
    }

    enum class CardEdit : Translatable {
        POI_NAME,
        TASK_NAME,
        MEETING_NAME,
        EDIT_EVENT,
        CUSTOMIZE_BADGE,
        CHANGE_GEOFENCE,
        ATTENDEES,
        ASSIGNEE,
        ;

        override val prefix: String = "cardedit"

    }

    enum class CardEditPosition : Translatable {
        EDIT_POSITION_INSTRUCTIONS
        ;

        override val prefix: String = "cardeditposition"

    }

    enum class CardInfo : Translatable {
        ADDRESS,
        CREATED_BY,
        CREATED_AT,
        UPDATED_AT,
        UPDATED_BY,
        ASSIGNED_TO,
        ASSIGN_YOURSELF,
        UNASSIGN_YOURSELF,
        STATUS,
        YOUR_STATUS,
        SET_YOUR_MEETING_STATUS,
        TIME,
        ATTENDEES,
        EDIT_TYPE,
        TITLE,
        DUE_DATE,
        CONTAINED_OBJECTS,
        FLOORS,
        OBJECT_DETAILS,
        OWNED_BY
        ;

        override val prefix: String = "cardinfo"
    }

    enum class CardMyUser : Translatable {
        STATUS,
        SHARING,
        SETTINGS,
        ;

        override val prefix: String = "cardmyuser"
    }

    enum class PublicUserProfile : Translatable {
        EMAIL,
        PHONE,
        ;

        override val prefix: String = "publicuserprofile"
    }

    enum class CardMapLayerSelection : Translatable {
        MAP_LAYERS,
        MAP_STYLES,
        SEE_ALL,
        ;

        override val prefix: String = "cardmaplayerselection"
    }

    enum class CardMapLayers : Translatable {
        CARD_TITLE,
        ADD_LAYER,
        ADD_LAYER_TOOLTIP,
        ;

        override val prefix: String = "cardmaplayers"
    }

    enum class CardMapStyles : Translatable {
        CARD_TITLE,
        ;

        override val prefix: String = "cardmapstyles"
    }

    enum class CardTaskState : Translatable {
        CARD_TITLE,
        ;

        override val prefix: String = "cardtaskstate"

    }

    enum class CardUserSelect : Translatable {
        CARD_TITLE,
        RESULTS,
        ALL,
        ANYONE,
        ANYONE_DESCRIPTION,
        CREATOR,
        ;

        override val prefix: String = "carduserselect"
    }

    enum class ObjectTypeTitle : Translatable {
        POI,
        TASK,
        EVENT,
        USER_MARKER,
        CURRENT_USER_MARKER,
        OBJECT_MARKER,
        BUILDING,
        FLOOR,
        UNIT,
        AREA,
        TRANSIENT_MARKER,
        CLUSTER,
        NOTIFICATION,
        ZONE,
        GEOFENCE,
        HISTORY_ENTRY,
        GENERAL_MARKER
        ;

        override val prefix: String = "objecttypetitle"
    }

    enum class TaskStateTitle : Translatable {
        UNASSIGNED,
        INPROGRESS,
        COMPLETED,
        PENDING,
        PROBLEM,
        ;

        override val prefix: String = "taskstatetitle"
    }

    enum class TaskStateSubTitle : Translatable {
        UNASSIGNED,
        INPROGRESS,
        COMPLETED,
        PENDING,
        PROBLEM,
        ;

        override val prefix: String = "taskstatesubtitle"
    }

//    enum class Locale : Translatable {
//        EN_US,
//        EN_GB,
//        DE_DE,
//        ES_ES,
//        PT_PT,
//        NL_NL,
//        UA_UA,
//        ;
//
//        override val prefix: String = "locale"
//    }

    enum class DateTime : Translatable {
        TODAY,
        TIME_NOT_SET,
        ;

        override val prefix: String = "datetime"
    }

    enum class Search : Translatable {
        PAGE_SEARCH_TITLE,
        FILTER_PEOPLE,
        FILTER_PLACES,
        FILTER_THINGS,
        YOU_ARE_INVITED,
        FIND_IT_FAST,
        FOR,
        NO_RESULTS,
        NO_NOTIFICATIONS,
        NO_MESSAGES,
        MANAGE_SEARCH_FILTERS
        ;

        override val prefix: String = "search"
    }

    enum class MapTools : Translatable {
        SHARING_BUTTON_TOOLTIP,
        FOLLOW_BUTTON_TOOLTIP,
        LAYER_BUTTON_TOOLTIP,
        ZOOM_IN_TOOLTIP,
        ZOOM_OUT_TOOLTIP,
        SEARCH_HERE,
        ;

        override val prefix: String = "maptools"
    }

    enum class MonthAbbreviations : Translatable {
        JAN,
        FEB,
        MAR,
        APR,
        MAY,
        JUN,
        JUL,
        AUG,
        SEP,
        OCT,
        NOV,
        DEC,
        ;

        override val prefix: String = "monthabbreviations"
    }

    enum class PageTerms : Translatable {
        PAGE_TITLE,
        I_AGREE,
        BUTTON_LOADING_TEXT,
        AGREE_DATE_INFO,
        I_HAVE_READ_T_AND_C,
        INFO_TEXT,
        ;

        override val prefix: String = "pageterms"
    }

    enum class GenericSwitch : Translatable {
        ON,
        OFF,
        ;

        override val prefix: String = "genericswitch"
    }

    enum class LoginSplash : Translatable {
        LOGGING_IN,
        ;

        override val prefix: String = "loginsplash"
    }

    enum class AlertNotifications : Translatable {
        LOGGED_IN_SUCCESSFULLY,
        WRONG_USER_NAME_OR_PW,
        INCORRECT_VERSION,
        OTHER_FAILURE,
        UNKNOWN_FAILURE,
        LOGIN_ERROR,
        LOCATION_FOLLOW_ON,
        LOCATION_FOLLOW_OFF,
        OBJECT_SUCCESSFULLY_CREATED,
        OBJECT_CREATION_FAILED,
        OBJECT_SUCCESSFULLY_UPDATED,
        OBJECT_UPDATE_FAILED,
        OBJECT_SUCCESSFULLY_DELETED,
        OBJECT_DELETION_FAILED,
        OBJECT_STATE_SUCCESSFULLY_CHANGED,
        OBJECT_STATE_CHANGE_FAILED,
        TASK_ASSIGNED_TO_USER,
        TASK_UNASSIGNED,
        TASK_ASSIGNMENT_FAILED,
        TASK_UNASSIGNMENT_FAILED,
        MEETING_INVITATIONS_SUCCESSFULLY_UPDATED,
        MEETING_INVITATIONS_UPDATE_FAILED,
        PW_CHANGE_ERROR,
        PW_CHANGE_SUCCESSFUL,
        PWS_DO_NOT_MATCH,
        CURRENT_PW_NOT_CORRECT,
        USER_NOT_LOGGED_IN,
        SIGNED_UP_AND_LOGGED_IN_SUCCESSFULLY,
        BAD_PASSWORD,
        EMAIL_ALREADY_EXISTS,
        WORKSPACE_NOT_FOUND,
        TERMS_NOT_ACCEPTED,
        DEEPLINK_NOT_FOUND,
        DEEPLINK_OBJECT_NOT_FOUND,
        SCANNED_OBJECT_NOT_FOUND,
        SCANNING_OBJECT,
        SCANNED_OBJECT_POSITION_UPDATED,
        SCANNED_OBJECT_POSITION_UPDATE_FAILED,
        NO_POSITION_FOUND,
        POSITION_REQUIRED_TO_CREATE_TRACKED_OBJECT,
        FEEDBACK_SEND_SUCCESSFULLY,
        FEEDBACK_NOT_SEND,
        MAP_LAYER_SUCCESSFULLY_CREATED,
        MAP_LAYER_SUCCESSFULLY_UPDATED,
        MAP_LAYER_SUCCESSFULLY_DELETED,
        MAP_LAYER_CREATION_FAILED,
        MAP_LAYER_UPDATE_FAILED,
        MAP_LAYER_DELETION_FAILED,
        USER_PROFILE_SUCCESSFULLY_UPDATED,
        USER_PROFILE_UPDATE_FAILED,
        USER_PROFILE_FIRST_NAME_BLANK,
        USER_PROFILE_LAST_NAME_BLANK,
        ACCOUNT_CREATED_SUCCESSFULLY,
        ACCOUNT_ACTIVATED_SUCCESSFULLY,
        RESEND_CODE_SUCCESSFULLY,
        FILE_TOO_LARGE,
        FILE_TYPE_NOT_SUPPORTED,
        ACCOUNT_DELETED_SUCCESSFULLY,
        ACCOUNT_DELETION_FAILED,
        ;

        override val prefix: String = "alertnotifications"
    }

    enum class PWEvaluations : Translatable {
        UNACCEPTABLE,
        WEAK,
        ACCEPTABLE,
        STRONG,
        ;

        override val prefix: String = "pwevaluations"
    }

    enum class MeetingInvitationStatus : Translatable {
        PENDING,
        MAYBE,
        ACCEPTED,
        REJECTED,
        UNKNOWN,
        ;

        override val prefix: String = "meetinginvitationstatus"
    }

    enum class Notifications : Translatable {
        NOTIFICATIONS,
        MESSAGES,
        OLDER_ALERTS,
        OLDER_MESSAGES,
        NEW,
        MEETING_INVITED,
        MEETING_INVITE_STATUS_CHANGE,
        MEETING_INVITE_REMOVED,
        TASK_ASSIGNED,
        TASK_UNASSIGNED,
        TASK_STATE_CHANGED,
        OBJECT_MODIFIED,
        MEETING_INVITED_SHORT,
        MEETING_INVITE_STATUS_CHANGE_SHORT,
        MEETING_INVITE_REMOVED_SHORT,
        TASK_ASSIGNED_SHORT,
        TASK_UNASSIGNED_SHORT,
        TASK_STATE_CHANGED_SHORT,
        OBJECT_MODIFIED_SHORT,
        MARK_ALL_NEW_AS_READ,
        REMINDER_EVENT,
        REMINDER_EVENT_SHORT,
        REMINDER_TASK,
        REMINDER_TASK_SHORT
        ;

        override val prefix: String = "notifications"
    }

    enum class Signup : Translatable {
        SIGN_UP,
        FIRST_NAME,
        LAST_NAME,
        WORKSPACE,
        RE_ENTER_PASSWORD,
        CREATE_ACCOUNT,
        CREATE_ACCOUNT_ONGOING,
        GO_TO_LOGIN,
        ALREADY_HAVE_ACCOUNT,
        READ_MORE_ABOUT,
        GOT_INVITED_BY,
        GOT_INVITED,
        ;

        override val prefix: String = "signup"
    }

    enum class CodeScanning : Translatable {
        QR_SCANNER,
        NFC_SCANNER,
        START_SCAN,
        INSERT_CODE_MANUALLY,
        CODE_ENTERED,
        UPDATE_LOCATION,
        CREATE_OBJECT_QUESTION,
        CREATE_NOT_ALLOWED,
        INSTRUCTIONS_OBJECT_SCANNER,
        INSTRUCTIONS_NFC_SCANNER,
        INSTRUCTIONS_ENTER_MANUALLY,
        QR_SCANNER_PROMPT,
        LOCATION_PERMISSION_QUESTION,
        ALLOW_LOCATION_PERMISSION,
        LOCATION_PERMISSION_ALTERNATIVE,
        UPDATE_LOCATION_MANUALLY,
        CHECK_MANUAL_CODE,
        SCANNED_CODE,
        CONNECT_CODE_TO,
        CODE_ALREADY_IN_USE,
        CODE_REJECTED,
        ;

        override val prefix: String = "codescanning"
    }

    enum class Status : Translatable {
        ENABLED,
        DISABLED,
        NOT_AVAILABLE,
        UNKNOWN,
        ;

        override val prefix: String = "status"
    }

    enum class Feedback : Translatable {
        CARD_TITLE,
        SUBJECT_PLACEHOLDER,
        MESSAGE_PLACEHOLDER,
        THANK_YOU,
        THANK_TEXT,
        CANCEL_TITLE,
        CANCEL_QUESTION,
        SEND,
        ;

        override val prefix: String = "feedback"
    }

    enum class KeywordTags : Translatable {
        CARD_TITLE,
        TAG_INPUT_PLACEHOLDER,
        TAG_INPUT_PLACEHOLDER_KEYWORD_LAYER,
        ADD_A_TAG_TOOLTIP,
        TAGS,
        VALUE_TAGS,
        SUGGESTED,
        ADDED,
        SELECT_A_VALUE,
        CREATE_TAG,
        TYPE,
        MANAGE_TAGS
        ;

        override val prefix: String = "keywordtags"
    }

    enum class CardObjectHistory : Translatable {
        CARD_TITLE,
        ;

        override val prefix: String = "cardobjecthistory"
    }

    enum class ObjectHistory : Translatable {
        DELETE,
        CREATE,
        UPDATE,
        EVENT_UPDATE_INVITE_STATUS,
        EVENT_ADD_INVITEES,
        EVENT_REMOVE_INVITEES,
        TASK_ASSIGNMENT_UPDATE,
        TASK_ASSIGNMENT_REMOVED,
        TASK_ASSIGNMENT_UPDATE_SELF,
        TASK_STATE_CHANGED,
        TRACKED_OBJECT_LOCATION_UPDATE,
        TRACKED_OBJECT_ENTERED_ZONE,
        TRACKED_OBJECT_EXITED_ZONE,
        TRACKED_OBJECT_INSIDE_ZONE,
        OCCUPANT_ENTERED_ZONE,
        OCCUPANT_EXITED_ZONE,
        BUILDING_ADD_FLOOR,
        TRACKED_OBJECT_RELEASED,
        OBJECT_ARCHIVED,
        OBJECT_MOVED,
        OWNER_CHANGED,
        ;

        override val prefix: String = "objecthistory"
    }

    enum class CardCustomData : Translatable {
        CARD_TITLE,
        BUTTON_TITLE,
        ;

        override val prefix: String = "cardcustomdata"
    }

    enum class CardNestedObjects : Translatable {
        CARD_TITLE,
        BUTTON_TITLE,
        LOAD_MORE,
        ;

        override val prefix: String = "cardnestedobjects"
    }

    enum class CardManageKeywordLayer : Translatable {
        TITLE_INPUT_PLACEHOLDER,
        DEFAULT_VISIBILITY_DESC,
        ANY_TAG_CAN_MATCH,
        ALL_TAGS_MUST_MATCH,
        ;

        override val prefix: String = "cardmanagekeywordlayer"
    }

    enum class LayerAction : Translatable {
        CREATE_NEW_LAYER,
        EDIT_LAYER,
        DELETE_LAYER,
        ;

        override val prefix = "layeraction"
    }

    enum class LayerTypeTranslation : Translatable {
        OBJECT,
        HEATMAP,
        ;

        override val prefix = "layertype"
    }

    enum class CardSecondaryMenu : Translatable {
        DUPLICATE,
        DUPLICATE_THIS,
        DETAILS,
        SHOW_DETAILS,
        SHARE,
        SHARE_THIS,
        RELEASE_AND_ARCHIVE_CODE,
        RELEASE_AND_ARCHIVE_CODE_SUBTITLE,
        RELEASE_AND_ARCHIVE_CODE_ARE_YOU_SURE,
        ARCHIVE,
        ARCHIVE_SUBTITLE,
        ARCHIVE_ARE_YOU_SURE,
        UNARCHIVE,
        UNARCHIVE_SUBTITLE,
        UNARCHIVE_ARE_YOU_SURE,
        OBJECT_HISTORY,
        OBJECT_HISTORY_SUBTITLE,
        FLAG,
        FLAG_SUBTITLE,
        FLAG_ARE_YOU_SURE,
        UN_FLAG,
        UN_FLAG_SUBTITLE,
        UN_FLAG_ARE_YOU_SURE,
        CONNECT_TO_QR_CODE,
        CONNECT_TO_QR_CODE_SUBTITLE,
        RELEASE_CODE,
        RELEASE_CODE_SUBTITLE,
        RELEASE_CODE_ARE_YOU_SURE,
        HIDE_MARKER,
        HIDE_MARKER_SUBTITLE,
        SHOW_MARKER,
        SHOW_MARKER_SUBTITLE,
        MORE,
        ;

        override val prefix = "cardsecondarymenu"
    }

    enum class SpinnerTexts : Translatable {
        CREATING,
        UPDATING,
        DELETING,
        ATTACHING,
        SEARCHING,
        ;

        override val prefix = "spinnertexts"
    }

    enum class PageActivation : Translatable {
        ACTIVATION_CODE_PLACEHOLDER,
        RESEND_CODE,
        SENDING
        ;

        override val prefix = "pageactivation"
    }

    enum class PageDisclaimer : Translatable {
        PAGE_TITLE,
        ;

        override val prefix = "pagedisclaimer"
    }

    enum class OnBoarding : Translatable {
        QUICK_TOUR,
        QUICK_TOUR_TEXT,
        THE_INTERFACE,
        THE_INTERFACE_TEXT,
        THE_MAP,
        THE_MAP_TEXT,
        THE_NAVIGATION_BAR,
        THE_NAVIGATION_BAR_TEXT,
        YOUR_WORKSPACE,
        YOUR_WORKSPACE_TEXT,
        ;

        override val prefix = "onboarding"
    }

    enum class PageHelp : Translatable {
        FORMATION4UKRAINE_WEBSITE_DESC,
        ;

        override val prefix = "pagehelp"
    }

    enum class CardBrowserQrScanner : Translatable {
        CAMERA_LIST_PLACEHOLDER,
        QR_DETECTION_MODE,
        QR_DETECTION_MODE_PLACEHOLDER,
        SWITCH_CAMERA,
        LIGHT,
        DEFAULT_BACK_CAMERA,
        DEFAULT_FRONT_CAMERA,
        ;

        override val prefix = "cardbrowserqrscanner"
    }

    enum class CardBrowserPhotoCamera : Translatable {
        TAKE_PHOTO,
        ;

        override val prefix = "cardbrowserphotocamera"
    }

    enum class Attachments : Translatable {
        ATTACHMENTS,
        SEE_ALL_ATTACHMENTS,
        ATTACHED_TEXT_NOTE,
        ATTACHED_IMAGE,
        NO_PREVIEW_AVAILABLE,
        CREATE_NEW_ATTACHMENT,
        NEW_OR_EDITED_ATTACHMENTS,
        ADD_IMAGE,
        CREATE_WEBLINK,
        CREATE_TEXT_NOTE,
        SELECT_OBJECT,
        WEBLINK_TITLE_PLACEHOLDER,
        WEBLINK_LINK_PLACEHOLDER,
        GEO_OBJECT_TITLE_PLACEHOLDER,
        GEO_OBJECT_OBJECT_ID_PLACEHOLDER,
        GEO_OBJECT_HINT,
        TEXT_NOTE_TITLE_PLACEHOLDER,
        TEXT_NOTE_CONTENT_PLACEHOLDER,
        MARKDOWN_HELP_LINK,
        TAKE_A_PHOTO,
        UPLOAD_IMAGE,
        IMAGE_TITLE_PLACEHOLDER,
        POLL_TITLE_PLACEHOLDER
        ;

        override val prefix: String = "attachments"

    }

    enum class AttachmentTypeTrans : Translatable {
        IMAGE,
        IMAGE_SUBTITLE,
        MARKDOWN,
        MARKDOWN_SUBTITLE,
        WEBLINK,
        WEBLINK_SUBTITLE,
        GEO_OBJECT,
        GEO_OBJECT_SUBTITLE,
        POLL,
        POLL_SUBTITLE,
        SCAN_TO_TASK_TEMPLATE,
        SCAN_TO_TASK_TEMPLATE_SUBTITLE
        ;

        override val prefix: String = "attachmenttypetrans"

    }

    enum class PopUpSignUp : Translatable {
        POPUP_SIGN_UP_TITLE,
        SIGN_UP_FOR_FEATURES_MESSAGE
        ;

        override val prefix: String = "popupsignup"

    }

    enum class Network : Translatable {
        TRYING_TO_RECONNECT,
        YOU_ARE_OFFLINE_NETWORK_CONNECTION_REQUIRED,
        YOU_ARE_BACK_ONLINE,
        ;

        override val prefix: String = "network"

    }

    enum class UserVerification : Translatable {
        IDENTITY_VERIFIED,
        IDENTITY_CHECKING,
        IDENTITY_NOT_VERIFIED,
        NOT_VERIFIED_HELP_TEXT,
        CHECKING_HELP_TEXT,
        NEW_QR_IN_X_SECS,
        QR_CODE_EXPIRED
        ;

        override val prefix: String = "userverification"

    }

    enum class Poll : Translatable {
        GIVE_US_YOUR_OPINION,
        BE_FIRST_TO_VOTE,
        GO_TO_VOTE,
        VOTE,
        VOTING_SUCCESSFUL,
        VOTING_FAILED,
        RETRACT_VOTE,
        RETRACT_VOTE_SUCCESSFUL,
        RETRACT_VOTE_FAILED,
        RESET_POLL,
        RESET_POLL_QUESTION,
        RESET_POLL_SUCCESSFUL,
        RESET_POLL_FAILED,
        SEE_FULL_RESULTS,
        NUMBER_VOTES,
        NUMBER_OF_TOTAL_VOTES,
        CHOOSE_ONE_OPTION,
        CHOOSE_UP_TO_X_OPTIONS,
        ;

        override val prefix: String = "poll"

    }

    enum class ReadOnlyTagTranslations : Translatable {
        ASSIGNEE,
        CREATOR,
        ;

        override val prefix: String = "readonlytagtranslations"

    }

    enum class TimeAbbrevations : Translatable {
        SECONDS,
        MINUTES,
        HOURS,
        DAYS,
        WEEKS
        ;

        override val prefix: String = "timeabbrevations"

    }

    enum class PageHub : Translatable {
        GREETING,
        HERE_IS_WHATS_HAPPENING,
        ;

        override val prefix: String = "pagehub"

    }

    enum class AppVersion : Translatable {
        EXPIRED_UPDATE_MESSAGE,
        EXPIRED_REFRESH_MESSAGE,
        GO_TO_PLAYSTORE,
        REFRESH
        ;

        override val prefix: String = "appversion"

    }

    enum class CardClusterPrev : Translatable {
        CLUSTER_PREVIEW,
        OBJECTS_AMOUNT
        ;

        override val prefix = "cardclusterprev"
    }

    enum class AnalyticsTimefilter : Translatable {
        PLACEHOLDER_START,
        PLACEHOLDER_END,
        ;

        override val prefix = "analytics-timefilter"
    }

    enum class CardZoneEditor : Translatable {
        CARD_TITLE,
        WIDTH,
        LENGTH,
        ROTATION,
        RADIUS,
        VERTICES,
        SHORTCODE,
        ;

        override val prefix = "cardzoneeditor"
    }

    enum class DeleteAccount : Translatable {
        DELETE_ACCOUNT_BUTTON,
        DELETE_ACCOUNT_TITLE,
        DELETE_ACCOUNT_TEXT_A,
        DELETE_ACCOUNT_TEXT_B,
        ;

        override val prefix = "deleteaccount"
    }

    enum class SuspendedAccount : Translatable {
        ACCOUNT_SUSPENDED_TITLE,
        ACCOUNT_SUSPENDED_TEXT_A,
        ACCOUNT_SUSPENDED_TEXT_B,
        ;

        override val prefix = "suspendedaccount"
    }

    enum class ImportExport : Translatable {
        EXPORT_OBJECTS,
        EXPORT_OBJECTS_TO_FILE,
        EXPORTING_OBJECTS_FAILED,
        EXPORTED_X_OBJECTS_SUCCESSFULLY,
        NO_OBJECTS_TO_EXPORT,
        IMPORT_OBJECTS,
        IMPORT_OBJECTS_FROM_FILE,
        IMPORTING_OBJECTS_FAILED,
        IMPORTED_X_OBJECTS_SUCCESSFULLY,
        NO_OBJECTS_TO_IMPORT,
        SELECT_A_FILE,
        CLEAR_FILE,
        ROWS_OBJECTS
        ;

        override val prefix = "importexport"
    }

    enum class SearchFilterTexts : Translatable {
        OTHER_FILTER_OPTIONS_TITLE,
        ;

        override val prefix = "searchfiltertexts"
    }

    enum class OnBoardingWizardTexts : Translatable {
        WELCOME,
        WELCOME_TEXT,
        WELCOME_TRACK_YOUR_FIRST_ASSET_BUTTON,
        PRINT_QR_TEXT,
        PRINT_QR_BUTTON,
        ALREADY_HAVE_QR_TRACKER_BUTTON,
        DONE_NEXT_STEP_BUTTON,
        PRINT_QR_DONT_HAVE_PRINTER_BUTTON,
        PRINT_QR_NO_PRINTER_PROMPT_TITLE,
        PRINT_QR_NO_PRINTER_PROMPT_TEXT,
        CREATE_ASSET_TEXT,
        WIZARD_GO_TO_NEXT_STEP_BUTTON,
        DONE_UNDERSTOOD_BUTTON,
        SCAN_WITH_MOBILE_TEXT,
        SCAN_QR_TRACKER_TEXT,
        LEARN_TRACKING_TEXT,
        PRACTICE_TRACKING_TEXT,
        TRACKING_SUCCESS_TEXT,
        WIZARD_DONE_FINISH_DEMO_BUTTON,
        UPGRADE_CTA_TEXT,
        UPGRADE_FOR_X_MONTH_BUTTON,
        SEND_US_MESSAGE_BUTTON,
        RESTART_DEMO_BUTTON
        ;

        override val prefix = "onboardingwizardtexts"
    }

    enum class Comments : Translatable {
        HEADER,
        ADD,
        PLACEHOLDER,
        SHOW_MORE,
        HIDE,
        ARE_YOU_SURE_TO_DELETE
        ;

        override val prefix = "comments"
    }

    enum class PermissionPromptTexts : Translatable {
        LOCATION_PERMISSION_TITLE,
        LOCATION_PERMISSION_TEXT,
        LOCATION_PERMISSION_GRANTED,
        LOCATION_PERMISSION_PLEASE_GRANT,
        LOCATION_PERMISSION_DENIED,
        ;

        override val prefix = "permissionprompttexts"
    }

    enum class CreateWorkspaceTexts : Translatable {
        CREATE_WORKSPACE_TITLE,
        CREATE_WORKSPACE_BUTTON,
        LOGIN_TO_EXISTING_BUTTON,
        ALREADY_USE,
        WORKSPACE_CHOOSE_TEXT,
        WORKSPACE_INPUT_PLACEHOLDER,
        WORKSPACE_CREATED_TEXT,
        WORKSPACE_CREATED_TITLE,
        WORKSPACE_CREATED_CLOSE_PAGE,
        RESEND_CREATE_WORKSPACE_REQUEST_BUTTON,
        CHANGE_EMAIL_OR_WS_BUTTON
        ;

        override val prefix = "createworkspacetexts"
    }

    enum class Placeholder : Translatable {
        TITLE,
        TEXT,

        ;

        override val prefix = "placeholder"
    }

    enum class Navigation : Translatable {
        MOVE_MAP_TO_START,
        MOVE_MAP_TO_DESTINATION,
        CHOOSE_STARTING_POINT_PLACEHOLDER,
        CHOOSE_DESTINATION_PLACEHOLDER,
        CLOSE_NAVIGATION_POPUP,
        REVERSE_START_AND_DESTINATION,
        HISTORY,
        CLEAR_HISTORY,
        FROM_GPS,
        FROM_LAST_OBJECT,
        TO_START,
        SHOW_ROUTE,
        LINKED_ROUTES,
        GPS_COORDINATES,
        ;

        override val prefix = "navigation"
    }

    enum class ActionCode : Translatable {
        LINKED_ACTION_CODE,
        ADD_ACTION_CODE,
        REMOVE_ACTION_CODE,
        ENTER_ACTION_CODE_PLACEHOLDER,
        ADD_ACTION_CODE_EXPLANATION,
        ARE_YOU_SURE,
        DO_YOU_REALLY_WANT_TO_REMOVE,
        ;

        override val prefix = "actioncode"
    }

}

