package maplibreGL.renderer

import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import maplibreGL.MaplibreMap
import maplibreGL.TileLayerData

class ActiveTileLayerSourceIdsStore : RootStore<Set<String>>(emptySet(), Job())

class TileLayerRender {

    val maplibreMap: MaplibreMap by koinCtx.inject()
    private val activeTileLayerSourceIdsStore: ActiveTileLayerSourceIdsStore by koinCtx.inject()

    val map get() = maplibreMap.map

    // Keep track of tileLayerSources for garbage collection
    private var tileLayerSources: Set<String> = emptySet()

    private fun removeTileSource(tileSourceId: String) {
        if (map?.getSource(tileSourceId) != null) {
            map?.removeSource(tileSourceId)
//            console.log("Removed tileLayerSource: $tileSourceId")
        }
    }

    private fun removeTileLayer(tileLayerId: String) {
        if (map?.getLayer(tileLayerId) != null) {
            map?.removeLayer(tileLayerId)
//            console.log("Removed tileLayer: $tileLayerId")
        }
    }

    private fun addOrUpdateTileLayer(tileLayer: TileLayerData, forceReplaceSource: Boolean = false) {
        (tileLayer.styleLayerSpecification["id"] as? String)?.let { id ->
            removeTileLayer(id)
        }
        tileLayerSources += tileLayer.source.id
        activeTileLayerSourceIdsStore.update(tileLayerSources)
        maplibreMap.ensureGeoJSONSourceAndAddGeoJSONLayers(
            sourceId = tileLayer.source.id,
            sourceDefinition = tileLayer.source.sourceDefinition,
            forceReplaceSource = forceReplaceSource,
            layerDefinitionsMap = mapOf(tileLayer.styleLayerSpecification to null),
        )
//        console.log("Added tileLayer: ${tileLayer.id} and its source: ${tileLayer.source.id}")
    }

    fun addOrUpdateTileLayersAndStates(tileLayersStateMap: Map<TileLayerData, Boolean>) {
        tileLayersStateMap.mapValues { (tileLayer, enabled) ->
            if (enabled) {
                addOrUpdateTileLayer(tileLayer)
            } else {
                removeTileLayer(tileLayer.styleLayerSpecification["id"] as String)
            }
        }
        // Throw out old sources
        tileLayerSources = tileLayerSources.mapNotNull { sourceId ->
            if (sourceId in tileLayersStateMap.keys.map { tileLayer ->
                    tileLayer.source.id
                }
            ) {
                sourceId
            } else {
                removeTileSource(sourceId)
                null
            }
        }.toSet()
        activeTileLayerSourceIdsStore.update(tileLayerSources)
    }
}
