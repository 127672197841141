package data.objects.views

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectTags
import apiclient.tags.getTagValues
import data.objects.ActiveObjectStore
import data.objects.views.cardinfo.showExtraData
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import model.L
import styling.primaryButtonStyleParams
import theme.FormationIcons
import webcomponents.baseLayout
import webcomponents.cardTitle
import webcomponents.twContentScrollBox
import webcomponents.oneButtonFooter

fun RenderContext.cardCustomData() {

    val translation: Translation by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val title = activeObjectStore.map(GeoObjectDetails.L.title)
    val extraData = activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getTagValues(ObjectTags.ExtraData) }

    baseLayout(
        header = {
            cardTitle(title.data)
            cardTitle(translation[TL.CardCustomData.CARD_TITLE]) { FormationIcons.API.icon }
        },
        content = {
            twContentScrollBox {
                extraData.render(into = this) { data ->
                    showExtraData(data)
                }
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.BACK],
                styleParams = primaryButtonStyleParams,
                value = Unit,
                clickHandlers = listOf(routerStore.back),
            )
        },
    )
}
