package twcomponents

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.isFlagged
import com.tryformation.localization.Translatable
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.headless.foundation.utils.floatingui.utils.Placement
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import koin.withKoin
import kotlinx.coroutines.flow.Flow
import localization.TL
import localization.Translation
import localization.translate
import mainmenu.RouterStore
import org.w3c.dom.Element
import search.getSvgIconOptions
import svgmarker.MarkerSize
import svgmarker.renderSvgIcon
import theme.FormationUIIcons
import theme.IconEnum


fun RenderContext.twObjectSvgIcon(geoObject: GeoObjectDetails) {
    getSvgIconOptions(
        obj = geoObject,
        flagged = geoObject.isFlagged,
        showStateIndicator = false,
    )?.copy(size = MarkerSize.S)?.let { renderSvgIcon(it) }
}

fun RenderContext.twObjectCardHeader(
    geoObject: GeoObjectDetails,
    closeHandlers: List<SimpleHandler<Unit>>? = null,
) {
    twRowOfJustifyBetween {
        twObjectSvgIcon(geoObject)
        twTitle {
            +geoObject.title
        }
        twLargeIconButtonNeutralRounded(FormationUIIcons.Close) {
            clicks handledBy {
                closeHandlers?.let { handlers ->
                    handlers.forEach {
                        it.invoke(Unit)
                    }
                }
            }
        }
    }
}

fun RenderContext.twCardHeader(
    title: Flow<String>,
    closeHandlers: List<SimpleHandler<Unit>>? = null,
    additionalButtons: (HtmlTag<Element>.() -> Unit)? = null,
) {
    twRowOfJustifyBetween {
        twMainTitle {
            title.renderText(into = this)
        }
        twRowOf {
            additionalButtons?.invoke(this)
            twLargeIconButtonNeutralRounded(FormationUIIcons.Close) {
                clicks handledBy {
                    closeHandlers?.let { handlers ->
                        handlers.forEach {
                            it.invoke(Unit)
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.twPageHeaderBlank(
    rightAlignedBlock: (HtmlTag<Element>.() -> Unit)? = null,
    leftAlignedBlock: (HtmlTag<Element>.() -> Unit)? = null,
) {
    withKoin {
        twRowOf {
            className(if (leftAlignedBlock != null) "w-full justify-between" else "w-full justify-end")
            leftAlignedBlock?.invoke(this)
            rightAlignedBlock?.invoke(this)
        }
    }
}

fun RenderContext.twPageHeaderBack(
    backButtonIcon: IconEnum = FormationUIIcons.ArrowLeft,
    backButtonTitle: Translatable = TL.General.BACK,
    routeBack: Boolean = true,
    routeToMap: Boolean = false,
    backButtonToolTipPlacement: Placement = PlacementValues.left,
    additionalBackButtonHandler: SimpleHandler<Unit>? = null,
    rightAlignedBlock: (HtmlTag<Element>.() -> Unit)? = null,
    leftAlignedBlock: (HtmlTag<Element>.() -> Unit)? = null,
) {
    withKoin {
        val routerStore: RouterStore = get()
        val translation: Translation = get()
        twRowOf {
            className(if (leftAlignedBlock != null) "w-full justify-between" else "w-full justify-end")
            leftAlignedBlock?.invoke(this)
            // right aligned block
            twRowOf {
                rightAlignedBlock?.invoke(this)
                twLargeIconButtonNeutralRounded(backButtonIcon) {
                    clicks handledBy {
                        additionalBackButtonHandler?.invoke(Unit)
                        if (routeBack) {
                            routerStore.back(Unit)
                        }
                        if (routeToMap) {
                            routerStore.goToMap(Unit)
                        }
                    }
                }.twTooltip(positioning = backButtonToolTipPlacement, fallback = translation.getString(backButtonTitle)) {
                    p("text-xs font-bold") {
                        translate(backButtonTitle)
                    }
                }
            }
        }
    }
}

fun RenderContext.twPageHeaderClose(
    backButtonIcon: IconEnum = FormationUIIcons.Close,
    backButtonTitle: Translatable = TL.General.CLOSE,
    routeBack: Boolean = false,
    routeToMap: Boolean = true,
    backButtonToolTipPlacement: Placement = PlacementValues.left,
    additionalBackButtonHandler: SimpleHandler<Unit>? = null,
    rightAlignedBlock: (HtmlTag<Element>.() -> Unit)? = null,
    leftAlignedBlock: (HtmlTag<Element>.() -> Unit)? = null,
) {
    twPageHeaderBack(
        backButtonIcon = backButtonIcon,
        backButtonTitle = backButtonTitle,
        routeBack = routeBack,
        routeToMap = routeToMap,
        backButtonToolTipPlacement = backButtonToolTipPlacement,
        additionalBackButtonHandler = additionalBackButtonHandler,
        rightAlignedBlock = rightAlignedBlock,
        leftAlignedBlock = leftAlignedBlock,
    )
}
