package analyticsdashboard

import com.tryformation.localization.Locale
import com.tryformation.localization.Translatable
import dev.fritz2.components.compat.Input
import dev.fritz2.components.compat.input
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.selectField
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.disabled
import dev.fritz2.core.placeholder
import dev.fritz2.core.type
import flatpickr.BaseOptions
import flatpickr.Instance
import flatpickr.flatpickr
import koin.koinCtx
import kotlin.js.Date
import kotlin.js.json
import kotlin.time.Duration.Companion.days
import kotlinx.browser.document
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Instant
import kotlinx.datetime.toKotlinInstant
import localization.LocaleStore
import localization.TL
import localization.Translation
import model.dateFrom
import model.dateTo
import org.w3c.dom.MutationObserver
import org.w3c.dom.MutationObserverInit
import overlays.AlertOverlayStore
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import theme.FormationUIIcons
import utils.getTimeFilter
import utils.jsApply

data class TimeFilter(
    val filterStartDate: String? = null,
    val filterEndDate: String? = null,
) {
    companion object
}

enum class TimeFilterOption : Translatable {
    AllTime,
    LastHour,
    LastThreeHours,
    Today,
    Yesterday,
    LastThreeDays,
    LastWeek,
    LastThreeWeeks,
    ThisMonth,
    LastMonth,
    LastThreeMonth,
    LastSixMonth,
    ThisYear,
    LastYear,
    LastThreeYears,
    CustomTime,
    ;

    val timeFilter: TimeFilter get() = getTimeFilter(this)

    override val prefix = "analytics-timefilteroptions"
}

class SelectedTimeFilterOptionStore : RootStore<TimeFilterOption>(TimeFilterOption.AllTime, Job())

class AnalyticsTimeFilterStore : RootStore<TimeFilter>(
    initialData = TimeFilterOption.ThisMonth.timeFilter,
    job = Job(),
) {

    private val localeStore by koinCtx.inject<LocaleStore>()
    private val alertOverlayStore: AlertOverlayStore by koinCtx.inject()
    private val selectedTimeFilterOptionStore: SelectedTimeFilterOptionStore by koinCtx.inject()

    var pickerStartDate: Instance? = null
    private var pickerEndDate: Instance? = null

    private val fromDate = map(TimeFilter.dateFrom())
    private val toDate = map(TimeFilter.dateTo())

    val clear = handle<TimeFilterOption> { _, _ ->
        TimeFilter()
    }

    val reset = handle { _ ->
        TimeFilter()
    }

    val refreshTimeFilter = handle { current ->
        val refreshed = selectedTimeFilterOptionStore.current.timeFilter
        console.log("Refresh timefilter from \"${current.filterStartDate} : ${current.filterEndDate}\" to \"${refreshed.filterStartDate} : ${refreshed.filterEndDate}\"")
        refreshed
    }

    val insertStartDateFlatpickr = handle<String> { current, selector ->
        pickerStartDate?.destroy()
//        if(pickerStartDate != null) {
//            console.log("$selector already existed -> re-insert", pickerStartDate)
//            pickerStartDate = flatpickr("#$selector", pickerStartDate!!.config.apply {
//                defaultDate = current.filterStartDate?.let { from -> arrayOf(from) }
//            }, pickerStartDate!!)
//            pickerStartDate?.redraw()
//        } else {
        val config = jsApply<BaseOptions> {
            locale = localeStore.current.languageCode
            dateFormat = "D, d.m.Y, H:i K"
            enableTime = true
            weekNumbers = true
            defaultDate = current.filterStartDate?.let { from -> arrayOf(from) }
            onClose = arrayOf(
                { dates, _, _, _ ->
                    // only update when calendar closes, to reduce api calls
                    fromDate.update(dates.takeIf { it.isNotEmpty() }?.get(0)?.toISOString())
                },
            )
            onChange = arrayOf(
                { dates, _, _, _ ->
                    val startDate = dates.takeIf { it.isNotEmpty() }?.get(0)
                    // use first selection immediately, if no date is selected
                    if (pickerStartDate == null) {
                        fromDate.update(startDate?.toISOString())
                    }
                    if (pickerEndDate != null) {
                        startDate?.let { selectedStartDate ->
                            val minDate = selectedStartDate.toKotlinInstant().minus(1.days)
                            // disable all dates before the selected startDate in the picker for endDate
                            pickerEndDate?.set(
                                "disable",
                                arrayOf(
                                    json(
                                        "from" to Date(Instant.DISTANT_PAST.toEpochMilliseconds()).toISOString(),
                                        "to" to minDate.toString(),
                                    ),
                                ),
                            )
                            // validate current selected endDate and modify it, to prevent impossible queries
                            pickerEndDate?.selectedDates?.get(0)?.let { selectedEndDate ->
                                if (selectedEndDate.toKotlinInstant().toEpochMilliseconds()
                                    < selectedStartDate.toKotlinInstant().toEpochMilliseconds()
                                ) {
                                    console.log(
                                        "Selected date (${selectedEndDate.toISOString()}) is older than startDate (${selectedStartDate.toISOString()}). Reset and set startDate as closest match.",
                                        selectedStartDate.toISOString(),
                                    )
                                    fromDate.update(selectedStartDate.toISOString())
                                    toDate.update(selectedStartDate.toISOString())
                                }
                            }
                        }
                    } else {
                        console.warn("PickerEndDate is null")
                    }
                },
            )
        }
        pickerStartDate = flatpickr("#$selector", config)
        updateLanguage(localeStore.current)
        pickerStartDate?.redraw()
        console.log("Inserted $selector", pickerStartDate)
//        }
        current
    }

    val insertToDateFlatpickr = handle<String> { current, selector ->
        pickerEndDate?.destroy()
//        if(pickerEndDate != null) {
//            console.log("$selector already existed -> re-insert", pickerEndDate)
//            pickerEndDate = flatpickr("#$selector", pickerEndDate!!.config.apply {
//                defaultDate = current.filterEndDate?.let { to -> arrayOf(to) }
//            }, pickerEndDate!!)
//            pickerEndDate?.redraw()
//        } else {
        val config = jsApply<BaseOptions> {
            locale = localeStore.current.languageCode
            dateFormat = "D, d.m.Y, H:i K"
            enableTime = true
            weekNumbers = true
            defaultDate = current.filterEndDate?.let { to -> arrayOf(to) }
            onClose = arrayOf(
                { dates, _, _, _ ->
                    // validate selected endDate to be later than startDate
                    val startDate = pickerStartDate?.selectedDates?.get(0)
                    val endDate = dates.takeIf { it.isNotEmpty() }?.get(0)
                    if (endDate != null
                        && startDate != null
                        && startDate.toKotlinInstant().toEpochMilliseconds() <= endDate.toKotlinInstant()
                            .toEpochMilliseconds()
                    ) {
                        toDate.update(endDate.toISOString())
                    } else {
                        alertOverlayStore.warnNotify(flowOf("End date cannot be earlier than start date."))
                        toDate.update(startDate?.toISOString())
                    }
                },
            )
        }
        pickerEndDate = flatpickr("#$selector", config)
        updateLanguage(localeStore.current)
        pickerEndDate?.redraw()
        console.log("Inserted $selector", pickerEndDate)
//        }
        current
    }

    private val updateLanguage = handle<Locale> { current, locale ->
        pickerStartDate?.set("locale", locale.languageCode)
        pickerEndDate?.set("locale", locale.languageCode)
        pickerStartDate?.set(
            "dateFormat",
            if (pickerStartDate?.l10n?.time_24hr == true) "D, d.m.Y, H:i" else "D, d.m.Y, H:i K",
        )
        pickerEndDate?.set(
            "dateFormat",
            if (pickerEndDate?.l10n?.time_24hr == true) "D, d.m.Y, H:i" else "D, d.m.Y, H:i K",
        )
        current
    }

    private val updateFlatpickrs = SimpleHandler<TimeFilter> { timeFilterData, _ ->
        timeFilterData handledBy { timeFilter ->
            timeFilter.filterStartDate?.let {
                console.log("Set date to flatPickrs", timeFilter)
                pickerStartDate?.setDate(it, true)
            } ?: run {
                pickerStartDate?.clear(emitChangeEvent = true, toInitial = true)
            }
            timeFilter.filterEndDate?.let {
                pickerEndDate?.setDate(it, true)
            } ?: run {
                pickerEndDate?.clear(emitChangeEvent = true, toInitial = true)
            }
        }
    }

    init {
        // required resources for flatpickr
        utils.require("flatpickr/dist/flatpickr.min.css")
        utils.require("flatpickr/dist/l10n/de.js")
        utils.require("flatpickr/dist/l10n/es.js")
        utils.require("flatpickr/dist/l10n/pt.js")
        utils.require("flatpickr/dist/l10n/nl.js")

        localeStore.data handledBy updateLanguage
        data handledBy updateFlatpickrs
    }
}


fun RenderContext.timeFilter(startPickerId: String, endPickerId: String) {
    val analyticsTimeFilterStore: AnalyticsTimeFilterStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val selectedTimeFilterOptionStore: SelectedTimeFilterOptionStore by koinCtx.inject()

//    flexBox({ flex { grow { "1" } } }) {  }

    flexBox(
        {
            direction { row }
            width { maxContent }
            alignItems { center }
            justifyContent { start }
            children("div > svg, label, input, p") {
                margin { tiny }
            }
            flex { grow { "1" } }
            wrap { wrap }
        },
    ) {
        analyticsTimeFilterStore.data.render { activeTimeFilter ->
            icon(
                {
                    size { large }
                    margins { horizontal { small } }
                },
            ) { fromTheme { FormationIcons.Time.icon } }

            selectField(
                {
                    fontSize { smaller }
                    height(formationStyles.inputHeight)
                    radius(formationStyles.buttonRadius)
                    maxWidth { "200px" }
                    minWidth { maxContent }
                },
                items = TimeFilterOption.entries.toList(),
            ) {
                selectedItem(
                    TimeFilterOption.entries.firstOrNull { it.timeFilter == activeTimeFilter }
                        ?: TimeFilterOption.CustomTime,
                )
                label { timeFilter -> translation.getString(timeFilter) }
                events {
                    selected handledBy selectedTimeFilterOptionStore.update
                    selected.map { it.timeFilter } handledBy analyticsTimeFilterStore.update
                }
            }
        }

        flexBox(
            {
                direction { row }
                alignItems { center }
                justifyContent { center }
            },
        ) {
            flatpickrInput(id = startPickerId) {
                placeholder(translation[TL.AnalyticsTimefilter.PLACEHOLDER_START])
                type("datetime-local")
                val observer = MutationObserver { _, mutationObserver ->
                    if (document.contains(domNode)) {
                        analyticsTimeFilterStore.insertStartDateFlatpickr(startPickerId)
                        mutationObserver.disconnect()
                    }
                }
                observer.observe(
                    document,
                    MutationObserverInit(attributes = true, childList = true, characterData = false, subtree = true),
                )
            }

            span(
                {
                    margins {
                        horizontal { smaller }
                    }
                    fontSize { smaller }
                    fontWeight { lighter }
                },
            ) {
                analyticsTimeFilterStore.data.map {
                    // use flatplickr's internal localized range separator string
                    analyticsTimeFilterStore.pickerStartDate?.l10n?.rangeSeparator ?: ""
                }.renderText(into = this)
            }

            flatpickrInput(id = endPickerId) {
                disabled(analyticsTimeFilterStore.data.map { it.filterStartDate.isNullOrBlank() })
                type("datetime-local")
                placeholder(translation[TL.AnalyticsTimefilter.PLACEHOLDER_END])
                val observer = MutationObserver { _, mutationObserver ->
                    if (document.contains(domNode)) {
                        analyticsTimeFilterStore.insertToDateFlatpickr(endPickerId)
                        mutationObserver.disconnect()
                    }
                }
                observer.observe(
                    document,
                    MutationObserverInit(attributes = true, childList = true, characterData = false, subtree = true),
                )
            }
            analyticsTimeFilterStore.data.render { activeTimeFilter ->
                if (TimeFilterOption.entries.firstOrNull { it.timeFilter == activeTimeFilter } != TimeFilterOption.AllTime) {
                    dashboardIconButton(
                        title = null,
                        icon = FormationUIIcons.Close,
                        value = TimeFilterOption.AllTime,
                        clickHandlers = listOf(analyticsTimeFilterStore.clear, selectedTimeFilterOptionStore.update),
                    )
                }
            }
        }
    }
}

fun RenderContext.flatpickrInput(id: String? = null, content: Input.() -> Unit) {
    input(
        {
            textAlign { center }
            fontSize { smaller }
            fontWeight { lighter }
            height(formationStyles.inputHeight)
            width { maxContent }
            minWidth { minContent }
//        color { secondary.main }
//        background {
//            color { primary.main }
//        }
            border {
                width { "1px" }
            }
            radius(formationStyles.buttonRadius)
        },
        id = id,
    ) {
        content.invoke(this)
    }
}
