package analyticsdashboard

import apiclient.analytics.AnalyticsData
import apiclient.analytics.DashboardElement
import apiclient.analytics.DashboardParamNames
import apiclient.analytics.columNames
import apiclient.analytics.rows
import apiclient.util.formatIsoDate
import com.jillesvangurp.kotlinvegadsl.VegaLiteSpec
import com.jillesvangurp.serializationext.DEFAULT_PRETTY_JSON
import dev.fritz2.core.Handler
import dev.fritz2.core.Id
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.core.id
import dev.fritz2.core.storeOf
import dev.fritz2.core.title
import dev.fritz2.tracking.Tracker
import dev.fritz2.tracking.tracker
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.serialization.json.longOrNull
import localization.Translation
import localization.getString
import localization.getTranslationFlow
import localization.translate
import mainmenu.RouterStore
import theme.FormationIcons
import theme.FormationUIIcons
import theme.IconEnum
import twcomponents.twColOf
import twcomponents.twGhostButton
import twcomponents.twIconMedium
import twcomponents.twIconSmall
import twcomponents.twLargeIconButtonNeutralRounded
import twcomponents.twModal
import twcomponents.twModalFullScreenWrapper
import twcomponents.twPageHeaderClose
import twcomponents.twRowOf
import twcomponents.twRowOfJustifyBetween
import twcomponents.twRowOfJustifyStart
import utils.ToggleStore
import utils.handleFunctions
import webcomponents.Position
import webcomponents.baseLayout
import webcomponents.twContentScrollBox

fun <T> RenderContext.dashboardMainPageButton(
    icon: IconEnum,
    title: Flow<String>,
    routingMap: Map<String, String>? = null,
    value: T,
    valueHandlers: List<SimpleHandler<T>>? = null,
    clickHandlers: List<SimpleHandler<Unit>>? = null,
    disabled: Boolean = false,
    prefix: String = "dashboardMainPageButton",
    active: Boolean = false,
) {
    val routerStore: RouterStore by koinCtx.inject()

    button("h-full px-2 py-1", id = "$prefix-${Id.next()}") {
        className(
            if (disabled) {
                "text-gray-300"
            } else {
                "text-formationBlack"
            },
        )
        div("flex flex-row h-full items-center justify-center flex-nowrap") {
            className(
                if (disabled) {
                    "text-gray-300"
                } else {
                    if (active) {
                        "text-highlight"
                    } else {
                        "text-formationBlack"
                    }

                },
            )
            attr("onClick", "blur();")
            title(
                title.map { title ->
                    title.lowercase()
                        .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                },
            )
            twIconMedium(icon = icon)
            span("font-semibold ml-2 hover:underline") {
                className(
                    if (active) {
                        "underline"
                    } else "",
                )
                title.renderText(into = this)
            }
        }
        if (!disabled) {
            clicks handledBy {
                valueHandlers?.forEach { handler ->
                    handler(value)
                }
                clickHandlers?.forEach { handler ->
                    handler(Unit)
                }
                if (routingMap != null) {
                    routerStore.validateInternalRoute(routingMap)
                }
            }
        }
    }
}

fun RenderContext.dashboardElementTitle(element: DashboardElement, data: AnalyticsData) {
    val translation by koinCtx.inject<Translation>()
    val selectedAnalyticsDashboardStore by koinCtx.inject<SelectedAnalyticsDashboardStore>()
    val refreshTracker = tracker()

    twRowOf {
        p("font-bold") {
            +when (data) {
                is AnalyticsData.JsonPrimitiveTable -> data.title
                is AnalyticsData.Chart -> data.title.messageId.translate()
                else -> "unknown data type"
            }
        }
        // refresh button
        dashboardIconButton(
            icon = FormationUIIcons.Undo,
            tooltip = translation[AnalyticsDashboardTexts.REFRESH],
            value = Pair(element, refreshTracker),
            tracker = refreshTracker,
            clickHandlers = listOf(selectedAnalyticsDashboardStore.computeSingleElementData),
        )
        // print button
        dashboardIconButton(
            icon = FormationIcons.Printer,
            tooltip = translation[AnalyticsDashboardTexts.PRINT],
            value = element,
            clickHandlers = listOf(selectedAnalyticsDashboardStore.printElement),
        )
    }
}

fun RenderContext.dashboardElementTable(element: DashboardElement, data: AnalyticsData.JsonPrimitiveTable) {
    val translation by koinCtx.inject<Translation>()
    val analyticsTextFilterStore: AnalyticsTextFilterStore by koinCtx.inject()
    val dashboardTableElementsSortingStore by koinCtx.inject<DashboardTableElementsSortingStore>()
    val tableSortingStateStore = storeOf(dashboardTableElementsSortingStore.current[element])
    val tableDataStore = storeOf(
        dashboardTableElementsSortingStore.current[element]?.let { columnSortingState ->
            sortDashboardTableRows(element.analyticsType, data, columnSortingState)
        } ?: data,
    )

    dashboardTableElementsSortingStore.data.mapNotNull { it[element] } handledBy tableSortingStateStore.update

    div("max-w-full border border-formationBlack rounded-xl overflow-auto") {
        combine(
            tableDataStore.data,
            tableSortingStateStore.data,
            analyticsTextFilterStore.data,
        ) { data, sorting, textQuery ->
            val filteredData = filterDashboardTableData(textQuery, data)
            Pair(
                (sorting?.let { sortDashboardTableRows(element.analyticsType, filteredData, sorting) }
                    ?: filteredData),
                sorting,
            )
        }.render { (tableData, currentSortingState) ->
            table("w-full overflow-hidden", id = element.id) {
                thead("text-formationWhite sticky top-0") {
                    tableData.columNames.forEach { columnName ->
                        val columnIndex = data.columNames.indexOf(columnName)
                        val currentSorting =
                            if (currentSortingState?.columnName == columnName) currentSortingState.sorting else Sorting.None
                        th("mx-1 py-1 px-2 cursor-pointer text-sm") {
                            when (columnIndex) {
                                0 -> inlineStyle("box-shadow: inset -0.5px 0px #555;")
                                in 1 until tableData.columNames.size -> inlineStyle("box-shadow: inset 0.5px 0px #555, inset -0.5px 0px #555;")
                                tableData.columNames.size -> inlineStyle("box-shadow: inset 0.5px 0px #555;")
                            }
                            className(
                                when (currentSorting) {
                                    Sorting.Ascending, Sorting.Descending -> "bg-highlight hover:bg-highlight/75"
                                    else -> "bg-formationBlack hover:bg-highlight"
                                },
                            )

                            twRowOfJustifyStart {
                                div {
                                    className(
                                        when (currentSorting) {
                                            Sorting.Ascending, Sorting.Descending -> "text-formationWhite"
                                            else -> "text-gray-500"
                                        },
                                    )
                                    twIconSmall(icon = currentSorting.icon)
                                }
                                span {
                                    val directName = element.columnNameMapping?.get(columnName)
                                    when {
                                        directName != null -> {
                                            +directName
                                        }

                                        columnName == "analytics-field-value" -> {
                                            translation.get(
                                                columnName,
                                                mapOf(
                                                    "fieldValueName" to (element.elementParams[DashboardParamNames.FieldName]
                                                        ?: columnName),
                                                ),
                                            ).renderText()
                                        }

                                        else -> {
                                            translation.get(columnName).renderText()
                                        }
                                    }
                                }
                            }
                            clicks.map {
                                val tableSorting =
                                    DashboardTableSortedColumnState(columnName, columnIndex, currentSorting.next())
                                tableSortingStateStore.update(tableSorting)
                                dashboardTableElementsSortingStore.addOrUpdate(Pair(element, tableSorting))
                                sortDashboardTableRows(element.analyticsType, data, tableSorting)
                            } handledBy tableDataStore.update
                        }
                    }
                }
                // NO DATA
                if (tableData.rows.isEmpty()) {
                    tr {
                        td("py-1 px-2 border-t") {
                            translate(AnalyticsDashboardTexts.NO_DATA)
                        }
                        for (i in 2..data.columns.size) {
                            td("p-1 border-t border-l") { }
                        }
                    }
                }
                // TABLE DATA ROWS
                tableData.rows.map { tableData.rows.indexOf(it) to it }.forEach { (index, row) ->
                    tr {
                        if (index % 2 != 0) className("bg-gray-100")
                        row.map { row.indexOf(it) to it }.forEach { (columnIndex, column) ->
                            td("mx-1 p-1 border-t") {
                                if (columnIndex != 0) className("border-l")
                                try {
                                    if (tableData.columns[columnIndex].columnName == "analytics-time-span-ms" || tableData.columns[columnIndex].columnName == "analytics-time-span-per-parent-per-field") {
                                        +(column.longOrNull?.let { msTowdhms(it) }
                                            ?: getFieldValueTitle(column.content))
                                    } else {
                                        +getFieldValueTitle(column.content)
                                    }
                                } catch (e: IndexOutOfBoundsException) {
                                    console.warn("Could not get column name", e)
                                    +getFieldValueTitle(column.content)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.dashboardElement(element: DashboardElement, elementData: AnalyticsData) {
    twColOf {
        className("border border-dashed border-gray-300 max-h-full max-w-full overflow-auto rounded-2xl p-3 m-2")
        id("dashboardElement-${element.id}")
        twRowOfJustifyBetween {
            dashboardElementTitle(element, elementData)
            dashboardElementExpandButton(element, elementData)
        }
        when (elementData) {
            is AnalyticsData.JsonPrimitiveTable -> {
                dashboardElementTable(element, elementData)
            }

            is AnalyticsData.Chart -> {
                when (elementData) {
                    is AnalyticsData.Chart.SimpleLongChart -> {
                        vegaComponent(VegaLiteSpec.pie(elementData.values.map { it.toDouble() }, elementData.labels))
                    }

                    is AnalyticsData.Chart.TranslatableLongChart -> {
                        console.log(elementData)
                        vegaComponent(VegaLiteSpec.horizontalBar(elementData.values.map { it.toDouble() }, elementData.labels.map { it.getString() }))
                    }

                    is AnalyticsData.Chart.SimpleDoubleChart -> {
                        console.log(elementData)
                        vegaComponent(VegaLiteSpec.verticalBarOrLine(elementData.values.map { it }, elementData.labels))
                    }

                    is AnalyticsData.Chart.SimpleDoubleLineChart -> {
                        vegaComponent(
                            VegaLiteSpec.verticalBarOrLine(
                                elementData.values.map { it },
                                elementData.intervals.map { it.start.formatIsoDate() },
                                temporalCategory = true,
                                chartType = "line",
                            ),
                        )
                    }

                    else -> {
                        pre("overflow-auto font-mono") {
                            +DEFAULT_PRETTY_JSON.encodeToString(AnalyticsData.serializer(), elementData)
                        }
                    }
                }
            }

            else -> {}
        }
    }
}

fun <T> RenderContext.dashboardIconButton(
    title: Flow<String>? = null,
    tooltip: Flow<String>? = null,
    icon: IconEnum,
    iconPosition: Position = Position.Left,
    value: T,
    tracker: Tracker? = null,
    clickHandlers: List<Handler<T>>? = null,
    disabled: Boolean = false,
) {
    twGhostButton(
        fallbackTextAsFlow = title,
        icon = icon,
        iconPosition = iconPosition,
        disabledFlow = if (disabled) flowOf(true) else tracker?.data,
    ) {
        tooltip?.let { title(it) }
        clickHandlers?.forEach { handler ->
            clicks.map { value } handledBy handler
        }
    }
}

fun dashboardElementModal(toggleModalExpand: Store<Boolean>, element: DashboardElement, elementData: AnalyticsData) {
    twModal(
        toggleModalExpand,
    ) { closeModal, _, _ ->
        twModalFullScreenWrapper {
            baseLayout(
                header = {
                    twPageHeaderClose(
                        routeToMap = false,
                        additionalBackButtonHandler = handleFunctions {
                            closeModal(Unit)
                        },
                    ) {
                        dashboardElementTitle(element, elementData)
                    }
                },
                content = {
                    twContentScrollBox {
                        when (elementData) {
                            is AnalyticsData.JsonPrimitiveTable -> {
                                dashboardElementTable(element, elementData)
                            }

                            is AnalyticsData.Chart -> {
                                when (elementData) {
                                    is AnalyticsData.Chart.SimpleLongChart -> {
                                        vegaComponent(
                                            spec = VegaLiteSpec.pie(elementData.values.map { it.toDouble() }, elementData.labels),
                                            classes = "h-full w-full",
                                        )
                                    }

                                    is AnalyticsData.Chart.TranslatableLongChart -> {
                                        console.log(elementData)
                                        vegaComponent(
                                            VegaLiteSpec.horizontalBar(
                                                elementData.values.map { it.toDouble() },
                                                elementData.labels.map { it.getString() },
                                            ),
                                            classes = "h-full w-full",
                                        )
                                    }

                                    is AnalyticsData.Chart.SimpleDoubleChart -> {
                                        console.log(elementData)
                                        vegaComponent(
                                            spec = VegaLiteSpec.verticalBarOrLine(elementData.values.map { it }, elementData.labels),
                                            classes = "h-full w-full",
                                        )
                                    }

                                    is AnalyticsData.Chart.SimpleDoubleLineChart -> {
                                        vegaComponent(
                                            spec = VegaLiteSpec.verticalBarOrLine(
                                                elementData.values.map { it },
                                                elementData.intervals.map { it.start.formatIsoDate() },
                                                temporalCategory = true,
                                                chartType = "line",
                                            ),
                                            classes = "h-full w-full",
                                        )
                                    }

                                    else -> {
                                        pre("overflow-auto font-mono") {
                                            +DEFAULT_PRETTY_JSON.encodeToString(AnalyticsData.serializer(), elementData)
                                        }
                                    }
                                }
                            }

                            else -> {}
                        }
                    }
                },
            )
        }
    }
}

fun RenderContext.dashboardElementExpandButton(element: DashboardElement, elementData: AnalyticsData) {

    val expandStore = ToggleStore(false)
    dashboardElementModal(toggleModalExpand = expandStore, element = element, elementData = elementData)

    twLargeIconButtonNeutralRounded(icon = FormationUIIcons.Expand) {
        title(AnalyticsDashboardTexts.FULL_SCREEN.getTranslationFlow())
        clicks handledBy {
            expandStore.update(true)
        }
    }
}
