package mainmenu.about

import analytics.AnalyticsCategory
import apiclient.FormationClient
import apiclient.feedback.CreateFeedback
import apiclient.feedback.sendFeedback
import apiclient.validations.parseEnumValue
import apiclient.websocket.WebsocketApiClient
import auth.ApiUserStore
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.id
import dev.fritz2.core.invoke
import dev.fritz2.core.placeholder
import dev.fritz2.routing.MapRouter
import generated.ServerConfig
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import localization.translate
import mainmenu.Pages
import mainmenu.RouterStore
import model.Feedback
import model.message
import model.subject
import overlays.AlertOverlayStore
import overlays.BusyStore
import theme.FormationIcons
import twcomponents.twColOfCenter
import twcomponents.twColOfNoGap
import twcomponents.twFullWidthTextArea
import twcomponents.twIconCustomSize
import twcomponents.twInputField
import twcomponents.twInputTextFieldBold
import twcomponents.twMainTitle
import twcomponents.twMultiLineTextareaTextfieldFull
import twcomponents.twPageHeaderBack
import twcomponents.twPageHeaderClose
import twcomponents.twPrimaryButton
import twcomponents.twSecondaryButton
import utils.focusInputObserver
import utils.generateVersionCodeFromTimeStamp
import webcomponents.baseLayout
import webcomponents.twContentScrollBox
import webcomponents.inputLabelWrapper

class FeedbackStore : RootStore<Feedback>(
    initialData = Feedback("", ""),
    job = Job(),
) {
    val formationClient: FormationClient by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    private val websocketApiClient: WebsocketApiClient by koinCtx.inject()
    val router: MapRouter by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val alertOverlayStore: AlertOverlayStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val busyStore: BusyStore by koinCtx.inject()

    val reset = handle { current ->
        update(Feedback("", ""))
        current
    }

    val sendFeedback = handle { current ->
        apiUserStore.current.apiUser?.groups?.firstOrNull()?.groupId?.let { groupId ->
            busyStore.handleApiCall(
                supplier = suspend {
                    formationClient.sendFeedback(
                        feedback = CreateFeedback(
                            groupId = groupId,
                            sessionId = websocketApiClient.sessionIdProvider.invoke(),
                            subject = current.subject,
                            message = current.message,
                            version = "App " + generateVersionCodeFromTimeStamp(ServerConfig.buildTimestamp) + ".0",
                        ),
                    )
                },
                successMessage = translation[TL.AlertNotifications.FEEDBACK_SEND_SUCCESSFULLY],
                processResult = { result ->
                    routerStore.validateInternalRoute(
                        mapOf(
                            "page" to Pages.Feedback.name,
                            "feedback" to FeedbackPages.Send.name,
                        ),
                    )
                    console.log("Feedback send!", result)
                    reset()
                },
                errorMessage = translation[TL.AlertNotifications.FEEDBACK_NOT_SEND],
                processError = { throwable ->
                    console.warn("Failed to send feedback", throwable)
                },
            )
        }
        current
    }

    val cancelFeedback = handle { current ->
        if (current.subject.isBlank() && current.message.isBlank()) {
            reset()
            routerStore.back()
        } else {
            routerStore.validateExternalRoute(
                mapOf(
                    "page" to Pages.Feedback.name,
                    "feedback" to FeedbackPages.Cancel.name,
                ),
            )
        }
        current
    }

    fun isBlank(): Flow<Boolean> = data.map { it.subject.isBlank() && it.message.isBlank() }
}

enum class FeedbackPages { Cancel, Send }

fun RenderContext.pageFeedback() {
    val router: MapRouter by koinCtx.inject()

    router.select("feedback").render { (feedbackPage, _) ->
        when (parseEnumValue<FeedbackPages>(feedbackPage)) {
            FeedbackPages.Cancel -> pageFeedbackCancel()
            FeedbackPages.Send -> pageFeedbackSend()
            else -> pageFeedbackWrite()
        }
    }
}


fun RenderContext.pageFeedbackWrite() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val feedbackStore: FeedbackStore = koinCtx.get()
    val subject = feedbackStore.map(Feedback.subject())
    val message = feedbackStore.map(Feedback.message())

    baseLayout(
        header = {
            twPageHeaderClose(
                routeToMap = false,
                additionalBackButtonHandler = feedbackStore.cancelFeedback,
            ) {
                twMainTitle {
                    translate(TL.Feedback.CARD_TITLE)
                }
            }
        },
        content = {
            twContentScrollBox {
                className("p-2 h-full")
                twColOfCenter {
                    inputLabelWrapper(
                        title = TL.Feedback.SUBJECT_PLACEHOLDER,
                        visibilityFlow = subject.data.map { it.isNotBlank() },
                    ) {
                        twInputField(subject) {
                            twInputTextFieldBold {
                                id("feedbackSubjectInput")
                                placeholder(translation[TL.Feedback.SUBJECT_PLACEHOLDER])
                            }
                        }
                    }
                    focusInputObserver({ js("document.getElementById('feedbackSubjectInput').focus()") }, domNode)

                    inputLabelWrapper(
                        title = TL.Feedback.MESSAGE_PLACEHOLDER,
                        visibilityFlow = message.data.map { it.isNotBlank() },
                    ) {
                        twFullWidthTextArea(valueStore = message) {
                            twMultiLineTextareaTextfieldFull {
                                placeholder(translation[TL.Feedback.MESSAGE_PLACEHOLDER])
                            }
                        }
                    }

                    twPrimaryButton(
                        text = TL.Feedback.SEND,
                        disabledFlow = feedbackStore.isBlank(),
                        analyticsEventProvider = AnalyticsCategory.SendFeedback.click(),
                    ) {
                        clicks handledBy {
                            feedbackStore.sendFeedback()
                            routerStore.back()
                        }
                    }
                }
            }
        },
        footer = null,
    )
}

fun RenderContext.pageFeedbackCancel() {

    val feedbackStore: FeedbackStore = koinCtx.get()
    val routerStore: RouterStore by koinCtx.inject()

    baseLayout(
        header = {
            twPageHeaderBack()
        },
        content = {
            twContentScrollBox {
                twColOfNoGap {
                    className("items-center justify center gap-10")
                    twMainTitle {
                        translate(TL.Feedback.CANCEL_TITLE)
                    }
                    p("text-xl text-center px-4") {
                        translate(TL.Feedback.CANCEL_QUESTION)
                    }
                    twSecondaryButton(
                        text = TL.General.DISCARD,
                        analyticsEventProvider = AnalyticsCategory.DiscardFeedback.click(),
                    ) {
                        clicks handledBy {
                            feedbackStore.reset(Unit)
                            routerStore.goToMap(Unit)
                        }
                    }
                }
            }
        },
        footer = null,
    )
}

fun RenderContext.pageFeedbackSend() {

    baseLayout(
        header = {
            twPageHeaderClose()
        },
        content = {
            twContentScrollBox {
                twColOfNoGap {
                    className("items-center justify center gap-10")
                    twIconCustomSize(
                        icon = FormationIcons.MailAlt,
                        size = "4rem",
                    )
                    twMainTitle {
                        translate(TL.Feedback.THANK_YOU)
                    }
                    p("text-xl text-center px-4") {
                        translate(TL.Feedback.THANK_TEXT)
                    }
                }
            }

        },
        footer = null,
    )
}
