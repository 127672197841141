package data.objects.views

import apiclient.geoobjects.GeoObjectDetails
import data.objects.ActiveObjectStore
import data.objects.DatePickerStore
import data.objects.DurationPickerStore
import data.objects.TimePickerStore
import dev.fritz2.components.flexBox
import dev.fritz2.core.RenderContext
import dev.fritz2.core.placeholder
import dev.fritz2.core.values
import koin.koinCtx
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import localization.translate
import model.L
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.twColOf
import twcomponents.twIconLarge
import twcomponents.twPageHeaderBack
import twcomponents.twTitle
import utils.formatDateForDatePickerFlow
import utils.formatTimeForTimePickerFlow
import utils.handleFunctions
import webcomponents.baseLayout
import webcomponents.genericInput
import webcomponents.inputLabelWrapper
import webcomponents.smallSelectButton
import webcomponents.twContentScrollBox

fun RenderContext.cardDateTime() {
    val translation: Translation by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val startTime = activeObjectStore.map(GeoObjectDetails.L.atTime)
    val datePickerStore: DatePickerStore by koinCtx.inject()
    val timePickerStore: TimePickerStore by koinCtx.inject()
    val durationPickerStore: DurationPickerStore by koinCtx.inject()

    baseLayout(
        header = {
            twPageHeaderBack(
                backButtonIcon = FormationUIIcons.Save,
                backButtonTitle = TL.General.SAVE,
                additionalBackButtonHandler = handleFunctions {
                    activeObjectStore.readFromChangeInputStores(Unit)
                },
                rightAlignedBlock = {
                    twIconLarge(icon = FormationUIIcons.ArrowLeft)
                },
            ) {
                twTitle(icon = FormationIcons.Calendar) {
                    translate(TL.CardDateTime.CARD_TITLE)
                }
            }
        },
        content = {
            twContentScrollBox {
                twColOf {
                    // DATE INPUT
                    inputLabelWrapper(
                        title = TL.CardDateTime.DATE,
                        visibilityFlow = startTime.data.formatDateForDatePickerFlow().map { it.isNotBlank() },
                    ) {
                        genericInput(
                            value = startTime.data.formatDateForDatePickerFlow(),
                            type = "date",
                        ) {
                            placeholder(translation[TL.CardDateTime.DATE])
                            changes.values() handledBy datePickerStore.update
                        }
                    }
                    // TIME INPUT
                    inputLabelWrapper(
                        title = TL.CardDateTime.TIME,
                        visibilityFlow = formatTimeForTimePickerFlow(startTime.data).map { it.isNotBlank() },
                    ) {
                        genericInput(
                            value = formatTimeForTimePickerFlow(startTime.data),
                            type = "time",
                        ) {
                            placeholder(translation[TL.CardDateTime.TIME])
                            changes.values() handledBy timePickerStore.update
                        }
                    }
                    p("text-xs mt-3") {
                        translate(TL.CardDateTime.DURATION_IN_MINUTES)
                    }

                    // DURATION QUICK BUTTONS
                    flexBox(
                        {
                            direction { row }
                            width { full }
                            justifyContent { spaceBetween }
                            alignItems { center }
                        },
                    ) {
                        smallSelectButton(isActive = durationPickerStore.data.map { it == 10 }) {
                            +"10 ${translation.getString(TL.CardDateTime.MINUTE)}"
                            clicks.map { 10 } handledBy durationPickerStore.update
                        }
                        smallSelectButton(isActive = durationPickerStore.data.map { it == 20 }) {
                            +"20 ${translation.getString(TL.CardDateTime.MINUTE)}"
                            clicks.map { 20 } handledBy durationPickerStore.update
                        }
                        smallSelectButton(isActive = durationPickerStore.data.map { it == 30 }) {
                            +"30 ${translation.getString(TL.CardDateTime.MINUTE)}"
                            clicks.map { 30 } handledBy durationPickerStore.update
                        }
                    }
                    // DURATION INPUT
                    genericInput(
                        value = durationPickerStore.data.asString(),
                        type = "number",
                    ) {
                        inputs.values().map { it.toInt() } handledBy durationPickerStore.update
                    }
                }
            }
        },
    )
}
