package data.objects.views.cardinfo

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.tags.getTagValues
import apiclient.tags.getUniqueTag
import data.objects.ActiveObjectStore
import data.objects.views.directediting.directEditingCardContent
import dev.fritz2.components.compat.div
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import model.L
import theme.FormationIcons
import utils.getIcon
import utils.getName
import webcomponents.baseLayout
import webcomponents.cardTitleWithSubtitle
import webcomponents.cardTitleWithSubtitleDirectEdit
import webcomponents.twContentScrollBox
import webcomponents.ellipseText
import webcomponents.selectorButton
import webcomponents.selectorContent

fun RenderContext.areaInfoCardContent() {

    val objectType = ObjectType.Area
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val title = activeObjectStore.map(apiclient.geoobjects.GeoObjectDetails.L.title)
    val customData = activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getTagValues(ObjectTags.ExtraData) }
    val customDataFieldsMapFlow =
        getCustomFieldMap(activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getTagValues(ObjectTags.CustomField) })
    val externalId = activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getUniqueTag(ObjectTags.ExternalId) }
    val canModify = activeObjectStore.map(GeoObjectDetails.L.canModify)
    val canManage = activeObjectStore.map(GeoObjectDetails.L.canManage)

    baseLayout(
        header = {
            flexBox(
                {
                    direction { row }
                    justifyContent { spaceBetween }
                    alignItems { start }
                    width { full }
                    height { maxContent }
                    overflowX { hidden }
                },
            ) {
                canManage.data.combine(canModify.data) { manage, modify -> manage || modify }
                    .render { editAccess ->
                        if (editAccess) {
                            cardTitleWithSubtitleDirectEdit(
                                titleFlow = title.data,
                                title = title.current,
                                subtitle = flowOf(objectType.getName()),
                                titleIconFlow = flowOf(objectType.getIcon().icon),
                                subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                                changeHandler = activeObjectStore.editTitle,
                            )
                        } else {
                            cardTitleWithSubtitle(
                                title = title.data,
                                subtitle = flowOf(objectType.getName()),
                                titleIconFlow = flowOf(objectType.getIcon().icon),
                                subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                            )
                        }
                    }
                infoCardButtons()
            }
        },
        content = {
            twContentScrollBox {
                // OPTIONAL CUSTOM FIELDS
                div(
                    {
                        margins { top { smaller } }
                    },
                ) {
                    showCustomData(customDataFieldsMapFlow)
                }

                directEditingCardContent {
                    // OPTIONAL EXTRA DATA BUTTON
                    customData.render { dataFlow ->
                        if (dataFlow.isNotEmpty()) {
                            selectorButton {
                                selectorContent {
                                    icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.API.icon } }
                                    ellipseText { translation[TL.CardCustomData.BUTTON_TITLE].renderText(into = this) }
                                }
                                clicks.map {
                                    mapOf(
                                        "show" to "objectData",
                                    )
                                } handledBy routerStore.addOrReplaceRoute
                            }
                        }
                    }
                    nestedObjectsButton()
                }
            }
        },
        footer = {
            // CARD BUTTONS FOOTER WITH: [BACK] [EDIT]
            infoCardEditFooter(objectType)
        },
    )
}
