package map

import apiclient.auth.Token
import apiclient.groups.Group
import apiclient.groups.HeatmapLayerDefinition
import apiclient.groups.MapLayerUserSettings
import auth.ApiUserStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import io.ktor.http.*
import koin.koinCtx
import kotlin.js.json
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import kotlinx.serialization.json.JsonObject
import login.HostConfigStore
import maplibreGL.SourceType
import maplibreGL.TileLayerData
import maplibreGL.TileSourceData
import maplibreGL.renderer.TileLayerRender
import overlays.AlertOverlayStore
import utils.makeRGB
import utils.makeRGBA
import utils.rgbaToHexColor

class MaplibreTileLayersStore : RootStore<Map<String, TileLayerData>>(
    initialData = emptyMap(),
    job = Job(),
) {
    private val tileLayerRender by koinCtx.inject<TileLayerRender>()
    val apiUserStore by koinCtx.inject<ApiUserStore>()
    val alertOverlayStore by koinCtx.inject<AlertOverlayStore>()
    private val mapLayerUserSettingsStore by koinCtx.inject<MapLayerUserSettingsStore>()

    private var tileSourceAccessToken: String? = null
    private var heatmapLayerDefinitions: List<HeatmapLayerDefinition>? = null
    private var tileLayerStates: Map<String, Boolean>? = null

    val updateHeatmapLayerDefinitions = SimpleHandler<List<Group>> { data, _ ->
        data handledBy { groups ->
            val newHeatmapLayerDefinitions = groups.flatMap { group ->
                group.heatmapLayerDefinitions ?: emptyList()
            }
            if (heatmapLayerDefinitions != newHeatmapLayerDefinitions) {
                heatmapLayerDefinitions = newHeatmapLayerDefinitions
                console.log("Got new heatmapLayerDefinitions from workspaces to build tileLayers.", newHeatmapLayerDefinitions.map { it.layerId }.toString())
                buildTileLayers()
            }
        }
    }

    private val updateAccessToken = SimpleHandler<Token?> { data, _ ->
        data handledBy { accessToken ->
            val newToken = accessToken?.token
            if (tileSourceAccessToken != newToken) {
                tileSourceAccessToken = newToken
                console.log("Got new accessToken to build tileLayers.")
                buildTileLayers()
            }
        }
    }

    private val updateTileLayerStates = SimpleHandler<List<MapLayerUserSettings>> { data, _ ->
        data handledBy { mapLayerUserSettingsList ->
            val settingsMap = mapLayerUserSettingsList.associate { it.layerId to it.getStatus() }
            tileLayerStates = current.values.associate { tileLayer ->
                tileLayer.id to (settingsMap[tileLayer.source.id] ?: false)
            }
            renderTileLayers(current)
        }
    }

    private fun buildTileLayers() {
        val tileLayers = tileSourceAccessToken?.let { token ->
            heatmapLayerDefinitions?.associate {
                val layerSource = createTileSource(it.layerId, token)
                val tileLayer = createTileLayer(sourceData = layerSource, style = it.style)
                tileLayer.id to tileLayer
            }
        }
        tileLayers?.let { layers ->
            console.log("Build tileLayers: ${layers.values.map { tileLayer -> tileLayer.source.id }}")
            update(layers)
        } ?: run {
            console.log("Could not build tileLayers, yet. Either workspace has no heatmapLayerDefinitions or accessToken missing.")
            update(emptyMap())
        }
    }

    val renderTileLayers = SimpleHandler<Map<String, TileLayerData>?> { data, _ ->
        data handledBy { tileLayerMap ->
            tileLayerRender.addOrUpdateTileLayersAndStates(
                (tileLayerMap ?: current).values.associateWith { tileLayer ->
                    (tileLayerStates?.get(tileLayer.id) ?: false)
                }.toMap(),
            )
        }
    }

    init {
        mapLayerUserSettingsStore.data handledBy updateTileLayerStates
        apiUserStore.data.map { it.apiUser?.apiAccessToken } handledBy updateAccessToken
        data handledBy renderTileLayers
    }
}

/**
 * Helper functions to create TileSources & TileLayers
 */

fun createTileSource(layerId: String, accessToken: String): TileSourceData {
    val apiUserStore by koinCtx.inject<ApiUserStore>()
    val hostConfigStore by koinCtx.inject<HostConfigStore>()
    val groupId = apiUserStore.current.apiUser?.groups?.firstOrNull()?.groupId
    val currentConfig = hostConfigStore.current
    return if (groupId != null) {

        val url = URLBuilder().apply {
            protocol = if (currentConfig.ssl) URLProtocol.HTTPS else URLProtocol.HTTP
            host = currentConfig.host
            port = currentConfig.port
            encodedPath = "/mvt/$groupId/$layerId/{z}/{x}/{y}.mvt"
            parameters.append("groupId", groupId)
            parameters.append("token", accessToken)
        }.buildString()

        TileSourceData(
            id = layerId,
            url = url,
            sourceDefinition = json(
                "type" to SourceType.Vector.id,
                "tiles" to arrayOf(url),
            ),
        )
    } else {
        error("groupId is null")
    }
}

fun createTileLayer(sourceData: TileSourceData, style: JsonObject?): TileLayerData {
    return TileLayerData(
        source = sourceData,
        id = "${sourceData.id}-layer",
        styleLayerSpecification = json(
            "id" to "${sourceData.id}-layer",
            "type" to "heatmap",
            "sourceId" to sourceData.id,
            "source" to sourceData.id,
            "source-layer" to "hits",
            "paint" to (style ?: defaultHeatmapStyle),
        ),
    )
}

val defaultHeatmapStyle = json(
//    "heatmap-opacity" to 0.75,
    "heatmap-opacity" to arrayOf(
        "interpolate",
        arrayOf("linear"),
        arrayOf("zoom"),
        7, 1,
        24, 0.3,
    ),
    "heatmap-weight" to arrayOf(
        "interpolate",
        arrayOf("linear"),
        arrayOf("zoom"),
        0, 1,
        22, 0.05,
    ),
    // Increase the heatmap color weight by zoom level
    // heatmap-intensity is a multiplier on top of heatmap-weight
    "heatmap-intensity" to arrayOf(
        "interpolate",
        arrayOf("linear"),
        arrayOf("zoom"),
        0, 6,
        7, 5,
        12, 4,
        16, 3,
        22, 2,
        24, 1,
    ),
    "heatmap-color" to arrayOf(
        "interpolate",
        arrayOf("linear"),
        arrayOf("heatmap-density"),
        0,
        "rgba(33,102,172,0)",
        0.2,
        "rgb(103,169,207)",
        0.4,
        "rgb(209,229,240)",
        0.6,
        "rgb(253,219,199)",
        0.8,
        "rgb(239,138,98)",
        1,
        "rgb(178,24,43)",
    ),
    // Adjust the heatmap radius by zoom level
//    "heatmap-radius" to arrayOf(
//        "interpolate",
//        arrayOf("linear"),
//        arrayOf("zoom"),
//        0, 2,
//        24, 20
//    ),
)

fun getColorPalette(): List<String> {
    val colors = defaultHeatmapStyle["heatmap-color"] as Array<*>
    return listOf(
        rgbaToHexColor(colors[4].toString()),
        rgbaToHexColor(colors[6].toString()),
        rgbaToHexColor(colors[8].toString()),
        rgbaToHexColor(colors[10].toString()),
        rgbaToHexColor(colors[12].toString()),
        rgbaToHexColor(colors[14].toString()),
    )
}

fun setColorPalette(colors: List<String>): Array<*> {
    return arrayOf(
        "interpolate",
        arrayOf("linear"),
        arrayOf("heatmap-density"),
        0,
        makeRGBA(colors[0], 0.0),
        0.2,
        makeRGB(colors[1]),
        0.4,
        makeRGB(colors[2]),
        0.6,
        makeRGB(colors[3]),
        0.8,
        makeRGB(colors[4]),
        1,
        makeRGB(colors[5]),
    )
}
