package workspacetools.usermanagement

import apiclient.groups.MemberShipResponsePublic
import auth.CurrentWorkspaceStore
import data.users.views.initials
import dev.fritz2.components.radioGroup
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import dev.fritz2.routing.decodeURIComponent
import dev.fritz2.routing.encodeURIComponent
import koin.koinCtx
import kotlin.random.Random
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.Translation
import localization.getTranslationFlow
import localization.translate
import overlays.dotSpinner
import signup.textLinkButton
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.twColOf
import twcomponents.twColOfCenter
import twcomponents.twContentScrollBox
import twcomponents.twDeleteButton
import twcomponents.twIconCustomSize
import twcomponents.twIconMedium
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twLeftAlignedRow
import twcomponents.twMediumIconButtonNeutralRounded
import twcomponents.twPrimaryButton
import twcomponents.twRevertButton
import twcomponents.twRightAlignedButtonRow
import twcomponents.twRowOfJustifyBetween
import twcomponents.twRowOfJustifyStart
import twcomponents.twRowOfWrap
import twcomponents.twSecondaryButton
import twcomponents.twSimpleModalWithCloseHeader
import twcomponents.twSubtitle
import twcomponents.twTitle
import utils.ToggleStore
import utils.merge
import utils.mergeIfNotBlank
import webcomponents.baseLayout
import webcomponents.inputLabelWrapper
import webcomponents.userOrStateIcon

val MemberShipResponsePublic.search
    get() = (this.userId + " " + this.roles.joinToString(" ") + " " + this.userProfile?.let {
        "${it.firstName} ${it.lastName} ${it.emailAddresses.joinToString(" ")} ${it.jobTitle} ${
            it.phoneNumbers.joinToString(
                " ",
            )
        }"
    }).lowercase()

private fun MemberShipResponsePublic.include(filter: String) = this.search.contains(filter, true)

fun String.urlEncode() = encodeURIComponent(this)
fun String.urlDecode() = decodeURIComponent(this)

fun RenderContext.userManagement() {
    val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()
    val groupMembersStore by koinCtx.inject<GroupMembersStore>()
    val selectedGroupMemberStore by koinCtx.inject<SelectedGroupMemberStore>()

    val memberFilterStore = storeOf("")
    groupMembersStore.enabledStore.update(true) // start fetching only when this renders the first time

    val inviteUserModalStore = ToggleStore(false)
    inviteNewUserModal(inviteUserModalStore)

    val showUserModalStore = ToggleStore(false)
    showUserModal(showUserModalStore, selectedGroupMemberStore.data)

    currentWorkspaceStore.data.render { workspace ->
        if (workspace == null) {
            dotSpinner()
        } else {
            baseLayout(
                header = {
                    twRowOfJustifyBetween("w-full my-4 flex-wrap") {
                        twPrimaryButton(
                            text = DashboardTexts.InviteNewMemberButton,
                            icon = FormationIcons.PersonAdd,
                        ) {
                            className("shrink-0")

                            clicks handledBy {
                                inviteUserModalStore.update(true)
                            }
                        }

                        div("min-w-24 w-80") {
                            inputLabelWrapper(
                                title = flowOf("Filter Users by name"), // TODO translate
                                visibilityFlow = memberFilterStore.data.map { it.isNotBlank() },
                            ) {
                                twInputField(memberFilterStore) {
                                    twLeftAlignedRow {
                                        twIconMedium(icon = FormationUIIcons.FilterAlt)
                                    }
                                    twInputTextField {

                                        placeholder(flowOf("Alice")) // TODO translate
                                    }
                                    twRightAlignedButtonRow {
                                        memberFilterStore.data.render { text ->
                                            if (text.isNotBlank()) {
                                                twRevertButton(memberFilterStore, "")
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
//                    p {
//                        translate(DashboardTexts.DashboardExplainerShort)
//                    }
                },
                content = {
                    twContentScrollBox {
                        table("w-full lg:w-2/3 max-w-full text-left") {
                            tr("sticky top-0 bg-formationWhite") {
                                th {
                                    translate(DashboardTexts.DataTableName)
                                }
                                th {
                                    translate(DashboardTexts.DataTableEmail)
                                }
                                th {
                                    translate(DashboardTexts.DataTableAction)
                                }
                            }
                            combine(memberFilterStore.data, groupMembersStore.data) { f, m ->
                                Pair(f, m)
                            }.render { (filter, members) ->
                                members.sortedBy { it.userProfile?.name ?: "" }.forEach { member ->
                                    if (filter.isBlank() || member.include(filter)) {
                                        tr("cursor-pointer hover:bg-gray-100") {
                                            td("p-2 align-middle") {
                                                twRowOfJustifyStart {
                                                    userOrStateIcon(
                                                        32.0,
                                                        picture = member.userProfile?.profilePhoto?.href,
                                                        initials = initials(member.userProfile?.firstName, member.userProfile?.lastName),
                                                        iconSize = { small },
                                                    )
                                                    p("font-bold") { +"${member.userProfile?.firstName} ${member.userProfile?.lastName}" }
                                                }
                                            }
                                            td("py-2 align-middle") {
                                                p {
                                                    +(member.userProfile?.emailAddresses?.joinToString(
                                                        ",",
                                                    ) ?: "")
                                                }
                                            }
                                            td("px-2 align-middle") {
                                                twRowOfJustifyStart {
                                                    twIconMedium(FormationIcons.Edit)
                                                    p("text-sm") { translate(DashboardTexts.OpenEditButton) }
                                                }
                                            }
                                            clicks handledBy {
                                                selectedGroupMemberStore.update(member)
                                                showUserModalStore.update(true)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
            )
        }
    }
}

fun inviteNewUserModal(toggleStore: Store<Boolean>) {
    val invitationEmailStore by koinCtx.inject<InvitationEmailStore>()

    twSimpleModalWithCloseHeader(
        "w-128",
        title = DashboardTexts.InviteUserTitle,
        icon = FormationIcons.PersonAdd,
        toggleStore = toggleStore,
    ) { close, _, _ ->
        twContentScrollBox {
            twColOf("p-2") {
                inputLabelWrapper(
                    title = flowOf("Email address ( e.g. alice@domain.com)"), // TODO translate
                    visibilityFlow = invitationEmailStore.data.map { it.isNotBlank() },
                ) {
                    twInputField(invitationEmailStore) {
                        twLeftAlignedRow {
                            twIconMedium(icon = FormationIcons.Mail)
                        }
                        twInputTextField {
                            placeholder("alice@domain.com")
                        }
                        twRightAlignedButtonRow {
                            invitationEmailStore.data.render { text ->
                                if (text.isNotBlank()) {
                                    twRevertButton(invitationEmailStore, "")
                                }
                            }
                        }
                    }
                }

                twPrimaryButton(
                    text = DashboardTexts.InviteUserInviteButton,
                    icon = FormationIcons.Mail,
                    disabledFlow = invitationEmailStore.data.map { email -> email.isBlank() },
                ) {
                    clicks handledBy {
                        invitationEmailStore.inviteNewUser(Unit)
                        close(Unit)
                    }
                }

                p {
                    translate(DashboardTexts.InviteUserExplainer)
                }
            }
        }
    }
}

fun showUserModal(toggleStore: Store<Boolean>, memberFlow: Flow<MemberShipResponsePublic?>) {
    val copyToCLipboardStore by koinCtx.inject<CopyToCLipboardStore>()
    val selectedGroupMemberStore by koinCtx.inject<SelectedGroupMemberStore>()
    val translation by koinCtx.inject<Translation>()

    twSimpleModalWithCloseHeader(
        "w-128",
        titleAsFlow = memberFlow.map { user ->
            "${user?.userProfile?.firstName} ${user?.userProfile?.lastName}"
        },
        icon = FormationIcons.UserAlt,
        toggleStore = toggleStore,
    ) { _, _, _ ->
        memberFlow.render { user ->
            if (user != null) {
                val fullName = "${user.userProfile?.firstName} ${user.userProfile?.lastName}"
                val jobAndCompany = user.userProfile?.jobTitle.mergeIfNotBlank(user.userProfile?.company, " | ")
                val profile = user.userProfile ?: error("should have a profile")

                val setNewPasswordModalStore = ToggleStore(false)
                setNewPasswordModal(setNewPasswordModalStore)

                val setRoleModalStore = ToggleStore(false)
                setRoleModal(setRoleModalStore)

                twContentScrollBox {
                    twColOf("p-2") {
                        profile.profilePhoto.let { photo ->
                            div("flex items-center justify-center grow-0 shrink-0 w-52 h-52 my-2 rounded-full self-center") {
                                if (photo == null) {
                                    className("text-gray-300 border border-gray-300")
                                    twIconCustomSize(icon = FormationIcons.UserAlt, size = "100px")
                                } else {
                                    inlineStyle("--image-url:url(${photo.href})")
                                    className("bg-[image:var(--image-url)] bg-center bg-cover")
                                }
                            }
                        }
                        twColOfCenter("w-full mb-2") {
                            twTitle { +fullName }
                            jobAndCompany?.let { twSubtitle { +it } }
                        }
                        p {
                            translation[DashboardTexts.DataTableEmail]
                                .merge(flowOf(": ${profile.emailAddresses.joinToString(",")}"))
                                .renderText(into = this)
                        }
                        profile.phoneNumbers.let { numbers ->
                            if (numbers.isNotEmpty()) {
                                p {
                                    translation[DashboardTexts.DataTablePhone]
                                        .merge(flowOf(": ${numbers.joinToString(" ")}"))
                                        .renderText(into = this)
                                }
                            }
                        }
                        twRowOfJustifyStart {
                            p { +"ID: " }
                            pre {
                                +user.userId
                            }
                            twMediumIconButtonNeutralRounded(
                                icon = FormationUIIcons.Copy,
                            ) {
                                clicks.map { user.userId } handledBy copyToCLipboardStore.copyThat
                            }
                        }
                        val link = "https://login.tryformation.com/to/${user.groupName.urlEncode()}/${
                            profile.emailAddresses.first().urlEncode()
                        }"

                        twRowOfJustifyStart {
                            p { +"Login link: " }
                            textLinkButton(
                                text = flowOf(link),
                                fontSize = { small },
                                link = link,
                            )
                            twMediumIconButtonNeutralRounded(
                                icon = FormationUIIcons.Copy,
                            ) {
                                clicks.map { link } handledBy copyToCLipboardStore.copyThat
                            }
                        }

                        p {
                            translation[DashboardTexts.DataRole].merge(flowOf(": ${user.roles.joinToString(",")}"))
                                .renderText(into = this)
                        }

                        twRowOfJustifyBetween("flex-wrap") {
                            twDeleteButton(
                                text = DashboardTexts.DeleteUserButton,
                                icon = FormationIcons.DeleteAlt,
                            ) {
                                clicks handledBy confirm(
                                    text = DashboardTexts.DeleteUserAreYouSure.getTranslationFlow(
                                        mapOf(
                                            "name" to (user.userProfile?.name ?: user.userId),
                                        ),
                                    ),
                                    okHandlers = listOf(selectedGroupMemberStore.delete),
                                )
                            }

                            twPrimaryButton(
                                text = DashboardTexts.SetNewPasswordButton,
                                icon = FormationIcons.Lock,
                            ) {
                                clicks handledBy {
                                    setNewPasswordModalStore.update(true)
                                }
                            }

                            twPrimaryButton(
                                text = DashboardTexts.ChangeRoleButton,
                                icon = FormationIcons.PersonChange,
                            ) {
                                clicks handledBy {
                                    setRoleModalStore.update(true)
                                }
                            }
                        }
                    }
                }
            } else {
                p {
                    translate(DashboardTexts.NoMemberSelected)
                }
            }
        }
    }
}

val groupRoles = listOf("group_member", "group_guest", "group_admin", "group_owner", "tracker")
private const val acceptableCharacters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!$-#%^"
fun generatePassword(length: Int = 10) = (0..length)
    .map { Random.nextInt(0, acceptableCharacters.length) }
    .map { acceptableCharacters[it] }
    .joinToString("") { "$it" }

fun RenderContext.setNewPasswordModal(toggleStore: Store<Boolean>) {
    val selectedGroupMemberStore by koinCtx.inject<SelectedGroupMemberStore>()

    val passwordStore = storeOf("")

    twSimpleModalWithCloseHeader(
        "w-128",
        title = DashboardTexts.SetNewPasswordTitle,
        icon = FormationIcons.Lock,
        toggleStore = toggleStore,
    ) { close, _, _ ->
        twContentScrollBox {
            twColOf("p-2") {
                inputLabelWrapper(
                    title = flowOf("Choose a password"), // TODO translate
                    visibilityFlow = passwordStore.data.map { it.isNotBlank() },
                ) {
                    twInputField(passwordStore) {
                        twLeftAlignedRow {
                            twIconMedium(icon = FormationIcons.Mail)
                        }
                        twInputTextField {
                            placeholder("secret")
                        }
                        twRightAlignedButtonRow {
                            passwordStore.data.render { text ->
                                if (text.isNotBlank()) {
                                    twRevertButton(passwordStore, "")
                                }
                            }
                        }
                    }
                }
                passwordStore.data.render { password ->
                    twRowOfWrap {
                        twPrimaryButton(
                            text = DashboardTexts.SetNewPasswordButton,
                            icon = FormationIcons.Lock,
                            disabledFlow = passwordStore.data.map { pw -> pw.isBlank() },
                        ) {
                            clicks handledBy {
                                selectedGroupMemberStore.setNewPassword(password)
                                close(Unit)
                            }
                        }
                        twSecondaryButton(
                            text = DashboardTexts.GeneratePasswordButton,
                            icon = FormationIcons.Update,
                        ) {
                            clicks handledBy {
                                passwordStore.update(generatePassword())
                            }
                        }
                    }
                }
            }
        }
    }
}

fun setRoleModal(toggleStore: Store<Boolean>) {
    val selectedGroupMemberStore by koinCtx.inject<SelectedGroupMemberStore>()

    twSimpleModalWithCloseHeader(
        "w-128",
        title = DashboardTexts.ChangeRoleTitle,
        icon = FormationIcons.PersonChange,
        toggleStore = toggleStore,
    ) { _, _, _ ->
        twContentScrollBox {
            twColOf("p-2") {
                p {
                    translate(DashboardTexts.ChangeRoleExplainer)
                }

                selectedGroupMemberStore.data.mapNotNull { it }.render { member ->
                    val selectedGroupRoleStore = storeOf(member.roles.first())
                    p {
                        translate(
                            DashboardTexts.ChangeRoleCurrentRole,
                            mapOf("role" to member.roles.joinToString(",")),
                        )
                    }
                    radioGroup(items = groupRoles, value = selectedGroupRoleStore)
                    twPrimaryButton(
                        text = DashboardTexts.ChangeRoleButton,
                        icon = FormationIcons.PersonChange,
                        disabledFlow = selectedGroupRoleStore.data.map { selectedRole ->
                            selectedRole == member.roles.firstOrNull()
                        },
                    ) {
                        clicks.map { selectedGroupRoleStore.current } handledBy selectedGroupMemberStore.changeRole
                    }
                }
            }
        }
    }
}
