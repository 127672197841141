package overlays

import apiclient.FormationClient
import apiclient.RestStatusException
import apiclient.auth.AuthProblemCode
import apiclient.exceptions.RestError
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectChange
import apiclient.geoobjects.ObjectChanges
import apiclient.geoobjects.applyObjectChanges
import apiclient.util.isNotNullOrEmpty
import auth.ApiUserStore
import com.tryformation.localization.Translatable
import data.objects.ActiveObjectStore
import data.users.views.deleteAccountButton
import dev.fritz2.components.modal
import dev.fritz2.components.stackUp
import dev.fritz2.core.RootStore
import dev.fritz2.core.Store
import dev.fritz2.core.invoke
import koin.koinCtx
import koin.withKoin
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.launch
import localization.TL
import localization.Translation
import localization.translate
import login.CredentialsStore
import login.HostConfigStore
import mainmenu.RouterStore
import model.ConfirmationType
import model.L
import model.NotificationType
import model.Overlay
import routing.MainController
import theme.FormationColors
import theme.FormationIcons
import twcomponents.twMainTitle
import twcomponents.twModal
import twcomponents.twModalContentWrapper
import twcomponents.twPrimaryButton
import utils.ToggleStore
import utils.insertObjectInCachesAndMap
import utils.merge
import webcomponents.cardSubtitle
import webcomponents.fullPageConfirmation
import webcomponents.fullPageConfirmationContainer

val handlerScope = CoroutineScope(CoroutineName("handler"))


enum class BusyStoreTexts : Translatable {
    PleaseWait,
    Success,
    Failed,

    ;

    override val prefix = "busy-store"
}

enum class RestErrorMessage : Translatable {
    IncorrectVersion,
    Aborted,
    BadRequest,
    NotAuthorized,
    NotFound,
    Unhandled,
    Unknown,
    ;

    override val prefix = "resterrormessage"
}

fun RestError.getMessageFlow(): Flow<String> {
    val translation by koinCtx.inject<Translation>()
    return when (this) {
        is RestError.IncorrectVersion -> translation[RestErrorMessage.IncorrectVersion]
        is RestError.Aborted -> translation[RestErrorMessage.Aborted]
        is RestError.BadRequest -> translation[RestErrorMessage.BadRequest]
        is RestError.NotAuthorized -> translation[RestErrorMessage.NotAuthorized]
        is RestError.NotFound -> translation[RestErrorMessage.NotFound]
        is RestError.Unhandled -> translation[RestErrorMessage.Unhandled]
        is RestError.Unknown -> translation[RestErrorMessage.Unknown]
        else -> translation[RestErrorMessage.Unknown]
    }
}

fun RestError.getMessageString(): String {
    val translation by koinCtx.inject<Translation>()
    return when (this) {
        is RestError.IncorrectVersion -> translation.getString(RestErrorMessage.IncorrectVersion)
        is RestError.Aborted -> translation.getString(RestErrorMessage.Aborted)
        is RestError.BadRequest -> translation.getString(RestErrorMessage.BadRequest)
        is RestError.NotAuthorized -> translation.getString(RestErrorMessage.NotAuthorized)
        is RestError.NotFound -> translation.getString(RestErrorMessage.NotFound)
        is RestError.Unhandled -> translation.getString(RestErrorMessage.Unhandled)
        is RestError.Unknown -> translation.getString(RestErrorMessage.Unknown)
        else -> translation.getString(RestErrorMessage.Unknown)
    }
}

class TokenExpiredException : Exception("ApiRefreshToken has expired.")

enum class ApiNullResult { IsSuccess, IsError, HasNoMessage }

suspend fun <T> withBusyApiClient(
    block: suspend (FormationClient) -> Result<T>,
    processResult: (suspend (T) -> Unit)? = null
) {
    withKoin {
        val formationClient = get<FormationClient>()
        val busyStore = get<BusyStore>()
        busyStore.withBusy(
            {
                block(formationClient)
            },
        ) {
            processResult?.invoke(it)
        }
    }
}

suspend fun withBusyApplyObjectChange(objId: String, change: ObjectChange) {
    withKoin {
        val activeObjectStore = get<ActiveObjectStore>()
        withBusyApiClient(
            { client ->
                client.applyObjectChanges(ObjectChanges(objId, change))
            },
        ) { results ->
            results.firstOrNull()?.let { obj ->
                activeObjectStore.update(obj)
                insertObjectInCachesAndMap(obj)
            }

        }
    }
}

suspend fun withBusyApplyContentChange(objId: String, change: ObjectChange) {
    withKoin {
        val activeObjectStore = get<ActiveObjectStore>()
        val attachments = activeObjectStore.map(GeoObjectDetails.L.attachments)
        withBusyApiClient(
            { client ->
                client.applyObjectChanges(ObjectChanges(objId, change))
            },
        ) { results ->
            results.firstOrNull()?.let { obj ->
                attachments.update(obj.attachments)
                insertObjectInCachesAndMap(obj)
            }
        }
    }
}

class BusyStore : RootStore<Boolean>(
    initialData = false,
    job = Job(),
) {
    val translation by koinCtx.inject<Translation>()
    val alertOverlayStore by koinCtx.inject<AlertOverlayStore>()
    private val confirmationOverlayStore by koinCtx.inject<ConfirmationOverlayStore>()
    val routerStore by koinCtx.inject<RouterStore>()
    val apiUserStore by koinCtx.inject<ApiUserStore>()

    suspend fun <T> withBusy(
        block: suspend () -> Result<T>,
        waitMessage: Flow<String> = translation[BusyStoreTexts.PleaseWait],
        processError: suspend (Throwable) -> Unit = {
            console.error(it.message)
            alertOverlayStore.errorNotify(translation[BusyStoreTexts.Failed, mapOf("message" to (it.message ?: it))])
        },
        processResult: suspend (T) -> Unit = {
            alertOverlayStore.notify(translation[BusyStoreTexts.Success])
        },
    ) {
        handleApiCall(
            supplier = suspend { block.invoke() },
            processResult = processResult,
            processError = processError,
            withBusyState = true,
            busyStateMessage = waitMessage,
        )
    }

    suspend fun <T> handleApiCall(
        supplier: suspend () -> Result<T>?,
        successMessage: Flow<String>? = null,
        processResult: suspend (T) -> Unit = {
//            alertOverlayStore.notify(successMessage?: translation[BusyStoreTexts.Success])
        },
        errorMessage: Flow<String>? = null,
        processError: suspend (Throwable) -> Unit = { throwable ->
            console.log("$throwable - ${throwable.cause}:${throwable.message}")
//            alertOverlayStore.errorNotify(errorMessage?: translation[BusyStoreTexts.Failed, mapOf("message" to (throwable.message?: throwable))])
        },
        apiNullResultMessage: ApiNullResult? = ApiNullResult.HasNoMessage,
        processNullResult: suspend () -> Unit = {},
        withBusyState: Boolean? = false,
        busyStateMessage: Flow<String> = translation[BusyStoreTexts.PleaseWait],
    ) {
        if (withBusyState == true) {
            val spinnerHandler = modal(
                {
                    width { none }
                    height { none }
                },
            ) {
                placement { center }
                hasCloseButton(false)
                content { close ->
                    handlerScope.launch {
                        while (current) {
                            delay(20)
                        }
                        close.invoke()
                    }
                    // modal dialog closes by itself once login checks complete
                    fullPageConfirmation {
                        fullPageConfirmationContainer(
                            width = { maxContent },
                        ) {
                            stackUp(
                                {
                                    alignItems { center }
                                },
                            ) {
                                spacing { small }
                                items {
                                    cardSubtitle(busyStateMessage)
                                    dotSpinner()
                                }
                            }
                        }
                    }
                }
            }
            spinnerHandler.invoke()
        }
        update(true)
        handlerScope.launch {
            try {
                supplier()?.fold(
                    { result ->
                        successMessage?.let { message -> alertOverlayStore.notify(message) }

                        /* EXAMPLE for confirmation overlay */
//                    confirmationOverlayStore.show(
//                        Overlay.ConfirmationScreen(
//                            confirmationType = ConfirmationType.AutoClose,
//                            durationSeconds = 2,
//                            text = flowOf("Success!"),
//                            icon = defaultTheme.icons.check
//                        )

//                        Overlay.ConfirmationScreen(
//                            confirmationType = ConfirmationType.Close,
//                            text = flowOf("Success!"),
//                            icon = defaultTheme.icons.check,
//                            primaryActionName = translation[TL.General.CLOSE],
//                            primaryClickHandlers = listOf(routerStore.goToMap)
//                        )

//                        Overlay.ConfirmationScreen(
//                            confirmationType = ConfirmationType.UserAction,
//                            text = flowOf("Logout?"),
//                            icon = defaultTheme.icons.check,
//                            primaryActionName = translation[TL.MainMenu.LOGOUT],
//                            secondaryActionName = translation[TL.General.CLOSE],
//                            primaryClickHandlers = listOf(apiUserStore.logoutWithParams),
//                            secondaryClickHandlers = listOf(routerStore.goToMap),
//                        )
//                    )
                        /* EXAMPLE END */

                        processResult(result)
                        update(false)
                    },
                    { throwable ->
//                    console.log(throwable::class.simpleName, throwable)
                        // TODO create combined alert with: message + cause and react to different causes
                        //  e.g. show link to app store to update app or show link to network settings (android)
                        handleThrowable(throwable, errorMessage, processError)
                        update(false)
                    },
                ) ?: run {
                    when (apiNullResultMessage) {
                        ApiNullResult.IsError -> {
                            errorMessage?.let { alertOverlayStore.errorNotify(it) }
                        }

                        ApiNullResult.IsSuccess -> {
                            successMessage?.let { alertOverlayStore.notify(it) }
                        }

                        else -> {}
                    }
                    processNullResult.invoke()
                    update(false)
                }
            } catch (throwable: Throwable) {
                handleThrowable(throwable, errorMessage, processError)
                update(false)
            }
        }
    }

    private suspend fun handleThrowable(
        throwable: Throwable,
        errorMessage: Flow<String>?,
        processError: suspend (Throwable) -> Unit
    ) {
        when (throwable) {
            // TODO handle all RestErrors and other HTTP error codes
            is RestStatusException -> {
                alertOverlayStore.errorNotify(
                    (errorMessage ?: flowOf("")).merge(throwable.error.getMessageFlow(), " - "),
                )
                console.log("Error raw message:", throwable.message)
                console.log("Error translated message:", throwable.error.getMessageString())
                when (throwable.error) {

                    is RestError.IncorrectVersion -> {
                        console.error("incorrect version")
                        alertOverlayStore.show(
                            Overlay.NotificationToast(
                                notificationType = NotificationType.Alert,
                                durationSeconds = 4,
                                text = translation[TL.AlertNotifications.INCORRECT_VERSION],
                                bgColor = FormationColors.RedError.color,
                            ),
                        )
                        confirmationOverlayStore.show(
                            Overlay.ConfirmationScreen(
                                confirmationType = ConfirmationType.AppVersionExpired,
                                primaryActionName = translation[TL.General.CLOSE],
                                primaryClickHandlers = listOf(confirmationOverlayStore.reset),
                            ),
                        )
                    }

                    is RestError.NotAuthorized -> {
                        val restError = throwable.error as RestError.NotAuthorized
                        console.log("Not Authorized:", restError.code)
                        when (restError.code) {
                            AuthProblemCode.WRONG_USER_OR_PASSWORD -> {
                                console.error("wrong username or password")
                                alertOverlayStore.show(
                                    Overlay.NotificationToast(
                                        notificationType = NotificationType.Alert,
                                        durationSeconds = 4,
                                        text = translation[TL.AlertNotifications.WRONG_USER_NAME_OR_PW],
                                        bgColor = FormationColors.RedError.color,
                                    ),
                                )
                            }

                            AuthProblemCode.USER_SUSPENDED -> {
                                console.error("user suspended")
                                val suspendedModalToggle = ToggleStore(false)
                                accountSuspendedPopup(suspendedModalToggle)
                                suspendedModalToggle.toggle()
                            }

                            AuthProblemCode.USER_DOES_NOT_EXIST -> {
                                console.error("user does not exist")
                                alertOverlayStore.show(
                                    Overlay.NotificationToast(
                                        notificationType = NotificationType.Alert,
                                        durationSeconds = 4,
                                        text = translation[TL.AlertNotifications.WRONG_USER_NAME_OR_PW],
                                        bgColor = FormationColors.RedError.color,
                                    ),
                                )
                            }

                            else -> {

                            }
                        }
                    }

                    is RestError.Unknown -> {
                        console.error("unknown failure")
                        alertOverlayStore.show(
                            Overlay.NotificationToast(
                                notificationType = NotificationType.Alert,
                                durationSeconds = 4,
                                text = translation[TL.AlertNotifications.UNKNOWN_FAILURE],
                                bgColor = FormationColors.RedError.color,
                            ),
                        )
                    }

                    else -> { // Aborted (Timeout message, slow network?), BadRequest, NotFound, Unhandled
                        alertOverlayStore.errorNotify(
                            (errorMessage ?: flowOf("")).merge(throwable.error.getMessageFlow(), " - "),
                        )
                        console.log("Error", throwable.error)
                        console.error("other failure")
                    }
                }
            }

            is TokenExpiredException -> {
                apiUserStore.setExpiredToken()
            }

            else -> {
                errorMessage?.let { message ->
                    alertOverlayStore.errorNotify(
                        message.merge(flowOf("$throwable (${throwable.cause}): \"${throwable.message}\""), " - "),
                    )
                }
                console.log("$throwable (${throwable.cause}): \"${throwable.message}\"")
            }
        }
        processError.invoke(throwable)
    }
}

fun accountSuspendedPopup(toggleStore: Store<Boolean>) {
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val credentialsStore: CredentialsStore by koinCtx.inject()
    val formationClient: FormationClient by koinCtx.inject()
    val hostConfigStore: HostConfigStore by koinCtx.inject()
    val mainController: MainController by koinCtx.inject()
    val client: FormationClient = if (apiUserStore.current.userId.isNotNullOrEmpty() && !apiUserStore.current.isAnonymous) {
        formationClient
    } else {
        hostConfigStore.anonymousClient()
    }
    val email = credentialsStore.current.email.takeIf { it.isNotBlank() } ?: apiUserStore.current.apiUser?.emails?.firstOrNull()

    twModal(
        toggleStore,
    ) { close, _, _ ->
        twModalContentWrapper("w-96 gap-4") {
            twMainTitle(icon = FormationIcons.PassageForbidden) {
                translate(TL.SuspendedAccount.ACCOUNT_SUSPENDED_TITLE)
            }

            p {
                translate(TL.SuspendedAccount.ACCOUNT_SUSPENDED_TEXT_A, mapOf("email" to (email ?: "")))
            }

            email?.let {
                p {
                    translate(TL.SuspendedAccount.ACCOUNT_SUSPENDED_TEXT_B)
                }
                deleteAccountButton(email, client)
            }

            twPrimaryButton(
                text = TL.PageLogout.LOGOUT,
            ) {
                clicks handledBy {
                    mainController.logoutWithParams(Unit)
                    close()
                }
            }
        }
    }
}
