package map.views

import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import location.LocationUploadStore
import mainmenu.RouterStore
import styling.primaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault
import theme.FormationIcons
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.cardTitle
import webcomponents.twContentScrollBox
import webcomponents.oneButtonFooter

fun RenderContext.cardSharing() {
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val locationUploadStore: LocationUploadStore by koinCtx.inject()

    baseLayout(
        header = {
            cardTitle(translation[TL.LocationSharing.HEADER])
            cardSubtitle(translation[TL.LocationSharing.DESCRIPTION])
        },
        content = {
            twContentScrollBox {
                span(
                    {
                        margins {
                            top { small }
                            bottom { larger }
                        }
                    },
                ) {
                    translation[TL.LocationSharing.SHARING_DISCLAIMER].renderText(into = this)
                }

                locationSharingButton(
                    title = locationUploadStore.data.map {
                        if (it.isActive) translation.getString(TL.LocationSharing.TURN_SHARING_OFF) else translation.getString(
                            TL.LocationSharing.TURN_SHARING_ON,
                        )
                    },
                    backgroundJobState = locationUploadStore.data.map { it.isActive },
                    clickHandlers = listOf(locationUploadStore.flipLocationSendingState),
                )
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.BACK],
                styleParams = primaryButtonStyleParams,
                value = Unit,
                clickHandlers = listOf(routerStore.back),
            )
        },
    )
}

fun RenderContext.locationSharingButton(
    title: Flow<String>,
    backgroundJobState: Flow<Boolean>,
    routingMap: Map<String, String>? = null,
    clickHandlers: List<SimpleHandler<Unit>>? = null
) {
    val routerStore: RouterStore by koinCtx.inject()

    backgroundJobState.render { state ->

        button(
            {
                minWidth { "240px" }
                width { full }
                height(FormationDefault.formationStyles.inputHeight)
            },
        ) {
            flexBox(
                {
                    direction { row }
                    width { full }
                    height { full }
                    justifyContent { spaceBetween }
                    alignItems { center }
                    color { secondary.main }
                    background {
                        color {
                            if (state) FormationColors.RedError.color
                            else FormationColors.MarkerYou.color
                        }
                    }
                    radius(FormationDefault.formationStyles.inputRadius)
                    padding { smaller }
                },
            ) {
                icon(
                    {
                        size { large }
                        margins { left { small } }
                    },
                ) {
                    fromTheme {
                        if (state) FormationIcons.SharingOff.icon
                        else FormationIcons.SharingOn.icon
                    }
                }
                flexBox(
                    {
                        width { full }
                        alignItems { stretch }
                        justifyContent { center }
                    },
                ) { title.renderText() }
            }
            clickHandlers?.forEach { handler ->
                clicks handledBy handler
            }

            if (routingMap != null) {
                clicks.map {
                    routingMap
                } handledBy routerStore.validateInternalRoute
            }
        }
    }
}
