package objectrouting

import apiclient.FormationClient
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.MarkerColor
import apiclient.geoobjects.MarkerIcon
import apiclient.geoobjects.MarkerShape
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.distanceTo
import apiclient.geoobjects.isFlagged
import apiclient.geoobjects.newContext
import apiclient.geoobjects.restSearch
import apiclient.geoobjects.toLatLon
import apiclient.tags.getUniqueTag
import apiclient.tags.tag
import apiclient.util.parsePoint
import apiclient.validations.parseEnumValue
import auth.CurrentWorkspaceStore
import com.jillesvangurp.geo.findUTMCoordinates
import com.jillesvangurp.geo.parseMgrs
import com.jillesvangurp.geo.toPointCoordinate
import com.jillesvangurp.geo.toPointCoordinates
import data.objects.building.ActiveFloorLevelStore
import data.objects.building.CurrentBuildingsStore
import data.objects.building.getBuilding
import data.objects.building.getFloorLevel
import data.users.profile.MyProfileStore
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import koin.withKoin
import kotlin.math.max
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.launch
import localization.TL
import localization.getString
import localization.getTranslationFlow
import localization.translate
import location.LocationUploadStore
import map.MapStateStore
import maplibreGL.MaplibreMap
import model.destinationLatLon
import model.destinationObject
import model.destinationPointSource
import model.originLatLon
import model.originObject
import model.originPointSource
import point2pointnavigation.RoutingService
import search.getSvgIconOptions
import search.separationLine
import search.simpleListEntry
import search.titleSizedText
import services.GeoPositionService
import svgmarker.MarkerSize
import svgmarker.myUserSize
import svgmarker.myUserSvgIconOptions
import svgmarker.renderSvgIcon
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.doOnceWhenElementInDOM
import twcomponents.twColOfNoGap
import twcomponents.twGhostButton
import twcomponents.twIconMedium
import twcomponents.twIconSmall
import twcomponents.twIconSmallToMedium
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twLargeIconButtonNeutralRounded
import twcomponents.twMediumIconButton
import twcomponents.twMediumIconButtonNeutralRounded
import twcomponents.twPrimaryButtonBlue
import twcomponents.twRowOf
import twcomponents.twRowOfJustifyBetween
import twcomponents.twRowOfJustifyStart
import twcomponents.twRowOfNoGap
import twcomponents.twRowOfWrap
import twcomponents.twTooltip
import utils.Quadruple
import utils.humanReadable
import webcomponents.Position
import webcomponents.ellipseText
import websocket.MarkerClientStore

const val navigationToolContainerId = "navigation-tool-container"

fun parseCoordinates(q: String): ParsedCoordinates? {
    q.parsePoint()?.let { point ->
        return ParsedCoordinates(
            NavigationPointSource.GpsCoordinates,
            "${point.toLatLon().lat}, ${point.toLatLon().lon}",
            point.toLatLon(),
        )
    }
    q.findUTMCoordinates().takeIf { it.isNotEmpty() }?.let { utmCoordinates ->
        utmCoordinates.firstOrNull()?.let { utm ->
            return ParsedCoordinates(NavigationPointSource.UtmCoordinates, "$utm", utm.toPointCoordinates().toLatLon())
        }
    }
    q.parseMgrs()?.let { mgrs ->
        return ParsedCoordinates(NavigationPointSource.MgrsCoordinates, mgrs.usng(), mgrs.toPointCoordinate().toLatLon())
    }
    return null
}

fun RenderContext.navigationComponent() {

    withKoin {
        val navigationToolToggleStore: NavigationToolToggleStore = get()
        val maplibreMap: MaplibreMap = get()
        val geoPositionService: GeoPositionService = get()
        val lastKnownPositionStore: LastKnownPositionStore = get()
        val currentBuildingsStore: CurrentBuildingsStore = get()
        val activeFloorLevelStore: ActiveFloorLevelStore = get()
        val mapStateStore: MapStateStore = get()
        val navigationStore: NavigationStore = get()
        val navigationRender: NavigationRender = get()
        val originPointSourceStore = navigationStore.map(NavigationData.originPointSource())
        val originObjectStore = navigationStore.map(NavigationData.originObject())
        val originLatLonStore = navigationStore.map(NavigationData.originLatLon())
        val destinationPointSourceStore = navigationStore.map(NavigationData.destinationPointSource())
        val destinationObjectStore = navigationStore.map(NavigationData.destinationObject())
        val destinationLatLonStore = navigationStore.map(NavigationData.destinationLatLon())
        val navigationSearchHistoryStore: NavigationSearchHistoryStore = get()
        val myProfileStore: MyProfileStore = get()
        val locationUploadStore: LocationUploadStore = get()

        val parsedCoordinateStore = storeOf<ParsedCoordinates?>(null)
        val originCoordinateStore = storeOf<ParsedCoordinates?>(null)
        val destinationCoordinateStore = storeOf<ParsedCoordinates?>(null)
        val navigationSearchResultsStore = storeOf<List<GeoObjectDetails>>(emptyList())
        val renderedNavigationOriginCoordinatesStore = storeOf<LatLon?>(null)

        var lastSelectedInput: NavigationInput? = null

        fun Store<ParsedCoordinates?>.switchValues(
            otherStore: Store<ParsedCoordinates?>
        ) {
            val otherValue = otherStore.current
            val thisValue = this.current
            this.update(otherValue)
            otherStore.update(thisValue)
        }

        suspend fun doSearch(query: String) {

            val formationClient: FormationClient = get()
            val currentWorkspaceStore: CurrentWorkspaceStore = get()
            val markerClientStore: MarkerClientStore = get()

            if (query.isNotBlank()) {

                parseCoordinates(query)?.let { data ->
                    parsedCoordinateStore.update(data)
                } ?: run {
                    parsedCoordinateStore.update(null)
                }

                val result = formationClient.restSearch(
                    SearchQueryContext.newContext(listOf(currentWorkspaceStore.current?.groupId ?: error("no group"))).copy(
                        from = 0,
                        size = 15,
                        text = query,
                        objectTypes = listOf(
                            ObjectType.ObjectMarker, ObjectType.Task, ObjectType.GeneralMarker, ObjectType.UserMarker, ObjectType.Zone,
                            ObjectType.POI, ObjectType.Event,
                        ),
                        excludeTags = listOf(
                            ObjectTags.Archived.tag("true"),
                            ObjectTags.Deleted.tag("true"),
                            ObjectTags.Flagged.tag("true"),
                        ),
                    ),
                )
                if (result.isFailure) {
                    console.error("Error searching for $query", result.exceptionOrNull())
                } else {
                    val results = result.getOrThrow().hits.mapNotNull {
                        // filter out users that are not sharing
                        when (it.hit.objectType) {
                            ObjectType.UserMarker -> {
                                if (markerClientStore.isUserSharing(it.hit.ownerId)) {
                                    it.hit
                                } else null
                            }

                            else -> it.hit
                        }
                    }
                    navigationSearchResultsStore.update(results)
                }
            } else {
                navigationSearchResultsStore.update(emptyList())
            }
        }

        suspend fun getRoutePoints(changeFloor: Boolean = true): List<RoutePoint>? {
            val originLatLon = originLatLonStore.current
            renderedNavigationOriginCoordinatesStore.update(originLatLon)

            val originFloorId: String? = getFloorIdForNavigationPointSource(
                navigationPointSource = originPointSourceStore.current,
                latLon = originLatLon,
                selectedObjectStore = originObjectStore,
            )

            val destinationLatLon = destinationLatLonStore.current

            val destinationFloorId = getFloorIdForNavigationPointSource(
                navigationPointSource = destinationPointSourceStore.current,
                latLon = destinationLatLon,
                selectedObjectStore = destinationObjectStore,
            )

            if (changeFloor) {
                // switch floor to known level of starting point
                getLevelFromFloorId(originFloorId)?.let { floorLevel ->
                    activeFloorLevelStore.update(floorLevel)
                }
            }

            // calculate new route points and display navigation path on the map
            if (originLatLon != null && destinationLatLon != null) {
                RoutingService.navigate(
                    from = originLatLon,
                    to = destinationLatLon,
                    startFloorId = originFloorId,
                    targetFloorId = destinationFloorId,
                ).also { routingPoints ->
                    val listOfRoutePoints = routingPoints.mapIndexed { index, routePoint ->
                        val floorId = routePoint.tags.getUniqueTag(ObjectTags.ConnectedTo)
                        val floorLevel = floorId?.let {
                            currentBuildingsStore.current.getBuilding(it)?.getFloorLevel(it)
                        }
                        val previousFloorId = if (index > 0) {
                            routingPoints[index - 1].tags.getUniqueTag(ObjectTags.ConnectedTo)
                        } else null
                        val previousLevel = previousFloorId?.let {
                            currentBuildingsStore.current.getBuilding(it)?.getFloorLevel(it)
                        }
                        val nextFloorId = if (index < routingPoints.size - 1) {
                            routingPoints[index + 1].tags.getUniqueTag(ObjectTags.ConnectedTo)
                        } else null
                        val nextLevel = nextFloorId?.let {
                            currentBuildingsStore.current.getBuilding(it)?.getFloorLevel(it)
                        }
                        val isLevelDown =
                            previousFloorId?.let { previous -> floorId?.let { pointFloorId -> previous != pointFloorId } }
                                ?: false
                        val isLevelUp = nextFloorId?.let { next -> floorId?.let { pointFloorId -> next != pointFloorId } }
                            ?: false

                        RoutePoint(
                            lat = routePoint.latLon.lat,
                            lon = routePoint.latLon.lon,
                            floorId = floorId,
                            floorLevel = floorLevel,
                            isFloorChange = isLevelUp || isLevelDown,
                            floorChange = floorLevel?.let {
                                if (isLevelUp) {
                                    nextLevel?.let {
                                        FloorChange(
                                            previousLevel = floorLevel,
                                            newLevel = nextLevel,
                                        )
                                    }
                                } else if (isLevelDown) {
                                    previousLevel?.let {
                                        FloorChange(
                                            previousLevel = previousLevel,
                                            newLevel = floorLevel,
                                        )
                                    }
                                } else null
                            },
                        )
                    }

                    // delay to wait for draggable card to move down (on mobile),
                    // before map animation starts for new navigation path
                    delay(400)

                    return listOfNotNull(
                        RoutePoint(
                            lat = originLatLon.lat,
                            lon = originLatLon.lon,
                            floorId = originFloorId,
                        ),
                    ) + listOfRoutePoints + listOfNotNull(
                        RoutePoint(
                            lat = destinationLatLon.lat,
                            lon = destinationLatLon.lon,
                            floorId = destinationFloorId,
                        ),
                    )
                }
            } else {
                return null
            }
        }

        navigationToolToggleStore.data.render { navigationEnabled ->
            if (navigationEnabled) {

                val activeNavigationInputStore = storeOf<NavigationInput?>(null)

                val originInputQueryStore = storeOf("", Job())
                val destinationInputQueryStore = storeOf("", Job())

                // handle object search input queries
                originInputQueryStore.data handledBy { q ->
                    doSearch(q)
                }
                destinationInputQueryStore.data handledBy { q ->
                    doSearch(q)
                }

                // check last known position store here and update originPointSourceStore with it
                lastKnownPositionStore.current?.latLon?.let { lastPosition ->
                    if (originPointSourceStore.current == null && destinationLatLonStore.current != lastPosition) {
                        originPointSourceStore.update(NavigationPointSource.LastKnownLocation)
                        originLatLonStore.update(lastPosition)
                    }
                }

                // Main navigation component container
                div(
                    baseClass = "flex flex-col gap-1 overflow-hidden w-full md:w-120 md:min-w-100 h-min min-h-min max-h-128 z-[1020] md:z-[1009] absolute top-0 md:top-4 md:left-4 md:relative rounded-b-2xl md:rounded-2xl bg-formationWhite transition-all duration-300 ease-in-out shadow-2xl", // rounded-r-2xl md:w-100 absolute top-0 left-0 right-14 md:right-auto
                    id = navigationToolContainerId,
                ) {
                    // Column of Containers for origin and destination input
                    twColOfNoGap {
                        className("w-full px-2 pt-4 pb-3")

                        /**
                         * Row of "Move to Location"-(icon <-> button) and Origin inputField
                         */
                        twRowOf {
                            // Origin: Position icon <-> "Move to Start" button
                            combine(originLatLonStore.data, mapStateStore.data) { d, m -> Pair(d, m) }.render { (originLatLon, mapState) ->
                                if (originLatLon != null && originLatLon.distanceTo(mapState?.center ?: originLatLon) > 1) {
                                    twMediumIconButton(FormationUIIcons.StartCircle) {
                                        className("text-slate-500 hover:text-red-300")
                                        clicks handledBy {
                                            maplibreMap.flyTo(
                                                originLatLon,
                                                zoom = max(mapState?.zoom ?: maplibreMap.getZoom(), 19.0),
                                                maxDuration = 1000.0,
                                            )
                                            val floorId = getFloorIdForNavigationPointSource(
                                                navigationPointSource = originPointSourceStore.current,
                                                latLon = originLatLon,
                                                selectedObjectStore = originObjectStore,
                                            )
                                            // switch floor to known level of starting point
                                            getLevelFromFloorId(floorId)?.let { floorLevel ->
                                                activeFloorLevelStore.update(floorLevel)
                                            }
                                        }
                                    }.twTooltip(positioning = PlacementValues.right, fallback = TL.Navigation.MOVE_MAP_TO_START.getString()) {
                                        TL.Navigation.MOVE_MAP_TO_START.getTranslationFlow().renderText()
                                    }
                                } else {
                                    div {
                                        className(if (originLatLon != null) "text-red-500" else "text-slate-500")
                                        twIconMedium(FormationUIIcons.StartCircle)
                                    }
                                }
                            }

                            // Origin: InputField for Search <-> Selected NavigationPointSource (YourLocation, MapCenter, LastKnownLocation or Object)
                            originPointSourceStore.data.render { originSource ->
                                when (originSource) {

                                    // display user marker when using GPS location
                                    NavigationPointSource.YourLocation -> {
                                        navigationPointSourceRichButton(
                                            title = originSource.getTranslationFlow(),
                                            icon = originSource.icon,
                                            latLonAsSubtitleFlow = originLatLonStore.data,
                                        ) {
                                            val profilePicture = myProfileStore.current.profilePhoto?.href
                                            renderSvgIcon(
                                                myUserSvgIconOptions(
                                                    size = myUserSize,
                                                    sharing = locationUploadStore.current.isActive,
                                                    color = null,
                                                    icon = null,
                                                    shape = null,
                                                    picture = profilePicture,
                                                ),
                                            )
                                            clicks handledBy {
                                                navigationStore.setOriginObjectWithSourceAndLatLon(null)
                                                activeNavigationInputStore.update(NavigationInput.Origin)
                                                lastSelectedInput = NavigationInput.Origin
                                            }
                                        }
                                    }
//
                                    // display basic button with Map center icon and raw map center coordinates as subtitle
                                    NavigationPointSource.MapCenter -> {
                                        navigationPointSourceRichButton(
                                            title = originSource.getTranslationFlow(),
                                            icon = originSource.icon,
                                            latLonAsSubtitleFlow = originLatLonStore.data,
                                        ) {
                                            // check if current map center is point in building
                                            combine(originLatLonStore.data, activeFloorLevelStore.data) { latLon, level ->
                                                Pair(
                                                    latLon,
                                                    level,
                                                )
                                            }.render { (mapCenter, floorLevel) ->
                                                currentBuildingsStore.pointWithinFloor(mapCenter)?.let { floorId ->
                                                    currentBuildingsStore.current.getBuilding(floorId)?.let { building ->
                                                        building.buildingName?.let { buildingName ->
                                                            twIconSmallToMedium(icon = FormationUIIcons.ArrowRight)
                                                            twColOfNoGap {
                                                                className("p-0.5 self-start min-w-0 overflow-hidden")
                                                                titleSizedText {
                                                                    +buildingName
                                                                }
                                                                ellipseText(
                                                                    {
                                                                        fontSize(sm = { tiny }, md = { smaller })
                                                                    },
                                                                ) {
                                                                    +"Level: $floorLevel"
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            clicks handledBy {
                                                navigationStore.setOriginObjectWithSourceAndLatLon(null)
                                                activeNavigationInputStore.update(NavigationInput.Origin)
                                                lastSelectedInput = NavigationInput.Origin
                                            }
                                        }
                                    }

                                    NavigationPointSource.GpsCoordinates,
                                    NavigationPointSource.UtmCoordinates,
                                    NavigationPointSource.MgrsCoordinates -> {
                                        navigationCoordinateButton {
                                            navigationCoordinateButtonContent(
                                                title = originSource.getTranslationFlow(),
                                                icon = originSource.icon,
                                                subtitle = originCoordinateStore.current?.humanReadable ?: originLatLonStore.current?.humanReadable(),
                                                latLon = originCoordinateStore.current?.latLon ?: originLatLonStore.current,
                                            )
                                            clicks handledBy {
                                                (originCoordinateStore.current?.humanReadable
                                                    ?: originLatLonStore.current?.humanReadable())?.let {
                                                    originInputQueryStore.update(it)
                                                }
                                                navigationStore.setOriginObjectWithSourceAndLatLon(null)
                                                originCoordinateStore.update(null)
                                                activeNavigationInputStore.update(NavigationInput.Origin)
                                                lastSelectedInput = NavigationInput.Origin
                                            }
                                        }
                                    }

                                    // Last Known Position (GPS -> User Icon, Scan -> mini Marker of last scanned Object)
                                    NavigationPointSource.LastKnownLocation -> {
                                        navigationPointSourceRichButton(
                                            title = originSource.getTranslationFlow(),
                                            icon = when (lastKnownPositionStore.current?.type) {
                                                PositionType.GPS -> FormationIcons.Position
                                                PositionType.ScannedObject -> FormationIcons.QRCode
                                                null -> originSource.icon
                                            },
                                            // use subtitle to specify where last known location is from (fallback to display raw coordinates)
                                            subtitleBlock = when (lastKnownPositionStore.current?.type) {
                                                PositionType.GPS -> {
                                                    {
                                                        ellipseText(
                                                            {
                                                                fontSize { tiny }
                                                                fontStyle { italic }
                                                            },
                                                        ) { translate(TL.Navigation.FROM_GPS) }
                                                    }
                                                }

                                                PositionType.ScannedObject -> {
                                                    {
                                                        ellipseText(
                                                            {
                                                                fontSize { tiny }
                                                                fontStyle { italic }
                                                            },
                                                        ) { translate(TL.Navigation.FROM_LAST_OBJECT) }
                                                    }
                                                }

                                                else -> null
                                            },
                                            latLonAsSubtitleFlow = originLatLonStore.data,
                                        ) {
                                            when (lastKnownPositionStore.current?.type) {
                                                // display user icon if last known location is from GPS
                                                PositionType.GPS -> {
                                                    twIconSmallToMedium(icon = FormationUIIcons.ArrowLeft)
                                                    val profilePicture = myProfileStore.current.profilePhoto?.href
                                                    renderSvgIcon(
                                                        myUserSvgIconOptions(
                                                            size = myUserSize,
                                                            sharing = locationUploadStore.current.isActive,
                                                            color = null,
                                                            icon = null,
                                                            shape = null,
                                                            picture = profilePicture,
                                                        ),
                                                    )
                                                }
                                                // display mini Marker if last known location is from of last scanned object
                                                PositionType.ScannedObject -> {
                                                    lastKnownPositionStore.current?.geoObjectDetails?.let { geoObject ->
                                                        getSvgIconOptions(
                                                            obj = geoObject,
                                                            flagged = geoObject.isFlagged,
                                                            showStateIndicator = true,
                                                        )?.copy(size = MarkerSize.XS)?.let {
                                                            twIconSmallToMedium(icon = FormationUIIcons.ArrowLeft)
                                                            div("flex shrink flex-col max-h-11 items-center justify-center overflow-hidden") {
                                                                renderSvgIcon(it)
                                                                ellipseText({ fontSize { tiny } }) {
                                                                    +geoObject.title
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                null -> {}
                                            }

                                            clicks handledBy {
                                                navigationStore.setOriginObjectWithSourceAndLatLon(null)
                                                activeNavigationInputStore.update(NavigationInput.Origin)
                                                lastSelectedInput = NavigationInput.Origin
                                            }
                                        }
                                    }

                                    // display simplified list entry of selected object
                                    NavigationPointSource.OtherObject -> {
                                        originObjectStore.data.render { obj ->
                                            if (obj != null) {
                                                div("flex flex-row w-full items-center justify-center cursor-pointer rounded-xl bg-gray-100 hover:bg-gray-200 overflow-hidden") {
                                                    simpleListEntry(obj)
                                                    clicks handledBy {
                                                        originInputQueryStore.update("")
                                                        navigationStore.setOriginObjectWithSourceAndLatLon(null)
                                                        activeNavigationInputStore.update(NavigationInput.Origin)
                                                        lastSelectedInput = NavigationInput.Origin
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    // display input field, if nothing is selected
                                    null -> {
                                        twInputField(originInputQueryStore) {
                                            twInputTextField {
                                                doOnceWhenElementInDOM(domNode = domNode) {
                                                    domNode.focus()
                                                    activeNavigationInputStore.update(NavigationInput.Origin)
                                                }
                                                placeholder(TL.Navigation.CHOOSE_STARTING_POINT_PLACEHOLDER.getTranslationFlow())
                                            }
                                            focusinsCaptured handledBy {
                                                activeNavigationInputStore.update(NavigationInput.Origin)
                                                lastSelectedInput = NavigationInput.Origin
                                            }
                                            focusoutsCaptured.handledBy {
                                                delay(500)
                                                // reset focus input state, but only if not other input is already in focus
                                                if (activeNavigationInputStore.current == NavigationInput.Origin) {
                                                    activeNavigationInputStore.update(null)
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            // Button to close navigation
                            twLargeIconButtonNeutralRounded(icon = FormationUIIcons.Close) {
                                clicks handledBy {
                                    navigationStore.reset(Unit)
                                    navigationRender.showRoutingPath(listOf())
                                    navigationToolToggleStore.update(false)
                                }
                            }.twTooltip(positioning = PlacementValues.left, fallback = TL.Navigation.CLOSE_NAVIGATION_POPUP.getString()) {
                                TL.Navigation.CLOSE_NAVIGATION_POPUP.getTranslationFlow().renderText()
                            }
                        }

                        // Dots visualisation between inputs
                        twRowOfJustifyStart {
                            className("text-slate-500")
                            twIconMedium(icon = FormationUIIcons.OptionsVertical)
                        }

                        /**
                         * Row of "Move to Location"-(icon <-> button) and Destination inputField
                         */
                        twRowOf {
                            // Destination: Location icon <-> "Move to Destination" button
                            combine(destinationLatLonStore.data, mapStateStore.data) { d, m -> Pair(d, m) }.render { (destinationLatLon, mapState) ->
                                if (destinationLatLon != null && destinationLatLon.distanceTo(mapState?.center ?: destinationLatLon) > 1) {
                                    twMediumIconButton(FormationIcons.Location) {
                                        className("text-slate-500 hover:text-red-300")
                                        clicks handledBy {
                                            maplibreMap.flyTo(
                                                destinationLatLon,
                                                zoom = max(mapState?.zoom ?: maplibreMap.getZoom(), 19.0),
                                                maxDuration = 1000.0,
                                            )

                                            val floorId = getFloorIdForNavigationPointSource(
                                                navigationPointSource = destinationPointSourceStore.current,
                                                latLon = destinationLatLon,
                                                selectedObjectStore = destinationObjectStore,
                                            )

                                            // switch floor to known level of starting point
                                            getLevelFromFloorId(floorId)?.let { floorLevel ->
                                                activeFloorLevelStore.update(floorLevel)
                                            }
                                        }
                                    }.twTooltip(positioning = PlacementValues.right, fallback = TL.Navigation.MOVE_MAP_TO_DESTINATION.getString()) {
                                        TL.Navigation.MOVE_MAP_TO_DESTINATION.getTranslationFlow().renderText()

                                    }
                                } else {
                                    div {
                                        className(if (destinationLatLon != null) "text-red-500" else "text-slate-500")
                                        twIconMedium(FormationIcons.Location)
                                    }
                                }
                            }

                            // Destination: InputField for Search <-> Selected NavigationPointSource (YourLocation, MapCenter, LastKnownLocation or Object)
                            destinationPointSourceStore.data.render { destinationSource ->
                                when (destinationSource) {

                                    // display user marker when using GPS location
                                    NavigationPointSource.YourLocation -> {
                                        navigationPointSourceRichButton(
                                            title = destinationSource.getTranslationFlow(),
                                            icon = destinationSource.icon,
                                            latLonAsSubtitleFlow = destinationLatLonStore.data,
                                        ) {
                                            val profilePicture = myProfileStore.current.profilePhoto?.href
                                            renderSvgIcon(
                                                myUserSvgIconOptions(
                                                    size = myUserSize,
                                                    sharing = locationUploadStore.current.isActive,
                                                    color = null,
                                                    icon = null,
                                                    shape = null,
                                                    picture = profilePicture,
                                                ),
                                            )
                                            clicks handledBy {
                                                navigationStore.setDestinationObjectWithSourceAndLatLon(null)
                                                activeNavigationInputStore.update(NavigationInput.Destination)
                                                lastSelectedInput = NavigationInput.Destination
                                            }
                                        }
                                    }
//
                                    // display basic button with Map center icon and raw map center coordinates as subtitle
                                    NavigationPointSource.MapCenter -> {
                                        // MapCenter
                                        navigationPointSourceRichButton(
                                            title = destinationSource.getTranslationFlow(),
                                            icon = destinationSource.icon,
                                            latLonAsSubtitleFlow = destinationLatLonStore.data,
                                        ) {
                                            // check if current map center is point in building
                                            combine(destinationLatLonStore.data, activeFloorLevelStore.data) { latLon, level ->
                                                Pair(
                                                    latLon,
                                                    level,
                                                )
                                            }.render { (mapCenter, floorLevel) ->
                                                currentBuildingsStore.pointWithinFloor(mapCenter)?.let { floorId ->
                                                    currentBuildingsStore.current.getBuilding(floorId)?.let { building ->
                                                        building.buildingName?.let { buildingName ->
                                                            twIconSmallToMedium(icon = FormationUIIcons.ArrowRight)
                                                            twColOfNoGap {
                                                                className("p-0.5 self-start min-w-0 overflow-hidden")
                                                                titleSizedText {
                                                                    +buildingName
                                                                }
                                                                ellipseText(
                                                                    {
                                                                        fontSize(sm = { tiny }, md = { smaller })
                                                                    },
                                                                ) {
                                                                    +"Level: $floorLevel"
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            clicks handledBy {
                                                navigationStore.setDestinationObjectWithSourceAndLatLon(null)
                                                activeNavigationInputStore.update(NavigationInput.Destination)
                                                lastSelectedInput = NavigationInput.Destination
                                            }
                                        }
                                    }

                                    NavigationPointSource.GpsCoordinates,
                                    NavigationPointSource.UtmCoordinates,
                                    NavigationPointSource.MgrsCoordinates -> {
                                        navigationCoordinateButton {
                                            navigationCoordinateButtonContent(
                                                title = destinationSource.getTranslationFlow(),
                                                icon = destinationSource.icon,
                                                subtitle = destinationCoordinateStore.current?.humanReadable ?: destinationLatLonStore.current?.humanReadable(),
                                                latLon = destinationCoordinateStore.current?.latLon ?: destinationLatLonStore.current,
                                            )
                                            clicks handledBy {
                                                (destinationCoordinateStore.current?.humanReadable
                                                    ?: destinationLatLonStore.current?.humanReadable())?.let {
                                                    destinationInputQueryStore.update(it)
                                                }
                                                navigationStore.setDestinationObjectWithSourceAndLatLon(null)
                                                destinationCoordinateStore.update(null)
                                                activeNavigationInputStore.update(NavigationInput.Destination)
                                                lastSelectedInput = NavigationInput.Destination
                                            }
                                        }
                                    }

                                    // Last Known Position (GPS -> User Icon, Scan -> mini Marker of last scanned Object)
                                    NavigationPointSource.LastKnownLocation -> {
                                        navigationPointSourceRichButton(
                                            title = destinationSource.getTranslationFlow(),
                                            icon = when (lastKnownPositionStore.current?.type) {
                                                PositionType.GPS -> FormationIcons.Position
                                                PositionType.ScannedObject -> FormationIcons.QRCode
                                                null -> destinationSource.icon
                                            },
                                            subtitleBlock = when (lastKnownPositionStore.current?.type) {
                                                PositionType.GPS -> {
                                                    {
                                                        ellipseText(
                                                            {
                                                                fontSize { tiny }
                                                                fontStyle { italic }
                                                            },
                                                        ) { translate(TL.Navigation.FROM_GPS) }
                                                    }
                                                }

                                                PositionType.ScannedObject -> {
                                                    {
                                                        ellipseText(
                                                            {
                                                                fontSize { tiny }
                                                                fontStyle { italic }
                                                            },
                                                        ) { translate(TL.Navigation.FROM_LAST_OBJECT) }
                                                    }
                                                }

                                                else -> null
                                            },
                                            latLonAsSubtitleFlow = destinationLatLonStore.data,
                                        ) {
                                            when (lastKnownPositionStore.current?.type) {
                                                PositionType.GPS -> {
                                                    twIconSmallToMedium(icon = FormationUIIcons.ArrowLeft)
                                                    val profilePicture = myProfileStore.current.profilePhoto?.href
                                                    renderSvgIcon(
                                                        myUserSvgIconOptions(
                                                            size = myUserSize,
                                                            sharing = locationUploadStore.current.isActive,
                                                            color = null,
                                                            icon = null,
                                                            shape = null,
                                                            picture = profilePicture,
                                                        ),
                                                    )
                                                }

                                                PositionType.ScannedObject -> {
                                                    lastKnownPositionStore.current?.geoObjectDetails?.let { geoObject ->
                                                        getSvgIconOptions(
                                                            obj = geoObject,
                                                            flagged = geoObject.isFlagged,
                                                            showStateIndicator = true,
                                                        )?.copy(size = MarkerSize.XS)?.let {
                                                            twIconSmall(icon = FormationUIIcons.ArrowLeft)
                                                            div("flex shrink flex-col max-h-11 items-center justify-center overflow-hidden") {
                                                                renderSvgIcon(it)
                                                                ellipseText({ fontSize { tiny } }) {
                                                                    +geoObject.title
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                null -> {}
                                            }

                                            clicks handledBy {
                                                navigationStore.setDestinationObjectWithSourceAndLatLon(null)
                                                activeNavigationInputStore.update(NavigationInput.Destination)
                                                lastSelectedInput = NavigationInput.Destination
                                            }
                                        }
                                    }

                                    // display simplified list entry of selected object
                                    NavigationPointSource.OtherObject -> {
                                        destinationObjectStore.data.render { obj ->
                                            if (obj != null) {
                                                div("flex flex-row w-full items-center justify-center cursor-pointer rounded-xl bg-gray-100 hover:bg-gray-200 overflow-hidden") { //border border-formationBlack
                                                    simpleListEntry(obj)
                                                    clicks handledBy {
                                                        navigationSearchHistoryStore.add(obj)
                                                        destinationInputQueryStore.update("")
                                                        navigationStore.setDestinationObjectWithSourceAndLatLon(null)
                                                        activeNavigationInputStore.update(NavigationInput.Destination)
                                                        lastSelectedInput = NavigationInput.Destination
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    // display input field, if nothing is selected
                                    null -> {
                                        twInputField(store = destinationInputQueryStore) {
                                            twInputTextField {
                                                doOnceWhenElementInDOM(domNode = domNode) {
                                                    domNode.focus()
                                                    activeNavigationInputStore.update(NavigationInput.Destination)
                                                }
                                                placeholder(TL.Navigation.CHOOSE_DESTINATION_PLACEHOLDER.getTranslationFlow())
                                            }
                                            focussCaptured handledBy {
                                                activeNavigationInputStore.update(NavigationInput.Destination)
                                                lastSelectedInput = NavigationInput.Destination
                                            }
                                            focusoutsCaptured.handledBy {
                                                // reset focus input state, but only if not other input is already in focus
                                                delay(500)
                                                if (activeNavigationInputStore.current == NavigationInput.Destination) {
                                                    activeNavigationInputStore.update(null)
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            // Button to Reverse selected NavigationPointSources of Origin and Destination
                            twMediumIconButtonNeutralRounded(FormationUIIcons.Sort) {
                                clicks handledBy {
                                    navigationStore.reverse(Unit)
                                    originCoordinateStore.switchValues(destinationCoordinateStore)
                                }
                            }.twTooltip(positioning = PlacementValues.left, fallback = TL.Navigation.REVERSE_START_AND_DESTINATION.getString()) {
                                TL.Navigation.REVERSE_START_AND_DESTINATION.getTranslationFlow().renderText()
                            }
                        }
                    }

                    // Combined search results with buttons to select navigation point source options: LastKnownLocation, MapCenter, Your Location (GPS)
                    combine(
                        activeNavigationInputStore.data,
                        parsedCoordinateStore.data,
                        navigationSearchResultsStore.data,
                    ) { i, c, r ->
                        Triple(i, c, r)
                    }.render { (selectedInput, parsedCoordinate, searchResults) ->
                        if (selectedInput != null) {
                            if (searchResults.isNotEmpty() || parsedCoordinate != null) {

                                separationLine("mb-2")

                                div(
                                    "flex flex-col w-full max-h-100 mt-1 rounded-b-2xl overflow-y-auto", // md:max-w-100 bg-white border border-gray-300 shadow-lg
                                ) {
                                    if (parsedCoordinate != null) {
                                        val obj = GeoObjectDetails(
                                            id = parsedCoordinate.humanReadable,
                                            ownerId = "navigation-tool",
                                            objectType = ObjectType.POI,
                                            title = parsedCoordinate.pointSource.name,
                                            description = parsedCoordinate.humanReadable,
                                            latLon = parsedCoordinate.latLon,
                                            createdAt = "",
                                            updatedAt = "",
                                            color = MarkerColor.Default,
                                            iconCategory = MarkerIcon.Location,
                                            shape = MarkerShape.SquareWithPointer,
                                        )
                                        div("p-1 cursor-pointer hover:bg-gray-100") {
                                            navigationCoordinateButtonContent(
                                                title = parsedCoordinate.pointSource.getTranslationFlow(),
                                                icon = FormationIcons.Globe,
                                                subtitle = parsedCoordinate.humanReadable,
                                                latLon = parsedCoordinate.latLon,
                                            )
                                            clicks handledBy {
                                                when (selectedInput) {
                                                    NavigationInput.Origin -> {
                                                        originInputQueryStore.update("")
                                                        originCoordinateStore.update(parsedCoordinate)
                                                        originPointSourceStore.update(parsedCoordinate.pointSource)
                                                        originLatLonStore.update(parsedCoordinate.latLon)
                                                    }

                                                    NavigationInput.Destination -> {
                                                        destinationInputQueryStore.update("")
                                                        destinationCoordinateStore.update(parsedCoordinate)
                                                        destinationPointSourceStore.update(parsedCoordinate.pointSource)
                                                        destinationLatLonStore.update(parsedCoordinate.latLon)
                                                    }

                                                    else -> {}
                                                }
                                                navigationSearchHistoryStore.add(obj)
                                                activeNavigationInputStore.update(null)
                                                parsedCoordinateStore.update(null)
                                            }
                                        }
                                    }
                                    searchResults.forEach { obj ->
                                        div("p-1 cursor-pointer hover:bg-gray-100") {
                                            simpleListEntry(obj)
                                            clicks handledBy {
                                                when (selectedInput) {
                                                    NavigationInput.Origin -> {
                                                        originInputQueryStore.update("")
                                                        navigationStore.setOriginObjectWithSourceAndLatLon(obj)
                                                    }

                                                    NavigationInput.Destination -> {
                                                        destinationInputQueryStore.update("")
                                                        navigationStore.setDestinationObjectWithSourceAndLatLon(obj)
                                                    }

                                                    else -> {}
                                                }
                                                navigationSearchHistoryStore.add(obj)
                                                activeNavigationInputStore.update(null)
                                                parsedCoordinateStore.update(null)
                                            }
                                        }
                                    }
                                }
                            } else {
                                // show buttons for navigation point source options here:
                                // MapCenter, Your Location (GPS), LastKnownLocation
                                combine(
                                    activeNavigationInputStore.data,
                                    originPointSourceStore.data,
                                    destinationPointSourceStore.data,
                                ) { input, o, d -> Triple(input, o, d) }.render { (activeInput, originSource, destinationSource) ->

                                    separationLine("mb-2")

                                    val currentSelectedOtherSource = if (activeInput == NavigationInput.Destination) originSource else destinationSource

                                    // display source option buttons
                                    twRowOfWrap {
                                        className("px-2 pb-2")

                                        NavigationPointSource.entries.filter { src ->
                                            src != NavigationPointSource.OtherObject
                                                && src != NavigationPointSource.GpsCoordinates
                                                && src != NavigationPointSource.UtmCoordinates
                                                && src != NavigationPointSource.MgrsCoordinates
                                        }.forEach { navPointSrc ->
                                            // Don't show button for current selected PointSource, OtherObject
                                            if (
                                                currentSelectedOtherSource != navPointSrc
                                                && (if (navPointSrc == NavigationPointSource.LastKnownLocation) {
                                                    // only show Last Position as Option here, if its available and from a Scanned object,
                                                    // otherwise it's same as YourLocation
                                                    lastKnownPositionStore.current?.type == PositionType.ScannedObject
                                                } else true)
                                            ) {
                                                navigationPointSourceSmallButton(
                                                    title = navPointSrc.getTranslationFlow(),
                                                    icon = if (navPointSrc == NavigationPointSource.LastKnownLocation) {
                                                        when (lastKnownPositionStore.current?.type) {
                                                            PositionType.GPS -> FormationIcons.Position
                                                            PositionType.ScannedObject -> FormationIcons.QRCode
                                                            null -> navPointSrc.icon
                                                        }
                                                    } else navPointSrc.icon,
                                                ) {
                                                    clicks handledBy {
                                                        if (navPointSrc == NavigationPointSource.YourLocation) {
                                                            geoPositionService.getActiveWatchIdOrNew()
                                                        }
                                                        when (activeInput) {
                                                            NavigationInput.Origin -> originPointSourceStore.update(navPointSrc)
                                                            NavigationInput.Destination -> destinationPointSourceStore.update(navPointSrc)
                                                            else -> {}
                                                        }
                                                        activeNavigationInputStore.update(null)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                // Navigation search history (last 5 objects)
                                combine(activeNavigationInputStore.data, navigationSearchHistoryStore.data) { input, history ->
                                    Pair(input, history)
                                }.render { (activeInput, objectHistory) ->
                                    if (activeInput != null && objectHistory.isNotEmpty()) {

                                        twRowOfJustifyBetween {
                                            className("px-2 opacity-50")
                                            twRowOf {
                                                twIconMedium(icon = FormationIcons.History)
                                                p("flex w-full items-center, justify-center italic") {
                                                    translate(TL.Navigation.HISTORY)
                                                }
                                            }
                                            twMediumIconButton(icon = FormationUIIcons.Close) {
                                                clicks handledBy navigationSearchHistoryStore.clear
                                            }.twTooltip(fallback = TL.Navigation.CLEAR_HISTORY.getString()) {
                                                TL.Navigation.CLEAR_HISTORY.getTranslationFlow().renderText()
                                            }
                                        }

                                        div(
                                            "flex flex-col w-full max-h-100 mt-1 bg-white rounded-b-2xl overflow-y-auto", //md:max-w-100 border border-gray-300 shadow-lg
                                        ) {
                                            objectHistory.forEach { obj ->
                                                div("p-1 cursor-pointer opacity-50 hover:bg-gray-100") {
                                                    if (obj.ownerId == "navigation-tool") { // GPS, MGRS or UTM Coordinate Object
                                                        navigationCoordinateButtonContent(
                                                            title = parseEnumValue<NavigationPointSource>(obj.title)?.getTranslationFlow() ?: flowOf(obj.title),
                                                            icon = FormationIcons.Globe,
                                                            subtitle = obj.description,
                                                            latLon = obj.latLon,
                                                        )
                                                        clicks handledBy {
                                                            when (activeInput) {
                                                                NavigationInput.Origin -> {
                                                                    originInputQueryStore.update("")
                                                                    parseEnumValue<NavigationPointSource>(obj.title)?.let { navPointSrc ->
                                                                        originCoordinateStore.update(
                                                                            ParsedCoordinates(
                                                                                pointSource = navPointSrc,
                                                                                humanReadable = obj.description ?: "",
                                                                                latLon = obj.latLon,
                                                                            ),
                                                                        )
                                                                        originPointSourceStore.update(navPointSrc)
                                                                    }
                                                                    originLatLonStore.update(obj.latLon)
                                                                }

                                                                NavigationInput.Destination -> {
                                                                    destinationInputQueryStore.update("")
                                                                    parseEnumValue<NavigationPointSource>(obj.title)?.let { navPointSrc ->
                                                                        destinationCoordinateStore.update(
                                                                            ParsedCoordinates(
                                                                                pointSource = navPointSrc,
                                                                                humanReadable = obj.description ?: "",
                                                                                latLon = obj.latLon,
                                                                            ),
                                                                        )
                                                                        destinationPointSourceStore.update(navPointSrc)
                                                                    }
                                                                    destinationLatLonStore.update(obj.latLon)
                                                                }

                                                                else -> {}
                                                            }
                                                            navigationSearchHistoryStore.add(obj)
                                                            activeNavigationInputStore.update(null)
                                                        }
                                                    } else {
                                                        simpleListEntry(obj)
                                                        clicks handledBy {
                                                            when (activeInput) {
                                                                NavigationInput.Origin -> {
                                                                    originInputQueryStore.update("")
                                                                    navigationStore.setOriginObjectWithSourceAndLatLon(obj)
                                                                }

                                                                NavigationInput.Destination -> {
                                                                    destinationInputQueryStore.update("")
                                                                    navigationStore.setDestinationObjectWithSourceAndLatLon(obj)
                                                                }

                                                                else -> {}
                                                            }
                                                            navigationSearchHistoryStore.add(obj)
                                                            activeNavigationInputStore.update(null)
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            combine(
                                originPointSourceStore.data,
                                originObjectStore.data,
                                destinationPointSourceStore.data,
                                destinationObjectStore.data,
                            ) { origin, originObj, destination, destinationObj ->
                                Quadruple(origin, originObj, destination, destinationObj)
                            }.render { (origin, originObj, destination, destinationObj) ->
                                if (origin != null && destination != null) {

                                    // Trigger drawing the path on the map
                                    CoroutineScope(CoroutineName("draw-navigation-path")).launch {
                                        getRoutePoints(changeFloor = false)?.let { points ->
                                            navigationRender.drawRoutingPath(points)
                                        }
                                    }

                                    twRowOfNoGap {
                                        className("px-4 pb-4 gap-2")
                                        // Button to show or associate action QR code, that opens this route directly
                                        routeToXActionQRButton(
                                            originObj = originObj,
                                            destinationObjectId = destinationObj?.id,
                                            destinationLatLon = destinationLatLonStore.current,
                                            disabled = originObj == null,
                                        )

                                        // Go to Start Button
                                        twGhostButton(
                                            text = TL.Navigation.TO_START,
                                            icon = FormationUIIcons.StartCircle,
                                            iconPosition = Position.Right,
                                        ) {
                                            clicks handledBy {
                                                val latLon = renderedNavigationOriginCoordinatesStore.current
                                                val floorId = getFloorIdForNavigationPointSource(
                                                    navigationPointSource = origin,
                                                    latLon = latLon,
                                                    selectedObjectStore = originObjectStore,
                                                )

                                                // switch floor to known level of starting point
                                                getLevelFromFloorId(floorId)?.let { floorLevel ->
                                                    activeFloorLevelStore.update(floorLevel)
                                                }

                                                latLon?.let {
                                                    delay(500) // give the map a little time to trigger a search
                                                    maplibreMap.easeTo(
                                                        center = it,
                                                        zoom = max(maplibreMap.getZoom(), 19.0),
                                                        duration = 1000.0,
                                                    )
                                                }
                                            }
                                        }

                                        // Start Navigation button
                                        twPrimaryButtonBlue(
                                            text = TL.Navigation.SHOW_ROUTE,
                                            icon = FormationUIIcons.NavigationArrow,
                                            iconPosition = Position.Right,
                                        ) {
                                            clicks handledBy {
                                                getRoutePoints()?.let { points ->
                                                    navigationRender.showRoutingPath(points)
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
