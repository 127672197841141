package analyticsdashboard

import com.jillesvangurp.jsondsl.toJsObject
import com.jillesvangurp.kotlinvegadsl.VegaConfig
import com.jillesvangurp.kotlinvegadsl.VegaEmbeddable
import com.jillesvangurp.kotlinvegadsl.toJsObject
import dev.fritz2.core.RenderContext
import vegaembed.embed

fun RenderContext.vegaComponent(
    spec: VegaEmbeddable,
    config: VegaConfig = VegaConfig().apply {
        actions = false
    },
    classes: String = "h-96 w-96"
) {
    console.log(JSON.stringify(spec.toJsObject(),null,2))
    val element =  div(baseClass = classes) {
    }
    embed(element.domNode, spec.toJsObject(), config.toJsObject())
}

