package svgmarker

import com.github.nwillc.ksvg.RenderMode
import com.github.nwillc.ksvg.elements.SVG
import kotlin.math.cos
import kotlin.math.sin
import maplibreGL.ClusterProperties
import theme.FormationColors

private fun polarToCartesian(center: Double, radius: Double, angleInDegrees: Double): Pair<Double, Double> {
    val radians = (angleInDegrees - 90) * kotlin.math.PI / 180
    return Pair(
        center + (radius * cos(radians)),
        center + (radius * sin(radians)),
    )
}

private fun getPath(center: Double, radius: Double, startAngle: Double, endAngle: Double): String {
    var endAngle = endAngle
    val isCircle = endAngle - startAngle == 360.0
    if (isCircle) {
        endAngle -= 2.0 // trick to get a almost full circle to render
    }
    val start = polarToCartesian(center, radius, startAngle);
    val end = polarToCartesian(center, radius, endAngle)
    val largeArcFlag = if ((endAngle - startAngle) <= 180) 0 else 1
    val d = mutableListOf(
        "M", start.first, start.second,
        "A", radius, radius, 0, largeArcFlag, 1, end.first, end.second,
    )
    if (isCircle) {
        d += "Z"
    } else {
        d += listOf(
            "L", radius, radius,
            "L", start.first, start.second,
            "Z",
        )
    }
    return d.joinToString(" ")
}

private fun piePaths(center: Double, radius: Double, segments: List<Double>): List<String> {
    if (segments.isEmpty()) return emptyList()
    val total = segments.sum()
    return segments
        .let {
            listOf(0.0) + it
        }
        .map {
            (it / total) * 360.0
        }
        .runningReduce { acc, it ->
            acc + it
        }.zipWithNext { a, b ->
            a to b
        }.map { (from, to) ->
            getPath(center, radius, from, to)
        }
}

fun makeClusterMarkerSvg(
    count: Int,
    props: ClusterProperties,
    withShadow: Boolean = true,
    desaturated: Boolean = false,
    cursorPointer: Boolean = true
): String {
    val colors = props.colors.entries
        .map { (k, v) -> k to v }

    val paddingRatio = 1.3
    val bgWidth = pointSize.smPixel
    val wholeWidth = 44 // (bgWidth * paddingRatio).toInt()

    val svg = SVG.svg(false) {
        width = "$wholeWidth"
        height = "$wholeWidth"
        if (withShadow) cssClass = "drop-shadow-md"

        attributes["preserveAspectRatio"] = "xMidYMid meet"
        if (cursorPointer) attributes["cursor"] = "pointer"

        val lineWidth = 8
        val center = (wholeWidth / 2)
        val radius = center

        val segmentColors = colors.let {
            if (it.size == 1) {
                it + it
            } else {
                it
            }
        }
        if (segmentColors.isEmpty()) {
            console.warn("cluster marker had no colors", props)
        }

        val pieSegments = segmentColors.zip(
            piePaths(
                center = center.toDouble(),
                radius = radius.toDouble(),
                segments = segmentColors.map { (_, it) -> it.toDouble() },
            ),
        ) { (color, _), path ->
            Pair(color, path)
        }

        pieSegments.forEach { (color, path) ->
            path {
                if (desaturated) {
                    attributes["filter"] = "grayscale(100%)"
                    attributes["opacity"] = "0.5"
                }
                this.id = color.name
                this.d = path
                this.fill = color.color
            }
        }

        //background
        circle {
            if (desaturated) {
                attributes["filter"] = "grayscale(100%)"
                attributes["opacity"] = "0.5"
            }
            cx = center.toString()
            cy = center.toString()
            r = (radius - lineWidth).toString()
            fill = FormationColors.GrayLight.color
        }
        if (count > 0) {
            g {
                cssClass = "chart-text"
                text {
                    if (desaturated) {
                        attributes["filter"] = "grayscale(100%)"
                        attributes["opacity"] = "0.5"
                    }
                    cssClass = "chart-number"
                    this.x = "50%"
                    this.y = "50%"
                    body = "$count"
                }
            }
        }
    }
    val svgContent = buildString {
        svg.render(this, RenderMode.INLINE)
    }
    return svgContent
}
