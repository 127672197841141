package geofenceeditor

import apiclient.geoobjects.ObjectChanges
import apiclient.geoobjects.UpdateZoneGeometry
import apiclient.geoobjects.pointGeometry
import com.jillesvangurp.geojson.Geometry
import data.objects.ActiveObjectStore
import dev.fritz2.components.compat.div
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.id
import dev.fritz2.core.values
import koin.koinCtx
import kotlinx.browser.document
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.plus
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import map.MapStateStore
import maplibreGL.MaplibreMap
import nouislider.NoUIOptions
import nouislider.Range
import nouislider.nouislider
import org.w3c.dom.MutationObserver
import org.w3c.dom.MutationObserverInit
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationColors
import theme.FormationIcons
import theme.FormationUIIcons
import utils.jsApply
import utils.roundTo
import webcomponents.baseLayout
import webcomponents.cardTitle
import webcomponents.twContentScrollBox
import webcomponents.genericButton
import webcomponents.genericInput
import workspacetools.usermanagement.urlEncode


fun RenderContext.cardEditZoneGeofence() {
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val mapStateStore: MapStateStore by koinCtx.inject()
    val maplibreMap: MaplibreMap by koinCtx.inject()
    val geoShapeStore: GeoShapeStore by koinCtx.inject()

    utils.require("nouislider/dist/nouislider.css")
    utils.require("nouislider/dist/nouislider.min.css")

    baseLayout(
        header = {
            cardTitle(title = translation[TL.CardZoneEditor.CARD_TITLE]) { FormationIcons.Zone.icon }
        },
        content = {
            twContentScrollBox {
                shapeSelector(geoShapeStore)
                stackUp(
                    {
                        flex {
                            grow { "1" }
                        }
                        alignItems { stretch }
                        justifyContent { spaceBetween }
                    },
                ) {
                    items {
                        geoShapeStore.selectedGeoShape.data.filterNotNull().render { geoShape ->
                            when (geoShape) {
                                is GeoShape.Circle -> {
                                    inputSliderDouble(
                                        id = "circleRadiusMeterInput",
                                        title = translation[TL.CardZoneEditor.RADIUS],
                                        minValue = 1.0,
                                        maxValue = 1000.0,
                                        initValue = geoShape.radius,
                                        valueStream = geoShapeStore.data.mapNotNull { gs ->
                                            if (gs is GeoShape.Circle) gs.radius else null
                                        },
                                        valueFromSliderHandler = geoShapeStore.setRadiusMetersFromSlider,
                                        valueStreamFromInput = geoShapeStore.lastRadiusMetersFromInput.data.mapNotNull { it },
                                        valueFromInputHandler = geoShapeStore.setRadiusMetersFromInput,
                                    )
                                }

                                is GeoShape.Rectangle -> {
                                    inputSliderDouble(
                                        id = "xMeterInput",
                                        title = translation[TL.CardZoneEditor.WIDTH],
                                        minValue = 1.0,
                                        maxValue = 1000.0,
                                        initValue = geoShape.xMeters,
                                        valueStream = geoShapeStore.data.mapNotNull { gs ->
                                            if (gs is GeoShape.Rectangle) gs.xMeters else null
                                        },
                                        valueFromSliderHandler = geoShapeStore.setXMetersFromSlider,
                                        valueStreamFromInput = geoShapeStore.lastXMetersFromInput.data.mapNotNull { it },
                                        valueFromInputHandler = geoShapeStore.setXMetersFromInput,
                                    )
                                    inputSliderDouble(
                                        id = "yMeterInput",
                                        title = translation[TL.CardZoneEditor.LENGTH],
                                        minValue = 1.0,
                                        maxValue = 1000.0,
                                        initValue = geoShape.yMeters,
                                        valueStream = geoShapeStore.data.mapNotNull { gs ->
                                            if (gs is GeoShape.Rectangle) gs.yMeters else null
                                        },
                                        valueFromSliderHandler = geoShapeStore.setYMetersFromSlider,
                                        valueStreamFromInput = geoShapeStore.lastYMetersFromInput.data.mapNotNull { it },
                                        valueFromInputHandler = geoShapeStore.setYMetersFromInput,
                                    )
                                    inputSliderDouble(
                                        id = "rectRotationInput",
                                        title = translation[TL.CardZoneEditor.ROTATION],
                                        minValue = -180.0,
                                        maxValue = 180.0,
                                        initValue = geoShape.rotationDegrees,
                                        valueStream = geoShapeStore.data.mapNotNull { gs ->
                                            if (gs is GeoShape.Rectangle) gs.rotationDegrees else null
                                        },
                                        valueFromSliderHandler = geoShapeStore.setRotationFromSlider,
                                        valueStreamFromInput = geoShapeStore.lastRotationFromInput.data.mapNotNull { it },
                                        valueFromInputHandler = geoShapeStore.setRotationFromInput,
                                    )
                                }

                                is GeoShape.Isogon -> {
                                    inputSliderInt(
                                        id = "verticesNumberInput",
                                        title = translation[TL.CardZoneEditor.VERTICES],
                                        minValue = 5,
                                        maxValue = 49,
                                        initValue = geoShape.vertices,
                                        valueStream = geoShapeStore.data.mapNotNull { gs ->
                                            if (gs is GeoShape.Isogon) gs.vertices else null
                                        },
                                        valueFromSliderHandler = geoShapeStore.setVerticesFromSlider,
                                        valueStreamFromInput = geoShapeStore.lastVerticesFromInput.data.mapNotNull { it },
                                        valueFromInputHandler = geoShapeStore.setVerticesFromInput,
                                    )
                                    inputSliderDouble(
                                        id = "isogonRadiusMeterInput",
                                        title = translation[TL.CardZoneEditor.RADIUS],
                                        minValue = 1.0,
                                        maxValue = 1000.0,
                                        initValue = geoShape.radius,
                                        valueStream = geoShapeStore.data.mapNotNull { gs ->
                                            if (gs is GeoShape.Isogon) gs.radius else null
                                        },
                                        valueFromSliderHandler = geoShapeStore.setRadiusMetersFromSlider,
                                        valueStreamFromInput = geoShapeStore.lastRadiusMetersFromInput.data.mapNotNull { it },
                                        valueFromInputHandler = geoShapeStore.setRadiusMetersFromInput,
                                    )
                                    inputSliderDouble(
                                        id = "isogonRotationInput",
                                        title = translation[TL.CardZoneEditor.ROTATION],
                                        minValue = -180.0,
                                        maxValue = 180.0,
                                        initValue = geoShape.rotationDegrees,
                                        valueStream = geoShapeStore.data.mapNotNull { gs ->
                                            if (gs is GeoShape.Isogon) gs.rotationDegrees else null
                                        },
                                        valueFromSliderHandler = geoShapeStore.setRotationFromSlider,
                                        valueStreamFromInput = geoShapeStore.lastRotationFromInput.data.mapNotNull { it },
                                        valueFromInputHandler = geoShapeStore.setRotationFromInput,
                                    )
                                }
                            }
                            lineUp(
                                {
                                    alignItems { center }
                                    justifyContent { spaceBetween }
                                    padding { small }
                                },
                            ) {
                                items {
                                    span {
                                        inlineStyle("white-space: nowrap;")
                                        translation[TL.CardZoneEditor.SHORTCODE].renderText(into = this)
                                    }
                                    genericInput(
                                        value = geoShapeStore.data.mapNotNull { it?.stringCode },
                                    ) {
                                        changes.values() handledBy geoShapeStore.parseStringCode
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },

        footer = {
            div("w-full") {
                div("flex flew-row w-full justify-between wrap-nowrap") {
                    genericButton(
                        title = translation[TL.General.BACK],
                        styleFlow = flowOf(secondaryButtonStyleParams),
                        width = { "120px" },
                        valueFlow = activeObjectStore.data,
                        clickHandlers = listOf(routerStore.back),
                        valueHandlers = listOf(
                            SimpleHandler { flow, job ->
                                flow.filterNotNull().onEach { obj ->
                                    maplibreMap.removeGeometryShapeOverride(obj)
                                }.launchIn(MainScope() + job)
                            },
                        ),
                    )

                    // applies the change immeditately so kind of breaks the back back back save paradigm ;-)
                    genericButton(
                        title = translation[TL.General.SAVE],
                        state = geoShapeStore.data.map { modifiedGeoShape ->
                            modifiedGeoShape != null && activeObjectStore.current.geometry.getGeoShape() != modifiedGeoShape
                        }.distinctUntilChanged(),
                        styleFlow = flowOf(primaryButtonStyleParams),
                        width = { "150px" },
                        valueFlow = combine(activeObjectStore.data, geoShapeStore.data) { currentObject, modifiedGeoShape ->
                            modifiedGeoShape?.let { geoShape ->
                                ObjectChanges(
                                    currentObject.id,
                                    UpdateZoneGeometry(geoShape.geometry(currentObject.latLon)),
                                )
                            }
                        },
                        valueHandlers = listOf(activeObjectStore.applyObjectChanges),
                        clickHandlers = listOf(routerStore.back),
                    )
                }
            }
        },
    )
    combine(activeObjectStore.data, mapStateStore.data) { a, s -> a to s }.render { (currentObject, mapState) ->
        if (mapState != null) {
            maplibreMap.addGeometryCenterOverride(currentObject, mapState.center.pointGeometry())
        }
    }
    combine(activeObjectStore.data, geoShapeStore.data) { a, s -> a to s }.render { (currentObject, modifiedGeoShape) ->
        if (modifiedGeoShape != null) {
            maplibreMap.addGeometryShapeOverride(currentObject, modifiedGeoShape.geometry(currentObject.latLon))
        }
    }

}

val Geometry.geojsonIOLink get() = "http://geojson.io/#data=data:application/json,${this.toString().urlEncode()}"


fun RenderContext.shapeSelector(geoShapeStore: GeoShapeStore) {
    geoShapeStore.selectedGeoShape.data.render { geoShape ->
        lineUp(
            {
                width { full }
                alignItems { center }
                justifyContent { spaceBetween }
                padding { small }
            },
        ) {
            spacing { small }
            items {
                div {
                    shapeButton(
                        GeoShapeSelect.Circle,
                        value = 2.5,
                        valueHandler = geoShapeStore.circle,
                        active = geoShape?.shapeSelect == GeoShapeSelect.Circle,
                    )
                }
                div {
                    shapeButton(
                        GeoShapeSelect.Rectangle,
                        value = 5.0 to 5.0,
                        valueHandler = geoShapeStore.rectangle,
                        active = geoShape?.shapeSelect == GeoShapeSelect.Rectangle,
                    )
                }
                div {
                    shapeButton(
                        GeoShapeSelect.Isogon,
                        value = 5 to 2.5,
                        valueHandler = geoShapeStore.isogon,
                        active = geoShape?.shapeSelect == GeoShapeSelect.Isogon,
                    )
                }
            }
        }
    }
}

fun <T> RenderContext.shapeButton(
    shapeSelect: GeoShapeSelect,
    value: T,
    valueHandler: SimpleHandler<T>,
    active: Boolean,
) {
    flexBox(
        {
            position { relative { } }
            width { "60px" }
            height { "60px" }
            justifyContent { center }
            alignItems { center }
            textAlign { center }
            css("cursor: pointer;")
        },
    ) {
        icon(
            {
                color { FormationColors.GrayDisabled.color }
                size { "50px" }
                if (active) {
                    color { primary.main }
                }
            },
        ) { fromTheme { shapeSelect.icon.shape } }
        if (active) {
            // Check Icon
            div(
                {
                    position {
                        absolute {
                            bottom { none }
                            right { none }
                        }
                    }
                },
            ) {
                flexBox(
                    {
                        flex {
                            grow { "0" }
                            shrink { "0" }
                            basis { "25px" }
                        }
                        width { "25px" }
                        height { "25px" }
                        color { secondary.main }
                        background { color { primary.main } }
                        border {
                            width { "2px" }
                            color { secondary.main }
                        }
                        radius { full }
                        padding { tiny }
                        justifyContent { center }
                        alignItems { center }
                        textAlign { center }
                    },
                ) {
                    icon(
                        {
                            size { normal }
                        },
                    ) { fromTheme { FormationUIIcons.Check.icon } }
                }
            }
        }
        clicks.map { value } handledBy valueHandler
    }
}

fun RenderContext.inputSliderDouble(
    id: String,
    title: Flow<String>,
    minValue: Double,
    maxValue: Double,
    initValue: Double,
    valueStream: Flow<Double>,
    valueStreamFromInput: Flow<Double>,
    valueFromInputHandler: SimpleHandler<Double>,
    valueFromSliderHandler: SimpleHandler<Double>,
) {
    stackUp(
        {
            width { full }
            alignItems { start }
            justifyContent { center }
            padding { small }
        },
    ) {
        spacing { small }
        items {
            lineUp(
                {
                    width { full }
                    alignItems { center }
                    justifyContent { spaceBetween }
                },
            ) {
                spacing { small }
                items {
                    span("w-full") {
                        title.renderText(into = this)
                    }
                    genericInput(
                        value = valueStream.map { it.toString() },
                    ) {
                        changes.values().map { it.toDouble() } handledBy valueFromInputHandler
                    }
                }
            }
            div("w-full") {
                id(id)
                val observer = MutationObserver { _, mutationObserver ->
                    if (document.contains(domNode)) {
                        val slider = domNode
                        nouislider.create(
                            slider,
                            jsApply<NoUIOptions> {
                                start = arrayOf(initValue as Number)
                                range = jsApply<Range> {
                                    min = minValue
                                    max = maxValue
                                }
                            },
                        )
                        slider.asDynamic().noUiSlider.on("slide") { value: Double ->
                            valueFromSliderHandler(value)
                        }
                        valueStreamFromInput handledBy { newValue ->
                            slider.asDynamic().noUiSlider.set(newValue.roundTo(1))
                            console.log("SET $id value to ${newValue.roundTo(1)}")
                        }
                        mutationObserver.disconnect()
                    }
                }
                observer.observe(
                    document,
                    MutationObserverInit(
                        attributes = true,
                        childList = true,
                        characterData = false,
                        subtree = true,
                    ),
                )
            }
        }
    }
}

fun RenderContext.inputSliderInt(
    id: String,
    title: Flow<String>,
    minValue: Int,
    maxValue: Int,
    initValue: Int,
    valueStream: Flow<Int>,
    valueStreamFromInput: Flow<Int>,
    valueFromInputHandler: SimpleHandler<Int>,
    valueFromSliderHandler: SimpleHandler<Int>,
) {
    stackUp(
        {
            width { full }
            alignItems { start }
            justifyContent { center }
            padding { small }
        },
    ) {
        spacing { small }
        items {
            lineUp(
                {
                    width { full }
                    alignItems { center }
                    justifyContent { spaceBetween }
                },
            ) {
                spacing { small }
                items {
                    span("w-full") {
                        title.renderText(into = this)
                    }
                    genericInput(
                        value = valueStream.map { it.toString() },
                    ) {
                        changes.values().map { it.toInt() } handledBy valueFromInputHandler
                    }
                }
            }
            div("w-full") {
                id(id)
                val observer = MutationObserver { _, mutationObserver ->
                    if (document.contains(domNode)) {
                        val slider = domNode
                        nouislider.create(
                            slider,
                            jsApply<NoUIOptions> {
                                start = arrayOf(initValue as Number)
                                range = jsApply<Range> {
                                    min = minValue
                                    max = maxValue
                                }
                            },
                        )
                        slider.asDynamic().noUiSlider.on("update") { value: Double ->
                            valueFromSliderHandler(value.toInt())
                        }
                        valueStreamFromInput.render { newValue ->
                            slider.asDynamic().noUiSlider.set(newValue)
                            console.log("SET $id value to $newValue")
                        }
                        mutationObserver.disconnect()
                    }
                }
                observer.observe(
                    document,
                    MutationObserverInit(
                        attributes = true,
                        childList = true,
                        characterData = false,
                        subtree = true,
                    ),
                )
            }
        }
    }
}
