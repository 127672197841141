package auth

import apiclient.groups.WorkSpaceOptions
import apiclient.groups.restGetWorkspaceOptions
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.conflate
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.mapNotNull
import login.HostConfigStore
import login.WorkspaceInputStore

val emptyWorkSpaceOptions = WorkSpaceOptions("", "", anonymousAccessAllowed = false, signupsAllowed = false)

class WorkspaceOptionsStore : RootStore<WorkSpaceOptions?>(
    initialData = null,
    job = Job(),
) {

    private val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()
    private val workspaceInputStore by koinCtx.inject<WorkspaceInputStore>()
    private val hostConfigStore by koinCtx.inject<HostConfigStore>()

    private val preLoginGraphQLClient = hostConfigStore.anonymousClient()

    private val fetchWorkSpaceOptions = handle<String> { oldOptions, workspaceName ->
        if (workspaceName.isNotBlank()) {
            preLoginGraphQLClient.restGetWorkspaceOptions(name = workspaceName).fold(
                { options ->
                    console.log("Fetched WorkspaceOptions for:", options.name)
                    (oldOptions ?: emptyWorkSpaceOptions).copy(
                        groupId = options.groupId,
                        name = options.name,
                        anonymousAccessAllowed = options.anonymousAccessAllowed,
                        signupsAllowed = options.signupsAllowed,
                    )
                },
                { error ->
                    console.log("Failed to fetch workspace options:", error.message)
                    emptyWorkSpaceOptions
                    // TODO display Workspace not found popup or page when workspace options not found
                },
            )
        } else {
            null
        }
    }

    init {
        workspaceInputStore.data.debounce(500).conflate() handledBy fetchWorkSpaceOptions
        currentWorkspaceStore.data.debounce(500).conflate().mapNotNull { currentWorkspace ->
            if (currentWorkspace?.name.isNullOrBlank() || currentWorkspace?.name == current?.name) null else currentWorkspace?.name
        } handledBy fetchWorkSpaceOptions
    }
}
