package twcomponents

import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.ScopeContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.headless.components.Modal
import dev.fritz2.headless.components.modal
import kotlinx.coroutines.flow.Flow
import org.w3c.dom.Element
import org.w3c.dom.HTMLDivElement

fun RenderContext.twRowOf(
    additionalClasses: String? = null,
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) = div("flex flex-row gap-2 items-center", id, scope) {
    additionalClasses?.let { className(it) }
    content(this)
}

fun RenderContext.twRowOfCenter(
    additionalClasses: String? = null,
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) = div("flex flex-row w-full gap-2 items-center justify-center", id, scope) {
    additionalClasses?.let { className(it) }
    content(this)
}

fun RenderContext.twRowOfWrap(
    additionalClasses: String? = null,
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) = div("flex flex-row flex-wrap gap-2 sm:gap-3 items-center justify-start", id, scope) {
    additionalClasses?.let { className(it) }
    content(this)
}

fun RenderContext.twRowOfJustifyBetween(
    additionalClasses: String? = null,
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<Element>.() -> Unit
) = custom("row-with-justify-between", "flex flex-row gap-2 items-center justify-between", id, scope) {
    additionalClasses?.let { className(it) }
    content(this)
}

fun RenderContext.twRowOfJustifyEnd(
    additionalClasses: String? = null,
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) = div("w-full flex flex-row gap-2 items-center justify-end", id, scope) {
    additionalClasses?.let { className(it) }
    content(this)
}


fun RenderContext.twRowOfJustifyStart(
    additionalClasses: String? = null,
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) = div("w-full flex flex-row gap-2 items-center justify-start", id, scope) {
    additionalClasses?.let { className(it) }
    content(this)
}

fun RenderContext.twColOf(
    additionalClasses: String? = null,
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) = div("flex flex-col gap-2", id, scope) {
    additionalClasses?.let { className(it) }
    content(this)
}


fun RenderContext.twColOfCenter(
    additionalClasses: String? = null,
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) = div("flex flex-col gap-2 items-center justify-center", id, scope) {
    additionalClasses?.let { className(it) }
    content(this)
}

fun RenderContext.twColOfStretch(
    additionalClasses: String? = null,
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) = div("flex flex-col gap-2 items-stretch justify-center", id, scope) {
    additionalClasses?.let { className(it) }
    content(this)
}

fun RenderContext.twColOfNoGap(
    additionalClasses: String? = null,
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) = div("flex flex-col", id, scope) {
    additionalClasses?.let { className(it) }
    content(this)
}

fun RenderContext.twRowOfNoGap(
    additionalClasses: String? = null,
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) = div("flex flex-row items-stretch justify-between", id, scope) {
    additionalClasses?.let { className(it) }
    content(this)
}

fun RenderContext.twButtonRow(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) {
    div("flex flex-row gap-2 items-center justify-center", id, scope) {
        content(this)
    }
}

fun RenderContext.twContentBoxOf(
    additionalClasses: String? = null,
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) {
    div(
        "flex flex-col gap-2 items-stretch justify-center border-b border-gray-200 hover:bg-gray-100 focus:bg-gray-100",
        id,
        scope,
    ) {
        additionalClasses?.let { className(it) }
        content(this)
    }
}

fun twModal(
    toggleStore: Store<Boolean>,
    closeHandlers: List<SimpleHandler<Unit>>? = null,
    content: (Modal.ModalPanel<HTMLDivElement>.(closeModal: SimpleHandler<Unit>, modalOpened: Flow<Boolean>, optionalCloseHandlers: List<SimpleHandler<Unit>>?) -> Unit)? = null,
) = modal {
    this.openState(toggleStore)
    modalPanel("fixed inset-0 flex items-center justify-center z-[1050] overflow-auto bg-black bg-opacity-50") {
        content?.invoke(this, close, opened, closeHandlers)
    }
}

fun RenderContext.twModalCenterFitContentWrapper(
    content: HtmlTag<HTMLDivElement>.() -> Unit
) {
    div("relative flex flex-col min-w-[fit-content] min-h-[fit-content] max-w-full max-h-full overflow-auto bg-white rounded-2xl p-2 m-4") {
        content.invoke(this)
    }
}

fun RenderContext.twModalContentWrapper(
    additionalClasses: String? = null,
    content: HtmlTag<HTMLDivElement>.() -> Unit
) {
    div("relative flex flex-col max-w-full max-h-full overflow-auto bg-white rounded-2xl p-2 m-4") {
        additionalClasses?.let { className(" $it") }
        content.invoke(this)
    }
}

fun RenderContext.twModalFullScreenWrapper(
    content: HtmlTag<HTMLDivElement>.() -> Unit
) {
    div("relative w-full h-full md:w-[95%] md:h-[95%] bg-white md:rounded-2xl p-2") {
        content.invoke(this)
    }
}
