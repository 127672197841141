package data.users.views

import apiclient.geoobjects.Content
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectType
import auth.ApiUserStore
import data.objects.ActiveObjectStore
import data.objects.AssigneeSelectorStore
import data.objects.AttendeesSelectorStore
import data.users.UserListStore
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import localization.translate
import model.L
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.twIconLarge
import twcomponents.twIconMedium
import twcomponents.twPageHeaderBack
import twcomponents.twRowOfJustifyEnd
import twcomponents.twTitle
import utils.handleFunctions
import utils.mergeIfNotBlank
import webcomponents.baseLayout
import webcomponents.stateSelectorButton
import webcomponents.twContentScrollBox

fun RenderContext.cardUserSelect() {

    val translation: Translation by koinCtx.inject()
    val attendeesSelectorStore: AttendeesSelectorStore = koinCtx.get()
    val assigneeSelectorStore: AssigneeSelectorStore = koinCtx.get()
    val userListStore: UserListStore = koinCtx.get()
    val activeObjectStore: ActiveObjectStore = koinCtx.get()
    val createdBy = activeObjectStore.map(GeoObjectDetails.L.createdBy)
    val type = activeObjectStore.map(GeoObjectDetails.L.objectType)
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val currentUserId = apiUserStore.current.userId

    userListStore.fetchGroupMembers(null)

    when (type.current) {
        ObjectType.Task -> {
            val taskAssigneeId = activeObjectStore.map(GeoObjectDetails.L.assignedTo)
            taskAssigneeId.data.map {
                it?.let { assigneeId -> if (assigneeId.isNotBlank()) assigneeSelectorStore.updateUserIds(listOf(assigneeId)) } ?: assigneeSelectorStore.update(
                    null,
                )
            }
        }

        ObjectType.Event -> {
            val attendeesList = activeObjectStore.map(GeoObjectDetails.L.attendees)
            attendeesList.data.map { it?.let { attendees -> attendeesSelectorStore.update(attendees.map { attendee -> attendee.userId }) } }
        }

        else -> {}
    }

    baseLayout(
        header = {
            twPageHeaderBack(
                backButtonIcon = FormationUIIcons.Save,
                backButtonTitle = TL.General.SAVE,
                additionalBackButtonHandler = handleFunctions {
                    activeObjectStore.readFromChangeInputStores(Unit)
                },
                rightAlignedBlock = {
                    twIconLarge(icon = FormationUIIcons.ArrowLeft)
                },
            ) {
                twTitle(icon = FormationIcons.UserAlt) {
                    translate(TL.CardUserSelect.CARD_TITLE)
                }
            }
        },
        content = {
            twContentScrollBox {
                // SELECT ALL BUTTON, WHEN SELECTING MEETING ATTENDEES
                if (type.current == ObjectType.Event) {
                    val allSelected = attendeesSelectorStore.data.map { selectedUsers ->
                        selectedUsers != null
                            && selectedUsers.containsAll((userListStore.current?.map { it.userId } ?: emptyList()))
                    }
                    twRowOfJustifyEnd("pr-3 pb-2") {
                        p { translate(TL.CardUserSelect.ALL) }
                        button {
                            div("flex flex-grow-0 flex-shrink-0 basis-10 w-10 h-10 text-formationWhite items-center justify-center p-1 rounded-full") {
                                className(allSelected.map { active -> if (active) "bg-formationBlack" else "border-2 border-formationBlack" })
                                allSelected.render { active ->
                                    if (active) {
                                        twIconMedium(icon = FormationUIIcons.Check)
                                    }
                                }
                            }
                            clicks.map { userListStore.current?.map { it.userId } ?: emptyList() } handledBy attendeesSelectorStore.toggleAll
                        }
                    }
                }

                // USER LIST
                stackUp(
                    {
                        width { full }
                        height { maxContent }
                        justifyContent { center }
                        alignItems { stretch }
                        display { flex }
                    },
                ) {
                    spacing { small }
                    items {
                        when (type.current) {
                            ObjectType.Task -> {
                                stateSelectorButton(
                                    active = assigneeSelectorStore.data.map { it == null },
                                    icon = { FormationIcons.UserGroup.icon },
                                    initials = null,
                                    title = translation[TL.CardUserSelect.ANYONE],
                                    subtitle = translation[TL.CardUserSelect.ANYONE_DESCRIPTION],
                                    value = Unit,
                                    valueHandlers = listOf(assigneeSelectorStore.reset),
                                )
                                userListStore.current?.forEach { userEntry ->
                                    val isCreator = createdBy.current == userEntry.userId
                                    val isYou = currentUserId == userEntry.userId
                                    stateSelectorButton(
                                        //active = assigneeSelectorStore.data.map { it?.contains(userEntry)?: false },
                                        active = assigneeSelectorStore.data.map { selected ->
                                            selected?.map { it.userId }?.contains(userEntry.userId) ?: false
                                        },
                                        profilePictureLink = (userEntry.profilePhoto?.thumbNail as? Content.Image)?.href ?: userEntry.profilePhoto?.href,
                                        icon = { FormationIcons.UserAlt.icon },
                                        initials = initials(userEntry.firstName, userEntry.lastName),
                                        title = flowOf(
                                            "${userEntry.firstName} ${userEntry.lastName}${
                                                when {
                                                    isCreator && isYou -> {
                                                        " (${
                                                            translation.getString(TL.General.YOU, mapOf("case" to "nominative"))
                                                                .lowercase().replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                                                        }, ${translation.getString(TL.CardUserSelect.CREATOR)})"
                                                    }

                                                    isCreator -> " (${translation.getString(TL.CardUserSelect.CREATOR)})"
                                                    isYou -> {
                                                        " (${
                                                            translation.getString(TL.General.YOU, mapOf("case" to "nominative"))
                                                                .lowercase().replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                                                        })"
                                                    }

                                                    else -> ""
                                                }
                                            }",
                                        ),
                                        subtitle = flowOf(userEntry.jobTitle.mergeIfNotBlank(userEntry.company, " | ")),
                                        value = userEntry,
                                        valueHandlers = listOf(assigneeSelectorStore.toggleSingleUserSelection),
                                    )
                                }
                            }

                            ObjectType.Event -> {
                                userListStore.current?.forEach { userEntry ->
                                    val isCreator = createdBy.current == userEntry.userId
                                    val isYou = currentUserId == userEntry.userId
                                    stateSelectorButton(
                                        active = attendeesSelectorStore.data.map { it?.contains(userEntry.userId) ?: false },
                                        profilePictureLink = (userEntry.profilePhoto?.thumbNail as? Content.Image)?.href ?: userEntry.profilePhoto?.href,
                                        icon = { FormationIcons.UserAlt.icon },
                                        initials = initials(userEntry.firstName, userEntry.lastName),
                                        title = flowOf(
                                            "${userEntry.firstName} ${userEntry.lastName}${
                                                when {
                                                    isCreator && isYou -> {
                                                        " (${
                                                            translation.getString(TL.General.YOU, mapOf("case" to "nominative"))
                                                                .lowercase().replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                                                        }, ${translation.getString(TL.CardUserSelect.CREATOR)})"
                                                    }

                                                    isCreator -> " (${translation.getString(TL.CardUserSelect.CREATOR)})"
                                                    isYou -> {
                                                        " (${
                                                            translation.getString(TL.General.YOU, mapOf("case" to "nominative"))
                                                                .lowercase().replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                                                        })"
                                                    }

                                                    else -> ""
                                                }
                                            }",
                                        ),
                                        subtitle = flowOf(userEntry.jobTitle.mergeIfNotBlank(userEntry.company, " | ")),
//                                        stateColor = attendees.data.map { list -> list?.firstOrNull { it.userId  == userEntry.userId }?.meetingInvitationStatus?.getColor() }, // TODO check with design team if enable this
//                                        stateIcon = attendees.data.map { list -> list?.firstOrNull { it.userId  == userEntry.userId }?.meetingInvitationStatus?.getIcon() },
                                        value = userEntry.userId,
                                        valueHandlers = listOf(attendeesSelectorStore.toggleMultiUserSelection),
                                    )
                                }
                            }

                            else -> {}
                        }
                    }
                }
            }
        },
    )
}

fun initials(firstName: String?, lastName: String?): String? {
    return if (firstName == null && lastName == null) null
    else "${firstName?.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }?.get(0) ?: "?"}${
        lastName?.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }?.get(0) ?: "?"
    }"
}
