package search.nestedObjects

import apiclient.search.ObjectSearchResult
import auth.ApiUserStore
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.Translation
import localization.translate
import mainmenu.RouterStore
import map.views.workplacetools.ActiveArchetypeSearchFieldValuesStore
import model.NestedObjects
import model.fetched
import search.searchFilterStores.ActiveFieldValuesStore
import search.searchFilterStores.ActiveKeywordsStore
import search.searchFilterStores.ActiveObjectTypesStore
import search.searchFilterStores.ActiveReadOnlyKeywordsStore
import search.searchInput
import search.searchResultsListEntry
import search.twSearchHeaderWrapper
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.twPageHeaderBack
import twcomponents.twTitle
import utils.toKeyWordTagsList
import webcomponents.KeywordTagActionType
import webcomponents.KeywordTagType
import webcomponents.baseLayout
import webcomponents.circleIconButton
import webcomponents.keywordTagList
import webcomponents.twContentScrollBox

fun RenderContext.cardNestedObjects() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val nestedObjectsResultsStore: NestedObjectsResultsStore = koinCtx.get()
    val nestedObjectSearchInputFieldStore: NestedObjectSearchInputFieldStore = koinCtx.get()
    val activeNestedObjectTypesStore: ActiveNestedObjectTypesStore = koinCtx.get()
    val activeNestedSearchKeywordStore: ActiveNestedSearchKeywordStore = koinCtx.get()
    val activeNestedObjectsReadOnlyKeywordsStore: ActiveNestedObjectsReadOnlyKeywordsStore = koinCtx.get()
    val activeNestedSearchFieldValuesStore: ActiveArchetypeSearchFieldValuesStore = koinCtx.get()

    baseLayout(
        header = {
            // Search input and tag management
            twSearchHeaderWrapper(
                searchInputFieldId = "inputNestedObjectSearch",
                searchInputFieldStore = nestedObjectSearchInputFieldStore,
                activeObjectTypesStore = activeNestedObjectTypesStore,
                activeKeywordsStore = activeNestedSearchKeywordStore,
                activeReadOnlyKeywordsStore = activeNestedObjectsReadOnlyKeywordsStore,
                activeFieldValuesStore = activeNestedSearchFieldValuesStore,
                keyWordTagType = KeywordTagType.NestedObjectTag,
                withCoordinateDetection = false,
            ) {
                twPageHeaderBack(
                    backButtonToolTipPlacement = PlacementValues.left,
                ) {
                    twTitle(icon = FormationIcons.Boxes) { translate(TL.CardNestedObjects.CARD_TITLE) }
                }
            }
        },
        content = {
            twContentScrollBox {
                // RESULTSLIST
                nestedObjectList(
                    results = nestedObjectsResultsStore.map(NestedObjects.fetched()).data.map { results ->
                        results
//                    .sortedBy { it.hit.tags.getUniqueTag(ObjectTags.Archived).toBoolean() }
                    },
                )
            }
        },
    )
}

fun RenderContext.nestedObjectList(results: Flow<List<ObjectSearchResult>>) {

    val translation: Translation by koinCtx.inject()
    val searchPageStore: SearchPageStore by koinCtx.inject()
    val nestedObjectsResultsStore: NestedObjectsResultsStore by koinCtx.inject()

    val moreToLoad =
        nestedObjectsResultsStore.data.mapNotNull { it.total }.combine(searchPageStore.data) { total, searchPage ->
            (searchPage.from + searchPage.size) < total
        }

    results.render { resultsList ->
        if (resultsList.isNotEmpty()) {
            div(
                {
                    width { full }
                    height { full }
                    overflow { auto }
                },
                id = "list-scroll-container",
            ) {
                stackUp(
                    {
                        width { full }
                        alignItems { center }
                        padding { normal }
                    },
                    id = "list-entry-stack-up",
                ) {
                    spacing { small }
                    items {
                        resultsList.forEach { result ->
                            searchResultsListEntry(
                                geoObject = result.hit,
                                keywordTagType = KeywordTagType.NestedObjectTag,
                            )
                        }
                        // LOAD MORE BUTTON
                        moreToLoad.render { showButton ->
                            if (showButton) {
                                button {
                                    flexBox(
                                        {
                                            direction { row }
                                            justifyContent { center }
                                            alignItems { center }
                                            margins {
                                                vertical { small }
                                            }
                                            fontSize { smaller }
                                            border {
                                                width(formationStyles.borderWidth)
                                            }
                                            hover {
                                                background {
                                                    color { FormationColors.GrayLight.color }
                                                }
                                            }
                                            radius(formationStyles.inputRadius)
                                            padding { smaller }
                                        },
                                    ) {
                                        icon(
                                            {
                                                size { normal }
                                            },
                                        ) { fromTheme { FormationUIIcons.Add.icon } }
                                        span(
                                            {
                                                margins { left { tiny } }
                                            },
                                        ) { translation[TL.CardNestedObjects.LOAD_MORE].renderText(into = this) }
                                    }
                                    clicks handledBy searchPageStore.loadDefaultStepMore
                                }
                            }
                        }
                    }
                }
            }
        } else {
            flexBox(
                {
                    width { full }
                    justifyContent { center }
                    alignItems { center }
                },
            ) {
                span { translation[TL.Search.NO_RESULTS].renderText(into = this) }
            }
        }
    }
}

fun RenderContext.genericSearchHeader(
    searchInputFieldId: String,
    searchInputFieldStore: Store<String>,
    activeObjectTypesStore: ActiveObjectTypesStore,
    activeSearchKeywordStore: ActiveKeywordsStore,
    activeReadOnlyKeywordsStore: ActiveReadOnlyKeywordsStore,
    activeFieldValuesStore: ActiveFieldValuesStore,
    keyWordTagType: KeywordTagType,
) {

    val apiUserStore: ApiUserStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()

    // SEARCH INPUT FIELD
    div(
        "w-full flex items-center px-3 my-1 gap-3",
    ) {
        inlineStyle("height: ${formationStyles.inputHeight};")
        searchInput(id = searchInputFieldId, searchInputFieldStore, apiUserStore.current.isAnonymous)
        circleIconButton(
            size = formationStyles.inputHeight,
            iconFlow = flowOf { FormationIcons.Tag.icon },
            iconSize = { large },
            styleFlow = flowOf {
                background {
                    color { secondary.main }
                }
                border {
                    color { primary.main }
                    width(formationStyles.borderWidth)
                }
                hover {
                    color { secondary.main }
                    background {
                        color { FormationColors.MarkerYou.color }
                    }
                    border {
                        color { FormationColors.MarkerYou.color }
                    }
                }
                radius(formationStyles.inputRadius)
            },
            disabled = apiUserStore.current.isAnonymous,
            tooltipText = translation[TL.KeywordTags.ADD_A_TAG_TOOLTIP], // TODO change tooltip to "manage search tags"
            value = Unit,
            addOrReplaceRoute = mapOf("change" to "tags"),
        )
    }
    // active Tags
    combine(
        activeObjectTypesStore.data,
        activeSearchKeywordStore.data,
        activeReadOnlyKeywordsStore.data,
        activeFieldValuesStore.data,
    ) { objTypes, keywords, otherTags, fieldValues ->
        objTypes.map { it.name }.toKeyWordTagsList(KeywordTagActionType.RemoveReadOnly) +
            otherTags.map { it.copy(actionType = KeywordTagActionType.RemoveReadOnly) } +
            keywords.toKeyWordTagsList(KeywordTagActionType.Remove) +
            fieldValues.toKeyWordTagsList(KeywordTagActionType.RemoveFieldValue)
    }.render { combinedKeywords ->
        if (combinedKeywords.isNotEmpty()) {
            div(
                {
                    width { full }
                    paddings {
                        horizontal { small }
                    }
                    margins {
                        bottom { tiny }
                    }
                },
            ) {
                keywordTagList(
                    keywords = flowOf(combinedKeywords),
                    keywordTagType = keyWordTagType,
                )
            }
        }
    }
}
