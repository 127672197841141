package search.global

import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import map.bottombar.inlinedBottomBar
import search.SearchDistanceCalculationStore
import search.distanceCalcSwitch
import search.globalSearchHeader
import search.searchResultsList
import twcomponents.twColOfNoGap
import webcomponents.baseLayout
import webcomponents.twContentScrollBox

fun RenderContext.pageSearch() {
    val globalSearchResultsStore: GlobalSearchResultsStore by koinCtx.inject()
    val searchInputFieldStore: SearchInputFieldStore by koinCtx.inject()
    val searchDistanceCalculationStore: SearchDistanceCalculationStore by koinCtx.inject()

    baseLayout(
        header = {
            twColOfNoGap("w-full gap-1") {
                globalSearchHeader(
                    title = TL.Search.PAGE_SEARCH_TITLE,
                    searchInputFieldStore = searchInputFieldStore,
                    withCoordinateDetection = true,
                )
                // filter buttons
//                searchFilterBar()
                // distance calculation switch
                distanceCalcSwitch(
                    distanceToSelectStore = searchDistanceCalculationStore,
                    switchHandler = searchDistanceCalculationStore.select,
                )
            }
        },
        content = {
            twContentScrollBox {
                // search results
                searchResultsList(results = globalSearchResultsStore.data.mapNotNull { it })
            }
        },
        footerPaddings = null,
        footer = {
            inlinedBottomBar()
        },
    )
}
