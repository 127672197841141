package mainmenu

import analyticsdashboard.pageAnalyticsDashboard
import apiclient.users.UserFeatureFlag
import apiclient.validations.parseEnumValue
import auth.ApiUserStore
import auth.FeatureFlagStore
import auth.Features
import camera.cameraWrapper.cameraModal
import camera.nimiq.qrScanCamera
import data.objects.views.cardManageFieldValueTag
import data.objects.views.cardManageKeywordTags
import data.users.views.pageMyProfile
import data.users.views.pageOtherUserProfile
import data.users.views.pagePublicUserProfile
import data.users.views.pageUserVerificationResult
import dev.fritz2.components.compat.div
import dev.fritz2.components.icon
import dev.fritz2.components.modal
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.href
import dev.fritz2.core.invoke
import dev.fritz2.core.storeOf
import dev.fritz2.core.viewBox
import dev.fritz2.routing.MapRouter
import generated.ServerConfig
import koin.koinCtx
import kotlin.time.Duration.Companion.milliseconds
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.launch
import localization.TL
import localization.Translation
import localization.showMissingTranslations
import login.pageForgotPassword
import login.pageLogin
import login.pageLoginWithEmail
import login.pageResetPasswordLinkSend
import login.pageResetPasswort
import mainmenu.about.pageAbout
import mainmenu.about.pageAcceptCookies
import mainmenu.about.pageAppPaused
import mainmenu.about.pageDisclaimer
import mainmenu.about.pageFeedback
import mainmenu.about.pageHelp
import mainmenu.about.pageTermsAndConditions
import model.AppPhase
import model.NotificationType
import model.Overlay
import model.ScanPurpose
import model.SplashType
import model.isInternalAdminGroupAdminOrGroupOwner
import notifications.pageNotificationCenter
import overlays.AlertOverlayStore
import overlays.SplashOverlayStore
import overlays.dotSpinner
import overlays.handlerScope
import routing.MainController
import search.global.pageSearch
import search.hub.pageHub
import signup.pageActivate
import signup.pageCreateWS
import signup.pageSignUp
import signup.pageSignUpFromInvite
import theme.FormationColors
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.doIfUserFeatureFlagEnabled
import twcomponents.twSimpleInputField
import webcomponents.KeywordTagType
import webcomponents.cardTitle
import webcomponents.expandCard
import webcomponents.fullCard
import webcomponents.fullPage
import webcomponents.fullPageConfirmationContainer
import webcomponents.fullWidthCenterContainer
import webcomponents.halfCard
import workspacetools.workplaceTools

fun RenderContext.loginStatePage() {
    val appStateStore by koinCtx.inject<AppStateStore>()
    val apiUserStore by koinCtx.inject<ApiUserStore>()
    val translation by koinCtx.inject<Translation>()

    // modal dialog closes by itself once login checks complete
    fullPageConfirmationContainer(
        width = { maxContent },
    ) {
        appStateStore.data.render { appState ->
            val isLoading = appState.appPhase == AppPhase.Checking
            stackUp(
                {
                    alignItems { center }
                },
            ) {
                spacing { larger }
                items {
                    cardTitle(
                        if (isLoading) translation[TL.Login.LOADING] else {
                            when (appState.appPhase) {
                                AppPhase.LoggedIn, AppPhase.LoggedInAnonymous -> translation[TL.Login.WELCOME, mapOf("name" to apiUserStore.current.firstName)]
//                                AppPhase.NotLoggedIn -> flowOf("Goodbye.")
                                else -> flowOf("Loading..")
                            }
                        },
                    )
                    if (isLoading) {
                        dotSpinner()
                    } else {
                        icon(
                            {
                                size { giant }
                                color { FormationColors.GreenActive.color }
                            },
                        ) { fromTheme { FormationUIIcons.Check.icon } }
                    }
                }
            }
        }
    }
}

class LoginModalStore : RootStore<Boolean>(
    initialData = false,
    job = Job(),
) {

    fun startLoginModal() {
        update(true)
        if (!current) {
            val spinnerHandler = modal(
                {
                    height { full }
                },
            ) {
                width { full }
                hasCloseButton(false)
                content { close ->
                    handlerScope.launch {
                        while (current) {
                            delay(20)
                        }
                        delay(500)
                        close.invoke()
                    }
                    loginStatePage()
                }
            }
            spinnerHandler.invoke()
        }
    }

    fun stopLoginModal() {
        update(false)
    }

}

fun RenderContext.pagesNavigator() {
    val router by koinCtx.inject<MapRouter>()
    val mainController: MainController by koinCtx.inject()
    val alertOverlayStore: AlertOverlayStore by koinCtx.inject()
    val splashOverlayStore: SplashOverlayStore by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val busyStore = LoginModalStore()
    val appStateStore by koinCtx.inject<AppStateStore>()
    val featureFlagStore by koinCtx.inject<FeatureFlagStore>()

    div(
        {
            zIndex { "1030" }
            maxWidth { full }
        },
        prefix = "pagesNavigator",
    ) {
        combine(routerStore.data, appStateStore.data, featureFlagStore.data) { r, a, f -> Triple(a, r, f) }
            .render { (appState, route, features) ->

                if (appState.appPhase == AppPhase.Checking) {
                    busyStore.startLoginModal()
                } else {
                    busyStore.stopLoginModal()
                    when (appState.appPhase) {
                        AppPhase.NotAcceptedCookies -> {
                            when (parseEnumValue<Pages>(route["page"])) {
                                Pages.AppPaused -> {
                                    fullPage {
                                        fullWidthCenterContainer {
                                            pageAppPaused()
                                        }
                                    }
                                }

                                else -> {
                                    fullPage {
                                        fullWidthCenterContainer {
                                            pageAcceptCookies(routerStore.preLoginRedirectRoute)
                                        }
                                    }
                                }
                            }
                        }

                        AppPhase.NotAcceptedDisclaimer -> {
                            fullPage {
                                fullWidthCenterContainer(prefix = "disclaimer", width = { "750px" }) {
                                    pageDisclaimer()
                                }
                            }
                        }

                        AppPhase.NotLoggedIn -> {
                            // Page routing for pre-login pages: Login / SignUp / CreateAccount
                            notLoggedInRouting(route, router)
                        }

                        AppPhase.NoValidToken -> {
                            alertOverlayStore.show(
                                Overlay.NotificationToast(
                                    notificationType = NotificationType.Alert,
                                    durationSeconds = 4,
                                    text = flowOf("SESSION EXPIRED. LOGGING OUT..."),
                                    bgColor = FormationColors.RedError.color,
                                ),
                            )
                            splashOverlayStore.show(
                                Overlay.SplashScreen(
                                    splashType = SplashType.TokenSplash,
                                    durationSeconds = 4,
                                ),
                            )
                            mainController.logout()
                        }

                        AppPhase.LoggedInAnonymous -> {
                            // page routing for all pages accessible for anonymous users
                            loggedInRouting(route, router, features, notLoggedInAnonymous = false)
                        }

                        AppPhase.NotActivated -> {
                            fullPage {
                                fullWidthCenterContainer(prefix = "activate", width = { "550px" }) {
                                    pageActivate()
                                }
                            }
                        }

                        AppPhase.NotAcceptedTerms -> {
                            fullPage {
                                fullWidthCenterContainer(prefix = "terms-content", width = { "750px" }) {
                                    pageTermsAndConditions()
                                }
                            }
                        }

                        AppPhase.NewPasswordNeeded -> {
                            newPasswordRouting(route)
                        }

                        AppPhase.LoggedIn -> {
                            loggedInRouting(route, router, features, notLoggedInAnonymous = true)
                        }

                        else -> {}
                    }
                }
            }
    }
}

private fun RenderContext.notLoggedInRouting(
    route: Map<String, String>,
    router: MapRouter
) {
    when (parseEnumValue<Pages>(route["page"])) {

        Pages.ResetPassword -> {
            fullPage {
                fullWidthCenterContainer {
                    pageResetPasswort()
                }
            }
        }

        Pages.CreateAccount -> {
            fullPage {
                fullWidthCenterContainer {
                    pageSignUpFromInvite()
                }
            }
        }

        Pages.CreateWorkspace -> {
            fullPage {
                fullWidthCenterContainer {
                    pageCreateWS()
                }
            }
        }

        Pages.SignUp -> {
            fullPage {
                fullWidthCenterContainer {
                    pageSignUp()
                }
            }
        }

        Pages.PublicProfile -> {
            fullPage {
                fullWidthCenterContainer {
                    pagePublicUserProfile()
                }
            }
        }

        // Pages.Login -> New login with email only
        Pages.LoginWithEmail -> {
            fullPage {
                pageLoginWithEmail()
            }
        }

        else -> { // Fallback normal login with email and password
            val ws = route["ws"] ?: route["workspace"]
            val email = route["email"]

            fullPage {
                fullWidthCenterContainer(prefix = "login-content") {
                    router.select("show").render { (page, _) ->
                        when (page) {
                            "forgotPassword" -> {
                                pageForgotPassword(ws, email)
                            }

                            "resetPasswordLinkSend" -> {
                                pageResetPasswordLinkSend()
                            }

                            else -> {
                                pageLogin(ws, email)
                            }
                        }
                    }
                }
            }
        }
    }
}

private fun RenderContext.newPasswordRouting(
    route: Map<String, String>
) {
    when (parseEnumValue<Pages>(route["page"])) {
        Pages.MyProfile -> {
            fullPage {
                pageMyProfile()
            }
        }

        else -> {
            fullPage {
                fullWidthCenterContainer {
                    pageNewPassword()
                }
            }
        }
    }
}

private fun RenderContext.loggedInRouting(
    route: Map<String, String>,
    router: MapRouter,
    features: Map<Features, Boolean>,
    notLoggedInAnonymous: Boolean
) {
    val routerStore: RouterStore by koinCtx.inject()
    val apiUserStore by koinCtx.inject<ApiUserStore>()
    val canSeeDashboard = apiUserStore.current.isInternalAdminGroupAdminOrGroupOwner()
    val page = parseEnumValue<Pages>(route["page"])
    when {
        ServerConfig.developmentMode && page == Pages.Debug -> {
            debugScreen()
        }

        page == Pages.Map -> {}

        page == Pages.Help -> {
            fullPage {
                fullWidthCenterContainer(prefix = "page-help", width = { "750px" }) {
                    pageHelp()
                }
            }
        }

        page == Pages.AdminDashboard && notLoggedInAnonymous && canSeeDashboard -> {
            workplaceTools()
        }

        page == Pages.ViewTerms && notLoggedInAnonymous -> {
            fullPage {
                fullWidthCenterContainer(prefix = "terms-content", width = { "750px" }) {
                    pageTermsAndConditions()
                }
            }
        }

        page == Pages.Hub -> {
            doIfUserFeatureFlagEnabled(UserFeatureFlag.EnableDevelopmentFeatures) {
                expandCard(openExpended = true, prefix = "hub-page") {
                    fullWidthCenterContainer(prefix = "hub-content") {
                        pageHub()
                    }
                }
            }
        }

        page == Pages.Search -> {
            expandCard(openExpended = true, prefix = "search-page") {
                fullWidthCenterContainer(prefix = "search-content") {
                    router.select("change").render { (key, _) ->
                        when (key) {
                            "tags" -> cardManageKeywordTags(type = KeywordTagType.SearchTag)
                            "fieldValue" -> cardManageFieldValueTag(tagType = KeywordTagType.SearchTag)
                            else -> pageSearch()
                        }
                    }
                }
            }
        }

        page == Pages.Scan -> {
//            fullCard(prefix = "scan-page") {
//                cardBrowserQRScanCamera()
////                zxingScanScreen() // TODO Change to use zxing or other library, when ready
//            }
            val toggleCamera = storeOf(true, Job())
            cameraModal(toggleCamera, listOf(routerStore.back)) { close, opened, video, _ ->
                qrScanCamera(close, opened, video, ScanPurpose.OpenCode)
            }
        }

        page == Pages.NotificationCenter && notLoggedInAnonymous -> {
            expandCard(openExpended = true, prefix = "notification-center-page") {
                fullWidthCenterContainer(prefix = "search-content") {
                    pageNotificationCenter()
                }
            }
        }

        page == Pages.MyProfile && notLoggedInAnonymous -> {
            if (features[Features.NewPasswordNeeded] == true) {
                fullPage {
                    pageMyProfile()
                }
            } else {
                fullCard(prefix = "user-profile") { pageMyProfile() }
            }
        }

        page == Pages.UserProfile -> {
            fullCard(prefix = "other-user-profile") { pageOtherUserProfile() }
        }

        page == Pages.UserVerificationResult -> {
            fullCard(prefix = "user-verification-result") { pageUserVerificationResult() }
        }

//        page == Pages.Debug && notAnonymous -> {
//            expandCard(prefix = "debug", openExpended = true) {
//                fullWidthCenterContainer(prefix = "debug-content") {
//                    pageDebug()
//                }
//            }
//        }

        page == Pages.UserSettings -> {
            expandCard(prefix = "user-settings", openExpended = true) {
                fullWidthCenterContainer(prefix = "user-settings-content") {
                    pageUserSettings()
                }
            }
        }

        page == Pages.Feedback -> {
            expandCard(prefix = "feedback") {
                fullWidthCenterContainer(prefix = "feedback-content") {
                    pageFeedback()
                }
            }
        }

        page == Pages.About -> {
            fullCard(prefix = "about") {
                fullWidthCenterContainer(prefix = "about-content") {
                    pageAbout()
                }
            }
        }

        page == Pages.Menu -> {
            fullCard(prefix = "mainmenu") {
                fullWidthCenterContainer(prefix = "menu-content") {
                    pageMainMenu()
                }
            }
        }

        page == Pages.OnBoarding -> {
            fullCard {
                fullWidthCenterContainer(prefix = "onBoarding-content") {
                    pageOnBoarding()
                }
            }
        }

        page == Pages.AnalyticsDashboard && notLoggedInAnonymous && canSeeDashboard -> {
            pageAnalyticsDashboard()
        }

        page == Pages.SignOut && notLoggedInAnonymous -> {
            halfCard { pageSignOut() }
        }

        else -> {
            routerStore.addOrReplaceRoute(Pages.Map.route)
        }
    }
//    if(route["card"].isNullOrBlank()
//        && route["add"].isNullOrBlank()
//        && route["show"].isNullOrBlank()
//        && route["edit"].isNullOrBlank()
//        && route["id"].isNullOrBlank()
//        && route["userId"].isNullOrBlank()
//        && route["change"].isNullOrBlank()
//        && route["mapLayer"].isNullOrBlank()
//        || route.isEmpty()
//    ) {
//        bottomBar()
//    }
}

private fun RenderContext.debugScreen() {
    div("scroll-y-auto flex flex-col space-3") {
        a {
            +"Back to map"
            href("#page=Map")
        }
        h1 {
            +"Config"
        }
        pre {
            +"""
                        ssl: ${ServerConfig.ssl}
                        host: ${ServerConfig.host}
                        port: ${ServerConfig.port}
                        devMode: ${ServerConfig.developmentMode}
                        apiVersion: ${ServerConfig.apiVersion}
                        buildTime: ${ServerConfig.buildTimestamp}
                    """.trimIndent()
        }

        h1 {
            +"Gathered missing translations so far"
        }
        showMissingTranslations()

        h1 {
            +"Icon Browser"
        }
        val store = storeOf("")

        twSimpleInputField(store)

        store.data.debounce(10.milliseconds).render { filter ->
            h2 {
                +"FormationUIIcons"
            }
            FormationUIIcons.entries
                .filter { if (filter.isBlank()) true else it.name.lowercase().contains(filter.lowercase()) }
                .sortedBy { it.name }.forEach { ico ->
                    div("flex flex-row fill-black h-10 justify-between") {
                        div {
                            +ico.name
                        }
                        svg {
                            viewBox(ico.icon.iconDefViewBox)
                            this.content(ico.icon.iconDefSvg)
                        }
                    }
                }

            h2 {
                +"FormationIcons"
            }
            FormationIcons.entries
                .filter { if (filter.isBlank()) true else it.name.lowercase().contains(filter.lowercase()) }
                .sortedBy { it.name }.forEach { ico ->
                    div("flex flex-row fill-black h-10 justify-between") {
                        div {
                            +ico.name
                        }
                        svg {
                            viewBox(ico.icon.iconDefViewBox)
                            this.content(ico.icon.iconDefSvg)
                        }
                    }
                }
        }
    }
}
