package mainmenu

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import auth.FeatureFlagStore
import auth.Features
import auth.openAnotherDevicePopup
import com.tryformation.localization.Translatable
import data.users.profile.MyProfileStore
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.invoke
import dev.fritz2.core.src
import dev.fritz2.styling.params.ColorProperty
import dev.fritz2.styling.theme.Colors
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Icons
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.Translation
import map.Cards
import map.bottombar.inlinedBottomBar
import model.MyProfile
import model.fullName
import model.isInternalAdminGroupAdminOrGroupOwner
import model.profilePhoto
import notifications.GlobalNotificationResultsStore
import org.w3c.dom.HTMLDivElement
import routing.DestinationRoute
import routing.MainController
import routing.stringify
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import twcomponents.renderIfFeatureFlagEnabled
import twcomponents.twColOfCenter
import twcomponents.twGhostButton
import twcomponents.twPageHeaderClose
import webcomponents.baseLayout
import webcomponents.cardTitle
import webcomponents.ellipseText
import webcomponents.selectorButton
import webcomponents.selectorContent
import webcomponents.twContentScrollBox
import webcomponents.userOrStateIcon
import wizard.onbaording.onBoardingWizard
import wizard.onbaording.wizardModal

fun RenderContext.pageMainMenu() {
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val myProfileStore: MyProfileStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val mainController: MainController by koinCtx.inject()
    val globalNotificationResultsStore: GlobalNotificationResultsStore by koinCtx.inject()
    val featureFlagStore by koinCtx.inject<FeatureFlagStore>()
    val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()

    featureFlagStore.setGroupAndRoleFlags()
    myProfileStore.fetchMyProfile()

    combine(apiUserStore.data, featureFlagStore.data) { a, f -> Pair(a.apiUser, f) }.render { (apiUser, features) ->

        val isNotAnonymous = apiUser?.isAnonymous == false
        val signUp = features[Features.EnableSignUpButton] == true
        val sharing = features[Features.DisableLocationSharing] == false
        val showQRTrackerDemo = features[Features.EnableTrackingOnboarding] == true

        baseLayout(
            header = {
                twPageHeaderClose {
                    twGhostButton(
                        text = TL.CardMyUser.SETTINGS,
                        icon = FormationIcons.Gear,
                    ) {
                        clicks handledBy {
                            routerStore.addOrReplaceRoute(Pages.UserSettings.route)
                        }
                    }
                }
            },
            content = {
                twContentScrollBox {

                    div("flex flex-grow") {}

                    twColOfCenter {
                        className("relative my-4")
                        // Logo
                        img("flex flex-grow min-h-20 max-h-36 my-4 mx-4 object-contain") {
                            val ws = apiUserStore.current.apiUser?.workspaceName
                            when {
                                ws?.contains("hoffest") == true -> src("assets/images/LOGO_Formation+Hoffest.png")
                                else -> src("assets/images/logo-blue_1.svg")
                            }
                        }
                        cardTitle(currentWorkspaceStore.data.mapNotNull { it?.name })
                    }

                    div("flex flex-grow") {}

                    stackUp(
                        {
                            width { full }
                            height { maxContent }
                            justifyContent { center }
                            alignItems { stretch }
                            margins { bottom { larger } }
                        },
                    ) {
                        spacing { small }
                        items {
                            if (isNotAnonymous) {
                                // YOUR PROFILE
                                myProfileStore.map(MyProfile.profilePhoto()).data.render { profilePhoto ->
                                    mainMenuButton(
                                        profilePicture = profilePhoto?.href,
                                        icon = { FormationIcons.UserAlt.icon },
                                        subtitle = translation[TL.MainMenu.PROFILE_SUBTITLE],
                                        titleLiteral = myProfileStore.map(MyProfile.fullName()).data.map { name ->
                                            translation.getString(
                                                TL.MainMenu.PROFILE,
                                                mapOf("name" to name),
                                            )
                                        },
                                        destinations = listOf(Pages.MyProfile),
                                    )
                                }
                            }
                            // HELP PAGE
                            if (apiUserStore.current.apiUser?.groups?.map { it.groupName }
                                    ?.contains("formation4ukraine") == true) {
                                mainMenuButton(
                                    icon = { circleHelp },
                                    title = TL.MainMenu.HELP,
                                    subtitle = translation[TL.MainMenu.HELP_SUBTITLE],
                                    destinations = listOf(Pages.Help),
                                )
                            }

                            // DEBUGGING
//                            mainMenuButton(
//                                icon = { FormationIcons.FireExtinguisher.icon },
//                                titleLiteral = flowOf("Debugging"),
//                                subtitle = flowOf("Debug here"),
//                                destinations = listOf(Pages.Debug),
//                            )

                            if (isNotAnonymous) {
                                // ONBOARDING WIZARD
                                if (showQRTrackerDemo) {
                                    button {
                                        mainMenuButton(
                                            icon = { FormationIcons.QuickTour.icon },
                                            iconBackgroudColor = { FormationColors.MarkerYou.color },
                                            subtitle = translation[TL.MainMenu.QUICK_TOUR_SUBTITLE],
                                            title = TL.MainMenu.QUICK_TOUR,
                                            destinations = listOf(),
                                        )
                                        clicks handledBy wizardModal { closeHandler ->
                                            onBoardingWizard(closeHandler)
                                        }
                                    }
                                }
                                if (sharing) {
                                    // LOCATION SHARING
                                    mainMenuButton(
                                        icon = { FormationIcons.SharingOn.icon },
                                        title = TL.MainMenu.LOCATION_SHARING,
                                        subtitle = translation[TL.MainMenu.LOCATION_SHARING_SUBTITLE],
                                        destinations = listOf(Pages.Map, Cards.Sharing),
                                    )
                                }
                                // ADMIN DASHBOARD
                                if (apiUser?.isInternalAdminGroupAdminOrGroupOwner() == true) {
                                    mainMenuButton(
                                        icon = { FormationIcons.UserGroup.icon },
                                        title = TL.MainMenu.WORKPLACE_TOOLS,
                                        subtitle = translation[TL.MainMenu.WORKPLACE_TOOLS_SUBTITLE],
                                        destinations = listOf(Pages.AdminDashboard),
                                    )
                                    mainMenuButton(
                                        icon = { FormationIcons.DisplayGraph.icon },
                                        title = TL.MainMenu.INSIGHTS_SUITE,
                                        subtitle = translation[TL.MainMenu.INSIGHTS_SUITE_SUBTITLE],
                                        destinations = listOf(Pages.AnalyticsDashboard),
                                    )
                                }
                                // NOTIFICATION CENTER
                                mainMenuButton(
                                    icon = { FormationIcons.NotificationsOn.icon },
                                    titleLiteral = translation[TL.MainMenu.NOTIFICATION_CENTER].combine(
                                        globalNotificationResultsStore.data.map { it.unreadNumber },
                                    ) { title, unreadNumber ->
                                        "$title${if (unreadNumber > 0) " (${unreadNumber})" else ""}"
                                    },
                                    subtitle = translation[TL.MainMenu.NOTIFICATION_CENTER_SUBTITLE],
                                    destinations = listOf(Pages.NotificationCenter),
                                )
                            }

                            // ABOUT
                            div("mb-3") {
                                mainMenuButton(
                                    icon = { FormationIcons.Information.icon },
                                    title = TL.MainMenu.ABOUT,
                                    subtitle = translation[TL.MainMenu.ABOUT_SUBTITLE],
                                    destinations = listOf(Pages.About),
                                )
                            }

                            if (isNotAnonymous) {
                                renderIfFeatureFlagEnabled(Features.AllowExperimentalFeatures) {
                                    mainMenuButton(
                                        icon = { FormationIcons.QRCode.icon },
                                        title = TL.MainMenu.LoginWithDifferentDevice,
                                        subtitle = translation[TL.MainMenu.LoginWithDifferentDeviceSubtitle],
                                    )
                                    clicks handledBy openAnotherDevicePopup()
                                }
                                // LOGOUT
                                mainMenuButton(
                                    icon = { FormationIcons.Logout.icon },
                                    title = TL.MainMenu.LOGOUT,
                                    subtitle = translation[TL.MainMenu.LOGOUT_SUBTITLE],
                                    destinations = listOf(Pages.SignOut),
                                )
                            } else {
                                // LOGIN
                                mainMenuButton(
                                    icon = { FormationIcons.Login.icon },
                                    title = TL.MainMenu.LOGIN,
                                    subtitle = translation[TL.MainMenu.LOGIN_SUBTITLE],
                                    clickHandlers = listOf(mainController.logoutWithParams),
                                    destinations = listOf(Pages.Login),
                                )
                                // In-App LOGIN
//                                val loginToggle = ToggleStore(false)
//                                inAppLoginPopup(loginToggle)
//
//                                mainMenuButton(
//                                    icon = { FormationIcons.Login.icon },
//                                    titleLiteral = flowOf("in-app login"),
//                                    subtitle = translation[TL.MainMenu.LOGIN_SUBTITLE],
//                                    clickHandlers = listOf(
//                                        SimpleHandler { toggle, _ ->
//                                            toggle handledBy {
//                                                loginToggle.update(true)
//                                            }
//                                        },
//                                    ),
//                                )
                                if (signUp) {
                                    // SIGN UP
                                    mainMenuButton(
                                        icon = { editAlt },
                                        title = TL.MainMenu.SIGN_UP,
                                        subtitle = translation[TL.MainMenu.SIGN_UP_SUBTITLE],
                                        clickHandlers = listOf(mainController.goToSignUp),
                                        destinations = listOf(Pages.SignUp),
                                    )
                                }
                            }
                        }
                    }
                }
            },
            footerPaddings = null,
            footer = {
                inlinedBottomBar()
            },
        )
    }
}

fun RenderContext.mainMenuButton(
    icon: (Icons.() -> IconDefinition),
    iconBackgroudColor: Colors.() -> ColorProperty = { primary.main },
    profilePicture: String? = null,
    subtitle: Flow<String>,
    destinations: List<DestinationRoute>? = null,
    title: Translatable? = null,
    titleLiteral: Flow<String>? = null,
    clickHandlers: List<SimpleHandler<Unit>>? = null,
    addOrReplaceRoute: Map<String, String>? = null,
    relative: Boolean = false,
    extraContent: (HtmlTag<HTMLDivElement>.() -> Unit)? = null
) {
    val routerStore: RouterStore by koinCtx.inject()
    val analyticsService: AnalyticsService by koinCtx.inject()
    val translation: Translation by koinCtx.inject()

    selectorButton(
        radius = formationStyles.buttonRadius,
        margins = { },
        additionalStyle = if (relative) {
            { position { relative { } } }
        } else null,
    ) {
        selectorContent {
            lineUp(
                {
                    margin { tiny }
                    alignItems { center }
                    overflowX { hidden }
                },
            ) {
                spacing { small }
                items {
                    // USER ICON ON CIRCLE
                    if (profilePicture != null) {
                        userOrStateIcon(
                            pixelSize = 48.0,
                            shadow = false,
                            picture = profilePicture,
                            icon = { FormationIcons.UserAlt.icon },
                            iconBackground = iconBackgroudColor,
                        )
                    } else {
                        flexBox(
                            {
                                width { "48px" }
                                height { "48px" }
                                color { secondary.main }
                                background { color(iconBackgroudColor) }
                                radius { full }
                                padding { smaller }
                                flex {
                                    grow { "0" }
                                    shrink { "0" }
                                    basis { "48px" }
                                }
                                alignItems { center }
                                justifyContent { center }
                            },
                        ) {
                            icon({ size { larger } }) { fromTheme(icon) }
                        }
                    }
                    // TITLE & SUBTITLE
                    stackUp(
                        {
                            overflowX { hidden }
                        },
                    ) {
                        spacing { tiny }
                        items {
                            ellipseText(
                                styleParams = {
                                    width { full }
                                    fontSize { normal }
                                    fontWeight { bold }
                                    textAlign { left }
                                },
                            ) {
                                titleLiteral?.renderText(into = this) ?: translation[
                                    title
                                        ?: error("need a title here"),
                                ].renderText(into = this)
                            }
                            ellipseText(
                                styleParams = {
                                    width { full }
                                    fontSize { smaller }
                                    textAlign { left }
                                },
                            ) { subtitle.renderText(into = this) }
                        }
                    }
                }
            }
        }
        extraContent?.invoke(this)
        with(clicks) {
            clickHandlers?.forEach { handler ->
                this.map { } handledBy handler
            }

            destinations?.let { destinationsList ->
                this.map {
                    analyticsService.createEvent(AnalyticsCategory.MainMenu) {
                        click(
                            target = destinationsList.joinToString(",") { destination -> destination.stringify() },
                        )
                    }
                }

                this.map {
                    destinationsList.associate { it.routeKey to it.name }
                } handledBy routerStore.addOrReplaceRoute
            }
            addOrReplaceRoute?.let { route ->
                this.map { route } handledBy routerStore.addOrReplaceRoute
            }
        }

    }
}
