package search

import analyticsdashboard.PathActiveHighlightedObjectStore
import analyticsdashboard.PathSearchResultsStore
import apiclient.customfields.parseFieldValues
import apiclient.geoobjects.Content
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.MarkerColor
import apiclient.geoobjects.MarkerIcon
import apiclient.geoobjects.MarkerShape
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.TaskState
import apiclient.geoobjects.isFlagged
import apiclient.search.ObjectSearchResult
import apiclient.tags.getTagValues
import apiclient.tags.getUniqueTag
import apiclient.users.UserProfileSummary
import apiclient.validations.parseEnumValue
import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import auth.FeatureFlagStore
import auth.Features
import com.jillesvangurp.geo.GeoGeometry
import com.tryformation.localization.Translatable
import data.ObjectAndUserHandler
import data.objects.building.CurrentBuildingsStore
import data.objects.building.getBuilding
import data.objects.objecthistory.ObjectHistoryResultsCache
import data.objects.views.cardinfo.getColorForOccupancy
import data.objects.views.cardinfo.getCustomFieldMap
import data.users.UserListStore
import data.users.settings.zoneTypeColorMap
import data.users.settings.zoneTypeIconMap
import data.users.settings.zoneTypeShapeMap
import data.users.views.initials
import dev.fritz2.components.compat.Span
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.storeOf
import dev.fritz2.core.title
import dev.fritz2.styling.params.BasicParams
import dev.fritz2.styling.params.ColorProperty
import dev.fritz2.styling.params.FlexParams
import dev.fritz2.styling.params.SpacesContext
import dev.fritz2.styling.params.Style
import dev.fritz2.styling.theme.Colors
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Icons
import koin.koinCtx
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.Translation
import location.GeoPositionStore
import location.LocationUploadStore
import location.geolocation.GeoPosition
import location.geolocation.latLon
import map.MapStateStore
import model.KeywordTag
import notifications.GlobalNotificationResultsStore
import objectrouting.getLevelFromFloorId
import org.w3c.dom.HTMLDivElement
import search.global.ActiveSearchKeywordsStore
import search.global.SearchInputFieldStore
import svgmarker.MarkerSize
import svgmarker.SvgIconOptions
import svgmarker.areaMarkerSvgIconOptions
import svgmarker.buildingSvgIconOptions
import svgmarker.connectableShapeSvgIconOptions
import svgmarker.defaultIconBoxSizeSearch
import svgmarker.defaultMarkerSizeSearch
import svgmarker.eventSvgIconOptions
import svgmarker.myUserSize
import svgmarker.myUserSvgIconOptions
import svgmarker.objectMarkerSvgIconOptions
import svgmarker.pointSvgIconOptions
import svgmarker.renderSvgIcon
import svgmarker.taskSvgIconOptions
import svgmarker.userInitialsIconSizeSearch
import svgmarker.userSvgIconOptions
import svgmarker.zoneMarkerSvgIconOptions
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import theme.FormationUIIcons
import theme.IconEnum
import twcomponents.twIconSmall
import twcomponents.twRowOfJustifyBetween
import utils.cutString
import utils.extractReadOnlyTags
import utils.formatDateForSearch
import utils.formatTime
import utils.getColor
import utils.getColorForStatus
import utils.getDistanceString
import utils.getIcon
import utils.getName
import utils.getNotificationTagData
import utils.getTitle
import utils.isInFuture
import utils.isInPast
import utils.isToday
import utils.localDT
import utils.makeRGBA
import utils.mergeIfNotBlank
import utils.mergeTagLists
import utils.parseInstant
import utils.respectFeatureFlags
import utils.toKeyWordTagsList
import webcomponents.KeywordTagActionType
import webcomponents.KeywordTagType
import webcomponents.ellipseText
import webcomponents.overFlowContent
import webcomponents.tinyKeyWordTag
import webcomponents.userOrStateIcon
import websocket.MarkerClientStore

val filterButtonOnStyle: Style<BasicParams> = {
    width { full }
    height(formationStyles.inputHeight)
    color { secondary.main }
    background { color { primary.main } }
    radius(formationStyles.inputRadius)
    fontSize { small }
    fontWeight { bold }
    hover {
        background { color { primary.highlight } }
    }
}

val filterButtonOffStyle: Style<BasicParams> = {
    width { full }
    height(formationStyles.inputHeight)
    color { primary.main }
    background { color { secondary.main } }
    radius(formationStyles.inputRadius)
    fontSize { small }
    fontWeight { bold }
    hover {
        background { color { FormationColors.GrayLight.color } }
    }
}

val filterButtonDisabledStyle: Style<BasicParams> = {
    width { full }
    height(formationStyles.inputHeight)
    color { FormationColors.GrayDisabled.color }
    background { color { secondary.main } }
    radius(formationStyles.inputRadius)
    fontSize { small }
    fontWeight { bold }
}

val distanceFilterButtonOnStyle: Style<BasicParams> = {
    width { auto }
    height { maxContent }
    fontSize { tiny }
    fontWeight { bold }
    paddings {
        vertical { tiny }
        horizontal { smaller }
    }
    color { secondary.main }
    background { color { primary.main } }
    radius(formationStyles.inputRadius)
    hover {
        background { color { primary.highlight } }
    }
}

val distanceFilterButtonOffStyle: Style<BasicParams> = {
    width { auto }
    height { maxContent }
    fontSize { tiny }
    fontWeight { bold }
    paddings {
        vertical { tiny }
        horizontal { smaller }
    }
    color { primary.main }
    background { color { secondary.main } }
    radius(formationStyles.inputRadius)
    hover {
        background { color { FormationColors.GrayLight.color } }
    }
}

fun RenderContext.searchResultsList(results: Flow<List<ObjectSearchResult>>) {

    val translation: Translation by koinCtx.inject()
    val markerClientStore: MarkerClientStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val locationUploadStore: LocationUploadStore by koinCtx.inject()
    val searchInputFieldStore: SearchInputFieldStore by koinCtx.inject()
    val activeSearchKeywordsStore: ActiveSearchKeywordsStore by koinCtx.inject()

    results.render { resultsList ->
        if (resultsList.isNotEmpty()) {
            stackUp(
                {
                    width { full }
                    alignItems { center }
                    margins { bottom { larger } }
                },
                id = "list-entry-stack-up",
            ) {
                spacing { small }
                items {
                    if (resultsList.all { it.hit.objectType == ObjectType.UserMarker }) {
                        resultsList.sortedByDescending {
                            markerClientStore.isUserSharing(it.hit.ownerId)
                                || (it.hit.ownerId == apiUserStore.current.userId && locationUploadStore.current.isActive)
                        }.forEach { result ->
                            searchResultsListEntry(
                                result.hit,
                                clickHandlers = listOf(
                                    searchInputFieldStore.reset,
                                    activeSearchKeywordsStore.reset,
                                ),
                                keywordTagType = KeywordTagType.SearchTag,
                            )
                        }
                    } else {
                        resultsList.forEach { result ->
                            searchResultsListEntry(
                                result.hit,
                                clickHandlers = listOf(
                                    searchInputFieldStore.reset,
                                    activeSearchKeywordsStore.reset,
                                ),
                                keywordTagType = KeywordTagType.SearchTag,
                            )
                        }
                    }
                }
            }
        } else {
            flexBox(
                {
                    width { full }
                    height { full }
                    alignItems { center }
                    justifyContent { center }
                },
            ) {
                span { translation[TL.Search.NO_RESULTS].renderText(into = this) }
            }
        }
    }
}

fun RenderContext.pathToolSearchResultsList(results: Flow<List<ObjectSearchResult>>, selectedIds: Flow<List<String>>) {

    val translation: Translation by koinCtx.inject()
    val markerClientStore: MarkerClientStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val locationUploadStore: LocationUploadStore by koinCtx.inject()
    val pathSearchResultsStore: PathSearchResultsStore by koinCtx.inject()
    val objectHistoryResultsCache: ObjectHistoryResultsCache by koinCtx.inject()
    val pathActiveHighlightedObjectStore: PathActiveHighlightedObjectStore by koinCtx.inject()

    results.render { resultsList ->
        if (resultsList.isNotEmpty()) {
            stackUp(
                {
                    width { full }
                    alignItems { center }
                    margins { bottom { larger } }
                },
                id = "list-entry-stack-up",
            ) {
                spacing { small }
                items {
                    if (resultsList.all { it.hit.objectType == ObjectType.UserMarker }) {
                        resultsList.sortedByDescending {
                            markerClientStore.isUserSharing(it.hit.ownerId)
                                || (it.hit.ownerId == apiUserStore.current.userId && locationUploadStore.current.isActive)
                        }.forEach { result ->
                            selectableHistoryPathSearchResultsListEntry(
                                result.hit,
                                selected = selectedIds.map { result.hit.id in it },
                                highlighted = pathActiveHighlightedObjectStore.data.mapNotNull { result.hit.id == it },
                                selectHandlers = listOf(objectHistoryResultsCache.getTrackedObjectHistory),
                            )
                        }
                    } else {
                        resultsList.forEach { result ->
                            selectableHistoryPathSearchResultsListEntry(
                                result.hit,
                                selected = selectedIds.map { result.hit.id in it },
                                highlighted = pathActiveHighlightedObjectStore.data.mapNotNull { result.hit.id == it },
                                selectHandlers = listOf(objectHistoryResultsCache.getTrackedObjectHistory),
                                clickHandlers = listOf(pathSearchResultsStore.centerToObject),
                            )
                        }
                    }
                }
            }
        } else {
            flexBox(
                {
                    width { full }
                    height { full }
                    alignItems { center }
                    justifyContent { center }
                },
            ) {
                span { translation[TL.Search.NO_RESULTS].renderText(into = this) }
            }
        }
    }
}


fun RenderContext.separationLine(
    additionalClasses: String? = null,
    title: Flow<String>? = null,
) {
    twRowOfJustifyBetween("w-full") {
        additionalClasses?.let { className(additionalClasses) }
        div("flex flex-grow flex-shrink basis-[10px] h-px bg-gray-300") { }
        title?.let {
            p("font-semibold text-gray-300 px-2") {
                title.renderText(into = this)
            }
            div("flex flex-grow flex-shrink basis-[10px] h-px bg-gray-300") { }
        }
    }
}

fun RenderContext.selectableHistoryPathSearchResultsListEntry(
    geoObject: GeoObjectDetails,
    selected: Flow<Boolean>,
    highlighted: Flow<Boolean> = flowOf(false),
    clickHandlers: List<SimpleHandler<GeoObjectDetails>>? = null,
//    selectHandlers: List<SimpleHandler<GeoObjectDetails>>? = null,
    selectHandlers: List<SimpleHandler<String>>? = null,
    icon: Icons.() -> IconDefinition = { FormationUIIcons.Check.icon },
    iconBgColor: Colors.() -> ColorProperty = { FormationColors.BlueDeep.color }
) {

    button(
        {
            width { full }
            height { maxContent }
            overflow { visible }
        },
        id = "${geoObject.id}-selectable-list-entry-button",
    ) {

        flexBox(
            {
                width { full }
                direction { row }
                alignItems { center }
                justifyContent { spaceBetween }
            },
        ) {
            generalListEntry(
                geoObject = geoObject,
                keywordTagType = KeywordTagType.AnalyticsPathViewTag,
                selectable = true,
                selected = selected,
                highlighted = highlighted,
                selectIcon = icon,
                selectColor = iconBgColor,
                selectClickHandlers = selectHandlers,
            )
        }
        with(clicks) {
            clickHandlers?.forEach { handler ->
                this.map { geoObject } handledBy handler
            }
        }
    }
}

fun RenderContext.searchResultsListEntry(
    geoObject: GeoObjectDetails,
    clickHandlers: List<SimpleHandler<Unit>>? = null,
    keywordTagType: KeywordTagType
) {
    val objectAndUserHandler: ObjectAndUserHandler by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val markerClientStore: MarkerClientStore by koinCtx.inject()
    val locationUploadStore: LocationUploadStore by koinCtx.inject() // required if currentUserMarker will be displayed in search

    button(
        {
            width { full }
            height { maxContent }
            overflow { visible }
        },
        id = "list-entry-button",
    ) {

        generalListEntry(geoObject, keywordTagType = keywordTagType)

        val isNotOfflineUser = when (geoObject.objectType) {
            ObjectType.UserMarker -> {
                markerClientStore.isUserSharing(geoObject.ownerId)
                    || (geoObject.ownerId == apiUserStore.current.userId && locationUploadStore.current.isActive)
            }

            else -> true
        }
        with(clicks) {
            if (isNotOfflineUser) {
                this.map { geoObject } handledBy objectAndUserHandler.updateAndLocateObjectOrUserFromList
            } else {
                this.map { geoObject.ownerId } handledBy objectAndUserHandler.updateAndLocateActiveUserById
            }
            clickHandlers?.forEach { handler ->
                this handledBy handler
            }
        }
    }
}


fun RenderContext.listEntryBox(
    additionalStyle: Style<FlexParams>? = null,
    withBorder: Boolean = true,
    paddings: SpacesContext.() -> Unit = { vertical { tiny }; left { tiny }; right { small }; },
    hoverEffect: Boolean? = true,
    content: (HtmlTag<HTMLDivElement>.() -> Unit)? = null
) {
    div(
        {
            //height { "60px" }
            height { maxContent }
            width { full }
            justifyContent { start }
            alignItems { center }
            display { flex }
            paddings(paddings)
            radius(formationStyles.buttonRadius)
//        css ("""
//            -moz-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
//            -webkit-appearance: none !important;
//            -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.25) !important;
//            box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
//            """.trimIndent()
//        )
            if (hoverEffect == true) {
                hover {
                    background { color { FormationColors.GrayLight.color } }
//            css ("""
//            -moz-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
//            -webkit-appearance: none !important;
//            -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.25) !important;
//            box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
//            """.trimIndent()
//            )
                    if (withBorder) border {
                        color { primary.main }
                    }
                }
            }
            background { color { secondary.main } }
            // fine border for better looks on safari?
            if (withBorder) border {
                color { FormationColors.GrayDisabled.color } // FIXME adjust (remove?) border color when shadows are back and fixed
                width(formationStyles.borderWidth)
            }
            additionalStyle?.let { it() }
        },
        id = "list-entry-box",
    ) {

        content?.invoke(this)

    }
}

fun RenderContext.iconBox(
    obj: GeoObjectDetails,
    flagged: Boolean = false,
    showStateIndicator: Boolean = false
) {
    val userListStore by koinCtx.inject<UserListStore>()
    val objectType: ObjectType?

    when (obj.objectType) {
        ObjectType.Notification -> {
            val connectedObjectData = obj.getNotificationTagData()
            objectType = connectedObjectData.COType
        }

        else -> {
            objectType = obj.objectType

        }
    }

    flexBox(
        {
            flex {
                grow { "0" }
                shrink { "0" }
                basis { "${defaultIconBoxSizeSearch}px" }
            }
            width { "${defaultIconBoxSizeSearch}px" }
            height { "${defaultIconBoxSizeSearch}px" }
            margins { right { smaller } }
            justifyContent { center }
            alignItems { center }
        },
        id = "icon-box",
    ) {
        when (objectType) {
            ObjectType.UserMarker -> {
                // USER ICON ON CIRCLE
                val picture = userListStore.current?.firstOrNull { obj.ownerId == it.userId }?.profilePhoto
                userOrStateIcon(
                    pixelSize = userInitialsIconSizeSearch,
                    shadow = true,
                    margins = {
                        vertical { "${((defaultIconBoxSizeSearch - userInitialsIconSizeSearch) / 2).toInt()}px" }
                        horizontal { "${((defaultIconBoxSizeSearch - userInitialsIconSizeSearch) / 2).toInt()}px" }
                    },
                    picture = (picture?.thumbNail as? Content.Image)?.href ?: picture?.href,
                    initials = initials(obj.owner?.firstName, obj.owner?.lastName),
                )
            }

            else -> getSvgIconOptions(
                obj = obj,
                flagged = flagged,
                showStateIndicator = showStateIndicator,
            )?.let { renderSvgIcon(it) }
        }
    }
}

fun getSvgIconOptions(
    obj: GeoObjectDetails,
    flagged: Boolean = false,
    showStateIndicator: Boolean = false
): SvgIconOptions? {

    val apiUserStore: ApiUserStore by koinCtx.inject()
    val markerClientStore: MarkerClientStore by koinCtx.inject()
    val userListStore: UserListStore by koinCtx.inject()
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()
    val locationUploadStore: LocationUploadStore by koinCtx.inject()
    val currentUserId = apiUserStore.current.userId

    // map notification objects to connected objects to display the marker of the connectedObject
    val globalNotificationResultsStore: GlobalNotificationResultsStore by koinCtx.inject()
    val hasUnreadNotifications = globalNotificationResultsStore.isOrHasUnreadNotification(obj)
    val color: MarkerColor?
    val icon: MarkerIcon?
    val shape: MarkerShape?
    val objectType: ObjectType?
    val stateColor: FormationColors?
    val stateIcon: IconEnum?
    val bitmapImage = if (featureFlagStore.current[Features.AllowBitmapMarkerIcons] == true) {
        (obj.attachments?.firstOrNull { it is Content.Icon } as? Content.Icon)?.href
    } else null

    val userSharingState = markerClientStore.isUserSharing(obj.ownerId)
    val userPicture = userListStore.getPublicUserProfile(obj.ownerId)?.profilePhoto?.href
//    val profilePicture = myProfileStore.current.profilePhoto?.href
    val myUserSharingState = locationUploadStore.current.isActive

    when (obj.objectType) {
        ObjectType.Notification -> {
            val connectedObjectData = obj.getNotificationTagData()
            objectType = connectedObjectData.COType
            color = connectedObjectData.COColor
            icon = connectedObjectData.COIconCategory
            shape = connectedObjectData.COShape
            stateColor = if (showStateIndicator) when (connectedObjectData.COType) {
                ObjectType.Task -> connectedObjectData.taskStatus?.getColor()
                else -> null
            } else null
            stateIcon = null
        }

        ObjectType.Zone -> {
            val customFields = getCustomFieldMap(obj.tags.getTagValues(ObjectTags.CustomField))
            val capacity = customFields["Capacity"]?.fieldValue?.toIntOrNull() ?: 0
            val occupancy =
                obj.tags.getTagValues(ObjectTags.ChildCount).getUniqueTag(ObjectType.ObjectMarker)?.toIntOrNull() ?: 0
            val fraction =
                capacity.let { cap -> occupancy.let { occ -> if (cap > 0) occ.toDouble() / cap.toDouble() else -1.0 } } // -1.0 used to indicate unknown capacity
            val zoneType = customFields["Type"]?.fieldValue
            val statusColor =
                parseEnumValue<MarkerColor>(obj.tags.getUniqueTag(ObjectTags.StatusColor)).getColorForStatus()
            objectType = obj.objectType
            color = obj.color ?: zoneTypeColorMap[customFields["Type"]?.fieldValue]
            icon = obj.iconCategory ?: zoneTypeIconMap[customFields["Type"]?.fieldValue]
            shape = obj.shape ?: zoneTypeShapeMap[customFields["Type"]?.fieldValue]
            stateColor = if (showStateIndicator) {
                if (flagged) {
                    FormationColors.RedError
                } else {
                    when (zoneType) {
                        "containers", "Container", "coils", "Coil" -> getColorForOccupancy(fraction)
                        "machine" -> statusColor
                        else -> null
                    }
                }
            } else null
            stateIcon = if (flagged) FormationIcons.Caution else null
        }

        else -> {
            objectType = obj.objectType
            color = obj.color
            icon = obj.iconCategory
            shape = obj.shape
            stateColor = if (showStateIndicator) {
                if (flagged) {
                    FormationColors.RedError
                } else {
                    when (obj.objectType) {
                        ObjectType.Task -> obj.taskState?.getColor()
                        ObjectType.Event -> obj.attendees?.firstOrNull { it.userId == currentUserId }?.meetingInvitationStatus?.getColor()
                        else -> null
                    }
                }
            } else null
            stateIcon = if (showStateIndicator) {
                if (flagged) {
                    FormationIcons.Caution
                } else {
                    when (obj.objectType) {
                        ObjectType.Task -> obj.taskState?.getIcon()
                        ObjectType.Event -> obj.attendees?.firstOrNull { it.userId == currentUserId }?.meetingInvitationStatus?.getIcon()
                        else -> null
                    }
                }
            } else null
        }
    }

    return when (objectType) {

        ObjectType.UserMarker -> userSvgIconOptions(
            size = myUserSize,
            color = color,
            icon = icon,
            shape = shape,
            hasNotification = hasUnreadNotifications,
            flagged = flagged,
            sharing = userSharingState,
            picture = userPicture,
            bitmapImage = bitmapImage,
        )

        ObjectType.CurrentUserMarker -> myUserSvgIconOptions(
            size = myUserSize,
            sharing = myUserSharingState,
            color = null,
            icon = null,
            shape = null,
            picture = userPicture,
        )

        ObjectType.POI -> pointSvgIconOptions(
            size = defaultMarkerSizeSearch,
            color = color,
            icon = icon,
            shape = shape,
            hasNotification = hasUnreadNotifications,
            flagged = flagged,
            bitmapImage = bitmapImage,
        )

        ObjectType.Task -> taskSvgIconOptions(
            size = defaultMarkerSizeSearch,
            color = color,
            icon = icon,
            shape = shape,
            hasNotification = hasUnreadNotifications,
            flagged = flagged,
            stateColor = stateColor,
            stateIcon = stateIcon,
            bitmapImage = bitmapImage,
        )

        ObjectType.Event -> eventSvgIconOptions(
            size = defaultMarkerSizeSearch,
            color = color,
            icon = icon,
            shape = shape,
            hasNotification = hasUnreadNotifications,
            flagged = flagged,
            stateColor = stateColor,
            stateIcon = stateIcon,
            bitmapImage = bitmapImage,
        )

        ObjectType.Building -> buildingSvgIconOptions(
            size = defaultMarkerSizeSearch,
            color = color,
            icon = icon,
            shape = shape,
            hasNotification = hasUnreadNotifications,
            flagged = flagged,
            bitmapImage = bitmapImage,
        )

        ObjectType.ObjectMarker -> objectMarkerSvgIconOptions(
            size = defaultMarkerSizeSearch,
            color = color,
            icon = icon,
            shape = shape,
            hasNotification = hasUnreadNotifications,
            flagged = flagged,
            bitmapImage = bitmapImage,
        )

        ObjectType.Area -> areaMarkerSvgIconOptions(
            size = defaultMarkerSizeSearch,
            color = color,
            icon = icon,
            shape = shape,
            hasNotification = hasUnreadNotifications,
            flagged = flagged,
            bitmapImage = bitmapImage,
        )

        ObjectType.Zone -> zoneMarkerSvgIconOptions(
            size = defaultMarkerSizeSearch,
            color = color,
            icon = icon,
            shape = shape,
            hasNotification = hasUnreadNotifications,
            flagged = flagged,
            stateColor = stateColor,
            bitmapImage = bitmapImage,

            )

        ObjectType.GeneralMarker -> connectableShapeSvgIconOptions(
            size = defaultMarkerSizeSearch,
            color = color,
            icon = icon,
            shape = shape,
            hasNotification = hasUnreadNotifications,
            flagged = flagged,
            bitmapImage = bitmapImage,
        )

        else -> null
    }
}

fun RenderContext.titleSubtitleStack(
    addStyling: Style<FlexParams>? = null,
    content: (RenderContext.() -> Unit)? = null
) {
    stackUp(
        {
            height { full }
            width { full }
            alignItems { start }
            justifyContent { center }
            overflow { hidden }
            addStyling?.let { it() }
        },
        id = "title-subtitle-box",
    ) {
        spacing { none }
        items {
            content?.invoke(this)
        }
    }
}

fun RenderContext.titleBox(
    titleText: (HtmlTag<HTMLDivElement>.() -> Unit)? = null,
    titleInfo: (HtmlTag<HTMLDivElement>.() -> Unit)? = null
) {
    flexBox(
        {
            width { full }
            height { full }
            direction { row }
            justifyContent { spaceBetween }
            alignItems { center }
        },
        id = "title-box",
    ) {
        titleText?.invoke(this)

        flexBox(
            {
                direction { row }
                justifyContent { spaceBetween }
                alignItems { center }
                width { maxContent }
            },
            id = "title-info",
        ) {
            titleInfo?.invoke(this)
        }
    }
}

@Deprecated("Try not to use this component and write new one usind tailwindCSS")
fun RenderContext.titleSizedText(
    text: (Span.() -> Unit)? = null
) {
    ellipseText(
        {
            width { full }
            fontWeight { bold }
            fontSize { small }
            textAlign { left }
        },
    ) { text?.invoke(this) }
}

fun RenderContext.subtitleBox(
    wrapText: Boolean = false,
    subtitleText: (HtmlTag<HTMLDivElement>.() -> Unit)? = null,
    subtitleInfo: (HtmlTag<HTMLDivElement>.() -> Unit)? = null
) {
    flexBox(
        {
            width { full }
            height { full }
            direction { row }
            justifyContent { spaceBetween }
            alignItems { center }
            if (wrapText) {
                wrap { wrap }
            } else {
                overflowX { hidden }
            }
        },
        id = "subtitle-box",
    ) {
        div(
            {
                display { flex }
                width { full }
                margins { right { tiny } }
                if (!wrapText) {
                    overflowX { hidden }
                }
            },
            id = "subtitle-text",
        ) {
            subtitleText?.invoke(this)
        }
        div(
            {
                display { flex }
                flex {
                    shrink { "0" }
                    basis { maxContent }
                }
            },
            id = "subtitle-info",
        ) {
            subtitleInfo?.invoke(this)
        }
    }
}

fun RenderContext.subtitleInfoText(wrapText: Boolean = false, text: (Span.() -> Unit)? = null) {
    flexBox(
        {
            fontSize { smaller }
            width { full }
            if (wrapText) {
                wrap { wrap }
            } else {
                overflowX { hidden }
            }
        },
        id = "subtitle-text-size",
    ) {
        if (wrapText) {
            span(
                {
                    width { full }
                    textAlign { left }
                },
            ) { text?.invoke(this) }
        } else {
            ellipseText { text?.invoke(this) }
        }
    }
}

fun RenderContext.keywordTagsBox(
    keywords: List<KeywordTag>,
    searchable: Boolean = true,
    keywordTagType: KeywordTagType,
    maxLines: Int = 1
) {
    val tagOverflowStore = storeOf(0)
    if (keywords.isNotEmpty()) {
        flexBox(
            {
                direction { row }
                width { full }
                justifyContent { start }
                alignItems { center }
                fontSize { small }
                overflowX { hidden }
            },
        ) {
            overFlowContent(maxLines = maxLines) {

                keywords.forEach { tag ->
                    tinyKeyWordTag(
                        tag = tag,
                        searchable = searchable,
                        tagActionType = tag.actionType,
                        tagType = keywordTagType,
                    )
                }

//                resizeObserver(domNode).map { it.width * maxLines }.distinctUntilChanged().debounce(100)
//                    .render { containerWidth ->
//
//                        val visibleElements = mutableListOf<Button>()
//                        var overflownTags = 0
//                        keywords.forEach { tag ->
//                            val tagElement = tinyKeyWordTag(
//                                tag = tag,
//                                searchable = searchable,
//                                tagActionType = tag.actionType,
//                                tagType = keywordTagType,
//                            )
//                            if (visibleElements.sumOf { widthWithMarginsOf(it.domNode) }
//                                + widthWithMarginsOf(tagElement.domNode)
//                                + (if (overflownTags - 1 > 0 || keywords.size > 1) 40 else 0)
//                                < containerWidth
//                            ) {
//                                visibleElements.add(tagElement)
//                                overflownTags = keywords.size - visibleElements.size
//                                tagOverflowStore.update(overflownTags)
//                            } else {
//                                if (containerWidth > 0) {
//                                    tagElement.domNode.style.setProperty("display", "none")
//                                }
//                            }
//                        }
//                        tagOverflowStore.data.render { overflown ->
//                            if (overflown > 0) tinyKeyWordTag(
//                                KeywordTag(
//                                    fieldText = "+${overflown}",
//                                    actionType = KeywordTagActionType.Default,
//                                ),
//                                tagType = keywordTagType,
//                            )
//                        }
//                    }
            }
        }
    }
}

enum class DistanceTo : Translatable {
    Map,
    User,
    ;

    override val prefix = "distanceto"
}

class SearchDistanceCalculationStore : RootStore<DistanceTo?>(
    initialData = null,
    job = Job(),
) {
    private val geoPositionStore: GeoPositionStore by koinCtx.inject()

    private val updateToUserOnce = handle<GeoPosition?> { current, geoPosition ->
        if (geoPosition != null) {
            current ?: DistanceTo.User
        } else current
    }

    val switch = handle { current ->
        if (current == DistanceTo.User) DistanceTo.Map else DistanceTo.User
    }
    val select = handle<DistanceTo> { _, new ->
        new
    }

    init {
        geoPositionStore.data handledBy updateToUserOnce
    }
}

fun RenderContext.distanceCalcSwitch(
    distanceToSelectStore: RootStore<DistanceTo?>,
    switchHandler: SimpleHandler<DistanceTo>
) {
    val geoPositionStore: GeoPositionStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()

    combine(distanceToSelectStore.data, geoPositionStore.data) { d, g ->
        Pair(
            d,
            g,
        )
    }.render { (distanceTo, geoPosition) ->
        if (geoPosition != null && distanceTo != null) {
            flexBox(
                {
                    width { full }
                    direction { row }
                    alignItems { center }
                    justifyContent { spaceBetween }
                    paddings {
                        right { smaller }
                        vertical { tiny }
                    }
                },
            ) {
                flexBox(
                    {
                        direction { row }
                        justifyContent { spaceBetween }
                        alignItems { center }
                        border {
                            color { primary.main }
                            width(formationStyles.borderWidth)
                        }
                        radius(formationStyles.inputRadius)
                    },
                ) {
                    listOf(DistanceTo.Map, DistanceTo.User).forEach { enumValue ->
                        button(
                            {
                                if (distanceTo == enumValue) distanceFilterButtonOnStyle() else distanceFilterButtonOffStyle()
                            },
                        ) {
                            translation[enumValue].renderText(into = this)
                            clicks.map { enumValue } handledBy switchHandler
                        }
                    }
                }
                icon(
                    {
                        size { small }
                    },
                ) { fromTheme { if (distanceTo == DistanceTo.Map) FormationIcons.MapCenter.icon else FormationIcons.User.icon } }
            }
        }
    }
}

fun RenderContext.distanceInfo(latLonFlow: Flow<LatLon?>) {
    val geoPositionStore: GeoPositionStore by koinCtx.inject()
    val mapStateStore: MapStateStore by koinCtx.inject()
    val searchDistanceCalculationStore: SearchDistanceCalculationStore by koinCtx.inject()

    combine(
        mapStateStore.data,
        geoPositionStore.data.debounce(1000),
        searchDistanceCalculationStore.data,
    ) { m, g, d -> Triple(m, g, d) }
        .combine(latLonFlow) { data, latLon -> Pair(data, latLon) }.render { (data, latLon) ->
            val (mapState, position, useDistance) = data
            val currentMapPosition = mapState?.center
            val currentUserPosition = position?.latLon()
            val distance =
                (if (useDistance == DistanceTo.User) currentUserPosition else currentMapPosition)?.let { comparePos ->
                    latLon?.let { objPos ->
                        GeoGeometry.distance(
                            objPos.lat,
                            objPos.lon,
                            comparePos.lat,
                            comparePos.lon,
                        )
                    }
                }
            if (distance != null) {
                flexBox(
                    {
                        margins { left { smaller } }
                        color {
                            when (distance.toInt()) {
                                in 0..100 -> FormationColors.GreenActive.color
                                in 100..200 -> FormationColors.YellowDoing.color
                                else -> FormationColors.GrayDisabled.color
                            }
                        }
                        alignItems { center }
                        justifyContent { flexEnd }
                    },
                    id = "distance",
                ) {
                    ellipseText(
                        {
                            width { full }
                            fontWeight { semiBold }
                            fontSize { smaller }
                            textAlign { left }
                        },
                    ) { +distance.getDistanceString() }
                    icon(
                        {
                            size { small }
                            margins { left { tiny } }
                        },
                    ) { fromTheme { if (useDistance == DistanceTo.User) FormationIcons.User.icon else FormationIcons.MapCenter.icon } }
                }
            }
        }
}

fun RenderContext.sharingInfo(sharing: Boolean) {
    flexBox(
        {
            width { "25px" }
            height { "25px" }
            color { secondary.main }
            background {
                color {
                    if (sharing) {
                        FormationColors.GreenActive.color
                    } else FormationColors.GrayPrivate.color
                }
            }
            radius { full }
            padding { smaller }
            justifyContent { center }
            alignItems { center }
        },
    ) {
        icon(
            {
                size { normal }
            },
        ) {
            fromTheme {
                if (sharing) {
                    FormationIcons.SharingOn.icon
                } else FormationIcons.SharingOff.icon
            }
        }
    }
}

@ExperimentalCoroutinesApi
fun RenderContext.stateInfo(text: String, color: ColorProperty) {
    flexBox(
        {
            direction { row }
            justifyContent { flexStart }
            alignItems { center }
        },
    ) {
        span(
            {
                fontSize { smaller }
                color { color }
                textAlign { right }
//            background {
//                color { makeRGBA(color, 0.05) }
//            }
//            radius { normal }
//            paddings { horizontal { tiny } }
            },
        ) { +text }
        div(
            {
                width { "10px" }
                height { "10px" }
                flex {
                    grow { "0" }
                    shrink { "0" }
                    basis { "10px" }
                }
                radius { full }
                background { color { color } }
                margins { left { smaller } }
            },
        ) { }
    }
}

fun RenderContext.generalListEntry(
    geoObject: GeoObjectDetails,
    withBorder: Boolean = true,
    tagsSearchable: Boolean = true,
    keywordTagType: KeywordTagType = KeywordTagType.SearchTag,
    selectable: Boolean = false,
    selected: Flow<Boolean> = flowOf(false),
    highlighted: Flow<Boolean> = flowOf(false),
    selectIcon: Icons.() -> IconDefinition = { FormationUIIcons.Check.icon },
    selectColor: Colors.() -> ColorProperty = { FormationColors.RedError.color },
//    selectClickHandlers: List<SimpleHandler<GeoObjectDetails>>? = null,
    hoverEffect: Boolean? = true,
    selectClickHandlers: List<SimpleHandler<String>>? = null,
) {

    val translation: Translation by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val userListStore: UserListStore by koinCtx.inject()
    val markerClientStore: MarkerClientStore by koinCtx.inject()
    val assignedToUserName =
        userListStore.getPublicUserProfile(geoObject.assignedTo)?.let { "${it.firstName} ${it.lastName}" }
    // Info for zones:
    val customFields = getCustomFieldMap(geoObject.tags.getTagValues(ObjectTags.CustomField))
    val capacity = customFields["Capacity"]?.fieldValue?.toIntOrNull()
        ?: 0 // TODO remove Fallback to zero, when capacity tag is "0" on all zones that have unknown capacity
    val occupancy = geoObject.tags.getTagValues(ObjectTags.ChildCount).getUniqueTag(ObjectType.ObjectMarker)?.toIntOrNull()
        ?: 0 // TODO remove Fallback to zero, when childCount tag is added with "0" when the object has zero children
    val zoneType = customFields["Type"]?.fieldValue
    val statusCode = customFields["Status Code"]?.fieldValue
    val statusColor =
        parseEnumValue<MarkerColor>(geoObject.tags.getUniqueTag(ObjectTags.StatusColor)).getColorForStatus()

    val archived = geoObject.tags.getUniqueTag(ObjectTags.Archived).toBoolean()
    val flagged = geoObject.tags.getUniqueTag(ObjectTags.Flagged).toBoolean()
    val contentCount = geoObject.attachments?.size ?: 0

    val readOnlyKeywordTags = geoObject.tags.extractReadOnlyTags()

    val currentWorkspaceStore: CurrentWorkspaceStore by koinCtx.inject()
    val fieldValueTags = currentWorkspaceStore.current?.fieldDefinitions?.let { definitions ->
        geoObject.tags.respectFeatureFlags().parseFieldValues(definitions)
    }?.toKeyWordTagsList(KeywordTagActionType.DefaultFieldValue) ?: emptyList()

    val currentBuildingsStore: CurrentBuildingsStore by koinCtx.inject()
    val connectedToId = geoObject.tags.getUniqueTag(ObjectTags.ConnectedTo)
    val isInBuilding = connectedToId?.let {
        currentBuildingsStore.current.getBuilding(it)?.buildingName
    }
    val isOnLevel = connectedToId?.let {
        getLevelFromFloorId(it)
    }
    var isHighlighted: Boolean? = null

    highlighted.mapNotNull { if (it != isHighlighted) it else null }.render { highlight ->
        isHighlighted = highlight
        listEntryBox(
            additionalStyle = {
                if (archived || flagged) {
                    opacity { light }
                }
                if (highlight) {
                    background {
                        color { makeRGBA(FormationColors.MarkerYou.color, 0.2) }
                    }
                    hover {
                        background {
                            color { makeRGBA(FormationColors.MarkerYou.color, 0.4) }
                        }
                    }
                }
            },
            withBorder = withBorder,
            hoverEffect = hoverEffect,
        ) {
            val objectIsMe =
                geoObject.ownerId == apiUserStore.current.userId && geoObject.objectType == ObjectType.UserMarker
            iconBox(
                geoObject,
                flagged = flagged,
                showStateIndicator = geoObject.objectType == ObjectType.Task || geoObject.objectType == ObjectType.Event || flagged,
            )
            titleSubtitleStack {
                titleBox(
                    titleText = {
                        titleSizedText {
                            if (flagged) {
                                +"["
                                icon(
                                    {
                                        size { small }
//                                margins { right { tiny } }
                                    },
                                ) { fromTheme { FormationIcons.Caution.icon } }
                                +"${translation.getString(TL.General.FLAGGED)}] "
                            }
                            if (flagged && archived) {
                                +" & "
                            }
                            if (archived) {
                                +"["
                                icon(
                                    {
                                        size { small }
                                        if (!flagged) margins { right { tiny } }
                                    },
                                ) { fromTheme { FormationIcons.Archive.icon } }
                                +"${if (!flagged) translation.getString(TL.General.ARCHIVED) else ""}] "
                            }
                            +"${geoObject.title} ${
                                if (objectIsMe) "(${
                                    translation.getString(TL.General.YOU, mapOf("case" to "nominative"))
                                        .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                                })" else ""
                            }"
                        }
                    },
                    titleInfo = {
                        if (geoObject.objectType == ObjectType.UserMarker) {
                            markerClientStore.data.render { _ ->
                                sharingInfo(markerClientStore.isUserSharing(geoObject.ownerId))
                            }
                            if (!objectIsMe) distanceInfo(
                                markerClientStore.data.mapNotNull { markers ->
                                    markers[geoObject.ownerId]?.latLon
                                },
                            )
                        } else if (geoObject.objectType == ObjectType.ObjectMarker) {
                            distanceInfo(
                                markerClientStore.data.map { markers ->
                                    markers[geoObject.id]?.latLon ?: geoObject.latLon
                                },
                            )
                        } else distanceInfo(flowOf(geoObject.latLon))
                    },
                )
                subtitleBox(
                    subtitleText = {
                        stackUp(
                            {
                                width { full }
                                overflowX { hidden }
                                alignItems { center }
                                justifyContent { start }
                            },
                        ) {
                            spacing { none }
                            items {
                                when (geoObject.objectType) {
                                    ObjectType.UserMarker -> subtitleInfoText {
                                        +(geoObject.owner?.jobTitle.mergeIfNotBlank(geoObject.owner?.company, " | ") ?: geoObject.objectType.getName())
                                    }

                                    ObjectType.POI -> subtitleInfoText {
                                        +"${geoObject.objectType.getName()}${geoObject.description?.let { " - $it" } ?: ""}${isInBuilding?.let { b -> " @ $b${isOnLevel?.let { " ($it)" }}" } ?: ""}"
                                    }

                                    ObjectType.Task -> subtitleInfoText {
                                        +"${geoObject.objectType.getName()}${
                                            geoObject.assignedTo?.let { userId ->
                                                " ${translation.getString(TL.Search.FOR)} ${
                                                    if (userId == apiUserStore.current.userId) {
                                                        translation.getString(
                                                            TL.General.YOU,
                                                            mapOf("case" to "accusative"),
                                                        )
                                                    } else {
                                                        assignedToUserName?.let { name ->
                                                            cutString(
                                                                name,
                                                                30,
                                                            )
                                                        } ?: cutString(userId, 10) // TODO does not cut the id to 10 chars -> investigate
                                                    }
                                                }"
                                            } ?: ""
                                        }${isInBuilding?.let { b -> " @ $b${isOnLevel?.let { " ($it)" }}" } ?: ""}"
                                        geoObject.assignedTo?.let { title(it) }
                                    }

                                    ObjectType.Event -> subtitleInfoText {
                                        +"${geoObject.objectType.getName()}${
                                            if (geoObject.attendees?.map { it.userId }
                                                    ?.contains(apiUserStore.current.userId) == true) {
                                                " ${translation.getString(TL.Search.YOU_ARE_INVITED)}"
                                            } else ""
                                        }${isInBuilding?.let { b -> " @ $b${isOnLevel?.let { " ($it)" }}" } ?: ""}"
                                    }

                                    ObjectType.Building -> subtitleInfoText { +geoObject.objectType.getName() }
                                    ObjectType.CurrentUserMarker -> subtitleInfoText {
                                        +translation.getString(
                                            TL.General.YOU,
                                            mapOf("case" to "nominative"),
                                        )
                                    }

                                    ObjectType.ObjectMarker -> subtitleInfoText { +"${geoObject.objectType.getName()}${isInBuilding?.let { b -> " @ $b${isOnLevel?.let { " ($it)" }}" } ?: ""}" }
                                    ObjectType.Area -> subtitleInfoText { +"${geoObject.objectType.getName()}${isInBuilding?.let { b -> " @ $b${isOnLevel?.let { " ($it)" }}" } ?: ""}" }
                                    ObjectType.Zone -> subtitleInfoText {
                                        customFields["Type"]?.let {
                                            +"${geoObject.objectType.getName()} - ${it.fieldValue?.replaceFirstChar { c -> if (c.isLowerCase()) c.titlecase() else c.toString() }}${isInBuilding?.let { b -> " @ $b${isOnLevel?.let { " ($it)" }}" } ?: ""}"
                                        } ?: +"${geoObject.objectType.getName()}${isInBuilding?.let { b -> " @ $b${isOnLevel?.let { " ($it)" }}" } ?: ""}"
                                    }

                                    ObjectType.GeneralMarker -> subtitleInfoText {
                                        +"${geoObject.objectType.getName()}${isInBuilding?.let { b -> " @ $b${isOnLevel?.let { " ($it)" }}" } ?: ""}"
                                    }

                                    else -> {}
                                }
                                lineUp(
                                    {
                                        width { full }
                                        alignItems { center }
                                        justifyContent { start }
                                    },
                                ) {
                                    spacing { none }
                                    items {
                                        if (contentCount > 0) {
                                            span({ fontSize { smaller } }) { +"$contentCount" }
                                            icon({ size { small } }) { fromTheme { FormationIcons.Clip.icon } }
                                        }
                                        keywordTagsBox(
                                            keywords = mergeTagLists(
                                                readOnlyTags = readOnlyKeywordTags,
                                                keywords = geoObject.keywords,
                                                readOnlyFirst = false,
                                                fieldValueTags = fieldValueTags,
                                            ),
                                            searchable = tagsSearchable,
                                            keywordTagType = keywordTagType,
                                        )
                                    }
                                }
                            }
                        }
                    },
                    subtitleInfo = {
                        when (geoObject.objectType) {
                            ObjectType.Task -> {
                                stackUp(
                                    {
                                        width { full }
                                        alignItems { end }
                                        justifyContent { end }
                                    },
                                ) {
                                    spacing { none }
                                    items {
                                        val isToday =
                                            geoObject.atTime?.parseInstant()?.let { isToday(it.localDT()) } == true
                                        val isInFuture =
                                            geoObject.atTime?.parseInstant()?.let { isInFuture(it.localDT()) } == true
                                        val isInPast =
                                            geoObject.atTime?.parseInstant()?.let { isInPast(it.localDT()) } == true
                                        div(
                                            {
                                                when {
                                                    isToday -> {
                                                        color { FormationColors.RedError.color }; fontWeight { semiBold }
                                                    }

                                                    isInFuture -> {
                                                        color { FormationColors.GreenActive.color }
                                                    }

                                                    isInPast && geoObject.taskState != TaskState.Completed -> {
                                                        color { FormationColors.RedDarkCustom.color }
                                                    }

                                                    else -> {
                                                        color { primary.main }
                                                    }
                                                }
                                            },
                                        ) {
                                            subtitleInfoText {
                                                geoObject.atTime?.parseInstant()?.let { atTime ->
                                                    if (isToday) +"${atTime.formatTime()} ${translation.getString(TL.DateTime.TODAY)}"
                                                    else +"${atTime.formatTime()} ${atTime.formatDateForSearch()}"
                                                }
                                            }
                                        }
                                        with(geoObject.taskState) {
                                            if (this != null) stateInfo(
                                                text = this.getTitle(),
                                                color = this.getColor().color,
                                            )
                                        }
                                    }
                                }
                            }

                            ObjectType.Event -> {
                                val isToday = geoObject.atTime?.parseInstant()?.let { isToday(it.localDT()) } == true
                                div(
                                    {
                                        if (isToday) {
                                            color { FormationColors.RedError.color }; fontWeight { semiBold }
                                        }
                                    },
                                ) {
                                    subtitleInfoText {
                                        geoObject.atTime?.parseInstant()?.let { atTime ->
                                            if (isToday) +"${atTime.formatTime()} ${translation.getString(TL.DateTime.TODAY)}"
                                            else +"${atTime.formatTime()} ${atTime.formatDateForSearch()}"
                                        }
                                    }
                                }
                            }
                            // display Building (Workplace) office ours or other info
                            ObjectType.Building -> subtitleInfoText { +"\"Office hours\"" }
                            // display device availability state
                            ObjectType.ObjectMarker -> subtitleInfoText { }
                            ObjectType.Zone -> {
                                when (zoneType) {
                                    "containers", "Container", "coils", "Coil" -> {
                                        capacity.let { cap ->
                                            occupancy.let { occ ->
                                                val fraction =
                                                    if (cap > 0) occ.toDouble() / cap.toDouble() else -1.0 // -1.0 used to indicate unknown capacity
                                                stateInfo(
                                                    text = "Capacity: $occ/${if (cap > 0.0) cap else "-"}",
                                                    color = getColorForOccupancy(fraction)?.color ?: statusColor.color,
                                                )
                                            }
                                        }
                                    }

                                    "machine" -> {
                                        statusCode?.let { status ->
                                            stateInfo(
                                                text = status,
                                                color = statusColor.color,
                                            )
                                        }
                                    }

                                    else -> {}
                                }
                            }

                            else -> {}
                        }
                    },
                )
            }
            if (selectable) {
                selected.render { isSelected ->
                    div(
                        {
                            width { "40px" }
                            height { "40px" }
                            color { secondary.main }
                            radius { full }
                            padding { smaller }
                            margins {
                                vertical { tiny }
                                left { tiny }
                            }
                            flex {
                                grow { "0" }
                                shrink { "0" }
                                basis { "40px" }
                            }
                            if (isSelected) {
                                background { color(selectColor) }
                            } else {
                                border {
                                    //                            color { FormationColors.GrayPrivate.color }
                                    color { primary.main }
                                    width { "2px" }
                                }
                            }
                        },
                    ) {
                        if (isSelected) icon({ size { larger } }) { fromTheme(selectIcon) }
                        selectClickHandlers?.forEach { handler ->
                            clicks.map {
                                it.stopPropagation()
                                geoObject.id
                            } handledBy handler
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.simpleListEntry(geoObject: GeoObjectDetails) {
    val translation: Translation by koinCtx.inject()
    val markerClientStore: MarkerClientStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val userListStore: UserListStore by koinCtx.inject()
    val archived = geoObject.tags.getUniqueTag(ObjectTags.Archived).toBoolean()
    val flagged = geoObject.tags.getUniqueTag(ObjectTags.Flagged).toBoolean()
    val objectIsMe = geoObject.ownerId == apiUserStore.current.userId
        && geoObject.objectType == ObjectType.UserMarker
    val currentBuildingsStore: CurrentBuildingsStore by koinCtx.inject()
    val connectedToId = geoObject.tags.getUniqueTag(ObjectTags.ConnectedTo)
    val isInBuilding = connectedToId?.let {
        currentBuildingsStore.current.getBuilding(it)?.buildingName
    }
    val isOnLevel = connectedToId?.let {
        getLevelFromFloorId(it)
    }

    val assignedToUserName =
        userListStore.getPublicUserProfile(geoObject.assignedTo)?.let { "${it.firstName} ${it.lastName}" }
    // Info for zones:
    val customFields = getCustomFieldMap(geoObject.tags.getTagValues(ObjectTags.CustomField))
    val capacity = customFields["Capacity"]?.fieldValue?.toIntOrNull()
        ?: 0 // TODO remove Fallback to zero, when capacity tag is "0" on all zones that have unknown capacity
    val occupancy = geoObject.tags.getTagValues(ObjectTags.ChildCount).getUniqueTag(ObjectType.ObjectMarker)?.toIntOrNull()
        ?: 0 // TODO remove Fallback to zero, when childCount tag is added with "0" when the object has zero children
    val zoneType = customFields["Type"]?.fieldValue
    val statusCode = customFields["Status Code"]?.fieldValue
    val statusColor =
        parseEnumValue<MarkerColor>(geoObject.tags.getUniqueTag(ObjectTags.StatusColor)).getColorForStatus()

    div("flex flex-row w-full gap-1 pl-1 pr-2 py-1 items-center justify-start rounded-xl overflow-hidden") {
        // iconBox
        div("flex w-11 h-11 grow-0 shrink-0 basis-11 items-center justify-center mr-1") {
            when (geoObject.objectType) {
                ObjectType.UserMarker -> {
                    // USER ICON ON CIRCLE
                    val picture = userListStore.current?.firstOrNull { geoObject.ownerId == it.userId }?.profilePhoto
                    userOrStateIcon(
                        pixelSize = MarkerSize.S.smPixel.toDouble(),
                        shadow = true,
                        margins = {
                            vertical { "${(((MarkerSize.XS.smPixel * 1.3) - MarkerSize.XS.smPixel) / 2).toInt()}px" }
                            horizontal { "${(((MarkerSize.XS.smPixel * 1.3) - MarkerSize.XS.smPixel) / 2).toInt()}px" }
                        },
                        picture = (picture?.thumbNail as? Content.Image)?.href ?: picture?.href,
                        initials = initials(geoObject.owner?.firstName, geoObject.owner?.lastName),
                    )
                }

                else -> {
                    getSvgIconOptions(
                        obj = geoObject,
                        flagged = geoObject.isFlagged,
                        showStateIndicator = false,
                    )?.copy(size = MarkerSize.S)?.let { renderSvgIcon(it) }
                }
            }
        }
        titleSubtitleStack {
            titleBox(
                titleText = {
                    titleSizedText {
                        if (flagged) {
                            +"["
                            twIconSmall(icon = FormationIcons.Caution)
                            +"${translation.getString(TL.General.FLAGGED)}] "
                        }
                        if (flagged && archived) {
                            +" & "
                        }
                        if (archived) {
                            +"["
                            twIconSmall(icon = FormationIcons.Archive)
                            icon(
                                {
                                    size { small }
                                    if (!flagged) margins { right { tiny } }
                                },
                            ) { fromTheme { FormationIcons.Archive.icon } }
                            +"${if (!flagged) translation.getString(TL.General.ARCHIVED) else ""}] "
                        }
                        +"${geoObject.title} ${
                            if (objectIsMe) "(${
                                translation.getString(TL.General.YOU, mapOf("case" to "nominative"))
                                    .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                            })" else ""
                        }"
                    }
                },
                titleInfo = {
                    if (geoObject.objectType == ObjectType.UserMarker) {
                        markerClientStore.data.render { _ ->
                            sharingInfo(markerClientStore.isUserSharing(geoObject.ownerId))
                        }
                        if (!objectIsMe) distanceInfo(
                            markerClientStore.data.mapNotNull { markers ->
                                markers[geoObject.ownerId]?.latLon
                            },
                        )
                    } else if (geoObject.objectType == ObjectType.ObjectMarker) {
                        distanceInfo(
                            markerClientStore.data.map { markers ->
                                markers[geoObject.id]?.latLon ?: geoObject.latLon
                            },
                        )
                    } else distanceInfo(flowOf(geoObject.latLon))
                },
            )
            subtitleBox(
                subtitleText = {
                    stackUp(
                        {
                            width { full }
                            overflowX { hidden }
                            alignItems { center }
                            justifyContent { start }
                        },
                    ) {
                        spacing { none }
                        items {
                            when (geoObject.objectType) {
                                ObjectType.UserMarker -> subtitleInfoText {
                                    +(geoObject.owner?.jobTitle.mergeIfNotBlank(geoObject.owner?.company, " | ") ?: geoObject.objectType.getName())
                                }

                                ObjectType.POI -> subtitleInfoText {
                                    +"${geoObject.objectType.getName()}${geoObject.description?.let { " - $it" } ?: ""}${isInBuilding?.let { b -> " @ $b${isOnLevel?.let { lvl -> " ($lvl)" }}" } ?: ""}"
                                }

                                ObjectType.Task -> subtitleInfoText {
                                    +"${geoObject.objectType.getName()}${
                                        geoObject.assignedTo?.let {
                                            " ${translation.getString(TL.Search.FOR)} ${
                                                if (it == apiUserStore.current.userId) translation.getString(
                                                    TL.General.YOU,
                                                    mapOf("case" to "accusative"),
                                                ) else {
                                                    assignedToUserName?.let { name ->
                                                        cutString(
                                                            name,
                                                            30,
                                                        )
                                                    } ?: cutString(it, 30)
                                                }
                                            }"
                                        } ?: ""
                                    }${isInBuilding?.let { b -> " @ $b${isOnLevel?.let { lvl -> " ($lvl)" }}" } ?: ""}"
                                }

                                ObjectType.Event -> subtitleInfoText {
                                    +"${geoObject.objectType.getName()}${
                                        if (geoObject.attendees?.map { it.userId }
                                                ?.contains(apiUserStore.current.userId) == true) {
                                            " ${translation.getString(TL.Search.YOU_ARE_INVITED)}"
                                        } else ""
                                    }${isInBuilding?.let { b -> " @ $b${isOnLevel?.let { lvl -> " ($lvl)" }}" } ?: ""}"
                                }

                                ObjectType.Building -> subtitleInfoText { +geoObject.objectType.getName() }
                                ObjectType.CurrentUserMarker -> subtitleInfoText {
                                    +translation.getString(
                                        TL.General.YOU,
                                        mapOf("case" to "nominative"),
                                    )
                                }

                                ObjectType.ObjectMarker -> subtitleInfoText { +"${geoObject.objectType.getName()}${isInBuilding?.let { b -> " @ $b${isOnLevel?.let { lvl -> " ($lvl)" }}" } ?: ""}" }
                                ObjectType.Area -> subtitleInfoText { +"${geoObject.objectType.getName()}${isInBuilding?.let { b -> " @ $b${isOnLevel?.let { lvl -> " ($lvl)" }}" } ?: ""}" }
                                ObjectType.Zone -> subtitleInfoText {
                                    customFields["Type"]?.let {
                                        +"${geoObject.objectType.getName()} - ${it.fieldValue?.replaceFirstChar { c -> if (c.isLowerCase()) c.titlecase() else c.toString() }}${isInBuilding?.let { b -> " @ $b${isOnLevel?.let { lvl -> " ($lvl)" }}" } ?: ""}"
                                    } ?: +"${geoObject.objectType.getName()}${isInBuilding?.let { b -> " @ $b${isOnLevel?.let { lvl -> " ($lvl)" }}" } ?: ""}"
                                }

                                ObjectType.GeneralMarker -> subtitleInfoText {
                                    +"${geoObject.objectType.getName()}${isInBuilding?.let { b -> " @ $b${isOnLevel?.let { lvl -> " ($lvl)" }}" } ?: ""}"
                                }

                                else -> {}
                            }
                        }
                    }
                },
                subtitleInfo = {
                    when (geoObject.objectType) {
                        ObjectType.Task -> {
                            stackUp(
                                {
                                    width { full }
                                    alignItems { end }
                                    justifyContent { end }
                                },
                            ) {
                                spacing { none }
                                items {
//                                    val isToday =
//                                        geoObject.atTime?.parseInstant()?.let { isToday(it.localDT()) } == true
//                                    val isInFuture =
//                                        geoObject.atTime?.parseInstant()?.let { isInFuture(it.localDT()) } == true
//                                    val isInPast =
//                                        geoObject.atTime?.parseInstant()?.let { isInPast(it.localDT()) } == true
//                                    div(
//                                        {
//                                            when {
//                                                isToday -> {
//                                                    color { FormationColors.RedError.color }; fontWeight { semiBold }
//                                                }
//
//                                                isInFuture -> {
//                                                    color { FormationColors.GreenActive.color }
//                                                }
//
//                                                isInPast && geoObject.taskState != TaskState.Completed -> {
//                                                    color { FormationColors.RedDarkCustom.color }
//                                                }
//
//                                                else -> {
//                                                    color { primary.main }
//                                                }
//                                            }
//                                        },
//                                    ) {
//                                        subtitleInfoText {
//                                            geoObject.atTime?.parseInstant()?.let { atTime ->
//                                                if (isToday) +"${atTime.formatTime()} ${translation.getString(TL.DateTime.TODAY)}"
//                                                else +"${atTime.formatTime()} ${atTime.formatDateForSearch()}"
//                                            }
//                                        }
//                                    }
                                    with(geoObject.taskState) {
                                        if (this != null) stateInfo(
                                            text = this.getTitle(),
                                            color = this.getColor().color,
                                        )
                                    }
                                }
                            }
                        }

                        ObjectType.Event -> {
                            val isToday = geoObject.atTime?.parseInstant()?.let { isToday(it.localDT()) } == true
                            div(
                                {
                                    if (isToday) {
                                        color { FormationColors.RedError.color }; fontWeight { semiBold }
                                    }
                                },
                            ) {
                                subtitleInfoText {
                                    geoObject.atTime?.parseInstant()?.let { atTime ->
                                        if (isToday) +"${atTime.formatTime()} ${translation.getString(TL.DateTime.TODAY)}"
                                        else +"${atTime.formatTime()} ${atTime.formatDateForSearch()}"
                                    }
                                }
                            }
                        }
                        // display Building (Workplace) office ours or other info
                        ObjectType.Building -> subtitleInfoText { +"\"Office hours\"" }
                        // display device availability state
                        ObjectType.ObjectMarker -> subtitleInfoText { }
                        ObjectType.Zone -> {
                            when (zoneType) {
                                "containers", "Container", "coils", "Coil" -> {
                                    capacity.let { cap ->
                                        occupancy.let { occ ->
                                            val fraction =
                                                if (cap > 0) occ.toDouble() / cap.toDouble() else -1.0 // -1.0 used to indicate unknown capacity
                                            stateInfo(
                                                text = "Capacity: $occ/${if (cap > 0.0) cap else "-"}",
                                                color = getColorForOccupancy(fraction)?.color ?: statusColor.color,
                                            )
                                        }
                                    }
                                }

                                "machine" -> {
                                    statusCode?.let { status ->
                                        stateInfo(
                                            text = status,
                                            color = statusColor.color,
                                        )
                                    }
                                }

                                else -> {}
                            }
                        }

                        else -> {}
                    }
                },
            )
        }
    }
}

fun RenderContext.simpleUserProfileListEntry(userProfileSummary: UserProfileSummary) {
    val translation: Translation by koinCtx.inject()
    val markerClientStore: MarkerClientStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val objectIsMe = userProfileSummary.userId == apiUserStore.current.userId


    div("flex flex-row w-full gap-1 pl-1 pr-2 py-1 items-center justify-start rounded-xl overflow-hidden") {
        // iconBox
        div("flex w-11 h-11 grow-0 shrink-0 basis-11 items-center justify-center mr-1") {
            // USER ICON ON CIRCLE
            val picture = userProfileSummary.profilePhoto
            userOrStateIcon(
                pixelSize = MarkerSize.S.smPixel.toDouble(),
                shadow = true,
                margins = {
                    vertical { "${(((MarkerSize.XS.smPixel * 1.3) - MarkerSize.XS.smPixel) / 2).toInt()}px" }
                    horizontal { "${(((MarkerSize.XS.smPixel * 1.3) - MarkerSize.XS.smPixel) / 2).toInt()}px" }
                },
                picture = (picture?.thumbNail as? Content.Image)?.href ?: picture?.href,
                initials = initials(userProfileSummary.firstName, userProfileSummary.lastName),
            )
        }
        titleSubtitleStack {
            titleBox(
                titleText = {
                    titleSizedText {
                        +"${userProfileSummary.name} ${
                            if (objectIsMe) "(${
                                translation.getString(TL.General.YOU, mapOf("case" to "nominative"))
                                    .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                            })" else ""
                        }"
                    }
                },
                titleInfo = null,
            )
            subtitleBox(
                subtitleText = {
                    stackUp(
                        {
                            width { full }
                            overflowX { hidden }
                            alignItems { center }
                            justifyContent { start }
                        },
                    ) {
                        spacing { none }
                        items {
                            subtitleInfoText {
                                +(userProfileSummary.jobTitle.mergeIfNotBlank(userProfileSummary.company, " | ") ?: "")
                            }
                        }
                    }
                },
                subtitleInfo = null,
            )
        }
    }
}
