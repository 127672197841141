package analyticsdashboard


import analyticsdashboard.importexport.objectImportExport
import apiclient.FormationClient
import apiclient.analytics.AnalyticsData
import apiclient.analytics.Dashboard
import apiclient.analytics.DashboardElement
import apiclient.analytics.StandardDashboards
import apiclient.analytics.produceAnalyticsForDashboardElement
import apiclient.analytics.toDashBoards
import apiclient.groups.LayerType
import auth.CurrentWorkspaceStore
import auth.FeatureFlagStore
import auth.Features
import data.objects.objecthistory.DisplayedPathObjectResultsStore
import data.objects.objecthistory.ObjectHistoryResultsCache
import data.objects.objecthistory.ObjectHistoryResultsStore
import data.objects.objecthistory.ShowObjectHistoryPathStore
import data.objects.views.cardManageFieldValueTag
import data.objects.views.objectHistoryList
import data.objects.views.tagManagement
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.core.invoke
import dev.fritz2.core.src
import dev.fritz2.core.storeOf
import dev.fritz2.history.history
import dev.fritz2.routing.MapRouter
import dev.fritz2.tracking.Tracker
import dev.fritz2.tracking.tracker
import koin.koinCtx
import kotlinx.browser.document
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.Translation
import mainmenu.Pages
import mainmenu.RouterStore
import map.views.mapLayerList
import maplibreGL.MaplibreMap
import org.koin.dsl.module
import org.w3c.dom.HTMLIFrameElement
import overlays.BusyStore
import search.PathActiveHighlightedObjectMarkersStore
import search.SearchDistanceCalculationStore
import search.distanceCalcSwitch
import search.globalSearchHeader
import search.pathToolSearchResultsList
import search.searchInput
import search.searchTagButton
import search.searchlayer.MapLayerMetadataListStore
import search.selectedSearchTagsList
import search.twCoordinatesButtons
import services.SuggestedTagsContextStore
import theme.FormationIcons
import theme.FormationUIIcons
import theme.IconEnum
import twcomponents.twColOfNoGap
import twcomponents.twColOfStretch
import twcomponents.twLargeIconButtonNeutralRounded
import twcomponents.twPageHeaderBack
import twcomponents.twPrimaryWhenActiveButton
import twcomponents.twRowOfJustifyBetween
import utils.formatDateTimeForObjectHistory
import utils.handleFunctions
import utils.parseInstant
import webcomponents.BigButtonOption
import webcomponents.KeywordTagType
import webcomponents.genericBigButtonSwitch
import webcomponents.twContentScrollBox
import workspacetools.zoneexporter.zoneHistory

fun analyticsDashboardModule() = module {
    single { WorkspaceAnalyticsDashboardsStore() }
    single { SelectedAnalyticsDashboardStore() }
    single { DashboardTableElementsSortingStore() }
    single { AnalyticsTimeFilterStore() }
    single { AnalyticsTextFilterStore() }
    single { SelectedTimeFilterOptionStore() }
}

fun getFieldValueTitle(fieldValueTag: String): String {
    val workspaceStore by koinCtx.inject<CurrentWorkspaceStore>()
    val tagValues = fieldValueTag.split(":")
    return if (tagValues.size == 2) {
        val (catNamespace, catValue) = tagValues
        workspaceStore.current?.categoryNamespaces?.firstOrNull {
            it.name == catNamespace
        }?.categories?.firstOrNull {
            it.name == catValue
        }?.title ?: catValue
    } else {
        fieldValueTag.parseInstant()?.formatDateTimeForObjectHistory()
            ?: fieldValueTag
    }
}

class WorkspaceAnalyticsDashboardsStore : RootStore<List<Dashboard>?>(
    initialData = null,
    job = Job(),
) {
    val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()
    private val selectedAnalyticsDashboardStore by koinCtx.inject<SelectedAnalyticsDashboardStore>()

    private val autoSelectFirst = handle<List<Dashboard>> { _, workspaceDashboards ->
        workspaceDashboards.firstOrNull()?.let { dashboard ->
            selectedAnalyticsDashboardStore.update(dashboard)
        }
        workspaceDashboards
    }

    init {
        currentWorkspaceStore.data.map { workspace ->
            val standardDashboards =
//                if (workspace?.featureFlags()?.get(GroupFeatureFlags.AllowStandardDashboards) == true) {
                listOf(
                    StandardDashboards.userAnalytics,
                    StandardDashboards.objectMovements,
                    StandardDashboards.contentCreation,
                    StandardDashboards.taskDashboard,
                )
//                } else {
//                    listOf()
//                }

            standardDashboards + (workspace?.dashboards.toDashBoards() ?: emptyList())
        } handledBy update
        data.mapNotNull { it } handledBy autoSelectFirst
    }
}

class DashboardTableElementsSortingStore : RootStore<Map<DashboardElement, DashboardTableSortedColumnState?>>(
    initialData = emptyMap(),
    job = Job(),
) {

    val addOrUpdate =
        handle<Pair<DashboardElement, DashboardTableSortedColumnState?>> { current, (element, sortingState) ->
            val currentMutable = current.toMutableMap()
            currentMutable[element] = sortingState
            currentMutable.toMap()
        }
}

class SelectedAnalyticsDashboardStore : RootStore<Dashboard?>(
    initialData = null,
    job = Job(),
) {

    val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()
    val formationClient by koinCtx.inject<FormationClient>()
    val busyStore by koinCtx.inject<BusyStore>()
    private val analyticsTimeFilterStore: AnalyticsTimeFilterStore by koinCtx.inject()
    private val analyticsTextFilterStore: AnalyticsTextFilterStore by koinCtx.inject()
    private val dashboardTableElementsSortingStore by koinCtx.inject<DashboardTableElementsSortingStore>()

    val computedAnalyticsStore: Store<Map<DashboardElement, AnalyticsData>> = storeOf(emptyMap(), Job())

    val refreshTracker = tracker()

    private fun DashboardElement.addTimeFilter(): DashboardElement {
        val timefilter = analyticsTimeFilterStore.current
        return this.copy(
            searchQueryContext = this.searchQueryContext.copy(
                timeRangeOnCreatedAt = true,
                fromTime = timefilter.filterStartDate,
                toTime = timefilter.filterEndDate,
            ),
        )
    }

    private suspend fun computeDataForElement(elementId: String, debug: Boolean): AnalyticsData? {
        val groupId = currentWorkspaceStore.current?.groupId
        val dashboard = current
        return if (groupId != null && dashboard != null) {
            dashboard.elements.firstOrNull { it.id == elementId }?.let { element ->
                val newData =
                    formationClient.produceAnalyticsForDashboardElement(groupId, element.addTimeFilter(), debug = debug)
                        .fold(
                            onSuccess = { data ->
                                data
                            },
                            onFailure = { e ->
                                console.warn("No data available for dashboard element: ${element.title}", e)
                                null
                            },
                        )
                newData
            }
        } else null
    }

    val computeAllElementData = handle { current ->
        refreshTracker.track {
            current?.elements?.mapNotNull { element ->
                computeDataForElement(element.id, debug = false)?.let { element to it }
            }?.toMap()?.let { elementDataMap ->
                computedAnalyticsStore.update(elementDataMap)
            }
        }
        current
    }

    val computeSingleElementData = handle<Pair<DashboardElement, Tracker>> { current, (element, elementTracker) ->
        elementTracker.track {
            computeDataForElement(element.id, debug = true)?.let { elementData ->
                val updatedElementDataMap = computedAnalyticsStore.current.toMutableMap()
                updatedElementDataMap[element] = elementData
                computedAnalyticsStore.update(updatedElementDataMap)
            }
        }
        current
    }

    val printElement = handle<DashboardElement> { current, element ->
        if (current != null) {
            computedAnalyticsStore.current[element]?.let { data ->
                when (data) {
                    is AnalyticsData.JsonPrimitiveTable -> {
                        val filteredData = filterDashboardTableData(analyticsTextFilterStore.current, data)
                        val sortedData = dashboardTableElementsSortingStore.current[element]?.let { sorting ->
                            sortDashboardTableRows(element.analyticsType, filteredData, sorting)
                        } ?: filteredData
                        val html = getPrintableTable(element, sortedData, current)
                        val iframe = document.createElement("iframe") as HTMLIFrameElement
                        iframe.style.display = "none"
                        document.body?.appendChild(iframe)
                        val pri = iframe.contentWindow
                        pri?.let {
                            pri.document.open()
                            pri.document.write(html)
                            pri.document.close()
                            pri.focus()
                            pri.onafterprint = {
                                document.body?.removeChild(iframe)
                            }
                            pri.print()
                        }
                    }

                    else -> {}
                }
            }
        }
        current
    }

    init {
        data.map { } handledBy computeAllElementData
        analyticsTimeFilterStore.data.mapNotNull { filter ->
            if ((filter.filterStartDate != null) == (filter.filterEndDate != null)) {
                console.log("Choose time filter -> ${filter.filterStartDate} to ${filter.filterEndDate}")
            } else {
                null
            }
        } handledBy computeAllElementData
    }
}

enum class AnalyticPage {
    Stats {
        override val icon: IconEnum = FormationUIIcons.BarChartAlt
        override val mapInteraction: Boolean = false
        override val sidePages: Set<AnalyticSidePage> = setOf()
    },
    Paths {
        override val icon: IconEnum = FormationIcons.Zone
        override val mapInteraction: Boolean = true
        override val sidePages: Set<AnalyticSidePage> = setOf(
            AnalyticSidePage.PathsSearch,
            AnalyticSidePage.PathsHistory,
        )
    },
    Heatmaps {
        override val icon: IconEnum = FormationIcons.HeatmapAlt
        override val mapInteraction: Boolean = true
        override val sidePages: Set<AnalyticSidePage> = setOf()
    },
    Exports {
        override val icon: IconEnum = FormationUIIcons.Export
        override val mapInteraction: Boolean = false
        override val sidePages: Set<AnalyticSidePage> = setOf(
            AnalyticSidePage.ExportZoneHistory,
            AnalyticSidePage.ObjectImportExport,
        )
    },
    ;

    abstract val icon: IconEnum
    abstract val mapInteraction: Boolean
    abstract val sidePages: Set<AnalyticSidePage>
}

enum class AnalyticSidePage {
    PathsSearch, PathsHistory, ExportZoneHistory, ObjectImportExport
}

enum class AnalyticSideSubPage {
    PathsSearchFieldValue, PathsSearchTags
}

class AnalyticsPageStore : RootStore<AnalyticPage?>(
    initialData = AnalyticPage.Stats,
    job = Job(),
) {

    val maplibreMap: MaplibreMap by koinCtx.inject()
    val router: MapRouter by koinCtx.inject()
    private val showObjectHistoryPathStore: ShowObjectHistoryPathStore by koinCtx.inject()
    private val pathActivehighlightedObjectMarkersStore: PathActiveHighlightedObjectMarkersStore by koinCtx.inject()
    private val pathSearchResultsStore: PathSearchResultsStore by koinCtx.inject()
    private val objectHistoryResultsCache: ObjectHistoryResultsCache by koinCtx.inject()
    private val pathActiveHighlightedObjectStore: PathActiveHighlightedObjectStore by koinCtx.inject()
    private val activeHistoryPathSearchKeywordsStore: ActiveHistoryPathSearchKeywordsStore by koinCtx.inject()
    private val activeHistoryPathSearchObjectTypesStore: ActiveHistoryPathSearchObjectTypesStore by koinCtx.inject()
    private val activeHistoryPathSearchReadOnlyKeywordsStore: ActiveHistoryPathSearchReadOnlyKeywordsStore by koinCtx.inject()
    private val activeHistoryPathSearchFieldValuesStore: ActiveHistoryPathSearchFieldValuesStore by koinCtx.inject()
    private val analyticsSidePageStore: AnalyticsSidePageStore by koinCtx.inject()
    private val analyticsSideSubPageStore: AnalyticsSideSubPageStore by koinCtx.inject()
    private val objectHistoryResultsStore: ObjectHistoryResultsStore by koinCtx.inject()

    val initialize = handle {
        console.log("Initialized AnalyticsPageStore")
        AnalyticPage.Stats
    }

    val prepare = SimpleHandler<AnalyticPage?> { newPageData, _ ->
        newPageData handledBy { newPage ->
            // auto select first sidePage for corresponding AnalyticPage
            newPage?.sidePages?.firstOrNull()?.let { analyticsSidePageStore.update(it) }

            when (newPage) {
                AnalyticPage.Stats -> {
                    pathActivehighlightedObjectMarkersStore.initialize()
                    showObjectHistoryPathStore.update(false)
                }

                AnalyticPage.Paths -> {
                    pathSearchResultsStore.triggerSearch()
                    pathActivehighlightedObjectMarkersStore.refresh()
                    showObjectHistoryPathStore.update(true)
                }

                AnalyticPage.Heatmaps -> {
                    pathActivehighlightedObjectMarkersStore.initialize()
                    showObjectHistoryPathStore.update(false)
                }

                AnalyticPage.Exports -> {
                    pathActivehighlightedObjectMarkersStore.initialize()
                    showObjectHistoryPathStore.update(false)
                }

                else -> {
                    pathActivehighlightedObjectMarkersStore.initialize()
                    showObjectHistoryPathStore.update(false)
                }
            }
        }
    }

    private val analyticsPageListener = handle<Map<String, String>> { _, route ->
        if (route["page"] == Pages.AnalyticsDashboard.name) {
            // initialize analytics stores
            pathSearchResultsStore.triggerSearch()
            pathActivehighlightedObjectMarkersStore.refresh()
            showObjectHistoryPathStore.update(true)
        } else {
            // reset analytics stores
            pathSearchResultsStore.reset()
            objectHistoryResultsCache.clearObjectHistoryResultsCache() // TODO: necessary or keep cache longer?
            pathActiveHighlightedObjectStore.reset()
            activeHistoryPathSearchKeywordsStore.reset()
            activeHistoryPathSearchObjectTypesStore.reset()
            activeHistoryPathSearchReadOnlyKeywordsStore.reset()
            activeHistoryPathSearchFieldValuesStore.reset()
            maplibreMap.clearClustersFromMap()
            showObjectHistoryPathStore.update(false)
            maplibreMap.setPathToolHighlightOverrides(emptySet())
            analyticsSidePageStore.reset()
            analyticsSideSubPageStore.reset()
            objectHistoryResultsStore.reset()
            maplibreMap.removeAllActiveObjectOverrides()
        }
        AnalyticPage.Stats
    }

    init {
        data handledBy prepare
        router.data handledBy analyticsPageListener
    }
}

class AnalyticsSidePageStore : RootStore<AnalyticSidePage?>(
    initialData = AnalyticSidePage.PathsSearch,
    job = Job(),
) {
    val reset = handle { AnalyticSidePage.PathsSearch }
}

class AnalyticsSideSubPageStore : RootStore<AnalyticSideSubPage?>(
    initialData = null,
    job = Job(),
) {
    val history = history(
        capacity = 100,
        initialEntries = listOf(null),
    )

    val back = handle { _ ->
        if (history.current.isNotEmpty()) {
            val previous = history.back()
            previous
        } else {
            history.push(null)
            null
        }
    }

    val reset = handle { null }
}

fun RenderContext.pageAnalyticsDashboard() {
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()

    // Filter
    val analyticsTimeFilterStore: AnalyticsTimeFilterStore by koinCtx.inject()

    // StatsTool
    val workspaceAnalyticsDashboardsStore: WorkspaceAnalyticsDashboardsStore by koinCtx.inject()
    val selectedAnalyticsDashboardStore: SelectedAnalyticsDashboardStore by koinCtx.inject()

    // PathToolSearch
    val pathToolSearchInputStore: PathToolSearchInputStore by koinCtx.inject()
    val pathSearchResultsStore: PathSearchResultsStore by koinCtx.inject()
    val displayedPathObjectResultsStore: DisplayedPathObjectResultsStore by koinCtx.inject()
    val pathActivehighlightedObjectMarkersStore: PathActiveHighlightedObjectMarkersStore by koinCtx.inject()
    val activeHistoryPathSearchKeywordsStore: ActiveHistoryPathSearchKeywordsStore by koinCtx.inject()
    val activeHistoryPathSearchObjectTypesStore: ActiveHistoryPathSearchObjectTypesStore by koinCtx.inject()
    val activeHistoryPathSearchReadOnlyKeywordsStore: ActiveHistoryPathSearchReadOnlyKeywordsStore by koinCtx.inject()
    val activeHistoryPathSearchFieldValuesStore: ActiveHistoryPathSearchFieldValuesStore by koinCtx.inject()
    val suggestedTagsContextStore: SuggestedTagsContextStore by koinCtx.inject()
    val searchDistanceCalculationStore: SearchDistanceCalculationStore by koinCtx.inject()
    val mapLayerMetaDataListStore: MapLayerMetadataListStore by koinCtx.inject()
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()

    // Main navigation
    val analyticsPageStore: AnalyticsPageStore by koinCtx.inject()
    // Sidebar navigation
    val analyticsSidePageStore: AnalyticsSidePageStore by koinCtx.inject()
    val analyticsSideSubPageStore: AnalyticsSideSubPageStore by koinCtx.inject()

    analyticsPageStore.initialize()

    combine(analyticsPageStore.data, analyticsSidePageStore.data) { p, s ->
        Pair(p, s)
    }.render { (analyticPage, sidePage) ->
        // Main grid
        div("grid w-full h-full fixed inset-0 z-[1035] gap-1 sm:gap-3 grid-cols-[minmax(200px,_auto)_minmax(0px,_2fr)_minmax(0px,_2fr)_minmax(0px,_2fr)] grid-rows-[auto_auto_1fr]") {

            if (analyticPage?.mapInteraction == false) {
                // hide map completely for some pages
                className("bg-gray-200 pointer-events-auto")
            } else {
                // show map and keep it interactive
                className("pointer-events-none")
            }

            // MAIN HEADER
            div("flex flex-row w-full items-center justify-between py-0.5 pl-0.5 pr-3 bg-formationWhite pointer-events-auto") {
                className("order-1 col-span-4")
                // Title
                div("flex flex-row w-max items-center justify-center mr-3") {
                    // Logo
                    img("h-10 md:h-15 p-2 mx-3") {
                        src("assets/images/logo-blue_1.svg")
                    }
                    // InsightsSuite
                    div("flex flex-row flex-wrap items-start justify-start") {
                        span("text-xs md:text-xl font-bold") { +"Insights" }
                        span("text-xs md:text-xl font-light") { +"Suite" }
                    }
                }

                // Box for AnalyticsPage navigation buttons
                div("flex flex-row grow text-xs md:text-base overflow-x-auto") {
                    inlineStyle(
                        """
                        -ms-overflow-style: none;  /* IE and Edge */
                        scrollbar-width: none;  /* Firefox */
                    """.trimIndent(),
                    )
                    AnalyticPage.entries.forEach { page ->
                        dashboardMainPageButton(
                            value = page,
                            valueHandlers = listOf(analyticsPageStore.update),
                            icon = page.icon,
                            title = flowOf(page.name),
                            active = analyticPage == page,
                        )
                    }
                }
                // Back button
                twLargeIconButtonNeutralRounded(
                    icon = FormationUIIcons.Close,
                ) {
                    clicks handledBy {
                        pathActivehighlightedObjectMarkersStore.initialize(Unit)
                        analyticsTimeFilterStore.reset(Unit)
                        routerStore.goToMap(Unit)
                    }
                }
            }
            // SIDEBAR
            // Sidebar grid container
            div("flex flex-col bg-formationWhite items-stretch justify-start grow-1 shrink-1 basis-min p-1 ml-1 sm:ml-3 mb-1 sm:mb-3 rounded-xl pointer-events-auto overflow-y-auto") {
                className("order-2 row-start-3")
                // Sidebar content navigation via AnalyticPage and AnalyticSidePage
                when (analyticPage) {
                    AnalyticPage.Stats -> {
                        twContentScrollBox {
                            combine(
                                workspaceAnalyticsDashboardsStore.data,
                                selectedAnalyticsDashboardStore.data,
                            ) { ds, d ->
                                Pair(ds, d)
                            }.render(into = this) { (dashboards, selected) ->
                                twColOfStretch("my-3 px-1") {
                                    // Dashboards tabs
                                    dashboards?.forEach { dashboard ->
                                        twPrimaryWhenActiveButton(
                                            fallbackTextAsFlow = flowOf(dashboard.title),
                                            icon = FormationUIIcons.BarChartAlt,
                                            activeFlow = flowOf(selected == dashboard),
                                        ) {
                                            clicks handledBy {
                                                selectedAnalyticsDashboardStore.update(dashboard)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    AnalyticPage.Paths -> {
                        twColOfNoGap("h-full w-full max-w-100") {
                            div("w-full px-3") {
                                genericBigButtonSwitch(
                                    analyticsSidePageStore,
                                    listOf(
                                        BigButtonOption(
                                            title = flowOf("Assets"),
                                            value = AnalyticSidePage.PathsSearch,
                                            selectHandler = analyticsSidePageStore.update,
                                        ),
                                        BigButtonOption(
                                            title = displayedPathObjectResultsStore.data.map { "History (${it.size})" },
                                            value = AnalyticSidePage.PathsHistory,
                                            selectHandler = analyticsSidePageStore.update,
                                            disabled = displayedPathObjectResultsStore.data.map { it.isNullOrEmpty() },
                                        ),
                                    ),
                                )
                            }
                            if (sidePage in analyticPage.sidePages) {
                                when (sidePage) {
                                    AnalyticSidePage.PathsSearch -> {
                                        analyticsSideSubPageStore.data.render { sideSubPage ->
                                            when (sideSubPage) {
                                                AnalyticSideSubPage.PathsSearchTags -> {
                                                    twColOfStretch("px-2") {
                                                        twPageHeaderBack(
                                                            routeBack = false,
                                                            additionalBackButtonHandler = handleFunctions {
                                                                suggestedTagsContextStore.resetPrefix(Unit)
                                                                analyticsSideSubPageStore.update(null)
                                                            },
                                                        )
                                                    }
                                                    twContentScrollBox {
                                                        tagManagement(type = KeywordTagType.AnalyticsPathViewTag)
                                                    }
                                                }

                                                AnalyticSideSubPage.PathsSearchFieldValue -> {
                                                    cardManageFieldValueTag(tagType = KeywordTagType.AnalyticsPathViewTag)
                                                }

                                                else -> {
                                                    twColOfStretch("px-2 pb-2") {
                                                        // SEARCH INPUT FIELD & TAG BUTTON
                                                        twRowOfJustifyBetween {
                                                            searchInput(
                                                                id = "pathtool-search-input",
                                                                pathToolSearchInputStore,
                                                            )
                                                            searchTagButton(
                                                                navValue = AnalyticSideSubPage.PathsSearchTags,
                                                                navHandlers = listOf(analyticsSideSubPageStore.update),
                                                            )
                                                        }
                                                        // COORDINATE BUTTONS
                                                        twCoordinatesButtons(pathToolSearchInputStore)
                                                        // SELECTED TAGS
                                                        selectedSearchTagsList(
                                                            activeSearchKeywordsStore = activeHistoryPathSearchKeywordsStore,
                                                            activeSearchObjectTypesStore = activeHistoryPathSearchObjectTypesStore,
                                                            activeSearchReadOnlyKeywordsStore = activeHistoryPathSearchReadOnlyKeywordsStore,
                                                            activeSearchFieldValuesStore = activeHistoryPathSearchFieldValuesStore,
                                                            keywordTagType = KeywordTagType.AnalyticsPathViewTag,
                                                        )
                                                        // distance calculation switch
                                                        distanceCalcSwitch(
                                                            distanceToSelectStore = searchDistanceCalculationStore,
                                                            switchHandler = searchDistanceCalculationStore.select,
                                                        )
                                                    }
                                                    twContentScrollBox {
                                                        // SEARCH RESULTS
                                                        pathToolSearchResultsList(
                                                            results = pathSearchResultsStore.data.mapNotNull { it },
                                                            selectedIds = displayedPathObjectResultsStore.data.mapNotNull { it.keys.toList() },
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    AnalyticSidePage.PathsHistory -> {
                                        displayedPathObjectResultsStore.data.render { historyObjects ->
                                            if (historyObjects.isEmpty()) {
                                                analyticsSidePageStore.update(AnalyticSidePage.PathsSearch)
                                            } else {
                                                twContentScrollBox {
                                                    objectHistoryList(
                                                        results = displayedPathObjectResultsStore.data,
                                                        multiList = true,
                                                        interactive = true,
                                                    )
                                                }
                                            }
                                        }
                                    }

                                    else -> {
                                        globalSearchHeader(searchInputFieldStore = pathToolSearchInputStore)
                                    }
                                }
                            }
                        }
                    }

                    AnalyticPage.Heatmaps -> {
                        div("flex flex-col items-center h-full w-full min-w-100 mt-3 px-3") {
                            // list of heatmaplayers here
                            mapLayerList(mapLayerMetaDataListStore.data.map { it.filter { it.layerType == LayerType.Heatmap } })
                        }
                    }

                    AnalyticPage.Exports -> {
                        twContentScrollBox {
                            div("flex flex-col items-stretch justify-start h-full w-full mt-3 gap-2") {
                                featureFlagStore.data.render { featureflags ->
                                    if (featureflags[Features.AllowZoneHistoryExport] == true) {
                                        twPrimaryWhenActiveButton(
                                            fallbackTextAsFlow = flowOf("Zone History Export"), // TODO translate
                                            icon = FormationIcons.History,
                                            activeFlow = flowOf(sidePage == AnalyticSidePage.ExportZoneHistory),
                                        ) {
                                            clicks handledBy {
                                                analyticsSidePageStore.update(AnalyticSidePage.ExportZoneHistory)
                                            }
                                        }
                                    }
                                    if (featureflags[Features.AllowObjectImportExport] == true) {
                                        twPrimaryWhenActiveButton(
                                            fallbackTextAsFlow = flowOf("Object Import/Export"), // TODO translate
                                            icon = FormationUIIcons.Select,
                                            activeFlow = flowOf(sidePage == AnalyticSidePage.ObjectImportExport),
                                        ) {
                                            clicks handledBy {
                                                analyticsSidePageStore.update(AnalyticSidePage.ObjectImportExport)
                                            }
                                        }
                                    }
                                }
                                // Add more Buttons here for other Export Tools
                            }
                        }
                    }

                    else -> {}
                }
            }

            // Filterbar
            div("flex flex-row grow-1 shrink-1 basis-max items-center justify-start bg-formationWhite p-2 flex-wrap rounded-xl pointer-events-auto") {
                className("order-3 row-start-2 col-span-4")
                className(
                    if (analyticPage?.mapInteraction != true) {
                        "mx-1 sm:mx-3"
                    } else {
                        "ml-1 sm:ml-3 mr-15"
                    },
                )
                timeFilter(startPickerId = "pickerStartInput", endPickerId = "pickerEndInput")
                if (analyticPage == AnalyticPage.Stats) {
                    dashboardIconButton(
                        title = translation[AnalyticsDashboardTexts.REFRESH_ALL_DATA],
                        icon = FormationUIIcons.Undo,
                        value = Unit,
                        tracker = selectedAnalyticsDashboardStore.refreshTracker,
                        clickHandlers = listOf(selectedAnalyticsDashboardStore.computeAllElementData),
                    )
                }
                when (analyticPage) {
                    AnalyticPage.Heatmaps -> {}
                    else -> {
                        textFilter()
                    }
                }
            }

            // Dashboard scrollable content
            div("flex grow-1 shrink-1 bg-formationWhite overflow-y-auto p-1 rounded-xl") {
                className("order-4 row-start-3 col-start-2 col-span-3")
                className(
                    if (analyticPage?.mapInteraction != true) {
                        "mr-1 sm:mr-3 mb-1 sm:mb-3 pointer-events-auto"
                    } else {
                        "bg-transparent mb-13 mr-15 pointer-events-none"
                    },
                )
                when (analyticPage) {
                    AnalyticPage.Stats -> {
                        div("flex h-max items-start justify-start flex-wrap") {
                            selectedAnalyticsDashboardStore.computedAnalyticsStore.data.render { elementMap ->
                                elementMap.forEach { (element, elementData) ->
                                    dashboardElement(element, elementData)
                                }
                            }
//                                pieChart((0..<72).map { 72-it  }.map { it.toDouble() }, (0..<72).map { 72-it  }.map { it.toString() })
//                                horizontalBarChart((0..<72).map { 72-it  }.map { it.toDouble() }, (0..<72).map { 72-it  }.map { it.toString() })
//                                verticalBarChart((0..<72).map { 72-it  }.map { it.toDouble() }, (0..<72).map { 72-it  }.map { it.toString() })
//                                lineChart(listOf(1.0,2.0,1.0,3.0,1.0), listOf("11","22","11","3","1"))
                        }
                    }

                    AnalyticPage.Paths, AnalyticPage.Heatmaps -> {
                        /* No content here, only direct interaction with map underneath */
                    }

                    AnalyticPage.Exports -> {
                        featureFlagStore.data.render { featureflags ->
                            if (sidePage in analyticPage.sidePages) {
                                when (sidePage) {
                                    AnalyticSidePage.ExportZoneHistory -> {
                                        if (featureflags[Features.AllowZoneHistoryExport] == true) {
                                            twContentScrollBox {
                                                zoneHistory()
                                            }
                                        }
                                    }

                                    AnalyticSidePage.ObjectImportExport -> {
                                        if (featureflags[Features.AllowObjectImportExport] == true) {
                                            twContentScrollBox {
                                                objectImportExport()
                                            }
                                        }
                                    }

                                    else -> {}
                                }
                            }
                        }
                    }

                    else -> {}
                }
            }
        }
    }
}
