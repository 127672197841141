package data.objects.views.meeting

import apiclient.geoobjects.Content
import apiclient.geoobjects.GeoObjectDetails
import auth.ApiUserStore
import data.objects.ActiveObjectStore
import data.users.UserListStore
import data.users.views.initials
import dev.fritz2.components.compat.div
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import localization.TL
import localization.Translation
import localization.translate
import mainmenu.RouterStore
import model.L
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.twIconLarge
import twcomponents.twPageHeaderBack
import twcomponents.twTitle
import webcomponents.baseLayout

fun RenderContext.cardAttendees() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val createdBy = activeObjectStore.map(GeoObjectDetails.L.createdBy)
    val attendees = activeObjectStore.map(GeoObjectDetails.L.attendees)
    val userListStore: UserListStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val currentUserId = apiUserStore.current.userId

    userListStore.fetchGroupMembers(null)
    val attendeeProfiles = userListStore.data.combine(attendees.data) { groupMembers, attendeeList ->
        groupMembers?.filter { member -> (attendeeList?.map { it.userId } ?: listOf()).contains(member.userId) }
            ?.associate { profile -> profile.userId to Pair(profile, attendeeList?.first { it.userId == profile.userId }?.meetingInvitationStatus) }
    }

    baseLayout(
        header = {
            attendees.data.render(into = this) { att ->
                val oldStatus = activeObjectStore.initialObject.attendees?.firstOrNull { it.userId == currentUserId }?.meetingInvitationStatus
                val currentStatus = att?.firstOrNull { it.userId == currentUserId }?.meetingInvitationStatus

                twPageHeaderBack(
                    backButtonIcon = if (oldStatus != currentStatus) {
                        FormationUIIcons.Save
                    } else {
                        FormationUIIcons.ArrowLeft
                    },
                    backButtonTitle = if (oldStatus != currentStatus) {
                        TL.General.SAVE
                    } else TL.General.BACK,
                    additionalBackButtonHandler = if (oldStatus != currentStatus) {
                        activeObjectStore.changeMeetingStatus
                    } else null,
                    rightAlignedBlock = if (oldStatus != currentStatus) {
                        { twIconLarge(icon = FormationUIIcons.ArrowLeft) }
                    } else null,
                ) {
                    twTitle(icon = FormationIcons.UserGroup) {
                        translate(TL.CardAttendees.CARD_TITLE)
                    }
                }
            }
        },
        content = {
            div(
                {
                    width { full }
                    height { maxContent }
                    overflow { auto }
                },
            ) {
                stackUp(
                    {
                        width { full }
                        height { maxContent }
                        justifyContent { center }
                        alignItems { stretch }
                        paddings { horizontal { normal } }
                    },
                ) {
                    spacing { small }
                    items {
                        attendeeProfiles.render(into = this) { profileMap ->
                            // BUTTON IF CURRENT USER IS ATTENDEE
                            profileMap?.get(currentUserId)?.let { (profile, status) ->
                                console.log("Profile:", profile.firstName, profile.profilePhoto?.href)
                                val isCreator = createdBy.current == currentUserId
                                attendeeInfoButton(
                                    invitationStatus = status,
                                    profilePictureLink = (profile.profilePhoto?.thumbNail as? Content.Image)?.href ?: profile.profilePhoto?.href,
                                    icon = { FormationIcons.UserAlt.icon },
                                    initials = initials(profile.firstName, profile.lastName),
                                    title = flowOf(
                                        "${profile.firstName} ${profile.lastName}${
                                            when {
                                                isCreator -> {
                                                    " (${
                                                        translation.getString(TL.General.YOU, mapOf("case" to "nominative"))
                                                            .lowercase()
                                                            .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                                                    }, ${translation.getString(TL.CardUserSelect.CREATOR)})"
                                                }

                                                else -> {
                                                    " (${
                                                        translation.getString(TL.General.YOU, mapOf("case" to "nominative"))
                                                            .lowercase()
                                                            .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                                                    })"
                                                }
                                            }
                                        }",
                                    ),
                                    subtitle = flowOf(profile.jobTitle),
                                )
                                if (profile.userId == currentUserId) {
                                    invitationStatusBar(activeStatus = status)
                                }
                            }
                            // BUTONS FOR OTHER USERS
                            profileMap?.filterKeys { it != currentUserId }?.values?.forEach { (profile, status) ->
                                console.log("Profile:", profile.firstName, profile.profilePhoto?.href)
                                val isCreator = createdBy.current == profile.userId
                                val isYou = currentUserId == profile.userId
                                attendeeInfoButton(
                                    invitationStatus = status,
                                    profilePictureLink = profile.profilePhoto?.href,
                                    icon = { FormationIcons.UserAlt.icon },
                                    initials = initials(profile.firstName, profile.lastName),
                                    title = flowOf(
                                        "${profile.firstName} ${profile.lastName}${
                                            when {
                                                isCreator && isYou -> {
                                                    " (${
                                                        translation.getString(TL.General.YOU, mapOf("case" to "nominative"))
                                                            .lowercase().replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                                                    }, ${translation.getString(TL.CardUserSelect.CREATOR)})"
                                                }

                                                isCreator -> " (${translation.getString(TL.CardUserSelect.CREATOR)})"
                                                isYou -> {
                                                    " (${
                                                        translation.getString(TL.General.YOU, mapOf("case" to "nominative"))
                                                            .lowercase().replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                                                    })"
                                                }

                                                else -> ""
                                            }
                                        }",
                                    ),
                                    subtitle = flowOf(profile.jobTitle),
                                )
                            }
                        }
                    }
                }
            }
        },
//        footer = {
//            attendees.data.render(into = this) { att ->
//                val oldStatus = activeObjectStore.initialObject.attendees?.firstOrNull { it.userId == currentUserId }?.meetingInvitationStatus
//                val currentStatus = att?.firstOrNull { it.userId == currentUserId }?.meetingInvitationStatus
//                oneButtonFooter(
//                    title = if (oldStatus != currentStatus) translation[TL.General.UPDATE] else translation[TL.General.BACK],
//                    styleParams = primaryButtonStyleParams,
//                    value = Unit,
//                    clickHandlers = if (oldStatus != currentStatus) listOf(
//                        activeObjectStore.changeMeetingStatus,
//                        routerStore.back
//                    ) else listOf(routerStore.back),
//                )
//            }
//        }
    )
}
