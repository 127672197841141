package search

import apiclient.customfields.FieldValue
import apiclient.geoobjects.ObjectType
import com.tryformation.localization.Translatable
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.core.autocomplete
import dev.fritz2.core.disabled
import dev.fritz2.core.id
import dev.fritz2.core.placeholder
import dev.fritz2.core.type
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import koin.koinCtx
import koin.withKoin
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import localization.getString
import localization.getTranslationFlow
import localization.translate
import mainmenu.RouterStore
import maplibreGL.MaplibreMap
import maplibreGL.renderer.TemporaryMarkerRender
import model.KeywordTag
import objectrouting.navigationCoordinateButton
import objectrouting.navigationCoordinateButtonContent
import objectrouting.parseCoordinates
import org.w3c.dom.Element
import search.global.ActiveSearchFieldValuesStore
import search.global.ActiveSearchKeywordsStore
import search.global.ActiveSearchObjectTypesStore
import search.global.ActiveSearchReadOnlyKeywordsStore
import search.searchFilterStores.ActiveFieldValuesStore
import search.searchFilterStores.ActiveKeywordsStore
import search.searchFilterStores.ActiveObjectTypesStore
import search.searchFilterStores.ActiveReadOnlyKeywordsStore
import theme.FormationIcons
import theme.IconEnum
import twcomponents.twColOfNoGap
import twcomponents.twIconMedium
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twLeftAlignedRow
import twcomponents.twMainTitle
import twcomponents.twMediumIconButtonNeutralRounded
import twcomponents.twPageHeaderClose
import twcomponents.twRevertButton
import twcomponents.twRightAlignedButtonRow
import twcomponents.twRowOf
import twcomponents.twRowOfJustifyBetween
import twcomponents.twTooltip
import utils.merge
import utils.toKeyWordTagsList
import webcomponents.KeywordTagActionType
import webcomponents.KeywordTagType
import webcomponents.keywordTagList

fun RenderContext.searchInput(id: String, valueStore: Store<String>, disabled: Boolean = false) {
    val translation: Translation by koinCtx.inject()
    twInputField(valueStore) {
        twLeftAlignedRow {
            twIconMedium(icon = FormationIcons.Search)
        }
        twInputTextField {
            id(id)
            type("search")
            disabled(disabled)
            autocomplete("off")
            placeholder(translation[TL.Search.FIND_IT_FAST])
        }
        twRightAlignedButtonRow {
            valueStore.data.render { text ->
                if (text.isNotBlank() && !disabled) {
                    twRevertButton(valueStore, "")
                }
            }
        }
    }
}

fun <T> RenderContext.searchTagButton(
    navValue: T,
    navHandlers: List<SimpleHandler<T>>,
) {

    twMediumIconButtonNeutralRounded(
        icon = FormationIcons.Settings,
    ) {
        navHandlers.forEach { handler ->
            clicks.map { navValue } handledBy handler
        }
    }.twTooltip(positioning = PlacementValues.bottomEnd) {
        p("text-xs font-bold") {
            translate(TL.Search.MANAGE_SEARCH_FILTERS)
        }
    }
}

fun RenderContext.selectedSearchTagsList(
    activeSearchKeywordsStore: Store<List<String>>,
    activeSearchObjectTypesStore: Store<List<ObjectType>>,
    activeSearchReadOnlyKeywordsStore: Store<List<KeywordTag>>,
    activeSearchFieldValuesStore: Store<List<FieldValue>>,
    keywordTagType: KeywordTagType = KeywordTagType.SearchTag,
) {
    // optional Tags
    combine(
        activeSearchObjectTypesStore.data,
        activeSearchKeywordsStore.data,
        activeSearchReadOnlyKeywordsStore.data,
        activeSearchFieldValuesStore.data,
    ) { objTypes, keywords, otherTags, fieldValues ->
        objTypes.map { it.name }.toKeyWordTagsList(KeywordTagActionType.RemoveReadOnly) +
            otherTags.map { it.copy(actionType = KeywordTagActionType.RemoveReadOnly) } +
            keywords.toKeyWordTagsList(KeywordTagActionType.Remove) +
            fieldValues.toKeyWordTagsList(KeywordTagActionType.RemoveFieldValue)
    }.render { combinedKeywords ->
        if (combinedKeywords.isNotEmpty()) {
            div {
                keywordTagList(
                    keywords = flowOf(combinedKeywords),
                    keywordTagType = keywordTagType,
                )
            }
        }
    }
}

fun RenderContext.twCoordinatesButtons(inputStore: Store<String>) {
    val maplibreMap: MaplibreMap by koinCtx.inject()
    val temporaryMarkerRender: TemporaryMarkerRender by koinCtx.inject()

    inputStore.data.render { searchQuery ->
        val parsedCoordinate = parseCoordinates(searchQuery)

        parsedCoordinate?.let {
            twRowOf("pt-1 pr-4") {
                navigationCoordinateButton {
                    navigationCoordinateButtonContent(
                        title = flowOf("Go to: ").merge(parsedCoordinate.pointSource.getTranslationFlow()),
                        icon = FormationIcons.Globe,
                        subtitle = parsedCoordinate.humanReadable,
                        latLon = parsedCoordinate.latLon,
                    )
                    clicks handledBy {
                        maplibreMap.panTo(parsedCoordinate.latLon, 1000)
                        temporaryMarkerRender.insertTemporaryMarker(
                            parsedCoordinate.pointSource.getString() + ": " + parsedCoordinate.humanReadable,
                            parsedCoordinate.latLon,
                        )
                    }
                }
            }
        }
    }
}

fun RenderContext.twSearchHeaderWrapper(
    searchInputFieldId: String,
    searchInputFieldStore: Store<String>,
    activeObjectTypesStore: ActiveObjectTypesStore,
    activeKeywordsStore: ActiveKeywordsStore,
    activeReadOnlyKeywordsStore: ActiveReadOnlyKeywordsStore,
    activeFieldValuesStore: ActiveFieldValuesStore,
    keyWordTagType: KeywordTagType,
    withCoordinateDetection: Boolean = false,
    headerBlock: HtmlTag<Element>.() -> Unit
) {
    withKoin {
        val routerStore: RouterStore = get()

        twColOfNoGap("w-full gap-1") {
            // HEADER
            headerBlock.invoke(this)

            // SEARCH INPUT FIELD
            twRowOfJustifyBetween {
                searchInput(searchInputFieldId, searchInputFieldStore)
                // MANAGE TAGS BUTTON
                searchTagButton(
                    navValue = mapOf("change" to "tags"),
                    navHandlers = listOf(routerStore.addOrReplaceRoute),
                )
            }

            // OPTIONAL COORDINATE DETECTION
            if (withCoordinateDetection) {
                twCoordinatesButtons(searchInputFieldStore)
            }

            // ACTIVE SEARCH TAGS
            selectedSearchTagsList(
                activeSearchKeywordsStore = activeKeywordsStore,
                activeSearchObjectTypesStore = activeObjectTypesStore,
                activeSearchReadOnlyKeywordsStore = activeReadOnlyKeywordsStore,
                activeSearchFieldValuesStore = activeFieldValuesStore,
                keywordTagType = keyWordTagType,
            )
        }
    }
}

fun RenderContext.globalSearchHeader(
    title: Translatable? = null,
    icon: IconEnum? = null,
    searchInputFieldStore: Store<String>,
    withCoordinateDetection: Boolean = false
) {
    val activeSearchKeywordsStore: ActiveSearchKeywordsStore by koinCtx.inject()
    val activeSearchObjectTypesStore: ActiveSearchObjectTypesStore by koinCtx.inject()
    val activeSearchReadOnlyKeywordsStore: ActiveSearchReadOnlyKeywordsStore by koinCtx.inject()
    val activeSearchFieldValuesStore: ActiveSearchFieldValuesStore by koinCtx.inject()

    twSearchHeaderWrapper(
        searchInputFieldId = "",
        searchInputFieldStore = searchInputFieldStore,
        activeObjectTypesStore = activeSearchObjectTypesStore,
        activeKeywordsStore = activeSearchKeywordsStore,
        activeReadOnlyKeywordsStore = activeSearchReadOnlyKeywordsStore,
        activeFieldValuesStore = activeSearchFieldValuesStore,
        keyWordTagType = KeywordTagType.SearchTag,
        withCoordinateDetection = withCoordinateDetection,
    ) {
        twPageHeaderClose(
            backButtonToolTipPlacement = PlacementValues.left,
        ) {
            title?.let { twMainTitle(icon = icon) { translate(title) } }
        }
    }
}
