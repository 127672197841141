package auth

import apiclient.groups.GroupFeatureFlags
import apiclient.groups.featureFlags
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map


enum class Features {
    DisablePlusButton,
    DisableLocationSharing,
    DisablePhotoAttachments,
    EnableAdminDashboard,
    EnablePublicSignup,
    EnableAnonymousAccess,
    EnableSignUpButton,
    NewPasswordNeeded,
    AllowExperimentalFeatures,
    AllowQRVerification,
    AllowPolls,
    AllowCustomFields,
    AllowScanToX,
    AllowHeatMap,
    DisableHeatmapEditUI,
    AllowHistoryPaths,
    DisableClustering,
    AllowFictionalLanguages,
    AllowZoneEditing,
    AllowZoneHistoryExport,
    AllowObjectImportExport,
    EnableTrackingOnboarding,
    EnableDistanceMeasuringTool,
    AllowBitmapMarkerIcons,
    EnableRouteToObject,
    EnableBuildingEditor,
    DisableComments,
}

class FeatureFlagStore : RootStore<Map<Features, Boolean>>(
    initialData = Features.entries.associateWith { false },
    job = Job(),
) {

    val apiUserStore by koinCtx.inject<ApiUserStore>()
    private val workspacesStore by koinCtx.inject<WorkspacesStore>()

    val setGroupAndRoleFlags = handle { current ->
        val roles = apiUserStore.current.apiUser?.groups?.firstOrNull()?.roles
        current.toMutableMap().let { features ->
            // set group flags
            workspacesStore.current.let { groups ->
                groups.firstOrNull()?.featureFlags()?.let { flags ->
                    features[Features.EnableAnonymousAccess] = flags[GroupFeatureFlags.AllowAnonymousAccess] ?: false
                    features[Features.EnablePublicSignup] = flags[GroupFeatureFlags.AllowPublicSignup] ?: false
                    features[Features.DisableLocationSharing] = flags[GroupFeatureFlags.DisableLocationSharing] ?: false
                    features[Features.DisablePhotoAttachments] =
                        flags[GroupFeatureFlags.DisablePhotoAttachments] ?: false
                    features[Features.AllowExperimentalFeatures] =
                        flags[GroupFeatureFlags.AllowExperimentalFeatures] ?: false
                    features[Features.AllowQRVerification] = flags[GroupFeatureFlags.AllowQRVerification] ?: false
                    features[Features.AllowPolls] = flags[GroupFeatureFlags.AllowPolls] ?: false
                    features[Features.AllowCustomFields] = flags[GroupFeatureFlags.AllowCustomFields] ?: false
                    features[Features.AllowScanToX] = flags[GroupFeatureFlags.AllowScanToX] ?: false
                    features[Features.AllowHeatMap] = flags[GroupFeatureFlags.AllowHeatMap] ?: false
                    features[Features.DisableHeatmapEditUI] = flags[GroupFeatureFlags.DisableHeatmapEditUI] ?: false
                    features[Features.AllowHistoryPaths] = flags[GroupFeatureFlags.AllowHistoryPaths] ?: false
                    features[Features.DisableClustering] = flags[GroupFeatureFlags.DisableClustering] ?: false
                    features[Features.AllowFictionalLanguages] =
                        flags[GroupFeatureFlags.AllowFictionalLanguages] ?: false
                    features[Features.AllowZoneEditing] = flags[GroupFeatureFlags.AllowZoneEditing] ?: false
                    features[Features.AllowZoneHistoryExport] = flags[GroupFeatureFlags.AllowZoneHistoryExport] ?: false
                    features[Features.AllowObjectImportExport] =
                        flags[GroupFeatureFlags.AllowObjectImportExport] ?: false
                    features[Features.EnableTrackingOnboarding] =
                        flags[GroupFeatureFlags.EnableTrackingOnboarding] ?: false
                    features[Features.EnableDistanceMeasuringTool] =
                        flags[GroupFeatureFlags.EnableDistanceMeasuringTool] ?: false
                    features[Features.AllowBitmapMarkerIcons] = flags[GroupFeatureFlags.AllowBitmapMarkerIcons] ?: false
                    features[Features.EnableRouteToObject] = flags[GroupFeatureFlags.EnableRouteToObject] ?: false
                    features[Features.EnableBuildingEditor] = flags[GroupFeatureFlags.DisableBuildingEditor] != true
                    features[Features.DisableComments] = flags[GroupFeatureFlags.DisableComments] ?: false
                }
                val userProfileInActiveGroup = apiUserStore.current.apiUser?.groups?.firstOrNull()?.userProfile
                features[Features.NewPasswordNeeded] = userProfileInActiveGroup?.newPasswordNeeded ?: false
            }
            // set role flags (can override group flags!)
            features[Features.EnableAdminDashboard] =
                roles?.contains("group_admin") == true || roles?.contains("group_owner") == true
            if (roles?.contains("anonymous") == true) {
                features[Features.DisablePlusButton] = true
                features[Features.DisableLocationSharing] = true
                if (features[Features.EnablePublicSignup] == true) {
                    features[Features.EnableSignUpButton] = true
                }
            } else {
                features[Features.DisablePlusButton] = false
                features[Features.EnableSignUpButton] = false
            }
            features
        }
    }

    private val updatePasswordFlag = handle<Boolean?> { current, pwNeeded ->
        current.toMutableMap().let { features ->
            pwNeeded?.let { features[Features.NewPasswordNeeded] = it }
            features
        }
    }

    init {
        setGroupAndRoleFlags()
        apiUserStore.data.map { } handledBy setGroupAndRoleFlags
        apiUserStore.data.map { it.apiUser?.groups?.firstOrNull()?.userProfile?.newPasswordNeeded } handledBy updatePasswordFlag
        workspacesStore.data.map { } handledBy setGroupAndRoleFlags
    }
}
