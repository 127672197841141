package mainmenu.about

import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import data.users.settings.LocalSettingsStore
import dev.fritz2.core.RenderContext
import dev.fritz2.core.href
import dev.fritz2.core.src
import dev.fritz2.core.target
import generated.ServerConfig
import koin.koinCtx
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.Translation
import localization.translate
import mainmenu.Pages
import mainmenu.mainMenuButton
import theme.FormationIcons
import twcomponents.twColOf
import twcomponents.twColOfCenter
import twcomponents.twFlatBoxRowCenter
import twcomponents.twFlatIconBox
import twcomponents.twFlatIconButton
import twcomponents.twIconMedium
import twcomponents.twMainTitle
import twcomponents.twPageHeaderBack
import twcomponents.twRowOfNoGap
import utils.generateVersionCodeFromTimeStamp
import webcomponents.baseLayout
import webcomponents.cardTitle
import webcomponents.twContentScrollBox

fun RenderContext.pageAbout() {

    val apiUserStore: ApiUserStore by koinCtx.inject()
    val localSettingsStore: LocalSettingsStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()

    val isNotAnonymous = apiUserStore.current.apiUser?.isAnonymous == false

    baseLayout(
        header = {
            twPageHeaderBack {
                twMainTitle {
                    translate(TL.MainMenu.ABOUT)
                }
            }
        },
        content = {
            twContentScrollBox {
                twColOfCenter {
                    className("my-4")
                    // Logo
                    img("flex flex-grow min-h-20 max-h-36 my-4 mx-4 object-contain") {
                        val ws = apiUserStore.current.apiUser?.workspaceName
                        when {
                            ws?.contains("hoffest") == true -> src("assets/images/LOGO_Formation+Hoffest.png")
                            else -> src("assets/images/logo-blue_1.svg")
                        }
                    }
                    cardTitle(currentWorkspaceStore.data.mapNotNull { it?.name })
                }

                // About formation Text
//                p { +"About Formation text..." }

                twColOf {
                    // FEEDBACK
                    mainMenuButton(
                        icon = { FormationIcons.Feedback.icon },
                        title = TL.MainMenu.FEEDBACK,
                        subtitle = translation[TL.MainMenu.FEEDBACK_SUBTITLE],
                        destinations = listOf(Pages.Feedback),
                    )
                    if (isNotAnonymous) {
                        // TERMS & CONDITIONS
                        mainMenuButton(
                            icon = { FormationIcons.Rules.icon },
                            title = TL.MainMenu.TC,
                            subtitle = translation[TL.MainMenu.TC_SUBTITLE],
                            destinations = listOf(Pages.ViewTerms),
                        )
                    }
                    // BACK TO WEBSITE
                    a {
                        href("https://tryformation.com/")
                        target("_blank")
                        mainMenuButton(
                            icon = { FormationIcons.Website.icon },
                            title = TL.MainMenu.WEBSITE_LINK,
                            subtitle = translation[TL.MainMenu.WEBSITE_LINK_SUBTITLE],
                        )
                    }
                }

                div("flex flex-grow") {}

                // App version
                // Webapp build number
                twRowOfNoGap {
                    className("mt-2 -mb-2 -mx-2")
                    twFlatIconBox {
                        twIconMedium(FormationIcons.InformationAlt)
                    }
                    twFlatBoxRowCenter {
                        p("text-xs") {
                            +"FORMATION  App build_${generateVersionCodeFromTimeStamp(ServerConfig.buildTimestamp)}.0"
                        }
                    }
                    // Reset Cookies & Disclaimer
                    twFlatIconButton(
                        icon = FormationIcons.Cookie,
                        disabled = localSettingsStore.data.map { settings -> !settings.acceptCookies && !settings.readDisclaimer },
                    ) {
                        p("text-xs") { +"Delete Cookies" }
                        clicks handledBy localSettingsStore.reset
                    }
                }
            }
        },
    )
}
