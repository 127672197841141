package data.objects.views

import apiclient.geoobjects.Content
import apiclient.geoobjects.ObjectType
import data.ObjectAndUserHandler
import data.objects.ActiveObjectStore
import data.users.UserListStore
import data.users.views.initials
import dev.fritz2.components.compat.button
import dev.fritz2.components.flexBox
import dev.fritz2.components.lineUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.cancel
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import localization.TL
import localization.translate
import mainmenu.RouterStore
import map.Cards
import map.MapStateStore
import maplibreGL.Cluster
import maplibreGL.ClusterProperties
import maplibreGL.MapMarker
import maplibreGL.MaplibreMap
import maplibreGL.toLatLon
import search.listEntryBox
import search.subtitleBox
import search.subtitleInfoText
import search.titleBox
import search.titleSizedText
import search.titleSubtitleStack
import svgmarker.defaultIconBoxSizeSearch
import svgmarker.defaultMarkerSizeSearch
import svgmarker.makeClusterMarkerSvg
import svgmarker.renderSvgIcon
import svgmarker.userInitialsIconSizeSearch
import twcomponents.twColOfNoGap
import twcomponents.twPageHeaderClose
import twcomponents.twRowOf
import twcomponents.twSubtitle
import twcomponents.twTitle
import utils.getName
import webcomponents.baseLayout
import webcomponents.twContentScrollBox
import webcomponents.userOrStateIcon

class CurrentClusterStore : RootStore<Cluster<MapMarker, ClusterProperties>?>(
    initialData = null,
    job = Job(),
) {

    private val objectAndUserHandler: ObjectAndUserHandler by koinCtx.inject()
    private val maplibreMap: MaplibreMap by koinCtx.inject()
    private val routerStore: RouterStore by koinCtx.inject()
    private val mapStateStore: MapStateStore by koinCtx.inject()
    private val activeObjectStore: ActiveObjectStore by koinCtx.inject()

    val reset = handle { null }

    val onObjectClick = handle<MapMarker> { current, marker ->
        reset()
        when (marker.objectType) {
            ObjectType.UserMarker, ObjectType.CurrentUserMarker -> {
                objectAndUserHandler.fetchAndShowUser(marker.id)
            }

            else -> objectAndUserHandler.fetchAndShowObject(marker.id)
        }
        current
    }

    val onClusterClick = handle<Cluster<MapMarker, ClusterProperties>> { _, cluster ->
        console.log("Cluster click")
        routerStore.validateInternalRoute(Cards.ClusterPrev.route)
        coroutineScope {
            launch {
                routerStore.data.combine(mapStateStore.data) { r, _ -> r }.collect { route ->
                    if (route["card"] == Cards.ClusterPrev.name && maplibreMap.isNotMoving()) {
                        maplibreMap.panTo(cluster.center.toLatLon())
                        routerStore.addToMapClickListener()
                        maplibreMap.removeActiveObjectOverride(activeObjectStore.current.id)
                        activeObjectStore.resetStore()
                        cancel()
                    }
                }
            }
        }
        cluster
    }

    init {
        // make sure cluster card is not visible if there is no cluster is in this store
        if (current == null && routerStore.current["card"] == Cards.ClusterPrev.name) {
            console.log("** EMPTY CLUSTER CARD")
            routerStore.goToMap()
        }
    }
}

fun RenderContext.cardClusterPreview() {

    val currentClusterStore: CurrentClusterStore by koinCtx.inject()
    val userListStore: UserListStore by koinCtx.inject()
    currentClusterStore.data.render { cluster ->
        if (cluster != null) {
            baseLayout(
                header = {
                    twPageHeaderClose(
                        additionalBackButtonHandler = currentClusterStore.reset,
                    ) {
                        twRowOf {
                            div("flex flex-grow-0 flex-shrink-0 items-center justify-center", id = "icon-box") {
                                className("basis-[${defaultMarkerSizeSearch}px] w-[${defaultMarkerSizeSearch}px] h-[${defaultMarkerSizeSearch}px]")
                                svg {}.domNode.apply {
                                    outerHTML = makeClusterMarkerSvg(
                                        count = 0,
                                        props = cluster.properties,
                                        withShadow = false,
                                        cursorPointer = false,
                                    )
                                }
                            }
                            twColOfNoGap {
                                twTitle {
                                    translate(TL.CardClusterPrev.CLUSTER_PREVIEW)
                                }
                                twSubtitle {
                                    translate(TL.CardClusterPrev.OBJECTS_AMOUNT, mapOf("objects" to cluster.pointCount))
                                }
                            }
                        }
                    }
                },
                content = {
                    twContentScrollBox {
                        cluster.features.forEach { marker ->
                            val svgIconOptions = marker.svgIconOptions ?: return@forEach
                            lineUp(
                                {
                                    margins {
                                        top { small }
                                    }
                                },
                            ) {
                                spacing { small }
                                items {
                                    button(
                                        {
                                            width { full }
                                            height { maxContent }
                                            overflow { visible }
                                        },
                                        id = "list-entry-button",
                                    ) {
                                        listEntryBox {
                                            // icon
                                            flexBox(
                                                {
                                                    flex {
                                                        grow { "0" }
                                                        shrink { "0" }
                                                        basis { "${defaultIconBoxSizeSearch}px" }
                                                    }
                                                    width { "${defaultIconBoxSizeSearch}px" }
                                                    height { "${defaultIconBoxSizeSearch}px" }
                                                    margins { right { smaller } }
                                                    justifyContent { center }
                                                    alignItems { center }
                                                },
                                                id = "icon-box",
                                            ) {
                                                when (marker.objectType) {
                                                    ObjectType.UserMarker -> {
                                                        // USER ICON ON CIRCLE
                                                        val user =
                                                            userListStore.current?.firstOrNull { marker.id == it.userId }
                                                        val picture = user?.profilePhoto
                                                        userOrStateIcon(
                                                            pixelSize = userInitialsIconSizeSearch,
                                                            shadow = true,
                                                            margins = {
                                                                vertical { "${((defaultIconBoxSizeSearch - userInitialsIconSizeSearch) / 2).toInt()}px" }
                                                                horizontal { "${((defaultIconBoxSizeSearch - userInitialsIconSizeSearch) / 2).toInt()}px" }
                                                            },
                                                            picture = (picture?.thumbNail as? Content.Image)?.href
                                                                ?: picture?.href,
                                                            initials = user?.let {
                                                                initials(
                                                                    user.firstName,
                                                                    user.lastName,
                                                                )
                                                            }
                                                                ?: initials(
                                                                    marker.title.split(" ")[0],
                                                                    marker.title.split(" ")[1],
                                                                ),
                                                        )
                                                    }

                                                    else -> renderSvgIcon(svgIconOptions.copy(size = defaultMarkerSizeSearch))
                                                }
                                            }
                                            // title
                                            titleSubtitleStack {
                                                titleBox(
                                                    titleText = {
                                                        titleSizedText {
                                                            +marker.title
                                                        }
                                                    },
                                                )
                                                subtitleBox(
                                                    subtitleText = {
                                                        subtitleInfoText {
                                                            +marker.objectType.getName()
                                                        }
                                                    },
                                                )
                                            }
                                        }

                                        clicks.map { marker } handledBy currentClusterStore.onObjectClick
                                    }
                                }
                            }
                        }
                    }
                },
            )
        }
    }
}
