package mainmenu

import dev.fritz2.components.compat.img
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.lineUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.invoke
import dev.fritz2.core.src
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import utils.horizontalSwipeListener
import webcomponents.baseLayout
import webcomponents.twContentScrollBox
import webcomponents.mainTitle
import webcomponents.twoButtonFooter

enum class OnBoardingPage {
    QuickTour {
        override val lastPage = false
    },
    Map {
        override val lastPage = false
    },
    NavigationBar {
        override val lastPage = false
    },
    Workspace {
        override val lastPage = true
    },
    ;

    abstract val lastPage: Boolean
}

class OnBoardingPageStore : RootStore<OnBoardingPage>(
    initialData = OnBoardingPage.QuickTour,
    job = Job(),
) {

    val routerStore: RouterStore by koinCtx.inject()

    val next = handle { current ->
        if (current.ordinal < OnBoardingPage.values().size - 1) {
            OnBoardingPage.values()[current.ordinal + 1]
        } else current
    }

    val previous = handle { current ->
        if (current.ordinal > 0) {
            OnBoardingPage.values()[current.ordinal - 1]
        } else current
    }

    val selectByIndex = handle<Int> { _, index ->
        OnBoardingPage.values()[index]
    }

    val reset = handle {
        OnBoardingPage.QuickTour
    }

    val quit = handle {
        routerStore.back()
        OnBoardingPage.QuickTour
    }
}

fun RenderContext.pageOnBoarding() {

    val translation: Translation by koinCtx.inject()
    val onBoardingPageStore: OnBoardingPageStore by koinCtx.inject()

    baseLayout(
        content = {
            onBoardingPageStore.data.render { page ->
                when (page) {
                    OnBoardingPage.QuickTour -> {
                        onBoardingPage(
                            title = translation[TL.OnBoarding.QUICK_TOUR],
                            text = translation[TL.OnBoarding.QUICK_TOUR_TEXT],
                            imgSrc = "assets/images/onBoarding/pocket_pic.jpg",
                            isFrontPage = true,
                        )
                    }

                    OnBoardingPage.Map -> {
                        twContentScrollBox {
                            className("mt-2")
                            onBoardingPage(
                                title = translation[TL.OnBoarding.THE_MAP],
                                text = translation[TL.OnBoarding.THE_MAP_TEXT],
                                imgSrc = "assets/images/onBoarding/01-the-map.gif",
                            )
                        }
                    }

                    OnBoardingPage.NavigationBar -> {
                        twContentScrollBox {
                            className("mt-2")
                            onBoardingPage(
                                title = translation[TL.OnBoarding.THE_NAVIGATION_BAR],
                                text = translation[TL.OnBoarding.THE_NAVIGATION_BAR_TEXT],
                                imgSrc = "assets/images/onBoarding/02-the-navigation-bar.gif",
                            )
                        }
                    }

                    OnBoardingPage.Workspace -> {
                        twContentScrollBox {
                            className("mt-2")
                            onBoardingPage(
                                title = translation[TL.OnBoarding.YOUR_WORKSPACE],
                                text = translation[TL.OnBoarding.YOUR_WORKSPACE_TEXT],
                                imgSrc = "assets/images/onBoarding/03-your-workspace.gif",
                            )
                        }
                    }
                }
            }
        },
        footer = {
            onBoardingPageStore.data.render { page ->
                pageDots(
                    numberOfDots = OnBoardingPage.entries.size,
                    activeDot = page.ordinal,
                    selectIndexHandler = onBoardingPageStore.selectByIndex,
                )
                twoButtonFooter(
                    primaryTitle = if (page.lastPage) translation[TL.General.DONE] else translation[TL.General.NEXT],
                    primaryValue = Unit,
                    primaryClickHandlers = if (page.lastPage) listOf(onBoardingPageStore.quit) else listOf(onBoardingPageStore.next),
                    secondaryTitle = if (page.lastPage) translation[TL.General.RESTART] else translation[TL.General.QUIT],
                    secondaryClickHandlers = if (page.lastPage) listOf(onBoardingPageStore.reset) else listOf(onBoardingPageStore.quit),
                )
            }
        },
    )
}

fun RenderContext.onBoardingPage(title: Flow<String>, text: Flow<String>, imgSrc: String, isFrontPage: Boolean = false) {

    val onBoardingPageStore: OnBoardingPageStore by koinCtx.inject()

    flexBox(
        {
            width { full }
            height { full }
            direction { column }
            alignItems { center }
            justifyContent { center }
        },
    ) {
        //img
        img(
            {
                if (isFrontPage) {
                    maxWidth { full }
                } else {
                    minHeight { "250px" }
                    maxWidth { "300px" }
                    border {
                        width { "5px" }
                        color { primary.main }
                    }
                    radius(formationStyles.buttonRadius)
                    margins {
                        left { auto }
                        right { auto }
                    }
                }
                margins { bottom { normal } }
            },
        ) {
            src(imgSrc)
        }
        flexBox({ flex { grow { "1" } } }) { }
        //title
        mainTitle(title)
        //text
        flexBox({ flex { grow { "1" } } }) { }
        span(
            {
                maxWidth { "300px" }
                if (!isFrontPage) minHeight { "145px" }
                textAlign { center }
                display { flex }
                flex { grow { "2" } }
            },
        ) {
            text.renderText(into = this)
        }

        horizontalSwipeListener(
            domNode,
            onSwipedLeft = { onBoardingPageStore.next() },
            onSwipedRight = { onBoardingPageStore.previous() },
        )
    }
}

fun RenderContext.dot(active: Boolean = false) {
    flexBox(
        {
            width { "15px" }
            height { "15px" }
            background {
                color { if (active) primary.main else FormationColors.GrayPrivate.color }
            }
            radius { full }
            flex {
                grow { "0" }
                shrink { "0" }
                basis { "15px" }
            }
        },
    ) { }
}

fun RenderContext.pageDots(
    numberOfDots: Int,
    activeDot: Int,
    selectIndexHandler: SimpleHandler<Int>? = null,
    interactive: Boolean = true,
) {

    lineUp(
        {
            width { full }
            alignItems { center }
            justifyContent { center }
            margins { bottom { normal } }
        },
    ) {
        spacing { small }
        items {
            for (i in 0 until numberOfDots) {
                if (interactive) {
                    button {
                        dot(i == activeDot)
                        selectIndexHandler?.let {
                            clicks.map { i } handledBy it
                        }
                    }
                } else {
                    dot(i == activeDot)
                }
            }
        }
    }
}
