package services

import apiclient.geoobjects.pointCoordinates
import auth.ApiUserStore
import com.jillesvangurp.geo.GeoGeometry.Companion.distance
import dev.fritz2.core.invoke
import dev.fritz2.core.storeOf
import koin.koinCtx
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import localization.TL
import localization.Translation
import location.GeoPositionStore
import location.LocationFollowStore
import location.LocationUploadStore
import location.geolocation.GeoPosition
import location.geolocation.Geolocation
import location.geolocation.GeolocationException
import location.geolocation.PositionError
import location.geolocation.latlon
import maplibreGL.MaplibreMap
import maplibreGL.toLatLon
import model.NotificationType
import model.Overlay
import overlays.AlertOverlayStore
import theme.FormationColors
import utils.asGeolocationException
import utils.isSameLocationAs
import utils.merge
import web.geolocation.GeolocationWatchId

class GeoPositionService {

    private val geoPositionStore: GeoPositionStore = koinCtx.get()
    private val maplibreMap: MaplibreMap = koinCtx.get()
    private val apiUserStore: ApiUserStore = koinCtx.get()

    val activeWatchIdStore = storeOf<GeolocationWatchId?>(null, Job())

    suspend fun getPosition() {
        console.log("get position once")
        handlePositionResult(Geolocation.getCurrentPosition())
    }

    suspend fun getActiveWatchIdOrNew(): GeolocationWatchId {
        val currentActiveId = activeWatchIdStore.current
        return if (currentActiveId == null) {
            val newWatchId = watchPosition()
            activeWatchIdStore.update(newWatchId)
            newWatchId
        } else currentActiveId
    }

    fun stopActiveWatch() {
        activeWatchIdStore.current?.let { Geolocation.clearWatch(it) }
    }

    suspend fun watchPosition(): GeolocationWatchId {
        val watchId = Geolocation.watchPosition(resultCallback = positionResultCallback())
        console.log("start position watching (#$watchId)", watchId)
        return watchId
    }

    private fun positionResultCallback(): (Result<GeoPosition>) -> Unit {
        return { result -> handlePositionResult(result) }
    }

    fun jumpToMyPosition() {
        CoroutineScope(CoroutineName("jump-to-user")).launch {
            for (i in 0..1) {
                if (geoPositionStore.current == null) {
                    locateMe()
                    console.log("Trying to jump to users position")
                } else {
                    locateMe()
                    break
                }
                delay(500)
            }
            maplibreMap.addHiddenOverride(apiUserStore.current.userId)
        }
    }

    suspend fun locateMe() {
        val currentPos = geoPositionStore.current
        if (currentPos == null) {
//            getPosition()
            getActiveWatchIdOrNew()
        }
        if (currentPos != null) {
            val pos = currentPos.coords.latlon

            if (
                distance(
                    maplibreMap.getCenter().toLatLon().pointCoordinates(),
                    pos.pointCoordinates(),
                ) > 0.5
            ) {
                maplibreMap.panTo(center = pos)
            }
        }
    }
}

fun handlePositionResult(result: Result<GeoPosition>) {

    val geoPositionStore: GeoPositionStore = koinCtx.get()
    val locationUploadStore: LocationUploadStore = koinCtx.get()
    val locationFollowStore: LocationFollowStore = koinCtx.get()
    val alertOverlayStore: AlertOverlayStore = koinCtx.get()
    val translation: Translation = koinCtx.get()

    result.fold(
        { res ->
            if (!res.isSameLocationAs(geoPositionStore.current)) {
                geoPositionStore.update(res)
            }
        },
        { error ->
            geoPositionStore.update(null)
            when ((error as? GeolocationException)?.code) {
                PositionError.PERMISSION_DENIED -> {
                    if (locationUploadStore.current.isActive) {
                        locationUploadStore.stopSendingLocationsHandler(false)
                        alertOverlayStore.show(
                            Overlay.NotificationToast(
                                notificationType = NotificationType.Alert, durationSeconds = 5,
                                text = translation[TL.LocationSharing.POSITION_PERMISSION_DENIED].merge(
                                    translation[TL.LocationSharing.SHARING_DISABLED],
                                    " - ",
                                ),
                                bgColor = FormationColors.GrayDisabled.color,
                            ),
                        )
                    }
                    if (locationFollowStore.current.isActive) {
                        locationFollowStore.stopFollow()
                        alertOverlayStore.show(
                            Overlay.NotificationToast(
                                notificationType = NotificationType.Alert, durationSeconds = 5,
                                text = translation[TL.LocationSharing.POSITION_PERMISSION_DENIED],
                                bgColor = FormationColors.GrayDisabled.color,
                            ),
                        )
                    }
                    console.log("Geolocation Exception:", result.asGeolocationException()?.message)
                }

                PositionError.POSITION_UNAVAILABLE -> {
                    alertOverlayStore.show(
                        Overlay.NotificationToast(
                            notificationType = NotificationType.Alert, durationSeconds = 5,
                            text = translation[TL.LocationSharing.NO_POSITION_FOUND].merge(translation[TL.LocationSharing.SHARING_DISABLED], " - "),
                            bgColor = FormationColors.GrayDisabled.color,
                        ),
                    )
                    console.log("Geolocation Exception:", result.asGeolocationException()?.message)
                }

                PositionError.TIMEOUT -> {
                    alertOverlayStore.show(
                        Overlay.NotificationToast(
                            notificationType = NotificationType.Alert, durationSeconds = 5,
                            text = translation[TL.LocationSharing.POSITION_TIMEOUT].merge(translation[TL.LocationSharing.SHARING_DISABLED], " - "),
                            bgColor = FormationColors.GrayDisabled.color,
                        ),
                    )
                    console.log("Geolocation Exception:", result.asGeolocationException()?.message)
                }

                else -> {
                    console.log("Geolocation Exception:", result.asGeolocationException()?.message)
                }
            }
        },
    )
}
