package data.users.settings

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import auth.FeatureFlagStore
import auth.Features
import koin.koinCtx
import localstorage.LocalStorageKeys
import localstorage.NonNullableLocalStoringStore
import model.LocalSettings
import model.actionButtonStates
import model.clusterObjects
import model.hideMarkerTitles
import model.manualSearch
import model.periodicSearch
import model.wizardStates
import wizard.onbaording.ActionButton
import wizard.onbaording.Wizard

class LocalSettingsStore : NonNullableLocalStoringStore<LocalSettings>(
    initialData = LocalSettings.DEFAULT,
    emptyValue = LocalSettings.DEFAULT,
    key = LocalStorageKeys.LocalSettings.key(),
    serializer = LocalSettings.serializer(),
) {
    val analyticsService by koinCtx.inject<AnalyticsService>()

    val featureFlagStore: FeatureFlagStore by koinCtx.inject()

    val clusteringEnabled
        get() =
            (featureFlagStore.current[Features.DisableClustering] == false) && current.clusterObjects

    val reset = handle {
        LocalSettings.DEFAULT
    }

    val acceptCookies = handle { current ->
        console.log("User accepted cookies")
        analyticsService.createEvent(
            AnalyticsCategory.LocalSettings,
        ) {
            recordAction("cookies", target = "accepted")
        }
        current.copy(acceptCookies = true)
    }

    val readDisclaimer = handle { current ->
        console.log("User accepted disclaimer")
        analyticsService.createEvent(
            AnalyticsCategory.LocalSettings,
        ) {
            recordAction("disclaimer", target = "accepted")
        }
        current.copy(readDisclaimer = true)
    }

    val updateMapStyle = handle<String> { current, styleName ->
        if (current.mapStyle != styleName) {
            console.log("Changed map style to ->", styleName)
            analyticsService.createEvent(
                AnalyticsCategory.LocalSettings,
            ) {
                recordAction("map-style", target = styleName)
            }
            current.copy(mapStyle = styleName)
        } else current
    }

    val updateWizardState = handle<Pair<Wizard, Int>> { current, newWizardState ->
        current.copy(
            wizardStates = current.wizardStates + newWizardState,
        )
    }

    val updateActionButtonState = handle<Pair<ActionButton, Boolean>> { current, (newButton, state) ->
        val mutable = current.actionButtonStates.toMutableMap()
        mutable[newButton] = state
        current.copy(
            actionButtonStates = mutable,
        )
    }

    private val changedManualSearch = handle<Boolean> { current, manualSearch ->
        console.log("Changed manual search to -> ${if (manualSearch) "Enabled" else "Disabled"}")
        current
    }

    private val changedPeriodicSearch = handle<Boolean> { current, periodicSearch ->
        console.log("Changed periodic search to -> ${if (periodicSearch) "Enabled" else "Disabled"}")
        current
    }

    private val changedHideMarkerTitles = handle<Boolean> { current, showMarkerTitles ->
        console.log("Changed hideMarkerTitles to -> ${if (showMarkerTitles) "Enabled" else "Disabled"}")
        current
    }

    private val changedClusterObjects = handle<Boolean> { current, clusterObjects ->
        console.log("Changed clusterObjects to -> ${if (clusterObjects) "Enabled" else "Disabled"}")
        current
    }

    private val changedWizardStates = handle<Map<Wizard, Int>> { current, wizardStates ->
        console.log("Changed wizardStates to -> $wizardStates")
        current
    }

    private val changedActionButtonStates = handle<Map<ActionButton, Boolean>> { current, actionButtonStates ->
        console.log("Changed actionButtonStates to -> $actionButtonStates")
        current
    }

    init {
        map(LocalSettings.manualSearch()).data handledBy changedManualSearch
        map(LocalSettings.periodicSearch()).data handledBy changedPeriodicSearch
        map(LocalSettings.hideMarkerTitles()).data handledBy changedHideMarkerTitles
        map(LocalSettings.clusterObjects()).data handledBy changedClusterObjects
        map(LocalSettings.wizardStates()).data handledBy changedWizardStates
        map(LocalSettings.actionButtonStates()).data handledBy changedActionButtonStates
    }
}
