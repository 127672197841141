package search.searchlayer

import apiclient.geoobjects.GeoObjectDetails
import apiclient.util.isNotNullOrEmpty
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import maplibreGL.GeojsonConverter.activeObjectStore
import maplibreGL.MaplibreMap
import search.SearchResultsCoordinator
import search.SearchType

class MapSearchResultsStore : RootStore<Map<String, GeoObjectDetails>>(
    initialData = emptyMap(),
    job = Job(),
) {

    private val searchResultsCoordinator: SearchResultsCoordinator by koinCtx.inject()
    private val maplibreMap: MaplibreMap by koinCtx.inject()

    val set = handle<List<GeoObjectDetails>> { current, objects ->
        val newData = objects.associateBy { obj -> obj.id }
        if (current != newData || searchResultsCoordinator.last != SearchType.Map) {
//            console.log("SET MapSearchResults (${objects.size})", objects.map { it.title })
//            console.log("Data changed: ${current != newData}")
//            console.log("SearchType changed: ${searchResultsCoordinator.last != SearchType.Map} (last SearchType: ${searchResultsCoordinator.last})")
            searchResultsCoordinator.renderSearchResults(Pair(SearchType.Map, newData.values.toList()))
            // Make sure current active Marker is always on top
            maplibreMap.addActiveObjectOverride(activeObjectStore.current)
            newData
        } else {
            current
        }
    }

    val add = handle<List<GeoObjectDetails>> { current, objects ->
        if (objects.isNotNullOrEmpty()) {
//            console.log("ADD MapSearchResults (${objects.size})", objects.map { it.title })
            val newData = current + objects.associateBy { obj -> obj.id }
            searchResultsCoordinator.renderSearchResults(Pair(SearchType.Map, newData.values.toList()))
            // Make sure current active Marker is always on top
//        maplibreMap.addActiveObjectOverride(activeObjectStore.current)
//        console.log("ADD Called Overrride:", activeObjectStore.current.title)
            newData
        } else current
    }

    val remove = handle<Set<String>> { current, objectIds ->
        val newData = current - objectIds
        searchResultsCoordinator.renderSearchResults(Pair(SearchType.Map, newData.values.toList()))
        newData
    }

    val clear = handle { _ ->
        searchResultsCoordinator.renderSearchResults(Pair(SearchType.Map, emptyList()))
        emptyMap()
    }
}
