package maplibreGL.renderer

import koin.koinCtx
import kotlin.js.json
import kotlinx.datetime.Clock
import kotlinx.serialization.json.Json
import maplibreGL.GeoJSONSource
import maplibreGL.MaplibreMap
import maplibreGL.calcDistance
import theme.FormationColors
import utils.getDistanceString

const val MEASURING_TOOL = "measuring-tool"

class MeasuringToolRender {

    val maplibreMap: MaplibreMap by koinCtx.inject()

    val map get() = maplibreMap.map

    // GeoJSON object to store the measurement features
    private var measureFeatureData = json(
        "type" to "FeatureCollection",
        "features" to arrayOf<Json>(),
    )

    private val measuringToolSourceDefinition
        get() = json(
            "type" to "geojson",
            "data" to measureFeatureData,
        )

    enum class MeasuringToolLayers(val layerId: String) {
        DynamicDashedLine(
            layerId = "$MEASURING_TOOL-dynamic-dashed-line",
        ),
        DynamicLineDistance(
            layerId = "$MEASURING_TOOL-dynamic-line-distance",
        ),
        Lines(
            layerId = "$MEASURING_TOOL-lines",
        ),
        LineDistances(
            layerId = "$MEASURING_TOOL-line-distances",
        ),
        Points(
            layerId = "$MEASURING_TOOL-points",
        ),
        PointDistanceUntil(
            layerId = "$MEASURING_TOOL-point-distance-until",
        ),
    }

    enum class MeasuringToolListener(val listenerId: String) {
        AddPointOnClick(
            listenerId = "$MEASURING_TOOL-add-point-click",
        ),
        DashedLineAndCursorOnMouseMove(
            listenerId = "$MEASURING_TOOL-add-point",
        ),
    }

    private val newLineColor = "#2dbc94"//FormationColors.GreenBright.color
    private val startPointColor = "#2dbc94"//FormationColors.GreenBright.color
    private val pointColor = "#4285f4" //"#008aa1"//FormationColors.GrayLight.color
    private val pointOutlineColor = "#f5f5b8" //FormationColors.BlueDeep.color
    private val pointTextColor = "#f5f5b8" //FormationColors.GrayLight.color
    private val pointTextOutlineColor = FormationColors.BlueDeep.color
    private val lineColor = "#4285f4"// "#008aa1" //FormationColors.MarkerYou.color
    private val lineTextColor = "#4285f4" // "#008aa1" //FormationColors.MarkerYou.color
    private val lineTextOutlineColor = FormationColors.BlueDeep.color

    fun enableMeasuringToolAndAddLayers() {

        val drawnLineLayerDefinitions = listOf(
            json(
                "id" to MeasuringToolLayers.Lines.layerId,
                "type" to "line",
                "source" to MEASURING_TOOL,
                "layout" to json(
                    "line-cap" to "round",
                    "line-join" to "round",
                ),
                "paint" to json(
                    "line-color" to lineColor,
                    "line-width" to 2.5,
                ),
                "filter" to arrayOf("==", arrayOf("get", "lineType"), "normal"),
            ),
            json(
                "id" to MeasuringToolLayers.Points.layerId,
                "type" to "circle",
                "source" to MEASURING_TOOL,
                "paint" to json(
                    "circle-radius" to 5,
                    "circle-color" to arrayOf("get", "color"),
                    "circle-stroke-color" to pointOutlineColor,
                    "circle-stroke-width" to 0.5,
                ),
                "filter" to arrayOf("in", "\$type", "Point"),
            ),
            json(
                "id" to MeasuringToolLayers.LineDistances.layerId,
                "type" to "symbol",
                "source" to MEASURING_TOOL,
//                    "filter" to arrayOf("in", "\$type", "LineString"),
                "filter" to arrayOf("==", arrayOf("get", "lineType"), "normal"),
                "layout" to json(
                    "symbol-placement" to "line-center",
                    "text-field" to arrayOf("get", "title"),
//                        "text-font" to arrayOf("Open Sans Regular"),
                    "text-size" to 18,
                    "text-justify" to "auto",
                    "text-allow-overlap" to false,
                    "text-rotation-alignment" to "viewport",
                    "text-offset" to arrayOf(0, 0.5),
                ),
                "paint" to json(
                    "text-color" to lineTextColor,
                    "text-halo-color" to lineTextOutlineColor,
                    "text-halo-width" to 0.5,
                    "text-halo-blur" to 0.5,
                ),
            ),
            json(
                "id" to MeasuringToolLayers.PointDistanceUntil.layerId,
                "type" to "symbol",
                "source" to MEASURING_TOOL,
                "filter" to arrayOf("in", "\$type", "Point"),
                "layout" to json(
                    "symbol-placement" to "point",
                    "text-field" to arrayOf("get", "title"),
                    "text-size" to 16,
                    "text-justify" to "auto",
                    "text-allow-overlap" to false,
                    "text-rotation-alignment" to "viewport",
                    "text-offset" to arrayOf(0, 1),
                ),
                "paint" to json(
                    "text-color" to pointTextColor,
                    "text-halo-color" to pointTextOutlineColor,
                    "text-halo-width" to 0.5,
                    "text-halo-blur" to 0.5,
                ),
            ),
        )

        maplibreMap.ensureGeoJSONSourceAndAddGeoJSONLayers(
            sourceId = MEASURING_TOOL,
            sourceDefinition = measuringToolSourceDefinition,
            layerDefinitionsMap = drawnLineLayerDefinitions.associateWith { null },
        )
    }

    private fun addDynamicDashedLineLayers() {

        val dynamicDashedLineLayerDefinitions = listOf(
            json(
                "id" to MeasuringToolLayers.DynamicDashedLine.layerId,
                "type" to "line",
                "source" to MEASURING_TOOL,
                "layout" to json(
                    "line-cap" to "round",
                    "line-join" to "round",
                ),
                "paint" to json(
                    "line-color" to newLineColor,
                    "line-width" to 2.5,
                    "line-dasharray" to arrayOf(2, 4),
                ),
                "filter" to arrayOf("==", arrayOf("get", "lineType"), "dashed"),
            ),
            json(
                "id" to MeasuringToolLayers.DynamicLineDistance.layerId,
                "type" to "symbol",
                "source" to MEASURING_TOOL,
                "filter" to arrayOf("==", arrayOf("get", "lineType"), "dashed"),
                "layout" to json(
                    "symbol-placement" to "line-center",
                    "text-field" to arrayOf("get", "title"),
//                        "text-font" to arrayOf("Open Sans Regular"),
                    "text-size" to 18,
                    "text-justify" to "auto",
                    "text-allow-overlap" to false,
                    "text-rotation-alignment" to "viewport",
                    "text-offset" to arrayOf(0, 0.5),
                ),
                "paint" to json(
                    "text-color" to startPointColor,
                    "text-halo-color" to lineTextOutlineColor,
                    "text-halo-width" to 0.5,
                    "text-halo-blur" to 0.5,
                ),
            ),
        )

        maplibreMap.ensureGeoJSONSourceAndAddGeoJSONLayers(
            sourceId = MEASURING_TOOL,
            sourceDefinition = measuringToolSourceDefinition,
            layerDefinitionsMap = dynamicDashedLineLayerDefinitions.associateWith { null },
        )
    }

    fun startOrResumeMeasurement() {
        addDynamicDashedLineLayers()
        pointAddListener()
        dynamicDashedLineListener()
    }

    fun stopMeasurement() {
        map?.let {
            it.getCanvas()?.style.cursor = "unset"
        }
        maplibreMap.off("click", fnId = MeasuringToolListener.AddPointOnClick.listenerId)
        maplibreMap.off(type = "mousemove", fnId = MeasuringToolListener.DashedLineAndCursorOnMouseMove.listenerId)

        listOf(
            MeasuringToolLayers.DynamicDashedLine.layerId,
            MeasuringToolLayers.DynamicLineDistance.layerId,
        ).forEach { layer ->
            if (map?.getLayer(layer) != null) {
                map?.removeLayer(layer)
            }
        }
    }

    fun disableMeasuringTool() {
        stopMeasurement()

        listOf(
            MeasuringToolLayers.Points.layerId,
            MeasuringToolLayers.Lines.layerId,
            MeasuringToolLayers.LineDistances.layerId,
            MeasuringToolLayers.PointDistanceUntil.layerId,
        ).forEach { layer ->
            if (map?.getLayer(layer) != null) {
                map?.removeLayer(layer)
            }
        }
        if (map?.getSource(MEASURING_TOOL) != null) {
            map?.removeSource(MEASURING_TOOL)
        }
    }

    fun resetMeasureFeatureData() {
        // reset feature data
        measureFeatureData = json(
            "type" to "FeatureCollection",
            "features" to arrayOf<Json>(),
        )
        (map?.getSource(MEASURING_TOOL) as GeoJSONSource).setData(measureFeatureData)
    }

    /**
     * Listeners
     */
    private fun pointAddListener() {
        console.log("Add ${MeasuringToolListener.AddPointOnClick.listenerId} listener")
        maplibreMap.on(
            type = "click", fnId = MeasuringToolListener.AddPointOnClick.listenerId,
            fn = { e: dynamic ->
//                console.log("Triggered ${MeasuringToolListener.AddPointOnClick.listenerId} listener")
                val clickedPoint = map.asDynamic().queryRenderedFeatures(
                    e.point,
                    json(
                        "layers" to arrayOf(MeasuringToolLayers.Points.layerId),
                    ),
                )

                // Remove all lineStrings from the layer so we can redraw them based on the points collection
                if (measureFeatureData["features"].unsafeCast<Array<Json>>().size > 1) {
                    measureFeatureData["features"] = measureFeatureData["features"].unsafeCast<Array<Json>>().filter {
                        it.asDynamic().geometry.type != "LineString"
                    }.toTypedArray()
                }


                // If a point was clicked, remove it from the map
                if (clickedPoint.unsafeCast<Array<Json>>().isNotEmpty()) {
                    val clickedPointId = clickedPoint[0].properties.id
                    measureFeatureData["features"] =
                        measureFeatureData["features"].unsafeCast<Array<Json>>().filter { point ->
                            point.asDynamic().properties.id != clickedPointId
                        }.toTypedArray()
                } else {
                    val newPoint = json(
                        "type" to "Feature",
                        "geometry" to json(
                            "type" to "Point",
                            "coordinates" to arrayOf(e.lngLat.lng, e.lngLat.lat),
                        ),
                        "properties" to json(
                            "id" to Clock.System.now().toString(),
                            "color" to if (measureFeatureData["features"].unsafeCast<Array<Json>>()
                                    .isNotEmpty()
                            ) pointColor else startPointColor,
                        ),
                    )

                    measureFeatureData["features"].asDynamic().push(newPoint)
                }

                // New Linestring for every point
                val points = measureFeatureData["features"].unsafeCast<Array<Json>>()
                if (measureFeatureData["features"].unsafeCast<Array<Json>>().size > 1) {
                    var totalDistance = 0.0
                    // Iterate through all points and create LineStrings between them
                    points.forEachIndexed { index, point ->
                        if (index > 0) {
                            val prevCords =
                                measureFeatureData["features"].unsafeCast<Array<Json>>()[index - 1].asDynamic().geometry.coordinates
                            val pointCoords = point.asDynamic().geometry.coordinates

                            val distance = calcDistance(prevCords, pointCoords)

                            val newLine = json(
                                "type" to "Feature",
                                "geometry" to json(
                                    "type" to "LineString",
                                    "coordinates" to arrayOf(prevCords, pointCoords),
                                ),
                                "properties" to json(
                                    "lineType" to "normal",
                                    "title" to distance.getDistanceString(),
                                ),
                            )

                            totalDistance += distance
                            // add total distance until this point as title to each point
                            point.asDynamic().properties.title = totalDistance.getDistanceString()
                            point.asDynamic().properties.color = pointColor

                            measureFeatureData["features"].asDynamic().push(newLine)
                        } else {
                            point.asDynamic().properties.title = ""
                            point.asDynamic().properties.color = startPointColor
                        }
                    }
//                    console.log("Total Distance", totalDistance.getDistanceString())
                }

                (map?.getSource(MEASURING_TOOL) as GeoJSONSource).setData(measureFeatureData)
            },
        )
    }

    private fun dynamicDashedLineListener() {
        console.log("Add ${MeasuringToolListener.DashedLineAndCursorOnMouseMove.listenerId} listener")
        maplibreMap.on(
            type = "mousemove", fnId = MeasuringToolListener.DashedLineAndCursorOnMouseMove.listenerId,
            fn = { e: dynamic ->
                val clickedPoint = map.asDynamic().queryRenderedFeatures(
                    e.point,
                    json(
                        "layers" to arrayOf(MeasuringToolLayers.Points.layerId),
                    ),
                )
                // UI indicator for clicking/hovering a point on the map
                map?.getCanvas().style.cursor =
                    if (clickedPoint.unsafeCast<Array<Json>>().isNotEmpty()) "pointer" else "crosshair"

                // Draw dashed line from last point to cursor
                if (measureFeatureData["features"].unsafeCast<Array<Json>>().isNotEmpty()) {
                    // add newPoint and line to map
                    val lastPoint = measureFeatureData["features"].unsafeCast<Array<Json>>().lastOrNull {
                        it.asDynamic().geometry.type == "Point"
                    }
                    lastPoint?.let { point ->
                        val lastPointCoordinates = point.asDynamic().geometry.coordinates
                        val cursorCoordinates = arrayOf(e.lngLat.lng, e.lngLat.lat)

                        // new dashed line
                        val dashedLine = json(
                            "type" to "Feature",
                            "geometry" to json(
                                "type" to "LineString",
                                "coordinates" to arrayOf(lastPointCoordinates, cursorCoordinates),
                            ),
                            "properties" to json(
                                "lineType" to "dashed",
                                "title" to calcDistance(lastPointCoordinates, cursorCoordinates).getDistanceString(),
                            ),
                        )

                        measureFeatureData["features"] =
                            measureFeatureData["features"].unsafeCast<Array<Json>>().filter {
                                it.asDynamic().properties.lineType != "dashed"
                            }.toTypedArray()
                        measureFeatureData["features"].asDynamic().push(dashedLine)

                        (map?.getSource(MEASURING_TOOL) as GeoJSONSource).setData(measureFeatureData)
                    }
                }
            },
        )
    }
}
