package objectrouting

import apiclient.geoobjects.LatLon
import data.objects.ActiveObjectStore
import data.objects.building.ActiveFloorLevelStore
import data.objects.building.ActiveFloorsStore
import koin.koinCtx
import kotlin.coroutines.EmptyCoroutineContext
import kotlin.js.json
import kotlin.math.max
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.serialization.json.Json
import maplibreGL.GeoJSONSource
import maplibreGL.LngLat
import maplibreGL.LngLatBounds
import maplibreGL.MaplibreMap
import maplibreGL.calcDistance
import maplibreGL.calcHeading
import theme.FormationColors
import utils.getDistanceString
import utils.obj

const val ROUTING_PATH = "routing-path"

class NavigationRender {

    val maplibreMap: MaplibreMap by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()

    val map get() = maplibreMap.map

    // Routing path colors
    private val navStartPointColor = "#2dbc94"//FormationColors.GreenBright.color
    private val navPointColor = "#4285f4" //"#008aa1"//FormationColors.GrayLight.color
    private val navPointOutlineColor = FormationColors.White.color //"#f5f5b8" //FormationColors.BlueDeep.color
    private val navPointTextColor = "#4285f4" //"#f5f5b8" //FormationColors.GrayLight.color
    private val navPointTextOutlineColor = FormationColors.White.color
    private val routingLineColor = "#4285f4"// "#008aa1" //FormationColors.MarkerYou.color
    private val dashedRoutingLineColor = "#2dbc94"

    // GeoJSON object to store the routing features
    private var routingPathFeatureData = json(
        "type" to "FeatureCollection",
        "features" to arrayOf<Json>(),
    )

    private val routingPathSourceDefinition = json(
        "type" to "geojson",
        "data" to routingPathFeatureData,
    )

    enum class RoutingPathLayers(val layerId: String) {
        Line(
            layerId = "$ROUTING_PATH-line",
        ),
        Dashed(
            layerId = "$ROUTING_PATH-dashed",
        ),
        PointDistanceUntil(
            layerId = "$ROUTING_PATH-point-distance-until",
        ),
        Points(
            layerId = "$ROUTING_PATH-points",
        ),
        PointsWithFloorChange(
            layerId = "$ROUTING_PATH-points-with-floor-change",
        ),

    }

    fun filterFeaturesByFloorIds(floorIds: List<String>? = null) {
        val activeFloorsStore: ActiveFloorsStore by koinCtx.inject()

        NavigationRender.RoutingPathLayers.entries.map { it.layerId }.forEach { layerId ->
            maplibreMap.map?.getLayer(layerId)?.let { routingPathLayer ->
                maplibreMap.map?.setFilter(
                    layerId,
                    arrayOf(
                        "case",
                        // if floorId is null, keep default filter
                        arrayOf("==", arrayOf("get", "floorId"), null),
                        routingPathLayer.metadata.defaultFilter,
                        // if floorId is not null, add filter by floorId
                        arrayOf("!=", arrayOf("get", "floorId"), null),
                        arrayOf(
                            "all",
                            routingPathLayer.metadata.defaultFilter,
                            arrayOf("in", arrayOf("get", "floorId"), arrayOf("literal", (floorIds ?: activeFloorsStore.current)?.toTypedArray())),
                        ),
                        // fallback to default filter
                        routingPathLayer.metadata.defaultFilter,
                    ),
                )
            }
        }
    }

    private val routingPathLayerDefinitions = listOf(
        json(
            "id" to RoutingPathLayers.Line.layerId,
            "type" to "line",
            "source" to ROUTING_PATH,
            "filter" to arrayOf("==", arrayOf("get", "lineType"), "normal"),
            "metadata" to json(
                "defaultFilter" to arrayOf("==", arrayOf("get", "lineType"), "normal"),
            ),
            "layout" to json(
                "line-cap" to "round",
                "line-join" to "round",
            ),
            "paint" to json(
                "line-color" to routingLineColor,
                "line-width" to 7.5,
            ),
        ),

        json(
            "id" to RoutingPathLayers.Dashed.layerId,
            "type" to "line",
            "source" to ROUTING_PATH,
            "filter" to arrayOf("==", arrayOf("get", "lineType"), "dashed"),
            "metadata" to json(
                "defaultFilter" to arrayOf("==", arrayOf("get", "lineType"), "dashed"),
            ),
            "layout" to json(
                "line-cap" to "round",
                "line-join" to "round",
            ),
            "paint" to json(
                "line-color" to dashedRoutingLineColor,
                "line-width" to 7.5,
                "line-dasharray" to arrayOf(1, 3),
            ),
        ),

        json(
            "id" to RoutingPathLayers.PointDistanceUntil.layerId,
            "type" to "symbol",
            "source" to ROUTING_PATH,
            "filter" to arrayOf("==", arrayOf("geometry-type"), "Point"),
            "metadata" to json(
                "defaultFilter" to arrayOf("==", arrayOf("geometry-type"), "Point"),
            ),
            "layout" to json(
                "symbol-placement" to "point",
                "text-field" to arrayOf("get", "title"),
                "text-size" to 16,
                "text-font" to arrayOf("Open Sans Bold", "Arial Unicode MS Bold"),
                "text-justify" to "auto",
                "text-allow-overlap" to false,
                "text-rotation-alignment" to "viewport",
                "text-offset" to arrayOf(0, 3),
            ),
            "paint" to json(
                "text-color" to navPointTextColor,
                "text-halo-color" to navPointTextOutlineColor,
                "text-halo-width" to 1.0,
                "text-halo-blur" to 0.5,
            ),
        ),

        json(
            "id" to RoutingPathLayers.Points.layerId,
            "type" to "circle",
            "source" to ROUTING_PATH,
            "filter" to arrayOf("==", arrayOf("get", "isFloorChange"), false),
            "metadata" to json(
                "defaultFilter" to arrayOf("==", arrayOf("get", "isFloorChange"), false),
            ),
            "paint" to json(
                "circle-radius" to arrayOf("get", "circleRadius"),
                "circle-color" to arrayOf("get", "color"),
                "circle-stroke-color" to arrayOf("get", "strokeColor"),
                "circle-stroke-width" to arrayOf("get", "strokeWidth"),
            ),
        ),

        json(
            "id" to RoutingPathLayers.PointsWithFloorChange.layerId,
            "type" to "symbol",
            "source" to ROUTING_PATH,
            "filter" to arrayOf("==", arrayOf("get", "isFloorChange"), true),
            "metadata" to json(
                "defaultFilter" to arrayOf("==", arrayOf("get", "isFloorChange"), true),
            ),
            "layout" to json(
                "symbol-placement" to "point",
                "text-field" to arrayOf("get", "title"),
                "text-size" to 16,
                "text-font" to arrayOf("Open Sans Bold", "Arial Unicode MS Bold"),
                "text-justify" to "auto",
                "text-allow-overlap" to false,
                "text-rotation-alignment" to "viewport",
                "text-offset" to arrayOf(0, 3),
                "icon-image" to arrayOf(
                    "case",
                    arrayOf("==", arrayOf("get", "isLevelUp"), true),
                    "levelUp",
                    arrayOf("==", arrayOf("get", "isLevelUp"), false),
                    "levelDown",
                    "stairs",
                ),
                "icon-size" to 0.2645,
            ),
            "paint" to json(
                "text-color" to navPointTextColor,
                "text-halo-color" to navPointTextOutlineColor,
                "text-halo-width" to 0.5,
                "text-halo-blur" to 0.5,
            ),
        ),
    )

    fun resetRoutingPathFeatureData() {
        // reset feature data
        routingPathFeatureData = json(
            "type" to "FeatureCollection",
            "features" to arrayOf<Json>(),
        )
        (map?.getSource(ROUTING_PATH) as? GeoJSONSource)?.setData(routingPathFeatureData)
    }

    fun showRoutingPath(routePoints: List<RoutePoint>) {

        val activeFloorLevelStore: ActiveFloorLevelStore by koinCtx.inject()

        // make sure source and layers for points and line strings are there
        if (map?.getSource(ROUTING_PATH) == null) {
            maplibreMap.ensureGeoJSONSourceAndAddGeoJSONLayers(
                sourceId = ROUTING_PATH,
                sourceDefinition = routingPathSourceDefinition,
                layerDefinitionsMap = routingPathLayerDefinitions.associateWith { null },
            )
        }
        resetRoutingPathFeatureData()

        // draw points and line strings with timer
        if (routePoints.size > 1) {
            var totalDistance = 0.0
            var i = 1
            var timer = 0

            routePoints.forEachIndexed { index, routePoint ->
                val newPoint = json(
                    "type" to "Feature",
                    "geometry" to json(
                        "type" to "Point",
                        "coordinates" to arrayOf(routePoint.lon, routePoint.lat),
                    ),
                    "properties" to json(
                        "id" to Clock.System.now().toString(),
                        "circleRadius" to 6,
                        "color" to if (index > 0) "transparent" else navStartPointColor,
                        "strokeColor" to if (index > 0) "transparent" else navPointOutlineColor,
                        "strokeWidth" to 2.0,
                        "isFloorChange" to false,
                        "isLevelUp" to false,
                        "floorId" to routePoint.floorId,
                        "floorLevel" to routePoint.floorLevel,
                    ),
                )
                routingPathFeatureData["features"].asDynamic().push(newPoint)
            }

            val pointFeatures = routingPathFeatureData["features"].unsafeCast<Array<Json>>().filter {
                it.asDynamic().geometry.type == "Point"
            }.toTypedArray()

            console.log("ROUTE POINTS: ${routePoints.size}, POINT FEATURES: ${pointFeatures.size}")

            timer = window.setInterval(
                timeout = 650,
                handler = {
                    if (i < routePoints.size) {
                        pointFeatures[i].let { point ->
                            val routePoint = routePoints[i]
                            val prevCords = pointFeatures[i - 1].asDynamic().geometry.coordinates
                            val pointCoords = point.asDynamic().geometry.coordinates
                            val distance = calcDistance(prevCords, pointCoords)
                            val heading = calcHeading(prevCords, pointCoords)

                            val newLine = json(
                                "type" to "Feature",
                                "geometry" to json(
                                    "type" to "LineString",
                                    "coordinates" to arrayOf(prevCords, pointCoords),
                                ),
                                "properties" to json(
                                    "lineType" to if (i == 1 || i == pointFeatures.size - 1) "dashed" else "normal",
                                    // attach floorId from previous point to line, so it gets filtered out
                                    // by the floor level filter, when previous point is outside
                                    "floorId" to pointFeatures[i].asDynamic().properties.floorId,
                                ),
                            )

                            totalDistance += distance

                            if (routePoint.isFloorChange) {
                                // set special point properties for floor change points
                                point.asDynamic().properties.title =
                                    "Change floor ${routePoint.floorChange?.previousLevel} → ${routePoint.floorChange?.newLevel} (${totalDistance.getDistanceString()})"
                                point.asDynamic().properties.strokeColor = navPointOutlineColor
                                point.asDynamic().properties.strokeWidth = 2.0
                                point.asDynamic().properties.circleRadius = 10.0
                                point.asDynamic().properties.isFloorChange = true
                                point.asDynamic().properties.isLevelUp = routePoint.floorChange?.isLevelUp ?: false
                                point.asDynamic().properties.previousLevel = routePoint.floorChange?.previousLevel
                                point.asDynamic().properties.newLevel = routePoint.floorChange?.newLevel

                                if (point.asDynamic().properties.newLevel != point.asDynamic().properties.floorLevel) {
                                    CoroutineScope(EmptyCoroutineContext).launch {
                                        delay(1000)
                                        activeFloorLevelStore.update(routePoint.floorChange?.newLevel)
                                    }
                                }
                            } else {
                                // set point properties
                                point.asDynamic().properties.title = totalDistance.getDistanceString()
                                point.asDynamic().properties.color = navPointColor
                                point.asDynamic().properties.strokeColor = navPointOutlineColor
                                point.asDynamic().properties.strokeWidth = 2.0
                            }

                            routingPathFeatureData["features"].asDynamic().push(newLine)
                            (map?.getSource(ROUTING_PATH) as GeoJSONSource).setData(routingPathFeatureData)
                            maplibreMap.easeTo(
                                LatLon(lat = routePoint.lat, lon = routePoint.lon),
                                zoom = max(maplibreMap.getZoom(), 19.0),
                                pitch = 45.0,
                                bearing = if (distance > 2) heading else null, // do not rotate if points are too close together (e.g. on floor change)
                                duration = 650.0,
                            )
                            i++
                        }
                    } else {
                        window.clearInterval(timer)
                        map?.fitBounds(
                            bounds = LngLatBounds(
                                sw = LngLat(
                                    lng = routePoints.minBy { it.lon }.lon,
                                    lat = routePoints.minBy { it.lat }.lat,
                                ),
                                ne = LngLat(
                                    lng = routePoints.maxBy { it.lon }.lon,
                                    lat = routePoints.maxBy { it.lat }.lat,
                                ),
                            ),
                            options = obj {
                                padding = obj {
                                    top = 250
                                    bottom = 100
                                    left = 100
                                    right = 100
                                }
                                pitch = 0.0
                                animate = true
                                duration = 2000.0
//                                zoom = getZoom()
                            },
                        )
                        addFloorChangeClickListener()
//                        easeTo(
//                            center = routePoints[0],
//                            zoom = getZoom(),
//                            pitch = 0.0,
//                            bearing = calcHeading(
//                                pointFeatures[0].asDynamic().geometry.coordinates,
//                                pointFeatures[1].asDynamic().geometry.coordinates
//                            ),
//                            duration = 1000.0
//                        )
                    }
                },
            )
        }
    }

    fun drawRoutingPath(routePoints: List<RoutePoint>) {

        val activeFloorLevelStore: ActiveFloorLevelStore by koinCtx.inject()

        // make sure source and layers for points and line strings are there
        if (map?.getSource(ROUTING_PATH) == null) {
            maplibreMap.ensureGeoJSONSourceAndAddGeoJSONLayers(
                sourceId = ROUTING_PATH,
                sourceDefinition = routingPathSourceDefinition,
                layerDefinitionsMap = routingPathLayerDefinitions.associateWith { null },
            )
        }

        resetRoutingPathFeatureData()

        // draw points and line strings with timer
        if (routePoints.size > 1) {
            var totalDistance = 0.0

            routePoints.forEachIndexed { index, routePoint ->
                val newPoint = json(
                    "type" to "Feature",
                    "geometry" to json(
                        "type" to "Point",
                        "coordinates" to arrayOf(routePoint.lon, routePoint.lat),
                    ),
                    "properties" to json(
                        "id" to Clock.System.now().toString(),
                        "circleRadius" to 6,
                        "color" to if (index > 0) "transparent" else navStartPointColor,
                        "strokeColor" to if (index > 0) "transparent" else navPointOutlineColor,
                        "strokeWidth" to 2.0,
                        "isFloorChange" to false,
                        "isLevelUp" to false,
                        "floorId" to routePoint.floorId,
                        "floorLevel" to routePoint.floorLevel,
                    ),
                )
                routingPathFeatureData["features"].asDynamic().push(newPoint)
            }

            val pointFeatures = routingPathFeatureData["features"].unsafeCast<Array<Json>>().filter {
                it.asDynamic().geometry.type == "Point"
            }.toTypedArray()

            pointFeatures.forEachIndexed { index, point ->
                if (index > 0) {
                    val routePoint = routePoints[index]
                    val prevCords = pointFeatures[index - 1].asDynamic().geometry.coordinates
                    val pointCoords = point.asDynamic().geometry.coordinates
                    val distance = calcDistance(prevCords, pointCoords)
                    val heading = calcHeading(prevCords, pointCoords)

                    val newLine = json(
                        "type" to "Feature",
                        "geometry" to json(
                            "type" to "LineString",
                            "coordinates" to arrayOf(prevCords, pointCoords),
                        ),
                        "properties" to json(
                            "lineType" to if (index == 1 || index == pointFeatures.size - 1) "dashed" else "normal",
                            // attach floorId from previous point to line, so it gets filtered out
                            // by the floor level filter, when previous point is outside
                            "floorId" to pointFeatures[index].asDynamic().properties.floorId,
                        ),
                    )

                    totalDistance += distance

                    if (routePoint.isFloorChange) {
                        // set special point properties for floor change points
                        point.asDynamic().properties.title =
                            "Change floor ${routePoint.floorChange?.previousLevel} → ${routePoint.floorChange?.newLevel} (${totalDistance.getDistanceString()})"
                        point.asDynamic().properties.strokeColor = navPointOutlineColor
                        point.asDynamic().properties.strokeWidth = 2.0
                        point.asDynamic().properties.circleRadius = 10.0
                        point.asDynamic().properties.isFloorChange = true
                        point.asDynamic().properties.isLevelUp = routePoint.floorChange?.isLevelUp ?: false
                        point.asDynamic().properties.previousLevel = routePoint.floorChange?.previousLevel
                        point.asDynamic().properties.newLevel = routePoint.floorChange?.newLevel

                        if (point.asDynamic().properties.newLevel != point.asDynamic().properties.floorLevel) {
                            CoroutineScope(EmptyCoroutineContext).launch {
                                delay(1000)
                                activeFloorLevelStore.update(routePoint.floorChange?.newLevel)
                            }
                        }
                    } else {
                        // set point properties
                        point.asDynamic().properties.title = totalDistance.getDistanceString()
                        point.asDynamic().properties.color = navPointColor
                        point.asDynamic().properties.strokeColor = navPointOutlineColor
                        point.asDynamic().properties.strokeWidth = 2.0
                    }

                    routingPathFeatureData["features"].asDynamic().push(newLine)
                    (map?.getSource(ROUTING_PATH) as GeoJSONSource).setData(routingPathFeatureData)
                    maplibreMap.easeTo(
                        LatLon(lat = routePoint.lat, lon = routePoint.lon),
                        zoom = max(maplibreMap.getZoom(), 19.0),
                        pitch = 45.0,
                        bearing = if (distance > 2) heading else null, // do not rotate if points are too close together (e.g. on floor change)
                        duration = 650.0,
                    )
                }
            }
            map?.fitBounds(
                bounds = LngLatBounds(
                    sw = LngLat(
                        lng = routePoints.minBy { it.lon }.lon,
                        lat = routePoints.minBy { it.lat }.lat,
                    ),
                    ne = LngLat(
                        lng = routePoints.maxBy { it.lon }.lon,
                        lat = routePoints.maxBy { it.lat }.lat,
                    ),
                ),
                options = obj {
                    padding = obj {
                        top = 250
                        bottom = 100
                        left = 100
                        right = 100
                    }
                    pitch = 0.0
                    animate = true
                    duration = 2000.0
//                    zoom = getZoom()
                },
            )
            addFloorChangeClickListener()
        }
    }

    fun reDrawRoutingPath() {
        // make sure source and layers for points and line strings are there
        if (map?.getSource(ROUTING_PATH) == null) {
            maplibreMap.ensureGeoJSONSourceAndAddGeoJSONLayers(
                sourceId = ROUTING_PATH,
                sourceDefinition = routingPathSourceDefinition,
                layerDefinitionsMap = routingPathLayerDefinitions.associateWith { null },
            )
        }
        (map?.getSource(ROUTING_PATH) as GeoJSONSource).setData(routingPathFeatureData)
        filterFeaturesByFloorIds()
    }

    private fun addFloorChangeClickListener() {

        val activeFloorLevelStore: ActiveFloorLevelStore by koinCtx.inject()

        map?.on("mouseenter", RoutingPathLayers.PointsWithFloorChange.layerId) {
            map?.getCanvas().style.cursor = "pointer"
            if (maplibreMap.activeListeners["resetOnMapClick"] != null) {
                maplibreMap.off(type = "click", fnId = "resetOnMapClick")
            }
        }
        map?.on("mouseleave", RoutingPathLayers.PointsWithFloorChange.layerId) {
            map?.getCanvas().style.cursor = ""
            maplibreMap.once(type = "click", fn = activeObjectStore::resetOnMapClick, fnId = "resetOnMapClick")
        }

        map?.on("click", RoutingPathLayers.PointsWithFloorChange.layerId) { routPoint ->
            routPoint.preventDefault()

            val prev = routPoint?.features[0]?.properties?.previousLevel as? Double
            val next = routPoint?.features[0]?.properties?.newLevel as? Double
            val isLevelUp = routPoint.features[0]?.properties?.isLevelUp as? Boolean

            activeFloorLevelStore.update(
                when (activeFloorLevelStore.current) {
                    prev -> next
                    next -> prev
                    else -> if (isLevelUp == true) prev else next
                },
            )
        }
    }
}
