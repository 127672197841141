package twcomponents

import apiclient.users.PublicUserProfile
import apiclient.users.UserProfileSummary
import apiclient.users.toUserProfileSummary
import auth.ApiUserStore
import data.users.UserListStore
import dev.fritz2.core.Store
import dev.fritz2.core.invoke
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import koin.koinCtx
import kotlinx.coroutines.flow.flowOf
import search.simpleUserProfileListEntry


fun twUserSelectModal(
    toggleShowModalStore: Store<Boolean>,
    selectedUserStore: Store<PublicUserProfile?>? = null,
    selectedUserProfileSummaryStore: Store<UserProfileSummary?>? = null
) {
    twSimpleModalWithCloseHeader(
        titleAsFlow = flowOf("Select User"),
        toggleStore = toggleShowModalStore,
    ) { close, _, optionalCloseHandlers ->
        div(
            baseClass = "flex flex-col gap-3 w-full overflow-y-auto p-2",
            id = "user-select",
        ) {
            val apiUserStore: ApiUserStore by koinCtx.inject()
            val userListStore: UserListStore by koinCtx.inject()
            val userSearchInputQueryStore = storeOf("")
            val userFilteredResultsStore = storeOf(userListStore.current)

            val myUserId = apiUserStore.current.userId

            userSearchInputQueryStore.data handledBy { query ->
                userFilteredResultsStore.update(
                    userListStore.current?.filter { user ->
                        user.name.contains(query, true)
                    },
                )
            }

            // Search input to filter users
            twInputField(userSearchInputQueryStore) {
                twInputTextField {
                    doOnceWhenElementInDOM(domNode = domNode) {
                        domNode.focus()
                    }
                    placeholder("Type to search user...")
                }
            }
            // results list
            div(
                "flex flex-col w-full h-80 gap-2 overflow-y-auto",
            ) {
                userFilteredResultsStore.data.render { userList ->
                    userList?.firstOrNull { myUserId == it.userId }?.let { myUser ->
                        div("flex flex-row w-full h-max items-center justify-between p-1 cursor-pointer hover:bg-gray-100 rounded-xl") {
                            simpleUserProfileListEntry(myUser.toUserProfileSummary())
                            clicks handledBy {
                                selectedUserStore?.let { store ->
                                    store.update(myUser)
                                }
                                selectedUserProfileSummaryStore?.let { store ->
                                    store.update(myUser.toUserProfileSummary())
                                }
                                optionalCloseHandlers?.let { handlers ->
                                    handlers.forEach {
                                        it.invoke()
                                    }
                                }
                                close()
                            }
                        }
                    }
                    userList?.filter { it.userId != myUserId }?.forEach { userEntry ->
                        div("flex flex-row w-full h-max items-center justify-between p-1 cursor-pointer hover:bg-gray-100 rounded-xl") {
                            simpleUserProfileListEntry(userEntry.toUserProfileSummary())
                            clicks handledBy {
                                selectedUserStore?.let { store ->
                                    store.update(userEntry)
                                }
                                selectedUserProfileSummaryStore?.let { store ->
                                    store.update(userEntry.toUserProfileSummary())
                                }
                                optionalCloseHandlers?.let { handlers ->
                                    handlers.forEach {
                                        it.invoke()
                                    }
                                }
                                close()
                            }
                        }
                    }
                }
            }
        }
    }
}
