package data.objects.views.directediting

import apiclient.geoobjects.AuthChange
import apiclient.geoobjects.ObjectChanges
import apiclient.geoobjects.applyObjectChanges
import apiclient.users.UserProfileSummary
import data.objects.ActiveObjectStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.core.storeOf
import koin.withKoin
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import overlays.withBusyApiClient
import search.simpleUserProfileListEntry
import theme.FormationUIIcons
import twcomponents.twColOfCenter
import twcomponents.twIconLarge
import twcomponents.twIconMedium
import twcomponents.twPrimaryButton
import twcomponents.twSimpleModalWithCloseHeader
import twcomponents.twSubtitle
import twcomponents.twUserSelectModal
import utils.getName
import utils.insertObjectInCachesAndMap
import workspacetools.usermanagement.confirm

fun twOwnershipTransferModal(toggleShowModalStore: Store<Boolean>) {
    withKoin {
        val activeObjectStore: ActiveObjectStore = get()

        val selectedUserStore: Store<UserProfileSummary?> = storeOf(null, Job())
        val userSelectModalToggleStore: Store<Boolean> = storeOf(false, Job())

        twUserSelectModal(
            toggleShowModalStore = userSelectModalToggleStore,
            selectedUserProfileSummaryStore = selectedUserStore,
        )

        twSimpleModalWithCloseHeader(
            "w-96",
            titleAsFlow = flowOf("Transfer Ownership of this ${activeObjectStore.current.objectType.getName()}"), // TODO translate
            toggleStore = toggleShowModalStore,
        ) { closeHandler, _, optionalCloseHandlers ->
            twColOfCenter {
                className("px-2 pb-2 overflow-auto")

                twSubtitle {
                    +"Current Owner:" // TODO translate
                }
                activeObjectStore.current.owner?.let {
                    div("flex flex-row w-full items-center justify-center rounded-xl border-2 border-formationBlack overflow-hidden") {
                        simpleUserProfileListEntry(it)
                    }
                }
                twSubtitle {
                    className("text-red-500 font-bold")
                    +"Transfer Ownership to" // TODO translate
                }
                div("text-red-500") {
                    twIconLarge(FormationUIIcons.ArrowDown)
                }

                selectedUserStore.data.render { userProfileSummary ->
                    div("flex flex-row items-center w-full justify-between cursor-pointer rounded-xl bg-gray-100 hover:bg-gray-200 border-2 border-red-500 overflow-hidden") {
                        if (userProfileSummary != null) {
                            simpleUserProfileListEntry(userProfileSummary)
                        } else {
                            div("flex items-center justify-center h-12 pl-4") { +"Select User.." } // TODO translate
                        }
                        div("pr-2") {
                            twIconMedium(FormationUIIcons.Select)
                        }
                        clicks handledBy {
                            userSelectModalToggleStore.update(true)
                        }
                    }

                    selectedUserStore.data.render { selectedOwnerProfile ->
                        // button to confirm assignment of new owner
                        twPrimaryButton(
                            disabledFlow = selectedUserStore.data.map { it == null || it.userId == activeObjectStore.current.ownerId },
                        ) {
                            p("text-sm font-bold") {
                                +"CONFIRM" // TODO translate
                            }
                            if (selectedOwnerProfile != null) {
                                clicks handledBy confirm(
                                    // TODO translate
                                    text = flowOf("Do you really want to transfer the ownership of \"${activeObjectStore.current.title}\" to ${selectedOwnerProfile.name}? (You will lose the rights to revert this action if you are not an admin.)"),
                                    okHandlers = listOf(
                                        SimpleHandler { data, _ ->
                                            data handledBy {
                                                withBusyApiClient(
                                                    { client ->
                                                        client.applyObjectChanges(
                                                            ObjectChanges(
                                                                activeObjectStore.current.id,
                                                                AuthChange.ChangeOwner(selectedOwnerProfile.userId),
                                                            ),
                                                        )
                                                    },
                                                ) { results ->
                                                    results.firstOrNull()?.let { obj ->
                                                        activeObjectStore.updateActiveObject(obj)
                                                        insertObjectInCachesAndMap(obj)
                                                    }
                                                }
                                            }
                                        },
                                        SimpleHandler<Unit> { data, _ ->
                                            data handledBy {
                                                selectedUserStore.update(null)
                                            }
                                        },
                                    ) + (optionalCloseHandlers ?: emptyList()) + listOf(closeHandler),
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}
