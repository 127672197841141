package data.users.views

import apiclient.FormationClient
import apiclient.geoobjects.Content
import apiclient.geoobjects.OpenGraphMetadata
import apiclient.opengraph.linkPreview
import apiclient.usermessage.MessageToUser
import apiclient.usermessage.sendMessageToUser
import apiclient.users.PublicUserProfile
import apiclient.util.isNotNullOrEmpty
import auth.ApiUserStore
import com.tryformation.localization.Translatable
import data.objects.views.directediting.renderWebLink
import data.users.ActiveUserStore
import dev.fritz2.components.compat.img
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.autofocus
import dev.fritz2.core.disabled
import dev.fritz2.core.href
import dev.fritz2.core.id
import dev.fritz2.core.placeholder
import dev.fritz2.core.src
import dev.fritz2.core.storeOf
import dev.fritz2.core.target
import dev.fritz2.core.title
import dev.fritz2.core.type
import io.ktor.util.*
import koin.koinCtx
import koin.withKoin
import kotlin.collections.set
import kotlin.random.Random
import kotlin.random.nextULong
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import model.getFirstGroupIdOrNull
import org.w3c.dom.Element
import overlays.BusyStore
import theme.FormationIcons
import theme.FormationUIIcons
import theme.IconEnum
import twcomponents.twColOf
import twcomponents.twColOfNoGap
import twcomponents.twContentBoxOf
import twcomponents.twFlatBoxRowCenter
import twcomponents.twFlatCopyClipboardButton
import twcomponents.twFlatIconBox
import twcomponents.twFullWidthTextArea
import twcomponents.twIconMedium
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twInputTextFieldBold
import twcomponents.twLeftAlignedRow
import twcomponents.twMediumIconButtonRed
import twcomponents.twMultiLineTextareaTextfield
import twcomponents.twPrimaryButton
import twcomponents.twRevertButton
import twcomponents.twRightAlignedButtonRow
import twcomponents.twRowOfJustifyBetween
import twcomponents.twRowOfNoGap
import twcomponents.twSecondaryButton
import twcomponents.twSimpleModalWithCloseHeader
import twcomponents.twTextLinkButton
import utils.ToggleStore
import utils.merge
import webcomponents.Position
import webcomponents.genericSmallIconButton
import webcomponents.inputLabelWrapper
import webcomponents.twContentScrollBox

fun RenderContext.profileInput(
    id: String,
    title: Translatable? = null,
    titleAsFlow: Flow<String> = flowOf(""),
    icon: IconEnum? = null,
    placeHolder: Flow<String> = flowOf(""),
    store: Store<String>,
    tabIndex: Flow<Int>? = null,
    autofocus: Boolean = false,
    disabled: Flow<Boolean> = flowOf(false),
    type: Flow<String>? = null,
    rightInsideInputContent: (HtmlTag<Element>.() -> Unit)? = null,
    belowInputContent: (HtmlTag<Element>.() -> Unit)? = null,
    rightInRowWithInputContent: (HtmlTag<Element>.() -> Unit)? = null,
) {

    fun RenderContext.input() = twColOf {
//        className("w-full")
        twRowOfJustifyBetween {
            twInputField(store) {
                className(disabled.map { if (it) "bg-gray-300 border-gray-300 text-slate-500" else "" })
                icon?.let {
                    twLeftAlignedRow {
                        twIconMedium(icon = icon)
                    }
                }
                twInputTextField {
                    id(id)
                    type?.let { type(type) }
                    disabled(disabled)
                    autofocus(autofocus)
                    placeholder(placeHolder)
                    tabIndex?.let { indexFlow ->
                        attr("tabindex", indexFlow.map { "$it" })
                    }
                }
                twRightAlignedButtonRow {
                    combine(store.data, disabled) { da, di -> Pair(da, !di) }.render { (text, isNotDisabled) ->
                        if (text.isNotBlank() && isNotDisabled) {
                            twRevertButton(store, "")
                        }
                    }
                    rightInsideInputContent?.invoke(this)
                }
            }
            rightInRowWithInputContent?.invoke(this)
        }
        belowInputContent?.let { it() }
    }

    if (title != null) {
        inputLabelWrapper(
            title = title,
            visibilityFlow = store.data.map { it.isNotNullOrEmpty() },
        ) {
            input()
        }
    } else {
        // Fallback for non-translatable title
        inputLabelWrapper(
            titleAsFlow,
            visibilityFlow = store.data.map { it.isNotNullOrEmpty() },
        ) {
            input()
        }
    }

}

fun RenderContext.profileEmailSection(
    emails: List<String>,
    userName: String? = null,
    showFullEmail: Boolean = false
) {
    if (emails.isNotEmpty()) {
        withKoin {
            val translation: Translation = koinCtx.get()

            emails.forEachIndexed { index, address ->
                when (index) {
                    // Account Email
                    0 -> {
                        val contactUserToggle = ToggleStore(false)
                        /* Contact Request Form popup when email hidden on public Profile or as alternative */
                        contactUserPopup(userName, address, contactUserToggle)

                        if (showFullEmail) {
                            inputLabelWrapper(
                                title = TL.UserProfile.ACCOUNT_EMAIL,
                                visibilityFlow = flowOf(true),
                            ) {
                                twRowOfJustifyBetween {
                                    className("w-full")
                                    profileLinkButton(
                                        link = "mailto:$address",
                                        icon = FormationIcons.Mail,
                                        title = address,
                                        contentRight = {
                                            p("text-sm font-bold") { +"SEND" }
                                        },
                                    )
                                    div {
                                        twSecondaryButton(icon = FormationUIIcons.WriteMessage) {
                                            clicks handledBy contactUserToggle.toggle
                                        }
                                    }
                                }
                            }
                        } else if (emails.size == 1) {
                            inputLabelWrapper(
                                title = TL.UserProfile.CONTACT_EMAIL,
                                visibilityFlow = flowOf(true),
                            ) {
                                twRowOfJustifyBetween {
                                    className("w-full")
                                    profileButton(
                                        icon = FormationIcons.Mail,
                                        disabledFlow = flowOf(true),
                                    ) {
                                        className("flex-grow overflow-hidden")
                                        p("truncate min-w-0 text-sm font-bold") { +maskEmailAddress(address) }
                                    }
                                    div {
                                        twSecondaryButton(icon = FormationUIIcons.WriteMessage) {
                                            clicks handledBy contactUserToggle.toggle
                                        }
                                    }
                                }
                            }

                        } else {
                            /* Don't show Account Email on public Profile */
                        }
                    }
                    // Public Email
                    1 -> {
                        inputLabelWrapper(
                            title = TL.UserProfile.CONTACT_EMAIL,
                            visibilityFlow = flowOf(true),
                        ) {
                            profileLinkButton(
                                link = "mailto:$address",
                                icon = FormationIcons.Mail,
                                title = address,
                                contentRight = {
                                    p("text-sm font-bold") { +"SEND" }
                                },
                            )
                        }
                    }
                    // Additional Emails
                    else -> {
                        inputLabelWrapper(
                            title = translation[TL.UserProfile.EMAIL].merge(flowOf("#${index}")),
                            visibilityFlow = flowOf(true),
                        ) {
                            profileLinkButton(
                                link = "mailto:$address",
                                icon = FormationIcons.Mail,
                                title = address,
                                contentRight = {
                                    p("text-sm font-bold") { +"SEND" }
                                },
                            )
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.profilePhoneInputRemoveButton(
    index: Int,
    store: Store<String>,
    activeInputsStore: Store<Map<Int, Boolean>>,
) {
    twMediumIconButtonRed(icon = FormationIcons.DeleteAlt) {
        className("px-2")
        clicks.handledBy {
            store.update("")
            val mutable = activeInputsStore.current.toMutableMap()
            mutable[index] = false
            activeInputsStore.update(mutable)
        }
    }
}

/**
 * Helper function that removes the element at the given index
 * and then re-indexes all remaining elements
 */
fun <T> MutableMap<Int, T>.removeAndReIndex(removeAt: Int) {
    remove(removeAt)
    val reIndexed = values.mapIndexed { index, value -> index to value }.toMap()
    clear()
    putAll(reIndexed)
}

fun RenderContext.profileLinkButton(
    link: String,
    icon: IconEnum? = null,
    title: String? = link,
    target: String? = null,
    grow: Boolean = true,
    contentLeft: (HtmlTag<Element>.() -> Unit)? = null,
    contentRight: (HtmlTag<Element>.() -> Unit)? = null,
) {
    a("min-w-0") {
        if (grow) className("flex flex-grow")
        div("flex flex-row items-center w-full px-4 py-2 h-10 gap-3 bg-gray-100 hover:bg-gray-300 text-formationBlack cursor-pointer rounded-xl transition-color duration-300 ease-in-out") {
            className(if (contentRight != null) "justify-between" else "justify-start")
            className(if (grow) "flex-grow" else "")
            div("flex flex-row items-center justify-start gap-3 overflow-hidden") {
                contentLeft?.invoke(this)
                icon?.let { twIconMedium(icon) }
                title?.let {
                    p("truncate text-xs font-bold") { +title }
                }
            }
            contentRight?.invoke(this)
        }
        href(link)
        title(link)
        target?.let { target(target) }
    }
}

fun RenderContext.profileButton(
    icon: IconEnum? = null,
    grow: Boolean = true,
    disabledFlow: Flow<Boolean> = flowOf(false),
    content: (HtmlTag<Element>.() -> Unit)? = null,
) =
    div("flex flex-row items-center justify-start w-full text-sm font-bold px-4 py-2 h-10 gap-3 bg-gray-100 text-formationBlack hover:bg-gray-300 cursor-pointer rounded-xl transition-color duration-300 ease-in-out") {
        className(if (grow) "flex-grow" else "")
        className(disabledFlow.map { if (it) "bg-gray-300 text-slate-500" else "" })
        icon?.let { twIconMedium(icon) }
        content?.invoke(this)
    }


fun RenderContext.profilePhoneNumberButton(number: String, icon: IconEnum) {
    twRowOfJustifyBetween {
        profileLinkButton(
            link = "tel:$number",
            icon = icon,
            title = number,
            contentRight = {
                p("text-sm font-bold") { +"CALL" }
            },
        )

        // TODO only show SMS if it is mobile number?
        profileLinkButton(
            link = "sms:$number",
            icon = FormationUIIcons.Message,
            title = null,
            grow = false,
            contentLeft = {
                p("text-sm font-bold") { +"SMS" }
            },
        )
    }
}

fun RenderContext.profilePhoneNumberSection(phoneNumbers: List<String>) {
    if (phoneNumbers.isNotEmpty()) {
        withKoin {
            val translation: Translation = koinCtx.get()
            twColOf {
                className("w-full py-2")
                phoneNumbers.forEachIndexed { index, number ->
                    if (number.isNotBlank()) {
                        when (index) {
                            0 -> {
                                inputLabelWrapper(
                                    title = TL.UserProfile.CELLPHONE,
                                    visibilityFlow = flowOf(true),
                                ) {
                                    profilePhoneNumberButton(number, FormationIcons.Phone)
                                }
                            }

                            else -> {
                                inputLabelWrapper(
                                    title = translation[TL.UserProfile.PHONE_NUMBER].merge(flowOf("#${index + 1}")),
                                    visibilityFlow = flowOf(true),
                                ) {
                                    profilePhoneNumberButton(number, FormationIcons.TelephoneTypeWriter)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.profileLinkPreview(link: String?) {
    link?.let { site ->
        if (site.startsWith("http")) {
            val preview = storeOf<OpenGraphMetadata?>(null)
            flowOf(site) handledBy {
                withKoin {
                    val client = get<FormationClient>()
                    preview.update(
                        client.linkPreview(site).getOrNull(),
                    )
                }
            }
            preview.data.render { ogm ->
                if (ogm != null) {
                    twContentBoxOf {
                        className("-mx-2")
                        renderWebLink(
                            Content.WebLink(
                                Random.nextULong().toString(),
                                ogm.url ?: "",
                                title = ogm.title,
                                openGraphMetadata = ogm,
                            ),
                        )
                    }
                } else {
                    twRowOfNoGap {
                        twFlatIconBox {
                            twIconMedium(FormationUIIcons.ExternalLink)
                        }
                        twFlatBoxRowCenter {
                            twTextLinkButton {
                                target("_blank")
                                href(link)
                                +link
                            }
                        }
                        twFlatCopyClipboardButton(link)
                    }
                }
            }
        }
    }
}

fun RenderContext.profileLinkPreviewList(linkList: List<String>?) {
    if (linkList.isNotNullOrEmpty()) {
        twColOfNoGap {
            linkList?.forEach { link ->
                profileLinkPreview(link)
            }
        }
    }
}

fun maskEmailAddress(email: String): String {
    return email.substring(0, 1) + email.drop(1).substringBefore("@").take(10).map { "*" }
        .joinToString("") + "@" + email.substringAfter("@").substring(0, 1) +
        email.substringAfter("@").drop(1).substringBeforeLast(".").take(10).map { "*" }
            .joinToString("") + "." + email.substringAfterLast(".")
}

fun maskEmailAddressEmoji(email: String): String {
    return email.substring(0, 1) + "**\uD83E\uDD14*❓*\uD83E\uDD37\u200D♂\uFE0F*\uD83E\uDD37\u200D♀\uFE0F*\uD83D\uDC40*" +
        "@" + email.substringAfter("@").substring(0, 1) +
        "**\uD83E\uDD37\u200D♂\uFE0F*❓*\uD83E\uDD37\u200D♀\uFE0F*\uD83E\uDDD0*" + email.substringAfterLast(".")
}


fun contactUserPopup(
    recipientName: String? = null,
    recipientEmailAddress: String,
    popupToggle: Store<Boolean>
) = twSimpleModalWithCloseHeader(
    titleAsFlow = flowOf("Write a Message"),
    toggleStore = popupToggle,
) { close, _, optionalCloseHandlers ->

    withKoin {
        val busyStore: BusyStore = koinCtx.get()
        val apiUserStore: ApiUserStore = koinCtx.get()
        val formationClient: FormationClient = koinCtx.get()
        val subjectStore = storeOf("")
        val messageStore = storeOf("")

        twContentScrollBox("gap-2") {
            p("text-sm") {
                +"Start the conversation${recipientName?.let { " with $it" } ?: ""}." // TODO translate
            }
            inputLabelWrapper(
                title = flowOf("Subject"), // TODO translate
                visibilityFlow = subjectStore.data.map { it.isNotBlank() },
            ) {
                twInputField(subjectStore) {
                    twInputTextFieldBold {
                        placeholder("Subject of your message...") // TODO translate
                    }
                }
            }
            inputLabelWrapper(
                title = flowOf("Message"), // TODO translate
                visibilityFlow = messageStore.data.map { it.isNotBlank() },
            ) {
                twFullWidthTextArea(messageStore) {
                    this.twMultiLineTextareaTextfield {
                        className("h-48")
                        placeholder("Write your message here..") // TODO translate
                    }
                }
            }
            twRowOfJustifyBetween {
                p("text-xs") {
                    +"(Any response to this message will directly go to your email inbox.)" // TODO translate
                }
                twPrimaryButton(
                    icon = FormationUIIcons.Send,
                    disabledFlow = combine(subjectStore.data, messageStore.data) { sub, msg -> sub.isBlank() && msg.isBlank() },
                ) {
                    p("text-xs font-bold") { +"SEND" }
                    clicks handledBy {
                        apiUserStore.current.getFirstGroupIdOrNull()?.let { groupId ->
                            busyStore.handleApiCall(
                                supplier = {
                                    formationClient.sendMessageToUser(
                                        MessageToUser(
                                            groupId,
                                            subjectStore.current,
                                            messageStore.current,
                                            recipientName ?: "FORMATION User",
                                            recipientEmailAddress,
                                        ),
                                    )
                                },
                                successMessage = flowOf("Message send to ${(recipientName ?: "FORMATION User")}"),
                                processResult = {
                                    optionalCloseHandlers?.let { handlers ->
                                        handlers.forEach {
                                            it.invoke(Unit)
                                        }
                                    }
                                    close(Unit)
                                },
                                errorMessage = flowOf("Failed to send message."),
                                processError = {
                                    console.log("Failed to send message to user $recipientName")
                                },
                            )
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.vCardQR(vCard: String, svgContent: String, downloadFileName: String) {
    withKoin {
        val translation: Translation = koinCtx.get()

        twColOf {
//            img("w-full max-w-72 mx-auto") {
//                src(svgContent)
//            }
            img(
                {
                    css(
                        """
                    animation: verifyqrcode 1 2s ease;
                    @keyframes verifyqrcode {
                        0% { opacity: 0; }
                        100% { opacity: 1; }
                    }
                    """.trimIndent(),
                    )
                    margins {
                        horizontal { auto }
                    }
                    width { full }
                    maxWidth { "300px" }
                },
            ) {
                src(svgContent)
            }

            div("p-2") {
                genericSmallIconButton(
                    icon = { download },
                    iconPosition = Position.Left,
                    title = translation[TL.General.DOWNLOAD],
                    hrefOrValue = "data:text/vcard;charset=utf-8;base64,${vCard.encodeBase64()}",
                    isLink = true,
                    downloadValue = "${downloadFileName}.vcard",
                )
            }
        }
    }
}

fun RenderContext.profileVCardSection(publicProfile: PublicUserProfile) {
    withKoin {

        val activeUserStore: ActiveUserStore = koinCtx.get()

        if (publicProfile.allowVCardInPublicProfile == true) {
            combine(
                activeUserStore.vcardStore.data.filterNotNull(),
                activeUserStore.vcardQrCodeStore.data.filterNotNull(),
            ) { vcard, svg ->
                Pair(vcard, svg)
            }.render { (vcard, svgContent) ->
//                console.log(vcard)
                vCardQR(vcard, svgContent, publicProfile.name)
            }
        }
    }
}
