// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package model

import apiclient.geoobjects.Content
import apiclient.users.UserFeatureFlag
import apiclient.users.UserPreferences
import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf

fun MyProfile.Companion.firstName(): Lens<MyProfile, String> = lensOf(
    "firstName",
    { it.firstName },
    { p, v -> p.copy(firstName = v) },
)

fun MyProfile.Companion.lastName(): Lens<MyProfile, String> = lensOf(
    "lastName",
    { it.lastName },
    { p, v -> p.copy(lastName = v) },
)

fun MyProfile.Companion.fullName(): Lens<MyProfile, String> = lensOf(
    "fullName",
    { it.name },
    { p, v ->
        val split = v.split(" ")
        if (split.size > 1) {
            p.copy(
                firstName = split[0],
                lastName = split[1],
            )
        } else {
            p.copy(firstName = v)
        }
    },
)

fun MyProfile.Companion.jobTitle(): Lens<MyProfile, String> = lensOf(
    "jobTitle",
    { it.jobTitle },
    { p, v -> p.copy(jobTitle = v) },
)

fun MyProfile.Companion.emails(): Lens<MyProfile, List<String>> = lensOf(
    "emails",
    { it.emails },
    { p, v -> p.copy(emails = v) },
)

fun MyProfile.Companion.password(): Lens<MyProfile, String> = lensOf(
    "password",
    { it.password },
    { p, v -> p.copy(password = v) },
)

fun MyProfile.Companion.phoneNumbers(): Lens<MyProfile, List<String>> = lensOf(
    "phoneNumbers",
    { it.phoneNumbers },
    { p, v -> p.copy(phoneNumbers = v) },
)

fun MyProfile.Companion.preferences(): Lens<MyProfile, UserPreferences?> = lensOf(
    "preferences",
    { it.preferences },
    { p, v -> p.copy(preferences = v) },
)

//fun MyProfile.Companion.responseStatus(): Lens<MyProfile, StatusResult?> = lensOf(
//    "responseStatus",
//    { it.responseStatus },
//    { p, v -> p.copy(responseStatus = v) }
//)

fun MyProfile.Companion.profilePhoto(): Lens<MyProfile, Content.Image?> = lensOf(
    "profilePhoto",
    { it.profilePhoto },
    { p, v -> p.copy(profilePhoto = v) },
)

fun MyProfile.Companion.featureFlags(): Lens<MyProfile, Map<UserFeatureFlag, Boolean>?> = lensOf(
    "featureFlags",
    { it.featureFlags },
    { p, v -> p.copy(featureFlags = v) },
)

fun MyProfile.Companion.keywords(): Lens<MyProfile, List<String>> = lensOf(
    "keywords",
    { it.keywords },
    { p, v -> p.copy(keywords = v) },
)

fun MyProfile.Companion.company(): Lens<MyProfile, String> = lensOf(
    "company",
    { it.company },
    { p, v -> p.copy(company = v) },
)

fun MyProfile.Companion.linkedInLink(): Lens<MyProfile, String> = lensOf(
    "linkedInLink",
    { it.linkedInLink },
    { p, v -> p.copy(linkedInLink = v) },
)

fun MyProfile.Companion.websiteLink(): Lens<MyProfile, String> = lensOf(
    "websiteLink",
    { it.websiteLink },
    { p, v -> p.copy(websiteLink = v) },
)

fun MyProfile.Companion.webLinks(): Lens<MyProfile, List<String>> = lensOf(
    "webLinks",
    { it.webLinks },
    { p, v -> p.copy(webLinks = v) },
)

fun MyProfile.Companion.allowEmailInPublic(): Lens<MyProfile, Boolean> = lensOf(
    "allowEmailInPublic",
    { it.allowEmailInPublic },
    { p, v -> p.copy(allowEmailInPublic = v) },
)

fun MyProfile.Companion.allowVCardInPublic(): Lens<MyProfile, Boolean> = lensOf(
    "allowVCardInPublic",
    { it.allowVCardInPublic },
    { p, v -> p.copy(allowVCardInPublic = v) },
)
