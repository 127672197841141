package map.views

import analytics.AnalyticsAction
import analytics.AnalyticsCategory
import apiclient.websocket.MessageToServer
import auth.FeatureFlagStore
import auth.Features
import data.objects.ActiveObjectStore
import data.objects.building.ActiveBuildingStore
import data.objects.building.ActiveFloorLevelStore
import data.objects.building.CurrentBuildingsStore
import data.users.settings.LocalSettingsStore
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.title
import dev.fritz2.routing.MapRouter
import dev.fritz2.styling.params.BasicParams
import dev.fritz2.styling.params.ScaledValueProperty
import dev.fritz2.styling.params.Style
import dev.fritz2.styling.params.plus
import dev.fritz2.styling.params.rgba
import dev.fritz2.styling.params.shadow
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import location.LocationFollowStore
import location.LocationUploadStore
import mainmenu.Pages
import mainmenu.RouterStore
import map.Cards
import map.MapStateStore
import map.bottombar.addCircleButtonStyle
import measuringTool.measuringToolButton
import model.LocationUploadState
import model.backgroundJob
import notifications.GlobalNotificationResultsStore
import overlays.TickerOverlayStore
import overlays.dotSpinner
import search.searchlayer.MapSearchClientsStore
import services.GeoPositionService
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.twColOf
import twcomponents.twFeatureFlagDiv
import twcomponents.twIconMedium
import twcomponents.twRowOfJustifyBetween
import utils.makeRGBA
import webcomponents.circleIconButton
import webcomponents.fullPageConfirmationContainer

fun RenderContext.bottomRightSideControls() {

    // FLOATING SIDE BUTTONS
    twColOf {
        className("items-end justify-center mb-2 mr-2 pointer-events-none")

        floorSelector()
        twFeatureFlagDiv(flag = Features.DisableLocationSharing, flagValue = false) {
            sharingButton()
        }
        twFeatureFlagDiv(flag = Features.EnableDistanceMeasuringTool) {
            measuringToolButton()
        }
        mapLayerSelectorButton()
        locateOrFollowMeButton()
    }
}

fun RenderContext.bottomLeftSideControls() {

    val translation by koinCtx.inject<Translation>()
    val activeObjectStore by koinCtx.inject<ActiveObjectStore>()
    val featureFlagStore by koinCtx.inject<FeatureFlagStore>()

    featureFlagStore.data.render { features ->
        // ADD BUTTON
        flexBox(
            prefix = "AddButtonHolder",
            styling = {
                position(
                    sm = {
                        relative {
                            left { "5px" }
                            bottom { "5px" }
                        }
                    },
                    md = {
                        relative {
                            left { "5px" }
                            bottom { "63px" }
                        }
                    },
                )
                justifyContent { center }
                alignItems { center }
            },
        ) {
            flexBox(
                {
                    direction { column }
                    justifyContent { center }
                    alignItems { center }
                    wrap { nowrap }
                },
            ) {
                circleIconButton(
                    iconFlow = flowOf { FormationUIIcons.Add.icon },
                    size = { "46px" },
                    iconSize = { giant },
                    styleFlow = flowOf(addCircleButtonStyle),
                    tooltipText = translation[TL.BottomBar.ADD_TOOLTIP],
                    onlyDisabledLook = features[Features.DisablePlusButton] == true,
                    value = Unit,
                    clickHandlers = listOf(activeObjectStore.resetStore),
                    routingMap = mapOf("card" to Cards.Select.name),
                    analyticsEventProvider = AnalyticsCategory.AddContentButton.click(),
                )
//                span({
//                    fontSize { smaller }
//                    fontWeight { lighter }
//                    margins {
//                        top { smaller }
//                    }
//                }) { translation[TL.BottomBar.ADD].renderText(into = this) }
            }
        }
    }
}

fun RenderContext.topRightSideControls() {

    val router: MapRouter by koinCtx.inject()
    val globalNotificationResultsStore: GlobalNotificationResultsStore by koinCtx.inject()
    val tickerOverlayStore: TickerOverlayStore by koinCtx.inject()

//    val notificationBarOrTickerEnabled = router.data.combine(
//        globalNotificationResultsStore.data.map { it.unreadNumber },
//    ) { route, unread ->
//        if (route["page"] == Pages.Map.name && route["ws"] != null && route.keys.size <= 2) {
//            unread > 0
//        } else {
//            route["page"] == Pages.AnalyticsDashboard.name
//        }
//    }.combine(tickerOverlayStore.data) { a, b -> a || b.enabled }

    val analyticsOrTickerEnabled = combine(router.data, tickerOverlayStore.data) { route, ticker ->
        route["page"] == Pages.AnalyticsDashboard.name || ticker.enabled
    }
    // FLOATING SIDE BUTTONS
//    notificationBarOrTickerEnabled.render { barIsVisible ->
    analyticsOrTickerEnabled.render { moveControlsDown ->
        flexBox(
            styling = {
                direction { column }
                justifyContent { center }
                alignItems { center }
                margins {
                    if (moveControlsDown) top { "40px" } else top { smaller }
                    right { "-3px" }
                }
            },
        ) {
            zoomControls()
        }
    }
}

fun RenderContext.mapProcessSpinnerOverlay() {
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val mapSearchClientsStore: MapSearchClientsStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val router: MapRouter by koinCtx.inject()

    combine(
        activeObjectStore.createTracker.data,
        activeObjectStore.updateTracker.data,
        activeObjectStore.deleteTracker.data,
    ) { c, u, d -> Triple(c, u, d) }.combine(
        combine(
            mapSearchClientsStore.mapSearchTracker.data,
            activeObjectStore.attachmentTracker.data,
            router.data,
        ) { s, a, r -> Triple(s, a, r) },
    ) { crud, sar -> Pair(crud, sar) }.render { (crud, searchAttachRoute) ->
        val (create, update, delete) = crud
        val (search, attach, route) = searchAttachRoute
        if (route["page"] == Pages.Map.name && (create || update || delete || search || attach)) {
//            flexBox ({
//                position { absolute { top { "85%" }; left { "50%" } } }
//                zIndex { "1020" }
//                background { color { makeRGBA(primary.main, 0.6) } }
//                color { secondary.main }
//                radius(formationStyles.buttonRadius)
//                padding { smaller }
//                css("transform: translate(-50%, -50%);")
//                alignItems { center }
//                justifyContent { center }
//            }, id = "spinner") {
//                span({
//                    margins { right { small }}
//                }) {
//                    when {
//                        create -> translation[TL.SpinnerTexts.CREATING].renderText(into = this)
//                        update -> translation[TL.SpinnerTexts.UPDATING].renderText(into = this)
//                        delete -> translation[TL.SpinnerTexts.DELETING].renderText(into = this)
//                        attach -> translation[TL.SpinnerTexts.ATTACHING].renderText(into = this)
//                        search -> translation[TL.SpinnerTexts.SEARCHING].renderText(into = this)
//                    }
//                }
//                spinner({
//                    size { normal }
//                }) {
//                    thickness { normal }
//                    speed("1s")
//                }
//            }
            div(
                {
                    position {
                        absolute {
                            top { "85%" }
                            left { "50%" }
                        }
                    }
                    css("transform: translate(-50%, -50%);")
                    zIndex { "1040" }
                },
            ) {
                fullPageConfirmationContainer(
                    width = { maxContent },
                    margins = { },
                    paddings = {
                        horizontal { small }
                        vertical { small }
                    },
                ) {
                    lineUp(
                        {
                            width { full }
                            justifyContent { center }
                            alignItems { center }
                        },
                    ) {
                        spacing { small }
                        items {
                            span(
                                {
                                    textAlign { center }
                                    fontSize { small }
                                    fontWeight { bold }
                                    margins { right { tiny } }
                                },
                            ) {
                                when {
                                    create -> translation[TL.SpinnerTexts.CREATING].renderText(into = this)
                                    update -> translation[TL.SpinnerTexts.UPDATING].renderText(into = this)
                                    delete -> translation[TL.SpinnerTexts.DELETING].renderText(into = this)
                                    attach -> translation[TL.SpinnerTexts.ATTACHING].renderText(into = this)
                                    search -> translation[TL.SpinnerTexts.SEARCHING].renderText(into = this)
                                }
                            }
                            dotSpinner(pixelSize = 6)
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.floorSelector() {

    val currentBuildingsStore: CurrentBuildingsStore by koinCtx.inject()
    val activeBuildingStore: ActiveBuildingStore by koinCtx.inject()

    combine(currentBuildingsStore.data, activeBuildingStore.data) { b, a -> a to b }.render { (activeBuildingId, buildings) ->
        val show = activeBuildingId != null && buildings[activeBuildingId]?.activeFloorIds != null

        if (show) {
//            val floorData = buildings.map { (a, b) -> b.floorData }.reduce { acc, mutableMap ->
//                ((acc?: emptyMap()) + (mutableMap?: emptyMap())).toMutableMap()
//            }
            val floorData = buildings[activeBuildingId]?.floorData
            console.log("FLOORDATA:", floorData?.map { it.value.map { floor -> floor.floor.title } }.toString())
            if (floorData != null && floorData.size > 1) {
                div(
                    {
                        maxHeight { "168px" }
                        width(formationStyles.sideButtonSize)
                        background { color { "white" } }
                        radius(floorSelectorRadius)
                        border { width(formationStyles.borderWidth); color { primary.main } }
                        overflow { scroll }
                        css(
                            """
                        -ms-overflow-style: none;  /* IE and Edge */
                        scrollbar-width: none;  /* Firefox */
                        """.trimIndent(),
                        )
                        boxShadow {
                            shadow("2px", "4px", "10px", color = rgba(0, 0, 0, 0.35))
                        }

                    },
                ) {
                    //TODO: hide scrollbar for Chrome, Safari and Opera also with inline css in floorSelectorStyle
                    className("floorSelector pointer-events-auto") // required for hiding scrollbar
                    div {
                        stackUp(
                            {
                                overflow { auto }
                                width { full }
                                height { auto }
                                justifyContent { center }
                                alignItems { stretch }
                            },
                        ) {
                            spacing { "0" }
                            items {
                                // highest floor has always floorRange.maxOrNull() as number
                                floorButton(
                                    floorData.keys.maxOrNull()!!,
                                    style = floorSelectorTopButtonStyle,
                                    tooltipText = floorData[floorData.keys.maxOrNull()!!]?.map { floor -> floor.floor.title }.toString(),
                                )
                                if (floorData.size > 2) {
                                    // insert all floors in between here without radius
                                    floorData.keys.sorted().drop(1).dropLast(1).sortedDescending().forEach { level ->
                                        floorButton(
                                            level,
                                            style = floorSelectorMiddleButtonStyle,
                                            tooltipText = floorData[level]?.map { floor -> floor.floor.title }.toString(),
                                        )
                                    }
                                }
                                // lowest floor has always floorRange.minOrNull() as number
                                floorButton(
                                    floorData.keys.minOrNull()!!,
                                    style = floorSelectorBottomButtonStyle,
                                    tooltipText = floorData[floorData.keys.minOrNull()!!]?.map { floor -> floor.floor.title }.toString(),
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.floorButton(
    floorLevel: Double,
    style: Style<BasicParams>,
    tooltipText: String? = null
) {
    val activeFloorLevelStore: ActiveFloorLevelStore by koinCtx.inject()

    activeFloorLevelStore.data.render { activeFloor ->
        div(
            {
                width { full }
                color {
                    if (activeFloor == floorLevel) {
                        "#38A2BA"
                    } else {
                        "#000000"
                    }
                }
            },
        ) {
            tooltipText?.also { title(it) }
            button(
                {
                    style()
                },
            ) {
                +"$floorLevel"
                clicks.map { floorLevel } handledBy activeFloorLevelStore.update
                clicks.map { MessageToServer.AnalyticsEvent(AnalyticsCategory.FloorButton.name, AnalyticsAction.Click.name, value = floorLevel) }
            }
        }
    }
}

val floorSelectorRadius: ScaledValueProperty = formationStyles.inputRadius
const val floorSelectorButtonHeight = "37px" //"42px"

val floorSelectorButtonBaseStyle: Style<BasicParams> = {
    width { full }
    height { floorSelectorButtonHeight }
    padding { tiny }
    fontSize { small }
    fontWeight { semiBold }
}

val floorSelectorTopButtonStyle: Style<BasicParams> = {
    floorSelectorButtonBaseStyle()
    radius { "$floorSelectorRadius $floorSelectorRadius 0 0" }
}

val floorSelectorMiddleButtonStyle: Style<BasicParams> = {
    floorSelectorButtonBaseStyle()
    radius { "0" }
    borders { top { width { "1px" }; color { primary.main } } }
}

val floorSelectorBottomButtonStyle: Style<BasicParams> = {
    floorSelectorButtonBaseStyle()
    radius { "0 0 $floorSelectorRadius $floorSelectorRadius" }
    borders { top { width { "1px" }; color { primary.main } } }
}

fun RenderContext.sharingButton() {

    val locationUploadStore: LocationUploadStore by koinCtx.inject()
    val sharingState = locationUploadStore.map(LocationUploadState.backgroundJob())
    val translation: Translation by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()

    div({ margins { top { smaller } } }) {
        className("pointer-events-auto")
        circleIconButton(
            iconFlow = sharingState.data.map { state ->
                if (state != null) {
                    { FormationIcons.SharingOn.icon }
                } else {
                    { FormationIcons.SharingOff.icon }
                }
            },
            iconSize = { large },
            size = formationStyles.sideButtonSize,
            styleFlow = sharingState.data.map { state ->
                if (state != null) sideButtonStyle.plus {
                    color { secondary.main }
                    background { color { FormationColors.MarkerYou.color } }
                    hover {
                        background { color { FormationColors.MarkerYou.color } }
                        color { gray100 }
                    }
                }
                else sideButtonStyle
            },
            tooltipText = translation[TL.MapTools.SHARING_BUTTON_TOOLTIP],
            clickHandlers = listOf(activeObjectStore.resetActiveObjectAndUser),
            routingMap = mapOf(
                "page" to Pages.Map.name,
                "card" to Cards.Sharing.name,
            ),
            value = Unit,
//            clickHandlers = listOf(locationUploadStore.flip)
            analyticsEventProvider = AnalyticsCategory.SharingButton.click(),
        )
    }
}

fun RenderContext.locateOrFollowMeButton() {

    val locationFollowStore: LocationFollowStore by koinCtx.inject()
    val followState = locationFollowStore.data.map { it.isActive }
    val translation: Translation by koinCtx.inject()
    val geoPositionService by koinCtx.inject<GeoPositionService>()
    val router by koinCtx.inject<MapRouter>()

    div({ margins { top { smaller } } }) {
        className("pointer-events-auto")
        router.data.render { route ->
            if (
                !route["add"].isNullOrEmpty() ||
                !route["edit"].isNullOrEmpty() ||
                !route["editPosition"].isNullOrEmpty()
            ) {
                // Move to my Location button
                div("flex w-auto min-w-auto h-10 rounded-full p-2 border border-formationBlack bg-formationWhite hover:bg-gray-100 cursor-pointer") {
                    twRowOfJustifyBetween {
                        p("text-sm font-bold") {
                            translation[TL.General.MOVE_TO_MY_LOCATION].renderText(into = this)
                        }
                        twIconMedium(FormationIcons.Position)
                    }
                    clicks handledBy {
                        geoPositionService.jumpToMyPosition()
                    }
                }
            } else {
                // Follow me button
                circleIconButton(
                    iconFlow = flowOf { FormationIcons.Position.icon },
                    iconSize = { large },
                    size = formationStyles.sideButtonSize,
                    styleFlow = followState.map { state ->
                        if (state) sideButtonStyle.plus {
                            color { secondary.main }
                            background { color { FormationColors.MarkerDevices.color } }
                            hover {
                                background { color { FormationColors.MarkerDevices.color } }
                                color { gray100 }
                            }
                        }
                        else sideButtonStyle
                    },
                    tooltipText = translation[TL.MapTools.FOLLOW_BUTTON_TOOLTIP],
                    value = Unit,
                    clickHandlers = listOf(locationFollowStore.flip),
                    analyticsEventProvider = AnalyticsCategory.FollowMeButton.click(),
                )
            }
        }
    }
}

val sideButtonStyle: Style<BasicParams> = {
    background { color { secondary.main } }
    color { primary.main }
    border {
        width(formationStyles.borderWidth)
        color { primary.main }
    }
    hover {
        background { color { FormationColors.GrayLight.color } }
        color { primary.main }
    }
    boxShadow {
        shadow("2px", "4px", "10px", color = rgba(0, 0, 0, 0.35))
    }
}

fun RenderContext.mapLayerSelectorButton() {

    val translation: Translation by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()

    div({ margins { top { smaller } } }) {
        className("pointer-events-auto")
        circleIconButton(
            iconFlow = flowOf { FormationIcons.Layers.icon },
            iconSize = { large },
            size = formationStyles.sideButtonSize,
            styleFlow = flowOf(sideButtonStyle),
            tooltipText = translation[TL.MapTools.LAYER_BUTTON_TOOLTIP],
            value = Unit,
            clickHandlers = listOf(activeObjectStore.resetActiveObjectAndUser, routerStore.toggleMapLayersCard),
            analyticsEventProvider = AnalyticsCategory.MapLayersSelector.click(),
        )
    }
}

fun RenderContext.zoomControls() {

    val translation: Translation by koinCtx.inject()
    val mapStateStore: MapStateStore by koinCtx.inject()

    div("flex flex-col gap-2") {
        className("pointer-events-auto")
        circleIconButton(
            iconFlow = flowOf { FormationUIIcons.Add.icon },
            iconSize = { larger },
            size = formationStyles.sideButtonSize,
            styleFlow = flowOf(sideButtonStyle),
            tooltipText = translation[TL.MapTools.ZOOM_IN_TOOLTIP],
            value = Unit,
            clickHandlers = listOf(mapStateStore.zoomIn),
            analyticsEventProvider = AnalyticsCategory.ZoomInButton.click(),
        )
        circleIconButton(
            iconFlow = flowOf { remove },
            iconSize = { larger },
            size = { "38px" },
            styleFlow = flowOf(sideButtonStyle),
            tooltipText = translation[TL.MapTools.ZOOM_OUT_TOOLTIP],
            value = Unit,
            clickHandlers = listOf(mapStateStore.zoomOut),
            analyticsEventProvider = AnalyticsCategory.ZoomOutButton.click(),
        )
    }
}

fun RenderContext.searchManuallyButton() {

    val mapSearchClientsStore: MapSearchClientsStore by koinCtx.inject()
    val mapStateStore: MapStateStore by koinCtx.inject()
    val localSettingsStore: LocalSettingsStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()

    mapStateStore.data.render { mapState ->
        if (mapState?.hasMoved == true && localSettingsStore.current.manualSearch) {
            button {
                flexBox(
                    {
                        direction { row }
                        alignItems { center }
                        width { maxContent }
                        height { minContent }
                        zIndex { "1020" }
                        position {
                            absolute {
                                top { "10%" }
                                left { "50%" }
                            }
                        }
                        css("transform: translate(-50%, -50%);")
                        paddings {
                            vertical { smaller }
                            left { small }
                            right { smaller }
                        }
                        radius(formationStyles.buttonRadius)
                        color { secondary.main }
                        background {
                            color { makeRGBA(primary.main, 0.5) }
                        }
                        hover {
                            background {
                                color { makeRGBA(primary.main, 0.75) }
                            }
                        }
                    },
                ) {
                    span(
                        {
                            fontSize { normal }
                            fontWeight { bold }
                        },
                    ) { translation[TL.MapTools.SEARCH_HERE].renderText(into = this) }
                    icon(
                        {
                            margins { left { small } }
                            size { large }
                        },
                    ) { fromTheme { refresh } }
                }
                clicks handledBy mapSearchClientsStore.triggerMapSearchManually
            }
        }
    }
}
