package data.users.views

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import apiclient.FormationClient
import apiclient.auth.Token
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.keywordsForQueryContext
import apiclient.geoobjects.newContext
import apiclient.users.requestDeleteAccount
import apiclient.validations.parseEnumValue
import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import auth.FeatureFlagStore
import auth.Features
import com.tryformation.localization.Translatable
import data.objects.views.attachments.ImageFileDataStore
import data.users.profile.MyProfileStore
import data.users.profile.UpdatePasswordStore
import data.users.profile.VerificationTokenStore
import dev.fritz2.components.compat.img
import dev.fritz2.components.flexBox
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.core.autofocus
import dev.fritz2.core.id
import dev.fritz2.core.invoke
import dev.fritz2.core.placeholder
import dev.fritz2.core.src
import dev.fritz2.core.storeOf
import dev.fritz2.core.title
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import koin.withKoin
import kotlin.time.Duration.Companion.milliseconds
import kotlinx.coroutines.Job
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.plus
import kotlinx.datetime.Clock
import localization.TL
import localization.Translation
import localization.translate
import mainmenu.Pages
import mainmenu.RouterStore
import model.KeywordTag
import model.MyProfile
import model.StatusResult
import model.SuggestedTagsContext
import model.ValidatePassword
import model.allowEmailInPublic
import model.allowVCardInPublic
import model.company
import model.emails
import model.firstName
import model.firstPass
import model.isValid
import model.jobTitle
import model.keywords
import model.lastName
import model.linkedInLink
import model.name
import model.oldPass
import model.password
import model.phoneNumbers
import model.profilePhoto
import model.secondPass
import model.webLinks
import model.websiteLink
import overlays.BusyStore
import overlays.ConfirmationOverlayStore
import qrcode.userverification.QrCodeLinkStore
import routing.DestinationRoute
import routing.MainController
import search.separationLine
import signup.passwordStrengthIndicator
import theme.FormationIcons
import theme.FormationUIIcons
import theme.IconEnum
import twcomponents.twAddContentButton
import twcomponents.twCardSectionTitle
import twcomponents.twCheckbox
import twcomponents.twColOf
import twcomponents.twColOfCenter
import twcomponents.twColOfNoGap
import twcomponents.twDeleteButton
import twcomponents.twGhostButton
import twcomponents.twIconCustomSize
import twcomponents.twIconMedium
import twcomponents.twIconSmall
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twLargeIconButtonDangerRounded
import twcomponents.twLargeIconButtonFilledCircle
import twcomponents.twLargeIconToggleButtonNeutralRounded
import twcomponents.twLeftAlignedRow
import twcomponents.twMainTitle
import twcomponents.twPageHeaderBack
import twcomponents.twPageHeaderBlank
import twcomponents.twPageHeaderClose
import twcomponents.twPrimaryButton
import twcomponents.twRevertButton
import twcomponents.twRightAlignedButtonRow
import twcomponents.twRowOf
import twcomponents.twRowOfJustifyBetween
import twcomponents.twSimpleModalWithCloseHeader
import twcomponents.twSubtitle
import twcomponents.twTagButton
import twcomponents.twTitle
import twcomponents.twTooltip
import utils.ToggleStore
import utils.handleFunctions
import utils.isExpired
import utils.merge
import utils.mergeIfNotBlank
import utils.verificationLink
import webcomponents.BigButtonOption
import webcomponents.KeywordTagActionType
import webcomponents.KeywordTagType
import webcomponents.baseLayout
import webcomponents.fullCard
import webcomponents.fullWidthCenterContainer
import webcomponents.genericBigButtonSwitch
import webcomponents.inputLabelWrapper
import webcomponents.keywordTagList
import webcomponents.twContentScrollBox

enum class MyProfilePages : DestinationRoute {
    EditMyProfile,
    ChangePasswordEnter,
    ChangePasswordNew,
    ChangePasswordConfirm,
    ManageProfilePhoto,
//    TakeProfilePhoto
    ;

    override val routeKey = "myProfile"
    override val route = mapOf(routeKey to name)
}


fun RenderContext.pageMyProfile() {

    val router: MapRouter by koinCtx.inject()

    router.select("myProfile").render { (myProfile, _) ->
        when (parseEnumValue<MyProfilePages>(myProfile)) {
            MyProfilePages.EditMyProfile -> fullWidthCenterContainer { editMyProfile() }
            MyProfilePages.ChangePasswordEnter -> fullWidthCenterContainer { changePasswordFlow1() }
            MyProfilePages.ChangePasswordNew -> fullWidthCenterContainer { changePasswordFlow2() }
            MyProfilePages.ChangePasswordConfirm -> fullWidthCenterContainer { changePasswordFlow3() }
            MyProfilePages.ManageProfilePhoto -> fullWidthCenterContainer { cardManageProfilePhoto() }
//            MyProfilePages.TakeProfilePhoto -> fullWidthCenterContainer { cardBrowserPhotoCamera() }
            else -> myProfile()
        }
    }
}

enum class ProfileContent : Translatable {
    Details {
        override val icon: IconEnum = FormationUIIcons.ContactInfo
    },
    VCard {
        override val icon: IconEnum = FormationUIIcons.CreditCard
    },
    Verification {
        override val icon: IconEnum = FormationIcons.QRCode
    },
    ;

    abstract val icon: IconEnum
    override val prefix = "profilecontent"

}

fun RenderContext.myProfile() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val myProfileStore: MyProfileStore by koinCtx.inject()
    val verificationTokenStore: VerificationTokenStore by koinCtx.inject()

    val firstName = myProfileStore.map(MyProfile.firstName())
    val lastName = myProfileStore.map(MyProfile.lastName())
    val jobTitle = myProfileStore.map(MyProfile.jobTitle())
    val emails = myProfileStore.map(MyProfile.emails())
    val phoneNumbers = myProfileStore.map(MyProfile.phoneNumbers())
    val profilePhoto = myProfileStore.map(MyProfile.profilePhoto())
    val keywords = myProfileStore.map(MyProfile.keywords())
    val company = myProfileStore.map(MyProfile.company())
    val linkedInLink = myProfileStore.map(MyProfile.linkedInLink())
    val websiteLink = myProfileStore.map(MyProfile.websiteLink())
    val webLinks = myProfileStore.map(MyProfile.webLinks())
    val allowEmailInPublic = myProfileStore.map(MyProfile.allowEmailInPublic())

    val verificationQrCodeStore = QrCodeLinkStore(verificationTokenStore) { it?.verificationLink }

    val profileswitchStore = storeOf(ProfileContent.Details)

    myProfileStore.fetchMyProfile()
    fullCard {
        baseLayout(
            header = {
                twPageHeaderBack {
                    twGhostButton(
                        text = TL.UserProfile.EDIT_PROFILE,
                        icon = FormationIcons.Edit,
                    ) {
                        clicks handledBy {
                            routerStore.validateInternalRoute(
                                Pages.MyProfile.route + MyProfilePages.EditMyProfile.route,
                            )
                        }
                    }
                }
            },
            content = {
                div(baseClass = "flex flex-col w-full h-full justify-start overflow-y-auto px-2") {
                    profilePhoto.data.render { image ->
                        twColOfCenter {
                            className("my-4")
                            // Profile Photo
                            div("flex items-center justify-center text-center grow-0 shrink-0 w-52 h-52 rounded-full ") {
                                if (image == null) {
                                    className("text-gray-300 border-2 border-gray-300")
                                    twIconCustomSize(icon = FormationIcons.UserAlt, size = "100px")
                                } else {
                                    inlineStyle("--image-url:url(${image.href})")
                                    className("bg-[image:var(--image-url)] bg-center bg-cover")
                                }
                            }
                        }
                    }
                    twColOfCenter("w-full mb-2") {
                        twTitle {
                            firstName.data.combine(lastName.data) { f, l -> "$f $l" }.renderText(into = this)
                        }
                        twSubtitle {
                            jobTitle.data.mergeIfNotBlank(company.data, " | ").mapNotNull { it }.renderText(into = this)
                        }
                    }
                    genericBigButtonSwitch(
                        store = profileswitchStore,
                        options = ProfileContent.entries.map {
                            BigButtonOption(
                                title = translation[it],
                                icon = { it.icon.icon },
                                value = it,
                                selectHandler = profileswitchStore.update,
                            )
                        },
                    )
                    profileswitchStore.data.render { content ->
                        when (content) {
                            ProfileContent.Details -> {
                                twColOf {
                                    className(" min-w-0 max-w-full")
                                    // Email addresses
                                    emails.data.render { mails ->
                                        profileEmailSection(
                                            mails,
                                            showFullEmail = allowEmailInPublic.current,
                                        )
                                    }
                                    // Phone Numbers
                                    phoneNumbers.data.render { numbers ->
                                        profilePhoneNumberSection(numbers)
                                    }

                                    // LinkedIn link
                                    linkedInLink.data.render { linkedIn ->
                                        if (linkedIn.isNotBlank()) {
                                            inputLabelWrapper(
                                                title = TL.UserProfile.LINKEDIN,
                                                visibilityFlow = flowOf(true),
                                            ) {
                                                profileLinkButton(
                                                    link = linkedIn,
                                                    icon = FormationUIIcons.LinkedIn,
                                                    target = "_blank",
                                                ) {
                                                    twIconMedium(FormationUIIcons.ExternalLink)
                                                }
                                            }
                                        }
                                    }

                                    // Tags
                                    userKeywords(keywords)

                                    // Website preview
                                    websiteLink.data.render { website ->
                                        if (website.isNotBlank()) {
                                            twCardSectionTitle {
                                                twIconSmall(FormationIcons.Website)
                                                translation[TL.UserProfile.WEBSITE].renderText()
                                            }
                                            profileLinkPreview(website)
                                        }
                                    }

                                    // Web link previews
                                    webLinks.data.render { links ->
                                        if (links.isNotEmpty()) {
                                            twCardSectionTitle {
                                                twIconSmall(FormationUIIcons.ExternalLink)
                                                translation[TL.UserProfile.WEBLINK].renderText()
                                            }
                                            profileLinkPreviewList(links)
                                        }
                                    }
                                }
                            }

                            ProfileContent.VCard -> {
                                myProfileStore.data.render { profile ->
                                    combine(
                                        myProfileStore.myVcardStore.data.filterNotNull(),
                                        myProfileStore.myVcardQrCodeStore.data.filterNotNull(),
                                    ) { vcard, svg ->
                                        Pair(vcard, svg)
                                    }.render { (vcard, svgContent) ->
                                        console.log(vcard)
                                        vCardQR(vcard, svgContent, profile.name)
                                    }
                                }
                            }

                            ProfileContent.Verification -> {
                                combine(myProfileStore.data, verificationQrCodeStore.data) { p, qr -> Pair(p, qr) }.render { (myProfile, qrCode) ->
                                    if (myProfile.profilePhoto != null) {
                                        stackUp(
                                            {
                                                width { full }
                                                alignItems { start }
                                                justifyContent { center }
                                            },
                                        ) {
                                            spacing { small }
                                            items {
                                                if (!qrCode.isNullOrBlank()) {
                                                    img(
                                                        {
                                                            css(
                                                                """
                                                        animation: verifyqrcode 1 2s ease;
                                                        @keyframes verifyqrcode {
                                                            0% { opacity: 0; }
                                                            100% { opacity: 1; }
                                                        }
                                                        """.trimIndent(),
                                                            )
                                                            margins {
                                                                horizontal { auto }
                                                            }
                                                            width { full }
                                                            maxWidth { "300px" }
                                                        },
                                                    ) {
                                                        src(qrCode)
                                                    }
                                                    verificationTokenStore.data.render { token ->
                                                        flexBox(
                                                            {
                                                                width { full }
                                                                alignItems { center }
                                                                justifyContent { center }
                                                            },
                                                        ) {
                                                            verificationClock(token)
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    } else {
                                        withKoin {
                                            // Call to action to add profile photo
                                            twColOf {
                                                twRowOf {
                                                    twIconCustomSize(icon = FormationIcons.Caution, size = "48px")
                                                    p("text-sm font-bold max-w-72 my-5") {
                                                        +"The User Verification allows other workspace members to authorise you as a valid member of this workspace. Add a profile picture to enable this feature."
                                                    }
                                                }
                                                twPrimaryButton(
                                                    icon = FormationIcons.CameraPlus,
                                                ) {
                                                    p("text-sm font-bold") { +"ADD A PICTURE" }
                                                    clicks handledBy {
                                                        routerStore.validateInternalRoute(Pages.MyProfile.route + MyProfilePages.EditMyProfile.route)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            footer = null,
        )
    }
}

fun RenderContext.userKeywords(keywords: Store<List<String>>) {
    val translation: Translation by koinCtx.inject()

    val editMode = ToggleStore(false)

    editMode.data.render { editing ->
        twRowOfJustifyBetween {
            twCardSectionTitle {
                twIconSmall(FormationIcons.Tag)
                translation[TL.KeywordTags.CARD_TITLE].renderText()
            }

            twGhostButton(
                text = if (editing) TL.General.CLOSE else TL.General.EDIT,
                icon = if (editing) FormationUIIcons.Close else FormationIcons.Edit,
            ) {
                clicks handledBy editMode.toggle
            }
        }
        if (editing) {
            userKeywordEditor()
        } else {
            keywords.data.renderIf({ it.isNotEmpty() }) {
                keywordTagList(
                    keywords = keywords.data.map { keywordList -> keywordList.map { KeywordTag(it, actionType = KeywordTagActionType.Default) } },
                    keywordTagType = KeywordTagType.SearchTag,
                    searchable = true,
                )
            }
        }
    }
}

private fun RenderContext.userKeywordEditor() {
    val translation: Translation by koinCtx.inject()
    val myProfileStore = koinCtx.get<MyProfileStore>()
    val userKeywords = myProfileStore.map(MyProfile.keywords())
    val tagPrefix = storeOf("")

    userKeywords.data.render { keywords ->
        div("flex flex-col gap-3") {
            div("flex flex-row flex-wrap gap-3") {
                keywords.forEach { kw ->
                    twTagButton(text = kw, icon = FormationUIIcons.Close, inverted = true) {
                        clicks.map { kw } handledBy myProfileStore.removeKeyword
                    }
                }
            }
        }

        inputLabelWrapper(
            title = TL.KeywordTags.TAG_INPUT_PLACEHOLDER,
            visibilityFlow = tagPrefix.data.map { it.isNotBlank() },
        ) {
            twInputField(tagPrefix) {
                twLeftAlignedRow {
                    twIconMedium(icon = FormationIcons.Search)
                }
                twInputTextField {
                    id("inputKeywords")
                    autofocus(true)
                    attr("tabindex", "0")
                    placeholder(translation[TL.KeywordTags.TAG_INPUT_PLACEHOLDER])
                }
                twRightAlignedButtonRow {
                    tagPrefix.data.render { text ->
                        if (text.isNotBlank()) {
                            twRevertButton(tagPrefix, "")
                        }
                    }
                }
            }
        }

        div {
            suggestedTags(tagPrefix)
        }
    }
}

private fun RenderContext.suggestedTags(tagPrefix: Store<String>) {
    val translation: Translation by koinCtx.inject()
    val workspaceStore = koinCtx.get<CurrentWorkspaceStore>()
    val formationClient = koinCtx.get<FormationClient>()
    val myProfileStore = koinCtx.get<MyProfileStore>()

    val userKeywords = myProfileStore.map(MyProfile.keywords())

    val suggestionsStore = storeOf(listOf<String>())
    val suggestionContextStore = storeOf(
        SuggestedTagsContext(
            SearchQueryContext.newContext(
                listOf(
                    workspaceStore.current!!.groupId,
                ),
            ),
            "",
            limit = 50,
        ),
        job,
    )
    tagPrefix.data handledBy { prefix ->
        suggestionContextStore.update(suggestionContextStore.current.copy(prefix = prefix))
    }
    suggestionContextStore.data
        .debounce(100.milliseconds) handledBy { keywordSuggestionContext ->
        formationClient.keywordsForQueryContext(
            searchQueryContext = keywordSuggestionContext.ctx,
            prefix = keywordSuggestionContext.prefix,
            numberOfKeywords = keywordSuggestionContext.limit,
        ).fold(
            {
                suggestionsStore.update(it)
            },
            {
                console.error(it, keywordSuggestionContext)
            },
        )
    }

    p("mb-2") {
        translate(TL.KeywordTags.SUGGESTED)
    }
    div("flex flex-col space-y-3 h-60 overflow-y-auto mb-6") {
        div("flex flex-row flex-wrap gap-3") {
            combine(
                tagPrefix.data,
                suggestionsStore.data,
                userKeywords.data,
            ) { typed, suggested, userKws ->
                (listOfNotNull(typed.ifBlank { null }) + suggested - userKws.toSet()).distinct()
            }.render { keywords ->
                if (keywords.isNotEmpty()) {
                    keywords.forEach { kw ->
                        twTagButton(text = kw, icon = FormationUIIcons.Add) {
                            clicks.map { kw } handledBy myProfileStore.addKeyword
                        }
                    }
                } else {
                    twColOfCenter("w-full flex-grow flex-shrink basis-12") {
                        span { translation[TL.Search.NO_RESULTS].renderText(into = this) }
                    }
                }
            }
        }
    }
}

fun RenderContext.verificationClock(token: Token?) {
    val translation: Translation by koinCtx.inject()
    flow {
        while (token?.isExpired == false) {
            emit(((token.expiration - Clock.System.now().toEpochMilliseconds()) / 1000).toInt())
            delay(1000)
        }
    }.render { time ->
        span {
            if (time > 0) {
                translation[TL.UserVerification.NEW_QR_IN_X_SECS, mapOf("seconds" to time)].renderText(into = this)
            } else translation[TL.UserVerification.QR_CODE_EXPIRED].renderText(into = this)
        }
    }
}

fun RenderContext.editMyProfile() {

    val translation: Translation by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val formationClient: FormationClient by koinCtx.inject()
    val myProfileStore: MyProfileStore by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val imageFileDataStore: ImageFileDataStore by koinCtx.inject()
    val analyticsService by koinCtx.inject<AnalyticsService>()
    val firstName = myProfileStore.map(MyProfile.firstName())
    val lastName = myProfileStore.map(MyProfile.lastName())
    val jobTitle = myProfileStore.map(MyProfile.jobTitle())
    val emails = myProfileStore.map(MyProfile.emails())
    val allowEmailInPublic = myProfileStore.map(MyProfile.allowEmailInPublic())
    val useEmailInPublicStore = storeOf(allowEmailInPublic.current)
    val allowVCardInPublic = myProfileStore.map(MyProfile.allowVCardInPublic())
    val useVCardInPublicStore = storeOf(allowVCardInPublic.current)

    // Set UserFlag if email should be public
    useEmailInPublicStore.data handledBy { useInPublic ->
        allowEmailInPublic.update(useInPublic)
    }
    useVCardInPublicStore.data handledBy { useInPublic ->
        allowVCardInPublic.update(useInPublic)
    }
    val password = myProfileStore.map(MyProfile.password())
    val phones = myProfileStore.map(MyProfile.phoneNumbers())
    val phoneNumberAmount = phones.data.map { it.size }

    val profilePhoto = myProfileStore.map(MyProfile.profilePhoto())
    val company = myProfileStore.map(MyProfile.company())
    val linkedInLink = myProfileStore.map(MyProfile.linkedInLink())
    val websiteLink = myProfileStore.map(MyProfile.websiteLink())
    val webLinks = myProfileStore.map(MyProfile.webLinks())

    val activePhoneInputsStore: Store<Map<Int, Boolean>> = storeOf(
        phones.current.mapIndexed { i, p -> i to p.isNotBlank() }.toMap(),
    )
    val phoneNumberInputValues: MutableMap<Int, String> = phones.current.mapIndexed { index, number ->
        index to number
    }.toMap().toMutableMap()

    val activeWebLinkInputsStore: Store<Map<Int, Boolean>> = storeOf(
        webLinks.current.mapIndexed { i, link -> i to link.isNotBlank() }.toMap(), Job(),
    )
    val weblinkInputValues: MutableMap<Int, String> = webLinks.current.mapIndexed { index, link ->
        index to link
    }.toMap().toMutableMap()

    /**
     * Helper function that updates the webLinks sub store from MyProfile
     * with values from all active webLink inputs in the user profile
     */
    fun updateWebLinks() {
        webLinks.update(weblinkInputValues.values.toList())
    }

    fun updatePhones() {
        phones.update(phoneNumberInputValues.values.toList())
    }

    fullCard {
        baseLayout(
            header = {
                twPageHeaderClose(
                    routeBack = true,
                    routeToMap = false,
                    additionalBackButtonHandler = handleFunctions {
                        myProfileStore.fetchMyProfile()
                    },
                ) {
                    twPrimaryButton(
                        text = TL.UserProfile.SAVE_PROFILE,
                        disabledFlow = firstName.data.map { it.isBlank() },
                        icon = FormationUIIcons.Save,
                    ) {
                        clicks handledBy {
                            myProfileStore.updateMyProfile()
                            routerStore.back()
                        }
                    }
                }
            },
            content = {
                twContentScrollBox {
                    profilePhoto.data.combine(imageFileDataStore.data) { profilePhoto, uploadedPhoto ->
                        uploadedPhoto.href ?: profilePhoto?.href
                    }.render { imageSrc ->
                        twColOfCenter {
                            // Profile Photo
                            div(
                                "relative flex my-4 items-center " +
                                    "justify-center text-center grow-0 shrink-0 w-52 h-52 " +
                                    "rounded-full hover:cursor-pointer hover:brightness-75 border-2 " +
                                    "hover:border-gray-300",
                            ) {
                                if (imageSrc == null) {
                                    className("text-gray-300 border-gray-300")
                                    twIconCustomSize(icon = FormationIcons.UserAlt, size = "100px")
                                } else {
                                    inlineStyle("--image-url:url(${imageSrc})")
                                    className("border-transparent bg-[image:var(--image-url)] bg-center bg-cover")
                                }
                                // Edit photo click handler
                                clicks handledBy {
                                    routerStore.addOrReplaceRoute(mapOf("myProfile" to MyProfilePages.ManageProfilePhoto.name))
                                }

                                div("absolute right-2 bottom-2") {
                                    // Edit Profile Button
                                    twLargeIconButtonFilledCircle(icon = FormationIcons.CameraAlt) {
                                        clicks handledBy {
                                            routerStore.addOrReplaceRoute(mapOf("myProfile" to MyProfilePages.ManageProfilePhoto.name))
                                        }
                                    }.twTooltip(positioning = PlacementValues.left, fallback = translation.getString(TL.UserProfile.PROFILE_PHOTO)) {
                                        p("text-xs font-bold") {
                                            translate(TL.UserProfile.PROFILE_PHOTO)
                                        }
                                    }
                                }
                            }
                        }
                    }
                    twColOfCenter("w-full mb-2") {
                        twTitle {
                            firstName.data.combine(lastName.data) { f, l -> "$f $l" }.renderText(into = this)
                        }
                        twSubtitle {
                            jobTitle.data.mergeIfNotBlank(company.data, " | ").mapNotNull { it }.renderText(into = this)
                        }
                    }
                    twColOf {
                        className("w-full")
                        twRowOfJustifyBetween {
                            className("w-full")
                            // First Name
                            profileInput(
                                firstName.id,
                                title = TL.UserProfile.FIRST_NAME,
                                placeHolder = translation[TL.UserProfile.FIRST_NAME],
                                store = firstName,
                                tabIndex = flowOf(1),
                                autofocus = true,
                            )
                            // Last Name
                            profileInput(
                                lastName.id,
                                title = TL.UserProfile.LAST_NAME,
                                placeHolder = translation[TL.UserProfile.LAST_NAME],
                                store = lastName,
                                tabIndex = flowOf(2),
                            )
                        }
                        // Job title
                        profileInput(
                            jobTitle.id,
                            title = TL.UserProfile.JOB_TITLE,
                            icon = FormationIcons.CustomerService,
                            placeHolder = translation[TL.UserProfile.JOB_TITLE],
                            store = jobTitle,
                            tabIndex = flowOf(3),
                        )
                        // Company
                        profileInput(
                            company.id,
                            title = TL.UserProfile.COMPANY,
                            icon = FormationIcons.Building,
                            placeHolder = translation[TL.UserProfile.COMPANY],
                            store = company,
                            tabIndex = flowOf(4),
                        )
                        // Disabled Email
                        val firstEmailStore = storeOf(emails.current.firstOrNull() ?: "", Job())
                        profileInput(
                            emails.id,
                            title = TL.UserProfile.ACCOUNT_EMAIL,
                            icon = FormationIcons.Mail,
                            placeHolder = translation[TL.UserProfile.ACCOUNT_EMAIL],
                            store = firstEmailStore,
                            disabled = flowOf(true),
                            rightInsideInputContent = {
                                twIconMedium(FormationIcons.Lock)
                            },
                        )
                        twCheckbox(
                            store = useEmailInPublicStore,
                            contentRight = {
                                +"Show Email address on my public profile"
                            },
                        )
                        twCheckbox(
                            store = useVCardInPublicStore,
                            contentRight = {
                                +"Show vCard QR code on my public profile"
                            },
                        )

                        // Disabled Password
                        profileInput(
                            password.id,
                            title = TL.UserProfile.PASSWORD,
                            icon = FormationUIIcons.EyeOff,
                            placeHolder = translation[TL.UserProfile.PASSWORD],
                            store = password,
                            disabled = flowOf(true),
                            type = flowOf("password"),
                        ) {
                            twGhostButton(
                                text = TL.General.CHANGE,
                                icon = FormationIcons.Edit,
                            ) {
                                title(
                                    translation[TL.General.CHANGE].map { text ->
                                        text.lowercase()
                                            .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                                    },
                                )
                                clicks handledBy {
                                    routerStore.validateInternalRoute(Pages.MyProfile.route + MyProfilePages.ChangePasswordEnter.route)
                                    analyticsService.analyticsEvent(AnalyticsCategory.ChangePasswordButton.click()())
                                }
                            }
                        }
                        // Phone numbers
                        activePhoneInputsStore.data.render { phoneInputs ->
                            phoneInputs.forEach { (index, enabled) ->
                                if (enabled) {
                                    val phoneStore = storeOf(phoneNumberInputValues[index] ?: "")
                                    when (index) {
                                        //  Cellphone
                                        0 -> profileInput(
                                            phoneStore.id,
                                            title = TL.UserProfile.CELLPHONE,
                                            icon = FormationIcons.Phone,
                                            placeHolder = translation[TL.UserProfile.CELLPHONE],
                                            store = phoneStore,
                                            tabIndex = flowOf(index + 5),
                                        )
                                        // Other
                                        else -> {
                                            profileInput(
                                                id = phoneStore.id,
                                                titleAsFlow = translation[TL.UserProfile.PHONE_NUMBER].merge(flowOf("#${index + 1}")),
                                                icon = FormationIcons.TelephoneTypeWriter,
                                                placeHolder = translation[TL.UserProfile.PHONE_NUMBER].merge(flowOf("#${index + 1}")),
                                                store = phoneStore,
                                                tabIndex = flowOf(index + 5),
                                            ) {
                                                twLargeIconButtonDangerRounded(icon = FormationIcons.DeleteAlt) {
                                                    clicks.handledBy {
                                                        phoneNumberInputValues.removeAndReIndex(index)
                                                        updatePhones()
                                                        val mutableActiveInputs = phoneInputs.toMutableMap()
                                                        mutableActiveInputs.removeAndReIndex(index)
                                                        activePhoneInputsStore.update(mutableActiveInputs)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    phoneStore.data handledBy { number ->
                                        phoneNumberInputValues[index] = number
                                        updatePhones()
                                    }
                                }
                            }
                            twAddContentButton {
                                twIconMedium(icon = FormationUIIcons.Add)
                                +"Add Phone Number"
                                clicks handledBy {
                                    val newActiveInputs = phoneInputs + mapOf(phoneInputs.size to true)
                                    activePhoneInputsStore.update(newActiveInputs)
                                    phoneNumberInputValues.plus(phoneNumberInputValues.size to "")
                                }
                            }
                        }
                        // LinkedIn
                        profileInput(
                            linkedInLink.id,
                            title = TL.UserProfile.LINKEDIN,
                            icon = FormationUIIcons.LinkedIn,
                            placeHolder = translation[TL.UserProfile.LINKEDIN],
                            store = linkedInLink,
                            tabIndex = phoneNumberAmount.map { phones -> phones + 5 },
                        )
                        // Website
                        profileInput(
                            websiteLink.id,
                            title = TL.UserProfile.WEBSITE,
                            icon = FormationIcons.Website,
                            placeHolder = translation[TL.UserProfile.WEBSITE],
                            store = websiteLink,
                            tabIndex = phoneNumberAmount.map { phones -> phones + 6 },
                        )
                        // WebLinks
                        activeWebLinkInputsStore.data.render { webLinkInputs ->
                            webLinkInputs.forEach { (index, enabled) ->
                                if (enabled) {
                                    val webLinkStore = storeOf(weblinkInputValues[index] ?: "")
                                    profileInput(
                                        id = webLinkStore.id,
                                        titleAsFlow = translation[TL.UserProfile.WEBLINK].merge(flowOf("#${index + 1}")),
                                        icon = FormationUIIcons.ExternalLink,
                                        placeHolder = translation[TL.UserProfile.WEBLINK],
                                        store = webLinkStore,
                                        tabIndex = phoneNumberAmount.map { phones -> phones + index + 7 },
                                    ) {
                                        twLargeIconButtonDangerRounded(icon = FormationIcons.DeleteAlt) {
                                            clicks.handledBy {
                                                weblinkInputValues.removeAndReIndex(index)
                                                updateWebLinks()
                                                val mutableActiveInputs = webLinkInputs.toMutableMap()
                                                mutableActiveInputs.removeAndReIndex(index)
                                                activeWebLinkInputsStore.update(mutableActiveInputs)
                                            }
                                        }
                                    }
                                    webLinkStore.data handledBy { link ->
                                        weblinkInputValues[index] = link
                                        updateWebLinks()
                                    }
                                }
                            }
                            // Add another Web Link Input button
                            twAddContentButton {
                                twIconMedium(icon = FormationUIIcons.Add)
                                +"Add Web Link"
                                clicks handledBy {
                                    val newActiveInputs = webLinkInputs + mapOf(webLinkInputs.size to true)
                                    activeWebLinkInputsStore.update(newActiveInputs)
                                    weblinkInputValues.plus(weblinkInputValues.size to "")
                                }
                            }
                        }
                    }
                    // Delete Account button

                    twColOfNoGap("w-full mb-10") {
                        separationLine("my-10")
                        apiUserStore.current.apiUser?.emails?.firstOrNull()?.let { email ->
                            deleteAccountButton(email = email, client = formationClient)
                        }
                    }
                }
            },
            footer = null,
        )
    }
}

/**
 * CHANGE PASSWORD VIEWS
 */


fun RenderContext.changePasswordFlow1() {
    val updatePasswordStore: UpdatePasswordStore by koinCtx.inject()
    val oldPassword = updatePasswordStore.map(ValidatePassword.oldPass())
    val routerStore: RouterStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()

    val noBackButton = featureFlagStore.current[Features.NewPasswordNeeded] == true

    baseLayout(
        header = {
            if (noBackButton) {
                twPageHeaderBlank {
                    twMainTitle {
                        translate(TL.UserProfile.PASSWORD_VIEW1_HEADER)
                    }
                }
            } else {
                twPageHeaderBack(
                    additionalBackButtonHandler = updatePasswordStore.reset,
                ) {
                    twMainTitle {
                        translate(TL.UserProfile.PASSWORD_VIEW1_HEADER)
                    }
                }
            }
        },
        content = {
            twContentScrollBox {
                twColOf {
                    className("flex-grow w-full items-center justify-evenly")

                    p { translate(TL.UserProfile.PASSWORD_VIEW1_TITLE) }

                    val showOldPWStore = ToggleStore(false)

                    profileInput(
                        "oldPasswordInput",
                        title = TL.UserProfile.OLD_PASSWORD,
                        placeHolder = translation[TL.UserProfile.OLD_PASSWORD],
                        store = oldPassword,
                        autofocus = true,
                        type = showOldPWStore.data.map { if (it) "text" else "password" },
//                        rightInsideInputContent = {
//                            twMediumIconToggleButtonNeutral(
//                                iconTrue = FormationUIIcons.EyeOff,
//                                iconFalse = FormationUIIcons.Eye,
//                                boolStore = showOldPWStore,
//                            ) {
//                                clicks handledBy showOldPWStore.toggle
//                            }
//                        },
                        rightInRowWithInputContent = {
                            twLargeIconToggleButtonNeutralRounded(
                                iconTrue = FormationUIIcons.EyeOff,
                                iconFalse = FormationUIIcons.Eye,
                                boolStore = showOldPWStore,
                            ) {
                                clicks handledBy showOldPWStore.toggle
                            }
                        },
                    )
                }

                div("flex grow") { }

                twPrimaryButton(
                    text = TL.General.NEXT,
                    disabledFlow = oldPassword.data.map { it.isBlank() },
                ) {
                    clicks handledBy updatePasswordStore.validateCurrentPassword
                }

                div("flex grow-[2]") { }
            }
        },
    )
    updatePasswordStore.data.render { store ->
        if (store.responseStatus is StatusResult.Success) {
            updatePasswordStore.update(updatePasswordStore.current.copy(responseStatus = null))
            routerStore.addOrReplaceRoute(Pages.MyProfile.route + MyProfilePages.ChangePasswordNew.route)
        }
    }
}

fun RenderContext.changePasswordFlow2() {
    val updatePasswordStore = koinCtx.get<UpdatePasswordStore>()
    val firstPass = updatePasswordStore.map(ValidatePassword.firstPass())
    val secondPass = updatePasswordStore.map(ValidatePassword.secondPass())
    val isValid = updatePasswordStore.map(ValidatePassword.isValid())
    val routerStore: RouterStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()

    val cancelToPWNeededPage = featureFlagStore.current[Features.NewPasswordNeeded] == true

    baseLayout(
        header = {
            twPageHeaderBack(
                backButtonIcon = FormationUIIcons.Close,
                backButtonTitle = TL.General.CANCEL,
                routeBack = false,
                additionalBackButtonHandler = if (cancelToPWNeededPage) {
                    handleFunctions {
                        updatePasswordStore.reset(Unit)
                        routerStore.validateInternalRoute(Pages.NewPasswordNeeded.route)
                    }
                } else {
                    handleFunctions {
                        updatePasswordStore.reset(Unit)
                        routerStore.backTo(Pages.MyProfile.route + MyProfilePages.EditMyProfile.route)
                    }
                },
            ) {
                twMainTitle {
                    translate(TL.UserProfile.PASSWORD_VIEW2_HEADER)
                }
            }
        },
        content = {
            twContentScrollBox {
                // FIRST PASSWORD INPUT
                twColOf {
                    className("flex-grow w-full items-center justify-evenly")

                    p { translate(TL.UserProfile.PASSWORD_VIEW2_TITLE1) }

                    val showFirstPassStore = ToggleStore(false)

                    div("w-full h-14") {
                        profileInput(
                            "firstPasswordInput",
                            title = TL.UserProfile.NEW_PASSWORD,
                            placeHolder = translation[TL.UserProfile.NEW_PASSWORD],
                            store = firstPass,
                            autofocus = true,
                            type = showFirstPassStore.data.map { if (it) "text" else "password" },
                            rightInRowWithInputContent = {
                                twLargeIconToggleButtonNeutralRounded(
                                    iconTrue = FormationUIIcons.EyeOff,
                                    iconFalse = FormationUIIcons.Eye,
                                    boolStore = showFirstPassStore,
                                ) {
                                    clicks handledBy showFirstPassStore.toggle
                                }
                            },
                            belowInputContent = {
                                // add padding to show strength indicator below input only
                                div("pr-10") {
                                    passwordStrengthIndicator(updatePasswordStore.data)
                                }
                            },
                        )
                    }

                }
                // SECOND PASSWORD INPUT
                twColOf {
                    className("flex-grow w-full items-center justify-evenly")

                    p { translate(TL.UserProfile.PASSWORD_VIEW2_TITLE2) }

                    val showSecondPassStore = ToggleStore(false)

                    profileInput(
                        "inputPassword-Re-enter",
                        title = TL.UserProfile.NEW_PASSWORD_RE,
                        placeHolder = translation[TL.UserProfile.NEW_PASSWORD_RE],
                        store = secondPass,
                        type = showSecondPassStore.data.map { if (it) "text" else "password" },
                        rightInRowWithInputContent = {
                            twLargeIconToggleButtonNeutralRounded(
                                iconTrue = FormationUIIcons.EyeOff,
                                iconFalse = FormationUIIcons.Eye,
                                boolStore = showSecondPassStore,
                            ) {
                                clicks handledBy showSecondPassStore.toggle
                            }
                        },
                    )
                }
                // PASSWORD VALIDATION
                div("h-6 self-center mt-2") {
                    span("text-red-500 text-xs text-center bg-red-100 py-1 px-2 rounded-xl") {
                        className(
                            updatePasswordStore.data.map { pwStack ->
                                if (pwStack.firstPass.isNotBlank()
                                    && pwStack.secondPass.isNotBlank()
                                    && !pwStack.isMatching
                                ) {
                                    "visible"
                                } else {
                                    "invisible"
                                }
                            },
                        )
                        translate(TL.UserProfile.PASSWORD_NO_MATCH)
                    }
                }
                div("flex grow") { }
                // SET PASSWORD BUTTON
                twPrimaryButton(
                    text = TL.UserProfile.SET_PASSWORD,
                    disabledFlow = isValid.data.map { !it },
                ) {
                    clicks handledBy updatePasswordStore.updatePassword
                }
                div("flex grow-[2]") { }
            }
        },
    )

    firstPass.data.map { } handledBy updatePasswordStore.updateValidation
    secondPass.data.map { } handledBy updatePasswordStore.updateValidation

    updatePasswordStore.data.render { store ->
        if (store.responseStatus is StatusResult.Success) {
            updatePasswordStore.update(ValidatePassword())
            routerStore.addOrReplaceRoute(Pages.MyProfile.route + MyProfilePages.ChangePasswordConfirm.route)
        }
    }
}

fun RenderContext.changePasswordFlow3() {
    val routerStore: RouterStore by koinCtx.inject()
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()

    val newPWRequired = featureFlagStore.current[Features.NewPasswordNeeded] == true

    baseLayout(
        header = {
            if (newPWRequired) {
                twPageHeaderBlank {
                    twMainTitle {
                        translate(TL.UserProfile.PASSWORD_VIEW3_HEADER)
                    }
                }
            } else {
                twPageHeaderClose(
                    routeBack = true,
                    routeToMap = false,
                    additionalBackButtonHandler = handleFunctions {
                        routerStore.backTo(Pages.MyProfile.route + MyProfilePages.EditMyProfile.route)
                    },
                ) {
                    twMainTitle {
                        translate(TL.UserProfile.PASSWORD_VIEW3_HEADER)
                    }
                }
            }
        },
        content = {
            twContentScrollBox {
                twColOfCenter {
                    className("flex-grow w-full")
                    p("text-center px-4") {
                        translate(TL.UserProfile.PASSWORD_VIEW3_TITLE)
                    }
                }
                if (newPWRequired) {
                    div("flex grow") { }
                    twPrimaryButton(
                        text = TL.MainMenu.LOGIN,
                    ) {
                        clicks handledBy handleFunctions {
                            routerStore.validateInternalRoute(Pages.Login.route)
                            apiUserStore.refreshUser()
                        }
                    }
                }
                div("flex grow-[2]") { }
            }
        },
    )
}

fun RenderContext.deleteAccountButton(email: String, client: FormationClient) {
    val translation by koinCtx.inject<Translation>()
    val mainController: MainController by koinCtx.inject()
    val busyStore: BusyStore by koinCtx.inject()
    val confirmationOverlayStore: ConfirmationOverlayStore by koinCtx.inject()

    val deleteAccountToggle = ToggleStore(false)
    val pwStore = storeOf("")

    val requestHandler: SimpleHandler<Unit> = SimpleHandler { flow, job ->
        flow.onEach {
            busyStore.handleApiCall(
                supplier = {
                    client.requestDeleteAccount(email, pwStore.current)
                },
                processResult = { result ->
                    console.log("Account deleted", result)
                    pwStore.update("")
                    mainController.logout()
                    confirmationOverlayStore.reset()
                },
                successMessage = translation[TL.AlertNotifications.ACCOUNT_DELETED_SUCCESSFULLY],
                processError = { error ->
                    pwStore.update("")
                    console.log("Account deletion failed!", error.message)
                },
                errorMessage = translation[TL.AlertNotifications.ACCOUNT_DELETION_FAILED],
            )
        }.launchIn(MainScope() + job)
    }

    fun deleteAccountModal() = twSimpleModalWithCloseHeader(
        "w-96",
        title = TL.DeleteAccount.DELETE_ACCOUNT_TITLE,
        icon = FormationIcons.DeleteAlt,
        toggleStore = deleteAccountToggle,
    ) { close, _, _ ->
        twContentScrollBox {
            className("gap-4")
            p {
                translation[TL.DeleteAccount.DELETE_ACCOUNT_TEXT_A].renderText(into = this)
            }
            p {
                translation[TL.DeleteAccount.DELETE_ACCOUNT_TEXT_B].renderText(into = this)
            }
            // PASSWORD CONFIRMATION
            val showPWStore = ToggleStore(false)
            profileInput(
                "inputPassword-Delete-Account",
                title = TL.Login.PASSWORD,
                placeHolder = translation[TL.Login.PASSWORD],
                store = pwStore,
                type = showPWStore.data.map { if (it) "text" else "password" },
                rightInRowWithInputContent = {
                    twLargeIconToggleButtonNeutralRounded(
                        iconTrue = FormationUIIcons.EyeOff,
                        iconFalse = FormationUIIcons.Eye,
                        boolStore = showPWStore,
                    ) {
                        clicks handledBy showPWStore.toggle
                    }
                },
            )
            twDeleteButton(
                text = TL.DeleteAccount.DELETE_ACCOUNT_BUTTON,
                icon = FormationIcons.DeleteAlt,
                disabledFlow = pwStore.data.map { it.isBlank() },
            ) {
                clicks handledBy {
                    requestHandler()
                    close(Unit)
                }
            }
        }
    }

    deleteAccountModal()

    // twDeleteButton
    twDeleteButton(
        text = TL.DeleteAccount.DELETE_ACCOUNT_BUTTON,
        icon = FormationIcons.DeleteAlt,
    ) {
        clicks handledBy deleteAccountToggle.toggle
    }
}
