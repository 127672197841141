package mainmenu.about.termstexts

import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.p
import dev.fritz2.core.RenderContext
import mainmenu.about.textPart
import theme.FormationColors

fun RenderContext.disclaimerEN() {
    textPart {
        div(
            {
                padding { small }
                radius { large }
                border {
                    width { "2px" }
                    color { FormationColors.GrayLight.color }
                }
            },
        ) {
            p(
                {
                    fontSize { small }
                },
            ) {
                +"ABOUT THIS TRANSLATION"
            }
            p(
                {
                    fontSize { small }
                },
            ) {
                +"""
                    Disclaimer: The German version of this legal document shall be the legally binding version. While every effort has been made to provide an accurate English translation, in the event of any discrepancies or conflicts between the German and English versions, the German version shall prevail.

                """.trimIndent()
            }
        }
    }
}
