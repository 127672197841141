package workspacetools

import analyticsdashboard.dashboardMainPageButton
import apiclient.validations.parseEnumValue
import auth.Features
import dev.fritz2.core.RenderContext
import dev.fritz2.core.src
import koin.withKoin
import localization.getTranslationFlow
import mainmenu.Pages
import mainmenu.RouterStore
import theme.FormationUIIcons
import twcomponents.renderIfFeatureFlagEnabled
import twcomponents.twLargeIconButtonNeutralRounded
import workspacetools.buildingeditor.buildingsAndFloorEditorScreen
import workspacetools.usermanagement.userManagement
import workspacetools.workspaceoptions.workspaceOptions


fun RenderContext.workplaceTools() {
    withKoin {

        val routerStore = get<RouterStore>()

        div("fixed top-0 left-0 flex flex-col w-screen h-screen gap-1 sm:gap-3 bg-gray-100") {
            div("flex flex-row w-screen items-center justify-between py-0.5 pl-0.5 pr-3 bg-formationWhite pointer-events-auto") {

                div("flex flex-row w-max items-center justify-center mr-3") {
                    // Logo
                    img("h-10 md:h-15 p-2 mx-3") {
                        src("assets/images/logo-blue_1.svg")
                    }
                    // WorkspaceTools
                    div("flex flex-row flex-wrap items-start justify-start") {
                        span("text-xs md:text-xl font-bold") { +"Workspace" }
                        span("text-xs md:text-xl font-light") { +"Tools" }
                    }
                }
                div("flex flex-row grow text-xs md:text-base overflow-x-auto") {
                    inlineStyle(
                        """
                        -ms-overflow-style: none;  /* IE and Edge */
                        scrollbar-width: none;  /* Firefox */
                    """.trimIndent(),
                    )
                    WorkspaceToolsMenu.entries.forEach { tool ->
                        when (tool) {
                            WorkspaceToolsMenu.Buildings -> {
                                renderIfFeatureFlagEnabled(Features.EnableBuildingEditor) {
                                    dashboardMainPageButton(
                                        value = Unit,
                                        routingMap = tool.route,
                                        icon = tool.icon,
                                        title = tool.getTranslationFlow(),
                                        active = (parseEnumValue<WorkspaceToolsMenu>(routerStore.current[WorkspaceToolsMenu.routeKey])
                                            ?: WorkspaceToolsMenu.WorkspaceOptions) == tool,
                                    )
                                }
                            }

                            else -> {
                                dashboardMainPageButton(
                                    value = Unit,
                                    routingMap = tool.route,
                                    icon = tool.icon,
                                    title = tool.getTranslationFlow(),
                                    active = (parseEnumValue<WorkspaceToolsMenu>(routerStore.current[WorkspaceToolsMenu.routeKey])
                                        ?: WorkspaceToolsMenu.WorkspaceOptions) == tool,
                                )
                            }
                        }
                    }
                }
                div {
                    twLargeIconButtonNeutralRounded(FormationUIIcons.Close) {
                        clicks handledBy routerStore.goToMap
                    }
                }
            }
            div("grow bg-white w-max-content sm:rounded-xl shadow-sm sm:mx-3 sm:mb-3 p-3 overflow-y-auto") {
                routerStore.router.select(WorkspaceToolsMenu.routeKey).render { (subPage, params) ->
                    params[Pages.routeKey].takeIf {
                        it == Pages.AdminDashboard.name
                    }?.let {
                        val selectedSubPage = parseEnumValue<WorkspaceToolsMenu>(subPage) ?: WorkspaceToolsMenu.WorkspaceOptions
                        when (selectedSubPage) {
                            WorkspaceToolsMenu.WorkspaceOptions -> workspaceOptions()
                            WorkspaceToolsMenu.UserManagement -> userManagement()
                            WorkspaceToolsMenu.Buildings -> buildingsAndFloorEditorScreen()
                        }
                    }
                }
            }
        }
    }
}
