package koin

import analytics.AnalyticsService
import analyticsdashboard.ActiveHistoryPathSearchFieldValuesStore
import analyticsdashboard.ActiveHistoryPathSearchKeywordsStore
import analyticsdashboard.ActiveHistoryPathSearchObjectTypesStore
import analyticsdashboard.ActiveHistoryPathSearchOptionsStore
import analyticsdashboard.ActiveHistoryPathSearchReadOnlyKeywordsStore
import analyticsdashboard.AnalyticsPageStore
import analyticsdashboard.AnalyticsSidePageStore
import analyticsdashboard.AnalyticsSideSubPageStore
import analyticsdashboard.PathActiveHighlightedObjectStore
import analyticsdashboard.PathSearchResultsStore
import analyticsdashboard.PathToolSearchInputStore
import analyticsdashboard.importexport.ImportFileStoreJs
import analyticsdashboard.importexport.ObjectImportExportStore
import apiclient.FormationClient
import apiclient.apiversion.Platform
import apiclient.auth.LoginAndTokenRefreshService
import apiclient.geoobjects.Zones
import apiclient.markers.ZoneService
import apiclient.util.createHttpClient
import apiclient.websocket.AnalyticsModule
import apiclient.websocket.LocationWebsocketModule
import apiclient.websocket.MarkersWebsocketModule
import apiclient.websocket.NotificationsWebsocketModule
import apiclient.websocket.WebsocketApiClient
import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import auth.FeatureFlagStore
import auth.SessionIdStore
import auth.TermsStore
import auth.WorkspaceOptionsStore
import auth.WorkspacesStore
import auth.permissions.PermissionModalStore
import auth.permissions.PermissionsService
import camera.nfc.NfcService
import camera.nimiq.ActiveCameraStore
import camera.nimiq.BrowserCamera
import camera.nimiq.CameraListStore
import camera.nimiq.InversionModeStore
import camera.nimiq.QRScanModalStore
import camera.nimiq.SwitchCameraStore
import com.jillesvangurp.serializationext.DEFAULT_JSON
import data.ObjectAndUserHandler
import data.connectableshapes.ActiveConnectableShapeStore
import data.connectableshapes.ActiveSourceConnectorStore
import data.connectableshapes.ActiveTargetConnectorStore
import data.connectableshapes.DeleteConnectionConnectorIdStore
import data.connectableshapes.NewConnectableShapeConnectionStore
import data.heatmaplayer.ActiveHeatmapLayerDefinitionStore
import data.heatmaplayer.ActiveHeatmapLayerMetaDataStore
import data.keywordlayer.ActiveKeywordLayerDefinitionStore
import data.keywordlayer.ActiveKeywordLayerMetaDataStore
import data.keywordlayer.MapLayerTypeSwitchStore
import data.objects.ActiveObjectFieldValuesStore
import data.objects.ActiveObjectKeywordsStore
import data.objects.ActiveObjectStore
import data.objects.AssigneeSelectorStore
import data.objects.AttendeesSelectorStore
import data.objects.CurrentActiveFieldValueStore
import data.objects.DatePickerStore
import data.objects.DescriptionInputFieldStore
import data.objects.DurationPickerStore
import data.objects.TimePickerStore
import data.objects.WorkspaceCategoryNamespacesStore
import data.objects.building.ActiveBuildingOverrideStore
import data.objects.building.ActiveBuildingStore
import data.objects.building.ActiveFloorLevelStore
import data.objects.building.ActiveFloorsStore
import data.objects.building.CurrentBuildingsStore
import data.objects.objecthistory.ActiveHistoryEntryStore
import data.objects.objecthistory.DisplayedPathObjectResultsStore
import data.objects.objecthistory.ObjectHistoryResultsCache
import data.objects.objecthistory.ObjectHistoryResultsStore
import data.objects.objecthistory.ShowObjectHistoryPathStore
import data.objects.views.CurrentClusterStore
import data.objects.views.attachments.AttachedGeoObjectsStore
import data.objects.views.attachments.AttachmentsStore
import data.objects.views.attachments.FileHandlerStore
import data.objects.views.attachments.FileStoreFritz2
import data.objects.views.attachments.FileStoreJS
import data.objects.views.attachments.GeoObjectDataStore
import data.objects.views.attachments.ImageFileDataStore
import data.objects.views.attachments.ImagePreviewStore
import data.objects.views.attachments.MarkdownDataStore
import data.objects.views.attachments.MarkdownPreviewStore
import data.objects.views.attachments.PollDataStore
import data.objects.views.attachments.PreAttachmentsStore
import data.objects.views.attachments.RemovedAttachmentsStore
import data.objects.views.attachments.TaskTemplateDataStore
import data.objects.views.attachments.TaskTemplateLatLonStore
import data.objects.views.attachments.TaskTemplateStore
import data.objects.views.attachments.TaskTemplateUseMapCenterStore
import data.objects.views.attachments.WeblinkDataStore
import data.users.ActiveUserStore
import data.users.PublicUserProfileCache
import data.users.UserListStore
import data.users.profile.MyProfileStore
import data.users.profile.UpdatePasswordStore
import data.users.profile.VerificationTokenStore
import data.users.profile.VerifiedUserStore
import data.users.settings.LocalSettingsStore
import data.users.settings.NotificationPreferencesStore
import data.users.settings.SyncedUserPreferencesStore
import dev.fritz2.core.storeOf
import dev.fritz2.routing.routerOf
import generated.ServerConfig
import geofenceeditor.GeoShapeStore
import indexeddb.IDBRepository
import io.ktor.http.*
import kotlin.math.absoluteValue
import kotlin.time.Duration.Companion.minutes
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.receiveAsFlow
import layercache.GeoObjectDetailsCache
import layercache.GeoObjectDetailsCacheEntry
import layercache.GeoObjectDetailsDatabase
import localization.LocaleStore
import localization.Locales
import location.GeoPositionStore
import location.LocationFollowStore
import location.LocationUploadStore
import login.ChangeWorkspaceStore
import login.DirectAnonymousLoginStore
import login.EmailLoginService
import login.HostConfig
import login.HostConfigStore
import login.WorkspaceInputStore
import mainmenu.AppStateStore
import mainmenu.OnBoardingPageStore
import mainmenu.PeriodicSearchTimeUnitStore
import mainmenu.PeriodicSearchTimeValueStore
import mainmenu.about.DisclaimerLanguageStore
import mainmenu.about.FeedbackStore
import mainmenu.about.TermsLanguageStore
import map.MapLayerUserSettingsStore
import map.MapLayersStore
import map.MapStateStore
import map.MaplibreTileLayersStore
import map.UserMapMarkerStore
import map.bottombar.ActiveBottomBarPageStore
import map.views.MapStyleSelectionStore
import map.views.workplacetools.ActiveArchetypeSearchFieldValuesStore
import map.views.workplacetools.ActiveArchetypeSearchKeywordsStore
import map.views.workplacetools.ArchetypeSearchContextStore
import map.views.workplacetools.ArchetypeSearchInputFieldStore
import map.views.workplacetools.ArchetypesStore
import map.views.workplacetools.SuggestedArchetypeTagsStore
import maplibreGL.MaplibreMap
import maplibreGL.renderer.ActiveTileLayerSourceIdsStore
import maplibreGL.renderer.GeoJsonGeometryRender
import maplibreGL.renderer.MeasuringToolRender
import maplibreGL.renderer.TemporaryMarkerRender
import maplibreGL.renderer.TileLayerRender
import markdown.MarkdownService
import measuringTool.MeasuringToolStore
import notifications.GlobalNotificationResultsStore
import notifications.NotificationFilterStore
import notifications.NotificationSearchInputFieldStore
import objectrouting.LastKnownPositionStore
import objectrouting.NavigationRender
import objectrouting.NavigationSearchHistoryStore
import objectrouting.NavigationStore
import objectrouting.NavigationToolToggleStore
import org.koin.core.module.dsl.singleOf
import org.koin.core.qualifier.named
import org.koin.core.qualifier.qualifier
import org.koin.dsl.module
import overlays.BusyStore
import overlays.TokenExpiredException
import poll.ActivePollStore
import poll.SelectedPollOptionsStore
import qrcode.CodeConfigurationStore
import qrcode.HasCameraStore
import qrcode.ManualCodeInputStore
import qrcode.ManualEnterStore
import qrcode.ScannedCodeStore
import qrcode.ScannedCodeTypeStore
import search.PathActiveHighlightedObjectMarkersStore
import search.SearchContextsStore
import search.SearchDistanceCalculationStore
import search.SearchResultsCoordinator
import search.global.ActiveGlobalSearchOptionsStore
import search.global.ActiveSearchFieldValuesStore
import search.global.ActiveSearchKeywordsStore
import search.global.ActiveSearchObjectTypesStore
import search.global.ActiveSearchReadOnlyKeywordsStore
import search.global.GlobalSearchResultsStore
import search.global.SearchInputFieldStore
import search.hub.HubDistanceToCentroidStore
import search.hub.HubObjectTypeFilterStore
import search.hub.HubSeparateResultsStore
import search.hub.HubUnifiedResultsStore
import search.nestedObjects.ActiveNestedObjectTypesStore
import search.nestedObjects.ActiveNestedObjectsReadOnlyKeywordsStore
import search.nestedObjects.ActiveNestedObjectsSearchOptionsStore
import search.nestedObjects.ActiveNestedSearchFieldValuesStore
import search.nestedObjects.ActiveNestedSearchKeywordStore
import search.nestedObjects.NestedObjectSearchInputFieldStore
import search.nestedObjects.NestedObjectsResultsStore
import search.nestedObjects.SearchPageStore
import search.searchlayer.MapLayerMetadataListStore
import search.searchlayer.MapSearchClientsStore
import search.searchlayer.MapSearchResultsStore
import search.searchlayer.PeriodicSearchStore
import services.GeoPositionService
import services.GroupService
import services.SuggestedTagsContextStore
import services.SuggestedTagsStore
import services.UserService
import signup.ActivationCodeStore
import signup.SignupFromInviteStore
import signup.SignupStore
import signup.ValidatePasswordStore
import signup.WorkspaceCreationStore
import theme.AppThemeStore
import web.idb.IDBIndexParameters
import websocket.MarkerClientStore
import websocket.NotificationClientStore
import websocket.UserAndObjectResultsStore
import wizard.onbaording.OnboardingWizardDemoCodeStore
import wizard.onbaording.OnboardingWizardLoginCodeStore
import wizard.onbaording.OnboardingWizardPageStore

enum class FlowQualifiers {
    WebsocketNotifications
}

fun common() = module {
    single { DEFAULT_JSON }
    single { BusyStore() }
    single { AppThemeStore() }

    single(qualifier("geoObjects")) {
        IDBRepository<String, GeoObjectDetailsCacheEntry>(
            serializer = GeoObjectDetailsCacheEntry.serializer(),
            dbName = "form-geoObjectCache",
            storeName = "geoObjectCache",
            // bumping the version causes the db to be recreated
            version = 2,
            jsonKeyPath = GeoObjectDetailsCacheEntry::id.name,
        ) {
            createIndex(
                GeoObjectDetailsCacheEntry::retrieveTime.name,
                GeoObjectDetailsCacheEntry::retrieveTime.name,
                IDBIndexParameters(unique = false, multiEntry = false),
            )
        }
    }
    single {
        GeoObjectDetailsDatabase(
            idb = get(qualifier("geoObjects")),
        )
    }

    single {
        GeoObjectDetailsCache(
            database = get(),
        )
    }
}

fun URLBuilder.setHttpHostConfig(hostConfig: HostConfig): URLBuilder {
    this.host = hostConfig.host
    this.port = hostConfig.port
    this.protocol = if (hostConfig.ssl) URLProtocol.HTTPS else URLProtocol.HTTP
    return this
}

fun URLBuilder.setWsHostConfig(hostConfig: HostConfig): URLBuilder {
    this.host = hostConfig.host
    this.port = hostConfig.port
    this.protocol = if (hostConfig.ssl) URLProtocol.WSS else URLProtocol.WS
    return this
}

fun apiAndWsClient() = module {
    // default host config, use the HostConfigStore instead
    single { createHttpClient() }
    single { SessionIdStore() }
    single { HostConfig(ServerConfig.host, ServerConfig.port, ServerConfig.ssl) }
    single { HostConfigStore(get()) }
    single { DirectAnonymousLoginStore() }
    single { WorkspaceInputStore() }
    single { WorkspaceOptionsStore() }
    single { ApiUserStore(json = get()) } // FIXME circular dependency with LoginAndTokenRefreshService

    single {
        val hostConfigStore = get<HostConfigStore>()
        LoginAndTokenRefreshService(
            graphQLClient = hostConfigStore.anonymousClient(),
            apiUserItem = get<ApiUserStore>(),
        )
    }

    single {
        val loginService = get<LoginAndTokenRefreshService>()
        val hostConfigStore = get<HostConfigStore>()
        val sessionIdStore = get<SessionIdStore>()
        FormationClient(
            urlBuilder = {
                this.setHttpHostConfig(hostConfigStore.current)
            },
            tokenSupplier = {
                loginService.getOrRefreshAccessToken() ?: run {
                    console.warn("TokenSupplier says no! -> Token expired.")
                    throw TokenExpiredException()
                }
            },
            httpClient = get(),
            json = get(),
            platform = Platform.web.name,
            version = ServerConfig.apiVersion,
            sessionIdProvider = { sessionIdStore.current?.id },

            )
    }
    single { LocationWebsocketModule() }
    single { MarkersWebsocketModule() }
    single { NotificationsWebsocketModule() }
    single { AnalyticsModule() }
    single {
        val loginService: LoginAndTokenRefreshService = get()
        val hostConfigStore = get<HostConfigStore>()
        val sessionIdStore = get<SessionIdStore>()
        sessionIdStore.ensureHasSession()
        WebsocketApiClient(
            urlBuilder = {
                this.setWsHostConfig(hostConfigStore.current)
            },

            httpClient = get(),
            json = get(),
            tokenSupplier = {
                loginService.getOrRefreshAccessToken()
            },
            restartAfter = 10.minutes,
            sessionIdProvider = { sessionIdStore.current?.id ?: "-" },
            modules = arrayOf(
                get<LocationWebsocketModule>(),
                get<MarkersWebsocketModule>(),
                get<NotificationsWebsocketModule>(),
                get<AnalyticsModule>(),
            ),
        )
    }
    single(named(FlowQualifiers.WebsocketNotifications)) {
        get<NotificationsWebsocketModule>().notifications.receiveAsFlow()
    }
    single { CurrentWorkspaceStore() }
    single { WorkspacesStore() }
    single { WorkspaceCreationStore() }
}

fun routingModule() = module {
    single { routerOf(mapOf()) }
    single { LocalSettingsStore() }
    single { TermsStore(apiUserStore = get()) }
    single { ChangeWorkspaceStore() }
    single { AppStateStore() }
    single { FeatureFlagStore() }
}

fun userModule() = module {
    single { UserService(get()) }
    single { MyProfileStore() }
    single { SyncedUserPreferencesStore() }
    single { NotificationPreferencesStore() }
    single {
        LocaleStore(
            syncedUserPreferencesStore = get(),
            Locales.EN_GB,
        )
    }
    single { TermsLanguageStore(localeStore = get()) }
    single { DisclaimerLanguageStore(localeStore = get()) }
    single { EmailLoginService() }
    single { UserMapMarkerStore() }
}

fun tagsModule() = module {
    single { SuggestedTagsContextStore() }
    single { SuggestedTagsStore() }
    single { WorkspaceCategoryNamespacesStore() }
}

fun attachmentModule() = module {
    single { AttachedGeoObjectsStore() }
    single { MarkdownPreviewStore() }
    single { ImagePreviewStore() }
    single { MarkdownService() }
    single { FileStoreFritz2() }
    single { FileStoreJS() }
    single { ImageFileDataStore() }
    single { FileHandlerStore() }
    single { WeblinkDataStore() }
    single { GeoObjectDataStore() }
    single { MarkdownDataStore() }
    single { PollDataStore() }
    single { TaskTemplateUseMapCenterStore() }
    single { TaskTemplateLatLonStore() }
    single { TaskTemplateDataStore() }
    single { TaskTemplateStore() }
    single { RemovedAttachmentsStore() }
    single { PreAttachmentsStore() }
    single { AttachmentsStore() }
    single { SelectedPollOptionsStore() }
    single { ActivePollStore(null) }
}

fun browserCameraModule() = module {
    single { CameraListStore() }
    single { ActiveCameraStore() }
    single { InversionModeStore() }
    single { BrowserCamera() }
    single { SwitchCameraStore() }
    single { ManualEnterStore() }
    single { HasCameraStore() }
    single { ScannedCodeTypeStore() }
    single { ManualCodeInputStore() }
    single { QRScanModalStore() }
}

fun mapModule() = module {
    single {
        MaplibreMap(
            targetId = "map-${kotlin.random.Random.nextLong().absoluteValue}",
            centerLat = 52.51648201881731,
            centerLng = 13.382720947265627,
            initZoom = 13.0,
        )
    }
    single {
        MapStateStore(
            apiUserStore = get(),
            json = get(),
        )
    }
    single { MapLayersStore() }
    single { MaplibreTileLayersStore() }
    single { ActiveBuildingOverrideStore() }
    single { ActiveBuildingStore() }
    single { ActiveFloorLevelStore() }
    single { ActiveFloorsStore() }
    single { CurrentBuildingsStore() }
    single { SearchResultsCoordinator() }
    single { MapSearchResultsStore() }
    single { CurrentClusterStore() }
    single { MeasuringToolRender() }
    single { MeasuringToolStore() }
    single { TemporaryMarkerRender() }
    single { GeoJsonGeometryRender() }
    single { ActiveTileLayerSourceIdsStore() }
    single { TileLayerRender() }
}

fun geoLocationModule() = module {
    single { storeOf<Zones>(initialData = emptyList(), job = Job(), id = "zone-store") }
    single { ZoneService(get()) }
    single { GeoPositionStore() }
    single { SearchDistanceCalculationStore() }
    single { GeoPositionService() }
    single { LocationFollowStore() }
    single { LocationUploadStore(apiUserStore = get()) }
    single { PermissionModalStore() }
    single { PermissionsService() }
    single { LastKnownPositionStore() }
}

fun analyticsModule() = module {
    single { PathActiveHighlightedObjectStore() }
    single { PathActiveHighlightedObjectMarkersStore() }
    single { PathToolSearchInputStore() }
    single { PathSearchResultsStore() }
    single { DisplayedPathObjectResultsStore() }
    single { ObjectHistoryResultsCache() }
    single { AnalyticsPageStore() }
    single { AnalyticsSidePageStore() }
    single { AnalyticsSideSubPageStore() }
    single { ActiveHistoryPathSearchKeywordsStore() }
    single { ActiveHistoryPathSearchFieldValuesStore() }
    single { ActiveHistoryPathSearchObjectTypesStore() }
    single { ActiveHistoryPathSearchReadOnlyKeywordsStore() }
    single { ActiveHistoryPathSearchOptionsStore() }
    single { ObjectImportExportStore() }
    single { ImportFileStoreJs() }
}

fun searchModule() = module {
    single { PeriodicSearchTimeValueStore() }
    single { PeriodicSearchTimeUnitStore() }
    single { PeriodicSearchStore() }
    single { SearchInputFieldStore() }
    single { SearchContextsStore(apiUserStore = get()) }
    single { ActiveSearchKeywordsStore() }
    single { ActiveSearchObjectTypesStore() }
    single { ActiveSearchReadOnlyKeywordsStore() }
    single { CurrentActiveFieldValueStore() }
    single { ActiveSearchFieldValuesStore() }
    single { ActiveGlobalSearchOptionsStore() }
    single { ActiveObjectKeywordsStore() }
    single { ActiveObjectFieldValuesStore() }
//    single {
//        LayerCacheMain(
//            runtimeExcludeTags = emptyList(),
//            debug = false,
//            testing = false,
//        )
//    }
}

fun globalSearchModule() = module {
    single { GlobalSearchResultsStore() }
}

fun hubModule() = module {
    single { HubUnifiedResultsStore() }
    single { HubSeparateResultsStore() }
    single { HubDistanceToCentroidStore() }
    single { HubObjectTypeFilterStore() }
}

fun bottomBarModule() = module {
    single { ActiveBottomBarPageStore() }
}

fun verificationModule() = module {
    single { VerificationTokenStore() }
    single { VerifiedUserStore() }
}

fun mapLayerModule() = module {
    single { MapLayerTypeSwitchStore() }
    single { ActiveKeywordLayerMetaDataStore() }
    single { ActiveKeywordLayerDefinitionStore() }
    single { ActiveHeatmapLayerMetaDataStore() }
    single { ActiveHeatmapLayerDefinitionStore() }
}

fun objectHistoryModule() = module {
    single { ActiveHistoryEntryStore() }
    single { ObjectHistoryResultsStore() }
    single { ShowObjectHistoryPathStore() }
}

fun geofenceEditorModule() = module {
    single { GeoShapeStore(null) }
}

fun connectableShapeModule() = module {
    single { ActiveSourceConnectorStore() }
    single { ActiveTargetConnectorStore() }
    single { NewConnectableShapeConnectionStore() }
    single { DeleteConnectionConnectorIdStore() }
    single { ActiveConnectableShapeStore() }
}

fun archetypeModule() = module {
    single { ArchetypeSearchInputFieldStore() }
    single { ActiveArchetypeSearchKeywordsStore() }
    single { ArchetypeSearchContextStore() }
    single { ActiveArchetypeSearchFieldValuesStore() }
    single { SuggestedArchetypeTagsStore() }
    single { ArchetypesStore() }
}

fun nestedObjectSearchModule() = module {
    single { ActiveNestedSearchKeywordStore() }
    single { ActiveNestedSearchFieldValuesStore() }
    single { ActiveNestedObjectTypesStore() }
    single { ActiveNestedObjectsReadOnlyKeywordsStore() }
    single { NestedObjectSearchInputFieldStore() }
    single { ActiveNestedObjectsSearchOptionsStore() }
}

fun wizardModule() = module {
    single {
        OnboardingWizardPageStore(
            json = get(),
            localSettingsStore = get(),
        )
    }
    single { OnboardingWizardDemoCodeStore() }
    single { OnboardingWizardLoginCodeStore() }
}

fun codeGenerationModule() = module {
    single { CodeConfigurationStore() }
}

fun navigationModule() = module {
    single { NavigationToolToggleStore() }
    single { NavigationStore() }
    single { NavigationSearchHistoryStore() }
    single { NavigationRender() }
}

fun legacyKoinModuleThatIsWayTooBig() = module {
    // keep on moving stuff out of here
    single { OnBoardingPageStore() }
    single { ActivationCodeStore() }
    single { ValidatePasswordStore() }
    single { SignupStore(get()) }
    single { SignupFromInviteStore() }
    single { AnalyticsService() }
    single { ScannedCodeStore() }
    single { FeedbackStore() }
    single { NfcService() }
    single { MapStyleSelectionStore(localSettingsStore = get()) }
    single { AttendeesSelectorStore() }
    single { AssigneeSelectorStore() }
    single { NotificationSearchInputFieldStore() }
    single { DatePickerStore() }
    single { TimePickerStore() }
    single { DurationPickerStore() }
    single { UserAndObjectResultsStore() }
    single { GlobalNotificationResultsStore() }
    single { NotificationClientStore() }
    single { SearchPageStore() }
    single { NestedObjectsResultsStore() }
    single { NotificationFilterStore() }
    single { ActiveObjectStore() }
    single { DescriptionInputFieldStore() }


    single { MapSearchClientsStore() }
    single { MapLayerMetadataListStore() }
    single { MapLayerUserSettingsStore() }

    single { ActiveUserStore() }
    singleOf(::PublicUserProfileCache)
    single { UserListStore() }
    single { ObjectAndUserHandler() }
    single { MarkerClientStore() }
    single { GroupService(get()) }
    single { UpdatePasswordStore() }
}
