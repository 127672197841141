package login

import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.autofocus
import dev.fritz2.core.id
import dev.fritz2.core.placeholder
import dev.fritz2.core.values
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.Translation
import mainmenu.Pages
import mainmenu.RouterStore
import model.Credentials
import model.email
import webcomponents.baseLayout
import webcomponents.fullWidthFullContainer
import webcomponents.genericInput
import webcomponents.inputLabelWrapper
import webcomponents.mainTitle
import webcomponents.oneButtonFooter
import webcomponents.twoButtonFooter

fun RenderContext.pageForgotPassword(workspace: String?, emailParam: String?) {
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val workspaceInputStore: WorkspaceInputStore by koinCtx.inject()
    val credentialsStore: CredentialsStore by koinCtx.inject()
    val email = credentialsStore.map(Credentials.email())

    if (!emailParam.isNullOrBlank()) {
        email.update(emailParam)
    }
    if (!workspace.isNullOrBlank()) {
        workspaceInputStore.update(workspace)
    }

    baseLayout(
        header = {
//            cardTitle(translation[TL.Login.FORGOT_PASSWORD])
        },
        content = {
            fullWidthFullContainer {
                div("flex grow") { }

                mainTitle(translation[TL.Login.RESET_PASSWORD])

                div("flex grow") { }
                stackUp(
                    {
                        width { full }
                        display { flex }
                        alignItems { stretch }
                    },
                ) {
                    spacing { larger }
                    items {
                        p("text-center") {
                            translation[TL.Login.RESET_PW_INFO_TEXT].renderText(into = this)
                        }
                        // Input workspace
                        inputLabelWrapper(
                            title = translation[TL.Login.WORKSPACE],
                            visibilityFlow = workspaceInputStore.data.map { it.isNotBlank() },
                        ) {
                            genericInput(
                                value = workspaceInputStore.data.mapNotNull { it.ifBlank { null } },
                            ) {
                                id("workspace")
                                placeholder(translation[TL.Login.WORKSPACE])
                                inputs.values() handledBy workspaceInputStore.update
                                autofocus(true)
                                attr("tabindex", "1")
                            }
                        }
                        // Input email
                        inputLabelWrapper(
                            title = translation[TL.Login.EMAIL],
                            visibilityFlow = email.data.map { it.isNotBlank() },
                        ) {
                            genericInput(
                                value = email.data,
                                type = "email",
                            ) {
                                id("inputEmail")
                                placeholder(translation[TL.Login.EMAIL])
                                inputs.values() handledBy email.update
                                attr("tabindex", "2")
                            }
                        }
                    }
                }
                div("flex grow-3") { }
            }
        },
        footer = {
            twoButtonFooter(
                primaryTitle = translation[TL.Login.SEND_PW_RESET_LINK],
                primaryValue = Unit,
                primaryClickHandlers = listOf(credentialsStore.sendPasswordResetLink),
                primaryState = combine(
                    workspaceInputStore.data,
                    email.data,
                ) { ws, email -> ws.isNotBlank() && email.isNotBlank() },
                secondaryTitle = translation[TL.General.BACK],
                secondaryClickHandlers = listOf(routerStore.back),
            )
        },
    )
}

fun RenderContext.pageResetPasswordLinkSend() {
    val translation: Translation by koinCtx.inject()

    baseLayout(
        header = {},
        content = {
            fullWidthFullContainer {
                div("flex grow") { }
                mainTitle(translation[TL.Login.EMAIL_SEND])
                div("flex grow") { }
                p("text-center") {
                    translation[TL.Login.RESET_PW_LINK_SEND_TEXT].renderText(into = this)
                }
                div("flex grow") { }
                p("text-center") {
                    translation[TL.Login.RESET_PW_TEXT_CHECK_YOUR_EMAIL].renderText(into = this)
                }
                div("flex grow-3") { }
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.DONE],
                routingMap = Pages.Login.route,
                value = Unit,
            )
        },
    )
}
