package search.searchFilterStores

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job

abstract class ActiveKeywordsStore : RootStore<List<String>>(
    initialData = emptyList(),
    job = Job(),
) {

    private val analyticsService: AnalyticsService by koinCtx.inject()

    val add = handle<String> { current, newKeyword ->
        if (newKeyword.isNotBlank() && newKeyword !in current) {
            analyticsService.createEvent(AnalyticsCategory.AddKeyWord) {
                click(newKeyword)
            }
            (current + newKeyword).distinct()
        } else current
    }

    val remove = handle<String> { current, keyWord ->
        if (current.isNotEmpty() && keyWord in current) (current - keyWord).distinct()
        else current
    }

    val reset = handle {
        emptyList()
    }
}
