package map.views.workplacetools

import analytics.AnalyticsCategory
import auth.ApiUserStore
import dev.fritz2.components.compat.div
import dev.fritz2.components.flexBox
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import search.searchInput
import theme.FormationColors
import theme.FormationDefault
import theme.FormationIcons
import utils.toKeyWordTagsList
import webcomponents.KeywordTagActionType
import webcomponents.KeywordTagType
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.cardTitle
import webcomponents.circleIconButton
import webcomponents.keywordTagList
import webcomponents.oneButtonFooter
import webcomponents.twContentScrollBox

fun RenderContext.cardToolsOverview() {
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val archetypesStore: ArchetypesStore by koinCtx.inject()
    val archetypeSearchInputFieldStore: ArchetypeSearchInputFieldStore by koinCtx.inject()
    val activeArchetypeSearchKeywordsStore: ActiveArchetypeSearchKeywordsStore by koinCtx.inject()
    val activeArchetypeSearchFieldValuesStore: ActiveArchetypeSearchFieldValuesStore by koinCtx.inject()

    archetypesStore.fetchArchetypes(null)

    baseLayout(
        header = {
            flexBox(
                {
                    direction { column }
                    justifyContent { center }
                    alignItems { center }
                },
            ) {
                cardTitle(translation[TL.CardToolSelect.HEADER])
                cardSubtitle(translation[TL.CardToolSelect.DESCRIPTION])
            }
        },
        content = {
            // SEARCH INPUT FIELD
            div(
                "w-full flex items-center px-3 mb-1 gap-3",
            ) {
                inlineStyle("height: ${FormationDefault.formationStyles.inputHeight};")
                searchInput(
                    id = "inputArchetypeSearch",
                    valueStore = archetypeSearchInputFieldStore,
                    disabled = apiUserStore.current.isAnonymous,
                )
                circleIconButton(
                    size = FormationDefault.formationStyles.inputHeight,
                    iconFlow = flowOf { FormationIcons.Tag.icon },
                    iconSize = { large },
                    styleFlow = flowOf {
                        background {
                            color { secondary.main }
                        }
                        border {
                            color { primary.main }
                            width(FormationDefault.formationStyles.borderWidth)
                        }
                        hover {
                            color { secondary.main }
                            background {
                                color { FormationColors.MarkerYou.color }
                            }
                            border {
                                color { FormationColors.MarkerYou.color }
                            }
                        }
                        radius(FormationDefault.formationStyles.inputRadius)
                    },
                    disabled = apiUserStore.current.isAnonymous,
                    tooltipText = translation[TL.KeywordTags.ADD_A_TAG_TOOLTIP], // TODO change tooltip to "manage search tags"
                    value = Unit,
                    addOrReplaceRoute = mapOf("change" to "tags"),
                )
            }
            // active Tags
            combine(
                activeArchetypeSearchKeywordsStore.data,
                activeArchetypeSearchFieldValuesStore.data,
            ) { keywords, fieldValues ->
                keywords.toKeyWordTagsList(KeywordTagActionType.Remove) + fieldValues.toKeyWordTagsList(
                    KeywordTagActionType.RemoveFieldValue,
                )
            }.render { combinedKeywords ->
                if (combinedKeywords.isNotEmpty()) {
                    div(
                        {
                            width { full }
                            paddings {
                                horizontal { small }
                            }
                            margins {
                                bottom { tiny }
                            }
                        },
                    ) {
                        keywordTagList(
                            keywords = flowOf(combinedKeywords),
                            keywordTagType = KeywordTagType.ArchetypeTag,
                        )
                    }
                }
            }
            twContentScrollBox {
                stackUp(
                    {
                        width { full }
                        justifyContent { spaceBetween }
                        alignItems { center }
                    },
                ) {
                    spacing { smaller }
                    items {
                        archetypesStore.data.render { archetypes ->
                            // legacy tool items
                            val legacyToolButtons = ToolSelection.entries.map { it.toolButton }

                            // archetype tool items
                            val archetypeButtons: List<RenderContext.() -> Unit> =
                                if (!apiUserStore.current.isAnonymous) {
                                    archetypes.map { archetype ->
                                        {
                                            archetypeToolButton(
                                                archetype,
                                                valueHandlers = listOf(archetypesStore.createFromArchetype),
                                                analyticsEventProvider = AnalyticsCategory.CreateFromArchetype.click(
                                                    label = "Create object from archetype: ${archetype.title} (${archetype.id})",
                                                ),
                                                disabled = apiUserStore.data.map { user -> user.isAnonymous },
                                            )
                                        }
                                    }
                                } else {
                                    listOf()
                                }

                            // calculate number of rows matching the given number of columns
                            val columns = 3
                            val rest = legacyToolButtons.size % columns
                            val rows = legacyToolButtons.size / columns + (if (rest > 0) 1 else 0)
                            for (i in 0 until rows) {
                                toolsGridRow(
                                    items = legacyToolButtons.filterIndexed { index, _ ->
                                        index in i * columns until i * columns + columns
                                    },
                                    columns = columns,
                                    toolButtonHeight = toolButtonHeight,
                                    toolButtonWidth = toolButtonWidth,
                                )
                            }

                            // calculate number of rows matching the given number of columns
                            val archetypeColumns = 2
                            val archetypeRest = archetypeButtons.size % archetypeColumns
                            val archetypeRows =
                                archetypeButtons.size / archetypeColumns + (if (archetypeRest > 0) 1 else 0)
                            for (i in 0 until archetypeRows) {
                                toolsGridRow(
                                    items = archetypeButtons.filterIndexed { index, _ ->
                                        index in i * archetypeColumns until i * archetypeColumns + archetypeColumns
                                    },
                                    columns = archetypeColumns,
                                    toolButtonHeight = libraryButtonHeight,
                                    toolButtonWidth = libraryButtonWidth,
                                )
                            }
                        }
                    }
                }
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.BACK],
                value = Unit,
                clickHandlers = listOf(routerStore.back),
            )
        },
    )
}

fun RenderContext.toolsGridRow(
    items: List<RenderContext.() -> Unit>,
    columns: Int,
    toolButtonHeight: Int,
    toolButtonWidth: Int,
) {

    lineUp(
        {
            width { full }
            justifyContent { spaceEvenly }
            alignItems { center }
        },
    ) {
        spacing { smaller }
        items {
            items.forEach { gridElement ->
                gridElement.invoke(this)
            }
            if (items.size < columns) {
                for (i in items.size until columns) {
                    div(
                        {
                            width { "${toolButtonWidth}px" }
                            height { "${toolButtonHeight}px" }
                        },
                    ) { }
                }
            }
        }
    }
}
