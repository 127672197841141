package data.objects.views

import auth.CurrentWorkspaceStore
import apiclient.customfields.parseFieldValues
import apiclient.geoobjects.GeoObjectDetails
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import model.L
import mainmenu.RouterStore
import data.objects.ActiveObjectStore
import theme.FormationIcons
import utils.extractReadOnlyTags
import utils.mergeTagLists
import utils.respectFeatureFlags
import utils.toKeyWordTagsList
import webcomponents.*

fun RenderContext.cardShowKeywordTags() {

    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val currentWorkspaceStore: CurrentWorkspaceStore by koinCtx.inject()
    val keywords = activeObjectStore.map(GeoObjectDetails.L.keywords)
    val readOnlyKeywordTags = activeObjectStore.map(GeoObjectDetails.L.tags).data.extractReadOnlyTags()
    val fieldValueTags = activeObjectStore.map(GeoObjectDetails.L.tags).data.respectFeatureFlags().map { tags ->
        currentWorkspaceStore.current?.fieldDefinitions?.let { definitions ->
            tags.parseFieldValues(definitions)
        }
    }.map { it?.toKeyWordTagsList(KeywordTagActionType.DefaultFieldValue) ?: emptyList() }
    val title = activeObjectStore.map(GeoObjectDetails.L.title)
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()

    baseLayout(
        header = {
            cardTitle(translation[TL.KeywordTags.CARD_TITLE]) { FormationIcons.Tag.icon }
            cardSubtitle(title.data)
        },
        content = {
            twContentScrollBox {
                keywordTagList(
                    keywords = mergeTagLists(
                        readOnlyTags = readOnlyKeywordTags,
                        keywords = keywords.data,
                        fieldValueTags = fieldValueTags,
                    ),
                    keywordTagType = KeywordTagType.ObjectTag,
                    searchable = true,
                )
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.BACK],
                value = Unit,
                clickHandlers = listOf(routerStore.back),
            )
        },
    )
}
