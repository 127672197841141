package analyticsdashboard.importexport

import analyticsdashboard.dashboardIconButton
import apiclient.FormationClient
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.SearchQueryContext
import apiclient.importexport.exportNDJson
import apiclient.importexport.importNDJson
import apiclient.tags.tag
import apiclient.util.withDuration
import auth.ApiUserStore
import com.jillesvangurp.serializationext.DEFAULT_JSON
import data.objects.views.attachments.imagePrevData
import dev.fritz2.components.compat.input
import dev.fritz2.components.flexBox
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.accept
import dev.fritz2.core.files
import dev.fritz2.core.type
import dev.fritz2.tracking.tracker
import koin.koinCtx
import kotlin.time.measureTimedValue
import kotlinx.browser.document
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.catch
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.flow.onCompletion
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.flow.toList
import kotlinx.datetime.Clock
import kotlinx.serialization.json.JsonObject
import localization.TL
import localization.Translation
import model.getFirstGroupIdOrNull
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag
import org.w3c.files.File
import org.w3c.files.FileReader
import org.w3c.files.get
import overlays.AlertOverlayStore
import overlays.BusyStore
import search.separationLine
import styling.primaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import theme.FormationUIIcons
import utils.roundTo
import webcomponents.cardTitle
import webcomponents.ellipseText
import webcomponents.genericButton

class ImportFileStoreJs : RootStore<File?>(
    initialData = null,
    job = Job(),
) {
    private val objectImportExportStore: ObjectImportExportStore by koinCtx.inject()

//    val history = history(initialEntries = listOf<File?>())
//    val loadPrevious = handle {
//        if(history.current.isNotEmpty()) history.back() else null
//    }

    val clear = handle {
        null
    }

    val fileReadTracker = tracker()

    val readFile = handle<File> { current, newFile ->
        fileReadTracker.track {
            val reader = FileReader()
            reader.onloadend = { _ ->
                objectImportExportStore.update(reader.result as String)
            }
            reader.readAsText(newFile)
        }
        current
    }

    init {
        data.mapNotNull { it } handledBy readFile
    }
}


class ObjectImportExportStore : RootStore<String>(
    initialData = "",
    job = Job(),
) {

    val apiUserStore: ApiUserStore by koinCtx.inject()
    val formationClient: FormationClient by koinCtx.inject()
    val busyStore: BusyStore by koinCtx.inject()
    val alertOverlayStore: AlertOverlayStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()

    val groupId = apiUserStore.current.getFirstGroupIdOrNull()
    private val groupName = apiUserStore.current.apiUser?.workspaceName

    val import = handle { ndjson ->
        if (ndjson.isNotBlank()) {
            groupId?.let { gId ->
                console.log("calling import ndjson")
                formationClient.importNDJson(
                    ndjson = ndjson,
                    groupId = gId,
                )
                    .catch { error ->
                        console.error("encountered error handling flow", error.message)
                        alertOverlayStore.errorNotify(translation[TL.ImportExport.IMPORTING_OBJECTS_FAILED])
                    }
                    .onEach { jsonObj ->
                        console.log("Imported blob -> ", DEFAULT_JSON.encodeToString(JsonObject.serializer(), jsonObj))
                        ndjson.split("\n").size.let { objectCount ->
                            if (objectCount > 0) {
                                alertOverlayStore.notify(
                                    translation[
                                        TL.ImportExport.IMPORTED_X_OBJECTS_SUCCESSFULLY,
                                        mapOf(
                                            "objectCount" to objectCount,
                                        ),
                                    ],
                                )
                            } else {
                                alertOverlayStore.warnNotify(translation[TL.ImportExport.NO_OBJECTS_TO_IMPORT])
                            }
                        }
                    }.collect()
            }
        }
        current
    }

    private val exportCtx = SearchQueryContext(
        groupIds = apiUserStore.current.apiUser?.groups?.map { it.groupId },
        excludeTags = listOf(
//            ObjectTags.IsArchetype.tag("true"),
            ObjectTags.Deleted.tag("true"),
            ObjectTags.Archived.tag("true"),
        ),
    )

    val export = handle { current ->

        measureTimedValue {
            formationClient.exportNDJson(exportCtx)
                .onCompletion { error ->
                    if (error != null) {
                        console.error("Exporting objects failed", error.message)
                        alertOverlayStore.errorNotify(translation[TL.ImportExport.EXPORTING_OBJECTS_FAILED])
                    } else {
                        console.log("Exported!")
                    }
                }.toList()
        }.withDuration {
            console.info("exported ${value.size} lines in $duration")
        }.joinToString("\n")
            .also { content ->
                val blob = Blob(arrayOf(content), BlobPropertyBag("application/x-ndjson"))
                val url = URL.createObjectURL(blob)
                val a = document.createElement("a") as org.w3c.dom.HTMLAnchorElement
                a.href = url
                val fileName = "data-export-${groupName}_${Clock.System.now().toString().replace(":", "-").dropLast(5)}"
                a.download = fileName
                a.style.display = "none"
                document.body?.appendChild(a)
                a.click()
                URL.revokeObjectURL(url)
                document.body?.removeChild(a)
                content.split("\n").size.let { objectCount ->
                    if (objectCount > 0) {
                        alertOverlayStore.notify(translation[TL.ImportExport.EXPORTED_X_OBJECTS_SUCCESSFULLY, mapOf("objectCount" to objectCount)])
                    } else {
                        alertOverlayStore.warnNotify(translation[TL.ImportExport.NO_OBJECTS_TO_EXPORT])
                    }
                }
            }
        current
    }
}

fun RenderContext.objectImportExport() {

    val objectImportExportStore: ObjectImportExportStore by koinCtx.inject()
    val importFileStoreJs: ImportFileStoreJs by koinCtx.inject()
    val translation: Translation by koinCtx.inject()

    stackUp(
        {
            width { full }
        },
    ) {
        spacing { small }
        items {
            cardTitle(title = translation[TL.ImportExport.EXPORT_OBJECTS_TO_FILE])
            genericButton(
                styleFlow = flowOf {
                    primaryButtonStyleParams()
                },
                width = { "120px" },
                title = translation[TL.ImportExport.EXPORT_OBJECTS],
                icon = { export },
                value = Unit,
                clickHandlers = listOf(objectImportExportStore.export),
            )

            separationLine()

            importFileStoreJs.data.render { file ->
                lineUp(
                    {
                        margins {
                            top { small }
                        }
                        minHeight(formationStyles.buttonHeight)
                        alignItems { center }
                        wrap { wrap }
                    },
                ) {
                    spacing { small }
                    items {
                        cardTitle(title = translation[TL.ImportExport.IMPORT_OBJECTS_FROM_FILE])
                        flexBox(
                            {
                                css("cursor: pointer;")
                                background {
                                    color { FormationColors.GrayLight.color }
                                }
                                height(formationStyles.inputHeight)
                                radius(formationStyles.inputRadius)
                                paddings {
                                    horizontal { small }
                                }
                                alignItems { center }
                                justifyContent { center }
                                position { relative { } }
                                css("cursor: pointer;")
                            },
                        ) {
                            input(
                                {
                                    position {
                                        absolute {
                                            top { "0" }
                                            right { "0" }
                                            left { "0" }
                                            bottom { "0" }
                                        }
                                    }
                                    width { "100%" }
                                    margin { "0" }
                                    opacity { "0" }
                                    css("filter: alpha(opacity=0);")
                                    css("cursor: pointer;")
                                },
                            ) {
                                type("file")
                                accept(".ndjson, .json, .txt")
                                changes.files().map { files ->
                                    files?.get(0)?.let { file ->
                                        console.log("file picked:", file)
                                        file
                                    }
                                } handledBy importFileStoreJs.update
                            }
                            ellipseText(
                                {
                                    css("cursor: pointer;")
                                },
                            ) {
                                file?.name?.let { +it }
                                    ?: translation[TL.ImportExport.SELECT_A_FILE].renderText(into = this)
                            }
                        }
                        if (file != null) {
                            dashboardIconButton(
                                icon = FormationUIIcons.Close,
                                tooltip = translation[TL.ImportExport.CLEAR_FILE],
                                value = Unit,
                                clickHandlers = listOf(importFileStoreJs.clear),
                            )
                        }
                    }
                }
            }

            combine(importFileStoreJs.data, objectImportExportStore.data) { file, content ->
                Pair(file, content)
            }.render { (file, fileContent) ->
                flexBox(
                    {
                        maxWidth(sm = { full }, md = formationStyles.cardWidth)
                        wrap { wrap }
                        alignItems { center }
                        justifyContent { flexStart }
                    },
                ) {
                    file?.let { f ->
                        imagePrevData(value = ".${f.name.substringAfterLast(".")}")
                        val size = f.size.toDouble() / 1024 / 1024
                        imagePrevData(
                            value = if (size < 1.0) {
                                "${(size * 1024).roundTo(0)} KB"
                            } else {
                                "${size.roundTo(2)} MB"
                            },
                        )
                        fileContent.split("\n").size.let { objectCount ->
                            imagePrevData(
                                title = translation.getString(TL.ImportExport.ROWS_OBJECTS),
                                value = if (fileContent.isBlank()) "0" else objectCount.toString(),
                            )
                        }
                    }
                }
            }

            genericButton(
                styleFlow = flowOf {
                    primaryButtonStyleParams()
                },
                width = { "120px" },
                title = translation[TL.ImportExport.IMPORT_OBJECTS],
                icon = { download },
                tracker = importFileStoreJs.fileReadTracker,
                value = Unit,
                state = objectImportExportStore.data.map { it.isNotBlank() },
                clickHandlers = listOf(objectImportExportStore.import),
            )
        }
    }
}
