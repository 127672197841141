package data.objects.views

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectType
import auth.ApiUserStore
import data.objects.ActiveObjectStore
import data.objects.AssigneeSelectorStore
import data.objects.AttendeesSelectorStore
import data.objects.DatePickerStore
import data.objects.TimePickerStore
import data.objects.views.attachments.PreAttachmentsStore
import data.objects.views.cardinfo.TagButtonType
import data.objects.views.cardinfo.keywordTagsButton
import data.users.views.profileInput
import dev.fritz2.components.icon
import dev.fritz2.core.RenderContext
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Clock
import localization.TL
import localization.Translation
import localization.translate
import mainmenu.RouterStore
import model.L
import model.ScannedCode
import model.extObjId
import model.type
import qrcode.ScannedCodeStore
import theme.FormationIcons
import twcomponents.twColOfNoGap
import twcomponents.twPageHeaderClose
import twcomponents.twPrimaryButton
import twcomponents.twSubtitle
import twcomponents.twTitle
import utils.focusInputObserver
import utils.formatDateForDatePicker
import utils.formatEventTimeSpan
import utils.formatTimeForTimePicker
import utils.getIcon
import utils.getName
import utils.handleFunctions
import utils.nextQuarterOfHour
import webcomponents.baseLayout
import webcomponents.ellipseText
import webcomponents.selectorButton
import webcomponents.selectorContent
import webcomponents.twContentScrollBox

fun RenderContext.cardCreate(type: ObjectType) {
    val translation: Translation by koinCtx.inject()

    val routerStore: RouterStore by koinCtx.inject()
    val attendeesSelectorStore: AttendeesSelectorStore by koinCtx.inject()
    val assigneeSelectorStore: AssigneeSelectorStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val scannedCodeStore: ScannedCodeStore by koinCtx.inject()
    val datePickerStore: DatePickerStore by koinCtx.inject()
    val timePickerStore: TimePickerStore by koinCtx.inject()
    val objectType = activeObjectStore.map(GeoObjectDetails.L.objectType)
    val title = activeObjectStore.map(GeoObjectDetails.L.title)
    val startTime = activeObjectStore.map(GeoObjectDetails.L.atTime)
    val endTime = activeObjectStore.map(GeoObjectDetails.L.endTime)
    val preAttachmentsStore: PreAttachmentsStore by koinCtx.inject()
    val scannedCode = scannedCodeStore.map(ScannedCode.extObjId()).data.map { it ?: "" }
    val codeIcon = scannedCodeStore.map(ScannedCode.type()).data.map { it?.getIcon() }
    val titlePlaceholder: Flow<String> = when (type) {
        ObjectType.POI -> translation[TL.CardCreate.POI_PLACEHOLDER]
        ObjectType.Task -> translation[TL.CardCreate.TASK_PLACEHOLDER]
        ObjectType.Event -> translation[TL.CardCreate.MEETING_PLACEHOLDER]
        ObjectType.Zone -> translation[TL.CardCreate.ZONE_PLACEHOLDER]
        ObjectType.ObjectMarker -> translation[TL.CardCreate.TRACKED_OBJECT_PLACEHOLDER]
        else -> flowOf("Name")
    }

    flowOf(type) handledBy objectType.update

    if (startTime.current == null) {
        val nextQuarterHour = Clock.System.now().nextQuarterOfHour()
        datePickerStore.update(nextQuarterHour.formatDateForDatePicker())
        timePickerStore.update(nextQuarterHour.formatTimeForTimePicker())
        activeObjectStore.readFromChangeInputStores()
    }

    baseLayout(
        header = {
            twPageHeaderClose(
                backButtonTitle = TL.General.CANCEL,
                additionalBackButtonHandler = handleFunctions {
                    activeObjectStore.resetStore(Unit)
                    preAttachmentsStore.reset(Unit)
                },
            ) {
                twColOfNoGap {
                    twTitle {
                        translate(TL.CardCreate.CREATE_NEW, mapOf("objectType" to type.getName()))
                    }
                    if (type == ObjectType.ObjectMarker) {
//                        cardSubtitle(scannedCode, codeIcon)
                        codeIcon.render { icon ->
                            twSubtitle(icon = icon) {
                                scannedCode.renderText()
                            }
                        }
                    }
                }
            }
        },
        content = {
            twContentScrollBox("gap-2") {
                // TITLE INPUT
                profileInput(
                    id = "createTitleField",
                    titleAsFlow = titlePlaceholder,
                    icon = type.getIcon(),
                    placeHolder = titlePlaceholder,
                    store = title,
                    autofocus = true,
                )
                // Observer to set autofocus on title input field, when not on mobile
                focusInputObserver({ js("document.getElementById('createTitleField').focus()") }, domNode)

                when (type) {
                    ObjectType.POI, ObjectType.ObjectMarker -> {
                        // CUSTOMIZE BADGE BUTTON
                        selectorButton {
                            selectorContent {
                                icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { filter } }
                                ellipseText { translation[TL.CardCreate.CUSTOMIZE_BADGE].renderText(into = this) }
                            }
                            clicks.map {
                                mapOf("change" to "badge")
                            } handledBy routerStore.addOrReplaceRoute
                        }
                    }

                    ObjectType.Event -> {
                        // DATE/TIME BUTTON
                        selectorButton {
                            selectorContent {
                                icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.Calendar.icon } }
                                ellipseText {
                                    formatEventTimeSpan(
                                        startTime.data,
                                        endTime.data,
                                    ).renderText(into = this)
                                }
                            }
                            clicks.map {
                                mapOf("change" to "datetime")
                            } handledBy routerStore.addOrReplaceRoute
                        }
                        // ATTENDEE BUTTON
                        selectorButton {
                            attendeesSelectorStore.data.render { userList ->
                                selectorContent {
                                    icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.UserAlt.icon } }
                                    ellipseText {
                                        translation[
                                            TL.CardCreate.ATTENDEES,
                                            mapOf(
                                                "numberOfAttendees" to if (userList.isNullOrEmpty()) translation.getString(
                                                    TL.General.NONE,
                                                ) else userList.size,
                                            ),
                                        ].renderText(into = this)
                                    }
                                }
                            }
                            clicks.map {
                                mapOf("change" to "participants")
                            } handledBy routerStore.addOrReplaceRoute
                        }
                    }

                    ObjectType.Task -> {
                        // DUE DATE BUTTON
                        selectorButton {
                            selectorContent {
                                icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.Calendar.icon } }
                                ellipseText {
                                    formatEventTimeSpan(
                                        startTime.data,
                                        flowOf(null),
                                    ).renderText(into = this)
                                }
                            }
                            clicks.map {
                                mapOf("change" to "duedate")
                            } handledBy routerStore.addOrReplaceRoute
                        }
                        // ASSIGNEE BUTTON
                        selectorButton {
                            assigneeSelectorStore.data.render { userList ->
                                selectorContent {
                                    icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.UserAlt.icon } }
                                    ellipseText {
                                        translation[
                                            TL.CardCreate.ASSIGNEE,
                                            mapOf(
                                                "user" to
                                                    if (userList.isNullOrEmpty()) translation.getString(TL.CardUserSelect.ANYONE) else userList.first().firstName?.let { "$it ${userList.first().lastName ?: ""}" }
                                                        ?: userList.first().userId.let {
                                                            if (it == apiUserStore.current.userId) translation.getString(
                                                                TL.General.YOU,
                                                                mapOf("case" to "nominative"),
                                                            ) else it
                                                        },
                                            ),
                                        ].renderText(into = this)
                                    }
                                }
                            }
                            clicks.map {
                                mapOf("change" to "participants")
                            } handledBy routerStore.addOrReplaceRoute
                        }
                    }

                    else -> {
                    }
                }
                // KEYWORD TAG BUTTON
                keywordTagsButton(TagButtonType.CREATE)

                // CREATE BUTTON
                twPrimaryButton(
                    text = TL.General.CREATE,
                    disabledFlow = title.data.map { it.isBlank() },
                ) {
                    className("mt-2")
                    clicks handledBy activeObjectStore.createNew
                }
            }
        },
    )
}
