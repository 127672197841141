package data.objects.views.directediting

import apiclient.FormationClient
import apiclient.geoobjects.Action
import apiclient.geoobjects.AddOpenMapActionCode
import apiclient.geoobjects.Address
import apiclient.geoobjects.Content
import apiclient.geoobjects.ExternalIdChanges
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.MarkerColor
import apiclient.geoobjects.MarkerIcon
import apiclient.geoobjects.ObjectChanges
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.RemoveOpenMapActionCode
import apiclient.geoobjects.applyObjectChanges
import apiclient.geoobjects.distanceTo
import apiclient.geoobjects.extractAddress
import apiclient.geoobjects.pointCoordinates
import apiclient.markers.AIObjectClassification
import apiclient.markers.classifyObject
import apiclient.markers.objectChanges
import apiclient.tags.actionIdMap
import apiclient.tags.externalIds
import apiclient.tags.getUniqueTag
import apiclient.users.UserFeatureFlag
import apiclient.users.toUserProfileSummary
import apiclient.util.isNotNullOrEmpty
import apiclient.validations.parseEnumValue
import apiclient.validations.parseIsoDate
import auth.CurrentWorkspaceStore
import auth.Features
import com.jillesvangurp.geojson.humanReadable
import com.jillesvangurp.geojson.urlEncode
import com.tryformation.localization.Translatable
import data.ObjectAndUserHandler
import data.objects.ActiveObjectStore
import data.users.PublicUserProfileCache
import dev.fritz2.core.RenderContext
import dev.fritz2.core.storeOf
import js.errors.TypeError
import koin.koinCtx
import koin.withKoin
import kotlin.time.Duration.Companion.seconds
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.mapNotNull
import layercache.GeoObjectDetailsCache
import localization.TL
import localization.Translation
import localization.getTranslationFlow
import localization.translate
import map.MapStateStore
import maplibreGL.MaplibreMap
import model.MapState
import objectrouting.NavigationData
import objectrouting.NavigationPointSource
import objectrouting.NavigationStore
import objectrouting.NavigationToolToggleStore
import objectrouting.navigationCoordinateButtonContent
import objectrouting.openExternalNavigationButton
import objectrouting.openObjectNavigationButton
import objectrouting.showRouteToXActionCodeModal
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.asList
import org.w3c.dom.svg.SVGElement
import overlays.BusyStore
import overlays.withBusyApiClient
import qrcodegeneration.toSvgQrCode
import search.simpleListEntry
import search.simpleUserProfileListEntry
import svgmarker.MarkerSize
import svgmarker.SvgIconOptions
import svgmarker.makeSvgMarker
import theme.FormationColors
import theme.FormationIcons
import theme.FormationShapes
import theme.FormationUIIcons
import twcomponents.doContinuouslyWhenElementInDOM
import twcomponents.doIfUserFeatureFlagEnabled
import twcomponents.draggableCardContentWrapperId
import twcomponents.renderIfFeatureFlagEnabled
import twcomponents.twButtonRow
import twcomponents.twCardSectionTitle
import twcomponents.twCenteredLink
import twcomponents.twColOf
import twcomponents.twColOfNoGap
import twcomponents.twContentBoxOf
import twcomponents.twFlatBoxColStart
import twcomponents.twFlatBoxRow
import twcomponents.twFlatBoxRowCenter
import twcomponents.twFlatButton
import twcomponents.twFlatCopyClipboardButton
import twcomponents.twFlatIconBox
import twcomponents.twFlatIconButton
import twcomponents.twFlatIconButtonLight
import twcomponents.twIconMedium
import twcomponents.twIconSmall
import twcomponents.twMarkdownContent
import twcomponents.twPrimaryButton
import twcomponents.twRowOf
import twcomponents.twRowOfJustifyBetween
import twcomponents.twRowOfNoGap
import twcomponents.twRowOfWrap
import twcomponents.twSecondaryButton
import twcomponents.twTagButton
import twcomponents.twTooltip
import utils.ToggleStore
import utils.formatDateTime
import utils.getColorForIcon
import utils.getIcon
import utils.getShape
import utils.humanReadable
import utils.isBrightColor
import web.dom.document
import web.navigator.navigator

const val directEditingCardContentId = "directEditingCardContent"

fun RenderContext.directEditingCardContent(typeSpecificContent: (RenderContext.() -> Unit)? = null) {
    div(
        baseClass = "flex flex-col w-full h-full justify-between overflow-y-auto px-2 pt-3",
        id = directEditingCardContentId,
    ) {

        // Syncing overflow-y state with twPullCardWrapper:
        // While dragging, inherit overflow-y value from contentWrapperDiv, to stop scroll
        doContinuouslyWhenElementInDOM(domNode = domNode) {
            // FIXME  Direct lookup of wrapper div from within twPullCardWrapper component
            val contentWrapperDiv = document.getElementById(draggableCardContentWrapperId) as? HTMLDivElement

            contentWrapperDiv?.let {
                val overflowY = it.style.overflowY
                this.domNode.style.overflowY = overflowY
            }
        }

        // Top Section
        div("flex flex-col w-full") {
            div("-mx-2") { // ignore paddings of parent div for full width content
                openObjectNavigationButton()
                editableImageIcon()
                editableDescription()
            }
            typeSpecificContent?.invoke(this)

            div("-mx-2") { // ignore paddings of parent div for full width content
                showExperimentalAIContentAdder()
                showContent()
            }

            addContentBar()

            commentSection()
        }

        // Bottom Section
        div("flex flex-col w-full") {
            tagsSection()

            div("-mx-2") {
                newObjectDetailsSummary()
            }
        }
    }
}

fun RenderContext.showExperimentalAIContentAdder() {
    div {
        withKoin {
            doIfUserFeatureFlagEnabled(UserFeatureFlag.EnableDevelopmentFeatures) {
                val activeObjectStore = get<ActiveObjectStore>()
                val currentWorkspaceStore = get<CurrentWorkspaceStore>()
                val objectId = activeObjectStore.current.id
                val workspace = currentWorkspaceStore.current ?: error("need a group")

                val objectClassificationStore = storeOf<AIObjectClassification?>(null)
                activeObjectStore.data.render { currentObject ->
                    if (currentObject.attachments.orEmpty().firstOrNull { it is Content.Image } != null) {
                        objectClassificationStore.data.render { classification ->
                            if (classification == null) {
                                twContentBoxOf {
                                    className("p-2")
                                    twCenteredLink {
                                        +"Add content via AI (experimental)" // TODO translate
                                        clicks handledBy {
                                            withBusyApiClient(
                                                { client ->
                                                    client.classifyObject(workspace.groupId, objectId)
                                                },
                                            ) {
                                                objectClassificationStore.update(it)
                                            }
                                        }
                                    }
                                }
                            } else {
                                // FIXME quick and dirty preview, obviously
                                twContentBoxOf {
                                    className("p-2")
                                    twRowOf {
                                        makeSvgMarker(
                                            objectId = "ai-preview-marker",
                                            objectType = currentObject.objectType,
                                            title = classification.title,
                                            svgIconOptions = SvgIconOptions(
                                                icon = parseEnumValue<MarkerIcon>(
                                                    classification.icon ?: "Default",
                                                )?.getIcon()
                                                    ?: currentObject.iconCategory?.getIcon()
                                                    ?: currentObject.objectType.getIcon(),
                                                bgShape = currentObject.shape?.getShape() ?: FormationShapes.Circle,
                                                size = MarkerSize.M,
                                                bgColor = parseEnumValue<MarkerColor>(
                                                    classification.iconColor ?: "Default",
                                                )?.getColorForIcon()
                                                    ?: FormationColors.BlueDeep,
                                                iconColor = if (isBrightColor(
                                                        (parseEnumValue<MarkerColor>(classification.iconColor ?: "Default")?.getColorForIcon()
                                                            ?: FormationColors.White),
                                                    )) {
                                                    FormationColors.BlueDeep
                                                } else FormationColors.White, //formationColor.inverseColor
//                                                borderColor = null,
//                                                hasNotification = hasNotification,
//                                                archived = archived,
//                                                flagged = flagged,
//                                                desaturated = desaturated,
//                                                stateColor = if (flagged) FormationColors.RedError else stateColor,
//                                                stateIcon = if (flagged) FormationIcons.Caution.icon else stateIcon,
//                                                bitmapPicture = bitmapImage,
                                            ),
                                            showTitle = false,
                                        )
                                        p {
                                            b {
                                                +classification.title
                                            }
                                        }
                                    }
                                    if (classification.description.isNotNullOrEmpty()) {
                                        twMarkdownContent(classification.description)
                                    }
                                    if (classification.keywords.isNotEmpty()) {
                                        twRowOfWrap {
                                            classification.keywords.map {
                                                twTagButton(it, FormationIcons.Tag) {
                                                }
                                            }
                                        }
                                    }
                                    twButtonRow {
                                        twSecondaryButton {
                                            +"Cancel"
                                            clicks handledBy {
                                                objectClassificationStore.update(null)
                                            }
                                        }
                                        twPrimaryButton {
                                            +"Apply Changes" // TODO translate

                                            clicks handledBy {
                                                withBusyApiClient(
                                                    { client ->
                                                        client.applyObjectChanges(
                                                            ObjectChanges(
                                                                objectId,
                                                                classification.objectChanges,
                                                            ),
                                                        )
                                                    },
                                                ) {
                                                    activeObjectStore.updateActiveObject(it.first())
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun Address.formatMarkdown(): String {
    val builder = StringBuilder()

    name?.let {
        builder.appendLine(name).appendLine()
    }
    street?.let { street ->
        builder.append(street)
        houseNumber?.let { number ->
            builder.append(" ").append(number)
        }
        builder.appendLine()
    }
    postalCode?.let { builder.appendLine(it) }
    neighborhood?.let { builder.appendLine(it) }
    borrow?.let { builder.appendLine(it) }
    city?.let { builder.appendLine(it) }
    state?.let { builder.appendLine(it) }
    countryCode?.let { builder.appendLine(it) }

    return builder.toString()
}

fun RenderContext.newObjectDetailsSummary() {
    withKoin {
        val activeObjectStore: ActiveObjectStore = get()
        div {
            activeObjectStore.data.render { obj ->
                div("flex flex-col w-full justify-start gap-2 py-2 sm:py-5") {
                    objectRouteToXLinkedRoutesWithActionCodes(obj)
                    shareButton(obj)
                    objectQRCode(obj)
                    // FIXME @jacob, let's do ui for setting/releasing qr code here and get rid of the menu options.
                    scanToMapOrMarkerSwitch(obj)
                    addressDetails(obj)
                    position(obj)
                    creationMetadata(obj)
                    ownerMetadataWithOwnerTransfer(obj)
                }
            }
        }
    }
}

private fun RenderContext.position(obj: GeoObjectDetails) {
    withKoin {
        val mapStateStore = get<MapStateStore>()
        val maplibreMap = get<MaplibreMap>()
        val position = obj.latLon
        twColOf {
            twRowOfNoGap {
                mapStateStore.data.render { mapState: MapState? ->
                    if (position.distanceTo(mapState?.center ?: position) > 1) {
                        twFlatIconButton(FormationIcons.Position) {
                            clicks handledBy {
                                maplibreMap.panTo(position, 1.seconds.inWholeMilliseconds)
                            }
                        }
                    } else {
                        twFlatIconBox {
                            twIconMedium(FormationIcons.Location)
                        }
                    }
                }
                twFlatBoxRowCenter {
                    p("text-xs") {
                        +position.pointCoordinates().humanReadable()
                    }
                }
                twFlatCopyClipboardButton("${position.lat}, ${position.lon}")
            }
            openExternalNavigationButton()
        }
    }
}

private fun RenderContext.shareButton(obj: GeoObjectDetails) {
    withKoin {
        val translation = get<Translation>()
        val currentWorkspaceStore = get<CurrentWorkspaceStore>()
        currentWorkspaceStore.data.render { workspace ->
            val id = obj.tags.externalIds.firstOrNull() ?: obj.id
            val link = "https://app.tryformation.com/#id=${id.urlEncode()}&ws=${workspace?.name.orEmpty()}"

            twRowOfNoGap {
                twFlatIconBox {
                    twIconMedium(FormationUIIcons.Link)
                }
                twFlatBoxRowCenter {
                    p("text-xs break-all") {
                        +link
                    }
                }
                twFlatCopyClipboardButton(link)
            }

            try {
                val shareData = utils.obj {
                    title = obj.title
                    url = link
                    text = translation.getString(translatable = ContentTexts.SHARE_ON_FORMATION)
                }
                if (navigator.canShare(shareData)) {
                    twFlatButton {
                        className("p-2")
                        translation[ContentTexts.SHARE_LINK].renderText()
                        clicks handledBy {
                            navigator.share(shareData)
                        }
                    }
                }
            } catch (e: TypeError) {
                // Firefox does not support the share API on Desktop
            }
        }
    }
}

private fun RenderContext.addressDetails(obj: GeoObjectDetails) {
    obj.tags.extractAddress()?.let { address ->
        twFlatBoxRowCenter {
            className("text-xs")
            twMarkdownContent(address.formatMarkdown())
        }
    }
}

private fun RenderContext.creationMetadata(
    obj: GeoObjectDetails
) {
    withKoin {
        val activeObjectStore: ActiveObjectStore = get()
        val publicUserProfileCache = get<PublicUserProfileCache>()
        twFlatBoxColStart {
            className("p-2")
            publicUserProfileCache.getProfile(activeObjectStore.data) { obj ->
                obj.createdBy
            }.render { createdBy ->
                val createdAt = obj.createdAt.parseIsoDate()
                p("text-xs") {
                    translate(
                        TL.CardInfo.CREATED_BY,
                        "user" to createdBy?.name.orEmpty(),
                        "time" to createdAt.formatDateTime(),
                    )
                }
            }
            publicUserProfileCache.getProfile(activeObjectStore.data) {
                it.updatedBy
            }.render { updatedBy ->
                val updatedAt = obj.updatedAt.parseIsoDate()
                p("text-xs") {
                    translate(
                        TL.CardInfo.UPDATED_BY,
                        "user" to updatedBy?.name.orEmpty(),
                        "time" to updatedAt.formatDateTime(),
                    )
                }
            }
        }
    }
}

private fun RenderContext.ownerMetadataWithOwnerTransfer(
    obj: GeoObjectDetails
) {
    withKoin {
        val activeObjectStore: ActiveObjectStore = get()
        val objectAndUserHandler: ObjectAndUserHandler = get()
        val publicUserProfileCache = get<PublicUserProfileCache>()
        val showOwnerEditorStore = ToggleStore(false)

        if (obj.manageable) {
            twOwnershipTransferModal(showOwnerEditorStore)
        }

        twFlatBoxRow {
            publicUserProfileCache.getProfile(activeObjectStore.data) {
                it.ownerId
            }.render { owner ->
                if (owner != null) {
                    twFlatIconBox {
                        p("text-xs whitespace-nowrap") {
                            translate(
                                TL.CardInfo.OWNED_BY,
                            )
                        }
                    }
                    twFlatButton {
                        className("-my-1")
                        simpleUserProfileListEntry(owner.toUserProfileSummary())
                        clicks handledBy {
                            objectAndUserHandler.updateAndLocateActiveUser(owner)
                        }
                    }

                }
            }
            if (obj.manageable) {
                showOwnerEditorStore.data.render { showOwnerEditor ->
                    twFlatIconButton(
                        icon = if (showOwnerEditor) FormationUIIcons.Close else FormationIcons.PersonChange,
                    ) {
                        clicks handledBy showOwnerEditorStore.toggle
                    }.twTooltip(fallback = "Transfer Ownership") { // TODO translate
                        +"Transfer Ownership"
                    }
                }
            }
        }
    }
}

private fun RenderContext.objectQRCode(
    obj: GeoObjectDetails
) {
    val openMapActionId = obj.tags.actionIdMap.firstNotNullOfOrNull { (id, action) ->
        if (action is Action.OpenMap) {
            id
        } else {
            null
        }
    }
    val openMarkerId = obj.tags.getUniqueTag(ObjectTags.ExternalId)
    val externalId = openMapActionId ?: openMarkerId
    if (!externalId.isNullOrBlank()) {
        twFlatBoxRowCenter {
            flowOf(externalId).mapNotNull { it } handledBy { externalId ->
                val svgContent = toSvgQrCode("https://app.tryformation.com/#id=${externalId.urlEncode()}")
                div("flex flex-col h-full w-[50%]") {
                    div("flex max-h-max w-full object-scale-down") {
                        domNode.innerHTML = svgContent
                    }.also {
                        this.domNode.children.asList().firstOrNull { it is SVGElement }.also { svg ->
                            svg?.setAttribute("height", "100%")
                            svg?.setAttribute("width", "100%")
                        }
                    }
                }
                p("text-xs font-mono text-center text-wrap break-all h-full w-[50%] p-2") {
                    +externalId
                }
            }
        }
    }
}

private fun RenderContext.objectRouteToXLinkedRoutesWithActionCodes(
    obj: GeoObjectDetails
) {
    renderIfFeatureFlagEnabled(Features.EnableRouteToObject) {
        withKoin {
            val navigationToolToggleStore: NavigationToolToggleStore = koinCtx.get()
            val navigationStore: NavigationStore = koinCtx.get()
            val busyStore: BusyStore = koinCtx.get()
            val formationClient: FormationClient = koinCtx.get()

            val openRouteToXActions = obj.tags.actionIdMap.filter { entry ->
                entry.value is Action.OpenRouteToX
            }

            if (openRouteToXActions.isNotEmpty()) {
                twRowOfJustifyBetween {
                    className("px-2")
                    // Routes header
                    twCardSectionTitle {
                        twIconSmall(FormationUIIcons.NavigationArrow)
                        translate(TL.Navigation.LINKED_ROUTES)
                    }
                }
                twColOfNoGap {
                    className("pb-2")
                    openRouteToXActions.forEach { (actionId, action) ->
                        val fetchedObjectStore = storeOf<GeoObjectDetails?>(null, Job())
                        // lookup Destination obj
                        if (action is Action.OpenRouteToX) {
                            action.destinationObjectId?.let { objId ->
                                flowOf(objId).handledBy { geoObjectId ->
                                    val geoObjectDetailsCache: GeoObjectDetailsCache by koinCtx.inject()
                                    busyStore.handleApiCall(
                                        supplier = suspend {
                                            geoObjectDetailsCache.fetchResult(id = geoObjectId, formationClient, skipCache = true)
                                        },
                                        processResult = { (geoObjectDetails, isCachedResult) ->
                                            fetchedObjectStore.update(geoObjectDetails)
                                        },
                                        processError = { error ->
                                            console.warn("Error, object not found: $geoObjectId", error.message)
                                        },
                                    )
                                }
                            }

                            // modal that shows qr code and allows to remove action code
                            val showQRCodeToggleStore = storeOf(false)
                            showRouteToXActionCodeModal(
                                title = TL.ActionCode.LINKED_ACTION_CODE.getTranslationFlow(),
                                toggleShowModalStore = showQRCodeToggleStore,
                                actionCodeFlow = flowOf(actionId),
                                geoObject = obj,
                                objectUpdateHandlers = listOf(),
                            )

                            twRowOfNoGap {
                                // Button to open Navigation Tool
                                div("flex flex-row w-full items-center justify-between cursor-pointer hover:bg-gray-100") {
                                    fetchedObjectStore.data.render { destinationObj ->
                                        destinationObj?.let {
                                            simpleListEntry(destinationObj)
                                        } ?: action.latLon?.let { latLon ->
                                            navigationCoordinateButtonContent(
                                                title = TL.Navigation.GPS_COORDINATES.getTranslationFlow(),
                                                icon = FormationIcons.Globe,
                                                subtitle = latLon.humanReadable(),
                                                latLon = latLon,
                                            )
                                        }

                                        clicks handledBy {
                                            destinationObj?.let { destinationObj ->
                                                navigationToolToggleStore.update(true)
                                                navigationStore.update(
                                                    NavigationData(
                                                        originPointSource = NavigationPointSource.OtherObject,
                                                        originObject = obj,
                                                        originLatLon = obj.latLon,
                                                        destinationPointSource = NavigationPointSource.OtherObject,
                                                        destinationObject = destinationObj,
                                                        destinationLatLon = destinationObj.latLon,
                                                    ),
                                                )
                                            } ?: action.latLon?.let { latLon ->
                                                navigationToolToggleStore.update(true)
                                                navigationStore.update(
                                                    NavigationData(
                                                        originPointSource = NavigationPointSource.OtherObject,
                                                        originObject = obj,
                                                        originLatLon = obj.latLon,
                                                        destinationPointSource = NavigationPointSource.GpsCoordinates,
                                                        destinationObject = null,
                                                        destinationLatLon = latLon,
                                                    ),
                                                )
                                            } ?: console.log("OpenRouteToX Action failed, no destination object or latLon found")
                                        }
                                    }
                                }
                                // Button to show Action Code Modal
                                twFlatIconButtonLight(FormationIcons.QRCode) {
                                    clicks handledBy {
                                        showQRCodeToggleStore.update(true)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

enum class OpenMapOrMarker : Translatable {
    OPEN_MAP,
    OPEN_MARKER
    ;

    override val prefix = "open-map-or-marker"

}

private fun RenderContext.scanToMapOrMarkerSwitch(
    obj: GeoObjectDetails
) {
    val openMapActionId = obj.tags.actionIdMap.firstNotNullOfOrNull { (id, action) ->
        if (action is Action.OpenMap) {
            id
        } else {
            null
        }
    }
    val openMarkerId = obj.tags.getUniqueTag(ObjectTags.ExternalId)
    val externalId = openMapActionId ?: openMarkerId
    if (!externalId.isNullOrBlank()) {
        div {
            twFlatButton {
                className("p-2")
                if (externalId == openMarkerId) {
                    translate(OpenMapOrMarker.OPEN_MAP)
                } else {
                    translate(OpenMapOrMarker.OPEN_MARKER)
                }
//                +(if (externalId == openMarkerId) "Use to open Map" else "Use to open Marker")
                clicks handledBy {
                    withBusyApiClient(
                        { client ->
                            val changes = if (externalId == openMarkerId) {
                                listOf(
                                    ExternalIdChanges.RemoveExternalId(externalId),
                                    AddOpenMapActionCode(externalId),
                                )
                            } else {
                                listOf(
                                    ExternalIdChanges.AssociateExternalId(externalId),
                                    RemoveOpenMapActionCode(externalId),
                                )
                            }
                            client.applyObjectChanges(
                                ObjectChanges(
                                    obj.id,
                                    changes,
                                ),
                            )
                        },
                    ) { resultList ->
                        withKoin {
                            val activeObjectStore = get<ActiveObjectStore>()
                            resultList.firstOrNull()?.let {
                                activeObjectStore.updateActiveObject(it)
                            }
                        }
                    }
                }
            }
        }
    }
}


