package maplibreGL.renderer

import data.ObjectAndUserHandler
import data.connectableshapes.DeleteConnectionConnectorIdStore
import data.connectableshapes.NewConnectableShapeConnectionStore
import data.objects.ActiveObjectStore
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlin.js.json
import maplibreGL.MaplibreMap
import org.w3c.dom.events.Event
import search.searchlayer.MapSearchResultsStore
import utils.makeRGBA

const val ADDITIONAL_GEOMETRY = "additionalGeometry"

class GeoJsonGeometryRender {
    val maplibreMap: MaplibreMap by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()

    val map get() = maplibreMap.map

    private val additionalSourceDefinition = json(
        "type" to "geojson",
        "maxzoom" to 24,
        "lineMetrics" to true,
        "cluster" to false,
        "promoteId" to "id",
        "data" to json(
            "type" to "FeatureCollection",
            "features" to arrayOf<dynamic>(),
        ),
    )

    enum class GeoJsonGeometryLayer(val layerId: String) {
        ZoneFills(
            layerId = "$ADDITIONAL_GEOMETRY-fill",
        ),
        ZoneOutlines(
            layerId = "$ADDITIONAL_GEOMETRY-line",
        ),
        ZoneOutlinesDashed(
            layerId = "$ADDITIONAL_GEOMETRY-line-dashed",
        ),
        ConnectableShapeExclusionZones(
            layerId = "$ADDITIONAL_GEOMETRY-connectable-exclusion-zones",
        ),
        ConnectableShapes(
            layerId = "$ADDITIONAL_GEOMETRY-connectable-shapes",
        ),
        ConnectableConnectorPoints(
            layerId = "$ADDITIONAL_GEOMETRY-connectable-connector-points",
        ),
        ConnectableConnectionLines(
            layerId = "$ADDITIONAL_GEOMETRY-connectable-connection-lines",
        ),
        ConnectableConnectionDistances(
            layerId = "$ADDITIONAL_GEOMETRY-connectable-connection-distances",
        ),
        HistoryPathLineGradientGreenToRed(
            layerId = "$ADDITIONAL_GEOMETRY-history-path-line-gradient-green-to-red",
        )

    }

    fun addAllGeoJsonGeometryLayers() {
        addZoneGeoJsonLayers()
        addConnectableShapeGeoJsonLayers()
        addHistoryPathGeoJsonLayer()
    }

    /**
     * Zone geometry (shape, outline, dashedoutline)
     */

    private fun addZoneGeoJsonLayers() {

        val zoneGeometryLayerDefinitions = listOf(
            json(
                "id" to GeoJsonGeometryLayer.ZoneFills.layerId,
                "type" to "fill",
                "source" to ADDITIONAL_GEOMETRY,
                "filter" to arrayOf("==", arrayOf("get", "geoJSON_type"), "normal"),
                "layout" to json(),
                "paint" to json(
                    "fill-antialias" to true,
                    "fill-color" to arrayOf("get", "fill_color"),
                    "fill-opacity" to arrayOf("get", "fill_opacity"),
                ),
            ),

            json(
                "id" to GeoJsonGeometryLayer.ZoneOutlines.layerId,
                "type" to "line",
                "source" to ADDITIONAL_GEOMETRY,
                "filter" to arrayOf("==", arrayOf("get", "line_type"), "normal"),
                "layout" to json(),
                "paint" to json(
                    "line-color" to arrayOf("get", "line_color"),
                    "line-width" to arrayOf("get", "line_width"),
                    "line-opacity" to arrayOf("get", "line_opacity"),
                ),
            ),

            json(
                "id" to GeoJsonGeometryLayer.ZoneOutlinesDashed.layerId,
                "type" to "line",
                "source" to ADDITIONAL_GEOMETRY,
                "filter" to arrayOf("==", arrayOf("get", "line_type"), "dashed"),
                "layout" to json(),
                "paint" to json(
                    "line-color" to arrayOf("get", "line_color"),
                    "line-width" to arrayOf("get", "line_width"),
                    "line-opacity" to arrayOf("get", "line_opacity"),
                    "line-dasharray" to arrayOf(5, 10),
                ),
            ),
        )

        maplibreMap.ensureGeoJSONSourceAndAddGeoJSONLayers(
            sourceId = ADDITIONAL_GEOMETRY,
            sourceDefinition = additionalSourceDefinition,
            layerDefinitionsMap = zoneGeometryLayerDefinitions.associateWith { null },
        )
    }

    /**
     * Connectable shapes geometry (shape, lines, connectors)
     */

    var mouseOverConnector: String? = null
    var mouseOverConnection: String? = null

    private fun highlightConnector(id: String?) {
        mouseOverConnector = id
        maplibreMap.syncMarkersNow()
    }

    private fun highlightConnection(uniqueConnectionId: String?) {
        mouseOverConnection = uniqueConnectionId
        maplibreMap.syncMarkersNow()
    }

    private fun selectConnector(id: String?) {
        val newConnectableShapeConnectionStore: NewConnectableShapeConnectionStore by koinCtx.inject()
        val objectAndUserHandler: ObjectAndUserHandler by koinCtx.inject()

        id?.let { connectorId ->
            newConnectableShapeConnectionStore.current.let { connection ->
                when {
                    connection.sourceConnectorId.isEmpty() -> {
                        objectAndUserHandler.fetchAndShowObject(connectorId.split(":")[0])
                        newConnectableShapeConnectionStore.updateSourceConnectorById(connectorId)
                    }

                    connection.sourceConnectorId == connectorId -> {
                        newConnectableShapeConnectionStore.reset()
                    }

                    connection.sourceConnectorId.isNotEmpty() && connection.targetConnectorId.isEmpty() -> {
                        newConnectableShapeConnectionStore.handleConnectorById(connectorId)
                    }

                    connection.sourceConnectorId.isNotEmpty() && connection.targetConnectorId == connectorId -> {
                        newConnectableShapeConnectionStore.resetTarget()
                    }

                    connection.sourceConnectorId.isNotEmpty() && connection.targetConnectorId.isNotEmpty() -> {
                        newConnectableShapeConnectionStore.handleConnectorById(connectorId)
                    }

                    else -> {
                        newConnectableShapeConnectionStore.reset()
                    }
                }
            }
        }
    }

    private fun addConnectorClickListeners() {

        val mapSearchResultsStore: MapSearchResultsStore by koinCtx.inject()

        map?.on("mouseenter", GeoJsonGeometryLayer.ConnectableConnectorPoints.layerId) { connector ->
            val connectorId = connector.features[0].properties.id as? String
            val isAlreadyConnected = mapSearchResultsStore.current.values.mapNotNull { geoObj ->
                geoObj.geoReferencedConnectableObject?.connections?.map { i -> "${i.sourceMarkerId}:${i.sourceConnectorId}" }
            }.flatten().contains(connectorId)
            if (!isAlreadyConnected) {
                highlightConnector(connectorId)
                map?.getCanvas().style.cursor = "pointer"
            }
        }
        map?.on("mouseleave", GeoJsonGeometryLayer.ConnectableConnectorPoints.layerId) {
            highlightConnector(null)
            map?.getCanvas().style.cursor = ""
        }
        map?.on("click", GeoJsonGeometryLayer.ConnectableConnectorPoints.layerId) { connector ->
            val connectorId = connector.features[0].properties.id as? String
            val isAlreadyConnected = mapSearchResultsStore.current.values.mapNotNull { geoObj ->
                geoObj.geoReferencedConnectableObject?.connections?.map { i -> "${i.sourceMarkerId}:${i.sourceConnectorId}" }
            }.flatten().contains(connectorId)
            if (!isAlreadyConnected) {
                selectConnector(connectorId)
            }
        }
    }

    private fun selectConnection(id: String?) {
        val deleteConnectionConnectorIdStore: DeleteConnectionConnectorIdStore by koinCtx.inject()

        id?.let { uniqueConnectionId ->
            deleteConnectionConnectorIdStore.selectConnectionByUniqueId(uniqueConnectionId)
        }
    }

    private fun addConnectionClickListeners() {

        map?.on("mouseenter", GeoJsonGeometryLayer.ConnectableConnectionLines.layerId) { connection ->
            highlightConnection(connection.features[0].properties.id as? String)
            map?.getCanvas().style.cursor = "pointer"
        }
        map?.on("mouseleave", GeoJsonGeometryLayer.ConnectableConnectionLines.layerId) {
            highlightConnection(null)
            map?.getCanvas().style.cursor = ""
        }
        map?.on("click", GeoJsonGeometryLayer.ConnectableConnectionLines.layerId) { connection ->
            (connection as? Event)?.preventDefault()
            (connection as? Event)?.stopImmediatePropagation()
            (connection as? Event)?.stopPropagation()
            selectConnection(connection.features[0].properties.id as? String)
        }
    }

    private fun addConnectableShapeGeoJsonLayers() {

        val connectableGeometryLayerDefinitions = listOf(
            json(
                "id" to GeoJsonGeometryLayer.ConnectableShapeExclusionZones.layerId,
                "type" to "fill",
                "source" to ADDITIONAL_GEOMETRY,
                "filter" to arrayOf("==", arrayOf("get", "geoJSON_type"), "connectable-exclusion-zone"),
                "layout" to json(),
                "paint" to json(
                    "fill-antialias" to true,
                    "fill-color" to arrayOf("get", "fill_color"),
                    "fill-opacity" to arrayOf("get", "fill_opacity"),
                ),
            ),
            json(
                "id" to GeoJsonGeometryLayer.ConnectableShapes.layerId,
                "type" to "fill",
                "source" to ADDITIONAL_GEOMETRY,
                "filter" to arrayOf("==", arrayOf("get", "geoJSON_type"), "connectable-shape"),
                "layout" to json(),
                "paint" to json(
                    "fill-antialias" to true,
                    "fill-color" to arrayOf("get", "fill_color"),
                    "fill-opacity" to arrayOf("get", "fill_opacity"),
                ),
            ),
            json(
                "id" to GeoJsonGeometryLayer.ConnectableConnectionDistances.layerId,
                "type" to "symbol",
                "source" to ADDITIONAL_GEOMETRY,
                "filter" to arrayOf("==", arrayOf("get", "geoJSON_type"), "connection"),
                "layout" to json(
                    "symbol-placement" to "line-center",
                    "text-field" to arrayOf("get", "title"),
//                        "text-font" to arrayOf("Open Sans Regular"),
                    "text-size" to 20,
                    "text-justify" to "auto",
                    "text-allow-overlap" to false,
                    "text-rotation-alignment" to "viewport",
                    "text-offset" to arrayOf(0, 0.5),
                ),
            ),
        )

        maplibreMap.ensureGeoJSONSourceAndAddGeoJSONLayers(
            sourceId = ADDITIONAL_GEOMETRY,
            sourceDefinition = additionalSourceDefinition,
            layerDefinitionsMap = connectableGeometryLayerDefinitions.associateWith { null },
        )

        // Add ConnectorPointsLayer separately to add click listeners
        val connectableConnectorPointsLayerDefinition = listOf(
            json(
                "id" to GeoJsonGeometryLayer.ConnectableConnectorPoints.layerId,
                "type" to "circle",
                "source" to ADDITIONAL_GEOMETRY,
                "filter" to arrayOf("==", arrayOf("get", "geoJSON_type"), "connector"),
                "layout" to json(),
                "paint" to json(
                    "circle-color" to arrayOf("get", "fill_color"),
                    "circle-opacity" to arrayOf("get", "fill_opacity"),
                    "circle-radius" to arrayOf("get", "circle_radius"),
                    "circle-stroke-color" to arrayOf("get", "line_color"),
                    "circle-stroke-width" to arrayOf("get", "line_width"),
                    "circle-stroke-opacity" to arrayOf("get", "line_opacity"),
                ),
            ),
        )

        maplibreMap.ensureGeoJSONSourceAndAddGeoJSONLayers(
            sourceId = ADDITIONAL_GEOMETRY,
            sourceDefinition = additionalSourceDefinition,
            layerDefinitionsMap = connectableConnectorPointsLayerDefinition.associateWith { null },
            blockDoAfterLayerAddedInitially = {
                addConnectorClickListeners()
            },
        )

        // Add ConnectionLinesLayer separately to add click listeners
        val connectableConnectionLinesLayerDefinition = listOf(
            json(
                "id" to GeoJsonGeometryLayer.ConnectableConnectionLines.layerId,
                "type" to "line",
                "source" to ADDITIONAL_GEOMETRY,
                "filter" to arrayOf("==", arrayOf("get", "geoJSON_type"), "connection"),
                "layout" to json(
                    "line-join" to "round",
                    "line-cap" to "round",
                ),
                "paint" to json(
                    "line-color" to arrayOf("get", "line_color"),
                    "line-width" to arrayOf("get", "line_width"),
                    "line-opacity" to arrayOf("get", "line_opacity"),
                ),
            ),
        )

        maplibreMap.ensureGeoJSONSourceAndAddGeoJSONLayers(
            sourceId = ADDITIONAL_GEOMETRY,
            sourceDefinition = additionalSourceDefinition,
            layerDefinitionsMap = connectableConnectionLinesLayerDefinition.associateWith { null },
            blockDoAfterLayerAddedInitially = {
                addConnectionClickListeners()
            },
        )
    }

    /**
     * History path geometry (gradient line)
     */

    private fun addHistoryPathGeoJsonLayer() {

        val historyPathGeometryLayerDefinitions = listOf(
            json(
                "id" to GeoJsonGeometryLayer.HistoryPathLineGradientGreenToRed.layerId,
                "type" to "line",
                "source" to ADDITIONAL_GEOMETRY,
                "filter" to arrayOf("==", arrayOf("get", "line_type"), "gradient_green_red"),
                "layout" to json(
                    "line-join" to "round",
                ),
                "paint" to json(
//                        "line-color" to arrayOf("get", "line_color"),
                    "line-width" to arrayOf("get", "line_width"),
                    "line-opacity" to arrayOf("get", "line_opacity"),
                    "line-gradient" to arrayOf(
                        "interpolate",
                        arrayOf("linear"),
                        arrayOf("line-progress"),
                        0,
                        makeRGBA("#00FF00", 1.0),
                        0.1,
                        makeRGBA("#95FF66", 0.9),
                        0.3,
                        makeRGBA("#C8FFB3", 0.7),
                        0.5,
                        makeRGBA("#FFC2A6", 0.6),
                        0.7,
                        makeRGBA("#FF6B5E", 0.5),
                        1.0,
                        makeRGBA("#FF0000", 0.3),
                    ),
                ),
            ),
        )

        maplibreMap.ensureGeoJSONSourceAndAddGeoJSONLayers(
            sourceId = ADDITIONAL_GEOMETRY,
            sourceDefinition = additionalSourceDefinition,
            layerDefinitionsMap = historyPathGeometryLayerDefinitions.associateWith { null },
        )
    }
}
