package map.views

import apiclient.validations.parseEnumValue
import com.tryformation.localization.Translatable
import data.users.settings.LocalSettingsStore
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.img
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.src
import dev.fritz2.core.title
import dev.fritz2.styling.params.ScaledValueProperty
import dev.fritz2.styling.params.SizesProperty
import generated.OFFLINE_MODE
import generated.ServerConfig
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import localization.Translation
import maplibreGL.MaplibreMap
import protomaps.themes.base.labels
import protomaps.themes.base.noLabels
import theme.FormationColors
import utils.obj

class MapStyleSelectionStore(localSettingsStore: LocalSettingsStore) : RootStore<MapStyle>(
    initialData = if (!OFFLINE_MODE) {
        parseEnumValue<MapStyleOnline>(localSettingsStore.current.mapStyle)
            ?: MapStyleOnline.MapTilerBasic
    } else {
        parseEnumValue<MapStyleOffline>(localSettingsStore.current.mapStyle)
            ?: MapStyleOffline.ProtoMapsLight
    },
    job = Job(),
) {
    private val maplibreMap: MaplibreMap = koinCtx.get()

    val selectMapStyle = handle<MapStyle> { old, selected ->
        val newValue = when (selected) {
            is MapStyleOnline -> {
                maplibreMap.changeMapStyle(selected.url)
                selected
            }

            is MapStyleOffline -> {
                maplibreMap.changeMapStyleDynamic(selected.styleSpecification)
                selected
            }

            else -> {
                console.error("unable to change map style due to missing URL and StyleSpecification")
                old
            }
        }
        update(newValue)
        newValue
    }

    init {
        data.map { it.name } handledBy localSettingsStore.updateMapStyle
    }
}

enum class Feature { Mobile, Desktop, Map3D }

enum class ProtomapsTheme : Translatable {
    Light,
    Dark,
    White,
    Black,
    Grayscale,
//    Debug,
    ;

    override val prefix: String = "protomaps-theme"
}

fun buildStyleDefProtomaps(
//    glyphs: String = "https://protomaps.github.io/basemaps-assets/fonts/{fontstack}/{range}.pbf",
    glyphs: String = "basemaps-assets/fonts/{fontstack}/{range}.pbf",
    theme: ProtomapsTheme,
    url: String = PMTILES_URL,
) = obj {
    this.version = 8
    this.glyphs = glyphs
    this.sources = obj {
        protomaps = obj {
            this.type = "vector"
            this.url = url
            this.attribution = "<a href=\"https://github.com/protomaps/basemaps\">Protomaps</a> © <a href=\"https://openstreetmap.org\">OpenStreetMap</a>"
        }
    }
    this.layers = run {
//        console.warn("building ${theme.name}")
        val labels = labels(
            "protomaps",
            theme.name.lowercase(),
        )
        val noLabels = noLabels(
            "protomaps",
            theme.name.lowercase(),
        )
        noLabels.concat(labels)
    }
}

interface MapStyle : Translatable {
    val previewImageUrl: String
    val features: List<Feature>
}

const val PMTILES_URL = "pmtiles://tiles/map.pmtiles" // ""https://build.protomaps.com/20240229.pmtiles"
//""https://protomaps.github.io/PMTiles/protomaps(vector)ODbL_firenze.pmtiles"

@Suppress("unused")
enum class MapStyleOnline(
    val url: String,
    override val previewImageUrl: String,
    override val features: List<Feature> = listOf(),
    val forOfflineUse: Boolean = false,
) : MapStyle {
    MapTilerStreets3D(
        url = "https://api.maptiler.com/maps/streets-v2/style.json?key=${ServerConfig.mapTilerKey}",
        previewImageUrl = "/assets/images/map-style-prev/streets-3d.gif",
        features = listOf(Feature.Map3D),
    ),
    MapTilerBasic(
        url = "https://api.maptiler.com/maps/basic-v2/style.json?key=${ServerConfig.mapTilerKey}",
        previewImageUrl = "/assets/images/map-style-prev/basic.gif",
    ),
    MapTilerSatellite(
        url = "https://api.maptiler.com/maps/hybrid/style.json?key=${ServerConfig.mapTilerKey}",
        previewImageUrl = "assets/images/map-style-prev/satellite-hybrid.gif",
    ),
    MapTilerBright(
        url = "https://api.maptiler.com/maps/bright-v2/style.json?key=${ServerConfig.mapTilerKey}",
        previewImageUrl = "/assets/images/map-style-prev/bright.gif",
    ),
    MapTilerTopo(
        url = "https://api.maptiler.com/maps/topo-v2/style.json?key=${ServerConfig.mapTilerKey}",
        previewImageUrl = "/assets/images/map-style-prev/topo.gif",
    ),
    MapTilerTopographique(
        url = "https://api.maptiler.com/maps/topographique/style.json?key=${ServerConfig.mapTilerKey}",
        previewImageUrl = "/assets/images/map-style-prev/topographique.gif",
    ),
    MapTilerOutdoor(
        url = "https://api.maptiler.com/maps/outdoor-v2/style.json?key=${ServerConfig.mapTilerKey}",
        previewImageUrl = "/assets/images/map-style-prev/outdoor.gif",
    ),
    MapTilerOsmStandard(
        url = "https://api.maptiler.com/maps/openstreetmap/style.json?key=${ServerConfig.mapTilerKey}",
        previewImageUrl = "/assets/images/map-style-prev/open-street-map.gif",
    ),
    MapTilerWinter(
        url = "https://api.maptiler.com/maps/winter-v2/style.json?key=${ServerConfig.mapTilerKey}",
        previewImageUrl = "/assets/images/map-style-prev/winter.gif",
    ),
    ;

    override val prefix = "mapstyles"
}

@Suppress("unused")
enum class MapStyleOffline(
    private val spec: dynamic,
    override val previewImageUrl: String,
    override val features: List<Feature> = listOf(),
    val forOfflineUse: Boolean = false,
) : MapStyle {
    ProtoMapsLight(
        spec = buildStyleDefProtomaps(
            theme = ProtomapsTheme.Light,
        ),
        previewImageUrl = "/assets/images/map-style-prev/basic.gif",
        forOfflineUse = true,
    ),
    ProtoMapsDark(
        spec = buildStyleDefProtomaps(
            theme = ProtomapsTheme.Dark,
        ),
        previewImageUrl = "/assets/images/map-style-prev/basic.gif",
        forOfflineUse = true,
    ),
    ProtoMapsBlack(
        spec = buildStyleDefProtomaps(
            theme = ProtomapsTheme.Black,
        ),
        previewImageUrl = "/assets/images/map-style-prev/basic.gif",
        forOfflineUse = true,
    ),
    ProtoMapsGrayscale(
        spec = buildStyleDefProtomaps(
            theme = ProtomapsTheme.Grayscale,
        ),
        previewImageUrl = "/assets/images/map-style-prev/basic.gif",
        forOfflineUse = true,
    ),
//    ProtoMapsDebug(
//        styleSpecification = buildStyleDefProtomaps(
//            theme = ProtomapsTheme.Debug
//        ),
//        previewImageUrl = "/assets/images/map-style-prev/winter.gif",
//        forOfflineUse = true,
//    ),
    ;

    override val prefix = "mapstyles-offline"

    val styleSpecification: dynamic
        get() = spec
}


fun RenderContext.mapStyleGridRow(
    items: List<MapStyle>,
    columns: Int,
    itemSize: SizesProperty,
    deviceIconSize: SizesProperty,
    fontSize: ScaledValueProperty,
) {

    val mapStyleSelectionStore: MapStyleSelectionStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()

    lineUp(
        {
            width { full }
            justifyContent { spaceEvenly }
            alignItems { center }
        },
    ) {
        spacing { smaller }
        items {
            items.forEach { gridElement ->
                mapStyleSelectionStore.data.render { selectedStyle ->
                    flexBox(
                        {
                            direction { column }
                            justifyContent { center }
                            alignItems { center }
                        },
                    ) {
                        button(
                            {
                                width(itemSize)
                                height(itemSize)
                                radius { large }
                                hover {
                                    border {
                                        color { FormationColors.MarkerYou.color }
                                        width { "2px" }
                                    }
                                }
                                if (gridElement == selectedStyle) {
                                    border {
                                        width { "2px" }
                                        color { FormationColors.Black.color }
                                    }
                                } else {
                                    border {
                                        width { "2px" }
                                        color { secondary.main }
                                    }
                                }
                                position { relative { } }
                            },
                        ) {
                            title(translation.getString(gridElement))
                            img(
                                {
                                    height { full }
                                    radius { normal }
                                    position { absolute { top { none } } }
                                },
                            ) {
                                src(gridElement.previewImageUrl)
                            }
                            // Mobile / Desktop / 3D icons
                            if (gridElement.features.isNotEmpty()) {
                                flexBox(
                                    {
                                        width { maxContent }
                                        height { maxContent }
                                        direction { row }
                                        alignItems { center }
                                        justifyContent { center }
                                        background { color { FormationColors.White.color } }
                                        radii {
                                            topLeft { normal }
                                            bottomRight { large }
                                        }
                                        padding { tiny }
                                        position {
                                            absolute {
                                                top { none }
                                                left { none }
                                            }
                                        }
                                    },
                                ) {
                                    gridElement.features.forEach {
                                        when (it) {
                                            Feature.Mobile -> icon({ size(deviceIconSize) }) { fromTheme { mobile } }
                                            Feature.Desktop -> icon({ size(deviceIconSize) }) { fromTheme { computer } }
                                            Feature.Map3D -> span(
                                                {
                                                    fontSize { tiny }
                                                    fontWeight { bold }
                                                },
                                            ) { +"3D" }
                                        }
                                    }
                                }
                            }
                            clicks.map { gridElement } handledBy mapStyleSelectionStore.selectMapStyle
                        }
                        span(
                            {
                                fontSize(fontSize)
                                fontWeight { lighter }
                            },
                        ) { translation[gridElement].renderText(into = this) }
                    }
                }
            }
            if (items.size < columns) {
                for (i in items.size until columns) {
                    div(
                        {
                            width(itemSize)
                            height(itemSize)
                        },
                    ) { }
                }
            }
        }
    }
}
