@file:Suppress("EXPERIMENTAL_API_USAGE")

package login

import apiclient.groups.WorkSpaceOptions
import auth.ApiUserStore
import auth.WorkspaceOptionsStore
import data.users.views.profileInput
import dev.fritz2.components.clickButton
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.id
import dev.fritz2.core.placeholder
import dev.fritz2.core.type
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import localization.translate
import mainmenu.Pages
import model.Credentials
import model.anonymousAccessAllowed
import model.email
import model.password
import model.signupsAllowed
import network.NetworkState
import network.NetworkStateStore
import search.separationLine
import signup.textButton
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.twColOfStretch
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twLargeIconToggleButtonNeutralRounded
import twcomponents.twMainTitle
import twcomponents.twMediumIconButtonNeutralRounded
import twcomponents.twRevertButton
import twcomponents.twRightAlignedButtonRow
import twcomponents.twRowOfJustifyBetween
import twcomponents.twSimpleModalWithCloseHeader
import utils.ToggleStore
import utils.focusInputObserver
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.cardTitle
import webcomponents.fullWidthFullContainer
import webcomponents.inputLabelWrapper
import webcomponents.twContentScrollBox

fun RenderContext.pageLogin(workspace: String?, emailParam: String?) {
    val apiUserStore by koinCtx.inject<ApiUserStore>()
    val credentialsStore: CredentialsStore by koinCtx.inject()
    val directAnonymousLoginStore: DirectAnonymousLoginStore by koinCtx.inject()
    val workspaceInputStore: WorkspaceInputStore by koinCtx.inject()
    val workspaceOptionsStore: WorkspaceOptionsStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val email = credentialsStore.map(Credentials.email())
    val password = credentialsStore.map(Credentials.password())
    val openForSignUp = workspaceOptionsStore.map(WorkSpaceOptions.signupsAllowed())
    val anonymousAccess = workspaceOptionsStore.map(WorkSpaceOptions.anonymousAccessAllowed())
    val networkStateStore by koinCtx.inject<NetworkStateStore>()
    val enableNormalLogin = credentialsStore.data.map { it.email.isNotBlank() && it.password.isNotBlank() }
        .combine(workspaceInputStore.data.map { it.isNotBlank() }) { c, w -> c && w }
        .combine(networkStateStore.data.map { it == NetworkState.Online }) { a, n -> a && n }


    if (!workspace.isNullOrBlank()) {
        workspaceInputStore.update(workspace)
    }
    if (!emailParam.isNullOrBlank()) {
        email.update(emailParam)
    }

    val directAnonymousLogin = directAnonymousLoginStore.current

    if (apiUserStore.anonymousUserStore.current == null && anonymousAccess.current) {
        console.log("No user and no anonymous user set yet -> trigger anonymous login once")
        apiUserStore.loginAnonymous(workspaceInputStore.current.ifBlank { null }, directAnonymousLogin)
    }

    // Trigger anonymous login, when loading the login page, if anonymous access should be possible
    combine(apiUserStore.anonymousUserStore.data, anonymousAccess.data) { anonUser, anonAccess ->
        anonAccess && anonUser == null
    } handledBy { loginAnonymously ->
        if (loginAnonymously) {
            console.log("No user and no anonymous user set yet -> trigger anonymous login")
            apiUserStore.loginAnonymous(workspaceInputStore.current.ifBlank { null }, directAnonymousLogin)
        }
    }

    baseLayout(
        header = { },
        content = {
            form("w-full, h-full overflow-auto") {
                fullWidthFullContainer {
                    div("flex grow-2") { }

                    // Title Formation
//                    span(
//                        "flex flex-grow items-center justify-center text-2xl sm:text-3xl font-thin tracking-widest pl-2",
//                    ) {
//                        +"F O R M A T I O N"
//                    }
//
//                    div("flex grow-1") { }

                    twMainTitle {
                        translate(TL.Login.LOGIN)
                    }

                    div("flex grow-2") { }
                    div("flex flex-col w-full items-center justify-center mb-3 gap-1") {
//                        className(workspaceInputStore.data.map { ws -> if (ws.isNullOrBlank()) "invisible" else "visible" })
                        cardSubtitle(translation[TL.Signup.WORKSPACE])
                        cardTitle(
                            title = workspaceInputStore.data.map { it.ifBlank { " " } },
                            margins = { },
                            iconSize = { "30px" },
                            iconFlow = flowOf(FormationIcons.Map.icon), //workspaceInputStore.data.map { if (it.isNullOrBlank()) null else FormationIcons.Map.icon }
                        )
                    }

                    // Proceed Anonymous Button
                    combine(anonymousAccess.data, enableNormalLogin) { anonymousAccess, buttonFocus ->
                        Pair(anonymousAccess, buttonFocus)
                    }.render { (anonymousLogin, focusOnNormalLogin) ->
                        if (anonymousLogin) {
                            div("flex grow-2") { }
                            separationLine("my-2", title = translation[TL.Login.OPEN_FOR_ANONYMOUS_ACCESS])
                            div("flex w-full") {
                                attr("onClick", "event.preventDefault(); blurInput();")
                                clickButton(
                                    {
                                        secondaryButtonStyleParams()

                                        // overwrite color params to make button more discoverable
                                        color { secondary.main }
                                        background { color { FormationColors.GreenForest.color } }
                                        border {
                                            width(FormationDefault.formationStyles.borderWidth)
                                            color { FormationColors.GreenForest.color }
                                        }
                                        hover {
                                            color { secondary.main }
                                            background {
                                                color { FormationColors.GreenBright.color }
                                            }
                                            border {
                                                width(FormationDefault.formationStyles.borderWidth)
                                                color { FormationColors.GreenBright.color }
                                            }
                                        }

                                        fontSize { normal }
                                        width { full }
                                    },
                                ) {
                                    type { primary }
                                    text(translation[TL.Login.PROCEED_ANONYMOUSLY])
                                    element {
                                        if (focusOnNormalLogin) attr("type", "button")
                                        attr("tabindex", "4")
                                    }
                                } handledBy apiUserStore.proceedAnonymously
                            }
                            div("flex grow-2") { }
                            p {
                                translation[TL.Login.OR].renderText(into = this)
                            }
                        }
                    }

                    div("flex grow-2") { }

                    separationLine("my-2", title = translation[TL.Login.LOGIN_WITH_ACCOUNT])

                    // Workspace info modal
                    val toggleInfoStore = ToggleStore(false)
                    workspaceInfoModal(toggleInfoStore)

                    twColOfStretch("w-full") {
                        // input workspace
                        inputLabelWrapper(
                            title = TL.Login.WORKSPACE,
                            visibilityFlow = workspaceInputStore.data.map { it.isNotBlank() },
                        ) {
                            twRowOfJustifyBetween {
                                twInputField(workspaceInputStore) {
                                    twInputTextField {
                                        id("workspace")
                                        placeholder(translation[TL.Login.WORKSPACE])
                                        attr("tabindex", "1")
                                    }
                                    twRightAlignedButtonRow {
                                        workspaceInputStore.data.render { text ->
                                            if (text.isNotBlank()) {
                                                twRevertButton(workspaceInputStore, "")
                                            }
                                        }
                                    }
                                }
                                div {
                                    twMediumIconButtonNeutralRounded(
                                        icon = FormationIcons.InformationAlt,
                                    ) {
                                        clicks handledBy toggleInfoStore.toggle
                                    }
                                }
                            }
                        }
                        // input email
                        inputLabelWrapper(
                            title = TL.Login.EMAIL,
                            visibilityFlow = email.data.map { it.isNotBlank() },
                        ) {
                            twInputField(email) {
                                twInputTextField {
                                    id("inputEmail")
                                    type("email")
                                    placeholder(translation[TL.Login.EMAIL])
                                    attr("tabindex", "2")
                                }
                                twRightAlignedButtonRow {
                                    email.data.render { text ->
                                        if (text.isNotBlank()) {
                                            twRevertButton(email, "")
                                        }
                                    }
                                }
                            }
                        }
                        focusInputObserver({ js("document.getElementById('inputEmail').focus()") }, domNode)
                        // input pw
                        val showPWStore = ToggleStore(false)
                        profileInput(
                            "inputPassword",
                            title = TL.Login.PASSWORD,
                            placeHolder = translation[TL.Login.PASSWORD],
                            store = password,
                            type = showPWStore.data.map { if (it) "text" else "password" },
                            tabIndex = flowOf(3),
                            rightInRowWithInputContent = {
                                twLargeIconToggleButtonNeutralRounded(
                                    iconTrue = FormationUIIcons.EyeOff,
                                    iconFalse = FormationUIIcons.Eye,
                                    boolStore = showPWStore,
                                ) {
                                    clicks handledBy showPWStore.toggle
                                }
                            },
                        )
                    }
                    div("flex grow") { }
                    // Forgot Password button
                    textButton(
                        text = translation[TL.Login.FORGOT_PASSWORD],
                        attributes = listOf(
                            "tabindex" to "-1",
                            "type" to "button",
                            "onClick" to "event.preventDefault();",
                        ),
                        replaceRoute = mapOf("show" to "forgotPassword"),
                    )

                    // Use new sign in via email
//                    textButton(
//                        text = translation[TL.Login.SIGN_IN_VIA_EMAIL_BUTTON],
//                        attributes = listOf(
//                            "tabindex" to "-1",
//                            "type" to "button",
//                            "onClick" to "event.preventDefault();",
//                        ),
//                        replaceRoute = Pages.LoginWithEmail.route,
//                    )

                    openForSignUp.data.render { open ->
                        if (open) {
                            div("flex grow") { }
                            // Sign up Button, if workspace is open for signup
                            textButton(
                                text = translation[TL.Login.DO_NOT_HAVE_ACCOUNT],
                                attributes = listOf(
                                    "tabindex" to "-1",
                                    "type" to "button",
                                    "onClick" to "event.preventDefault();",
                                ),
                                replaceRoute = workspaceInputStore.current.ifBlank { null }?.let { workspace ->
                                    mapOf(
                                        "page" to Pages.SignUp.name,
                                        "ws" to workspace,
                                    )
                                } ?: mapOf("page" to Pages.SignUp.name),
                            )
                        }
                    }
                    div("flex grow-3") { }
                    // Login button
                    div("flex w-full") {
                        attr("onClick", "event.preventDefault(); blurInput();")
                        clickButton(
                            {
                                primaryButtonStyleParams()
                                fontSize { normal }
                                width { full }
                            },
                        ) {
                            type { primary }
                            enabled(enableNormalLogin)
                            text(translation[TL.Login.LOGIN])
                            loadingText(translation[TL.Login.LOGIN_ONGOING])
                            loading(credentialsStore.loggingIn.data)
                            element {
                                attr("type", "submit")
                                attr("tabindex", "5")
                            }
                        } handledBy credentialsStore.login
                    }
                }
            }
        },
    )
}

fun workspaceInfoModal(toggleStore: Store<Boolean>) = twSimpleModalWithCloseHeader(
    additionalClasses = "min-w-96",
    title = TL.Login.LOGIN_NEED_HELP,
    toggleStore = toggleStore,
) { _, _, _ ->
    twContentScrollBox {
        p("text-sm max-w-96") {
            translate(TL.Login.LOGIN_INFO_TEXT)
        }
    }
}
