package analyticsdashboard

import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.autocomplete
import dev.fritz2.core.autofocus
import dev.fritz2.core.placeholder
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.flowOf
import theme.FormationUIIcons
import twcomponents.twIconMedium
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twLeftAlignedRow
import twcomponents.twRevertButton
import twcomponents.twRightAlignedButtonRow
import twcomponents.twRowOfJustifyStart

class AnalyticsTextFilterStore : RootStore<String>(
    initialData = "",
    job = Job(),
)

fun RenderContext.textFilter() {
    val analyticsTextFilterStore: AnalyticsTextFilterStore by koinCtx.inject()

    twRowOfJustifyStart("ml-1 min-w-24 max-w-64") {
        twInputField(analyticsTextFilterStore) {
            twLeftAlignedRow {
                twIconMedium(icon = FormationUIIcons.FilterAlt)
            }
            twInputTextField {
                autocomplete("off")
                placeholder(flowOf("Filter by text")) // TODO translate
                autofocus(true)
                attr("tabindex", "0")
            }
            twRightAlignedButtonRow {
                analyticsTextFilterStore.data.render { text ->
                    if (text.isNotBlank()) {
                        twRevertButton(analyticsTextFilterStore, "")
                    }
                }
            }
        }
    }
}
